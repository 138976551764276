<div class="ti-form__one-line-fields">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
</div>

<div class="ti-menu-wrapper ti-form__column-fields search-results" infiniteScroll [scrollWindow]="false"
  [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="loadMore()">
  <div class="ti-form__one-line-fields ti-form__column-fields">
    <div class="ti-form__header">{{ "headers.list-of-lexemes" | translate }}</div>
    <app-universal-id-text-range-filter [currentRange]="'lexemes'"
      [loadedRecordCount]="lexemesList ? lexemesList.length : 0" [loadedRecordCountLabel]="'lexicon.lexemes'"
      [loadedSubRecCount]="totalWordSenses" [loadedSubRecCountLabel]="'lexicon.total-word-senses'"
      [enabledCount]="true"
      actionButtonTextKey="lexicon.retag" actionButtonTooltipKey="lexicon.retag-explanation"
      (actionButtonPressedEvent)="retag($event)"></app-universal-id-text-range-filter>
    <mat-progress-bar *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-bar>
    <div class="ti-form__one-line-fields">
      <mat-checkbox matInput name="isNoFullRefreshParameter" (change)="changeNoFullRefreshStatus($event)"
        [(ngModel)]="isNoFullRefresh">
        <span class="ti-form__label">{{"lexicon.no-full-refresh" | translate}}</span>
      </mat-checkbox>
    </div>
  </div>

  <!-- refine filter -->
  <div *ngIf="lexemesList && lexemesList.length > 0" class="ti-form__filter">
    <mat-form-field color="accent" class="ti-input__feature-description">
      <input matInput [(ngModel)]="featureDescription" (keyup)="filter(featureDescription)"
        [placeholder]="'lexicon.search-placeholder-with-example' | translate" />
    </mat-form-field>
    <button mat-mini-fab class="ti-button__reset-selection" color="primary" [disabled]="!featureDescription"
      (click)="featureDescription = ''; filter('');" [matTooltip]="'inflection-table.reset' | translate">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <div class="roundbutton-panel">
    <button mat-mini-fab color="primary" [routerLink]="'/lexicon/lexeme/create'"
      matTooltip="{{'lexicon.lexeme-creation' | translate}}">
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>&nbsp;
    <button mat-mini-fab color="primary" (click)="resetMorphology()"
      matTooltip="{{'lexicon.reset-morphology' | translate}}">
      <mat-icon aria-hidden="true">refresh</mat-icon>
    </button>
  </div>

  <mat-accordion *ngIf="showingLexemesList" multi="false">
    <mat-expansion-panel *ngFor="let lexeme of showingLexemesList; let lexemeIndex=index"
      [expanded]="(lexeme.id === selectedLexemeId || showingLexemesList.length === 1)"
      (opened)="expandedLexemeId = lexeme.id">
      <mat-expansion-panel-header>
        <mat-panel-title class="ti-panel-title" [ngClass]="{'ti-red-text' : lexeme.duplicateWarning}">
          <span *ngIf="lexeme.id === selectedLexemeId" class="currently-selected-lexeme" id="selectedLexeme"></span>
          {{lexeme.lemma}}
          <span id="{{'lexeme' + lexeme.id}}" class="ti-text--small">{{lexeme.dictionaryTag}}</span>
          <span *ngIf="lexeme.hasSameAs" matTooltip="{{lexeme.sameAsList}}">&nbsp;🗝️</span>
          <span *ngIf="lexeme.sameAsLemma" matTooltip="{{lexeme.sameAsLemma}}">&nbsp;🔒</span>
          <span *ngIf="lexeme.advancedCriteriaDescription"
            matTooltip="{{lexeme.advancedCriteriaDescription}}">&nbsp;🔣</span>
          <span *ngIf="lexeme.clitic" matTooltip="#{{lexeme.clitic}}">🔩&nbsp;</span>
        </mat-panel-title>
        <mat-panel-description>
          <span *ngIf="lexeme.inflections !== lexeme.lemma">{{inflections(lexeme)}}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container matExpansionPanelContent *ngIf="lexeme.id === expandedLexemeId">
        <app-lexicon-element [lexeme]="lexeme" (sendSuccessfulResponseMessageEvent)="showErrorMessage($event.error)"
          (reloadFilterEvent)="requireReloadEvent()" (updateLexemeEvent)="requireUpdatedEvent($event)"
          (deleteLexemeEvent)="deleteLexeme($event)" (deleteFamilyEvent)="deleteFamily($event)"></app-lexicon-element>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>