import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {RouterHelper} from '../../../shared/helpers/router.helper';
import {LampUpdateResponse} from '../../../shared/models/common';
import {RangeService} from '../../../shared/services/range.service';
import {
  LinguisticRange, emptyLinguisticRange, LinguisticRangeColumn
} from '../../../shared/models/range';
import {collectObjectChanges, toJsonObject} from '../../../shared/helpers/object.helper';
import {SessionService} from '../../../shared/services/session.service';
import {Language} from '../../../shared/models/language';
import {SessionData} from '../../../shared/models/session';
import {applicationModulesRoutePaths} from '../../../shared/constants/application-constants';

@Component({
  selector: 'app-range-edit',
  templateUrl: './range-edit.component.html',
  styleUrls: ['./range-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeEditComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  private sessionSubscription: Subscription;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public rangeEditForm: FormGroup;
  private initialRange: LinguisticRange;
  public range: LinguisticRange;

  public isNew: boolean;
  public initialLanguageId: number;
  public doesUserHaveAccessToEdit: boolean;

  public choosenColumn: LinguisticRangeColumn;
  public choosenColumnKeyForOrderBy: string;
  public relatedRecords: { [Key: string]: { Key: string; Value: string; } };

  public emptyLinguisticRange: LinguisticRange = emptyLinguisticRange;
  public showProgressbar: boolean;

  constructor(
    private rangeService: RangeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private routerHelper: RouterHelper,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeOnRouteChanges();
    this.subscribeOnSessionChanges();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.sessionSubscription.unsubscribe();
  }

  private subscribeOnSessionChanges(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.doesUserHaveAccessToEdit = !(this.sessionService.isGuest() || this.sessionService.isLinguistWhoIsAbleEditOnlyLexicon());
    });
  }

  private subscribeOnRouteChanges(): void {
    this.routeSubscription = this.route.data.subscribe(
      (data: {
        id: number,
        type: string
      }) => {
        this.isNew = (!data.id || isNaN(data.id));

        if (this.isNew) {
          return this.initRange(data.type).then((linguisticRange: LinguisticRange) => {
            if (linguisticRange && Object.keys(linguisticRange).length) {
              this.emptyLinguisticRange = toJsonObject(linguisticRange);
            }

            this.setRange(this.emptyLinguisticRange);
            this.createForm();
          });
        } else {
          return this.getRangeById(data.id)
            .then(() => {
              this.createForm();
            });
        }
      });
  }

  private setRange(linguisticRange: LinguisticRange): void {
    linguisticRange = (this.isNew) ? this.emptyLinguisticRange : linguisticRange;
    this.initialLanguageId = (this.isNew) ? (this.sessionService.session.targetLanguage.id) : linguisticRange.languageId;

    this.range = toJsonObject(linguisticRange) as LinguisticRange;
    this.range.languageId = this.initialLanguageId;
    
    this.initialRange = toJsonObject(linguisticRange) as LinguisticRange;

    this.detectChanges();
  }

  public initRange(tableName: string): Promise<LinguisticRange> {
    tableName = tableName.split(' ').join('').toLowerCase();
    return this.rangeService.initRange(tableName).then((linguisticRange: LinguisticRange) => {
      return linguisticRange;
    });
  }

  public getRelatedRecords(): void {
    this.relatedRecords = {};

    if (this.choosenColumn.Value && this.choosenColumn.Value.relatedRecords) {
      for (let i = 0; i < this.choosenColumn.Value.relatedRecords.length; i++) {
        const key = this.choosenColumn.Value.relatedRecords[i].Key;
        this.relatedRecords[key] = this.choosenColumn.Value.relatedRecords[i];
      }
    }

    this.detectChanges();
  }

  public addOrderBy(): void {
    if (this.range.orderBy) {
      this.range.orderBy += ',';
    } else {
      this.range.orderBy = '';
    }

    this.range.orderBy += this.choosenColumnKeyForOrderBy;
  }

  public detectChanges(): void {
    this.changeDetectorRef.markForCheck();
  }

  public updateLanguage(language: Language): void {
    this.range.languageId = (language) ? language.id : 0;
  }

  private getRangeById(id: number): Promise<void> {
    return this.rangeService.getRangeById(id)
      .then((linguisticRange: LinguisticRange) => {
        this.setRange(linguisticRange);
      });
  }

  private createForm(): void {
    this.rangeEditForm = this.formBuilder.group({
      active: [this.range.active],
      global: [this.range.global],
      description: [this.range.description],
      explanation: [this.range.explanation],
      where: [this.range.where],
      orderBy: [this.range.orderBy],
      showInCompilationJobs: [this.range.showInCompilationJobs],
      fromId: [this.range.fromId],
      toId: [this.range.toId]
    });

  }

  public addQueryToWhereClause(query): void {
    if (this.range.where) {
      query = ' AND (' + query + ')';
    } else {
      this.range.where = '';
    }

    this.range.where += query;
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public cancel(): void {
    this.routerHelper.back();
  }

  public save(): Promise<void> {
    this.showProgressbar = true;

    if (this.isNew) {
      const changes = toJsonObject(this.range) as LinguisticRange;

      return this.rangeService.createRange(changes)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnTheServerResponse(successfulResponseMessage);
        });
    } else {
      const changes = collectObjectChanges(this.initialRange, this.range) as LinguisticRange;

      changes.id = this.range.id;

      return this.rangeService.updateRange(changes)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnTheServerResponse(successfulResponseMessage);
        });
    }
  }

  private reactOnTheServerResponse(successfulResponseMessage: LampUpdateResponse): void {
    this.showProgressbar = false;

    if (successfulResponseMessage.success) {
      this.sessionService.refresh();
      this.cancel();
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }

    this.detectChanges();
  }
}
