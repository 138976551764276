import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {EndpointUrls} from '../constants/endpoint';
import {ApiClientService} from './api-client.service';
import { Edits, LampUpdateResponse } from '../models/common';

@Injectable()
export class LinguistService {
  public editsQuantity: number = 0;

  private editsQuantitySource: BehaviorSubject<number> = new BehaviorSubject<number>(this.editsQuantity);
  public currentEditsQuantitySource: Observable<number> = this.editsQuantitySource.asObservable();

  constructor(private apiClientService: ApiClientService) {
  }

  public getEditsQuantity(): Promise<void> {
    return this.apiClientService
      .get(EndpointUrls.todaysEditCount)
      .then((editsQuantity) => {
        this.editsQuantity = (editsQuantity) ? editsQuantity : 0;
        this.editsQuantitySource.next(this.editsQuantity);
      });
  }

  public getTodaysHourCount(selectedUser: string): Promise<number> {
    return this.apiClientService
      .get(`todaysHourCount?user=${selectedUser}`)
      .then((hours: number) => {
        return hours;
      });
  }

  public getMonthlyHourCount(selectedUser: string): Promise<number> {
    return this.apiClientService
      .get(`monthlyHourCount?user=${selectedUser}`)
      .then((hours: number) => {
        return hours;
      });
  }

  public getTodayEdits(selectedUser: string): Promise<Edits[]> {
    return this.apiClientService
      .get(`todaysEdits?user=${selectedUser}`)
      .then((edits: Edits[]) => {
        return edits;
      });

  }
  

  public getActiveUsers(): Promise<string[]> {
    return this.apiClientService
      .get('activeUsers')
      .then((userNames: string[]) => {
        return userNames;
      });

  }
  

  public getEdits(arg: string, type: string, selectedUser: string): Promise<Edits[]> {
    return this.apiClientService
      .get(`editList?arg=${arg}&type=${type}&user=${selectedUser}`)
      .then((edits: Edits[]) => {
        return edits;
      });
  }

  public getAuditTrail(tableName: string, entityId: number): Promise<Edits[]> {
    return this.apiClientService
      .get(`audit?table=${tableName}&id=${entityId}`)
      .then((edits: Edits[]) => {
        return edits;
      });
  }

  public getStatus(): Promise<string> {
    return this.apiClientService
      .get(EndpointUrls.status)
      .then((status) => {
        return status;
      });
  }

  public createStatus(status: string): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.status, status);
  }

  public updateStatus(status: string): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.status, status as any);
  }
}

