import { Injectable } from '@angular/core';

import { ApiClientService } from './api-client.service';
import { EndpointUrls } from '../constants/endpoint';
import { ParseTreeLink, PhraseInTheEditView, PhraseInTheListView, Superset } from '../models/phrases.model';
import { LampUpdateResponse } from '../models/common';
import { CacheService } from './cache.service';
import { LocalStorageHelper } from '../helpers/localhost.helper';
import { KnowledgeGraphService } from './knowledge-graph.service';

@Injectable({
  providedIn: 'root'
})
export class PhrasesService {

  constructor(private apiClientService: ApiClientService,
    private cachService: CacheService,
    private localStorageHelper: LocalStorageHelper,
    private knowledgeGraphService: KnowledgeGraphService) {
  }

  public getPhraseList(arg: string, type: string): Promise<PhraseInTheListView[]> {
    return this.apiClientService.get(`${EndpointUrls.phrases.phraseList}?arg=${arg}&type=${type}`);
  }

  public getComparisonByLanguage(family: number, language: number): Promise<PhraseInTheListView[][]> {
    return this.apiClientService.get(`${EndpointUrls.phrases.phraseFamilyCompare}?family=${family}&language=${language}`);
  }

  public getPhraseById(id: number): Promise<PhraseInTheEditView> {
    return this.apiClientService.get(`${EndpointUrls.phrases.phrase}?id=${id}`);
  }

  public createPhrase(phrase: PhraseInTheEditView): Promise<LampUpdateResponse> {
    this.cachService.clearCachedPhrase();
    return this.apiClientService.post(EndpointUrls.phrases.phrase, phrase);
  }

  public updatePhrase(phrase: PhraseInTheEditView): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.phrases.phrase, phrase);
  }

  public deletePhrase(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.phrases.phrase}?id=${id}`);
  }

  public getPhraseDescription(phraseDescription: string, familyId: number, templateId: number): Promise<PhraseInTheEditView> {
    return this.apiClientService.get(`${EndpointUrls.phrases.phraseFromDescription}?description=${phraseDescription}&family=${familyId}&sourcePhrase=${templateId}`);
  }

  public getPhraseTreeLinkList(family: number): Promise<ParseTreeLink[]> {
    return this.apiClientService.get(`${EndpointUrls.phrases.parsetreelinkList}?family=${family}`);
  }

  public getPhraseTreeLinkByChildAndParent(child: number, parent: number): Promise<ParseTreeLink> {
    return this.apiClientService.get(`${EndpointUrls.phrases.parsetreelink}?child=${child}&parent=${parent}`);
  }

  public createPhraseTreeLink(parseTreeLink: ParseTreeLink): Promise<LampUpdateResponse> {
    this.cachService.clearCachedPhrase();
    return this.apiClientService.post(EndpointUrls.phrases.parsetreelink, parseTreeLink);
  }

  public updatePhraseTreeLink(parseTreeLink: ParseTreeLink): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.phrases.parsetreelink, parseTreeLink);
  }

  public deletePhraseTreeLink(child: number, parent: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.phrases.parsetreelink}?child=${child}&parent=${parent}`);
  }

  public getSupersetList(family: number): Promise<Superset[]> {
    return this.apiClientService.get(`${EndpointUrls.phrases.supersetList}?family=${family}`);
  }

  public getSupersetByChildAndParent(subset: number, superset: number): Promise<Superset> {
    return this.apiClientService.get(`${EndpointUrls.phrases.superset}?subset=${subset}&superset=${superset}`);
  }

  public createSuperset(superset: Superset): Promise<LampUpdateResponse> {
    this.cachService.clearCachedPhrase();
    return this.apiClientService.post(EndpointUrls.phrases.superset, superset);
  }

  public updateSuperset(superset: Superset): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.phrases.superset, superset);
  }

  public deleteSuperset(subset: number, superset: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.phrases.superset}?subset=${subset}&superset=${superset}`);
  }

  public createPhraseByGenAI(family: number): Promise<LampUpdateResponse> {
    return this.apiClientService.post(`${EndpointUrls.phrases.generatePhrasalPatterns}?arg=${family}&type=id`);
  }
}
