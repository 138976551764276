import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { TestsetService } from '../../shared/services/testset.service';
import { SessionService } from '../../shared/services/session.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { TestFragment, TestResultSet, TestResult, TestProvider } from '../../shared/models/test.model';
import { Subscription } from 'rxjs';
import { FeatureListDefinition, FeatureValue } from '../../shared/models/feature';
import { FeaturesService } from '../../shared/services/features.service';
import { applicationConstants } from '../../shared/constants/application-constants';
import { LampUpdateResponse } from '../../shared/models/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AnnotatedFragment } from './annotated-fragment';
import { MatTabChangeEvent } from '@angular/material';

@Component({
  selector: 'app-testfragment-edit',
  templateUrl: './testfragment-edit.component.html',
  styleUrls: ['./testfragment-edit.component.less']
})



export class TestfragmentEditComponent extends Translated implements OnInit, AfterContentInit {
  public showSpinner = false;
  public fragment: TestFragment = { id: 0, fragment: '', };
  public classifications: TestResult[];
  public unknownWords: string[];
  private urlSubscr: Subscription;
  public taggedResult: SafeHtml = undefined;
  public resultsets: TestResultSet[] = [];
  public selectedResultSet: TestResultSet;
  private _msgSec: string = "s";
  private _msgWPS: string = "wps";
  private _msgKbPerSec: string = "K/sec"

  public relevantGrammarFeatureList: FeatureListDefinition[];
  public relevantSemanticFeatureList: FeatureListDefinition[] = [];
  public relevantStyleFeatureList: FeatureListDefinition[];

  public grammarFeatures: FeatureValue[] = [];
  public styleFeatures: FeatureValue[] = [];
  public semanticFeatures: FeatureValue[] = [];

  public testProviders: TestProvider[] = [];

  public fragmentTransformationResults: { [key: string]: TestResultSet[] } = {};

  constructor(
    private route: ActivatedRoute,
    private featuresService: FeaturesService,
    private testService: TestsetService,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    private _sanitizer: DomSanitizer
  ) {
    super(translateService, localStorageHelper, sessionService);
  }

  // scroll top up after loading
  ngAfterContentInit(): void {
    document.getElementsByClassName('mat-drawer-content')[0].scroll(0,0)
  }

  ngOnInit(): void {
    this.initFromSession();
    this.getTestProviders();
  }

  markStandard(rs: TestResultSet) {
    this.testService.markStandard(rs.id).then((success: LampUpdateResponse) => {
      if (success.success)
        this.loadResults(this.fragment.id);
    });
  }

  markGold(rs: TestResultSet) {
    this.testService.markGold(rs.id).then((success: LampUpdateResponse) => {
      if (success.success)
        this.loadResults(this.fragment.id);
    });
  }

  copyToClipboard(txt: string) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = txt;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public generateTimeInfo(rs: TestResultSet): string {
    if (!rs.fragment)
      return '';
    let kps: number = rs.fragment.length / rs.time;
    let wps: number = rs.words ? rs.words.length / rs.time : (rs.fragment.match(/is/g) || []).length / rs.time;
    return rs.time.toFixed(3) + " " + this._msgSec + " ("
      + (wps > 0 ? wps.toFixed(3) + " " + this._msgWPS + ", " : "")
      + (kps > 0 ? kps.toFixed(3) + " " + this._msgKbPerSec : "")
      + ")";
  }

  private loadResults(id: number) {
    if (!id || id === 0)
      return;
    this.testService.getFragmentResults(id).then((results: TestResultSet[]) => {
      this.resultsets = results;
      let words: TestResult[];
      for (let rs of this.resultsets)
        if (rs.words) {
          words = rs.words;
          break;
        }
      if (words) {
        for (let rs of this.resultsets)
          if (!rs.words) {
            rs.words = words;
            break;
          }
      }
    });

  }

  selectResultset(tr: TestResultSet) {
    this.selectedResultSet = tr;
    this.classifications = [];
    let result: AnnotatedFragment = new AnnotatedFragment(this.sessionService, 0);
    result.raw = tr.fragment;
    for (let ann of tr.breakdown) {
      switch (ann.type) {
        case 'information extraction':
          result.addSubfragment(ann, tr.fragment);
          break;
        case 'classification':
          ann.emoji = this.sessionService.getAnnotationTypeEmoji(ann.attribute);
          this.classifications.push(ann);
          break;
      }
    }
    const text = result.toString();
    const plainText = text.replace(/<a /g, '&lt;a ').replace('</a>', '&lt;a&gt;');
    this.taggedResult = this._sanitizer.bypassSecurityTrustHtml(plainText);
  }

  initThisSubtypeFromSession(): void {
    let thisOne = this;
    this._msgSec = this.translateService.instant('TEST.seconds');
    this._msgWPS = this.translateService.instant('TEST.wps');
    this._msgKbPerSec = this.translateService.instant('TEST.cps');
    this.getFeaturesList();

    this.urlSubscr = this.route.params.subscribe(
      params => {
        let id: number = parseInt(params['id']);
        if (id) {
          thisOne.loadResults(id);
          thisOne.testService.getTestfragment(id).then((tf: TestFragment) => {
            thisOne.fragment = tf;

            thisOne.fragment.labels = thisOne.fragment.labels || [];
            thisOne.grammarFeatures = thisOne.fragment.labels.filter(x => !x.type);
            thisOne.semanticFeatures = thisOne.fragment.labels.filter(x => x.type == applicationConstants.semanticFeatureType);
            thisOne.styleFeatures = thisOne.fragment.labels.filter(x => x.type == applicationConstants.styleFeatureType);
            
            if (tf.unknown && tf.unknown !== true)
              thisOne.unknownWords = tf.unknown.toString().split(' / ');
            else
              thisOne.unknownWords = [];
            thisOne.taggedResult = thisOne._sanitizer.bypassSecurityTrustHtml(tf.fragment);
            thisOne.urlSubscr.unsubscribe();
          });
        } else {
          thisOne.fragment.corporaId = parseInt(params['corporaId']);
        }
      });
  }

  public isCreating(): boolean {
    return !(this.fragment.id && this.fragment.id !== 0);
  }

  private getFeaturesList(): Promise<void> {
    return this.featuresService.createFilteredRelevantFeatureList(undefined, applicationConstants.defaultFeatureType).then((filteredGrammarFeatures) => {
      this.relevantGrammarFeatureList = filteredGrammarFeatures;
      return this.featuresService.createFilteredRelevantFeatureList(undefined, applicationConstants.styleFeatureType).then((filteredStyleFeatures) => {
        this.relevantStyleFeatureList = filteredStyleFeatures;
        return this.featuresService.getFeatureList(applicationConstants.semanticFeatureType
        ).then((semanticFeatures: FeatureListDefinition[]) => { this.relevantSemanticFeatureList = semanticFeatures; });
      });
    });
  }

  public save(): void {
    // collect all features from the three lists and put them into the fragment.features list
    this.fragment.labels = [].concat(
      this.grammarFeatures,
      this.semanticFeatures.map((x) => {
        x.type = applicationConstants.semanticFeatureType;
        return x;
      }),
      this.styleFeatures.map((x) => {
        x.type = applicationConstants.styleFeatureType;
        return x;
      })
    );

    if (this.isCreating()) {
      this.testService.createTestfragment(this.fragment).then((response: LampUpdateResponse) => {
        this.handleServerResponse(response);
      });
    } else {
      this.testService.updateTestfragment(this.fragment).then((response: LampUpdateResponse) => {
        this.handleServerResponse(response);
      });
    }
  }

  public async getTestProviders() {
    try {
      const testProviders = await this.testService.getTestproviderList();
      this.testProviders = testProviders ? testProviders.filter(e => e.translation) : [];
    } catch (error) {
      this.showErrorMessage(error.message || 'Failed to get provider list')
    }
  }

  public async getFragmentTransformationResults(event: MatTabChangeEvent) {
    const selectedProvider = this.testProviders.find(e => e.description == event.tab.textLabel);
    if (selectedProvider && this.fragmentTransformationResults[selectedProvider.id] === undefined) {
      this.fragmentTransformationResults[selectedProvider.id] = [];
      try {
        this.showSpinner = true;
        const result = await this.testService.getFragmentTransformationResultList(selectedProvider.id, this.fragment.id)
        this.fragmentTransformationResults[selectedProvider.id] = result;
        this.showSpinner = false;
      } catch (error) {
        this.showSpinner = false;
        this.showErrorMessage(error.message || 'Failed to get provider list')
      }
    }
  }

}