import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, UrlTree} from '@angular/router';

import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import {filter} from '../helpers/object.helper';

import {applicationConstants, applicationModulesRoutePaths} from '../constants/application-constants';


@Injectable()
export class TitleService {
  private routeQueryParamsChangedSubscription: Subscription;

  constructor(private router: Router, private titleRef: Title) {
    this.routeQueryParamsChangedSubscription =
      this.router.events
        .filter((event) => event instanceof NavigationEnd)
        .subscribe((NavigationEndEvent: NavigationEnd) => {
          const currentUrl = NavigationEndEvent.url;

          const generatedNewTitle = this.getTitleFromApplicationModuleRoutePathFroCurrentUrl(currentUrl);
          if (generatedNewTitle)
            this.titleRef.setTitle(generatedNewTitle);

        });
  }

  private getTitleFromApplicationModuleRoutePathFroCurrentUrl(currentUrl: string): string {
    let title = '';

    let urlPathPart1: string = currentUrl.split('?')[0];
    let urlSuffix: string;
    let searchArg: string;
    
    if (urlPathPart1.substr(1).includes('/')) {
      let posSlash1: number = urlPathPart1.indexOf('/', 1);
      urlSuffix = urlPathPart1.substr(posSlash1);
      if (urlSuffix.includes('/edit'))
        urlSuffix = urlSuffix.substr(0, urlSuffix.indexOf('/edit'));
      if (urlSuffix.includes('/create'))
        urlSuffix = urlSuffix.substr(0, urlSuffix.indexOf('/create'));
      urlPathPart1 = urlPathPart1.substr(0, posSlash1);
    }
    searchArg = urlPathPart1;
    const filterRule = (applicationModulesRoutePath: { path: string, title: string }) => {
      return (applicationModulesRoutePath.path && applicationModulesRoutePath.path === searchArg);
    };

    let bestPathMatch;
    let pathMatchList = filter(applicationModulesRoutePaths, filterRule);
    if (pathMatchList)
      bestPathMatch = pathMatchList[Object.keys(pathMatchList)[0]];
    if (!bestPathMatch) {
      try {
        if (urlSuffix) {
          searchArg = urlSuffix;
          switch (urlPathPart1) {
            case applicationModulesRoutePaths.syntaxAndContext.syntaxAndContext.path:
                pathMatchList = filter(applicationModulesRoutePaths.syntaxAndContext, filterRule)
                break;
            case applicationModulesRoutePaths.morphology.morphology.path:
                pathMatchList = filter(applicationModulesRoutePaths.morphology, filterRule)
                break;
            case applicationModulesRoutePaths.administration.administration.path:
                pathMatchList = filter(applicationModulesRoutePaths.administration, filterRule)
                break;
            case applicationModulesRoutePaths.advanced.advanced.path:
                pathMatchList = filter(applicationModulesRoutePaths.advanced, filterRule)
                break;
          }
          bestPathMatch = pathMatchList[Object.keys(pathMatchList)[0]];
        }
      } catch (e) {}
    }

    if (bestPathMatch && bestPathMatch.title) {
      title = bestPathMatch.title;
      const parsedUrl: UrlTree = this.router.parseUrl(currentUrl);
      if (parsedUrl.queryParamMap.has("description")) {
        title = title + " [" + parsedUrl.queryParamMap.get("description") + "]";
      } else {
        if (currentUrl.includes('/edit') || currentUrl.includes('/create'))
          title = "✍️ " + title;
        if (parsedUrl.queryParamMap.has("arg")) {
          title = title + " [" + parsedUrl.queryParamMap.get("arg") + "]";
        } else {
          if (parsedUrl.queryParamMap.has("value"))
            title = title + " [" + parsedUrl.queryParamMap.get("value") + "]";
          if (parsedUrl.queryParamMap.has("editedValue")) {
            title = title + " [" + parsedUrl.queryParamMap.get("editedValue") + "]";
          } else {
            if (parsedUrl.queryParamMap.has("editedId")) {
              title = title + " [" + parsedUrl.queryParamMap.get("editedId") + "]";
            }
          }
        }
      }
      title += applicationConstants.title.endPart;
    } else {
      title = applicationConstants.title.default;
    }
    return title;
  }
}
