<mat-form-field color="accent">
  <mat-select
    role="listbox"
    style="line-height: 150%;"
    placeholder="{{ description || ('language-selection.selected-language' | translate) }}"
    [(value)]="selectedLanguageId"
    (selectionChange)="onLanguageChange()"
    [disabled]="hasToBeDisabled"
  >
    <mat-option role="option" *ngIf="isEmptyLanguageAllowed" [value]="0"></mat-option>
    <mat-option role="option" *ngFor="let language of languagesList"         
    style="line-height: 150%;"
    [value]="language.id">{{language.englishName}} ({{language.name}})</mat-option>
  </mat-select>
</mat-form-field>
