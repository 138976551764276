import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {scrollDialogToEnd} from '../../../shared/helpers/scroll-content';

import {LinguisticRangeLocalization} from '../../../shared/models/range';
import {createFlatArrayCopy} from '../../../shared/helpers/object.helper';

@Component({
  selector: 'app-range-localization-dialog',
  templateUrl: './range-localization-dialog.component.html',
  styleUrls: ['./range-localization-dialog.component.less']
})
export class RangeLocalizationDialogComponent {
  public rangeId: number;
  public doesUserHaveAccessDelete: boolean;

  public addNewEntryBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addNewEntryObservable: Observable<boolean> = this.addNewEntryBehaviorSubject.asObservable();


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.rangeId = data.rangeId;
    this.doesUserHaveAccessDelete = data.doesUserHaveAccessDelete;

  }

  public addNewEntry(): void {
    this.addNewEntryBehaviorSubject.next(true);
  }

  public scrollToEnd(): void {
    scrollDialogToEnd();
  }
}
