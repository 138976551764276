import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subscription} from 'rxjs/Subscription';

import {TestsetService} from '../../shared/services/testset.service';
import {SessionService} from '../../shared/services/session.service';
import {KnowledgeGraphService} from '../../shared/services/knowledge-graph.service';

import {commonTabSettings} from '../../shared/constants/application-constants';

import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { TestResultSet, TestResult } from '../../shared/models/test.model';
import { LampUpdateResponse, FilterSetting } from '../../shared/models/common';
import { FeatureValueDefinition } from '../../shared/models/feature';
import { Options } from 'ng5-slider/options';
import { validateConfig } from '@angular/router/src/config';
import { Family, FamilyDefinition } from '../../shared/models/knowledge-graph';

@Component({
    selector: 'result-review',
    templateUrl: './result-review.component.html',
    styleUrls: ['./result-review.component.less']
  })
  
  export class ResultReviewComponent extends Translated implements OnInit {
      
    public results: TestResultSet[];
    public selectedId: number;
    private _lastSearchArgument: string;
    private _lastSearchArgumentType: string;
    public ratings: string[] = [];
    public sentimentAspects: FeatureValueDefinition[];
    public abuseTags: FeatureValueDefinition[];
    public entitySubtypes: FeatureValueDefinition[];
  

    public ratingSliderDefinition: Options = {
        floor: 0,
        ceil: 10,
        translate: (value: number): string => {
            return this.interpretRating(value);
        }
    };

    public aspectSliderDefinition: Options = {
        floor: 0,
        ceil: 5,
        translate: (value: number): string => {
          if (value < 1)
            return '';
          if (!this.sentimentAspects || this.sentimentAspects.length < value)
            return '???';
          let selectedFeatureValue: FeatureValueDefinition = this.sentimentAspects[value - 1];
          if (!selectedFeatureValue)
            return '???';
          if (selectedFeatureValue.emoji)
            return selectedFeatureValue.emoji + ' ' + selectedFeatureValue.description;
          else
            return selectedFeatureValue.description;
        }
      };
    
    public interpretRating(value: number): string {
        if (value < 0 || value >= this.ratings.length)
            return '';
        return this.ratings[value];
    }
    
    panelTitle(res: TestResultSet): string {
        let title: string = '';
        if (!res.fragment && res.fragment.length < 300)
            return title + res.fragment;
        else
            return title + res.fragment.substr(0, 300);
    }

    ngOnInit(): void {
        this.initFromSession();

    }

    public getSentimentValues(): FeatureValueDefinition[] {
        return this.sessionService.getAnnotationValues('sentiment', 'polarity').filter(
            (fo: FeatureValueDefinition) => { return fo.emoji && fo.emoji.length > 0; } );
    }

    public getAbuseValues(): FeatureValueDefinition[] {
        return this.sessionService.getAnnotationValues('abuse', 'type').filter(
            (fo: FeatureValueDefinition) => { return fo.emoji && fo.emoji.length > 0; } );
    }

    public getEntityValues(): FeatureValueDefinition[] {
        return this.sessionService.getAnnotationValues('entity', 'type').filter(
            (fo: FeatureValueDefinition) => { return fo.emoji && fo.emoji.length > 0; } );
    }

    public aspect2Emoji(aspect: string): string {
        if (!this.sentimentAspects || !aspect)
            return '';
        for (let sa of this.sentimentAspects)
            if (sa.description === aspect)
                return sa.emoji;
        return '';
    }

    public tag2Emoji(tag: string): string {
        if (!this.abuseTags || !tag)
            return '';
        for (let sa of this.abuseTags)
            if (sa.description === tag)
                return sa.emoji;
        return '';
    }

    public removeItem(res: TestResultSet, resultList: TestResult[], toRemove: TestResult): void {
        this.clearTheErrorMessage();
        res.changed = true;
        let whereInArray: number = resultList.indexOf(toRemove);
        if (whereInArray > -1)
            resultList.splice(whereInArray, 1);
    }

    public getAbuseEmoji(ts: TestResultSet): string {
        let emoji: string = '';
        for (let ab of ts.abuse) {
            let current: string = this.abuse2Emoji(ab.label);
            if (current && !emoji.includes(current))
                emoji += current;
        }
        return emoji;
    }

    public getSentimentEmoji(ts: TestResultSet): string {
        let emoji: string = '';
        for (let ab of ts.sentiment) {
            let current: string = this.sentiment2Emoji(ab.label);
            if (current && !emoji.includes(current))
                emoji += current;
        }
        return emoji;
        
    }

    public getEntityEmoji(ts: TestResultSet): string {
        let emoji: string = '';
        for (let ab of ts.entities) {
            let current: string = this.entity2Emoji(ab.label);
            if (current && !emoji.includes(current))
                emoji += current;
        }
        return emoji;
        
    }

    public resultText(fragment: string, r: TestResult, name: string): string {
        return this._service.resultText(fragment, r, name);
    }

    public loadWordDefinitions(words: TestResult[]) {
        for (let w of words) {
            if (w.entityId > 0) {
                this.familyService.getFamilyById(w.entityId.toString()).then(
                    (family: FamilyDefinition) => {
                        w.auxLabel = family.definition;
                    }
                );
            } else {
                if (w.subtype === 'word')
                    w.auxLabel = '???';
            }
        }
    }

    public save(rs: TestResultSet) {
        this.clearTheErrorMessage();
        this.disableRefresh();
        rs.standard = true;
        rs.gold = true;
        let rsClone: TestResultSet = JSON.parse(JSON.stringify(rs));
        
        //https://mantis.tisane.ai/view.php?id=843#c11835
        //Remove word list to prevent error HTTP 413 - Payload Too Large
        if (rsClone.words && rsClone.words.length > 10) {
            rsClone.words = undefined;
        }
        
        rsClone.breakdown = undefined; // we use the other arrays, so this one has to be removed
        this._service.validateTestResult(rsClone).then((response: LampUpdateResponse) =>
        {
            this.enableRefresh();
            let error: string = response.error;
            if (!response.success && !error)
                error = 'Unable to save, unknown error';
            if (error)
                this.showErrorMessage(error);
            else
                rs.changed = false;
        });
    }

    public setWordLexeme(commaDelimitedListOfFamilyIds: string, word: TestResult, res: TestResultSet) {
        let selected: number = parseInt(commaDelimitedListOfFamilyIds);
        if (selected !== word.num) {
            if (word.num != 0)
                word.entityId = 0; //TODO: find family by lexemeId + string, when possible
            word.num = selected;
        }
        res.changed = true;
        this.enableRefresh();
    }

    public setWordFamily($event: { commaDelimitedListOfFamilyIds: string, family: Family }, word: TestResult, res: TestResultSet) {
        if ($event.family.id !== word.entityId) {
            if (word.entityId != 0)
                word.num = 0; //TODO: find lexeme by familyId + string, when possible
            word.entityId = $event.family.id;
        }
        res.changed = true;
        this.enableRefresh();
    }

    public rate(rs: TestResultSet) {
        this.clearTheErrorMessage();
        this._service.rate(rs).then((response: LampUpdateResponse) =>
        {
            rs.changed = false;
            let error: string = response.error;
            if (!response.success && !error)
                error = 'Unable to rate, unknown error';
            if (error)
                this.showErrorMessage(error);
            
        });
    }

    protected filterSettingName(): string {
        return "testresults";
    }

    public captureFragment(res: TestResultSet, targetList: TestResult[], attributeName: string, resultValue: string) {
        this.clearTheErrorMessage();
        let resultType: string = 'information extraction';
        let tags;
        switch(attributeName){
            case 'topic':
                resultType = 'classification';
                break;
            case 'word':
                resultType = 'token';
                break;
            case 'abuse':
                tags = res.selectedAbuse;
                break;
            case 'sentiment_expressions':
                tags = res.selectedAspect;
                break;
            case 'entity':
                tags = res.selectedEntity;
                break;
        }
        if (attributeName === 'topic')
            resultType = 'classification';
        if (attributeName === 'word')
            resultType = 'token';
        res.changed = true;
        
        let newResult: TestResult = {id: 0, type: resultType, offset: res.selectStart, 
            length: res.selectLength, attribute: attributeName, label: resultValue,
            tags: tags}; 
        res.selectLength = undefined;
        for (let i = 0; i < targetList.length; i++)
        {
            if (targetList[i].offset > newResult.offset ||
                targetList[i].offset == newResult.offset && targetList[i].length < newResult.length) {
                    targetList.splice(i, 0, newResult);
                    return;
                }
        }
        targetList.push(newResult);
        res.selectedAspect = undefined;
        res.selectedAbuse = undefined;
    }
    

    public toSummary(fragment: string, results: TestResult[], name: string): string {
        let summary: string = '';
        for (let r of results)
            summary += this.resultText(fragment, r, name) + ' | ';
        if (summary.length > 0)
            return summary.substr(0, summary.length - 3).trim();
        else
            return '';
    }

    public hasSelection(rs: TestResultSet): boolean {
        return rs.selectStart != undefined && rs.selectLength > 0;
    }

    private allowedSelectionEdge(ch: string): boolean {
        return !(ch === ' ' || ch === '.' || ch === ',' || ch === '!' || ch === '?');
    }

    public addTopic($event: { commaDelimitedListOfFamilyIds: string, family: Family }, res: TestResultSet): void {
        let normalizedTopicName: string = $event.family.description;
        let firstCommaPos: number = normalizedTopicName.indexOf(',');
        if (firstCommaPos > 0)
            normalizedTopicName = normalizedTopicName.substr(0, firstCommaPos);
        let newT: TestResult = {id: 0, type: 'classification', offset: undefined, 
            entityId: $event.family.id,
            length: undefined, attribute: 'topic', label: normalizedTopicName};
        res.topics.push(newT);
        res.changed = true;
    }

    /**
     * Makes sure the offset is correct, because the standard substr does not work well with emoji and some Unicode characters in general. 
     * @param offset 
     * @param str 
     */
    private correctUnicodeOffset(offset: number, str: string): number {
        if (offset < 1) return offset;
        return Array.from(str.substr(0, offset)).length;
    }

    public setSelection($event, res: TestResultSet): void {
        res.selectStart = this.correctUnicodeOffset($event.srcElement.selectionStart, res.fragment);
        // Summary: javascript count \r\n as 1 character in fragment but Tisane Provider is counting it as 2 characters so need to make it consistent
        // need add 1 more selectionStart for each \r\n in front
        res.selectStart += (res.fragment.replace(/\r\n/g, '_X_').substr(0, res.selectStart).match(/_X_/g) || []).length;
        let strAsArray = Array.from(res.fragment);

        // move the selection start to the first allowed character
        while (res.selectStart < res.fragment.length && 
                !this.allowedSelectionEdge(strAsArray[res.selectStart]))
            res.selectStart++;
        
        // move the selection end to the last allowed character
        let correctedOffset = this.correctUnicodeOffset($event.srcElement.selectionEnd, res.fragment);
        const frontLineBreaks = (res.fragment.replace(/\r\n/g, '_X_').substr(0, correctedOffset).match(/_X_/g) || []).length;
        res.selectLength = correctedOffset - res.selectStart + frontLineBreaks;
        while (res.selectLength > 0 && 
                !this.allowedSelectionEdge(strAsArray[res.selectStart + res.selectLength - 1]))
            res.selectLength--;

        // console actually calculate offset, length and text
        console.log('*start: ' + res.selectStart + ', length: ' + res.selectLength + ',text: "' + res.fragment.substring(res.selectStart, res.selectStart + res.selectLength) + '"');
    }

    initThisSubtypeFromSession(): void {
        this.sentimentAspects = this.sessionService.getAnnotationValues('sentiment', 'aspect');
        this.abuseTags = this.sessionService.getAnnotationValues('abuse', 'tags');
        this.entitySubtypes = this.sessionService.getAnnotationValues('entity', 'subtype');

        this.aspectSliderDefinition.ceil = this.sentimentAspects ? this.sentimentAspects.length : 1;

        this.ratings.push(this.translateService.instant('TEST.unrated'));
        for (let ri = 1; ri <= 10; ri++)
            this.ratings.push(this.translateService.instant('TEST.rating' + ri));
    }

    protected getFilteredData(searchArgument: string, searchArgumentType: string): Promise<void> {
        this._lastSearchArgument = searchArgument;
        this._lastSearchArgument = searchArgumentType;
        return this._service.getResultList(searchArgument, searchArgumentType)
        .then((results: TestResultSet[]) => {
          this.results = results.map(e => {
            // e.fragment = e.fragment.replace(/\r\n/g, '\n');
            return e;
          });
          this.results.forEach((rs: TestResultSet) => {
              if (rs.breakdown) {
                if (!rs.sentiment)
                    rs.sentiment = [];
                if (!rs.abuse)
                    rs.abuse = [];
                if (!rs.entities)
                    rs.entities = [];
                if (!rs.topics)
                    rs.topics = [];
                if (!rs.words)
                    rs.words = [];
              }
          });
        });
      }
    
    constructor(
        private _service: TestsetService,
        protected sessionService: SessionService,
        protected translateService: TranslateService,
        protected localStorageHelper: LocalStorageHelper,
        protected familyService: KnowledgeGraphService,
    )
    {
        super(translateService, localStorageHelper, sessionService);
    }

  }
  