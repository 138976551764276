<h1>{{ 'headers.feature-localization' | translate }}</h1>
<div class="ti-menu">
  <div class="ti-menu__data-wrapper">
    <mat-accordion *ngIf="featureListLocalizationList">
      <mat-expansion-panel
        *ngFor="let featureListLocalizationElement of featureListLocalizationList;
        let featureListLocalizationElementIndex=index"
        appScrollToChangedPreviouslyElement
        [expanded]="featureListLocalizationElement.Key === visitedFeatureListLocalizationListKey">
        <mat-expansion-panel-header>
          <mat-panel-title>{{featureListLocalizationElement.Key}}</mat-panel-title>
          <mat-panel-description>
            <!-- place for edit button -->
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent ngFor let-feature let-featureListLocalizationIndex="index"
                     [ngForOf]="featureListLocalizationElement.Value">
          <mat-accordion *ngIf="feature">
            <mat-expansion-panel
              appScrollToChangedPreviouslyElement
              [expanded]="(featureListLocalizationElement.Key === visitedFeatureListLocalizationListKey) &&
(visitedFeatureListLocalizationId === feature.id)">
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                  <span
                    [ngClass]="(feature.localized) ? 'ti--localized': 'ti--not-localized'">{{feature.description}} [{{feature.id}}]</span>
                </mat-panel-title>
                <mat-panel-description style="padding:4px;vertical-align:middle;">
                  <!-- place for edit button -->
                  <button mat-mini-fab color="primary"
                  (click)="navigateToEditPage(featureListLocalizationElement.Key, feature.id )">
                    <mat-icon aria-hidden="true">edit</mat-icon>
                  </button>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ng-template ngFor let-featureOption let-index="index" [ngForOf]="feature.values">
                  <div class="ti-menu__description"
                       [ngClass]="((featureListLocalizationElement.Key === visitedFeatureListLocalizationListKey) &&
(visitedFeatureListLocalizationId === feature.id) &&
            (featureOption.value === visitedFeatureLocalizationValue)) ? changedPreviouslySelector: ''"
                  >
                    <div class="ti-menu-description-agreement-info__wrapper">
                      <p class="ti-menu-description__agreement-info">
                  <span
                    [ngClass]="(featureOption.localized) ? 'ti--localized': 'ti--not-localized'">{{featureOption.description}}</span>
                      </p>
                      <div class="ti-form__button-panel">

                        <button mat-mini-fab color="primary"
                                (click)="navigateToEditPage(featureListLocalizationElement.Key, feature.id, featureOption.value)">
                          <mat-icon aria-hidden="true">edit</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar *ngIf="!featureListLocalizationList" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
