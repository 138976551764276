import { Component, EventEmitter, Input, Output, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { SessionService } from '../../../shared/services/session.service';
import { Translated } from '../../../shared/classes/translated.class';
import { IdAndDescription } from '../../../shared/models/session';
import { LocalStorageHelper } from '../../../shared/helpers/localhost.helper';
import { FilterSetting } from '../../../shared/models/common';
import { applicationConstants } from '../../../shared/constants/application-constants';
import { RangeService } from '../../../shared/services/range.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-universal-filter',
  templateUrl: './universal-filter.component.html',
  styleUrls: ['./universal-filter.component.less']
})

export class UniversalFilterComponent extends Translated implements OnInit, OnChanges, OnDestroy {
  @Input() filterInputParametersNames: string[];
  @Input() filterSelectParametersNames: string[];
  @Input() set actionButtonTextKey(valueToSet: string) {
    if (valueToSet) {
      this.actionButtonText = valueToSet;
      this.translateService.get(valueToSet).subscribe((translation) => {
        this.actionButtonText = translation;
      }
      );
    }
  }
  @Input() set actionButtonTooltipKey(tooltipKeyValue: string) {
    this.actionButtonTooltip = undefined;
    if (tooltipKeyValue)
      this.translateService.get(tooltipKeyValue).subscribe((translation) => {
        this.actionButtonTooltip = translation;
      }
      );
  }

  @Input() filterSelectParametersValues: FilterSetting[];
  @Output() chosenParameterEvent: EventEmitter<FilterSetting> =
    new EventEmitter();
  @Output() actionButtonPressedEvent: EventEmitter<string> = new EventEmitter();

  @Input() predeterminedKey: string;
  @Input() predeterminedValue: string | number;
  @Input() loadedRecordCount: number = 0;
  @Input() loadedRecordCountLabel;
  @Input() loadedSubRecCount: number = 0;
  @Input() loadedSubRecCountLabel: string;
  @Input() tableName: string; // used to retrieve the recent IDs
  @Input() rangeNoneText: string;
  @Input() enabledCount = false;


  public filterForm: FormGroup;
  public canEditLanguage: boolean;
  public currentFormName: string;
  public actionButtonText: string;
  public actionButtonTooltip: string;
  public selectValues: { [name: string]: string | number } = {};
  public recentIds: IdAndDescription[] = [];
  public noneText = applicationConstants.noneText;
  public isLoadingCount = false;
  public totalEntries: number;
  public totalEntriesTitle: string | number = 'Message';


  constructor(protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService,
    private rangeService: RangeService,
    private dialog: MatDialog) {
    super(translateService, localStorageHelper, sessionService);
  }

  public ngOnInit(): void {
    this.initFromSessionRefresh();
    this.subscribeToSession();
  }

  ngOnDestroy(): void {
    super.unsubscribeFromSessionIfSubscribed();
  }

  ngOnChanges(): void {
    this.createFilterForm();
  }

  initThisSubtypeFromSession(): void {
    if (this.tableName) {
      this.recentIds = this.sessionService.getRecentIds(this.tableName);
      this.canEditLanguage = this.sessionService.canEditLanguage();
      this.applyFilter();
    }
  }

  public handleActionButton(): void {
    this.actionButtonPressedEvent.emit(this.actionButtonTooltip);
  }

  private createFilterForm(): void {
    if (this.filterInputParametersNames) {
      const formControlsObject = {};
      for (let i = 0; i < this.filterInputParametersNames.length; i++) {
        const formControlName = this.filterInputParametersNames[i];
        formControlsObject[formControlName] = new FormControl();
      }
      /* there is an Angular issue https://github.com/angular/material2/issues/10214*/
      /* for (let i = 0; i < this.filterSelectParametersNames.length; i++) {
       const formControlName = this.filterSelectParametersNames[i];
       formControlsObject[formControlName] = new FormControl('', []);
       }*/
      this.filterForm = new FormGroup(formControlsObject);
    }

    if (this.filterSelectParametersNames) {
      for (let i = 0; i < this.filterSelectParametersNames.length; i++) {
        const formControlName = this.filterSelectParametersNames[i];
        this.selectValues[formControlName] = '';
      }

      if (this.predeterminedKey && this.predeterminedValue) {
        this.currentFormName = this.predeterminedKey;
        this.resetAllFieldsExceptCurrent(this.predeterminedValue);
      }
    }
  }

  public getTextFieldWidth(formControlName: string): number {
    if (formControlName === 'id')
      return 100;
    else
      return 300;
  }

  private findRangeDescription(id: string): string {
    if (this.currentFormName !== 'range') return undefined;
    for (let rangeItem of this.filterSelectParametersValues)
      if (rangeItem.key === id) return rangeItem.value.toString();
    return undefined;
  }

  public applyFilter($event?: KeyboardEvent): void {
    if ($event && (<any>$event).isComposing)
      return;
    let filterValue = (this.filterForm) ? this.filterForm.value[this.currentFormName] : this.selectValues[this.currentFormName];
    filterValue = filterValue ? filterValue : this.selectValues[this.currentFormName];
    let description: string = this.findRangeDescription(filterValue);
    this.chosenParameterEvent.emit({
      key: this.currentFormName,
      value: filterValue,
      description: description
    });
  }

  public filterById(evt: Event): void {
    evt.stopPropagation();
    let idToFilterWith = (<HTMLElement>evt.target).innerHTML;
    this.currentFormName = "id";
    //this.filterForm.controls.id.value = idToFilterWith;
    this.filterForm.value.id = idToFilterWith;
    this.resetOtherFields("id");
    this.applyFilter();
    this.initFromSessionRefresh();
    //this.filterForm.value[this.currentFormName] = idToFilterWith;
    //this.applyFilter();
  }

  private resetAllFieldsExceptCurrent(currentValue: string | number): void {
    const currentNumericValue = parseInt(currentValue.toString(), 10);

    for (let i = 0; i < this.filterSelectParametersNames.length; i++) {
      const currentSelectName = this.filterSelectParametersNames[i];

      if (this.currentFormName === currentSelectName) {
        if (!isNaN(currentNumericValue)) {
          this.selectValues[currentSelectName] = currentNumericValue;
        } else {
          this.selectValues[currentSelectName] = currentValue;
        }
      } else {
        this.selectValues[currentSelectName] = '';
      }
    }

    if (this.filterForm && this.filterForm.controls) {
      if (this.filterForm.controls[this.currentFormName]) {
        const data = {};
        data[this.currentFormName] = currentValue;
        this.filterForm.reset(data);
      } else {
        this.filterForm.reset();
      }
    }
  }

  public saveCurrentFormName(currentFormName: string): void {
    const isCurrentValueEmpty = Boolean(this.selectValues[currentFormName] ||
      (this.filterForm && this.filterForm.value[currentFormName]));

    if (isCurrentValueEmpty) {
      this.currentFormName = currentFormName;
    } else {
      this.currentFormName = '';
    }
  }

  public resetOtherFields(currentFormName: string, $event?: KeyboardEvent): void {
    let currentValue: string | number;

    if ($event && (<any>$event).isComposing)
      return;

    this.saveCurrentFormName(currentFormName);

    if (this.selectValues[currentFormName]) {
      currentValue = this.selectValues[currentFormName];
    }
    if (this.filterForm && this.filterForm.value && this.filterForm.value[currentFormName]) {
      currentValue = this.filterForm.value[currentFormName];
    }
    if (!currentValue) {
      currentValue = '';
    }

    this.resetAllFieldsExceptCurrent(currentValue);

    if (!this.filterInputParametersNames) {
      this.applyFilter();
    }
  }

  public async countEntries(templateRef) {
    let filterValue = (this.filterForm) ? this.filterForm.value[this.currentFormName] : this.selectValues[this.currentFormName];
    filterValue = filterValue ? filterValue : this.selectValues[this.currentFormName];

    if (this.tableName && this.currentFormName && filterValue) {
      this.isLoadingCount = true;
      this.totalEntries = await this.rangeService.countEntries(this.tableName, this.currentFormName, filterValue);
      this.isLoadingCount = false;
      
      const selectedRange = this.filterSelectParametersValues.find(e => e.key == filterValue);
      this.totalEntriesTitle = selectedRange && selectedRange.value || filterValue;

      this.dialog.open(templateRef, {
        height: '200px',
        width: '400px',
      });
      
    }

  }

}
