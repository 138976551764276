import {
  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {SessionService} from '../../shared/services/session.service';
import {FamilyDefinition} from '../../shared/models/knowledge-graph';
import {FeatureListDefinition} from '../../shared/models/feature';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-knowledge-graph-element-language-specific-edit',
  templateUrl: './knowledge-graph-element-language-specific-edit.component.html',
  styleUrls: ['knowledge-graph-element-language-specific-edit.component.less']
})
export class KnowledgeGraphElementLanguageSpecificEditComponent extends Translated implements OnInit, OnChanges, OnDestroy {
  @Input() familyId: number;
  @Input() systemDescription: string;
  @Input() relevantGrammarFeatureList: FeatureListDefinition[];
  @Input() relevantSemanticFeatureList: FeatureListDefinition[];
  @Input() localizedFamily: FamilyDefinition;
  @Output() familyChangesEvent: EventEmitter<FamilyDefinition> = new EventEmitter<FamilyDefinition>();

  public allowedToEdit: boolean = false;
  public familyForm: FormGroup;

  public dataChanged: boolean = false;

  constructor(protected translateService: TranslateService, 
              protected sessionService: SessionService,
              protected localStorageHelper: LocalStorageHelper,
              private formBuilder: FormBuilder) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.initFromSession();
  }

  initThisSubtypeFromSession(): void {
    this.allowedToEdit = this.sessionService.canEditNonLexicon();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.familyId && simpleChanges.familyId.firstChange) {
      if (this.localizedFamily) {
        this.createForm();
      }
    }
  }

  ngOnDestroy(): void {
  }

  private createForm(): void {
    //default false
    this.localizedFamily.complement = this.localizedFamily.id ? this.localizedFamily.complement : false;
    
    this.familyForm = this.formBuilder.group({
      definition: [this.localizedFamily.definition],
      description: [this.localizedFamily.description],
      familyId: [this.familyId],
      systemDescription: [this.systemDescription],
      complementFallback: [this.localizedFamily.complement]
    });
  }

  public handleChange(): void {
    this.dataChanged = true;
    this.familyChangesEvent.next(this.localizedFamily);
  }

}
