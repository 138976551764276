<div class="ti-form__one-line-fields ti-form__one-line-fields--error">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
</div>

<div class="ti-menu-wrapper ti-form__column-fields search-results"  
    infiniteScroll
    [scrollWindow]="false"
    ngx-infinite-scroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    (scrolled)="loadMore()">
  <h1>{{ 'headers.list-of-families' | translate }}</h1>
  <app-universal-id-text-range-filter
    [currentRange]="'families'"
    [enabledCount]="true"
    [loadedRecordCount]="knowledgeGraphList ? knowledgeGraphList.length : 0">
  </app-universal-id-text-range-filter>
  <div class="ti-form__one-line-fields">
    <mat-checkbox
      matInput
      name="knowledgeGraphBasicParameter"
      (change)="filterWithChangedBasicMode()"
      [(ngModel)]="isKnowledgeGraphModeBasic">
      <span class="ti-form__label">{{"knowledge-graph.show-in-basic-mode" | translate}}</span>
    </mat-checkbox>
    <mat-checkbox
      matInput
      name="isNoFullRefreshParameter"
      (change)="changeNoFullRefreshStatus($event)"
      [(ngModel)]="isNoFullRefresh">
      <span class="ti-form__label">{{"lexicon.no-full-refresh" | translate}}</span>
    </mat-checkbox>
  </div>
  <!-- refine filter -->
  <div *ngIf="knowledgeGraphList && knowledgeGraphList.length > 0" class="ti-form__filter">
    <mat-form-field color="accent" class="ti-input__feature-description">
      <input matInput [(ngModel)]="searchStr" (keyup)="refinedFilter(searchStr)"
        [placeholder]="'knowledge-graph.filter-by-feature-description' | translate" />
    </mat-form-field>
    <button mat-mini-fab class="ti-button__reset-selection" color="primary" [disabled]="!searchStr"
      (click)="searchStr = ''; refinedFilter('');" [matTooltip]="'inflection-table.reset' | translate">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <div class="ti-form__one-line-fields">
    <mat-progress-spinner *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="ti-form__one-line-fields ti-form__button-panel">
    <button mat-mini-fab color="primary" [routerLink]="'/knowledge-graph/family-create'">
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </div>
  <mat-accordion
    *ngIf="showingKowledgeGraphList"
    multi="true"    
    >
    <mat-expansion-panel
      *ngFor="let knowledgeGraphElement of showingKowledgeGraphList; let knowledgeGraphElementIndex=index"
      appScrollToChangedPreviouslyElement
      (click)="saveTheCurrentId(knowledgeGraphElement.id)"
      [expanded]="(showingElementId === knowledgeGraphElement.id) || showingKowledgeGraphList.length === 1"
      (opened)="expandedElementId = knowledgeGraphElement.id">
      <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title
          [ngClass]="{
            'ti-red-text': !(knowledgeGraphElement.lexemes && knowledgeGraphElement.lexemes.length > 0 || knowledgeGraphElement.phrases && knowledgeGraphElement.phrases.length > 0),
            'ti-pink-tex': noLexemeInCurrentLang(knowledgeGraphElement)
          }"
          matTooltip="{{'[' + knowledgeGraphElement.id +'] ' + (knowledgeGraphElement.lexemeList ||
          knowledgeGraphElement.description || knowledgeGraphElement.definition)}}">
          <b *ngIf="knowledgeGraphElement.phraseTag">{{knowledgeGraphElement.phraseTag}}&nbsp;</b>
          <span
            *ngIf="knowledgeGraphElement.representativeLemma">{{shortenedDisplay(knowledgeGraphElement.representativeLemma, 60)}}&nbsp;</span>
          <span *ngIf="!knowledgeGraphElement.representativeLemma">{{"COMMON.family" | translate}} #{{knowledgeGraphElement.id}}&nbsp;</span>
        </mat-panel-title>
        <mat-panel-description [matTooltip]="getPanelTooltip(knowledgeGraphElement)" matTooltipClass="ti-tooltip__definition"
          class="ti-panel__description">
          <span *ngIf="knowledgeGraphElement.definition">{{shortenedDisplay(knowledgeGraphElement.definition, 100)}}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-knowledge-graph-table-element
          *ngIf="expandedElementId === knowledgeGraphElement.id"
          [isKnowledgeGraphModeBasic]="isKnowledgeGraphModeBasic"
          [knowledgeGraphElement]="knowledgeGraphElement"
          (errorMessageEventEmitter)="showErrorMessage($event)"
          (familiesIdsCommaDelimitedListForGoToEvent)="showGoToButtonWithFamiliesIdsCommaDelimitedList($event)"
          (familiesIdsCommaDelimitedListToUpdateEvent)="updateFamilies($event)"
          (currentFamilyUpdatedEvent)="reactOnUpdatedFamily($event)"
          (lexemeUpdate)="lexemeListUpdated($event)"
        ></app-knowledge-graph-table-element>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
