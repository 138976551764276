<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{'lexicon.lexeme-merge' | translate}}</h2>
</div>
<mat-dialog-content>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <app-lexeme-merge
    *ngIf="duplicatesIds"
    [currentLexeme]="currentLexeme"
    [duplicatesIds]="duplicatesIds"
    [mergeLexemesObservable]="mergeLexemesObservable"
    (mergeResultEvent)="reactOnTheMergeResult($event)">
  </app-lexeme-merge>
</mat-dialog-content>
<mat-dialog-actions class="ti-mat-dialog-actions--space-between">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
  <button
    mat-raised-button
    color="accent" (click)="mergeLexemes()">{{'COMMON.merge' | translate}}
  </button>
</mat-dialog-actions>
