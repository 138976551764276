import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ScrollContentService {

  public offsetTopSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentOffsetTopSource: Observable<number> = this.offsetTopSource.asObservable();
  public scrollToTopSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public scrollToTopSourceObservable: Observable<boolean> = this.scrollToTopSource.asObservable();

  constructor() {
  }

  public scrollContentBy(offsetTop?: number) {
    const mainContentElement = document.querySelector('mat-sidenav-content');
    if (offsetTop) {
      let scrollByOffset: number = offsetTop;
      const halfOfScreenHeightAsAString = (mainContentElement.clientHeight / 2).toString();
      const halfOfScreenHeight = parseInt(halfOfScreenHeightAsAString, 10);
      scrollByOffset = scrollByOffset - halfOfScreenHeight;
      mainContentElement.scrollBy({top: scrollByOffset, left: 0, behavior: 'smooth'});
    }
  }

  public scrollContentToTop() {
    const mainContentElement = document.querySelector('mat-sidenav-content');
    mainContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
