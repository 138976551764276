import {Component, ViewChild, Input} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';

import {AdvancedCriteria, Hypernum} from '../../../shared/models/lexicon';
import {Family} from '../../../shared/models/knowledge-graph';
import { SessionService } from '../../../shared/services/session.service';

@Component({
  selector: 'app-hypernym-table',
  templateUrl: 'hypernym-table.component.html',
  styleUrls: ['hypernym-table.component.less']
})
export class HypernymTableComponent {

  @Input() advancedCriteria: AdvancedCriteria;

  @ViewChild('hypernymsTable') hypernymsTable;

  public selection: SelectionModel<Hypernum> = new SelectionModel<Hypernum>(true, []);
  public familyCompabilityColumns: string[] = ['checked', 'id', 'word', 'definition'];
  public isGuest;

  constructor(public sessionService: SessionService) {
    this.isGuest = this.sessionService.isGuest();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.advancedCriteria.hypernyms.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.advancedCriteria.hypernyms.forEach(row => this.selection.select(row));
  }

  public removeHypernymItems(confirm: boolean): void {
    if (confirm) {
      const ids = [];
      for (let i = 0; i < this.selection.selected.length; i++) {
        ids.push(this.selection.selected[i].id);
      }
      this.advancedCriteria.hypernyms = this.advancedCriteria.hypernyms.filter(hypernym => !ids.includes(hypernym.id));
      this.selection.clear();
    }
  }

  public addHypernum(event: { commaDelimitedListOfFamilyIds: string, family: Family }): void {
    console.log(event);
    if (event) {
      this.advancedCriteria.hypernyms.push(event.family);
      this.hypernymsTable.renderRows();
    }
  }

  public doesUserClickButton(): boolean {
    return this.selection.selected.length === 0;
  }

  public doesUserSeeButton(): boolean {
    return !this.isGuest;
  }

}
