<app-universal-filter
  *ngIf="filterSelectParametersNames && filterSelectParametersValues"
  [filterSelectParametersNames]="filterSelectParametersNames"
  [filterSelectParametersValues]="filterSelectParametersValues"
  (chosenParameterEvent)="reactOnChosenParameterEvent($event)"
  
  [rangeNoneText]="rangeNoneText"
  ></app-universal-filter>
  
  <!-- [predeterminedKey]="predeterminedKey"
  [predeterminedValue]="predeterminedValue" -->
