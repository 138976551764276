<div class="ti-dialog__wrapper">
    <mat-dialog-content>
      <h1>{{ 'lexicon.lexemes-selection' | translate }}</h1>
      <app-root-lookup
        (selectItemEvent)="reactOnSelectItemEvent($event);"
      ></app-root-lookup>
    </mat-dialog-content>
    <mat-dialog-actions class="ti-actions">
      <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
      <button mat-raised-button color="accent" [mat-dialog-close]="selectedRoot">{{'COMMON.select' | translate}}</button>
    </mat-dialog-actions>
</div>

