import {Injectable} from '@angular/core';

import {PhraseInTheEditView} from '../models/phrases.model';
import {LocalStorageHelper} from '../helpers/localhost.helper';
import {CommonSense} from '../models/common-cues.model';
import {Clause} from '../models/clause.model';

@Injectable()
export class CacheService {
  private cacheKey = 'cacheKey';

  constructor(private localStorageHelper: LocalStorageHelper) {
  }

  private cachedPhrase: PhraseInTheEditView;
  private cachedData: any;
  private cachedPhraseElements: Clause[];

  public cachePhrase(phrase: PhraseInTheEditView): void {
    this.cachedPhrase = phrase;
    this.localStorageHelper.setTabSetting('cachedPhrase', JSON.stringify(phrase));
  }

  public clearCachedPhrase(): void {
    this.cachedPhrase = null;
    this.localStorageHelper.clearTabSetting('cachedPhrase');
  }

  public getCachedPhrase(): PhraseInTheEditView {
    const phrase = this.localStorageHelper.getTabSetting('cachedPhrase');
    if (phrase && (phrase !== 'undefined')) {
      this.cachedPhrase = JSON.parse(phrase) as PhraseInTheEditView;
    } else {
      this.cachedPhrase = null;
    }

    return this.cachedPhrase;
  }

  public cacheElements(phraseElements: Clause[]): void {
    this.cachedPhraseElements = phraseElements;
    this.localStorageHelper.setTabSetting('cachedElements', JSON.stringify(phraseElements));
  }

  public clearCachedElements(): void {
    this.cachedPhraseElements = null;
    this.localStorageHelper.clearTabSetting('cachedElements');
  }

  public getCachedElements(): Clause[] {
    const cachedPhraseElements = this.localStorageHelper.getTabSetting('cachedElements');
    if (cachedPhraseElements && (cachedPhraseElements !== 'undefined')) {
      this.cachedPhraseElements = JSON.parse(cachedPhraseElements) as Clause[];
    } else {
      this.cachedPhraseElements = null;
    }

    return this.cachedPhraseElements;
  }

  public cacheData(data: any): void {
    this.cachedData = data;
    this.localStorageHelper.setTabSetting(this.cacheKey, JSON.stringify(data));
  }

  public getCachedData(): any {
    const cachedData = this.localStorageHelper.getTabSetting(this.cacheKey);
    if (cachedData && (cachedData !== 'undefined')) {
      this.cachedData = JSON.parse(cachedData) as CommonSense;
    } else {
      this.cachedData = null;
    }

    return this.cachedData;
  }

  public clearCachedData(): void {
    this.cachedData = null;
    this.localStorageHelper.clearTabSetting('cachedElements');
    this.localStorageHelper.clearTabSetting(this.cacheKey);
  }
}
