import {Injectable} from '@angular/core';


import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import {LampUpdateResponse} from '../models/common';
import {ExtraHypothesis} from '../models/extra-hypothesis';

@Injectable()
export class ExtraHypothesisService {

  constructor(private apiClientService: ApiClientService) {}

  public getExtraHypothesisList(): Promise<ExtraHypothesis[]> {
    return this.apiClientService.get(EndpointUrls.extraHypothesis.extrahypothesisList);
  }

  public createExtraHypothesis(extraHypothesis: ExtraHypothesis): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.extraHypothesis.extrahypothesis, extraHypothesis);
  }

  public updateExtraHypothesis(extraHypothesis: ExtraHypothesis): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.extraHypothesis.extrahypothesis, extraHypothesis);
  }

  public deleteExtraHypothesis(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.extraHypothesis.extrahypothesis}?id=${id}`);
  }

  public getExtraHypothesisById(id: number): Promise<ExtraHypothesis> {
    return this.apiClientService.get(`${EndpointUrls.extraHypothesis.extrahypothesis}?id=${id}`);
  }

}
