import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {Lexeme} from '../../shared/models/lexicon';
import {LampUpdateResponse} from '../../shared/models/common';

@Component({
  selector: 'app-lexeme-merge-dialog',
  templateUrl: './lexeme-merge-dialog.component.html'
})
export class LexemeMergeDialogComponent {
  public duplicatesIds: string;
  public currentLexeme: Lexeme;
  public mergeLexemesBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mergeLexemesObservable: Observable<boolean> = this.mergeLexemesBehaviorSubject.asObservable();

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: { duplicatesIds: string, currentLexeme: Lexeme },
              private dialogRef: MatDialogRef<LexemeMergeDialogComponent>) {
    this.duplicatesIds = data.duplicatesIds;
    this.currentLexeme = data.currentLexeme;
  }

  public mergeLexemes(): void {
    this.mergeLexemesBehaviorSubject.next(true);
  }

  public reactOnTheMergeResult(successfulResponseMessage: LampUpdateResponse): void {
    if (successfulResponseMessage.success) {
      this.dialogRef.close();
    } else {
      this.errorMessageBehaviorSubject.next(successfulResponseMessage.error);
    }
  }

}
