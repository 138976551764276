import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,
  SimpleChanges, ChangeDetectionStrategy
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';


import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

import {ExtraHypothesis} from '../../../shared/models/extra-hypothesis';
import {Interfix} from '../../../shared/models/interfixes';
import {AdvancedCriteria} from '../../../shared/models/lexicon';
import {FeatureListDefinition, FeatureValue} from '../../../shared/models/feature';

import {SessionService} from '../../../shared/services/session.service';
import {LexiconService} from '../../../shared/services/lexicon.service';
import {FeaturesService} from '../../../shared/services/features.service';
import {collectObjectChanges, toJsonObject} from '../../../shared/helpers/object.helper';
import {RouterHelper} from '../../../shared/helpers/router.helper';

import {applicationConstants, applicationModulesRoutePaths} from '../../../shared/constants/application-constants';
import { Translated } from '../../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-interfix-and-hypothesis-edit',
  templateUrl: './interfix-and-hypothesis-edit.component.html',
  styleUrls: ['./interfix-and-hypothesis-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterfixAndHypothesisEditComponent extends Translated implements OnInit, OnDestroy, OnChanges {
  @Input() public isHypothesis: boolean;
  @Input() public showProgressBar: boolean;
  @Input() public errorMessageSource: Observable<string>;
  @Input() public element: ExtraHypothesis | Interfix;

  @Output() changedElementIdEvent: EventEmitter<number> = new EventEmitter();
  @Output() saveElementEvent: EventEmitter<{}> = new EventEmitter();

  public subscriptions: Subscription[] = [];
  public doesUserHaveAnAccessToEdit: boolean;
  public grammarFeatureList: FeatureListDefinition[];
  public styleFeatureList: FeatureListDefinition[];
  public semanticFeatureList: FeatureListDefinition[];
  public updatedElement: ExtraHypothesis | Interfix;
  public form: FormGroup;

  public elementId: number;
  public isEdit: boolean;
  public doesTheWholeFeatureListRelevant: boolean;

  public featuresKeysUsedInElement = ['requiredGrammar', 'requiredStyle', 'requiredSemantics',
    'assignedGrammar', 'assignedStyle', 'assignedSemantics',
    'incompatibleGrammar', 'incompatibleStyle', 'incompatibleSemantics'];

  private shortFeaturesKeys = ['required', 'assigned', 'incompatible'];

  constructor(protected translateService: TranslateService,
              protected sessionService: SessionService,
              protected localStorageHelper: LocalStorageHelper,
              private route: ActivatedRoute,
              private lexiconService: LexiconService,
              private featuresService: FeaturesService,
              private formBuilder: FormBuilder,
              private routerHelper: RouterHelper,
              private changeDetectionRef: ChangeDetectorRef) {
    super(translateService, localStorageHelper, sessionService);
  }

  initThisSubtypeFromSession(): void {
    this.doesUserHaveAnAccessToEdit = this.sessionService.canEditNonLexicon();
  }

  ngOnInit(): void {
    this.initFromSession();
    this.getFeatures();
    this.subscribeOnRouteChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.element && simpleChanges.element.currentValue) {
      if (this.isHypothesis) {
        this.updatedElement = toJsonObject(simpleChanges.element.currentValue) as ExtraHypothesis;
        this.form = this.formBuilder.group({
          notes: [this.updatedElement.note]
        });
      } else {
        this.updatedElement = toJsonObject(simpleChanges.element.currentValue) as Interfix;

        this.form = this.formBuilder.group({
          charactersToDelete: [this.updatedElement.charactersToDelete],
          notes: [this.updatedElement.note]
        });
      }

      this.setFeaturesToCorrectDisplay(simpleChanges.element.currentValue);
    }
  }

  private getFeatures(): Promise<void> {
    return this.featuresService.getFeatureList()
      .then((grammarFeatureList: FeatureListDefinition[]) => {
        this.grammarFeatureList = grammarFeatureList;
        return this.featuresService.getFeatureList(applicationConstants.styleFeatureType)
          .then((styleFeatureList: FeatureListDefinition[]) => {
            this.styleFeatureList = styleFeatureList;
            return this.featuresService.getFeatureList(applicationConstants.semanticFeature.type)
              .then((semanticFeatures: FeatureListDefinition[]) => {
                this.semanticFeatureList = semanticFeatures;
              });
          });
      });
  }

  private subscribeOnRouteChanges(): void {
    this.subscriptions.push(this.route.data.subscribe((data: { id: number }) => {
      this.isEdit = Boolean(data.id);
      this.elementId = data.id;

      this.initialize();
    }));
  }

  private initialize(): void {
    this.changedElementIdEvent.next(this.elementId);
  }

  public reactOnUpdateAdvancedCriteria(advancedCriteria: AdvancedCriteria | boolean, isFollowingWordAdvancedCriteria = false) {
    if (typeof advancedCriteria === 'object') {
      this.getAdvancedCriteria(advancedCriteria.id, isFollowingWordAdvancedCriteria);
    } else {
      this.getAdvancedCriteria(this.updatedElement.advancedCriteriaId, isFollowingWordAdvancedCriteria);
    }
  }

  private getAdvancedCriteria(advancedCriteriaId: number, isFollowingWordAdvancedCriteria: boolean): Promise<void> {
    return this.lexiconService.getAdvancedCriteriaById(advancedCriteriaId)
      .then((updatedAdvancedCriteria: AdvancedCriteria) => {
        this.setAdvancedCriteriaDataToForm(updatedAdvancedCriteria, isFollowingWordAdvancedCriteria);
      });
  }

  private setAdvancedCriteriaDataToForm(updatedAdvancedCriteria: AdvancedCriteria, isFollowingWordAdvancedCriteria: boolean): void {
    if (isFollowingWordAdvancedCriteria) {
      this.updatedElement.followingWordAdvancedCriteriaDescription = updatedAdvancedCriteria.description;
      this.updatedElement.followingWordAdvancedCriteriaId = updatedAdvancedCriteria.id;
      this.changeDetectionRef.markForCheck();
    } else {
      this.updatedElement.advancedCriteriaDescription = updatedAdvancedCriteria.description;
      this.updatedElement.advancedCriteriaId = updatedAdvancedCriteria.id;
      this.setFeaturesFromAdvancedCriteriaToCorrectDisplay(updatedAdvancedCriteria);
    }
  }

  private setFeaturesFromAdvancedCriteriaToCorrectDisplay(element: AdvancedCriteria): void {
    this.featuresKeysUsedInElement.forEach((key) => {
      this.updatedElement[key] = element[key];
    });
    this.changeDetectionRef.markForCheck();
  }

  private setFeaturesToCorrectDisplay(element: AdvancedCriteria): void {
    this.shortFeaturesKeys.forEach((key) => {
      if (Object(element).hasOwnProperty(key)) {
        element[key].forEach((feature: FeatureValue) => {
          const type = (feature.type) ? (feature.type) : 'Grammar';
          if (!this.updatedElement[key + type]) {
            this.updatedElement[key + type] = [];
          }
          this.updatedElement[key + type].push(feature);

        });
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  private removeFeaturesFromChanges(changes: ExtraHypothesis | Interfix): ExtraHypothesis | Interfix {
    this.featuresKeysUsedInElement.forEach((key) => {
      if (Object(changes).hasOwnProperty(key)) {
        delete changes[key];
      }
    });
    return changes;
  }

  public save(): void {
    this.showProgressBar = true;
    let changes = collectObjectChanges(this.element, this.updatedElement) as ExtraHypothesis | Interfix;
    changes = this.removeFeaturesFromChanges(changes);
    if (changes && Object.keys(changes).length) {
      if (this.elementId) {
        changes['id'] = this.element.id;
      }
      this.saveElementEvent.next(changes);
    } else {
      this.back();
    }
  }

  public back(): void {
    const url = applicationModulesRoutePaths.morphology.morphology.path + applicationModulesRoutePaths.morphology['extra-hypothesis generation'].path;
    this.routerHelper.navigateToPage(url);
  }
}
