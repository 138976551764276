import {Injectable} from '@angular/core';

import {applicationConstants} from '../constants/application-constants';

@Injectable()
export class CookieHelper {
  constructor() {
  }

  public clearSession(key: string): void {
    document.cookie = key + applicationConstants.clear_session_data;
  }

  public setCookie(key: string, data: string, expires: string): void {
    document.cookie = key + '=' + data + '; path=/; expires=' + expires;
  }


  public getCookie(key: string): string {
    const cookie = document.cookie;

    const dataCookie = cookie.match(new RegExp(
      '(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));

    if (dataCookie) {
      const data = decodeURIComponent(dataCookie[1]);
      return data ? data : '';
    }

    return '';
  }
}
