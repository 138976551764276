export function filter(obj: any, predicate: Function): any {
  const objectKeys = Object.keys(obj);
  if (objectKeys && objectKeys.length) {
    return objectKeys
      .filter(key => predicate(obj[key]))
      .reduce((result, key) => Object.assign(result, {[key]: obj[key]}), {});
  } else {
    return [];
  }
}

export function toJsonObject(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function createFlatArrayCopy(array: any): any {
  if (array && array.length) {
    return array.map(element => Object.assign({}, element));
  } else {
    return [];
  }
}

export function isArray(possibleArray: any): boolean {
  return (possibleArray instanceof Array);
}

export function areTwoObjectsIdentical(object1: object, object2: object): boolean {
  return (JSON.stringify(object1) === JSON.stringify(object2));
}

export function collectObjectChanges(initialObject: object, changedObject: object): object {
  const objectsDifference: object = {};

  if (!areTwoObjectsIdentical(initialObject, changedObject)) {
    const keys = Object.keys(changedObject);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (typeof initialObject[key] === 'object') {
        if (!areTwoObjectsIdentical(initialObject[key], changedObject[key])) {
          objectsDifference[key] = changedObject[key];
        }
      } else {
        if (initialObject[key] !== changedObject[key] || key === 'requestId') {
          objectsDifference[key] = changedObject[key];
        }
      }
    }
  }
  return objectsDifference;
}

export function getArrayWithoutDuplicates(array: any[]): any[] {
  return array.sort().filter(function(item, position, array2) {
    return (!position || item !== array2[position - 1]);
  });
}
