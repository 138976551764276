<div class="ti-ac__wrapper">
  <h1 *ngIf="isNotDialog">{{'advanced-criteria.advanced-criteria-edit' | translate}}</h1>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <mat-progress-bar *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-bar>
  <div *ngIf="advancedCriteria">
    <div *ngIf="isEdit" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'COMMON.id' | translate }}</div>
      <div class="ti-ac__id-value">{{ advancedCriteria.id }}</div>
    </div>
    <form *ngIf="form" [formGroup]="form">
      <div class="ti-ac__description">
        <mat-form-field color="accent">
          <input
            matInput
            name="description"
            formControlName="description"
            placeholder="{{ 'COMMON.description' | translate}}"
            [(ngModel)]="advancedCriteria.description"
            appDisableControl
            [disableControl]="isGuest"
          >
        </mat-form-field>
      </div>
      <div class="ti-ac__title ti-form__label">{{ 'advanced-criteria.phonetic-compability' | translate }}</div>
      <mat-radio-group
        formControlName="phonemesMustMatch"
        [(ngModel)]="advancedCriteria.phonemesMustMatch"
        [name]="'phonetic-compability'"
        [disabled]="isGuest"
      >
        <mat-radio-button class="ti-ac__radio-button" [value]="true">{{ 'COMMON.must-match' | translate}}
        </mat-radio-button>
        <mat-radio-button class="ti-ac__radio-button" [value]="false">{{ 'COMMON.must-not-match' | translate}}
        </mat-radio-button>

      </mat-radio-group>
      <app-phonetic-compatibilities-table [advancedCriteria]="advancedCriteria"></app-phonetic-compatibilities-table>
      <div class="ti-ac__title ti-form__label">{{ 'advanced-criteria.family-hypernym-compability' | translate }}</div>
      <mat-radio-group
        formControlName="hypernymsMustMatch"
        [(ngModel)]="advancedCriteria.hypernymsMustMatch"
        [name]="'hypernyms'"
        [disabled]="isGuest"
      >
        <mat-radio-button class="ti-ac__radio-button" [value]="true">{{ 'COMMON.must-match' | translate}}
        </mat-radio-button>
        <mat-radio-button class="ti-ac__radio-button" [value]="false">{{ 'COMMON.must-not-match' | translate}}
        </mat-radio-button>
      </mat-radio-group>
      <app-hypernym-table [advancedCriteria]="advancedCriteria"></app-hypernym-table>
      <div class="ti-ac__title ti-form__label">{{ 'COMMON.required-phrases' | translate }}</div>
      <div class="ti-ac__checkbox-wrapper">
        <mat-checkbox
          formControlName="requiredPhrasesChecked"
          [(ngModel)]="advancedCriteria.clearRequiredPhrases"
          [disabled]="isGuest"
        ></mat-checkbox>
        <div class="ti-ac__checkbox-label ti-form__label">{{ 'advanced-criteria.clear-required-phrases' | translate }}
        </div>
      </div>
      <app-required-phrases-table [advancedCriteria]="advancedCriteria"></app-required-phrases-table>
      <div *ngIf="!forWords">
        <h3>{{ 'COMMON.required-features' | translate }}</h3>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.requiredGrammar"
              [doesTheWholeFeatureListRelevant]="showAllGrammarFeatures"
              [relevantFeatureList]="grammarFeatureList"
              [isEmptyValueRelevant]="true"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.requiredSemantics"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'lexicon.style' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.requiredStyle"
              [doesTheWholeFeatureListRelevant]="true"
              [isEmptyValueRelevant]="false"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <h3>{{ 'COMMON.assigned-features' | translate }}</h3>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.assignedGrammar"
              [doesTheWholeFeatureListRelevant]="showAllGrammarFeatures"
              [isEmptyValueRelevant]="true"
              [relevantFeatureList]="grammarFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.assignedSemantics"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'lexicon.style' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.assignedStyle"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <h3>{{ 'COMMON.incompatible-features' | translate }}</h3>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.incompatibleGrammar"
              [doesTheWholeFeatureListRelevant]="showAllGrammarFeatures"
              [relevantFeatureList]="grammarFeatureList"
              [isOnlyOneFeature]="false"
              [isEmptyValueRelevant]="true"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.incompatibleSemantics"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
        <div class="ti-form__bordered-part">
          <h4 class="ti-ac__head">{{'lexicon.style' | translate}}</h4>
          <div class="ti-form-bordered-part__content" style="display: flex">
            <app-universal-features
              [featureValues]="advancedCriteria.incompatibleStyle"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="isGuest"
            ></app-universal-features>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="isEdit" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'advanced-criteria.legacy-reference-id' | translate }}</div>
      <div class="ti-ac__id-value">{{ advancedCriteria.legacyReferenceID }}</div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.lexemeIds" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'COMMON.lexemes' | translate }}</div>
      <div class="ti-ac__id-value">
        <ng-container *ngTemplateOutlet="idsTemplate;
            context: { arrayIds: lexemesList,
                 translationText: 'COMMON.lexeme',
                 routerLinkPart: '/lexicon/lexeme/edit/'}">
        </ng-container>
      </div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.lexemeFamilyIds && advancedCriteria.lexemeFamilyIds.length" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'headers.lexeme-families' | translate }}</div>
      <div class="ti-ac__id-value">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start">
          <a *ngFor="let item of lexemeFamilyIds" target="_blank"
            [href]="'/lexicon/lexeme-family-connection/edit/' + item.lexemeId + '/' + item.familyId + '?editedId=' + item.lexemeId + '&editedValue=' + item.familyId">
            Lexeme {{ item.lexemeId }} - Family {{ item.familyId }}
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.extrahypothesisIds" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'extra-hypothesis.extra-hypothesis' | translate }}</div>
      <div class="ti-ac__id-value">

        <ng-container *ngTemplateOutlet="idsTemplate;
            context: { arrayIds: extrahypothesisList,
                 translationText: 'extra-hypothesis.extra-hypothesis',
                 routerLinkPart: '/morphology/extra-hypothesis-generation/#'}">
        </ng-container>
      </div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.interfixIds" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'interfix.interfixes' | translate }}</div>
      <div class="ti-ac__id-value">
        <ng-container *ngTemplateOutlet="idsTemplate;
            context: { arrayIds: interfixList,
                 translationText: 'interfix.interfix',
                 routerLinkPart: '/morphology/interfixes/#'}">
        </ng-container>
      </div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.cliticIds" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'COMMON.clitics' | translate }}</div>
      <div class="ti-ac__id-value">
        <ng-container *ngTemplateOutlet="idsTemplate;
            context: { arrayIds: cliticList,
                 translationText: 'COMMON.clitic',
                 routerLinkPart: '/morphology/clitics/#'}">
        </ng-container>
      </div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.taggingAffixIds" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'COMMON.tagging-affixes' | translate }}</div>
      <div class="ti-ac__id-value">
        <ng-container *ngTemplateOutlet="idsTemplate;
            context: { arrayIds: taggingAffixList,
                 translationText: 'COMMON.tagging-affix',
                 routerLinkPart: '/morphology/affixes/tagging?key=id&value='}">
        </ng-container>
      </div>
    </div>
    <div *ngIf="isEdit && advancedCriteria.inflectionAffixIds" class="ti-ac__id">
      <div class="ti-ac__id-title ti-form__label">{{ 'COMMON.inflection-affixes' | translate }}</div>
      <div class="ti-ac__id-value">
        <ng-container *ngTemplateOutlet="idsTemplate;
            context: { arrayIds: inflectionAffixList,
                 translationText: 'COMMON.inflection-affix',
                 routerLinkPart: '/morphology/affixes/inflection?key=id&value='}">
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="isNotDialog" class="ti-form__button-panel">
    <button mat-raised-button routerLink="/welcome">{{'COMMON.cancel' | translate}}</button>
    <button
      mat-raised-button
      color="accent"
      (click)="saveAdvancedCriteria()"
      [disabled]="isGuest"
    >{{'COMMON.save' | translate}}</button>
  </div>
</div>

<ng-template
  #idsTemplate
  let-arrayIds="arrayIds"
  let-translationText="translationText"
  let-routerLinkPart="routerLinkPart"
>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start">
    <a *ngFor="let id of arrayIds" target="_blank" href="{{routerLinkPart}}{{id}}">{{translationText | translate}} {{id}}</a>
  </div>
</ng-template>
