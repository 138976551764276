import {Family} from './knowledge-graph';
import {FeatureValue, ModelWithRelevantFeature} from './feature';

export interface Lexeme extends ModelWithRelevantFeature {
  dictionaryTag?: string;
  families?: Family[];
  id?: number;
  inflections?: string;
  lemma?: string;
  sameAsLemma?: string;
  features?: string;
  warn?: boolean;
  duplicateWarning?: boolean;
  hasSameAs?: boolean;
  sameAsList?: string;
  advancedCriteriaDescription?: string;
  duplicateIds?: string; // A list of IDs for the duplicate lexemes. Comma-delimited list
  translationUrl?: string;
  linkedFamilyIds?: string;
  clitic?: number;
  root?: number;
  sameFamiliesAs?: number;
  unverified?: number | 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle';
}

export interface AdvancedLexeme extends ModelWithRelevantFeature {
  id?: number;
  lemma?: string;
  stem?: string;
  note?: string;
  frequency?: number; // A number in range 0 through 10, or an empty string.
  advancedCriteriaId?: number;
  advancedCriteriaDescription?: string;
  invariable?: boolean;
  unverified?: number | 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle';
  legacyInflectionMapId?: string;
  earliestMention?: string;
  lastYearActive?: number;
  sameInflectionsAs?: number;
  sameStyleAs?: number;
  inflectingSegment?: number;
  agreeingSegment1st?: number;
  agreeingSegmentLast?: number;
  root?: number;	// Root	Root 1 ID.
  root2?: number;	 // Root 2	Root 2 ID.
  root3?: number; // Root 3	Root 3 ID.
  root4?: number; // Root 4	Root 4 ID.
  mweBehavior?: string; // MWE behavior	MWE behavior. Values: wholeOnly / flexible / default.
  grammar?: FeatureValue[];
  style?: FeatureValue[];
  semantics?: FeatureValue[];
  sameFamiliesAs?: number;
  rootDescription?: string;
  root2Description?: string;
  root3Description?: string;
  root4Description?: string;
  tagging?: any;
  sourceType?: string;
  sourceId?: string;
  createdBy?: string;
  created?: string;
  lastUpdatedBy?: string;
  lastUpdate?: string;
  lastUpdatedByBatch?: string;
  lastBatchUpdate?: string;
}

export const mweBehaviorOptions = [
  {key: 'default', value: 'Default'},
  {key: 'wholeOnly', value: 'Single lexeme only'},
  {key: 'flexible', value: 'Flexible'}
];


export const emptyAdvancedLexeme: AdvancedLexeme = {
  lemma: '',
  stem: '',
  note: '',
  frequency: 0,
  advancedCriteriaId: 0,
  advancedCriteriaDescription: '',
  invariable: false,
  unverified: 0,
  legacyInflectionMapId: '',
  earliestMention: '',
  lastYearActive: 0,
  sameFamiliesAs: 0,
  mweBehavior: 'default',
  grammar: [],
  style: [],
  semantics: []
};

export interface LexemeFamilyConnection extends ModelWithRelevantFeature {
  id?: number;
  lexemeId?: number;
  lemma?: string;
  familyId?: number;
  familyDescription?: string;
  legacyMapId?: string | number;
  discouraged?: boolean;
  frequency?: number; // from 1 thru 10
  advancedCriteriaId?: number;
  advancedCriteriaDescription?: string;
  style?: FeatureValue[];
  grammar?: FeatureValue[];
  semantics?: FeatureValue[];
  unverified?: number;
}

export interface AdvancedCriteria extends ModelWithRelevantFeature {
  description?: string;
  hypernyms?: Hypernum[];
  id?: number;
  legacyReferenceID?: number | string;
  phoneticCompatibility?: PhoneticCompatibility[];
  requiredPhrases?: RequiredPhrase[];
  phonemesMustMatch?: boolean;
  hypernymsMustMatch?: boolean;
  clearRequiredPhrases?: boolean;
  requiredDescriptions?: string;
  assignedDescriptions?: string;
  incompatibleDescriptions?: string;
  requiredGrammar?: FeatureValue[];
  requiredSemantics?: FeatureValue[];
  requiredStyle?: FeatureValue[];
  assignedGrammar?: FeatureValue[];
  assignedSemantics?: FeatureValue[];
  assignedStyle?: FeatureValue[];
  incompatibleGrammar?: FeatureValue[];
  incompatibleSemantics?: FeatureValue[];
  incompatibleStyle?: FeatureValue[];
  assigned?: FeatureValue[];
  incompatible?: FeatureValue[];
  required?: FeatureValue[];
  lexemeIds?: string;
  extrahypothesisIds?: string;
  interfixIds?: string;
  cliticIds?: string;
  taggingAffixIds?: string;
  inflectionAffixIds?: string;
  lexemeFamilyIds?: { key: string; value: string }[];
  forWords?: boolean;
  languageId?: number;
}

export interface Root {
  id?: number;
  root?: string;
  familyId?: number;
  familyDescription?: string;
}

export const emptyRoot: Root = {
  root: '',
  familyId: 0,
  familyDescription: ''
}

export const emptyAdvancedCriteria: AdvancedCriteria = {
  description: '',
  hypernyms: [],
  legacyReferenceID: 0,
  phoneticCompatibility: [],
  requiredPhrases: [],
  phonemesMustMatch: false,
  hypernymsMustMatch: false,
  clearRequiredPhrases: false,
  requiredDescriptions: '',
  assignedDescriptions: '',
  incompatibleDescriptions: '',
  requiredGrammar: [],
  requiredSemantics: [],
  requiredStyle: [],
  assignedGrammar: [],
  assignedSemantics: [],
  assignedStyle: [],
  incompatibleGrammar: [],
  incompatibleSemantics: [],
  incompatibleStyle: [],
};

export interface RequiredPhrase {
  id?: number | string;
  description?: string;
  children?: boolean;
  warn?: boolean;
  definition?: string;
}

export interface Hypernum extends ModelWithRelevantFeature {
  id?: number | string;
  description?: string;
  definition?: string;
  children?: boolean;
}

export interface PhoneticCompatibility {
  type?: string;
  expression?: string;
  direction?: string;
  editVisibility?: boolean;
}

export const emptyPhoneticCompatibility: PhoneticCompatibility = {
  type: '',
  expression: '',
  direction: '',
  editVisibility: true
};

export const emptyLexemeFamilyConnection: LexemeFamilyConnection = {
  lexemeId: 0,
  lemma: '',
  familyId: 0,
  familyDescription: '',
  legacyMapId: 0,
  discouraged: false,
  frequency: 0,
  advancedCriteriaId: 0,
  advancedCriteriaDescription: '',
  style: [],
  grammar: [],
  semantics: [],
  unverified: 0
};
