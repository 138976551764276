<app-universal-filter
  *ngIf="filterInputParametersNames || (filterSelectParametersNames && filterSelectParametersValues)"
  [loadedRecordCount]="loadedRecordCount"
  [loadedRecordCountLabel]="loadedRecordCountLabel"
  [loadedSubRecCount]="loadedSubRecCount"
  [loadedSubRecCountLabel]="loadedSubRecCountLabel"
  [predeterminedKey]="predeterminedKey"
  [predeterminedValue]="predeterminedValue"
  [tableName]="tableName()"
  [filterInputParametersNames]="filterInputParametersNames"
  [filterSelectParametersNames]="filterSelectParametersNames"
  [filterSelectParametersValues]="filterSelectParametersValues"
  [actionButtonTextKey]="actionButtonTextKey"
  [enabledCount]="enabledCount"
  [actionButtonTooltipKey]="actionButtonTooltipKey"
  (actionButtonPressedEvent)="forwardActionButtonPressedEvent($event)"
  (chosenParameterEvent)="reactOnChosenParameterEvent($event)"
></app-universal-filter>

