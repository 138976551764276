import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';

import {FeatureListDefinition, FeatureValueDefinition} from '../../../shared/models/feature';

@Component({
  selector: 'app-universal-feature-selector-options',
  templateUrl: './universal-feature-selector-options.component.html',
  styleUrls: ['./universal-feature-selector-options.component.less']
})

export class UniversalFeatureSelectorOptionsComponent implements OnInit, OnChanges {
  @Input() relevantFeatureList: FeatureListDefinition[];
  @Input() doesTheWholeFeatureListRelevant: boolean;
  @Input() setDefaultValues: boolean;
  @Input() currentFeature: FeatureListDefinition;
  @Input() currentFeatureOptionId: string;
  @Input() isEmptyValueRelevant: boolean;
  @Input() disableSelect: boolean;

  public isFullFeatureValuesListShowed: boolean;
  public currentFeatureOptionDescription: string;
  public featureOptionId: string;

  @Output() featureChosenOptionEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearFeatureOptionEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const featureIsChangedCondition = (this.currentFeatureOptionId && changes.currentFeature &&
    changes.currentFeature && changes.previousValue &&
    (changes.currentFeature.currentValue.id !== changes.currentFeature.previousValue.id));

    const isFeatureFirstChangeCondition = !this.currentFeatureOptionId && changes.currentFeature &&
      changes.firstChange;

    if (featureIsChangedCondition || isFeatureFirstChangeCondition) {
      this.featureOptionId = '';
    }
    this.initialize();
  }

  public toggleFeatureValuesList(): void {
    this.isFullFeatureValuesListShowed = !this.isFullFeatureValuesListShowed;
  }

  public chooseCurrentFeatureOption(): void {
    if (this.featureOptionId) {
      setTimeout(() => {
        this.featureChosenOptionEvent.emit(this.featureOptionId);
      });

      if (this.currentFeature) {
        const currentFeatureOption = this.currentFeature.values
          .find(element => (element.id === this.featureOptionId));
        this.currentFeatureOptionDescription = (currentFeatureOption) ? currentFeatureOption.description : '';
      }
    } else {
      this.clearFeatureOptionEvent.emit(true);
    }
  }


  private initialize(): void {
    if (this.currentFeatureOptionId) {
      this.featureOptionId = this.currentFeatureOptionId;
    }
    if (this.setDefaultValues && this.currentFeature && !this.featureOptionId) {
      this.setDefault();
    }

    this.chooseCurrentFeatureOption();
  }

  private setDefault(): void {
    let currentFeatureOption: FeatureValueDefinition;

    if (this.doesTheWholeFeatureListRelevant || this.isFullFeatureValuesListShowed) {
      currentFeatureOption = this.currentFeature.values[0];
    } else {
      currentFeatureOption = this.currentFeature.values.find(element => element.relevant);
    }

    if (!currentFeatureOption) {
      currentFeatureOption = this.currentFeature.values[0];
    }

    const currentFeatureOptionId = currentFeatureOption.id.toString();
    this.featureOptionId = currentFeatureOptionId;
  }
}
