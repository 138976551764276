import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {KnowledgeGraphLink} from '../../shared/models/knowledge-graph';

@Component({
  selector: 'app-knowledge-graph-links-list',
  templateUrl: './knowledge-graph-links-list.component.html',
  styleUrls: ['./knowledge-graph-links-list.component.less']
})

export class KnowledgeGraphLinksListComponent implements OnChanges {
  public showProgressBar: boolean = false;
  @Input() linksListHeader: string;
  @Input() range: string;
  @Input() linksList: KnowledgeGraphLink[];
  @Input() isAdmin: boolean;
  @Input() isRangeSelectorShown: boolean;
  @Input() representativeLemma: string;
  @Input() isDomainList: boolean;
  @Input() familyId: number;

  @Output() chosenParameterEvent: EventEmitter<{ key: string, value: string }> =
    new EventEmitter();

  @Output() addSilentDomainsEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() addGenerationDomainsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteLinksEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() addLinksEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() checkedFamiliesEvent: EventEmitter<{ linkId: number, isLinkChecked: boolean, description: string }> =
    new EventEmitter<{ linkId: number, isLinkChecked: boolean, description: string }>();

  constructor() {
  }

  ngOnChanges(): void {
    this.showProgressBar = true;
  }

  public deleteLinks($event: string): void {
    this.deleteLinksEvent.next($event);
  }

  public updateLinksList($event: string): void {
    this.addLinksEvent.next($event);
  }

  public addSilentDomains(event): void {
    this.addSilentDomainsEvent.emit(event);
  }

  public addGenerationDomains(event): void {
    this.addGenerationDomainsEvent.emit(event);
  }

  public sendCheckedFamiliesEvent($event: { linkId: number, isLinkChecked: boolean, description: string }): void {
    this.checkedFamiliesEvent.next($event);
  }

  public pushTheDataFurther($event: { key: string, value: string | number }): void {
    this.showProgressBar = true;
    const $updatedEvent = {key: $event.key.toLowerCase(), value: $event.value.toString()};
    this.chosenParameterEvent.emit($updatedEvent);
  }
}
