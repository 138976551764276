import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SessionService } from './session.service';
import { environment } from '../../../environments/environment';
import { EndpointUrls } from '../constants/endpoint';

import { applicationConstants } from '../constants/application-constants';


@Injectable()
export class AuthenticationService {
  constructor(private sessionService: SessionService, private httpClient: HttpClient) {
  }

  private processAuthenticationData(login: string, authenticationData): boolean {
    const isSuccessfulAuthentication = Boolean(authenticationData);
    this.sessionService.processAuthenticationResponse(login, authenticationData);

    return isSuccessfulAuthentication;
  }

  public login(login: string, password?: string): Promise<boolean> {
    let isSuccessfulAuthentication = false;
    const authenticationUrl = EndpointUrls.authentication;

    if ((login && password) || (login === applicationConstants.guestLogin)) {
      return this.httpClient.post(environment.apiEndpoint + authenticationUrl, JSON.stringify([login, password]), { headers: { 'Content-Type': 'application/json' } })
        .toPromise()
        .then(authenticationData => {
          isSuccessfulAuthentication = this.processAuthenticationData(login, authenticationData);

          return isSuccessfulAuthentication;
        }, (error) => {
          console.error('reject', error);
          return isSuccessfulAuthentication;
        })
        .catch(error => {
          console.error('catch', error);
          return isSuccessfulAuthentication;
        });
    } else {
      console.error('Login or password is empty');
      return Promise.resolve(isSuccessfulAuthentication);
    }
  }


  public loginAsAGuest(): Promise<boolean> {
    return this.login(applicationConstants.guestLogin);
  }
}
