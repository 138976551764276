/**
 * Relative URLs for LaMP web service methods.
 */
export const EndpointUrls = {
  authentication: 'authenticate',
  refreshSession: 'refreshSession',
  refreshSessionNoReload: 'refreshSession?fast=true',
  logout: 'logout',
  bookmark: 'bookmark',
  bookmarkText: 'bookmarkText',
  languageSettings: {
    languagesList: 'languages',
    language: 'language',
    setLanguage: 'setLanguage'
  },
  statistics: 'statistics',
  status: 'status',
  shutdown: 'shutdown',
  punctuation: {
    punctuationList: 'punctuationList',
    punctuation: 'punctuation'
  },
  nonBreaks: {
    'nonBreaksList': 'nonbreakList',
    'nonbreak': 'nonbreak'
  },
  nonDictionaryList: 'nondictionaryList',
  phonemes: {
    phonemeList: 'phonemeList',
    phoneme: 'phoneme',
    phonemeAliasList: 'phonemeAliasList',
    phonemeAlias: 'phonemeAlias'
  },
  features: {
    feature: 'feature',
    featuresInUse: 'featuresInUse',
    featureListLocalizationList: 'featureListLocalizationList',
    featureListLocalization: 'featureListLocalization',
    featureLocalization: 'featureLocalization',
    hierarchicalFeatureDefinitionList: 'featureListList',
    featureDefinitionListDetails: 'featureList'
  },
  agreement: {
    agreement: 'agreement',
    agreementList: 'agreementList'
  },
  morphology: {
    defaultFeatureValuesList: 'defaultFeatureValuesList',
    defaultFeatureValues: 'defaultFeatureValues'
  },
  knowledgeGraph: {
    knowledgeGraph: 'knowledgeGraph',
    hyponymList: 'hyponymList',
    antonymList: 'antonymList',
    domainMemberList: 'domainMemberList',
    familyLexemes: 'familyLexemes',
    familyLinks: 'familyLinks',
    moveLexemeLinks: 'moveLexemeLinks',
    findFamilies: 'findFamilies',
    findLexemes: 'findLexemes',
    suggestLexemeLinks: 'suggestLexemeLinks',
    family: 'family',
    familyLocalization: 'familyLocalization',
    silentDomains: 'silentDomains',
    generateDomains: 'generateDomains',
    hyponymIds: 'hyponymIds'
  },
  coreference: {
    coreference: 'coreference',
    coreferenceList: 'coreferenceList'
  },
  contraction: {
    contraction: 'contraction',
    contractionList: 'contractionList',
  },
  prodrop: {
    prodrop: 'prodrop',
    prodropList: 'prodropList',
  },
  range: {
    rangeList: 'rangeList',
    range: 'range',
    localizationList: 'rangeLocalizationList',
    localization: 'rangeLocalization',
    initRange: 'initRange',
    countEntries: 'count'
  },
  lexicon: {
    lexicon: 'lexicon',
    retag: 'retag',
    mergeLexemes: 'mergeLexemes',
    lexemeFamily: 'lexemeFamily',
    lexeme: 'lexeme',
    lexemeDescription: 'lexemeDescription',
    legacy: 'legacyLexeme',
    advancedCriteriaList: 'advancedCriteriaList',
    advancedCriteria: 'advancedCriteria',
    rootList: 'rootList',
    unloadMorphology: 'unloadMorphology',
    approveLexeme: 'approveLexeme',
    approveLexemeFamily: 'approveLexemeFamily',
    validateLexemeLinks: 'validateLexemeLinks',
    toggleSilent: 'toggleSilent'
  },
  inflection: {
    inflectionList: 'inflectionList',
    inflection: 'inflection',
    runtimeInflectionFeatures: 'runtimeInflectionFeatures',
    tagLemma: 'tagLemma',
  },
  extraHypothesis: {
    extrahypothesisList: 'extrahypothesisList',
    extrahypothesis: 'extrahypothesis'
  },
  interfixes: {
    interfixList: 'interfixList',
    interfix: 'interfix'
  },
  clitics: {
    cliticList: 'cliticList',
    clitic: 'clitic',
    'cliticSampleWords': 'cliticSampleWords'
  },
  phrases: {
    phraseList: 'phraseList',
    phraseFamilyCompare: 'phraseFamilyCompare',
    phrase: 'phrase',
    parsetreelinkList: 'parsetreelinkList',
    parsetreelink: 'parsetreelink',
    supersetList: 'supersetList',
    superset: 'superset',
    describeElementClause: 'describeElementClause',
    phraseFromDescription: 'phraseFromDescription',
    generatePhrasalPatterns: 'generatePhrasalPatterns',
  },
  clause: {
    describeElementClause: 'describeElementClause',
    saveClauseDescription: 'saveClauseDescription',
    loadClauseDescription: 'loadClauseDescription',
    listClauseDescriptions: 'listClauseDescriptions',
    generateClause: 'generateClause'
  },
  CommonSenseCues: {
    commonsenseList: 'commonsenseList',
    commonsenseStats: 'commonsenseStats',
    commonsense: 'commonsense',
    commonsenseCueStatsBreakdown: 'commonsenseCueStatsBreakdown'
  },
  identityRoles: {
    identityroleList: 'identityroleList',
    identityrole: 'identityrole',
    identityDescription: 'identityDescription'
  },
  todaysEditCount: 'todaysEditCount',
  affixes: {
    affixList: 'affixList',
    affixTrigger: 'affixTrigger',
    inflectionRule: 'inflectionRule',
    inflectionSample: 'inflectionSample',
    affixTest: 'affixTest',
    affixTestList: 'affixTestList',
  },
  heuristics: {
    nondictionaryList: 'nondictionaryList',
    nondictionary: 'nondictionary',
    runtimeNondictionaryFeatures: 'runtimeNondictionaryFeatures'
  },
  roots: {
    rootList: 'rootList',
    root: 'root',
  },
  test: {
    testfragmentList: 'testfragmentList',
    corpora: 'corpora',
    testfragment: 'testfragment',
    phrastest: 'phrasetest',
    testresultCorrect: 'testresults',
    testresultRate: 'rateTestresult',
    testresultList: 'testresultList',
    transformationTestresult: 'transformationTestresult',
    transformationTestresultList: 'transformationTestresultList',
    fragmentTransformationResultList: 'fragmentTransformationResultList',
    fragmentResults: 'results',
    testresultFeedback: 'testresultFeedback',
    tisanerun: 'tisane',
    downloadTisaneLog: 'downloadLog',
    testInsight: 'testInsight',
    testproviderList: 'testproviderList',
    testprovider: 'testprovider',
    markStandard: 'testresultStandard',
    markGold: 'testresultGold',
    compile: 'compileMidday',
    unloadTisane: 'freeTisane',
    stats: 'teststats',
    unknownStats: 'unknownStats',
    metrics: 'metrics',
    manyPhraseRoots: 'manyPhraseRoots',
    topPhrases: 'topPhrases',
    phraseCoverage: 'phraseCoverage',
    phraseTestTotals: 'phraseTestTotals',
    phraseFailureList: 'phraseFailureList',
    throughput: 'throughput',
    diff: 'diff',
    listCorporaUnknownWords: 'listCorporaUnknownWords',
    ratings: 'ratings',
    testresultTransformationGold: 'testresultTransformationGold',
    ratedDiff: 'ratedDiff'
  },
  linguistActivities: {
    users: 'users',
    editBreakdown: 'editBreakdown',
    statuses: 'statuses'
  },
  about: {
    version: 'version',
    libraryInfo: 'libraryInfo',
    runtimeVersion: 'runtimeVersion',
    runtimeLibraryInfo: 'runtimeLibraryInfo',
  },
  healthcheck: {
    healthcheck: 'healthcheck',
    healthcheckList: 'healthcheckList'
  }
};
