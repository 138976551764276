import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.less']
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
  private errorMessageSubscription: Subscription;
  public errorMessage: string;
  public shouldBeEmittedScrollToTopEventEvent: boolean = false;

  @Input() errorMessageSource: Observable<string>;
  @Input() hideCloseSign: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.errorMessageSubscription = this.errorMessageSource.subscribe((errorMessage) => {
      this.shouldBeEmittedScrollToTopEventEvent = Boolean(errorMessage);
      this.errorMessage = errorMessage;
    });
  }

  ngOnDestroy(): void {
    this.errorMessageSubscription.unsubscribe();
  }

  clear(): void {
    this.errorMessage = '';
    this.shouldBeEmittedScrollToTopEventEvent = Boolean(false);
  }
}
