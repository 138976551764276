import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Options, LabelType } from 'ng5-slider';

import { SessionService } from '../../shared/services/session.service';
import { LexiconService } from '../../shared/services/lexicon.service';
import { RouterHelper } from '../../shared/helpers/router.helper';
import { commonTabSettings } from '../../shared/constants/application-constants';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';

import { LampUpdateResponse } from '../../shared/models/common';
import {
  AdvancedLexeme,
  emptyAdvancedLexeme,
  AdvancedCriteria,
  mweBehaviorOptions,
  Root,
  Lexeme
} from '../../shared/models/lexicon';
import { collectObjectChanges, toJsonObject } from '../../shared/helpers/object.helper';
import { frequencyValidator, thisCEEraValidator } from '../../shared/models/form.validator';
import { applicationConstants } from '../../shared/constants/application-constants';
import { FeaturesService } from '../../shared/services/features.service';
import { FeatureListDefinition } from '../../shared/models/feature';
import { MatDialog, MatSliderChange } from '@angular/material';
import { InflectionTableComponent } from '../../inflection-table/inflection-table.component';
import { InflectionTableService } from '../../shared/services/inflection-table.service';
import { LocalStorageHelper } from "../../shared/helpers/localhost.helper";
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lexeme-edit',
  templateUrl: './lexeme-edit.component.html',
  styleUrls: ['./lexeme-edit.component.less']
})
export class LexemeEditComponent extends Translated implements OnInit, OnDestroy {
  @Input('dialogId') dialogId: string;

  public errorMessageBehaviorSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubj.asObservable();
  public showSpinner: boolean;
  public isNew: boolean;
  public isCopied: boolean;
  public doesUserHaveAccessToEdit: boolean;
  public goToInflections: boolean;
  public mweSegments: string[] = [];

  private subscriptions: Subscription[] = [];

  public lexeme: AdvancedLexeme;
  public initialLexeme: AdvancedLexeme;
  public _masterLexemeDescription: string;
  public _inflectionSourceLexemeDescription: string;
  public _sameStyleAsDescription: string;

  public form: FormGroup;
  public earliestMention: string = '';
  public maxDate = new Date((new Date()).getTime() + (24 * 60 * 60 * 1000));
  public selectedIndexSettingName = "lexicon_" + commonTabSettings.idIndexKey;

  public tagging: any;
  public relevantGrammarFeatureList: FeatureListDefinition[];
  public relevantStyleFeatureList: FeatureListDefinition[];
  public semanticFeatureList: FeatureListDefinition[];

  public displayedTagging: any;
  public taggingTrace: any[] = [];
  public dontFilterGrammar: boolean = true;

  public mweBehaviorOptions = mweBehaviorOptions;
  public isDisabledTagButton: boolean = false;

  public inflectingSegmentSliderDefinition: Options = {
    floor: 0,
    ceil: 2,
    translate: (value: number): string => {
      if (value < 1)
        return '';
      return this.mweSegments[value - 1];
    }
  };

  public agreementSliderDefinition: Options = {
    floor: 0,
    ceil: 2,
    minRange: 0,
    minLimit: 0,
    translate: (value: number, label: LabelType): string => {
      if (value < 1)
        return '❎';
      return this.mweSegments[value - 1];
    }
  };

  constructor(protected sessionService: SessionService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private lexiconService: LexiconService,
    private knowledgeGraphService: KnowledgeGraphService,
    private routerHelper: RouterHelper,
    private featuresService: FeaturesService,
    protected localStorageHelper: LocalStorageHelper,
    protected translateService: TranslateService,
    private dialog: MatDialog,
    private inflectionTableService: InflectionTableService) {
    super(translateService, localStorageHelper, sessionService);

  }

  ngOnInit(): void {
    this.addSubscriptions();
    this.mweSegmentLabels = this.mweSegmentLabels.bind(this);
  }

  initThisSubtypeFromSession(): void {
    //nothing at the moment; change during the next refactoring
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subsciprion => subsciprion.unsubscribe());
    if (!this.isNew && this.lexeme && this.lexeme.id)
      this.localStorageHelper.setTabSetting(this.selectedIndexSettingName, this.lexeme.id.toString());
  }

  private addSubscriptions(): void {
    const routeSubscription = this.route.data.subscribe((data: {
      id: number
    }) => {
      this.showSpinner = true;
      this.isCopied = window.location.pathname.indexOf('copy') > -1;
      this.isNew = ((data.id === undefined) || isNaN(data.id)) || this.isCopied;

      if (this.isNew && !this.isCopied) {
        this.lexeme = toJsonObject(emptyAdvancedLexeme) as AdvancedLexeme;
        this.initialLexeme = toJsonObject(emptyAdvancedLexeme) as AdvancedLexeme;
        if (!this.dialogId && window.location.search && window.location.search.length > 1) {
          this.lexeme.lemma = decodeURIComponent(window.location.search.substring(1, window.location.search.length - 1));
          //this.initialLexeme.lemma = this.lexeme.lemma;
        }

        this.getFeaturesList().then(() => {
          this.showSpinner = false;
          this.createForm();
        });
      } else {
        this.getLexeme(data.id);
      }

    });

    const sessionSubscription = this.sessionService.currentSessionSource.subscribe((session) => {
      this.doesUserHaveAccessToEdit = !this.sessionService.isGuest();
    });
    this.subscriptions = [routeSubscription, sessionSubscription];
  }

  private getFeaturesList(): Promise<void> {
    this.dontFilterGrammar = !Boolean(this.lexeme) || !Boolean(this.lexeme.relevant);
    return this.featuresService.createFilteredRelevantFeatureList(this.lexeme.relevant, applicationConstants.defaultFeatureType).then((filteredGrammarFeatures) => {
      this.relevantGrammarFeatureList = filteredGrammarFeatures;
      return this.featuresService.createFilteredRelevantFeatureList(this.lexeme.relevant, applicationConstants.styleFeatureType).then((filteredStyleFeatures) => {
        this.relevantStyleFeatureList = filteredStyleFeatures;
        return this.featuresService.getFeatureList(applicationConstants.semanticFeatureType
        ).then((semanticFeatures: FeatureListDefinition[]) => { this.semanticFeatureList = semanticFeatures; });
      });
    });
  }

  private getLexeme(lexemeId: number): Promise<void> {
    return this.lexiconService.getLexeme(lexemeId)
      .then((lexeme: AdvancedLexeme) => {
        this.populateData(lexeme);
      });
  }

  private populateData(lexeme: AdvancedLexeme) {
    if (this.isCopied) {
      this.initialLexeme = toJsonObject(emptyAdvancedLexeme) as AdvancedLexeme;
      lexeme.id = undefined;
      lexeme.created = undefined;
      lexeme.lastUpdate = undefined;
      lexeme.lastBatchUpdate = undefined;
    }

    this.lexeme = toJsonObject(lexeme) as AdvancedLexeme;
    this.prepareMWESettings();
    this.getLexemeDescription(lexeme.sameFamiliesAs).then((description: string) => {
      this._masterLexemeDescription = description;
    });
    this.getLexemeDescription(lexeme.sameInflectionsAs).then((description: string) => {
      this._inflectionSourceLexemeDescription = description;
    });
    this.getLexemeDescription(lexeme.sameStyleAs).then((description: string) => {
      this._sameStyleAsDescription = description;
    });
    this.initialLexeme = this.initialLexeme || toJsonObject(lexeme) as AdvancedLexeme;
    if (lexeme.earliestMention) {
      this.earliestMention = lexeme.earliestMention;
    }

    this.getFeaturesList().then(() => {
      this.showSpinner = false;
      this.createForm();
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      lemma: [this.lexeme.lemma],
      invariable: [this.lexeme.invariable],
      frequency: [this.lexeme.frequency, frequencyValidator()],
      earliestMention: [this.earliestMention],
      lastYearActive: [this.lexeme.lastYearActive || 0, thisCEEraValidator()],
      unverified: [this.lexeme.unverified],
      note: [this.lexeme.note],
      mweBehavior: [this.lexeme.mweBehavior],
      inflectingSegment: [this.lexeme.inflectingSegment]
    });
  }

  private earliestMentionToAppropriateEarliestMentionAttributeForm() {
    if (this.earliestMention) {
      this.lexeme.earliestMention = (new Date(this.earliestMention)).toISOString();
    }
  }

  public reactOnSelectedAdvancedCriteria(advancedCriteria: AdvancedCriteria): void {
    if (advancedCriteria) {
      this.lexeme.advancedCriteriaDescription = advancedCriteria.description;
      this.lexeme.advancedCriteriaId = advancedCriteria.id;
    }
  }

  public mweSliderChange(event: MatSliderChange) {
    this.lexeme.inflectingSegment = event.value;
  }

  public mweSegmentLabels(value: number) {
    if (value < 1)
      return this.lexeme.lemma;
    return this.mweSegments[value - 1];
  }

  public prepareMWESettings(): void {
    if (this.lexeme.lemma && this.lexeme.lemma.includes(' ')) {
      this.mweSegments = this.lexeme.lemma.split(/\s+/); //TODO: add hyphen
      if (!this.lexeme.inflectingSegment)
        this.lexeme.inflectingSegment = 0;
      this.agreementSliderDefinition.ceil = this.mweSegments.length;
      this.inflectingSegmentSliderDefinition.ceil = this.agreementSliderDefinition.ceil;
      if (!this.lexeme.agreeingSegment1st)
        this.lexeme.agreeingSegment1st = 0;
      if (!this.lexeme.agreeingSegmentLast)
        this.lexeme.agreeingSegmentLast = this.lexeme.agreeingSegment1st;
    } else {
      this.mweSegments = [];
      this.lexeme.inflectingSegment = undefined;
    }
  }

  public clearAdvancedCriteria(): void {
    this.lexeme.advancedCriteriaDescription = '';
    this.lexeme.advancedCriteriaId = 0;
  }

  private back(): void {
    this.routerHelper.back();
  }

  public cancel(): void {
    this.back();
  }

  public save(): Promise<void> {
    this.earliestMentionToAppropriateEarliestMentionAttributeForm();
    const changes: AdvancedLexeme = collectObjectChanges(this.initialLexeme, this.lexeme) as AdvancedLexeme;
    changes.invariable = changes.invariable || false;
    this.defineTypeOfStyleFeatures(changes);
    if (this.isNew) {
      if (!changes.stem) {
        changes.stem = this.lexeme.lemma;
      }
      return this.lexiconService.createLexeme(changes).then((successfulResponseMessage: LampUpdateResponse) => {
        this.localStorageHelper.setTabSetting('newCreatedLexemeId', successfulResponseMessage.id.toString());
        this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
      });
    } else {
      changes.id = this.lexeme.id;
      this.showSpinner = true;
      return this.lexiconService.updateLexeme(changes).then((successfulResponseMessage: LampUpdateResponse) => {
        this.showSpinner = false;
        this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
      });
    }
  }

  public clearMasterLexeme() {
    this.lexeme.sameFamiliesAs = 0;
    this._masterLexemeDescription = undefined;
  }

  public clearSameInflectionsAs() {
    this.lexeme.sameInflectionsAs = 0;
    this._inflectionSourceLexemeDescription = undefined;
  }

  public clearSameStyleAs() {
    this.lexeme.sameStyleAs = 0;
    this._sameStyleAsDescription = undefined;
  }

  private reactOnSuccessfulResponseMessage(successfulResponseMessage: LampUpdateResponse): void {
    if (successfulResponseMessage.success) {
      if (this.dialogId) {
        const dialog = this.dialog.getDialogById(this.dialogId);
        if(dialog) {
          dialog.close();
        }
      } else if (this.goToInflections) {
        this.openDialog();
      } else {
        this.back();
      }
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  public selectRoot(index: number, root: Root): void {
    if (!root) {
      root = { id: 0 };
    }
    if (index) {
      switch (index) {
        case 1:
          this.lexeme.root = root.id;
          this.lexeme.rootDescription = root.root;
          break;
        default:
          this.lexeme['root' + index] = root.id;
          this.lexeme['root' + index + 'Description'] = root.root;
          break;
      }
    }
  }

  public reactOnUpdateAdvancedCriteria(): void {
    this.lexiconService.getAdvancedCriteriaById(this.lexeme.advancedCriteriaId)
      .then((advancedCriteria: AdvancedCriteria) => {
        this.lexeme.advancedCriteriaDescription = advancedCriteria.description;
      });
  }

  public reactOnDoYouSaveDialogClosed(response: boolean): void {
    if (!response) {
      this.goToInflections = true;
      this.save();
    } else {
      if (!this.isObjectChanged()) {
        this.openDialog();
      }
    }
  }

  public isObjectChanged(): boolean {
    let changes: AdvancedLexeme = collectObjectChanges(this.initialLexeme, this.lexeme) as AdvancedLexeme;
    if (Object.keys(changes).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  private openDialog(): void {
    this.goToInflections = false;
    this.dialog.open(InflectionTableComponent,
      {
        data: {
          lexeme: this.lexeme.lemma,
          lexemeId: this.lexeme.id
        },
        minWidth: '90%',
        id: 'inflectionTableDialog'
      });
  }

  public reactOnTagClick(): void {
    this.showSpinner = true;
    const temp: AdvancedLexeme = toJsonObject(this.lexeme) as AdvancedLexeme;
    delete temp.legacyInflectionMapId;
    this.removeEmptyArrays(temp);
    this.defineTypeOfStyleFeatures(temp);
    this.inflectionTableService.tagLemma(temp as AdvancedLexeme)
      .then((tagLexeme: AdvancedLexeme) => {
        this.lexeme.grammar = tagLexeme.grammar || [];
        this.lexeme.stem = tagLexeme.stem;
        this.lexeme.invariable = tagLexeme.invariable;
        this.lexeme.style = tagLexeme.style || [];
        this.lexeme.semantics = tagLexeme.semantics || [];
        this.tagging = tagLexeme.tagging;
        //this.taggingTrace.push({Key: this.lexeme.stem, Value: tagLexeme.tagging});
        //this.groupTaggingByTrigger();
        this.showSpinner = false;
      });
  }

  public defineTypeOfStyleFeatures(lexeme: AdvancedLexeme): void {
    if (lexeme.style && lexeme.style[0]) {
      lexeme.style.forEach(element => (element.type = applicationConstants.styleFeatureType));
    }
  }

  public removeEmptyArrays(lexeme: AdvancedLexeme): void {
    if (lexeme) {
      Object.keys(lexeme).forEach(key => {
        if ((lexeme[key] instanceof Array) && (!lexeme[key] || lexeme[key].length === 0)) {
          delete lexeme[key];
        }
        if ((typeof lexeme[key] !== 'boolean') && !lexeme[key]) {
          delete lexeme[key];
        }
      });
      if (lexeme.relevant) {
        delete lexeme.relevant;
      }
    }

  }

  public groupTaggingByTrigger(): void {
    if (this.tagging) {
      this.displayedTagging = {};
      this.tagging.forEach(tag => {
        if (!this.displayedTagging[tag.trigger]) {
          this.displayedTagging[tag.trigger] = [];
        }
        this.displayedTagging[tag.trigger].push(tag);
      });
    }
  }

  private getLexemeDescription(id: number): Promise<string> {
    if (!id)
      return Promise.resolve("");
    return this.knowledgeGraphService.getLexemesListForTheCurrentRange(
      id.toString(), "id", "all").then((masterLexemes: Lexeme[]) => {
        if (masterLexemes.length == 1)
          return "[Lexeme#" + masterLexemes[0].id.toString() + "] " + masterLexemes[0].lemma;
      });
  }

  public setSameAsLexeme(commaDelimitedIdsList: string) {
    if (commaDelimitedIdsList && commaDelimitedIdsList.length > 0)
      try {
        this.lexeme.sameFamiliesAs = parseInt(commaDelimitedIdsList);
        this.getLexemeDescription(this.lexeme.sameFamiliesAs).then((description: string) => {
          this._masterLexemeDescription = description;
        });
      }
      catch (error) { }
  }

  public setSameInflectionsAs(commaDelimitedIdsList: string) {
    if (commaDelimitedIdsList && commaDelimitedIdsList.length > 0)
      try {
        this.lexeme.sameInflectionsAs = parseInt(commaDelimitedIdsList);
        this.getLexemeDescription(this.lexeme.sameInflectionsAs).then((description: string) => {
          this._inflectionSourceLexemeDescription = description;
        });
      }
      catch (error) { }

  }

  public setSameStyleAs(commaDelimitedIdsList: string) {
    if (commaDelimitedIdsList && commaDelimitedIdsList.length > 0)
      try {
        this.lexeme.sameStyleAs = parseInt(commaDelimitedIdsList);
        this.getLexemeDescription(this.lexeme.sameStyleAs).then((description: string) => {
          this._sameStyleAsDescription = description;
        });
      }
      catch (error) { }

  }

  public disableTagTrace($event): void {
    this.clearTheErrorMessage();
    this.displayedTagging = undefined;
  }

  public showHistory(log: AdvancedLexeme) {
    log = {
      ...emptyAdvancedLexeme,
      ...log,
      requestId: this.initialLexeme.requestId,
      legacyInflectionMapId: log.legacyInflectionMapId || undefined,
      earliestMention: log.earliestMention || undefined
    };
    
    this.populateData(log);
  }
}
