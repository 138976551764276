<h1>{{ 'headers.non-breaks' | translate }}</h1>
<app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
<mat-progress-bar *ngIf="showProgressbar" color="accent" mode="indeterminate"></mat-progress-bar>
<div class="ti-breaks-menu">
  <div class="ti-breaks-menu__button-wrapper">
    <button
      *ngIf="sessionService.canEditNonLexicon()"
      mat-raised-button
      color="accent"
      (click)="addNewRowInTheTable()">
      {{'COMMON.add-new' | translate}}<mat-icon aria-hidden="true">add</mat-icon>
    </button>    
  </div>
</div>

<div class="ti-breaks-table-wrapper">
  <mat-table *ngIf="nonBreakGlobals" #nonBreakGlobalsTable [dataSource]="nonBreakGlobals">
    <ng-container matColumnDef="regular-expression">
      <mat-header-cell *matHeaderCellDef>{{'COMMON.regular-expression' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{element.regex}}</span>
        <textarea [(ngModel)]="editedNonBreak && editedNonBreak.regex" *ngIf="isSelectedForEditing(element)" matInput
                  placeholder="{{'COMMON.regular-expression' | translate}}">{{element.regex}}</textarea>
        
        <!-- anchor link -->
        <h2 class="anchor-link" id="{{ element.regex }}"></h2>        
        <a class="hidden-link" href="{{ 'non-breaks#' + element.regex }}">LINK</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>{{'COMMON.description' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{element.description}}</span>
        <textarea [(ngModel)]="editedNonBreak && editedNonBreak.description" *ngIf="isSelectedForEditing(element)" matInput
                  placeholder="{{'COMMON.description' | translate}}">{{element.description}}</textarea>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="trailing-regex">
      <mat-header-cell *matHeaderCellDef>{{'non-break.trailing-regex' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{element.trailingRegex}}</span>
        <textarea [(ngModel)]="editedNonBreak && editedNonBreak.trailingRegex" *ngIf="isSelectedForEditing(element)" matInput
                  placeholder="{{'non-break.trailing-regex' | translate}}">{{element.trailingRegex}}</textarea>
      </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="max-iterations">
      <mat-header-cell *matHeaderCellDef>{{'non-break.max-iterations' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{element.maxIterations}}</span>
        <textarea [(ngModel)]="editedNonBreak && editedNonBreak.maxIterations" *ngIf="isSelectedForEditing(element)" matInput
                  placeholder="{{'non-break.max-iterations' | translate}}">{{element.maxIterations}}</textarea>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="iterate-backward">
      <mat-header-cell *matHeaderCellDef>{{'non-break.iterate-backward' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
        class="ti-form__column ti-form__one-line-fields--with-checkbox"
        matInput
        matTooltip="{{'non-break.iterate-backward' | translate}}"
        name="backward"
        *ngIf="isEditingThis(element)"
        [(ngModel)]="editedNonBreak && editedNonBreak.backward">
      </mat-checkbox>
      <ng-container *ngIf="!isEditingThis(element)">
        <mat-icon class="ti-form__checkbox-backward checked" *ngIf="element.backward" aria-hidden="true">check_box</mat-icon>
        <mat-icon class="ti-form__checkbox-backward unchecked" *ngIf="!element.backward" aria-hidden="true">check_box_outline_blank</mat-icon>
      </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="non-dictionary-pattern">
      <mat-header-cell *matHeaderCellDef>{{'non-break.non-dictionary-pattern' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{getNonDictionaryPattern(element.heuristicId)}}</span>
        <mat-select *ngIf="isSelectedForEditing(element)"
                    role="listbox"
                    placeholder="{{ 'non-break.non-dictionary-pattern' | translate}}"
                    [(ngModel)]="editedNonBreak && editedNonBreak.heuristicId"

        >
          <mat-option role="option" *ngFor="let nonDictionary of nonDictionaryList" [value]="nonDictionary.id">
            {{nonDictionary.description}}
            ({{nonDictionary.regex}})
          </mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="for-all-languages">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
        class="ti-form__column ti-form__one-line-fields--with-checkbox"
        matInput
        matTooltip="{{'non-break.for-all-languages' | translate}}"
        name="forAllLanguages"
        *ngIf="isEditingThis(element) && isAdmin()"
        [(ngModel)]="forAllLanguages">
        <span class="ti-form__label">&nbsp;🌐</span>
      </mat-checkbox>
        <button mat-mini-fab
           *ngIf="(element.language || isAdmin()) && element.id && !editedNonBreak && sessionService.canEditNonLexicon()"
           (click)="edit(element.id)"
        >
          <mat-icon aria-hidden="true">edit</mat-icon>
        </button>
        <button mat-mini-fab
           *ngIf="(element.language || isAdmin()) && element.id && !editedNonBreak && sessionService.canEditNonLexicon()"
           appOpenConfirmDialog
           (doesUserConfirmEvent)="deleteNonBreak(element.id, $event)"
        >
          <mat-icon aria-hidden="true">delete</mat-icon>
        </button>
        <button mat-mini-fab
           *ngIf="isEditingThis(element)"
           (click)="updateNonBreak(editedNonBreak)">
          <mat-icon aria-hidden="true">done</mat-icon>
        </button>
        <button mat-mini-fab
           *ngIf="isEditingThis(element)"
           (click)="revertChanges()">
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
        <span *ngIf="!element.language && !editedNonBreak"         
          matTooltip="{{'non-break.for-all-languages' | translate}}">&nbsp;🌐</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
