<div class="ti-ac__table-wrapper-content">
  <mat-table #hypernymsTable [dataSource]="advancedCriteria.hypernyms">
    <ng-container matColumnDef="checked">
      <mat-header-cell class="ti-ac__checkbox-cell" *matHeaderCellDef>
        <mat-checkbox
          class="ti-mat-cell---with-checkbox"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="isGuest"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell
        class="ti-mat-cell---with-checkbox ti-ac__checkbox-cell"
        *matCellDef="let row"
      >
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="isGuest"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="id">
      <mat-header-cell class="ti-ac__id-cell" *matHeaderCellDef>{{ 'COMMON.id' | translate }}</mat-header-cell>
      <mat-cell class="ti-ac__id-cell" *matCellDef="let hypernumItem">{{hypernumItem.id}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="word">
      <mat-header-cell class="ti-ac__word-cell" *matHeaderCellDef>{{ 'COMMON.word' | translate }}</mat-header-cell>
      <mat-cell class="ti-ac__word-cell" *matCellDef="let hypernumItem">{{hypernumItem.description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="definition">
      <mat-header-cell class="ti-ac__definition-cell" *matHeaderCellDef>{{ 'COMMON.definition' | translate}}</mat-header-cell>
      <mat-cell class="ti-ac__definition-cell" *matCellDef="let hypernumItem">{{hypernumItem.definition}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="familyCompabilityColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: familyCompabilityColumns;"
      (click)="selection.toggle(row)">
    </mat-row>
  </mat-table>
  <div class="ti-ac__add-button-wrapper">
    <button
      mat-mini-fab
      color="primary"
      appOpenConfirmDialog
      (doesUserConfirmEvent)="removeHypernymItems($event)"
      [disabled]="doesUserClickButton()"
      *ngIf="doesUserSeeButton()"
    >
      <mat-icon aria-hidden="true">delete</mat-icon>
    </button>
  </div>
</div>
<button
  class="ti-ac__add-button"
  mat-mini-fab
  color="primary"
  appOpenFamilySelectionDialog
  [chooseOnlyOneFamily]="true"
  (selectedDataEvent)="addHypernum($event)"
  *ngIf="doesUserSeeButton()"
>
  <mat-icon aria-hidden="true">add</mat-icon>
</button>
