import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';

import {AdvancedCriteria} from '../../shared/models/lexicon';
import { applicationConstants } from '../../shared/constants/application-constants';

@Component({
  selector: 'app-advanced-criteira-dialog',
  templateUrl: 'advanced-criteria-dialog.component.html',
  styleUrls: ['advanced-criteria-dialog.component.less']
})

export class AdvancedCriteriaDialogComponent {
  public advancedCriteria: AdvancedCriteria;
  public forWords: boolean;
  public dialogTitle: string;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: { forWords: boolean; dialogTitle: string; }
  ) {
    this.forWords = dialogData.forWords;
    this.dialogTitle = dialogData.dialogTitle;
  }

  public reactOnSelectAdvancedCriteriaItem(advancedCriteria: AdvancedCriteria): void {
    this.advancedCriteria = advancedCriteria;
  }

  public reactOnSessionInterrapted(): void {
    this.dialog.getDialogById(applicationConstants.dialogs.advancedCriteriaDialog).close();
  }

}
