import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';

import {LexiconService} from '../../shared/services/lexicon.service';
import {LampUpdateResponse} from '../../shared/models/common';
import {toJsonObject, collectObjectChanges} from '../../shared/helpers/object.helper';
import {AdvancedCriteria} from '../../shared/models/lexicon';
import {SessionService} from '../../shared/services/session.service';
import {applicationConstants} from '../../shared/constants/application-constants';
import {FeatureValue} from '../../shared/models/feature';

@Component({
  selector: 'app-advanced-criteria-edit-dialog',
  templateUrl: 'advanced-criteria-edit-dialog.component.html',
  styleUrls: ['advacned-criteria-edit-dialog.component.less'],
})
export class AdvancedCriteriaEditDialogComponent {

  @ViewChild('advancedCriteriaEdit') advancedCriteriaEdit;

  public isEdit: boolean;
  public isCopying: boolean;
  public currentId: number;
  public forWords: boolean;

  public isGuest: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public lexiconService: LexiconService,
              public dialog: MatDialog,
              public sessionService: SessionService) {
    this.isEdit = data.isEdit;
    this.currentId = data.currentId;
    this.forWords = data.forWords;
    this.isCopying = data.isCopying;
    this.isGuest = this.sessionService.isGuest();
  }

  public saveAdvancedCriteria(): void {
    this.removeTempProperty();
    if (this.advancedCriteriaEdit.isEdit) {
      const changes = collectObjectChanges(
        this.advancedCriteriaEdit.initialAdvancedCriteria,
        this.advancedCriteriaEdit.advancedCriteria
      ) as AdvancedCriteria;
      if (Object.keys(changes).length !== 0) {
        changes.id = this.advancedCriteriaEdit.advancedCriteria.id;
        changes.forWords = this.forWords;
        this.replaceFromArraysToIds(changes);
        this.defineFeatureTypes(changes);
        this.lexiconService.getAdvancedCriteriaById(changes.id)
          .then((advancedCriteria: AdvancedCriteria) => {
            this.lexiconService.updateAdvancedCriteria(changes)
              .then((message: LampUpdateResponse) => this.checkResponse(message));
          });
      } else {
        this.dialog.getDialogById(applicationConstants.dialogs.advancedCriteriaEditDialog).close(false);
      }
    } else {
      const changes = toJsonObject(this.advancedCriteriaEdit.advancedCriteria) as AdvancedCriteria;
      this.removeEmptyPropertyies(changes);
      changes.forWords = this.forWords;
      this.lexiconService.createAdvancedCriteria(changes)
        .then((message: LampUpdateResponse) => this.checkResponse(message));
    }
  }

  private replaceFromArraysToIds(changes: AdvancedCriteria): void {
    if (changes.requiredPhrases) {
      changes.requiredPhrases = changes.requiredPhrases
        .map(requiredPhrase => {
          return {id: requiredPhrase.id};
        });
    }
    if (changes.hypernyms) {
      changes.hypernyms = changes.hypernyms
        .map(hypernym => {
          return {id: hypernym.id};
        });
    }
  }

  private removeTempProperty(): void {
    if (this.advancedCriteriaEdit &&
      this.advancedCriteriaEdit.advancedCriteria &&
      this.advancedCriteriaEdit.advancedCriteria.phoneticCompatibility) {
      this.advancedCriteriaEdit.advancedCriteria.phoneticCompatibility.forEach(phoneticCompatibility => delete phoneticCompatibility.editVisibility);
    }
  }

  private removeEmptyPropertyies(changes: AdvancedCriteria): void {
    for (const key in changes) {
      if (Array.isArray(changes[key]) && changes[key].length === 0) {
        delete changes[key];
      }
    }
  }

  private defineFeatureTypes(advancedCriteria: AdvancedCriteria): void {
    for (const key in advancedCriteria) {
      if (key.includes(applicationConstants.styleFeatureType)) {
        this.defineType(advancedCriteria, key, applicationConstants.styleFeatureType);
      } else if (key.includes(applicationConstants.semanticFeature.type)) {
        this.defineType(advancedCriteria, key, applicationConstants.semanticFeature.type);
      }
    }
  }

  private defineType(advancedCriteria: AdvancedCriteria, key: string, type: string): void {
    if (advancedCriteria) {
      advancedCriteria[key].forEach(feature => feature.type = type);
    }
  }

  private checkResponse(message: LampUpdateResponse): void {
    if (message.error) {
      this.advancedCriteriaEdit.showErrorMessage(message.error);
      return;
    } else {
      this.dialog.getDialogById(applicationConstants.dialogs.advancedCriteriaEditDialog).close(true);
    }
  }

}
