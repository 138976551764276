<mat-dialog-content>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <div *ngIf="inflection" class="ti-inflection-edit">
    <h1>{{ (isEdit ? 'inflection-table.inflection-edit' : 'inflection-table.inflection-creation') | translate}} {{ lexeme }}</h1>
    <form [formGroup]="form">
      <div class="ti-inflection-edit__read-only ti-inflection-edit__label">
        <div class="ti-inflection-edit__read-only-key">
          {{ 'inflection-table.inflection-id' | translate }}
        </div>
        <div class="ti-inflection-edit__read-only-value">
          {{ inflection.inflectionId }}
        </div>
      </div>
      <div class="ti-inflection-edit__read-only">
        <div class="ti-inflection-edit__read-only-key ti-inflection-edit__label">
          {{ 'inflection-table.inflected-form' | translate}}
        </div>
        <div class="ti-inflection-edit__read-only-value ti-inflection-edit__inflected-form">
          <mat-form-field color="accent">
            <input
              matInput
              name="inflectedForm"
              formControlName="inflectedForm"
              [(ngModel)]="inflection.text"
              placeholder="{{ 'inflection-table.inflected-form' | translate}}"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="ti-inflection-edit__checkbox-wrapper">
        <mat-checkbox
          disabled="true"
          [(ngModel)]="inflection.isLemma"
          formControlName="isLemma"
        ></mat-checkbox>
        <div class="ti-inflection-edit__checkbox-label ti-inflection-edit__label">{{ 'inflection-table.lemma' | translate }}
        </div>
      </div>
      <h3>{{ 'inflection-table.inflection-specific-features' | translate }}</h3>
      <div class="ti-inflection-edit__sec-features">
        <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'COMMON.grammar' | translate}}</h4>
        <app-universal-features
          [featureValues]="inflection.inflectionGrammar"
          [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
          [relevantFeatureList]="grammarFeatureList"
          [isOnlyOneFeature]="false"
        ></app-universal-features>
      </div>
      <div class="ti-inflection-edit__sec-features">
        <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'lexicon.style' | translate}}</h4>
        <app-universal-features
          [featureValues]="inflection.inflectionStyle"
          [doesTheWholeFeatureListRelevant]="true"
          [relevantFeatureList]="styleFeatureList"
          [isOnlyOneFeature]="false"
        ></app-universal-features>
      </div>
      <div class="ti-inflection-edit__sec-features">
        <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'COMMON.semantic' | translate}}</h4>
        <app-universal-features
          [featureValues]="inflection.inflectionSemantic"
          [doesTheWholeFeatureListRelevant]="true"
          [relevantFeatureList]="semanticFeatureList"
          [isOnlyOneFeature]="false"
        ></app-universal-features>
      </div>
      <div class="ti-inflection-edit__read-only ti-inflection-edit__label">
        <div class="ti-inflection-edit__read-only-key">
          {{ 'COMMON.family' | translate }}
        </div>
        <div class="ti-inflection-edit__read-only-value">
          {{ familyId }}
        </div>
        <div
          class="ti-inflection-edit__tooltip"
          matTooltip="Please choose family in the Inflected forms dialog"
          [matTooltipDisabled]="familyId"
        >
          <a
            (click)="familyId ? closeDialogs() : false"
            [routerLink]="familyId ? '/knowledge-graph' : null"
            [queryParams]="{key: 'id', value: familyId}"
            [class.disabled]="!familyId"
          >
            {{ 'inflection-table.go-to-family' | translate }}
          </a>
        </div>
      </div>
      <div class="ti-inflection-edit__read-only ti-inflection-edit__label">
        <div class="ti-inflection-edit__read-only-key">
          {{ 'COMMON.lexeme-id' | translate }}
        </div>
        <div class="ti-inflection-edit__read-only-value">
          {{ dialogData.lexemeId }}
        </div>
        <div
          matTooltip="Please choose family in the Inflected forms dialog"
          [matTooltipDisabled]="familyId"
          class="ti-inflection-edit__tooltip"
        >
          <a
            [class.disabled]="!familyId"
            (click)="familyId ? closeDialogs() : false"
            [routerLink]="familyId ? '/lexicon/lexeme-family-connection/edit/'+dialogData.lexemeId+'/'+familyId : null"
            [queryParams]="{editedId: dialogData.lexemeId, editedValue: familyId}"
          >
            {{ 'inflection-table.go-to-lexeme-family' | translate }}
          </a>
        </div>
      </div>
      <div>
        <h3>{{ 'COMMON.lexeme-features' | translate }}</h3>
        <div class="ti-inflection-edit__section">
          <div>
            <h4 class="ti-inflection-edit__feature-sub-item-title">{{'COMMON.grammar' | translate}}</h4>
            <div class="ti-inflection-edit__select-wrapper">
              <app-universal-features
                [featureValues]="inflection.lexemeGrammar"
                [doesTheWholeFeatureListRelevant]="true"
                [relevantFeatureList]="grammarFeatureList"
                [isOnlyOneFeature]="false"
                [disableSelect]="true"
              ></app-universal-features>
            </div>
          </div>
          <div>
            <h4 class="ti-inflection-edit__feature-sub-item-title">{{'lexicon.style' | translate}}</h4>
            <div class="ti-inflection-edit__select-wrapper">
              <app-universal-features
                [featureValues]="inflection.lexemeStyle"
                [doesTheWholeFeatureListRelevant]="true"
                [relevantFeatureList]="styleFeatureList"
                [isOnlyOneFeature]="false"
                [disableSelect]="true"
              ></app-universal-features>
            </div>
          </div>
          <div>
            <h4 class="ti-inflection-edit__feature-sub-item-title">{{'COMMON.semantic' | translate}}</h4>
            <div class="ti-inflection-edit__select-wrapper">
              <app-universal-features
                [featureValues]="inflection.lexemeSemantic"
                [doesTheWholeFeatureListRelevant]="true"
                [relevantFeatureList]="semanticFeatureList"
                [isOnlyOneFeature]="false"
                [disableSelect]="true"
              ></app-universal-features>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3>{{ 'inflection-table.family-features' | translate }}</h3>
        <div class="ti-inflection-edit__section">
          <div>
            <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'COMMON.grammar' | translate}}</h4>
            <div class="ti-inflection-edit__select-wrapper">
              <app-universal-features
                [featureValues]="inflection.familyGrammar"
                [doesTheWholeFeatureListRelevant]="true"
                [relevantFeatureList]="grammarFeatureList"
                [isOnlyOneFeature]="false"
                [disableSelect]="true"
              ></app-universal-features>
            </div>
          </div>
          <div>
              <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'COMMON.semantic' | translate}}</h4>
              <div class="ti-inflection-edit__select-wrapper">
                <app-universal-features
                  [featureValues]="inflection.familySemantics"
                  [doesTheWholeFeatureListRelevant]="true"
                  [relevantFeatureList]="semanticFeatureList"
                  [isOnlyOneFeature]="false"
                  [disableSelect]="true"
                ></app-universal-features>
              </div>
            </div>
          </div>
        </div>
      <div>
        <h3>{{ 'inflection-table.lexeme-family-features' | translate }}</h3>
        <div class="ti-inflection-edit__section">
          <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'lexicon.style' | translate}}</h4>
          <div class="ti-inflection-edit__select-wrapper">
            <app-universal-features
              [featureValues]="inflection.lexemeFamilyStyle"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
        </div>
      </div>
      <div class="ti-inflection-edit__feature-title">{{ 'COMMON.features-in-runtime' | translate }}</div>
      <div>
        <button class="ti-inflection-edit__refresh" (click)="refreshRuntimeFeatures()" mat-stroked-button color="accent">{{'COMMON.refresh' | translate}}</button>
        <div class="ti-inflection-edit__section">
          <mat-progress-bar *ngIf="refresh" color="accent" mode="indeterminate"></mat-progress-bar>
          <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-inflection-edit__select-wrapper">
            <app-universal-features
              [featureValues]="runtimeGrammar"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="grammarFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'lexicon.style' | translate}}</h4>
          <div class="ti-inflection-edit__select-wrapper">
            <app-universal-features
              [featureValues]="runtimeStyle"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4 class="ti-inflection-edit__feature-sub-item-title ti-inflection-edit__head">{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-inflection-edit__select-wrapper">
            <app-universal-features
              [featureValues]="runtimeSemantics"
              [doesTheWholeFeatureListRelevant]="true"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
        </div>
      </div>
      <div class="ti-inflection-edit__read-only ti-inflection-edit__label">
        <div class="ti-inflection-edit__read-only-key">
          {{ 'inflection-table.legacy-inflection-map-id' | translate }}
        </div>
        <div class="ti-inflection-edit__read-only-value">
          {{ inflection.legacyInflectionMapId }}
        </div>
      </div>
      <div class="ti-inflection-edit__read-only ti-inflection-edit__label">
        <div class="ti-inflection-edit__read-only-key">
          {{ 'inflection-table.additional-forms' | translate }}
        </div>
        <mat-radio-group 
          formControlName="additionalForms"
          [(ngModel)]="inflection.additionalForms">
          <mat-radio-button class="ti-language-settings__radio-button"  value="no">
            <p class="ti-language-settings__radio-label">{{'inflection-table.additional-forms-no' | translate}}</p>
          </mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button class="ti-language-settings__radio-button"  value="baseOnly">
            <p class="ti-language-settings__radio-label">{{'inflection-table.additional-forms-base-only' | translate}}</p>
          </mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button class="ti-language-settings__radio-button"  value="complete">
            <p class="ti-language-settings__radio-label">{{'inflection-table.additional-forms-complete' | translate}}</p>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="ti-inflection-edit__checkbox-wrapper ti-inflection-edit__label">
        <mat-checkbox
          [(ngModel)]="inflection.misspelling"
          formControlName="misspelling"
        ></mat-checkbox>
        <div class="ti-inflection-edit__checkbox-label">{{ 'inflection-table.misspelling' | translate }}
        </div>
      </div>
      <div class="ti-inflection-edit__read-only ti-advanced-criteria__wrap">
        <div class="ti-inflection-edit__read-only-key ti-inflection-edit__label">
          {{ 'COMMON.advanced-criteria' | translate }}
        </div>
        <div *ngIf="inflection.advancedCriteriaDescription" class="ti-inflection-edit__advanced-criteria-value">{{ inflection.advancedCriteriaDescription }}</div>
        <div class="ti-inflection-edit__advanced-criteria-buttons">
          <button
            mat-mini-fab
            color="primary"
            appAdvancedCriteriaButton
            [forWords]="true"
            [dialogTitle]="'inflections'"
            (selectedDataEvent)="reactOnSelectedAdvancedCriteria($event)"
            [ngStyle]="{'margin-left': inflection.advancedCriteriaId ? '20px' : ''}"
          >
            <mat-icon aria-hidden="true">search</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="clearTheErrorMessage()"
            style="margin-left: 10px"
            [disabled]="!inflection.advancedCriteriaId"
            appAdvancedCriteriaOpenEditDialog
            [forWords]="true"
            (updateDataEvent)="reactOnUpdateAdvancedCriteria()"
            [isEdit]="true"
            [currentId]="inflection.advancedCriteriaId"
          >
            <mat-icon aria-hidden="true">edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="ti-inflection-edit__read-only">
        <div class="ti-inflection-edit__read-only-key ti-inflection-edit__label">
          {{ 'COMMON.note' | translate}}
        </div>
        <div class="ti-inflection-edit__read-only-value ti-inflection-edit__inflected-form">
          <mat-form-field>
            <textarea
              matInput
              placeholder="{{ 'COMMON.note' | translate}}"
              formControlName="note"
              [(ngModel)]="inflection.note"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="ti-form__button-panel">
    <button mat-button [mat-dialog-close]="false">{{'COMMON.close' | translate}}</button>
    <button mat-button mat-raised-button color="accent" (click)="save()">{{'COMMON.save' | translate}}</button>
  </div>
</mat-dialog-actions>
