import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {LanguageService} from '../../shared/services/language.service';
import {Language} from '../../shared/models/language';

@Component({
  selector: 'app-universal-language-select',
  templateUrl: './universal-language-select.component.html',
  styles: ['mat-form-field, mat-form-field mat-select { min-width: 100% !important;}']
})

export class UniversalLanguageSelectComponent implements OnInit, OnChanges {
  @Input() description: string;
  @Input() initialLanguageId: number;
  @Input() hasToBeDisabled: boolean;
  @Input() isEmptyLanguageAllowed: boolean;
  @Input() setInitialLanguage: boolean;

  public languagesList: Array<Language>;
  public language: Language;
  public selectedLanguageId: number;

  @Output() selectedLanguageChanged: EventEmitter<Language> = new EventEmitter<Language>();

  constructor(private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getLanguagesList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setInitialLanguage && changes.setInitialLanguage.currentValue) {
      this.selectedLanguageId = this.initialLanguageId || 0;
    }
  }

  private getLanguagesList(): void {
    this.languageService.getLanguagesList().then((languagesList: Array<Language>) => {
      this.languagesList = languagesList as Array<Language>;

      if (this.initialLanguageId) {
        this.selectedLanguageId = this.initialLanguageId;
      }
    });
  }

  public onLanguageChange(): void {
    this.language = this.languagesList.find((language: Language) => (language.id === this.selectedLanguageId));

    if (this.initialLanguageId !== this.selectedLanguageId) {
      this.selectedLanguageChanged.emit(this.language);
    }
  }
}
