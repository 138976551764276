import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {RangeService} from '../../../shared/services/range.service';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {LinguisticRangeList} from '../../../shared/models/range';

import {applicationModulesRoutePaths, commonTabSettings} from '../../../shared/constants/application-constants';
import {SessionService} from '../../../shared/services/session.service';
import {LocalStorageHelper} from '../../../shared/helpers/localhost.helper';
import {RouterHelper} from '../../../shared/helpers/router.helper';
import {LampUpdateResponse} from '../../../shared/models/common';

@Component({
  selector: 'app-ranges-definitions',
  templateUrl: './ranges-definitions.component.html',
  styleUrls: ['./ranges-definitions.component.less']
})
export class RangesDefinitionsComponent implements OnInit, OnDestroy {
  @Input() isLookupMode: boolean = false;
  @Output() selectItemEvent: EventEmitter<number> = new EventEmitter();

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public doesUserHaveAccessToAddAndDelete: boolean = false;
  private sessionSubscription: Subscription;

  public pathToEditPages: string = applicationModulesRoutePaths.administration.administration.path +
    applicationModulesRoutePaths.administration.range.path;

  public rangeList: LinguisticRangeList[];

  public changedPreviouslySelector = commonTabSettings.changedPreviouslySelector;
  public visitedTable: string = '';
  public visitedRangeId: number;
  public keyIndexKey = commonTabSettings.keyIndexKey;
  public idIndexKey = commonTabSettings.idIndexKey;

  constructor(
    private rangeService: RangeService,
    private localStorageHelper: LocalStorageHelper,
    private sessionService: SessionService,
    private routerHelper: RouterHelper
  ) {}

  ngOnInit() {
    this.subscribeOnSessionChanges();
    this.getVisitedRangeTable();
    this.getVisitedRangeId();
    this.getRangeList();
  }

  private subscribeOnSessionChanges(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.doesUserHaveAccessToAddAndDelete = !(this.sessionService.isGuest() 
        || this.sessionService.isLinguistWhoIsAbleEditOnlyLexicon());
    });
  }

  private getRangeList(): Promise<void> {
    return this.rangeService.getRangeList()
      .then((rangeList) => {
        this.rangeList = (rangeList as LinguisticRangeList[]);
      });
  }

  public navigateToEditPage(rangeType: string, rangeId: number): void {
    let page = this.pathToEditPages;
    const queryParameters = {
      editedKey: rangeType,
      editedId: rangeId
    };

    page = page + '/edit/' + rangeType + '/' + rangeId;

    this.routerHelper.navigateToPageWithQueryParameters(page, queryParameters);
  }

  public deleteAfterUserConfirmation(doesUserConfirmDeletion: boolean, rangeId: number): Promise<void> {
    if (doesUserConfirmDeletion) {
      return this.rangeService.deleteRangeById(rangeId)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            return this.sessionService.refresh()
              .then(() => {
                this.getRangeList();
              });
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
    }

    return Promise.resolve();
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public navigateToCreatePage(rangeType: string): void {
    let page = this.pathToEditPages;
    const queryParameters = {
      editedKey: rangeType
    };

    page = page + '/create/' + rangeType;

    this.routerHelper.navigateToPageWithQueryParameters(page, queryParameters);
  }

  public reactOnSelectItemEvent(id: number) {
    this.selectItemEvent.emit(id);
  }

  private getCashedIndex(indexName: string): string {
    return this.localStorageHelper.getTabSetting(indexName);
  }
  public getVisitedRangeTable(): void {
    this.visitedTable = decodeURIComponent(this.getCashedIndex(this.keyIndexKey));
  }

  public getVisitedRangeId(): void {
    const currentIndex = parseInt(this.getCashedIndex(this.idIndexKey), 10);
    this.visitedRangeId = (currentIndex && !isNaN(currentIndex)) ? currentIndex : undefined;
  }

  ngOnDestroy(): void {
    this.sessionSubscription.unsubscribe();
  }
}
