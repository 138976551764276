import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar, MatSnackBarRef} from '@angular/material';
import {Subscription} from 'rxjs/Subscription';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {KnowledgeGraphService} from '../../shared/services/knowledge-graph.service';
import {KnowledgeGraphElement} from '../../shared/models/knowledge-graph';
import {applicationConstants, commonTabSettings} from '../../shared/constants/application-constants';
import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import {createFlatArrayCopy} from '../../shared/helpers/object.helper';
import {RouterHelper} from '../../shared/helpers/router.helper';
import * as _ from 'lodash';

type KnowledgeGraphStorage = {
  parameters: { key: string; value: string | number; isBasic: boolean},
  knowledgeGraphList: KnowledgeGraphElement[]
};

@Component({
  selector: 'app-knowledge-graph-menu',
  templateUrl: './knowledge-graph-menu.component.html',
  styleUrls: ['./knowledge-graph-menu.component.less'],

})
export class KnowledgeGraphMenuComponent implements OnInit, OnDestroy {
  public showSpinner: boolean = false;
  private routeSubscription: Subscription;
  private $event: { key: string, value: string | number };
  public isKnowledgeGraphModeBasic: boolean = false;
  public isNoFullRefresh = true;

  public knowledgeGraphList: KnowledgeGraphElement[];
  public filteredKnowledgeGraphList: KnowledgeGraphElement[];  
  public showingKowledgeGraphList: KnowledgeGraphElement[];
  public visitedElementId: number = 0;
  public idIndexKey = commonTabSettings.idIndexKey;
  public changedPreviouslySelector = commonTabSettings.changedPreviouslySelector;

  public errorMessageBehaviorSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubj.asObservable();
  public shouldBeEmittedScrollToEventEvent: boolean = false;
  public showingElementId: number;
  public expandedElementId: number;
  public checkedFamiliesList: { linkId: number, isLinkChecked: boolean, description: string } =
    {} as { linkId: number, isLinkChecked: boolean, description: string };
  private snackBarRef: MatSnackBarRef<any>;
  
  //localstorage key
  private isBasicModeKey = 'isknowledgeGraphBasicMode';
  private listKnowledgeGraphKey = "knowledgeGraphs";
  private refreshStatusKey = "knowledgeGraphNoFullRefreshStatus";
  private createdKnowledgeGraphKey = "newCreatedKnowledgeGraphId";
  
  private editKnowledgeGraphPath = "/knowledge-graph/advanced-edit";
  private familyPath = "/knowledge-graph/family-create";

  //refined filter
  public searchStr: string;

  constructor(private knowledgeGraphService: KnowledgeGraphService,
              private route: ActivatedRoute,
              private router: Router,
              private localStorageHelper: LocalStorageHelper,
              private snackBar: MatSnackBar,
              private routerHelper: RouterHelper) {
  }

  ngOnInit(): void {
    this.showSpinner = false;
    if (window.event) {
      window.event.stopPropagation();
    }
    this.getCurrentFeatureLocalizationValueIndex();
    
    this.isKnowledgeGraphModeBasic = this.localStorageHelper.getBooleanTabSetting(this.isBasicModeKey);
    this.isNoFullRefresh = this.localStorageHelper.getTabSetting(this.refreshStatusKey) !== 'NO';

    this.routeSubscription = this.route
      .queryParams
      .subscribe(routeParameters => {
        const parameters = routeParameters as { key: string, value: string | number };
        if (parameters.key && parameters.value) {
          this.$event = parameters;
          this.requireUpdatedEvent();          
        }
      });    
  }

  public reactOnUpdatedFamily($event): void {
    this.clearCheckedFamilies();
    this.dismissSnackBar();
  }

  public lexemeListUpdated($event): void {
    this.dismissSnackBar();
  }

  public saveTheCurrentId(id: number) {
    this.showErrorMessage('');
    this.showingElementId = id;
    this.localStorageHelper.setTabSetting(this.idIndexKey, id.toString());
  }

  public shortenedDisplay(str: string, upTo: number) {
    if (!str || str.length < upTo)
      return str;
    else
      return str.substr(0, upTo) + '...';
  }

  public filterWithChangedBasicMode(): void {
    this.localStorageHelper.setBoolTabSetting(this.isBasicModeKey, this.isKnowledgeGraphModeBasic == true);
    this.showErrorMessage('');
    this.clearCheckedFamilies();
    if (this.$event && !this.isKnowledgeGraphModeBasic) {
      this.filter(this.$event);
    }
  }

  private getCashedIndex(indexName: string): string {
    return this.localStorageHelper.getTabSetting(indexName);
  }

  public getCurrentFeatureLocalizationValueIndex(): void {
    let visitedElementId = parseInt(decodeURIComponent(this.getCashedIndex(this.idIndexKey)), 10);
    visitedElementId = (!isNaN(visitedElementId) && visitedElementId) ? visitedElementId : 0;
    this.visitedElementId = visitedElementId;
    this.showingElementId = visitedElementId;
  }

  public refinedFilter(searchString) {
    searchString = (searchString || '').trim().toLowerCase();
    const originalList: KnowledgeGraphElement[] = _.cloneDeep(this.knowledgeGraphList);
    if (searchString.length == 0) {
      this.filteredKnowledgeGraphList = null;
    } else {
      this.filteredKnowledgeGraphList = originalList.filter(e => {
        if (e.featureList && e.featureList.toLowerCase().indexOf(searchString) > -1)
          return true;

        return false;
      })
    }
    this.loadMore(true);
  }

  private filter(parameters: { key: string, value: string | number }): void {
    this.showErrorMessage('');
    this.clearCheckedFamilies();
    this.localStorageHelper.setTabSetting('knowledgePath', JSON.stringify(parameters));
    this.showSpinner = true;
    const searchArgument: string = parameters.value.toString();
    const searchArgumentType: string = parameters.key;
    const basic: string = (this.isKnowledgeGraphModeBasic) ? this.isKnowledgeGraphModeBasic.toString() : undefined;
    this.knowledgeGraphService.getKnowledgeGraph(searchArgument,
      searchArgumentType,
      basic).then((knowledgeGraphList: KnowledgeGraphElement[]) => {
      this.knowledgeGraphList = knowledgeGraphList;
      this.showingKowledgeGraphList = [];
      this.loadMore();
      this.saveCurrentState(true);
      this.showSpinner = false;
    });
  }

  public updateFamilies(commaDelimitedListToUpdate: string): void {
    this.clearCheckedFamilies();
    const basic: string = (this.isKnowledgeGraphModeBasic) ? this.isKnowledgeGraphModeBasic.toString() : undefined;
    const knowledgeGraphListCopy = createFlatArrayCopy(this.knowledgeGraphList) as KnowledgeGraphElement[];
    this.knowledgeGraphService.getKnowledgeGraph(commaDelimitedListToUpdate,
      'id',
      basic).then((knowledgeGraphList: KnowledgeGraphElement[]) => {
      if (knowledgeGraphList) {
        knowledgeGraphList.map((element) => {
          const knowledgeGraphElementIndex = knowledgeGraphListCopy.findIndex((knowledgeGraphElement) => (knowledgeGraphElement.id === element.id));
          if (knowledgeGraphElementIndex > -1) {
            this.knowledgeGraphList[knowledgeGraphElementIndex] = element;
            const showingLexemeIndex = this.showingKowledgeGraphList.findIndex(x => x.id == element.id);
            this.showingKowledgeGraphList[showingLexemeIndex] = element;
          }
        });
      }
    });
  }

  public collectDescriptions(): string {
    let descriptions: string = '';
    if (this.checkedFamiliesList) {
      const keys: string[] = Object.keys(this.checkedFamiliesList);

      for (let i = 0; i < keys.length; i++) {
        const key: number = parseInt(keys[i], 10);
        descriptions += this.checkedFamiliesList[key].description + '(#' + this.checkedFamiliesList[key].linkId + ')';
        if (i !== (keys.length - 1)) {
          descriptions += ', ';
        }
      }
    }

    return descriptions;
  }

  public showGoToButtonWithFamiliesIdsCommaDelimitedList($event: { linkId: number, isLinkChecked: boolean, description: string }): void {
    if ($event.isLinkChecked) {
      if (this.checkedFamiliesList) {
        this.checkedFamiliesList[$event.linkId] = $event;
      } else {
        this.checkedFamiliesList = {} as { linkId: number, isLinkChecked: boolean, description: string };
      }
    } else {
      delete this.checkedFamiliesList[$event.linkId];
    }

    const descriptions = this.collectDescriptions();
    if (descriptions) {
      this.snackBarRef = this.snackBar.open(descriptions, 'Go to', {
        duration: 200000,
        verticalPosition: 'bottom'
      });
    } else {
      this.dismissSnackBar();
    }

    this.snackBarRef.onAction().subscribe(() => {
      this.goto();
    });
  }

  private clearCheckedFamilies(): void {
    this.checkedFamiliesList = {} as { linkId: number, isLinkChecked: boolean, description: string };
  }

  public goto(): void {
    const checkedFamiliesIdsList = Object.keys(this.checkedFamiliesList).join(',');
    this.clearCheckedFamilies();
    this.showingElementId = 0;
    this.dismissSnackBar();
    this.routerHelper.navigateToTheSamePageWithQueryParameters({
      key: 'id',
      value: checkedFamiliesIdsList
    });
  }

  public showErrorMessage(errorMessage: string): void {
    this.errorMessageBehaviorSubj.next(errorMessage);
  }

  private dismissSnackBar(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  public loadMore(resetScroll = false) {
    this.showingKowledgeGraphList = resetScroll || !this.showingKowledgeGraphList ? [] : this.showingKowledgeGraphList;
    const knowledgeGraphList = this.filteredKnowledgeGraphList || this.knowledgeGraphList || [];
    const startAt = this.showingKowledgeGraphList.length > 0 ? this.showingKowledgeGraphList.length : 0;
    this.showingKowledgeGraphList = this.showingKowledgeGraphList.concat(knowledgeGraphList.slice(startAt, startAt + applicationConstants.loadmoreItem));
  }

  public async requireUpdatedEvent() {
    //reset top search box
    this.searchStr = '';
    this.filteredKnowledgeGraphList = null;

    if(this.isNoFullRefresh && this.isValidLocalStorage()){
      //load current list and update new lexeme to list
      this.loadLocalStorage();
      const knowledgeGraphId = this.getWorkingId();
      if (knowledgeGraphId) {
        const knowledgeGraphs = await this.knowledgeGraphService
        .getKnowledgeGraph(knowledgeGraphId.toString(), 'id', (this.isKnowledgeGraphModeBasic || false).toString());
        const knowledgeGraph = (knowledgeGraphs || [])[0];
        if (knowledgeGraph) {
          const foundIndex = this.knowledgeGraphList.findIndex(x => x.id === knowledgeGraphId);
          if (foundIndex > -1) {
            this.knowledgeGraphList[foundIndex] = knowledgeGraph;
            const showingLexemeIndex = this.showingKowledgeGraphList.findIndex(x => x.id == knowledgeGraphId);
            this.showingKowledgeGraphList[showingLexemeIndex] = knowledgeGraph;
          } else {
            this.knowledgeGraphList.push(knowledgeGraph);
            this.showingKowledgeGraphList.push(knowledgeGraph);
          }
        }
      }
    } else {
      this.filter(this.$event);
    }    
  }

  private getWorkingId(): number {
    const prevUrl = this.routerHelper.getPreviousUrl();

    //just edited the lexeme or family connection
    const matches = prevUrl.match(/\/?editedId=\d+/g); //["editedId=4036591"]
    const updateId =
      matches && matches.length > 0 && matches[0].split("=").length >= 1
        ? matches[0].split("=")[1]
        : null;
    if (updateId) {
      return Number.parseInt(updateId);
    } else {
      //just created lexeme
      const matches = prevUrl.match(/\/knowledge-graph\/family-create/g);
      if(matches && matches.length > 0) {
        return Number.parseInt(this.localStorageHelper.getTabSetting(this.createdKnowledgeGraphKey));
      }
    }
    return null;
  }

  public changeNoFullRefreshStatus($event) {
    this.localStorageHelper.setTabSetting(
      this.refreshStatusKey,
      $event.checked ? "YES" : "NO"
    );
    this.saveCurrentState(true);
  }

  private saveCurrentState(forceSave = false) {
    if (this.isNoFullRefresh) {
      const nextPath = this.router.routerState.snapshot.url;

      if (
        nextPath.indexOf(this.editKnowledgeGraphPath) > -1 ||
        nextPath.indexOf(this.familyPath) > -1 ||
        forceSave
      ) {
        const storageData: KnowledgeGraphStorage = {
          parameters: { ...this.$event, isBasic: this.isKnowledgeGraphModeBasic },
          knowledgeGraphList: this.knowledgeGraphList,
        };
        this.localStorageHelper.setTabSetting(
          this.listKnowledgeGraphKey,
          JSON.stringify(storageData)
        );
        return;
      }
    }
    this.localStorageHelper.clearTabSetting(this.listKnowledgeGraphKey);
    this.localStorageHelper.clearTabSetting(this.createdKnowledgeGraphKey);
  }

  /**
   * load local storage to this.knowledgeGraphList
   * @returns true if get list lexeme successfully else return false
   */
  loadLocalStorage(): boolean {
    this.knowledgeGraphList = [];
    if (this.isNoFullRefresh && this.isValidLocalStorage()) {
      let storageStr = this.localStorageHelper.getTabSetting(this.listKnowledgeGraphKey);
      if (storageStr && storageStr.length > 0) {
        const { knowledgeGraphList, parameters } = JSON.parse(storageStr) as KnowledgeGraphStorage;
        if (
          knowledgeGraphList &&
          knowledgeGraphList.length > 0 &&
          parameters.key == this.$event.key &&
          parameters.value == this.$event.value && 
          parameters.isBasic == this.isKnowledgeGraphModeBasic
        ) {
          if (knowledgeGraphList && knowledgeGraphList.length > 0) {
            this.knowledgeGraphList = knowledgeGraphList;
            this.loadMore(true);
          }
        }
      }
    }
    return this.knowledgeGraphList.length > 0;
  }

  private isValidLocalStorage(): boolean {
    const prevUrl = this.routerHelper.getPreviousUrl();
    const validNavUrl =
      prevUrl.indexOf(this.editKnowledgeGraphPath) > -1 || prevUrl.indexOf(this.familyPath) > -1;

    let storageStr = this.localStorageHelper.getTabSetting(
      this.listKnowledgeGraphKey
    );
    if (storageStr && storageStr.length > 0) {
      const { knowledgeGraphList, parameters } = JSON.parse(storageStr) as KnowledgeGraphStorage;
      const isMatchedFilterings =
        (knowledgeGraphList || []).length > 0 &&
        parameters.key == this.$event.key &&
        parameters.value == this.$event.value &&
        parameters.isBasic == this.isKnowledgeGraphModeBasic;

      if (isMatchedFilterings && validNavUrl) {
        return true;
      }
    }
    this.localStorageHelper.clearTabSetting(this.listKnowledgeGraphKey);
    return false;
  }

  getPanelTooltip(element: KnowledgeGraphElement) {
    element.definition = element.definition || '';
    if (element.featureList) {
      return element.definition + '\n\n' + element.featureList
    }
  }

  noLexemeInCurrentLang(element: KnowledgeGraphElement) {
    if(!element.lexemes || !element.lexemes.length) {
      return false;
    }
    return element.lexemes.filter(lex => lex.lemma.match(/[(][a-z]{2}([-][A-Z]{2})?[)][ ]+.+/g)).length == element.lexemes.length;
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.dismissSnackBar();
    this.saveCurrentState();
  }
}
