import {Injectable} from '@angular/core';

/**
 * Manages settings, saving and loading.
 */
@Injectable()
export class LocalStorageHelper {
  constructor() {
  }

  /**
   * Deletes all tab-level settings.
   */
  public clearSession() {
    sessionStorage.clear();
  }

  /**
   * Removes a tab-level setting.
   * @param key name of the setting
   */
  public clearTabSetting(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {}
  }

  /**
   * Sets a tab-level setting.
   * @param key name of the setting
   * @param data setting value
   * @returns 
   */
  public setTabSetting(key: string, data: string | undefined): void {
    if (data === undefined) return;
    sessionStorage[key] = data;
  }

  /**
   * Sets a boolean tab-level setting (knows to handle undefined booleans as well)
   * @param key name of the setting
   * @param data setting value
   */
  public setBoolTabSetting(key: string, data: Boolean): void {
    if (data == undefined)
      sessionStorage[key] = 'false';
    else
      sessionStorage[key] = data.toString();
  }

  /**
   * Sets a numeric tab-level setting (knows to handle undefined numerals as well).
   * @param key name of the setting
   * @param data setting value
   */
  public setNumberTabSetting(key: string, data: number | string): void {
    if (data == undefined)
      sessionStorage[key] = '0';
    else
      sessionStorage[key] = data.toString();
  }

  /**
   * Gets a tab-level setting.
   * @param key name of the setting
   * @returns 
   */
  public getTabSetting(key: string): string {
    let settingValue = sessionStorage[key];
    if (settingValue == undefined)
      return "";
    else
      return settingValue;
  }
  
  public getNumberTabSetting(key: string): number {
    let val = sessionStorage[key];
    if (val == undefined)
      return 0;
    else
      return parseInt(val);
  }
  
  /**
   * Gets a boolean tab-level setting.
   * @param key name of the setting
   * @returns 
   */
  public getBooleanTabSetting(key: string): boolean {
    let val = sessionStorage[key];
    if (!(val == 'true' || val == true || val > 0))
      return false;
    else
      return Boolean(val);
  }
  
  /**
   * Clears a browser-level setting
   * @param key name of the setting
   */
  public clearGlobalSetting(key: string): void {
    try {
      localStorage.removeItem(key);
    } 
    catch(error) {}
  }

  /**
   * Sets a browser-level setting.
   * @param key name of the setting
   * @param data setting value
   */
  public setGlobalSetting(key: string, data: string): void {
    localStorage[key] = data;
  }

  /**
   * Gets a browser-level setting
   * @param key name of the setting
   * @returns 
   */
  public getGlobalSetting(key: string): any {
    return localStorage[key];
  }

}
