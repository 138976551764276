import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {AliasListEditDialogComponent} from './alias-list-edit-dialog.component';


@Directive({selector: '[appOpenAliasEditDialog]'})

export class ButtonOpensAliasEditDialogDirective {
  @Input('phonemeId') phonemeId: number;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openAliasEditDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(AliasListEditDialogComponent, {
      data: {phonemeId: this.phonemeId},
      maxWidth: this.isMobileView ? '96%' : '60%'
    });

    dialogRef.beforeClosed().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });
  }
}
