import { Directive, HostListener, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';

import { KnowledgeGraphDialogComponent } from './knowledge-graph-dialog.component';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';
import { Lexeme } from '../../shared/models/lexicon';

@Directive({ selector: '[appOpenKnowledgeGraphDialog]' })

export class ButtonOpensKnowledgeGraphDialogDirective {
  @Input('isLexemesDialog') isLexemesDialog: boolean;
  @Input('isFamilyDialog') isFamilyDialog: boolean;
  @Input('isAutoSuggestionLexemeDialog') isAutoSuggestionLexemeDialog: boolean;
  @Input() familyId: number;
  @Input() byText: string;
  @Input() chooseOnlyOneItem: boolean;
  @Input() allowOtherLanguages: boolean;
  @Output() beforeDialogOpenedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() afterDialogClosedEvent: EventEmitter<string> = new EventEmitter();
  @Output() isLoadingEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog, private knowledgeGraphService: KnowledgeGraphService, private snackBar: MatSnackBar) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') async openDialog() {
    let lexemesList: Lexeme[];
    if (this.isAutoSuggestionLexemeDialog && this.familyId) {
      this.isLoadingEvent.emit(true);
      lexemesList = await this.knowledgeGraphService.getSuggestedLexemesList(this.familyId);
      this.isLoadingEvent.emit(false);
      if (lexemesList && lexemesList[0]) {
        if (lexemesList.length && lexemesList[0].id == -1) {
          this.snackBar.open('Detected missing lexemes by Generative AI', "Close", {
            duration: 3000
          });
          this.afterDialogClosedEvent.emit('-1');
          return;
        }
      }
    }

    this.beforeDialogOpenedEvent.emit(true);
    const dialogRef: MatDialogRef<any> = this.dialog.open(KnowledgeGraphDialogComponent, {
      minWidth: this.isMobileView ? '96%' : '80%',
      data: {
        isLexemesDialog: this.isLexemesDialog,
        isFamilyDialog: this.isFamilyDialog,
        isAutoSuggestionLexemeDialog: this.isAutoSuggestionLexemeDialog,
        familyId: this.familyId,
        byText: this.byText,
        chooseOnlyOneItem: this.chooseOnlyOneItem,
        allowOtherLanguages: this.allowOtherLanguages,
        lexemesList: lexemesList
      }
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });

    dialogRef.afterClosed().subscribe((chosenData: any) => {
      if (chosenData) {
        this.afterDialogClosedEvent.emit(chosenData);
      }
    });
  }
}
