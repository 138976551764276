import {Component, OnInit} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {errorMessages} from '../../shared/constants/application-constants';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit {
  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  constructor() {
  }

  ngOnInit() {
    this.errorMessageBehaviorSubject.next(errorMessages.pageDoesNotExist);
  }

}
