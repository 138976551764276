<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{ 'lexicon.advanced-criteria-lookup' | translate }} ({{dialogTitle}})</h2>
  <mat-dialog-actions class="ti-mat-dialog-actions---end">
    <button
      mat-raised-button
      color="accent"
      appAdvancedCriteriaOpenEditDialog
      [isEdit]="false"
      [forWords]="forWords"
    >{{'COMMON.add-new' | translate}}
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
  <app-advanced-criteria
    (selectAdvancedCriteriaItemEvent)="reactOnSelectAdvancedCriteriaItem($event)"
    (sessionInterrupted)="reactOnSessionInterrapted();"
  ></app-advanced-criteria>
</mat-dialog-content>
<mat-dialog-actions class="ti-actions">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
  <button mat-raised-button color="accent" [mat-dialog-close]="advancedCriteria">{{'COMMON.select' | translate}}</button>
</mat-dialog-actions>
