<div class="ti-login-container-wrapper">
  <div class="ti-login-container">

    <h1 class="ti-login__header">
      {{"login.sign-in"|translate}}
    </h1>
    <mat-progress-bar
      *ngIf="loginProgress"
      color="accent"
      mode="indeterminate">
    </mat-progress-bar>
    <form
      [formGroup]="credentialsForm"
      (ngSubmit)="login()">
      <p
        *ngIf="!loginProgress && isLoginError && !doesUserTrySubmitForm"
        class="ti-login__error-message">
        &nbsp;<span translate="login.please-check-the-entered-data"></span>
        <span style="white-space: pre-line"></span>
        <a href="http://www.tisane.ai/contact-us/#support"
           translate="login.contact-our-technical-support"></a>
        &nbsp;&nbsp;&nbsp;<span translate="login.if-the-data-is-correct-please"></span>
      </p>
      <mat-form-field
        color="accent">
        <input matInput formControlName="loginData"
               aria-describedby="emailHelp" name="login"
               (keyup)="hideErrorMessage()"
               (click)="onInputClick()"
               placeholder="{{ 'login.login-email-or-phone' | translate}}">
      </mat-form-field>
      <mat-form-field color="accent" class="example-full-width">
        <input matInput
               formControlName="password"
               type="password"
               name="password"
               (keyup)="hideErrorMessage()"
               (click)="onInputClick()"
               id="exampleInputPassword1"
               placeholder="{{ 'login.password' | translate}}"/>
      </mat-form-field>
      <p class="ti-login__problems">
        <span translate="login.problems-signing-in">
        </span>
        &nbsp;&nbsp;&nbsp;<a href="http://www.tisane.ai/contact-us/#support"
           translate="login.contact-our-technical-support"></a>
      </p>
      <div class="ti-login__submit-button-panel">
        <button color="accent"
                type="submit"
                mat-raised-button
                [disabled]="loginProgress"
                (click)="login()">{{ 'login.sign-in' | translate}}
        </button>
        <a class="ti-link-underline-text-decoration ti-login__submit-link-as-a-guest"
           color="accent"
           (click)="signInAsGuest()">{{"login.sign-in"|translate}} {{"login.as-a-guest"|translate}}
        </a>
      </div>
    </form>
  </div>
</div>
