import {Component, OnInit} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {ExtraHypothesisService} from '../../shared/services/extra-hypothesis.service';
import {ExtraHypothesis} from '../../shared/models/extra-hypothesis';
import {LampUpdateResponse} from '../../shared/models/common';

@Component({
  selector: 'app-extra-hypothesis',
  templateUrl: 'extra-hypothesis.component.html'
})
export class ExtraHypothesisComponent implements OnInit {
  public extraHypothesisList: ExtraHypothesis[];

  public showProgressBar: boolean = false;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  constructor(private extraHypothesisService: ExtraHypothesisService) {
  }

  ngOnInit(): void {
    this.getHypothesisList();
  }

  public getHypothesisList(): Promise<void> {
    this.showProgressBar = true;
    return this.extraHypothesisService.getExtraHypothesisList()
      .then((extraHypothesisList: ExtraHypothesis[]) => {
        this.showProgressBar = false;
        this.extraHypothesisList = extraHypothesisList;
      });
  }

  public deleteExtraHypothesis(id: number): Promise<void> {
    this.showProgressBar = true;
    return this.extraHypothesisService.deleteExtraHypothesis(id)
      .then((message: LampUpdateResponse) => {
        this.showProgressBar = false;
        if (message.success) {
          this.getHypothesisList();
        } else {
          this.showErrorMessage(message.error);
        }
      });
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }
}
