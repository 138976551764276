import { Directive, HostListener, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';

import { DomainGenerationDialogComponent } from './domain-generation-dialog.component';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';
import { Lexeme } from '../../shared/models/lexicon';

@Directive({ selector: '[appOpenDomainGenerationDialog]' })

export class ButtonOpensDomainGenerationDialogDirective {
  @Input() familyId: number;
  @Output() beforeDialogOpenedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() afterDialogClosedEvent: EventEmitter<string> = new EventEmitter();
  @Output() isLoadingEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog, private knowledgeGraphService: KnowledgeGraphService, private snackBar: MatSnackBar) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') async openDialog() {
    this.beforeDialogOpenedEvent.emit(true);
    const dialogRef: MatDialogRef<any> = this.dialog.open(DomainGenerationDialogComponent, {
      minWidth: this.isMobileView ? '96%' : '60%',
      data: {
        familyId: this.familyId
      },
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });

    dialogRef.afterClosed().subscribe((chosenData: any) => {
      this.afterDialogClosedEvent.emit(chosenData);
    });
  }
}
