/**
 * Column of a table in a database used in a data range. 
 */
export interface LinguisticRangeColumn {
  Key: string; // Column's name (edit only)	The name of the column in the SQL database.
  Value: { // Column's details (edit only)	The details of the column as described below.
    description?: string;
    // The description of the column. If available, must be shown in brackets next to the name. E.g. MyColumn (my description).
    listOfValues?: string; // An SQL code snippet to list a set of values for the column.
    substring?: string; /* An SQL code snippet to look up a substring in the column value.
     Only active if the column is a string.
     If the attribute is missing, the Substring button must be disabled.*/
    relatedRecords?: { // Snippet for a lookup in related tables (edit only). SQL code snippets to list a set of values for the column.
      Key: string; // Name of the related table (edit only)	The table to generate the snippet for.
      Value: string; // SQL snippet for the related table (edit only)
    }[];
  };
}

/**
 * Data range.
 */
export interface LinguisticRange {
  requestId?: string;
  active?: boolean;
  columns?: LinguisticRangeColumn[];
  description?: string;
  example?: string;
  explanation?: string;
  global?: boolean;
  fromId?: number;
  toId?: number;
  id?: number;
  languageId?: number;
  language?: number;
  orderBy?: string;
  relatedTables?: string[];
  showInCompilationJobs?: boolean;
  type?: string;
  where?: string;
}

export interface LinguisticRangeLocalization {
  requestId?: string;
  description?: string;
  languageId?: number;
  rangeId?: number;
  index?: number; // only for frontend purpose
}

export const emptyLinguisticRangeLocalization: LinguisticRangeLocalization = {
  description: '', languageId: 0, rangeId: 0
};

export interface LinguisticRangeForListView {
  Key: string;
  Value: LinguisticRange;
}

export const emptyLinguisticRange: LinguisticRange = {
  active: true,
  description: '',
  showInCompilationJobs: true,
  global: false,
  example: '',
  explanation: '',
  languageId: 0,
  orderBy: '',
  type: '',
  where: '',
};

export interface LinguisticRangesForLanguage {
  Key: string;
  Value: LinguisticRangeForListView[];
}


export interface LinguisticRangeList {
  Key: string;
  Value: LinguisticRangesForLanguage[];
}

export interface RangeForLookupTable {
  id: number;
  description: string;
  table: string;
}