import {Injectable} from '@angular/core';

import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import {IdentityRole, AdvancedIdentityRole} from '../models/identity-roles';
import {LampUpdateResponse} from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class IdentityRolesService {

  constructor(private apiClientService: ApiClientService) {}

  public getIdentityRolesList(familyId: number): Promise<IdentityRole[]> {
    return this.apiClientService.get(`${EndpointUrls.identityRoles.identityroleList}?family=${familyId}`);
  }

  public getIdentityRoleById(identityId: number): Promise<AdvancedIdentityRole> {
    return this.apiClientService.get(`${EndpointUrls.identityRoles.identityrole}?id=${identityId}`);
  }

  public createIdentityRole(identityRole: AdvancedIdentityRole): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.identityRoles.identityrole, identityRole);
  }

  public updateIdentityRole(identityRole: AdvancedIdentityRole): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.identityRoles.identityrole, identityRole);
  }

  public deleteIdentityRole(identityId: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.identityRoles.identityrole}?id=${identityId}`);
  }

  private _cachedIdentityDesc: { [key: string]: string } = {};
  public async getIdentityDescription(family: number, identity: number): Promise<string> {
    const key = `${family}_${identity}`;
    if(this._cachedIdentityDesc[key])
      return Promise.resolve(this._cachedIdentityDesc[key]);
    
    // prevent call same family & identity concurent
    if(this._cachedIdentityDesc[key] === null)
      return Promise.resolve(this._cachedIdentityDesc[key]);


    this._cachedIdentityDesc[key] = null;
    return this.apiClientService.get(`${EndpointUrls.identityRoles.identityDescription}?family=${family}&identity=${identity}`)
    .then((description) => {
      this._cachedIdentityDesc[key] = description;
      return Promise.resolve(this._cachedIdentityDesc[key]);
    })
  }

}
