import {FeatureValue, ModelWithRelevantFeature} from './feature';

export interface ExtraHypothesis extends ModelWithRelevantFeature {
  advancedCriteriaDescription?: string;
  advancedCriteriaId?: number;
  assigned?: FeatureValue[];
  assignedGrammar?: FeatureValue[];
  assignedSemantics?: FeatureValue[];
  assignedStyle?: FeatureValue[];
  assignedDescriptions?: string;
  charactersToDelete?: number; // this is because of a faulty design that combined it with interfixes
  id?: number;
  text?: string;
  followingWordAdvancedCriteriaDescription?: string;
  followingWordAdvancedCriteriaId?: number;
  incompatibleGrammar?: FeatureValue[];
  incompatibleDescriptions?: string;
  legacyId?: number;
  note?: string;
  required?: FeatureValue[];
  requiredGrammar?: FeatureValue[];
  requiredDescriptions?: string;
  incompatible?: FeatureValue[];
  incompatibleSemantics?: FeatureValue[];
  incompatibleStyle?: FeatureValue[];
  requiredSemantics?: FeatureValue[];
  requiredStyle?: FeatureValue[];
}

export const emptyHypothesis: ExtraHypothesis = {
  advancedCriteriaDescription: '',
  advancedCriteriaId: null,
  assignedGrammar: null,
  assignedDescriptions: '',
  id: null,
  incompatibleGrammar: null,
  incompatibleDescriptions: '',
  legacyId: null,
  note: '',
  requiredGrammar: null,
  requiredDescriptions: '',
  assignedSemantics: [],
  assignedStyle: [],
  requiredSemantics: [],
  requiredStyle: []
};
