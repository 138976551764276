import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

import {LexiconService} from '../../shared/services/lexicon.service';
import {Lexeme} from '../../shared/models/lexicon';
import {LampUpdateResponse} from '../../shared/models/common';


@Component({
  selector: 'app-lexeme-merge',
  templateUrl: './lexeme-merge.component.html'
})
export class LexemeMergeComponent implements OnInit, OnDestroy {
  @Input() duplicatesIds: string;
  @Input() currentLexeme: Lexeme;
  @Input() mergeLexemesObservable: Observable<boolean>;
  @Output() mergeResultEvent: EventEmitter<LampUpdateResponse> = new EventEmitter();

  private subscription: Subscription;

  public showSpinner: boolean;
  public lexemesForMerge: Lexeme[];
  public displayedColumns: string[];

  constructor(private lexiconService: LexiconService) {
    this.displayedColumns = ['lexeme-id', 'description'];
  }

  ngOnInit(): void {
    this.getLexemesForMerge();
    this.subscription = this.mergeLexemesObservable.subscribe((shouldMergeLexemes) => {
      if (shouldMergeLexemes) {
        this.mergeLexemes();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getLexemesForMerge(): Promise<void> {
    this.showSpinner = true;

    return this.lexiconService.getLexicon('id', this.duplicatesIds).then((lexemesForMerge: Lexeme[]) => {
      this.showSpinner = false;
      this.lexemesForMerge = lexemesForMerge;
    });
  }

  private mergeLexemes(): Promise<void> {
    this.showSpinner = true;

    return this.lexiconService.mergeLexemes(this.currentLexeme.id, this.duplicatesIds)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        this.showSpinner = false;
        this.mergeResultEvent.next(successfulResponseMessage);
      });
  }
}
