<div class="ti-form-wrapper ti-feature-localization-edit-form">
  <form class="ti-form" *ngIf="featureLocalizationEditForm" [formGroup]="featureLocalizationEditForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <h1>{{ (isFeatureLocalizationValueEditPage ? 'feature-localization.feature-edit' :
      'feature-localization.feature-list-edit') | translate}}</h1>
    <div class="ti-form__one-line-fields">
      <p class="ti-form__label">{{ 'COMMON.feature-index' | translate}}:
        <span class="ti--bold-text"> {{featureListLocalizationId}}</span></p>
      <p class="ti-form__label" *ngIf="isFeatureLocalizationValueEditPage">{{'COMMON.value' | translate}}:
        <span class="ti--bold-text"> {{featureLocalizationValue}}</span></p>
    </div>
    <div class="ti-form__one-line-fields">
      <div class="ti-form__column-fields">
        <span class="ti-form__label">{{ 'COMMON.description' | translate}}: {{featureLocalization.systemDescription}}</span>
        <mat-form-field color="accent">
      <textarea matInput formControlName="description" [(ngModel)]="featureLocalization.description"
                (keypress)="clearTheErrorMessage()"
                placeholder="{{ 'COMMON.description' | translate}}"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="tag" name="tag" [(ngModel)]="featureLocalization.tag"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'feature-localization.dictionary-tag' | translate}}">
      </mat-form-field>
    </div>


    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="referenceUrl" name="referenceUrl"
               [(ngModel)]="featureLocalization.referenceUrl"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'COMMON.reference-url' | translate}}">
      </mat-form-field>
    </div>
    <div *ngIf="!isFeatureLocalizationValueEditPage" class="ti-form__one-line-fields ti-form__behavior">
      <p class="ti-form__label ti-form__column--label-for-radio-buttons">
        {{'feature-localization.copy-to-phrase-from-element-when-marked' | translate}}</p>
      <mat-radio-group color="accent" class=" ti-form__column" [(ngModel)]="featureLocalization.copyToPhrase"
        formControlName="copyToPhrase" name="copyToPhrase">
        <mat-radio-button class="ti-form__radio-button" [value]="''">
          <p class="ti-form__radio-label">Default</p>
        </mat-radio-button>
        <mat-radio-button class="ti-form__radio-button" [value]="'0'">
          <p class="ti-form__radio-label">No</p>
        </mat-radio-button>
        <mat-radio-button class="ti-form__radio-button" [value]="'1'">
          <p class="ti-form__radio-label">Yes</p>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="wiktionaryTag" name="wiktionaryTag" [(ngModel)]="featureLocalization.wiktionaryTag"
          (keypress)="clearTheErrorMessage()" placeholder="{{ 'feature-localization.wiktionary-tag' | translate}}">
      </mat-form-field>
    </div>
    <div class="ti-form__button-panel">

      <button mat-raised-button
              (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>
      <button
        mat-raised-button
        color="accent"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
