<div class="ti-form-wrapper">
  <form class="ti-form" *ngIf="rangeEditForm" [formGroup]="rangeEditForm">
    <h1>{{ (isNew ? 'range.range-creation' : 'range.range-edit') | translate}}</h1>
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <mat-progress-bar *ngIf="showProgressbar" color="accent" mode="indeterminate"></mat-progress-bar>
    <div class="ti-form__one-line-fields" *ngIf="!isNew">
      <p class="ti-form__label">ID: <span
        class="ti-language-settings--bold-text">{{range.id}}</span></p>
      </div>
    <div class="ti-form__one-line-fields">
      <div class="ti-form__half-line-fields">
        <div class="ti-form-half-line-fields__field">
          <app-universal-language-select
            [initialLanguageId]="initialLanguageId"
            [description]="'COMMON.language' | translate"
            [isEmptyLanguageAllowed]="true"
            [hasToBeDisabled]="!doesUserHaveAccessToEdit"
            (selectedLanguageChanged)="updateLanguage($event)">
          </app-universal-language-select>
        </div>
      </div>
      <div class="ti-form__half-line-fields ti-form__one-line-fields--with-checkbox">
        <mat-checkbox
          class="ti-form-half-line-fields__field"
          matInput
          name="active"
          formControlName="active"
          appDisableControl
          [disableControl]="!doesUserHaveAccessToEdit"
          [(ngModel)]="range.active"
          (click)="clearTheErrorMessage()">
        <span
          class="ti-form__column--label-for-radio-buttons">{{'COMMON.active' | translate}}</span>
        </mat-checkbox>
      </div>
    </div>
    <div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
      <mat-checkbox
        matInput formControlName="global" name="global"
        (click)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="range.global">
        <span
          class="ti-form__column--label-for-radio-buttons">{{'COMMON.global' | translate}}</span>
      </mat-checkbox>
    </div>
    <div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
      <mat-checkbox
        matInput formControlName="showInCompilationJobs" name="showInCompilationJobs"
        (click)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="range.showInCompilationJobs">
        <span
          class="ti-form__column--label-for-radio-buttons">{{'range.show-in-compilation-jobs' | translate}}</span>
      </mat-checkbox>
    </div>

    <div class="ti-form__one-line-fields">
      <div class="ti-form__half-line-fields">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent">
          <input
            matInput
            formControlName="description"
            name="description"
            [(ngModel)]="range.description"
            (keypress)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!doesUserHaveAccessToEdit"
            placeholder="{{ 'COMMON.description' | translate}}">
        </mat-form-field>
      </div>

      <div class="ti-form__half-line-fields">
        <button
          class="ti-link-underline-text-decoration ti-link-button ti-blue-link"
          appOpenRangeLocalizationDialog
          [doesUserHaveAccessDelete]="doesUserHaveAccessToEdit"
          [rangeId]="range.id"
          [disabled]="isNew"
        >{{'range.localize-description' | translate}}
        </button>
      </div>
    </div>

    <div class="ti-form__one-line-fields ti-range__column-dir">
      <div class="ti-form__half-line-fields">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent">
          <input
            matInput
            formControlName="fromId"
            name="fromId"
            [(ngModel)]="range.fromId"
            (keypress)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!doesUserHaveAccessToEdit"
            placeholder="{{ 'COMMON.from-id' | translate}}">
        </mat-form-field>
      </div>
      <div class="ti-form__half-line-fields">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent">
          <input
            matInput
            formControlName="toId"
            name="toId"
            [(ngModel)]="range.toId"
            (keypress)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!doesUserHaveAccessToEdit"
            placeholder="{{ 'COMMON.to-id' | translate}}">
        </mat-form-field>
      </div>
    </div>
    <div class="ti-form__one-line-fields ti-range__column-dir ti-form_where-part">
      <div class="ti-form__half-line-fields">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent">
        <textarea
          matInput
          appDisableControl
          [disableControl]="!doesUserHaveAccessToEdit"
          formControlName="where" name="where" [(ngModel)]="range.where"
          (keypress)="clearTheErrorMessage()"
          placeholder="{{ 'range.where-clause' | translate}}"></textarea>
        </mat-form-field>
      </div>

      <div class="ti-form__half-line-fields">
        <div class="ti-form-half-line-fields__field ti-form__column-fields">
          <mat-form-field color="accent">
            <mat-select
              role="listbox"
              placeholder="{{ 'COMMON.column' | translate}}"
              [(value)]="choosenColumn"
              (selectionChange)="getRelatedRecords()"
            >
              <mat-option role="option" *ngFor="let column of range.columns" [value]="column">{{column.Key}}
                {{(column.Value.description) ? '(' + column.Value.description + ')' : '' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="ti-form__column-fields ti-form__column-fields--buttons" *ngIf="choosenColumn">
            <button
              [disabled]="!choosenColumn.Value.substring"
              class="ti-link-underline-text-decoration ti-link-button ti-blue-link"
              (click)="addQueryToWhereClause(choosenColumn.Value.substring)">{{'range.substring' | translate}}
            </button>
            <button
              [disabled]="!choosenColumn.Value.listOfValues"
              class="ti-link-underline-text-decoration ti-link-button ti-blue-link"
              (click)="addQueryToWhereClause(choosenColumn.Value.listOfValues)">{{'range.list-of-values' | translate}}
            </button>
            <button
              *ngFor="let relatedTableName of range.relatedTables"
              class="ti-link-underline-text-decoration ti-link-button ti-blue-link"
              [disabled]="!relatedRecords[relatedTableName]"
              (click)="addQueryToWhereClause(relatedRecords[relatedTableName].Value)">{{relatedTableName}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ti-form__one-line-fields ti-range__column-dir">
      <div class="ti-form__half-line-fields">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent">
          <textarea
            matInput
            formControlName="orderBy"
            name="orderBy"
            [(ngModel)]="range.orderBy"
            (keypress)="clearTheErrorMessage()"
            placeholder="{{ 'range.order-by-clause' | translate}}">
          </textarea>
        </mat-form-field>
      </div>

      <div class="ti-form__half-line-fields">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent">
          <mat-select
            role="listbox"
            placeholder="{{ 'COMMON.column' | translate}}"
            [(value)]="choosenColumnKeyForOrderBy"
            (selectionChange)="addOrderBy()"
          >
            <mat-option role="option" *ngFor="let column of range.columns" [value]="column.Key">{{column.Key}}
              {{(column.Value.description) ? '(' + column.Value.description + ')' : '' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="ti-form__button-panel">
      <button
        mat-raised-button
        (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>
      <button
        mat-raised-button
        [disabled]="!doesUserHaveAccessToEdit"
        color="accent"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
