import { Injectable } from '@angular/core';

import { ApiClientService } from './api-client.service';
import { EndpointUrls } from '../constants/endpoint';
import { Affix, AffixTrigger, InflectionPattern, EditedInflectionPatternAffix, ListInflectionPattern, AffixTest } from '../models/affixes.model';
import { LampUpdateResponse } from '../models/common';

/**
 * Invokes web methods related to the Affixes module.
 * The module is currently under Morphology > Inflection and Morphology > Tagging. 
 */
@Injectable()
export class AffixesService {

  /**
   * Creates a new affixes service.
   * @param apiClientService Communicates with the LaMP API.  
   */
  constructor(private apiClientService: ApiClientService) {
  }

  /**
   * Gets the hierarchy of affixes (affix triggers with the lists of inflection patterns inside) 
   * @param tagging 
   * @param arg 
   * @param type 
   * @returns 
   */
  public getAffixesList(tagging: boolean, arg: string, type: string): Promise<Affix[]> {
    return this.apiClientService.get(`${EndpointUrls.affixes.affixList}?tagging=${tagging}&arg=${arg}&type=${type}`);
  }

  /**
   * Gets all details of a affix trigger specified by its ID (usually for editing).
   * @param affixTriggerId a trigger ID
   * @returns 
   */
  public getAffixTrigger(affixTriggerId: number | string): Promise<AffixTrigger> {
    return this.apiClientService.get(`${EndpointUrls.affixes.affixTrigger}?id=${affixTriggerId}`);
  }

  /**
   * Creates a new affix trigger. 
   * @param affixTrigger 
   * @returns 
   */
  public createAffixTrigger(affixTrigger: AffixTrigger): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.affixes.affixTrigger, affixTrigger);
  }

  /**
   * Updates the currently edited affix trigger, according to the request Id. 
   * @param affixTrigger 
   * @returns 
   */
  public updateAffixTrigger(affixTrigger: AffixTrigger): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.affixes.affixTrigger, affixTrigger);
  }

  /**
   * Deletes a specified affix trigger.
   * @param affixTriggerId 
   * @returns 
   */
  public deleteAffixTrigger(affixTriggerId: number | string): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.affixes.affixTrigger}?id=${affixTriggerId}`);
  }

  /**
   * Gets all details of an inflection pattern by its ID (usually for editing)
   * @param inflectionRuleId 
   * @returns 
   */
  public getInflectionPattern(inflectionRuleId: number | string): Promise<InflectionPattern> {
    return this.apiClientService.get(`${EndpointUrls.affixes.inflectionRule}?id=${inflectionRuleId}`);
  }

  /**
   * Creates a new inflection pattern. 
   * @param inflectionRule 
   * @returns 
   */
  public createInflectionPattern(inflectionRule: ListInflectionPattern): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.affixes.inflectionRule, inflectionRule);
  }

  /**
   * Updates the currently edited inflection pattern, according to the request ID
   * @param inflectionRule 
   * @returns 
   */
  public updateInflectionPattern(inflectionRule: ListInflectionPattern): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.affixes.inflectionRule, inflectionRule);
  }

  /**
   * Deletes a specified inflection pattern.
   * @param inflectionRuleId 
   * @returns 
   */
  public deleteInflectionPattern(inflectionRuleId: number | string): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.affixes.inflectionRule}?id=${inflectionRuleId}`);
  }

  /**
   * Generates a sample how a word will look like with the inflection pattern applied. 
   * E.g. pre-*****s when the affixes are 'pre-' and 's'.
   * @param inflectionPattern 
   * @returns 
   */
  public generateInflectionSampleText(inflectionPattern: InflectionPattern): Promise<string> {
    return this.apiClientService.post(EndpointUrls.affixes.inflectionSample, inflectionPattern);
  }

  public getAffixTestList(affixGroupId: string): Promise<AffixTest[]> {
    return this.apiClientService.get(`${EndpointUrls.affixes.affixTestList}?id=${affixGroupId}`);
  }

  public deleteAffixTest(affixTestId: number | string): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.affixes.affixTest}?id=${affixTestId}`);
  }

  public createAffixTest(affixTest: AffixTest): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.affixes.affixTest, affixTest);
  }

  public updateAffixTest(affixTest: Partial<AffixTest>): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.affixes.affixTest, affixTest);
  }
}
