import { Component, OnInit } from '@angular/core';
import { TestsetService } from '../../shared/services/testset.service';
import { SessionService } from '../../shared/services/session.service';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { TestResultSet, TestResult } from '../../shared/models/test.model';
import { LampUpdateResponse } from '../../shared/models/common';
import { FeatureValueDefinition } from '../../shared/models/feature';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { applicationConstants } from '../../shared/constants/application-constants';

@Component({
    selector: 'result-review-transformation',
    templateUrl: './result-review-transformation.component.html',
    styleUrls: ['./result-review-transformation.component.less']
})

export class ResultReviewTransformationComponent extends Translated implements OnInit {

    public results: TestResultSet[];
    public showingResults: TestResultSet[] = [];
    public selectedId: number | string = '';
    public selectedTestProvider: number;
    public ratings: string[] = [];
    public entitySubtypes: FeatureValueDefinition[];
    public testProviderValues: { key?: string | number, value?: string }[];

    public interpretRating(value: number): string {
        if (value < 0 || value >= this.ratings.length)
            return '';
        return this.ratings[value];
    }

    panelTitle(res: TestResultSet): string {
        let title: string = '';
        if (!res.fragment && res.fragment.length < 300)
            return title + res.fragment;
        else
            return title + res.fragment.substr(0, 300);
    }

    ngOnInit(): void {
        this.initFromSession();
        this.getTestProviderSelectOpts();
        const subs = this._route.queryParams.subscribe(params => {
            this.selectedTestProvider = params['testProviderId'] ? Number.parseInt(params['testProviderId']) : null;
            if (subs) {
                subs.unsubscribe();
            }
        });
    }

    initThisSubtypeFromSession(): void {
        this.ratings.push(this.translateService.instant('TEST.unrated'));
        for (let ri = 1; ri <= 10; ri++)
            this.ratings.push(this.translateService.instant('TEST.rating' + ri));
    }


    public async getTestProviderSelectOpts() {
        const results = await this._service.getTestproviderList();
        this.testProviderValues = results.map((item) => {
            return { key: item.id, value: item.description }
        })
    }

    public getAbuseEmoji(ts: TestResultSet): string {
        let emoji: string = '';
        for (let ab of ts.abuse) {
            let current: string = this.abuse2Emoji(ab.label);
            if (current && !emoji.includes(current))
                emoji += current;
        }
        return emoji;
    }

    public getSentimentEmoji(ts: TestResultSet): string {
        let emoji: string = '';
        for (let ab of ts.sentiment) {
            let current: string = this.sentiment2Emoji(ab.label);
            if (current && !emoji.includes(current))
                emoji += current;
        }
        return emoji;

    }

    public getEntityEmoji(ts: TestResultSet): string {
        let emoji: string = '';
        for (let ab of ts.entities) {
            let current: string = this.entity2Emoji(ab.label);
            if (current && !emoji.includes(current))
                emoji += current;
        }
        return emoji;

    }

    public resultText(fragment: string, r: TestResult, name: string): string {
        return this._service.resultText(fragment, r, name);
    }

    public save(testResultSet: TestResultSet, raw: string) {
        this.clearTheErrorMessage();
        this.disableRefresh();

        testResultSet.raw = raw;

        if (testResultSet.id) {
            this._service.updateTransformationResult(testResultSet).then((response: LampUpdateResponse) => {
                return this.reactOnSuccessfulResponseMessage(response);
            });
        } else {
            delete testResultSet.providerName;
            delete testResultSet.created;
            delete testResultSet.requestId;
            delete testResultSet.uiExpanding;
            delete testResultSet.breakdown;

            testResultSet.testProviderId = this.selectedTestProvider;

            this._service.createTransformationResult(testResultSet).then((response: LampUpdateResponse) => {
                if (response.id) {
                    testResultSet.id = response.id;
                    testResultSet.created = (new Date()).toISOString();
                }
                this.reactOnSuccessfulResponseMessage(response);
                return testResultSet;
            });
        }

    }

    public loadMore() {
        const startAt = this.showingResults.length > 0 ? this.showingResults.length : 0;
        this.showingResults = this.showingResults.concat(this.results.slice(startAt, startAt + applicationConstants.loadmoreItem));
    }

    private reactOnSuccessfulResponseMessage(response: LampUpdateResponse): void {
        this.enableRefresh();
        let error: string | HttpErrorResponse = response.error;
        if (!response.success && !error)
            error = 'Unable to save, unknown error';
        if (error)
            return this.showErrorMessage(error['error'] || error);
    }

    protected filterSettingName(): string {
        return "testresults";
    }

    public toSummary(fragment: string, results: TestResult[], name: string): string {
        let summary: string = '';
        for (let r of results)
            summary += this.resultText(fragment, r, name) + ' | ';
        if (summary.length > 0)
            return summary.substr(0, summary.length - 3).trim();
        else
            return '';
    }

    public hasSelection(rs: TestResultSet): boolean {
        return rs.selectStart != undefined && rs.selectLength > 0;
    }

    public changeTestProvider(event) {
        this._router.navigate([], {
            queryParams: {
                testProviderId: event.value
            },
            queryParamsHandling: 'merge',
        });
    }

    protected getFilteredData(searchArgument: string, searchArgumentType: string): Promise<void> {
        this._router.navigate([], {
            queryParams: {
                testProviderId: this.selectedTestProvider,
                key: searchArgumentType,
                value: searchArgument
            },
            queryParamsHandling: 'merge',
        });
        if (!this.selectedTestProvider) {
            this.showErrorMessage('Please select Test Provider');
            return Promise.resolve();
        } else {
            this.clearTheErrorMessage();
        }

        return this._service.getTransformationResultList(this.selectedTestProvider, searchArgument, searchArgumentType)
            .then((results: TestResultSet[]) => {
                this.results = results;
                this.showingResults = [];
                this.loadMore();
            });
    }

    constructor(
        private _service: TestsetService,
        protected sessionService: SessionService,
        protected translateService: TranslateService,
        protected localStorageHelper: LocalStorageHelper,
        protected familyService: KnowledgeGraphService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {
        super(translateService, localStorageHelper, sessionService);
    }

}
