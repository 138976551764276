import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import { Statistics, emptyStatistics, User, EditBreakdown } from '../models/statistics';

import {ApiClientService} from './api-client.service';

@Injectable()
export class StatisticsService {
  constructor(private apiClientService: ApiClientService) {
  }

  public getStatistics(languageId: number): Promise<Statistics> {
    if (languageId) {
      return this.apiClientService
        .get(`${EndpointUrls.statistics}?language=${languageId}`)
        .then((statistics) => {
          return statistics as Statistics;
        });
    } else {
      console.error('languageId does not exist');
      return Promise.resolve(emptyStatistics);
    }
  }

  public getUsers(): Promise<User[]> {
    return this.apiClientService
      .get(EndpointUrls.linguistActivities.users)
      .then((users: User[]) => {
        return users;
      });
  }

  public getEditBreakdownByUser(userId: string): Promise<EditBreakdown[]> {
    if (userId) {
      return this.apiClientService
        .get(`${EndpointUrls.linguistActivities.editBreakdown}?user=${userId}`)
        .then((editBreakdown: EditBreakdown[]) => {
          return editBreakdown;
        });
    } else {
      console.error('userId does not exist');
      return Promise.resolve([]);
    }
  }

  public getUserStatus(userId: string): Promise<string> {
    return this.apiClientService
      .get(`${EndpointUrls.linguistActivities.statuses}?user=${userId}`)
      .then((status) => {
        return status;
      });
  }
}
