import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs/Subscription';

import {PhonemesService} from '../../shared/services/phonemes.service';
import {Phoneme} from '../../shared/models/phoneme-sign';
import {LocalStorageHelper} from "../../shared/helpers/localhost.helper";

@Component({
  selector: 'app-phonemes-menu',
  templateUrl: './phonemes-menu.component.html',
  styleUrls: ['./phonemes-menu.component.less']
})

export class PhonemesMenuComponent implements OnInit, OnDestroy {
  public phonemes: Array<Phoneme>;
  public showSpinner: boolean = false;
  public $event: { key: string, value: string | number };

  @Input() isDialog: boolean;
  @Output() chosenPhonemeEvent: EventEmitter<Phoneme> = new EventEmitter<Phoneme>();

  private routeSubscription: Subscription;

  constructor(
    private phonemesService: PhonemesService,
    private localStorageHelper: LocalStorageHelper,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.routeSubscription = this.route
      .queryParams
      .subscribe(routeParameters => {
        const parameters = routeParameters as { key: string, value: string | number };
        if (parameters.key && parameters.value) {
          this.$event = parameters;
          this.filter(parameters);
        } else {
          this.$event = {
            key: null,
            value: null
          };
        }
      });
  }

  public sendChosenPhonemeToParent($event: Phoneme): void {
    this.chosenPhonemeEvent.emit($event);
  }

  public reactOnChosenParameterEvent(event: { key: string; value: string }): Promise<void> {
    this.$event.value = event.value;
    this.$event.key = event.key;
    return this.filter(this.$event);
  }

  public filter(filterData: { key: string, value: string | number }): Promise<void> {
    this.showSpinner = true;
    this.localStorageHelper.setTabSetting('phonemesPath', JSON.stringify(filterData));
    return this.phonemesService.getPhonemesList(filterData.key, filterData.value).then((phonemes: Array<Phoneme>) => {
      if (this.isDialog) {
        this.phonemes = phonemes.filter((phoneme: Phoneme) => {
          return phoneme.phoneme;
        });
      } else {
        this.phonemes = phonemes;
      }

      this.showSpinner = false;
    });
  }

  public repeatFilter(): Promise<void> {
    return this.filter(this.$event);
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}

