import {Component, ViewChild, Input} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';

import {AdvancedCriteria, RequiredPhrase} from '../../../shared/models/lexicon';
import {Family} from '../../../shared/models/knowledge-graph';
import {SessionService} from '../../../shared/services/session.service';

@Component({
  selector: 'app-required-phrases-table',
  templateUrl: 'required-phrases-table.component.html',
  styleUrls: ['required-phrases-table.component.less']
})
export class RequiredPhrasesTableComponent {
  @Input() advancedCriteria: AdvancedCriteria;

  @ViewChild('requiredPhrasesTable') requiredPhrasesTable;

  public selection: SelectionModel<RequiredPhrase> = new SelectionModel<RequiredPhrase>(true, []);
  public requiredPhrasesColumns: string[] = ['checked', 'id', 'addChildren', 'phraseDetails'];
  public isGuest: boolean;

  constructor(public sessionService: SessionService) {
    this.isGuest = this.sessionService.isGuest();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.advancedCriteria.requiredPhrases.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.advancedCriteria.requiredPhrases.forEach(row => this.selection.select(row));
  }

  public removeRequiredPhrases(confirm: boolean): void {
    if (confirm) {
      const ids = [];
      for (let i = 0; i < this.selection.selected.length; i++) {
        ids.push(this.selection.selected[i].id);
      }
      this.advancedCriteria.requiredPhrases = this.advancedCriteria.requiredPhrases
        .filter(requiredPhrase => !ids.includes(requiredPhrase.id));
      this.selection.clear();
    }
  }

  public addRequiredPhrase(event: { commaDelimitedListOfFamilyIds: string, family: Family }): void {
    if (event) {
      this.advancedCriteria.requiredPhrases.push(event.family);
      this.requiredPhrasesTable.renderRows();
    }
  }

  public doesUserClickButton(): boolean {
    return this.selection.selected.length === 0;
  }

  public doesUserSeeButton(): boolean {
    return !this.isGuest;
  }

}
