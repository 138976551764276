import {Component} from '@angular/core';

import {Animations} from '../../universal-components/animations/animations';
import {menuItems} from '../../shared/constants/application-constants';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.less'],
  animations: [Animations.slideInOut]
})
export class SidebarMenuComponent {
  private collapsed: boolean[][] = [];
  public permissionLevel: string;
  public menuItems = menuItems;

  constructor(protected sessionService: SessionService) {
    this.fillCollapsedValues();
    this.permissionLevel = this.sessionService.permissionLevel();
  }

  private fillCollapsedValues() {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].subMenu) {
        this.collapsed[i] = new Array(menuItems[i].subMenu.length).fill(true);
      } else {
        this.collapsed[i] = [true];
      }
    }
  }

  public isCollapsedMenuItems(index, subitemIndex): boolean {
    subitemIndex = (subitemIndex === undefined) ? 0 : subitemIndex;
    return this.collapsed[index][subitemIndex];
  }

  public toggleMenuItems(index, subitemIndex?): void {
    subitemIndex = (subitemIndex === undefined) ? 0 : subitemIndex;

    this.collapsed[index][subitemIndex] = !this.collapsed[index][subitemIndex];
  }

}
