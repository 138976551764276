import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import * as _ from 'lodash';
import { Edits } from '../../shared/models/common';
import { LinguistService } from '../../shared/services/linguist.service';
import * as JsonDiff from 'json-diff-ts';
import { IComparisonEnrichedNode } from 'json-diff-ts';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.less']
})
export class AuditTrailComponent implements OnInit {
  @Input() tableName: string;
  @Input() entityId: number;

  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatTable) table: MatTable<any>;
  editLog: Edits[];

  jsonDiff: IComparisonEnrichedNode;

  public columnNames: string[] = ['username', 'datetime', 'diff', 'action'];

  constructor(private linguisticsService: LinguistService) {
  }

  ngOnInit() {
    if (this.tableName && this.entityId) {
      this.linguisticsService.getAuditTrail(this.tableName, this.entityId).then(edits => {
        this.editLog = edits;
        for (let editEntry of this.editLog) {
          try {
            if (editEntry.diff && typeof editEntry.diff == 'string') {
              editEntry.diff = JSON.parse(editEntry.diff);
            }
            if (editEntry.original)
              editEntry.parsedOriginal = this.removeFields(JSON.parse(this.removeWrongEscape(editEntry.original)));

            if (editEntry.requestBody) {
              let newVersion = this.removeFields(JSON.parse(this.removeWrongEscape(editEntry.requestBody)));
              editEntry.parsedRequestBody = newVersion;
              // let changes = {};
              // Object.keys(newVersion).filter(k => {
              //   if ((newVersion[k] || 0) != (editEntry.parsedOriginal[k] || 0)) {
              //     const oldVersion = editEntry.parsedOriginal;
              //     if ((newVersion[k] && _.isArray(newVersion[k])) || (oldVersion[k] && _.isArray(oldVersion[k]))) {
              //       if (_.isArray(newVersion[k])) {
              //         let newArray = [];
              //         for (const newItem of newVersion[k]) {
              //           if (!oldVersion[k] || !oldVersion[k].find(oldItem => (oldItem.index == newItem.index && oldItem.value == newItem.value))) {
              //             newArray.push(newItem);
              //             continue;
              //           }
              //         }
              //         if (newArray.length > 0) {
              //           changes[k] = newArray;
              //         }
              //       }
              //       if (oldVersion[k]) {
              //         const deletedKey = `${k}(removed)`;
              //         for (const oldItem of oldVersion[k]) {
              //           if (!newVersion[k] || !newVersion[k].find(newItem => (newItem.index == oldItem.index && newItem.value == oldItem.value))) {
              //             changes[deletedKey] = changes[deletedKey] || [];
              //             changes[deletedKey].push(oldItem);
              //             continue;
              //           }
              //         }
              //       }
              //     } else {
              //       changes[k] = newVersion[k];
              //     }

              //   }
              // });
              // editEntry.changes = Diff.diffJson(editEntry.parsedOriginal, newVersion);
              // editEntry.parsedRequestBody = newVersion;
              // const mergedNewVersion = _.mergeWith(editEntry.parsedRequestBody, editEntry.parsedOriginal, this.customizer)
              // editEntry.diffs = JsonDiff.compare(editEntry.parsedOriginal, mergedNewVersion);              
            }
          } catch (error) {
            editEntry.parseError = error;
            console.error("Cannot parse body: " + error);
          }
        }
      });
    }
  }

  isObject(obj: any) {
    return _.isObject(obj);
  }

  customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue;
    }
    if (objValue === undefined)
      return srcValue;
    if (objValue && srcValue && typeof objValue != typeof srcValue) {
      if (typeof srcValue == 'string')
        return objValue.toString();
      if (typeof srcValue == 'number')
        return +objValue;
    }
    return objValue;
  }

  public removeWrongEscape(input: string) {
    return input.replace(/:""([a-zA-Z0-9 ]+)/g, ': "$1"').replace(/([a-zA-Z0-9 ]+)""/g, '$1')
  }

  public displayProperty(propVal: any): string {
    let concatenated: string = "";
    if (propVal instanceof Array) {
      for (let arrayMemb of propVal)
        if (concatenated.length > 0)
          concatenated += ", " + this.displayProperty(arrayMemb);
        else
          concatenated = this.displayProperty(arrayMemb);
      return concatenated;
    }
    else {
      if (typeof propVal == "object") {
        const OBJECT_PREFIX: string = " [";
        concatenated += OBJECT_PREFIX;
        for (let subpr in propVal) {
          if (concatenated.length > OBJECT_PREFIX.length)
            concatenated += ", ";
          concatenated += subpr + "=" + this.displayProperty(propVal[subpr]);
        }
        concatenated += "] ";
        return concatenated;
      }
      return propVal.toString();
    }
  }

  public shortened(str: string): string {
    if (!str || str.length < 20)
      return str;
    else
      return str.substr(0, 20) + '...';
  }

  public parseRequestBody(jsonColumn: string): string[] {
    if (jsonColumn) {
      try {
        const arrayOfData = JSON.parse(jsonColumn);
        const keys = Object.keys(arrayOfData);
        const requestDataArray: string[] = [];
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const element = arrayOfData[key];
          const data = (typeof element === 'object' && element) ? JSON.stringify(element) : element;
          const str = key + ':' + data;
          requestDataArray.push(str);
        }
        return requestDataArray;
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  }

  public parseStr(str: string, index = 0): string {
    const array: string[] = str.split(':');
    if (index) {
      array.shift();
      return array.join(':');
    } else {
      return array[0];
    }
  }

  public assign(original, requestBody) {
    original = this.removeFields(original);
    requestBody = this.removeFields(requestBody);
    this.assignEvent.emit(Object.keys(original || {}).length > 0 ? original : requestBody);
  }

  //remove additional fields
  removeFields(source: any) {
    return _.omitBy(source, (_value, keyName) => {
      return keyName == 'id' || ['create', 'Update', 'request', 'legacy', 'relevant', 'advancedCriteriaDescription'].find((k: string) => {
        return keyName.indexOf(k) > -1
      });
    });
  }
}
