import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { LexiconService } from '../../shared/services/lexicon.service';
import { LampUpdateResponse } from '../../shared/models/common';
import { Lexeme } from '../../shared/models/lexicon';
import { SessionService } from '../../shared/services/session.service';
import { applicationConstants } from '../../shared/constants/application-constants';

@Component({
  selector: 'app-lexeme-edit-dialog',
  templateUrl: 'lexeme-edit-dialog.component.html',
  styleUrls: ['lexeme-edit-dialog.component.less'],
})
export class LexemeEditDialogComponent {
  public dialogId = applicationConstants.dialogs.lexemeEditDialog;

}
