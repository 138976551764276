<p *ngIf="isStartPosition()">
  <span class="ti-phonemes-position--point"></span>
  <span class="ti-phonemes-position--dashed ti-phonemes-start-position--dashed"></span>
</p>
<p *ngIf="isMiddlePosition()">
  <span class="ti-phonemes-middle-position--dashed"></span>
  <span class="ti-phonemes-position--point"></span>
  <span class="ti-phonemes-middle-position--dashed"></span>
</p>
<p *ngIf="isEndPosition()">
  <span class="ti-phonemes-end-position--dashed"></span>
  <span class="ti-phonemes-position--point"></span>
</p>
<p *ngIf="isAllExceptEndPosition()">
  <span class="ti-phonemes-start-or-middle-position--first-dashed"></span>
  <span class="ti-phonemes-position--point"></span>
  <span class="ti-phonemes-start-or-middle-position--second-dashed"></span>
</p>
<p *ngIf="isAllExceptMiddlePosition()">
  <span class="ti-phonemes-position--point"></span>
  <span class="ti-phonemes-start-or-end-position--dashed"></span>
  <span class="ti-phonemes-position--point"></span>
</p>
<p *ngIf="isAllExceptStartPosition()">
  <span class="ti-phonemes-middle-or-end-position--first-dashed"></span>
  <span class="ti-phonemes-position--point"></span>
  <span class="ti-phonemes-middle-or-end-position--second-dashed"></span>
</p>

<p *ngIf="!position"><span class="ti-phonemes-position--anywhere"></span></p>
