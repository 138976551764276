<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{'non-break.non-breaks-edit' | translate}}</h2>
  <mat-dialog-actions class="ti-mat-dialog-actions---end">
    <button
      *ngIf="doesUserHaveAccessToEdit"
      mat-raised-button
      color="accent"
      (click)="addNewEntry()">{{'COMMON.add-new' | translate}}
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
  <app-non-breaks-table-for-the-mark
    [punctuationSignId]="punctuationSignId"
    [addNewEntryObservable]="addNewEntryObservable"
    (addNewElementEvent)="scrollToEnd()"
  ></app-non-breaks-table-for-the-mark>
  <p style="visibility: hidden">{{'non-break.non-breaks-edit' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions class="ti-mat-dialog-actions---end">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
</mat-dialog-actions>
