<div *ngIf="disableSelect" class="ti-text-data">
  {{textFeatureValue}}
</div>

<mat-form-field color="accent" *ngIf="relevantFeatureList && !disableSelect">
  <mat-select #featureSelect
              matInput
              [(ngModel)]="currentFeatureIndex"
              (selectionChange)="chooseCurrentFeature($event)"
              [required]="!isEmptyValueRelevant"
  >
    <mat-option *ngIf="isEmptyValueRelevant" [value]="null"></mat-option>
    <ng-template ngFor let-feature let-index="index" [ngForOf]="relevantFeatureList">
      <mat-option
        [value]="feature.index"
        [ngClass]="{'ti-universal-feature__hidden':
        (!doesTheWholeFeatureListRelevant &&!isFullFeatureListShowed && !feature.relevant && !feature.relevantPartially)}">
        {{feature.description}} [{{feature.index}}]
      </mat-option>
    </ng-template>
    <mat-option *ngIf="!doesTheWholeFeatureListRelevant"
                class="ti-toggle-sign"
                (click)="toggleFeatureList(); featureSelect.open()"
    >
      <mat-icon class="ti-toggle-sign--first" aria-hidden="true" *ngIf="!isFullFeatureListShowed">expand_more</mat-icon>
      <mat-icon aria-hidden="true" *ngIf="!isFullFeatureListShowed">expand_more</mat-icon>
      <mat-icon class="ti-toggle-sign--first" aria-hidden="true" *ngIf="isFullFeatureListShowed">expand_less</mat-icon>
      <mat-icon aria-hidden="true" *ngIf="isFullFeatureListShowed">expand_less</mat-icon>
    </mat-option>
  </mat-select>
</mat-form-field>
