<div class="ti-mat-dialog-header-with-button">
  <mat-dialog-actions class="ti-mat-dialog-actions---end">
    <button
      [disabled]="!doesUserHaveAccessDelete"
      mat-raised-button color="accent" (click)="addNewEntry()">{{'COMMON.add-new' | translate}}
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
  <app-range-localization-table
    [rangeId]="rangeId"
    [doesUserHaveAccessDelete]="doesUserHaveAccessDelete"
    [addNewEntryObservable]="addNewEntryObservable"
    (addNewElementEvent)="scrollToEnd()"></app-range-localization-table>

</mat-dialog-content>
<mat-dialog-actions class="ti-mat-dialog-actions---end">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
</mat-dialog-actions>
