import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {TestsetService} from '../shared/services/testset.service';
import {SessionService} from '../shared/services/session.service';
import {Translated} from '../shared/classes/translated.class';
import {TestProvider} from '../shared/models/test.model';
import { LocalStorageHelper } from '../shared/helpers/localhost.helper';

@Component({
  selector: 'app-testprovider-list',
  templateUrl: './testprovider-list.component.html',
  styleUrls: ['./testprovider-list.component.less']
})

export class TestProviderComponent extends Translated implements OnInit {
  public providers: TestProvider[] = [];
  public listColumns: string[];

  constructor(
    private _service: TestsetService,
    protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService,
    protected translateService: TranslateService
  ) 
  {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.listColumns = ['id', 'description', 'typename', 'updateButtons'];
    this.initFromSession();

    this._service.getTestproviderList()
      .then((providers: TestProvider[]) => {
        this.providers = providers;
      });
  }

  initThisSubtypeFromSession(): void {
  }

  public deleteRowById(id: number, confirmedDelete: Event): void {
    if (confirmedDelete) {
      this._service.deleteTestprovider(id).then(() => {
        // refresh
        this._service.getTestproviderList()
        .then((providers: TestProvider[]) => {
          this.providers = providers;
        });
      });
    }
  }



}