import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'tisanePhraseFilter',
  pure: false
})
@Injectable()
export class TisanePhraseFilter implements PipeTransform {
  transform(items: any[], filter: any): any {
      if (!items || !filter) {
          return items;
      }
      // filter items array, items which match and return true will be
      // kept, false will be filtered out
    return items.filter(item => {
      return (filter.matched && !item.incomplete)
        || (filter.mismatched && item.incomplete)
        || (filter.translated && item.generation)
    });
  }
}
