import {Injectable} from '@angular/core';

import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';

import {LampUpdateResponse} from '../models/common';
import { CommonSense, CommonSenseListElement, CommonSenseStats, CommonSenseStatsBreakdown } from '../models/common-cues.model';

/**
 * LaMP web methods related to commonsense cues. 
 */
@Injectable()
export class CommonSenseCuesService {

  constructor(private apiClientService: ApiClientService) {
  }

  /**
   * Gets a list of commonsense cues, based on the specified filter settings.
   * @param type 
   * @param argument 
   * @returns 
   */
  public getList(type: string, argument: string): Promise<CommonSenseListElement[]> {
    return this.apiClientService.get(`${EndpointUrls.CommonSenseCues.commonsenseList}?arg=${argument}&type=${type}`);
  }

  public getStats(type: string, argument: string | number, familyId: number, daysAgo: number = 0): Promise<CommonSenseStats[]> {
    if (familyId) {
      return this.apiClientService.get(`${EndpointUrls.CommonSenseCues.commonsenseStats}?family=${familyId}&daysAgo=${daysAgo}`);
    } else {
      return this.apiClientService.get(`${EndpointUrls.CommonSenseCues.commonsenseStats}?arg=${argument}&type=${type}&daysAgo=${daysAgo}`);
    }
  }

  public async getStatsBreakdown(commonsenseCueId: string, daysAgo: number = 0): Promise<CommonSenseStatsBreakdown[]> {
    return this.apiClientService.get(`${EndpointUrls.CommonSenseCues.commonsenseCueStatsBreakdown}?id=${commonsenseCueId}&daysAgo=${daysAgo}`);
  }


  /**
   * Gets a list of commonsense cues for the specified family.
   * @param family 
   * @param type 
   * @param argument 
   * @returns 
   */
  public getListForFamily(family: string, type?: string, argument?: string): Promise<CommonSenseListElement[]> {
    let url = `${EndpointUrls.CommonSenseCues.commonsenseList}?family=${family}`;
    if (type && argument) {
      url = `${url}&arg=${argument}`;
    }
    return this.apiClientService.get(url);
  }

  /**
   * Gets a specified commonsense cue specified by an ID. Must be called when editing.
   * @param id 
   * @returns 
   */
  public getById(id: number): Promise<CommonSense> {
    return this.apiClientService.get(`${EndpointUrls.CommonSenseCues.commonsense}?id=${ id}`);
  }

  /**
   * Create a new commonsense cue.
   * @param commonSense 
   * @returns 
   */
  public create(commonSense: CommonSense): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.CommonSenseCues.commonsense, commonSense);
  }

  /**
   * Update an existing commonsense cue, according to the request ID provided by getById().
   * @param commonSense 
   * @returns 
   */
  public update(commonSense: CommonSense): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.CommonSenseCues.commonsense, commonSense);
  }

  /**
   * Deletes a specified commonsense cue.
   * @param id 
   * @returns 
   */
  public deleteCommonSenseCue(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.CommonSenseCues.commonsense}?id=${id}`);
  }

}
