import {Injectable} from '@angular/core';

import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import {CacheService } from './cache.service';

@Injectable()
export class MiscellaneousService {
  // for pro-drop, anaphora, roots, etc.
  constructor(private apiClientService: ApiClientService,
              private cachService: CacheService) {
  }
  
  //TODO: implement

}
