import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionService } from "../shared/services/session.service";
import { Translated } from "../shared/classes/translated.class";
import { LocalStorageHelper } from "../shared/helpers/localhost.helper";
import { NonBreakGlobal, NonDictionary } from "../shared/models/non-break";
import { BehaviorSubject, Subscription } from "rxjs";
import { Observable } from "rxjs";
import { NonBreaksService } from "../shared/services/nonbreaks.service";
import { createFlatArrayCopy } from "../shared/helpers/object.helper";
import { LampUpdateResponse } from "../shared/models/common";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-nonbreak-list",
  templateUrl: "./nonbreak-list.component.html",
  styleUrls: ["./nonbreak-list.component.less"],
})
export class NonbreakListComponent extends Translated implements OnInit {
  public nonBreakGlobals: NonBreakGlobal[];
  public initialNonBreakGlobals: NonBreakGlobal[];
  public displayedColumns: string[];
  public nonDictionaryList: NonDictionary[];
  public editedNonBreak: NonBreakGlobal;
  public forAllLanguages: boolean = false;

  public errorMessageBehaviorSubj: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  public errorMessageSource: Observable<string> =
    this.errorMessageBehaviorSubj.asObservable();

  private subscription: Subscription;
  public showProgressbar = false;

  constructor(
    private nonBreaksService: NonBreaksService,
    protected translateService: TranslateService,
    public sessionService: SessionService,
    protected localStorageHelper: LocalStorageHelper,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super(translateService, localStorageHelper, sessionService);
    this.displayedColumns = [
      "regular-expression",
      "description",
      "trailing-regex",
      "max-iterations",
      "iterate-backward",
      "non-dictionary-pattern",
      "for-all-languages",
    ];
  }

  ngOnInit(): void {
    this.getNonBreakRules();
    // this.subscription = this.addNewEntryObservable.subscribe((shouldAddEntry) => {
    //   if (shouldAddEntry) {
    //     this.addNewRowInTheTable();
    //   }
    // });
  }

  initThisSubtypeFromSession(): void {}

  private getNonBreakRules(): Promise<boolean> {
    this.showProgressbar = true;
    const getNonBreaksListForCurrentLanguagePromise = this.nonBreaksService
      .getNonBreaksListForCurrentLanguage()
      .then((nonBreaksList: NonBreakGlobal[]) => {
        if (!this.nonBreakGlobals) {
          this.nonBreakGlobals = [];
        }
        this.nonBreakGlobals = this.nonBreakGlobals.concat(nonBreaksList);
        this.initialNonBreakGlobals = createFlatArrayCopy(this.nonBreakGlobals);
        
        this.route.fragment.subscribe(f => {
          setTimeout(() => {
            const element = document.querySelector('[id="'+ f +'"]');
            if (element) element.scrollIntoView();
          }, 1000);
        })
      });

    const getNonDictionaryListPromise = this.nonBreaksService
      .getNonDictionaryList()
      .then((nonDictionary: NonDictionary[]) => {
        this.nonDictionaryList = nonDictionary;
      });

    return Promise.all([
      getNonBreaksListForCurrentLanguagePromise,
      getNonDictionaryListPromise,
    ])
      .then(
        () => {
          this.showProgressbar = false;
          return true;
        },
        () => {
          return false;
        }
      )
      .catch(() => {
        return false;
      });
  }

  public addNewRowInTheTable(): void {
    this.revertChanges();

    if (!this.nonBreakGlobals) {
      this.nonBreakGlobals = [];
    }
    this.editedNonBreak = { description: "???", regex: "???" };

    this.nonBreakGlobals = this.nonBreakGlobals.concat([this.editedNonBreak]);

    document.querySelector('mat-sidenav-content').scrollBy(0, 1000000);
  }

  public updateNonBreak(element) {
    this.showProgressbar = true;
    if (element.id) {
      this.editedNonBreak.language = this.forAllLanguages
        ? 0
        : this.sessionService.session.targetLanguage.id;
      this.nonBreaksService
        .updateNonBreak(this.editedNonBreak)
        .then((result: LampUpdateResponse) => {
          this.editedNonBreak = undefined;
          if (result.success) {
            this.updateNonBreaksList();
          } else {
            this.showErrorMessage(result.error);
            this.changeDetectorRef.markForCheck();
          }
          this.showProgressbar = false;
        });
    } else {
      this.editedNonBreak.language = this.forAllLanguages
        ? undefined
        : this.sessionService.session.targetLanguage.id;
      this.nonBreaksService
        .createNonBreak(this.editedNonBreak)
        .then((result: LampUpdateResponse) => {
          if (result.success) {
            this.editedNonBreak = undefined;
            this.updateNonBreaksList();
          } else {
            this.showErrorMessage(result.error);
            this.changeDetectorRef.markForCheck();
          }
          this.showProgressbar = false;
        });
    }
  }

  private updateNonBreaksList(): void {
    this.nonBreakGlobals = [];
    this.initialNonBreakGlobals = [];
    this.getNonBreakRules().then(() => {
      this.clearTheErrorMessage();
      this.changeDetectorRef.markForCheck();
    });
  }

  public deleteNonBreak(id: number, doesUserConfirm: boolean): Promise<void> {
    if (doesUserConfirm) {
      this.showProgressbar = true;
      return this.nonBreaksService
        .deleteNonBreak(id)
        .then((message: LampUpdateResponse) => {
          this.showProgressbar = false;
          if (message.success) {
            this.updateNonBreaksList();
          } else {
            this.showErrorMessage(message.error);
            this.changeDetectorRef.markForCheck();
          }
          this.showProgressbar = false;
        });
    }
  }

  public revertChanges(): void {
    this.editedNonBreak = undefined;

    this.clearTheErrorMessage();

    this.nonBreakGlobals = createFlatArrayCopy(this.initialNonBreakGlobals);

    this.changeDetectorRef.markForCheck();
  }

  public isSelectedForEditing(nb: NonBreakGlobal): boolean {
    return !nb.id || (this.editedNonBreak && this.editedNonBreak.id === nb.id);
  }

  public isEditingThis(nb: NonBreakGlobal): boolean {
    return (nb.language || this.isAdmin()) && this.isSelectedForEditing(nb);
  }

  public edit(id: number) {
    this.revertChanges();
    this.showProgressbar = true;
    this.nonBreaksService
      .getNonBreakData(id)
      .then((nonBreak: NonBreakGlobal) => {
        this.showProgressbar = false;
        this.forAllLanguages = nonBreak.language ? false : true;
        this.editedNonBreak = nonBreak;
      });
  }

  public getNonDictionaryPattern(heuristicId: number) {
    if (heuristicId && this.nonDictionaryList) {
      const nonDictionaryPattern: NonDictionary = this.nonDictionaryList.find(
        (element) => element.id === heuristicId
      );
      if (!nonDictionaryPattern) return "";
      return (
        nonDictionaryPattern.description + " " + nonDictionaryPattern.regex
      );
    }
    return "";
  }

  encodeURIComponent(str){
    return encodeURIComponent(str);
  }

  ngOnDestroy(): void {}
}
