import {AbstractControl, ValidatorFn} from '@angular/forms';

export function frequencyValidator(): ValidatorFn {
  // Frequency grade.	A number in range 0 through 10, or an empty string
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentControlValue = control.value;
    const forbidden = (currentControlValue !== '') && ((currentControlValue < 0) || (currentControlValue > 10)) ||
      (/\D/).test(currentControlValue);
    return forbidden ? {'forbiddenName': {value: control.value}} : null;
  };
}

export function digitsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentControlValue = control.value;
    const forbidden = (/\D/).test(currentControlValue);
    return forbidden ? {'forbiddenName': {value: control.value}} : null;
  };
}


export function thisCEEraValidator(): ValidatorFn {
  // A valid year, only this era (CE).
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentControlValue = parseInt(control.value.toString(), 10);
    const forbidden = (isNaN(currentControlValue)) ||
      ((currentControlValue < 0) || (currentControlValue > (new Date()).getFullYear())) ||
      (/\D/).test(control.value);
    return forbidden ? {'forbiddenName': {value: control.value}} : null;
  };
}


export function ISOStringValidator(): ValidatorFn {
  // 'yyyy'-'MM'-'dd'T'HH':'mm':'ss'.'fff'Z
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentControlValue = control.value;
    const forbidden = !(/(\d){4}\-(\d){2}\-(\d){2}T(\d){2}\:(\d){2}\:(\d){2}\.(\d){3}Z/).test(currentControlValue);
    return forbidden ? {'forbiddenName': {value: control.value}} : null;
  };
}
