import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { emptyAffixTest, AffixTest } from '../../../shared/models/affixes.model';

import { SessionService } from '../../../shared/services/session.service';
import { collectObjectChanges } from '../../../shared/helpers/object.helper';
import { LampUpdateResponse } from '../../../shared/models/common';
import { Translated } from '../../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../../../shared/helpers/localhost.helper';
import { MatButton, MAT_DIALOG_DATA } from '@angular/material';
import { AffixesService } from '../../../shared/services/affixes.service';
import { LexiconService } from '../../../shared/services/lexicon.service';
import { Lexeme } from '../../../shared/models/lexicon';

@Component({
  selector: 'app-affix-test-add-dialog',
  templateUrl: './affix-test-add-dialog.component.html',
  styleUrls: ['./affix-test-add-dialog.component.less']
})

export class AffixTestAddDialogComponent extends Translated implements OnInit, OnDestroy {
  public isTaggingAffixesMenu: boolean;
  private subscriptions: Subscription[] = [];

  public affixTest: Partial<AffixTest> = { ...emptyAffixTest };
  public initialAffixTest: AffixTest = JSON.parse(JSON.stringify(emptyAffixTest));

  public affixTestAddDialogForm: FormGroup;

  public affixTestList: AffixTest[];
  public familyDesc: string;

  public familyOpts: { id: number; description: string }[];
  public affixTestId: number;
  public affixGroupId: number;
  public selectedLemma: string;

  public text: string;
  public doesUserHaveAccessToEdit: boolean;
  public showSpinner: boolean;
  @ViewChild('btnCloseDialog') btnCloseDialog: MatButton;

  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();

  constructor(protected translateService: TranslateService,
    private formBuilder: FormBuilder,
    protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService,
    private lexiconService: LexiconService,
    @Inject(MAT_DIALOG_DATA) public data: { isTaggingAffixesMenu: boolean, affixGroupId: number },
    private affixesService: AffixesService) {
    super(translateService, localStorageHelper, sessionService);

    this.isTaggingAffixesMenu = data.isTaggingAffixesMenu;
    this.affixGroupId = data.affixGroupId;
  }

  ngOnInit(): void {
    this.initFromSession();
    this.createForm();
  }

  initThisSubtypeFromSession(): void {
    this.doesUserHaveAccessToEdit = this.sessionService.canEditNonLexicon();
  }

  private createForm(): void {
    this.affixTestAddDialogForm = this.formBuilder.group({
      lexemeId: [this.affixTest.lexemeId],
      familyId: [this.affixTest.familyId],
      inflectedForm: [this.affixTest.inflectedForm],
      lastTestFailedString: [this.affixTest.lastTestFailedString],
      failReason: [this.affixTest.failReason],
      lastSucceeded: [this.affixTest.lastSucceeded]
    });
  }

  public save(): Promise<void> {
    const affixTestChanges = collectObjectChanges(this.initialAffixTest,
      this.affixTest) as AffixTest;

    if (Object.keys(affixTestChanges).length) {
      affixTestChanges.affixGroupId = this.affixGroupId;

      this.showSpinner = true;
      return this.affixesService.createAffixTest(affixTestChanges)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
          return this.btnCloseDialog._elementRef.nativeElement.click();
        });
    } else {
      return Promise.resolve();
    }
  }

  private loadFamilyOpts(lexemes: Lexeme[]) {
    if (lexemes && lexemes.length == 1) {
      this.familyOpts = (lexemes[0].families || [])
        .filter((x) => x.definition && x.definition.length > 0)
        .map((family) => {
          return {
            id: family.id,
            description: family.definition,
          };
        });
      this.selectedLemma = lexemes[0].lemma;
    }
    const existed = this.familyOpts.find(x => x.id === this.affixTest.familyId);
    if (!existed) {
      this.affixTest.familyId = null;
    }    
  }

  public selectLexeme(lexemeId: number) {
    this.affixTest.lexemeId = lexemeId;
    this.showSpinner = true;
    this.lexiconService.getLexicon("id", lexemeId).then((lexemes) => {
      //load dropdown options for family
      this.loadFamilyOpts(lexemes);
              
      this.showSpinner = false;
    })
    .catch((error) => {
      this.showErrorMessage(error);
    });   
  }

  public resetSelection(){
    this.affixTest.lexemeId = 0;
    this.affixTest.familyId = 0;
  }

  private reactOnSuccessfulResponseMessage(successfulResponseMessage: LampUpdateResponse): void {
    this.showSpinner = false;
    if (successfulResponseMessage.success) {
      this.affixTest = { ...emptyAffixTest };
      return this.btnCloseDialog._elementRef.nativeElement.click();
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  protected showErrorMessage(error?: string) {
    this.errorMessageSubj.next(error);
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  reactOnFamilySelection(event: { family: { id: number; description: string } }) {
    if (event && event.family) {
      this.affixTest.familyId = event.family.id;
      this.familyDesc = event.family.description;
    } else {
      this.affixTest.familyId = null;
      this.familyDesc = null;
    }
  }
}
