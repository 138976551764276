import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {SharedModule} from '../../shared/_module/shared.module';

import {ButtonOpensConfirmDialogDirective} from './open-confirm-dialog-button.directive';
import {AreYouSureDialogComponent} from './are-you-sure-dialog.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/app/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AreYouSureDialogComponent,
    ButtonOpensConfirmDialogDirective
  ],
  exports: [
    AreYouSureDialogComponent,
    ButtonOpensConfirmDialogDirective
  ],
  entryComponents: [AreYouSureDialogComponent]
})
export class AreYouSureDialogModule {}
