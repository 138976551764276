import {Component, OnInit} from '@angular/core';
import {Phoneme} from '../../shared/models/phoneme-sign';

@Component({
  selector: 'app-fall-back-to-phoneme-dialog',
  templateUrl: './fall-back-to-phoneme-dialog.component.html',
  styleUrls: ['./fall-back-to-phoneme-dialog.component.less']
})
export class FallBackToPhonemeDialogComponent {
  phoneme: Phoneme;

  constructor() {
  }

  savePhonemeLocally($event: Phoneme) {
    this.phoneme = $event;
  }
}
