import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {applicationModulesRoutePaths} from '../constants/application-constants';
import {RouterAccompaniment} from '../services/router-accompaniment.service';
import {getPathWithoutQueryAndHashParameters} from './path.helper';

@Injectable()
export class RouterHelper {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private routerAccompaniment: RouterAccompaniment
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public navigateToPage(path: string): void {
    this.router.navigate([path], {relativeTo: this.route});
  }

  public navigateToPageWithFragmentAndQueryParameters(path: string, queryParams: object, fragment: string): void {
    this.router.navigate([path], {queryParams: queryParams, fragment: fragment, relativeTo: this.route});
  }

  public navigateByUrl(targetPath: string): void {
    this.router.navigateByUrl(targetPath, {relativeTo: this.route});
 
  }

  public navigateToPageWithQueryParameters(path: string, queryParams: object): void {
    this.router.navigate([path], {queryParams: queryParams, relativeTo: this.route});
  }

  public navigateToPageWithFragment(path: string, fragment: string): void {
    this.router.navigate([path], {relativeTo: this.route, fragment: fragment});
  }

  public navigateToTheSamePageWithQueryParameters(queryParams: object): void {
    this.navigateToPageWithQueryParameters(this.getCurrentPureUrl(), queryParams);
  }

  public navigateToWelcomePage(): void {
    this.router.navigate([applicationModulesRoutePaths.results.path], {relativeTo: this.route});
  }

  public navigateToLoginPage(): void {
    this.router.navigate([applicationModulesRoutePaths.login.path], {relativeTo: this.route});
  }

  public back(): void {
    if (window.history.length > 1)
      window.history.back(); // Vadim: I'm wracking my brain trying to understand the purpose of the code block I commented out. 
    else
      window.close();
    // Except for making the user's life miserable, that is. Because all it does is magically transporting the user to a random place depending on the last opened tab! The solution is taken from here: https://stackoverflow.com/questions/14070285/how-to-implement-history-back-in-angular-js
    /*
    const currentPage = this.router.url;
    let previousPage = this.routerAccompaniment.getPreviousPage();
    if (currentPage === previousPage) {
      previousPage = this.routerAccompaniment.getBeforePreviousPage();
      if (currentPage === previousPage) {
        previousPage = '';
      }
    }
    if ((previousPage.indexOf('?') > 0) || (previousPage.indexOf('#') > 0)) {
      const queryParams = getQueryParameters(previousPage);
      const fragment = getHashFromPath(previousPage);
      const path = getPathWithoutQueryAndHashParameters(previousPage);

      if (fragment && queryParams) {
        this.navigateToPageWithFragmentAndQueryParameters(path, queryParams, fragment);
      } else if (fragment) {
        this.navigateToPageWithFragment(path, fragment);
      } else if (queryParams) {
        this.navigateToPageWithQueryParameters(path, queryParams);
      } else {
        this.navigateToPage(path);
      }

    } else {
      this.navigateToPage(previousPage);
    }
    */
  }

  /**
   * Gets the current URL without query parameters, hash value, etc.
   * @returns 
   */
  public getCurrentPureUrl(): string {
    return getPathWithoutQueryAndHashParameters(this.router.url);
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public isRefreshing(): boolean {
    return !this.router.navigated;
  }
}
