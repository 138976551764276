import {Component, OnDestroy, OnInit} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {FeaturesService} from '../../shared/services/features.service';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {emptyFeatureCategory, featureCategoryBehaviorOptions, FeatureCategory} from '../../shared/models/feature';
import {LampUpdateResponse} from '../../shared/models/common';
import {collectObjectChanges, toJsonObject} from '../../shared/helpers/object.helper';
import {SessionService} from '../../shared/services/session.service';
import {SessionData} from '../../shared/models/session';
import {applicationModulesRoutePaths} from "../../shared/constants/application-constants";

@Component({
  selector: 'app-feature-category-edit',
  templateUrl: './feature-category-edit.component.html',
  styleUrls: ['./feature-category-edit.component.less']
})
export class FeatureCategoryEditComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  private sessionSubscription: Subscription;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public isNew: boolean;
  public doesUserHaveAccessToEdit: boolean;
  public featureCategoryEditForm: FormGroup;
  public featureCategoryId: number;

  public featureCategory: FeatureCategory;
  public initialFeatureCategory: FeatureCategory;

  public featureCategoryBehaviorOptions = featureCategoryBehaviorOptions;

  constructor(private formBuilder: FormBuilder,
              private featuresService: FeaturesService,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              private routerHelper: RouterHelper) {
  }

  ngOnInit(): void {
    this.subscribeOnRouteChanges();
    this.subscribeOnSessionChanges();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.sessionSubscription.unsubscribe();
  }

  private subscribeOnSessionChanges(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.doesUserHaveAccessToEdit = this.sessionService.isAdmin();
    });
  }

  private subscribeOnRouteChanges(): void {
    this.routeSubscription = this.route.data.subscribe(
      (data: {
        id: number,
        type: string
      }) => {
        this.isNew = false;

        if (data.id && !isNaN(data.id)) {
          this.featureCategoryId = data.id;
          this.getFeatureDefinitionListDetailsById(data.id).then(() => {
            this.createForm();
          });
        } else {
          this.isNew = true;
          this.featureCategory = toJsonObject(emptyFeatureCategory) as FeatureCategory;
          this.featureCategory.type = data.type;
          this.createForm();
        }
      });
  }

  private createForm(): void {
    if (this.featureCategory) {
      this.featureCategoryEditForm = this.formBuilder.group({
        allowFallback: [this.featureCategory.allowFallback],
        allowMultipleValues: [this.featureCategory.allowMultipleValues],
        copyToPhrase: [this.featureCategory.copyToPhrase],
        behavior: [this.featureCategory.behavior],
        defaultValue: [this.featureCategory.defaultValue],
        description: [this.featureCategory.description],
        generationWeight: [this.featureCategory.generationWeight],
        inflectionPattern: [this.featureCategory.inflectionPattern],
        referenceUrl: [this.featureCategory.referenceUrl],
        ud: [this.featureCategory.ud],
        inflectionTablePriority: [this.featureCategory.inflectionTablePriority,
          [
            Validators.maxLength(3),
            Validators.pattern(/^\d+$/)
          ]
        ]
      });
    }
  }

  private getFeatureDefinitionListDetailsById(id: number): Promise<void> {
    if (id) {
      return this.featuresService.getFeatureDefinitionListDetailsById(id).then((featureCategory: FeatureCategory) => {
        this.featureCategory = toJsonObject(featureCategory) as FeatureCategory;
        this.initialFeatureCategory = toJsonObject(featureCategory) as FeatureCategory;
      });
    }

    return Promise.resolve();
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  protected showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public save(): Promise<void> {
    if (this.isNew) {
      const changes = toJsonObject(this.featureCategory) as FeatureCategory;

      if (changes.inflectionTablePriority === 0) {
        delete changes.inflectionTablePriority;
      }

      return this.featuresService.createFeatureDefinitionList(changes)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnResponseMessage(successfulResponseMessage);
        });
    } else {
      const changes = collectObjectChanges(this.initialFeatureCategory, this.featureCategory) as FeatureCategory;

      changes.id = this.featureCategoryId;

      if (changes.inflectionTablePriority === 0) {
        delete changes.inflectionTablePriority;
      }

      return this.featuresService.updateFeatureDefinitionList(changes)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnResponseMessage(successfulResponseMessage);
        });
    }
  }

  private reactOnResponseMessage(successfulResponseMessage: LampUpdateResponse): void {
    if (successfulResponseMessage.success) {
      this.featuresService.refreshSession()
        .then((sessionData: SessionData) => {
          this.cancel();
        });
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  public cancel(): void {
    window.history.back();
  }

}
