import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';

import {LocalStorageHelper} from '../helpers/localhost.helper';
import {applicationModulesRoutePaths, commonTabSettings} from '../constants/application-constants';
import {
  getPathWithoutQueryAndHashParameters, getQueryParameters,
  isFirstUrlChildOfTheSecond
} from '../helpers/path.helper';
import {LinguistService} from './linguist.service';

@Injectable()
export class RouterAccompaniment {
  private routerSubscription: Subscription;
  public previousPage: string;
  public beforePreviousPage: string;

  private loginPageUrl: string = applicationModulesRoutePaths.login.path;

  private keyIndexKey: string = commonTabSettings.keyIndexKey;
  private idIndexKey: string = commonTabSettings.idIndexKey;
  private valueIndexKey: string = commonTabSettings.valueIndexKey;
  private previousPageKey: string = commonTabSettings.previousPageKey;
  private beforePreviousPageKey: string = commonTabSettings.beforePreviousPageKey;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private localStorageHelper: LocalStorageHelper,
              private linguistService: LinguistService) {
    this.routerSubscription = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          const currentUrl = event.url;

          if (!this.previousPage) {
            this.previousPage = this.getPreviousPage();
            this.beforePreviousPage = this.getBeforePreviousPage();
          }
          if (!this.isLoginPage(currentUrl)) {
            {
              this.observeIndexes(currentUrl);

              if (this.previousPage !== currentUrl) {
                if (currentUrl === this.beforePreviousPage) {
                  this.previousPage = '/welcome';
                }
                this.localStorageHelper.setGlobalSetting(this.previousPageKey, this.previousPage);
                this.localStorageHelper.setGlobalSetting(this.beforePreviousPageKey, this.beforePreviousPage);

                this.beforePreviousPage = this.previousPage;
                this.previousPage = currentUrl;
              }
            }
          }
          if ((event instanceof NavigationCancel)) {
            this.router.navigate(['error'], {relativeTo: this.route});
          }
        }
        if (event instanceof NavigationEnd) {
          this.linguistService.getEditsQuantity();
        }
      });
  }

  public getPreviousPage(): string {
    if (!this.previousPageKey || !this.localStorageHelper.getGlobalSetting(this.previousPageKey))
      return '';
    let previousPageSetting = this.localStorageHelper.getGlobalSetting(this.previousPageKey);
    try {
      const previousPage = decodeURIComponent(previousPageSetting) || '';
      return previousPage;
    } catch (err) {
      console.error('Error decoding ' + previousPageSetting + ': ' + err);
      return document.referrer;
    }
  }

  public getBeforePreviousPage(): string {
    try {
      const beforePreviousPage = this.localStorageHelper.getGlobalSetting(this.beforePreviousPageKey) || '';
      if (beforePreviousPage)
        return decodeURIComponent(beforePreviousPage);
      else
        return '';
    } catch (err) {
      console.error('getBeforePreviousPage(): ' + err);
      return document.referrer;
    }
  }

  private clearIndexes(): void {
    this.saveIndex(this.keyIndexKey);
    this.saveIndex(this.idIndexKey);
    this.saveIndex(this.valueIndexKey);
  }

  private observeIndexes(currentUrl: string): void {
    if (currentUrl && this.previousPage) {
      const isCurrentUrlChildOfThePrevious = isFirstUrlChildOfTheSecond(currentUrl, this.previousPage);
      const isPreviousUrlChildOfTheCurrent = isFirstUrlChildOfTheSecond(this.previousPage, currentUrl);
      const queryParameters = getQueryParameters(currentUrl);
      const doesCurrentPageHaveQueryParameters = (queryParameters && !!(Object.keys(queryParameters)[0]));

      if (isPreviousUrlChildOfTheCurrent || isCurrentUrlChildOfThePrevious ||
        (getPathWithoutQueryAndHashParameters(currentUrl) === getPathWithoutQueryAndHashParameters(this.previousPage)) ||
        doesCurrentPageHaveQueryParameters) {
        const indexes: { editedKey: string, editedId: string, editedValue: string } =
          (queryParameters as { editedKey: string, editedId: string, editedValue: string });

        if (indexes.editedKey || indexes.editedId || indexes.editedValue) {
          this.saveIndex(this.keyIndexKey, indexes.editedKey);
          this.saveIndex(this.idIndexKey, indexes.editedId);
          this.saveIndex(this.valueIndexKey, indexes.editedValue);
        }
      } else {
        this.clearIndexes();
      }
    }
  }

  private isLoginPage(currentUrl: string): boolean {
    return (getPathWithoutQueryAndHashParameters(currentUrl) === this.loginPageUrl);
  }

  private saveIndex(indexName: string, index?: string): void {
    this.localStorageHelper.setGlobalSetting(indexName, index);
  }
}
