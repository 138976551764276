import { Component } from '@angular/core';

@Component({
  selector: 'app-language-and-statistic-menu',
  templateUrl: './language-and-statistic-menu.component.html',
  styleUrls: ['./language-and-statistic-menu.component.less']
})
export class LanguageAndStatisticMenuComponent {

  constructor() { }

}
