<h1>{{ 'headers.list-of-features' | translate }}</h1>
<div class="ti-menu">
  <div class="ti-menu__data-wrapper">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <mat-accordion *ngIf="featureDefinitionList" [id]="featureLists">
      <mat-expansion-panel
        *ngFor="let featureDefinitionListElement of featureDefinitionList;
        let featureDefinitionListElementIndex=index"
        appScrollToChangedPreviouslyElement
        [expanded]="featureDefinitionListElement.Key === featuresService.lastAccessedType()">
        <mat-expansion-panel-header>
          <mat-panel-title>{{featureDefinitionListElement.Key}}</mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent ngFor let-feature let-featureDefinitionListIndex="index"
                     [ngForOf]="featureDefinitionListElement.Value">
          <mat-accordion *ngIf="feature">
            <mat-expansion-panel
              appScrollToChangedPreviouslyElement
              [expanded]="(featureDefinitionListElement.Key === featuresService.lastAccessedType()) &&
(featuresService.lastAccessedList() === feature.id)">
              <mat-expansion-panel-header  class="right-aligned-header">
                <mat-panel-title>
                  <span
                    [ngClass]="(feature.localized) ? 'ti--localized': 'ti--not-localized'">
                      {{feature.description}} [{{feature.id}}]</span>
                </mat-panel-title>
                <mat-panel-description>
                    <button mat-mini-fab color="primary"
                    (click)="navigateToEditPage(featureDefinitionListElement.Key, feature.id )">
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>
            <button *ngIf="doesUserHaveAccessToAddAndDelete" mat-mini-fab color="primary"
                    appOpenConfirmDialog
                    (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, feature.id)">
              <mat-icon aria-hidden="true">delete</mat-icon>
            </button>
          </mat-panel-description>
          </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ng-template ngFor let-featureOption let-index="index" [ngForOf]="feature.values">
                  <div class="ti-menu__description"
                  >
                    <div class="ti-menu-description-agreement-info__wrapper">
                      <p class="ti-menu-description__agreement-info">
                  <span
                    id="f{{feature.id}}_{{featureOption.value}}"
                    [ngClass]="(featureOption.localized) ? 'ti--localized': 'ti--not-localized'">{{featureOption.description}} [{{featureOption.id}}] {{featureOption.emoji}}</span>
                      </p>
                      <div class="ti-form__button-panel">

                        <button mat-mini-fab color="primary"
                                (click)="navigateToEditPage(featureDefinitionListElement.Key, feature.id, featureOption.id)">
                          <mat-icon aria-hidden="true">edit</mat-icon>
                        </button>
                        <button *ngIf="doesUserHaveAccessToAddAndDelete" mat-mini-fab color="primary"
                                appOpenConfirmDialog
                                (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, feature.id, featureOption.id)">
                          <mat-icon aria-hidden="true">delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <div class="ti-menu__description-wrapper" *ngIf="doesUserHaveAccessToAddAndDelete">
                  <div class="ti-form__button-panel">
                    <button mat-mini-fab color="primary"
                            (click)="navigateToCreatePage(featureDefinitionListElement.Key, feature.id)">
                      <mat-icon aria-hidden="true">add</mat-icon>
                    </button>
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
        <div class="ti-menu-description-agreement-info__wrapper" *ngIf="doesUserHaveAccessToAddAndDelete">
          <div class="ti-form__button-panel">
            <button mat-mini-fab color="primary"
                    (click)="navigateToCreatePage(featureDefinitionListElement.Key)">
              <mat-icon aria-hidden="true">add</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar *ngIf="!featureDefinitionList" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
