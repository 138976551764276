import {Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';

import {Subscription} from 'rxjs/Subscription';

import {SessionService} from '../../shared/services/session.service';
import {ScrollContentService} from '../../shared/services/scroll-content.service';

@Component({
  selector: 'app-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.less']
})
export class RootPageComponent implements OnInit, OnDestroy {
  public mobileQuery: MediaQueryList;
  private mobileQueryListener;

  public openedMenu: boolean = true;
  public isMobileView: boolean;

  private sessionServiceSubscription: Subscription;
  private scrollContentSubscription: Subscription;
  private scrollToTopContentSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              public media: MediaMatcher,
              private sessionService: SessionService,
              private scrollContentService: ScrollContentService) {

    this.mobileQuery = media.matchMedia('(max-width: 100vh)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }
  
  public isAuthenticated(): boolean {
    return this.sessionService && this.sessionService.isAuthenticated();
  }

  ngOnInit(): void {
    this.sessionServiceSubscription = this.sessionService.currentSessionSource.subscribe((session) => {
    });

    this.scrollContentSubscription = this.scrollContentService.currentOffsetTopSource.subscribe((offsetTop: number) => {
      if ((typeof offsetTop === 'number') && offsetTop) {
        this.scrollContentService.scrollContentBy(offsetTop);
      }
    });

    this.scrollToTopContentSubscription = this.scrollContentService.scrollToTopSourceObservable.subscribe((scrollToTop: boolean) => {
      if (scrollToTop) {
        this.scrollContentService.scrollContentToTop();
      }
    });
  }

  onOpenMenuEvent($event) {
    this.openedMenu = $event;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    this.sessionServiceSubscription.unsubscribe();
    this.scrollContentSubscription.unsubscribe();
    this.scrollToTopContentSubscription.unsubscribe();
  }

}
