import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs/Observable';

@Injectable()
export class GetIdFromRouterParametersResolver implements Resolve<Promise<number>> {

  constructor() {
  }

  public resolve(route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot): Promise<number> {
    return Observable.create((observer) => {
      observer.next(parseInt(route.params.id, 10));
      observer.complete();
    });
  }
}
