<h1>{{ 'headers.punctuation-list' | translate }}</h1>
<mat-progress-bar *ngIf="!punctuation" color="accent" mode="indeterminate"></mat-progress-bar>
<div>
  <mat-table *ngIf="punctuation" #table [dataSource]="punctuation">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
      <mat-cell *matCellDef="let element"
      > {{element.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="languageModel">
      <mat-header-cell
        [ngStyle]="{'flex-basis': (isMobileView && isLookup) ? '33%' : '48%'}"
        *matHeaderCellDef
      > {{languageModel}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          [ngClass]="element.text ? 'ti-sign-in-the-table--editable' : 'ti-sign-in-the-table--empty'">{{element.text}}</span>

        <div>
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration"
                  *ngIf="element.text"
                  [routerLink]="'/punctuation/edit/'+element.id"
                  [queryParams]="{editedId: element.id}">
            <mat-icon aria-hidden="true">edit</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration"
                  *ngIf="element.text && canEditNonLexicon()"
                  appOpenConfirmDialog
                  (doesUserConfirmEvent)="deletePunctuationIfUserConfirmsDeletion(element.id, $event)">
            <mat-icon aria-hidden="true">delete</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration"
                  *ngIf="!element.text && canEditNonLexicon()"
                  [routerLink]="'/punctuation/create/'+element.id"
                  [queryParams]="{editedId: element.id}"
          >
            <mat-icon aria-hidden="true">add</mat-icon>
          </button>
        </div>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="changeableData">
      <mat-header-cell
        [ngStyle]="{'flex-basis': (isMobileView && isLookup) ? '26%' : '31%'}"
        *matHeaderCellDef
      >{{'COMMON.description' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="ti-punctuation--button-panel">
          <span *ngIf="element.type">{{'punctuation_roles_'+element.type | translate}}</span>
          <span *ngIf="element.nonBreakCount">{{element.nonBreakCount}} {{'non-break.non-breaks' | translate}}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{displayedColumns[3]}}">
      <mat-header-cell *matHeaderCellDef>{{referenceLanguageEnglishName}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="ti-sign-in-the-table">{{element.referenceLanguageText}}</span>
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="isLookup" matColumnDef="buttons">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-radio-button
          (click)="selectItem(element.id);"
          [value]="false"
          [name]="'selected-item'"
        ></mat-radio-button>
      </mat-cell>
    </ng-container>


    <!-- BEGIN headers -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             appScrollToEvent
             appScrollToChangedPreviouslyElement
             [shouldBeEmittedScrollToEventEvent]="(lastId === row.id)"></mat-row>
    <!-- END headers -->
  </mat-table>
</div>
