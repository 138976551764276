import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-punctuation-lookup',
  templateUrl: 'punctuation-lookup.component.html',
  styleUrls: ['punctuation-lookup.component.less']
})
export class PunctuationLookupComponent {

  public selectedItemId: number;

  public isLookup: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: {isLookup: boolean}) {
    this.isLookup = dialogData.isLookup;
  }

  public reactOnSelectPunctuationItem(selectedId: number): void {
    this.selectedItemId = selectedId;
  }

}
