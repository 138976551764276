import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { StatisticLevel } from '../../shared/constants/statistic-level.enum';

import { StatisticsService } from '../../shared/services/statistics.service';
import { SessionService } from '../../shared/services/session.service';

import { Statistics } from '../../shared/models/statistics';


@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.less']
})
export class StatisticsComponent implements OnDestroy {
  private languageId: number;
  private sessionServiceSubscription: Subscription;

  public showProgressBar: boolean = false;
  public statistics: Statistics;

  public lexemeFamiliesResult: string;
  public properFamiliesResult: string;
  public nonProperNameFamiliesResult: string;
  public commonConceptsFamiliesResult: string;
  public phraseFamiliesResult: string;

  constructor(private sessionService: SessionService,
    private statisticsService: StatisticsService) {
  }

  calculateStatistics(): void {
    this.sessionServiceSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.statistics = undefined;
    });

    const session = this.sessionService.session;
    this.languageId = session.targetLanguage.id;
    this.showProgressBar = true;
    
    this.statisticsService.getStatistics(this.languageId).then((statistics: Statistics) => {
      this.statistics = statistics;
      this.showProgressBar = false;
      this.lexemeFamiliesResult = this.getStatisticTextClass(
        statistics.LanguageLexemeFamilyCount, statistics.TotalLexemeFamilyCount);

      this.properFamiliesResult = this.getStatisticTextClass(
        statistics.LanguageProperNounLexemeFamilyCount, statistics.TotalProperNounLexemeFamilyCount);

      this.nonProperNameFamiliesResult = this.getStatisticTextClass(
        statistics.LanguageNonProperNounLexemeFamilyCount, statistics.TotalNonProperNounLexemeFamilyCount);

      this.commonConceptsFamiliesResult = this.getStatisticTextClass(
        statistics.LanguageFrequentLexemeFamilyCount, statistics.TotalFrequentLexemeFamilyCount);

      this.phraseFamiliesResult = this.getStatisticTextClass(
        statistics.LanguagePhraseFamilies, statistics.TotalPhraseFamilies);
    });
  }

  public getStatisticLevel(count: number, total: number): number {
    const result = count * 100 / total;
    const statisticLevel = (result >= 68) ? StatisticLevel.good : ((result >= 40) ? StatisticLevel.average : StatisticLevel.bad);

    return statisticLevel;
  }

  public getStatisticTextClass(count: number, total: number): string {
    const statisticLevel = this.getStatisticLevel(count, total);
    const textClass = (statisticLevel ? ((statisticLevel === 2) ? 'ti-green-text' : 'ti-yellow-text') : 'ti-red-text');

    return textClass;
  }

  ngOnDestroy(): void {
    if (this.sessionServiceSubscription) {
      this.sessionServiceSubscription.unsubscribe();
    }
  }

}
