import {Injectable} from '@angular/core';

import {LocalStorageHelper} from '../helpers/localhost.helper';
import {
  FeatureListDefinition,
  FeatureLocalization,
  FeaturesListElement,
  DefaultFeatureValue,
  DefaultFeatureValuesListElement, FeatureCategory, FeatureDefinition
} from '../models/feature';

import {applicationConstants} from '../constants/application-constants';
import {commonTabSettings} from '../../shared/constants/application-constants';

import {toJsonObject} from '../helpers/object.helper';
import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import {LampUpdateResponse} from '../models/common';
import {SessionData} from '../models/session';

@Injectable()
export class FeaturesService {

  private _lastAccessedList: number = 0;
  private _lastAccessedValue: string = "";
  private static SETTING_PREFIX: string = "feature_"; 
  private _listIdTypes: Map<number, string> = new Map<number, string>();

  constructor(private localStorageHelper: LocalStorageHelper,
              private apiClientService: ApiClientService) {
    this.loadLastAccessed();
  }

  public lastAccessedType(): string {
    if (this._listIdTypes.has(this._lastAccessedList))
      return this._listIdTypes.get(this._lastAccessedList);
    else
      return undefined;
  }

  public lastAccessedList(): number {
    return this._lastAccessedList;
  }

  public lastAccessedValue(): string {
    return this._lastAccessedValue;
  }

  public lastAccessedElementId(): string {
    return 'f' + this._lastAccessedList.toString() + '_' + this._lastAccessedValue;
  }

  public scrollToLast(): void {
    try {
      let lastElement = document.getElementById(this.lastAccessedElementId());
      if (lastElement)
        lastElement.scrollTo(0, 0);
    } catch(err) {
      console.warn(err.error);
    }
  }

  private loadLastAccessed(): void {
    this._lastAccessedList = this.localStorageHelper.getNumberTabSetting(
      FeaturesService.SETTING_PREFIX + commonTabSettings.idIndexKey
    );
    this._lastAccessedValue = this.localStorageHelper.getTabSetting(
      FeaturesService.SETTING_PREFIX + commonTabSettings.valueIndexKey
    );
  }

  private storeLastAccessed(list?: number, value?: string): void {
    let listType: string = undefined;
    if (this._listIdTypes.has(list))
      listType = this._listIdTypes.get(list);
    if (list > 0 && listType) {
      this.localStorageHelper.setTabSetting(FeaturesService.SETTING_PREFIX 
        + commonTabSettings.type, listType);
      this.localStorageHelper.setNumberTabSetting(FeaturesService.SETTING_PREFIX + commonTabSettings.idIndexKey, list);
      if (value)
        this.localStorageHelper.setTabSetting(FeaturesService.SETTING_PREFIX 
            + commonTabSettings.valueIndexKey, value);
      else
        this.localStorageHelper.clearTabSetting(FeaturesService.SETTING_PREFIX + commonTabSettings.valueIndexKey);
    } else {
      this.localStorageHelper.clearTabSetting(FeaturesService.SETTING_PREFIX + commonTabSettings.type);
      this.localStorageHelper.clearTabSetting(FeaturesService.SETTING_PREFIX + commonTabSettings.idIndexKey);
      this.localStorageHelper.clearTabSetting(FeaturesService.SETTING_PREFIX + commonTabSettings.valueIndexKey);
    }
  }

  public getAllFeatures(): FeaturesListElement[] | undefined {
    const allFeaturesAsAString = this.localStorageHelper.getGlobalSetting(applicationConstants.cookieKeys.features);
    try {
      return (allFeaturesAsAString && allFeaturesAsAString.length > 0 ? (JSON.parse(allFeaturesAsAString) as FeaturesListElement[]) : undefined);
    } catch (err) {
      return undefined;
    }
  }

  public loadFeatureValues(): Promise<FeaturesListElement[]> {
    return this.apiClientService
      .get(EndpointUrls.features.featuresInUse)
      .then((allFeatures) => {
        this.localStorageHelper.setGlobalSetting(applicationConstants.cookieKeys.features,
          JSON.stringify(allFeatures));

        this.mapTypes(allFeatures as FeaturesListElement[]);

        return (allFeatures as FeaturesListElement[]);
      });
  }

  private getFeaturesListFromAllFeaturesList(allFeatures: FeaturesListElement[], type?: string): FeatureListDefinition[] | undefined {
    var featureValues = (allFeatures.find(element => (element.Key === type))).Value;
    for (let i = 0; i < featureValues.length; i++) {
      featureValues[i].hasEmpty = true; // empty is always allowed
      featureValues[i].relevantPartially = false;           
      featureValues[i].relevant = true;
      for (let j = 0; j < featureValues[i].values.length; j++)
        featureValues[i].values[j].relevant = true;
    }
    return featureValues;
  }

  private mapTypes(allFeatureTypes: FeaturesListElement[]): void {
    this._listIdTypes.clear();
    for (let ft of allFeatureTypes)
    {
      let currentType: string = ft.Key;
      for (let featureList of ft.Value)
        this._listIdTypes.set(featureList.id, currentType);
    }
  }

  public getFeatureList(type?: string): Promise<FeatureListDefinition[]> {
    type = type ? type : applicationConstants.grammarFeatureType;
    const allFeatures = this.getAllFeatures();
    this.loadLastAccessed();

    if (allFeatures) {
      return Promise.resolve(this.getFeaturesListFromAllFeaturesList(allFeatures, type));
    }

    return this.loadFeatureValues().then((refreshedFeaturesList) => {
      return this.getFeaturesListFromAllFeaturesList(refreshedFeaturesList, type);
    });
  }

  // takes a JSON containing a list of relevant features (the "relevant" element) and builds a new Feature list out of it
  public createFilteredRelevantFeatureList(relevantFeatureJson?: { Key: number, Value: string[] }[], type?: string): Promise<FeatureListDefinition[]> {
    return this.getFeatureList(type).then((allFeaturesPerType: FeatureListDefinition[]) => {
      const filtered: FeatureListDefinition[] = toJsonObject(allFeaturesPerType) as FeatureListDefinition[]; // these are all features of a specific type
      
      // for those that don't need filtering, enable everything
      for (let i = 0; i < filtered.length; i++) {
        filtered[i].relevant = true;
        filtered[i].relevantPartially = false;
        for (let fi = 0; fi < filtered[i].values.length; fi++) {
          filtered[i].values[fi].relevant = true;
        }
      }
      if (!type || type !== applicationConstants.grammarFeatureType)
        return (filtered as FeatureListDefinition[]); // filtering is only relevant for the grammar
      if (relevantFeatureJson && relevantFeatureJson.length > 0) {
        var hasFeatures = false;

        // the list of features is copied from the full list, then every feature gets .relevant and .relevantPartially (if some values are filtered) attributes
        for (let i = 0; i < filtered.length; i++) {
          const listBeingFiltered = filtered[i];

          const listIndexInJson: number =
            relevantFeatureJson.findIndex(entryInJson => (listBeingFiltered.id === entryInJson.Key));

          if (listIndexInJson < 0) {
            listBeingFiltered.relevant = false;
          } else {
            const listOfValuesForCurrentFeatureInJson = relevantFeatureJson[listIndexInJson].Value;
            //const canCurrentFeatureHaveEmptyValue: boolean = (listOfValuesForCurrentFeatureInJson.indexOf('') !== -1);

            listBeingFiltered.hasEmpty = true; // empty is always allowed
            listBeingFiltered.relevantPartially = false;           
            listBeingFiltered.relevant = false;

            if (listOfValuesForCurrentFeatureInJson.length > -1) {
              if (listBeingFiltered.values.length >= listOfValuesForCurrentFeatureInJson.length) {
                for (let j = 0; j < listBeingFiltered.values.length; j++) {
                  const currentFeatureValueFromCompleteList = listBeingFiltered.values[j];

                  const isCurrentValueRelevant = listOfValuesForCurrentFeatureInJson.findIndex(
                      featureValueArrayElementInJson =>
                      (currentFeatureValueFromCompleteList.id === featureValueArrayElementInJson)) > -1;

                  listBeingFiltered.values[j].relevant = isCurrentValueRelevant;

                  listBeingFiltered.relevant = true;
                  if (!isCurrentValueRelevant)
                    listBeingFiltered.relevantPartially = true;
                }
              } else {
                listBeingFiltered.relevant = true;
              }
            }
            if (listBeingFiltered.relevant)
              hasFeatures = true;

          }

        }
        
        if (!hasFeatures)
          return (filtered as FeatureListDefinition[]);

        return (filtered as FeatureListDefinition[]);
      } else {
        return (filtered as FeatureListDefinition[]);
      }
    });

  }


  public getFeatureListLocalizationList(): Promise<FeaturesListElement[]> {
    return this.apiClientService
      .get(EndpointUrls.features.featureListLocalizationList)
      .then((featureListLocalizationList) => {
        this.mapTypes(featureListLocalizationList as FeaturesListElement[]);
        return (featureListLocalizationList as FeaturesListElement[]);
      });
  }

  public getFeatureListLocalizationById(featureListLocalizationId: number): Promise<FeatureLocalization> {
    return this.apiClientService
      .get(`${EndpointUrls.features.featureListLocalization}?id=${featureListLocalizationId}`)
      .then((featureListLocalization) => {
        this.storeLastAccessed(featureListLocalizationId);
        return (featureListLocalization as FeatureLocalization);
      });
  }

  public updateFeatureListLocalization(featureLocalization: FeatureLocalization): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.features.featureListLocalization, featureLocalization)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        this.storeLastAccessed(featureLocalization.id);
        return successfulResponseMessage;
      });
  }

  public getFeatureListLocalizationByIdAndValue(featureListLocalizationId: number,
                                                featureLocalizationValue: string): Promise<FeatureLocalization> {
    return this.apiClientService
      .get(`${EndpointUrls.features.featureLocalization}?id=${featureListLocalizationId}&value=${featureLocalizationValue}`)
      .then((featureListLocalization) => {
        return (featureListLocalization as FeatureLocalization);
      });
  }

  public updateFeatureLocalization(featureLocalization: FeatureLocalization): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.features.featureLocalization, featureLocalization)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        this.storeLastAccessed(featureLocalization.id);
        return successfulResponseMessage;
      });
  }


  public getDefaultFeatureValuesList(): Promise<DefaultFeatureValuesListElement[]> {
    return this.apiClientService
      .get(EndpointUrls.morphology.defaultFeatureValuesList)
      .then((defaultFeatureValuesList) => {
        return (defaultFeatureValuesList as DefaultFeatureValuesListElement[]);
      });
  }

  public getDefaultFeatureValueById(defaultFeatureValuesId: number): Promise<DefaultFeatureValue> {
    return this.apiClientService
      .get(`${EndpointUrls.morphology.defaultFeatureValues}?id=${defaultFeatureValuesId}`)
      .then((defaultFeatureValue) => {
        this.storeLastAccessed(defaultFeatureValuesId);
        return defaultFeatureValue as DefaultFeatureValue;
      });
  }

  public updateDefaultFeatureValue(defaultFeatureValue: any): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.morphology.defaultFeatureValues, defaultFeatureValue)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public createDefaultFeatureValue(defaultFeatureValue: any): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.morphology.defaultFeatureValues, defaultFeatureValue)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deleteDefaultFeatureValue(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.morphology.defaultFeatureValues}?id=${id}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  // definition menu item

  public getFeatureDefinitionList(): Promise<FeaturesListElement[]> {
    return this.apiClientService
      .get(EndpointUrls.features.hierarchicalFeatureDefinitionList)
      .then((hierarchicalFeatureDefinitionList: FeaturesListElement[]) => {
        this.loadLastAccessed();
        this.mapTypes(hierarchicalFeatureDefinitionList as FeaturesListElement[]);
        return (hierarchicalFeatureDefinitionList as FeaturesListElement[]);
      });
  }


  public getFeatureDefinitionListDetailsById(id: number): Promise<FeatureCategory> {
    return this.apiClientService
      .get(`${EndpointUrls.features.featureDefinitionListDetails}?id=${id}`)
      .then((featureDefinitionListDetails) => {
        this.storeLastAccessed(id);
        return (featureDefinitionListDetails as FeatureCategory);
      });
  }

  public updateFeatureDefinitionList(featureCategory: FeatureCategory): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.features.featureDefinitionListDetails, featureCategory)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        this.storeLastAccessed(featureCategory.id);
        return successfulResponseMessage;
      });
  }

  public createFeatureDefinitionList(featureCategory: FeatureCategory): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.features.featureDefinitionListDetails, featureCategory)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        this.storeLastAccessed(featureCategory.id);
        return successfulResponseMessage;
      });
  }

  public deleteFeatureDefinitionListById(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.features.featureDefinitionListDetails}?id=${id}`)
      .then((resultMessage: LampUpdateResponse) => {
        this.storeLastAccessed(id);
        return resultMessage;
      });
  }

  public getFeatureDefinitionByIdAndFeatureDefinitionListId(featureListId: number, id: number | string): Promise<FeatureDefinition> {
    return this.apiClientService
      .get(`${EndpointUrls.features.feature}?featureList=${featureListId}&id=${id.toString()}`)
      .then((featureDefinition) => {
        this.storeLastAccessed(featureListId, id.toString());
        return (featureDefinition as FeatureDefinition);
      });
  }

  public updateFeatureDefinition(featureDefinition: FeatureDefinition): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.features.feature, featureDefinition)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        try {
          this.storeLastAccessed(parseInt(featureDefinition.index.toString()), featureDefinition.id.toString());
        } catch (error) {}
        return successfulResponseMessage;
      });
  }

  public createFeatureDefinition(featureDefinition: FeatureDefinition): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.features.feature, featureDefinition)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        try {
          this.storeLastAccessed(parseInt(featureDefinition.index.toString()), featureDefinition.id.toString());
        } catch (error) {}
        return successfulResponseMessage;
      });
  }

  public deleteFeatureDefinitionByIdAndFeatureDefinitionListId(featureListId: number, id: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.features.feature}?featureList=${featureListId}&id=${id}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        try {
          this.storeLastAccessed(featureListId);
        } catch (error) {}
        return successfulResponseMessage;
      });
  }

  public refreshSession(): Promise<SessionData> {
    return this.apiClientService.get(EndpointUrls.refreshSessionNoReload);
  }

}
