import {Component, OnInit} from '@angular/core';

import {FeatureListDefinition, FeaturesListElement} from '../../shared/models/feature';
import {applicationModulesRoutePaths, commonTabSettings} from '../../shared/constants/application-constants';

import {FeaturesService} from '../../shared/services/features.service';
import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import {RouterHelper} from '../../shared/helpers/router.helper';

@Component({
  selector: 'app-feature-localization-menu',
  templateUrl: './feature-localization-menu.component.html',
  styleUrls: ['./feature-localization-menu.component.less']
})
export class FeatureLocalizationMenuComponent implements OnInit {
  public featureListLocalizationList: FeaturesListElement[];

  public visitedFeatureListLocalizationListKey: string;
  public visitedFeatureListLocalizationId: number;
  public visitedFeatureLocalizationValue: string;

  public changedPreviouslySelector = commonTabSettings.changedPreviouslySelector;
  public pathToEditPages: string = applicationModulesRoutePaths.administration.administration.path +
    applicationModulesRoutePaths.administration.localization.path + '/edit/';

  public keyIndexKey = commonTabSettings.keyIndexKey;
  public idIndexKey = commonTabSettings.idIndexKey;
  public valueIndexKey = commonTabSettings.valueIndexKey;

  constructor(private featuresService: FeaturesService,
              private localStorageHelper: LocalStorageHelper,
              private routerHelper: RouterHelper) {
  }

  ngOnInit(): void {
    this.getCurrentFeatureListLocalizationListKeyIndex();
    this.getCurrentFeatureListLocalizationIdIndex();
    this.getCurrentFeatureLocalizationValueIndex();

    this.getFeatureListLocalizationList();
  }

  public getFeatureListLocalizationList() {
    this.featuresService.getFeatureListLocalizationList().then((featureListLocalizationList: FeaturesListElement[]) => {
      this.featureListLocalizationList = featureListLocalizationList;
    });
  }

  public navigateToEditPage(featureListLocalizationListKey: string,
                            featureListLocalizationId: number, featureLocalizationValue?: string) {

    let page = this.pathToEditPages + featureListLocalizationId.toString();
    const queryParameters = {
      editedKey: featureListLocalizationListKey,
      editedId: featureListLocalizationId.toString(),
      editedValue: ''
    };

    if (featureLocalizationValue) {
      page = page + '/feature/' + featureLocalizationValue;
      queryParameters.editedValue = featureLocalizationValue;
    }

    this.routerHelper.navigateToPageWithQueryParameters(page, queryParameters);
  }

  public getCurrentFeatureListLocalizationListKeyIndex(): void {
    this.visitedFeatureListLocalizationListKey = decodeURIComponent(this.getCashedIndex(this.keyIndexKey));
  }

  public getCurrentFeatureListLocalizationIdIndex(): void {

    const currentIndex = parseInt(this.getCashedIndex(this.idIndexKey), 10);
    this.visitedFeatureListLocalizationId = (currentIndex && !isNaN(currentIndex)) ? currentIndex : undefined;
  }

  public getCurrentFeatureLocalizationValueIndex(): void {
    this.visitedFeatureLocalizationValue = decodeURIComponent(this.getCashedIndex(this.valueIndexKey));
  }

  private getCashedIndex(indexName: string): string {
    return this.localStorageHelper.getTabSetting(indexName);
  }

}
