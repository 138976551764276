import {Directive, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog} from '@angular/material';

import {AdvancedCriteriaDialogComponent} from './advanced-criteria-dialog.component';
import {AdvancedCriteria} from '../../shared/models/lexicon';

@Directive({
  selector: '[appAdvancedCriteriaButton]'
})
export class AdvancedCriteriaButtonDirective {

  @Input() forWords: boolean;
  @Input() dialogTitle: string;

  @Output() selectedDataEvent: EventEmitter<AdvancedCriteria> = new EventEmitter<AdvancedCriteria>();

  private isMobileView: boolean;

  constructor(
    public dialog: MatDialog
  ) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(
      AdvancedCriteriaDialogComponent,
      {
        minWidth: this.isMobileView ? '95%' : '80%',
        maxHeight: this.isMobileView ? '95%' : '',
        id: 'advancedCriteriaDialog',
        data: {
          forWords: this.forWords,
          dialogTitle: this.dialogTitle
        }
      }
    );

    dialogRef.afterClosed()
      .subscribe((advancedCriteria: AdvancedCriteria) => {
        this.selectedDataEvent.emit(advancedCriteria);
      });
  }

}
