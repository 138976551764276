<h1>{{ 'TEST.gold-standard-results-transformation' | translate }}</h1>
<div class="ti-form__one-line-fields ti-range__column-dir" style="justify-content: center;">
  <mat-form-field class="ti-form-half-line-fields__field" color="accent">
    <mat-select role="listbox" placeholder="{{ 'COMMON.type-of-results' | translate}}" [(value)]="selectedTestProvider"
      (selectionChange)="changeTestProvider($event)"
      [required]="true">
      <mat-option value="">-- None --</mat-option>
      <mat-option *ngFor="let option of testProviderValues" [value]="option.key">
        {{option.value | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<app-universal-id-text-range-filter *ngIf="selectedTestProvider && selectedTestProvider >= 0"
  [allowOtherQueryParameters]="true" [currentRange]="'testresults'" [loadedRecordCount]="results ? results.length : 0"
  (chosenParameterEvent)="loadData($event)">
</app-universal-id-text-range-filter>
<app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
<mat-progress-bar *ngIf="loading()" color="accent" mode="indeterminate"></mat-progress-bar>
<mat-accordion *ngIf="results" multi="true">
  <div infiniteScroll [scrollWindow]="false" ngx-infinite-scroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50" (scrolled)="loadMore()" class="viewport">
    <ng-container *ngFor="let res of showingResults">
      <mat-expansion-panel id="{{res.requestId}}">
        <mat-expansion-panel-header>
          <mat-panel-title *ngIf="res.id" class="ti-panel-title">
            <span *ngIf="res.created">[{{res.created}}]&nbsp;</span>
            <span *ngIf="res.rating" matTooltip="{{interpretRating(res.rating)}}">{{res.rating}}&nbsp;</span>
            <span *ngIf="res.grade1">{{res.grade1}}&nbsp;</span>
            <span *ngIf="res.grade2">{{res.grade2}}&nbsp;</span>
            <span *ngIf="res.grade3">{{res.grade3}}&nbsp;</span>
            <span *ngIf="res.abuse && res.abuse.length &gt; 0"
              matTooltip="{{toSummary(res.fragment, res.abuse, undefined)}}">&nbsp;{{getAbuseEmoji(res)}}</span>
            <span *ngIf="res.sentiment && res.sentiment.length &gt; 0"
              matTooltip="{{toSummary(res.fragment, res.sentiment, undefined)}}">&nbsp;{{getSentimentEmoji(res)}}</span>
            <span *ngIf="res.entities && res.entities.length &gt; 0"
              matTooltip="{{toSummary(res.fragment, res.entities, 'subtype')}}">&nbsp;{{getEntityEmoji(res)}}</span>
            <span *ngIf="res.topics && res.topics.length &gt; 0"
              matTooltip="{{toSummary(res.fragment, res.topics, 'label')}}">&nbsp;📚</span>
            &nbsp;
          </mat-panel-title>
          <mat-panel-description [style.color]="(!txtRaw && !res.raw) || (txtRaw && !txtRaw.value) ? 'red' : 'black'"
            class="ti-panel-description">
            {{panelTitle(res)}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container class="mat-tab-content">
          <div class="test-fragment-id">
            <p class="test-fragment-id__label">{{'TEST.test-fragment-id' | translate}}</p>
            <p class="test-fragment-id__value">{{res.testFragmentId}}</p>
          </div>
          <mat-form-field color="accent">
            <textarea matInput [placeholder]="'TEST.fragment' | translate" [(ngModel)]="res.fragment" readonly
              cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-form-field color="accent">
            <textarea matInput [style.text-align]="textAlignStyle()" matTooltip="" #txtRaw [lang]="languageISOCode()"
              [dir]="languageDir()" spellcheck="true" [placeholder]="'TEST.transformation' | translate"
              cdkTextareaAutosize>{{res.raw}}</textarea>
          </mat-form-field>

          <div class="bottom-actions">
            <a target="_blank"
              [href]="'https://translate.google.com/?sl=' + languageISOCode() + '&amp;tl=en&amp;text=' + res.fragment + '&amp;op=translate'">{{'COMMON.research'
              | translate}}</a>
            <button [disabled]="(!res.id && !txtRaw.value) || (res.id && txtRaw.value && res.raw == txtRaw.value)"
              mat-raised-button color="accent" (click)="save(res, txtRaw.value)">{{(res.raw ?
              'COMMON.update' :
              'COMMON.create') | translate}}
            </button>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </div>
</mat-accordion>