<div class="ti-dialog__wrapper">
  <div class="ti-ac__title">
    <h1>{{ isEdit ?
            ('advanced-criteria.advanced-criteria-edit' | translate) :
            isCopying ? ('advanced-criteria.advanced-criteria-copying' | translate) : ('advanced-criteria.advanced-criteria-creation' | translate)
    }}</h1>
  </div>
  <mat-dialog-content>
    <app-advanced-criteria-edit
      #advancedCriteriaEdit
      [isEdit]="isEdit"
      [currentId]="currentId"
      [forWords]="forWords"
      [isCopying]="isCopying"
    ></app-advanced-criteria-edit>
  </mat-dialog-content>
  <mat-dialog-actions class="ti-actions">
    <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
    <button
      mat-raised-button
      color="accent"
      (click)="saveAdvancedCriteria()"
      [disabled]="isGuest"
    >{{'COMMON.save' | translate}}</button>
  </mat-dialog-actions>
</div>
