<div class="ti-breaks-table-wrapper">
  <mat-table *ngIf="phonemes" #nonBreaksTable [dataSource]="phonemes">
    <ng-container *ngIf="isDialog" [matColumnDef]="displayedColumns[0]">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox (change)="toggle(element)"
                      [disabled]="((chosenElementId !== 0) && (chosenElementId !== element.id))">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="isDialog ? displayedColumns[1] : displayedColumns[0]"
    >
      <mat-header-cell
        *matHeaderCellDef
        [ngStyle]="{'flex-basis': (isMobileView && isDialog) ? '39%' : '28%'}"
      >ID</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.id">{{element.id}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{isDialog ? displayedColumns[2] : displayedColumns[1]}}">
      <mat-header-cell *matHeaderCellDef>{{isDialog ? displayedColumns[2] : displayedColumns[1]}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          *ngIf="element.id"
          [ngClass]="element.phoneme ? 'ti-sign-in-the-table--editable' : 'ti-sign-in-the-table--empty'"
          [matTooltip]="element.note"
          [matTooltipDisabled]="!element.note"
        >
          {{element.phoneme}}</span>
        <div class="ti-phonemes--button-panel">
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration ti-phonemes-button"
                  *ngIf="element.phoneme && !isDialog"
                  [routerLink]="'/phonemes/edit/'+element.id"
                  [queryParams]="{editedId: element.id}">
            <mat-icon aria-hidden="true">edit</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration ti-phonemes-button"
                  *ngIf="element.phoneme && doesUserHaveAccessToEdit && !isDialog"
                  appOpenConfirmDialog
                  (doesUserConfirmEvent)="deletePhonemeIfUserConfirmsDeletion(element.id, $event)"
          >
            <mat-icon aria-hidden="true">delete</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration ti-phonemes-button"
                  *ngIf="!element.phoneme && doesUserHaveAccessToEdit && !isDialog"
                  [routerLink]="'/phonemes/create/'+element.id"
                  [queryParams]="{editedId: element.id}"
          >
            <mat-icon aria-hidden="true">add</mat-icon>
          </button>
        </div>

      </mat-cell>
    </ng-container>

    <ng-container *ngIf="!isDialog" [matColumnDef]="displayedColumns[2]">
      <mat-header-cell *matHeaderCellDef>{{'aliases.aliases' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="ti-phonemes-alias" *ngIf="element.id && element.aliasList">{{element.aliasList}}</span>
      </mat-cell>
    </ng-container>


    <ng-container *ngIf="!isDialog" [matColumnDef]="displayedColumns[3]">
      <mat-header-cell *matHeaderCellDef>{{'COMMON.position' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="ti-phonemes-position-panel"
             [matTooltip]="getCurrentPositionText(element.position)"
             [matTooltipDisabled]="!element.position">
          <app-phonemes-position *ngIf="element.position" [position]="element.position"></app-phonemes-position>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="isDialog ? displayedColumns[3] : displayedColumns[4]">
      <mat-header-cell *matHeaderCellDef>{{isDialog ? displayedColumns[3] : displayedColumns[4]}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          class="ti-sign-in-the-table"
          *ngIf="element.id && element.referencePhoneme"
          [matTooltip]="element.referenceNote"
          [matTooltipDisabled]="!element.referenceNote">{{element.referencePhoneme}}</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             appScrollToEvent
             appScrollToChangedPreviouslyElement
             [shouldBeEmittedScrollToEventEvent]="(elementIndex === row.id)"
             [ngClass]="(elementIndex === row.id) ? changedPreviouslySelector: ''"></mat-row>
  </mat-table>
</div>
