export enum EntityType {
  ABUSE = "abuse",
  CLITIC = "clitic",
  COMMONSENSE_CUE = "commonsense_cue",
  COMMONSENSE_CUE_MATCH = "commonsense_cue_match",
  CONSTITUENT = "constituent",
  FAMILY = "family",
  HYPOTHESIS = "hypothesis",
  INPUT = "input",
  LEXICAL_ITEM = "lexical_item",
  MWE = "mwe",
  PARAGRAPH = "paragraph",
  PARSE = "parse",
  PHRASAL_ELEMENT = "phrasal_element",
  PHRASAL_PATTERN = "phrasal_pattern",
  PHRASE = "phrase",
  SEGMENTATION = "segmentation",
  SENSE = "sense",
  SENTENCE = "sentence",
  SENTIMENT = "sentiment",
  NON_BREAK_MATCH = "nonbreak_match"
}
