import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import {ApiClientService} from './api-client.service';
import {NonBreak, NonDictionary} from '../models/non-break';

@Injectable()
export class NonBreaksService {

  constructor(private apiClientService: ApiClientService) {
  }


  public getNonBreaks(punctuationId: number): Promise<any> {
    return this.apiClientService
      .get(`${EndpointUrls.nonBreaks.nonBreaksList}?punctuation=${punctuationId}`)
      .then((nonBreaksList) => {
        return (nonBreaksList as NonBreak[]);
      });
  }


  public getNonBreaksListForCurrentLanguage(): Promise<any> {
    return this.apiClientService
      .get(EndpointUrls.nonBreaks.nonBreaksList)
      .then((nonBreaksList) => {
        return (nonBreaksList as NonBreak[]);
      });
  }

  public getNonDictionaryList(): Promise<any> {
    return this.apiClientService
      .get(EndpointUrls.nonDictionaryList)
      .then((nonDictionaryList) => {
        return (nonDictionaryList as NonDictionary[]);
      });
  }

  public getNonBreakData(nonBreakId: number): Promise<any> {
    return this.apiClientService
      .get(`${EndpointUrls.nonBreaks.nonbreak}?id=${nonBreakId}`)
      .then((result) => {
        return result;
      });
  }

  public updateNonBreak(nonBreakData: NonBreak): Promise<any> {
    return this.getNonBreakData(nonBreakData.id).then(data => {
      return this.apiClientService
        .put(EndpointUrls.nonBreaks.nonbreak, nonBreakData)
        .then((result) => {
          return result;
        });
    });
  }

  public createNonBreak(nonBreakData: NonBreak): Promise<any> {
    return this.apiClientService
      .post(EndpointUrls.nonBreaks.nonbreak, nonBreakData)
      .then((result) => {

        return result;
      });
  }

  public deleteNonBreak(nonBreakId: number): Promise<any> {
    return this.getNonBreakData(nonBreakId).then(data => {
      return this.apiClientService
        .deleteRequest(`${EndpointUrls.nonBreaks.nonbreak}?id=${nonBreakId}`)
        .then((result) => {
          return result;
        });
    });
  }
}

