import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {applicationConstants} from '../../shared/constants/application-constants';
import {emptyFeatureValue, FeatureListDefinition, FeatureValue} from '../../shared/models/feature';

import {Language, LanguageDescription, LanguageSegmentation, languageSegmentationOptions} from '../../shared/models/language';
import {LanguageService} from '../../shared/services/language.service';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {FeaturesService} from '../../shared/services/features.service';
import {toJsonObject} from '../../shared/helpers/object.helper';

import {
  openChangesHaveBeenSavedDialog
} from '../../universal-components/changes-have-been-saved-dialog/open-changes-have-been-saved-dialog';
import {LampUpdateResponse} from '../../shared/models/common';

@Component({
  selector: 'app-language-settings',
  templateUrl: './language-settings.component.html',
  styleUrls: ['./language-settings.component.less']
})
export class LanguageSettingsComponent implements OnInit {
  public key = applicationConstants.grammarFeatureType;
  public semanticFeatureIndex = applicationConstants.semanticFeature.index;
  private semanticFeatureType = applicationConstants.semanticFeature.type;
  private styleFeatureType = applicationConstants.styleFeatureType;

  public relevantFeatureList: FeatureListDefinition[];

  public doesTheWholeFeatureListRelevant: boolean;

  public languageDescription: LanguageDescription;
  private initialLanguageDescription: LanguageDescription;
  public languageDescriptionForm: FormGroup;

  public featureValue: FeatureValue = emptyFeatureValue;
  public isOnlyOneFeature = true;
  public languageSegmentationOptions = languageSegmentationOptions;

  public semanticFeaturesValuesList: FeatureValue[];
  public semanticFeaturesList: FeatureListDefinition[];
  public semanticFeature: FeatureListDefinition;

  public grammarFeaturesList: FeatureListDefinition[];
  public styleFeaturesList: FeatureListDefinition[];

  private mouseEnterOnElementSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mouseEnterOnElementObservable: Observable<boolean> = this.mouseEnterOnElementSubject.asObservable();
  private mouseLeaveFromElementSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mouseLeaveFromElementObservable: Observable<boolean> = this.mouseLeaveFromElementSubject.asObservable();

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  constructor(private languageService: LanguageService,
              private routerHelper: RouterHelper,
              private formBuilder: FormBuilder,
              private featuresService: FeaturesService,
              private changeDetectorRef: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getLanguageDescription();
  }

  public mouseEnterOnElement() {
    this.mouseEnterOnElementSubject.next(true);
  }

  public mouseLeaveFromElement() {
    this.mouseLeaveFromElementSubject.next(true);
  }


  private createForm(): void {
    this.languageDescriptionForm = this.formBuilder.group({
      ietf: [this.languageDescription.ietf],
      systemLanguage: [this.languageDescription.systemLanguage],
      name: [this.languageDescription.name],
      englishName: [this.languageDescription.englishName],
      isHyphenationMWEMarker: [this.languageDescription.isHyphenationMWEMarker],
      encoding: [this.languageDescription.encoding],
      charset: [this.languageDescription.charset],
      inflectionSanityCheckRegex: [this.languageDescription.inflectionSanityCheckRegex],
      font: [this.languageDescription.font],
      segmentation: [this.languageDescription.segmentation],
      translationReferenceURL: [this.languageDescription.translationReferenceURL],
      lexemeReferenceURL: [this.languageDescription.lexemeReferenceURL],
      maximumPunctuationNonbreakPrefix: [this.languageDescription.maximumPunctuationNonbreakPrefix],
      doProperNounsInflect: [this.languageDescription.doProperNounsInflect],
      complementPhraseFallback: [this.languageDescription.complementPhraseFallback],
      nonproperFallbackNativeInflection: [this.languageDescription.nonproperFallbackNativeInflection],
      properFallbackNativeInflection: [this.languageDescription.properFallbackNativeInflection],
      alwaysAutogenMisspellings: [this.languageDescription.alwaysAutogenMisspellings],
      corpusUrl: [this.languageDescription.corpusUrl],
      customCollation: [this.languageDescription.customCollation],
      allowGenerativeAI: [this.languageDescription.allowGenerativeAI]
    });
  }

  private getLanguageDescription(): void {
    this.languageService.getLanguageDescription().then((languageDescription: LanguageDescription) => {
      this.initialLanguageDescription = languageDescription;
      this.languageDescription = toJsonObject(languageDescription) as LanguageDescription;

      this.doesTheWholeFeatureListRelevant = !Boolean((languageDescription.relevant));

      this.featuresService.getFeatureList().then((featuresList: FeatureListDefinition[]) => {
        if (languageDescription.relevant) {
          this.featuresService.createFilteredRelevantFeatureList(languageDescription.relevant).then((relevantFeatureList)=>{
            this.relevantFeatureList = relevantFeatureList;

            this.getFeatureList();

            if (languageDescription.compoundHead) {
              this.featureValue = languageDescription.compoundHead as FeatureValue;
              this.changeDetectorRef.markForCheck();
            }
            this.createForm();
          });
        } else {
          this.relevantFeatureList = featuresList;
          this.getFeatureList();

          if (languageDescription.compoundHead) {
            this.featureValue = languageDescription.compoundHead as FeatureValue;
            this.changeDetectorRef.markForCheck();
          }
          this.createForm();
        }

      });
    });
  }

  private createFeaturesValuesList(): FeatureValue[] {
    if(!this.initialLanguageDescription.features)
      return [];
    
      const semanticFeaturesValuesList = toJsonObject(this.initialLanguageDescription.features) as FeatureValue[];
    semanticFeaturesValuesList.map(element => delete element.type);
    return (semanticFeaturesValuesList) as FeatureValue[];
  }

  private getFeatureList(): void {
    this.semanticFeaturesValuesList = this.createFeaturesValuesList();

    let semanticType = this.semanticFeatureType;
    let semanticIndex = this.semanticFeatureIndex;
    if (this.initialLanguageDescription.features && this.initialLanguageDescription.features[0] &&
      this.initialLanguageDescription.features[0].type) {
      semanticType = this.initialLanguageDescription.features[0].type;
      semanticIndex = this.initialLanguageDescription.features[0].index;
    }

    this.featuresService.getFeatureList(semanticType)
      .then((semanticFeaturesList) => {
        this.semanticFeaturesList = semanticFeaturesList;
        this.semanticFeature = this.semanticFeaturesList.find(element => (element.index === semanticIndex));
        
        return this.featuresService.getFeatureList().then((result) => {
          this.grammarFeaturesList = result;
          return this.featuresService.getFeatureList(this.styleFeatureType).then(result => {
            this.styleFeaturesList = result;
          })
        })
      })
  }

  public setFeatureTypeForList(type: string, event: FeatureValue) {
    event.type = type;
    return event;
  }

  public updateCurrentSemanticFeatureValue($event: FeatureValue[]): void {
    this.semanticFeaturesValuesList = $event;
  }

  public doesSegmentationEqualToTheDecompoundingValue(): boolean {
    return this.languageDescription.segmentation === LanguageSegmentation.decompounding;
  }

  public saveUpdatedFeatures($event): void {
    this.languageDescription.compoundHead = $event;
  }

  public cancel(): void {
    this.routerHelper.back();
  }

  private getDifferenceBetweenInitialAndCurrentLanguageDescription(): LanguageDescription | undefined {
    const updatedLanguageDescription = {};
    let attributes = ['features'];

    if (this.initialLanguageDescription && Object.keys(this.initialLanguageDescription)) {
      attributes = attributes.concat(Object.keys(this.initialLanguageDescription));
    }

    if (this.languageDescription && Object.keys(this.languageDescription)) {
      attributes = attributes.concat(Object.keys(this.languageDescription));
    }

    attributes.map(attribute => {
      if (attribute === 'relevant') {
        return;
      }

      if (attribute === 'compoundHead') {
        if (this.languageDescription.compoundHead) {
          updatedLanguageDescription['compoundHead'] = this.languageDescription.compoundHead;
        }
        return;
      }

      if (attribute === 'features') {
        if (JSON.stringify(this.initialLanguageDescription.features) !== JSON.stringify(this.semanticFeaturesValuesList)) {
          updatedLanguageDescription['features'] = [];
          const semanticFeaturesValuesList = toJsonObject(this.semanticFeaturesValuesList) as FeatureValue[];
          semanticFeaturesValuesList.map(element => (element.type = this.semanticFeatureType));
          updatedLanguageDescription['features'] = semanticFeaturesValuesList;
        }
        return;
      }

      if (this.initialLanguageDescription[attribute] !== this.languageDescription[attribute]) {
        updatedLanguageDescription[attribute] = this.languageDescription[attribute];
      }
    });

    const existingAttributesIntoTheUpdatedLanguageDescription = Object.keys(updatedLanguageDescription);

    if (existingAttributesIntoTheUpdatedLanguageDescription.length) {
      return (updatedLanguageDescription as LanguageDescription);
    } else {
      return;
    }
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public updateWordsField($event: Language) {
    this.languageDescription.wordFallback1 = $event.id;
  }

  public updateProperNounsField($event: Language) {
    this.languageDescription.properNounFallback = $event.id;
  }

  public updatePhrasalPatternsField($event: Language) {
    this.languageDescription.phraseFallback = $event.id;
  }

  public updateEmojiFallback($event: Language) {
    this.languageDescription.emojiFallback = $event.id;
  }

  public updateThenField($event: Language) {
    this.languageDescription.wordFallback2 = $event.id;
  }

  public save(): void {
    const updatedLanguageDescription: LanguageDescription = this.getDifferenceBetweenInitialAndCurrentLanguageDescription();

    if (updatedLanguageDescription) {
      updatedLanguageDescription.requestId = this.initialLanguageDescription.requestId;
      this.languageService.updateLanguageDescription(updatedLanguageDescription)
        .then((result: LampUpdateResponse) => {
          if (result.success) {
            openChangesHaveBeenSavedDialog(this.dialog);
          } else {
            this.showErrorMessage(result.error);
          }
        });
    } else {
      openChangesHaveBeenSavedDialog(this.dialog);
    }
  }
}
