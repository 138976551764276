<div class="ti-dialog__wrapper">
    <mat-dialog-content>
      <div class="ti-table__wrapper">
        <mat-progress-bar *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-bar>
        <app-ranges-definitions isLookupMode="true" (selectItemEvent)="reactOnSelectItemEvent($event)"></app-ranges-definitions>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions class="ti-actions">
      <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
      <button mat-raised-button color="accent" [mat-dialog-close]="selectedRangeId">{{'COMMON.select' | translate}}</button>
    </mat-dialog-actions>
</div>

