import { Injectable } from '@angular/core';

import { EndpointUrls } from '../constants/endpoint';
import { LampUpdateResponse } from '../models/common';

import { ApiClientService } from './api-client.service';

@Injectable()
export class AdministrationService {
  constructor(private apiClientService: ApiClientService) {
  }

  public shutdown(): Promise<LampUpdateResponse> {
    let token: string = this.apiClientService.getToken();

    return this.apiClientService
      .get(`${EndpointUrls.shutdown}?t=${token}`)
      .then((res) => {
        return res;
      });
  }
}
