<div class="ti-form-wrapper ti-feature-localization-edit-form">
  <mat-progress-bar *ngIf="!featureDefinitionEditForm" color="accent" mode="indeterminate"></mat-progress-bar>
  <form class="ti-form" *ngIf="featureDefinitionEditForm" [formGroup]="featureDefinitionEditForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <h1>{{ (isNew ? 'feature-category.feature-definition-creation' : 'feature-category.feature-definition-edit') | translate}}</h1>
    <div class="ti-form__one-line-fields">
      <p class="ti-form__label">{{ 'COMMON.feature-list-id' | translate}}:
        <span class="ti--bold-text"> {{featureListId}}</span></p>
    </div>
    <div class="ti-form__one-line-fields" *ngIf="featureIndex">
      <p class="ti-form__label">{{ 'COMMON.feature-index' | translate}}:
        <span class="ti--bold-text"> {{featureIndex}}</span>
      </p>
    </div>
    <div class="ti-form__column-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="id" name="id"
               [(ngModel)]="featureDefinition.id"
               (keypress)="clearTheErrorMessage()"
               maxlength="8"
               appDisableControl
               [disableControl]="!isNew"
               [placeholder]="'COMMON.id' | translate">
      </mat-form-field>
    </div>


    <div class="ti-form__column-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="description" name="description"
               [(ngModel)]="featureDefinition.description"
               (keypress)="clearTheErrorMessage()"
               [placeholder]="'COMMON.description' | translate">
      </mat-form-field>
    </div>

    <!--antonym feature id-->
    <!--generation fallback feature id-->
    <div class="ti-form__one-line-fields">
      <mat-checkbox
        matInput formControlName="stopword" name="stopword"
        (click)="clearTheErrorMessage()"
        [(ngModel)]="featureDefinition.stopword">
        <span class="ti-language-settings__label">{{ 'feature-category.marks-stop-word' | translate}}
          <!--Marks stop word--></span>
      </mat-checkbox>
    </div>
    <div class="ti-form__one-line-fields" style="margin: 10px 0;">
      <mat-checkbox
        matInput formControlName="disableHeadExposal" name="disableHeadExposal"
        (click)="clearTheErrorMessage()"
        [(ngModel)]="featureDefinition.disableHeadExposal">
        <span class="ti-language-settings__label">{{ 'feature-category.marks-disable-expose-head' | translate}}</span>
      </mat-checkbox>
    </div>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="glossingTag" name="glossingTag"
               [(ngModel)]="featureDefinition.glossingTag"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'feature-category.glossing-tag' | translate}}">
      </mat-form-field>
    </div>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="emojiTag" name="emojiTag"
               [(ngModel)]="featureDefinition.emojiTag"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'feature-category.emoji-tag' | translate}}">
      </mat-form-field>
    </div>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="udTag" name="udTag"
               [(ngModel)]="featureDefinition.udTag"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'feature-category.ud-tag' | translate}}">
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="referenceUrl" name="referenceUrl"
               [(ngModel)]="featureDefinition.referenceUrl"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'COMMON.reference-url' | translate}}">
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="inflectionTablePriority" name="inflectionTablePriority"
               [(ngModel)]="featureDefinition.inflectionTablePriority"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'feature-category.inflection-table-priority' | translate}}">
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="triggerScoreClauseBonus" name="triggerScoreClauseBonus"
          [(ngModel)]="featureDefinition.triggerScoreClauseBonus" (keypress)="clearTheErrorMessage()"
          placeholder="{{ 'feature-category.trigger-score-clause-bonus' | translate}}">
        </mat-form-field>
        </div>
        <div class="ti-form__one-line-fields">
          <mat-form-field color="accent">
        <input matInput formControlName="reference" name="reference"
               [(ngModel)]="featureDefinition.reference"
               (keypress)="clearTheErrorMessage()"
               placeholder="{{ 'feature-category.reference' | translate}}">
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-checkbox
        matInput formControlName="requireFlag" name="requireFlag"
        (click)="clearTheErrorMessage()"
        [(ngModel)]="featureDefinition.requireFlag">
        <span class="ti-language-settings__label">{{ 'feature-category.require-flag' | translate}}</span>
      </mat-checkbox>
    </div>

    <!--read-only information-->
    <div *ngIf="!isNew">
      <h2>{{'feature-category.usage-statistics-all-languages' | translate}}</h2>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.lexemes-with-colon' | translate}} {{featureDefinition.globalUseCountLexemes || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.phrases-with-colon' | translate}} {{featureDefinition.globalUseCountPhrases || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span>{{'COMMON.families-with-colon' | translate}} {{featureDefinition.globalUseCountFamilies || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.affixes-with-colon' | translate}} {{featureDefinition.globalUseCountAffixes || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.clitics-with-colon' | translate}} {{featureDefinition.globalUseCountClitics || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.default-values-with-colon' | translate}} {{featureDefinition.globalUseCountAffixes || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.agreement-with-colon' | translate}} {{featureDefinition.globalUseCountClitics || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span>{{'COMMON.pragmatics-with-colon' | translate}} {{featureDefinition.globalUseCountPragmatics || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields ti-form__use">
        <span>{{'COMMON.used-in-languages-with-colon' | translate}}</span> <span class="ti-form__line-part">{{featureDefinition.usedInLanguages || 0}}</span>
      </p>
      <!--read-only information-->
      <h2>{{'feature-category.usage-statistics' | translate}} ({{currentLanguage}})</h2>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.lexemes-with-colon' | translate}} {{featureDefinition.languageUseCountLexemes || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.phrases-with-colon' | translate}} {{featureDefinition.languageUseCountPhrases || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.affixes-with-colon' | translate}} {{featureDefinition.languageUseCountAffixes || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.clitics-with-colon' | translate}} {{featureDefinition.languageUseCountClitics || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.default-values-with-colon' | translate}} {{featureDefinition.languageUseCountAffixes || 0}}</span>
      </p>
      <p class="ti-form__one-line-fields">
        <span class="ti-form__half-line-fields">{{'COMMON.agreement-with-colon' | translate}} {{featureDefinition.languageUseCountClitics || 0}}</span>
      </p>
    </div>

    <div class="ti-form__button-panel">
      <button mat-raised-button
              (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>
      <button
        mat-raised-button
        color="accent"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
