<div *ngIf="disableSelect" class="ti-text-data">
  {{currentFeatureOptionDescription}}
</div>

<mat-form-field color="accent" *ngIf="currentFeature && !disableSelect">
  <mat-select #featureValuesSelect
              matInput
              [required]="!isEmptyValueRelevant"
              [(ngModel)]="featureOptionId"
              (selectionChange)="chooseCurrentFeatureOption()"
              [matTooltip]="currentFeatureOptionDescription"
              [matTooltipDisabled]="!currentFeatureOptionId || !currentFeatureOptionDescription"
              matTooltipPosition="above"
  >
    <mat-option *ngIf="currentFeature.canCurrentFeatureHaveEmptyValue || isEmptyValueRelevant" [value]="null"></mat-option>
    <ng-template ngFor let-featureOption let-index="index" [ngForOf]="currentFeature.values">
      <mat-option [value]="featureOption.id"
                  [ngClass]="{'ti-universal-feature__hidden':
                  (!isFullFeatureValuesListShowed && !featureOption.relevant)}">
                  {{featureOption.description}} [{{featureOption.id}}]
      </mat-option>
    </ng-template>
    <mat-option
      *ngIf="!doesTheWholeFeatureListRelevant && (currentFeature.relevantPartially || !currentFeature.relevant)"
      class="ti-toggle-sign"
      (click)="toggleFeatureValuesList(); featureValuesSelect.open()">
      <mat-icon class="ti-toggle-sign--first" aria-hidden="true" *ngIf="!isFullFeatureValuesListShowed">expand_more
      </mat-icon>
      <mat-icon aria-hidden="true" *ngIf="!isFullFeatureValuesListShowed">expand_more</mat-icon>
      <mat-icon class="ti-toggle-sign--first" aria-hidden="true" *ngIf="isFullFeatureValuesListShowed">expand_less
      </mat-icon>
      <mat-icon aria-hidden="true" *ngIf="isFullFeatureValuesListShowed">expand_less</mat-icon>
    </mat-option>
  </mat-select>
</mat-form-field>
