import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {FormGroup, FormBuilder} from '@angular/forms';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {AdvancedCriteria, AdvancedLexeme} from '../../shared/models/lexicon';
import {FeatureValue, FeatureListDefinition} from '../../shared/models/feature';
import {InflectionTableService} from '../../shared/services/inflection-table.service';
import {FeaturesService} from '../../shared/services/features.service';
import {applicationConstants} from '../../shared/constants/application-constants';
import {AdvancedInflection, emplyAdvancedInflection} from '../../shared/models/inflections';
import {toJsonObject, collectObjectChanges} from '../../shared/helpers/object.helper';
import {LampUpdateResponse} from '../../shared/models/common';
import {LexiconService} from '../../shared/services/lexicon.service';
import {KnowledgeGraphService} from '../../shared/services/knowledge-graph.service';
import {FamilyDefinition} from '../../shared/models/knowledge-graph';
import {NumericKeyStringArrayValueStructure} from '../../shared/models/key-value-structure';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../shared/services/session.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-inflection-edit-dialog',
  templateUrl: 'inflection-edit-dialog.component.html',
  styleUrls: ['inflection-edit-dialog.component.less']
})
export class InflectionEditDialogComponent extends Translated implements OnInit {
  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();

  public isEdit: boolean;
  public lexeme: string;
  public familyId: number;

  public grammarFeatureList: FeatureListDefinition[];
  public styleFeatureList: FeatureListDefinition[];
  public semanticFeatureList: FeatureListDefinition[];

  public inflection: AdvancedInflection;
  public initialInflection: AdvancedInflection;
  public runtimeGrammar: FeatureValue[] = [];
  public runtimeStyle: FeatureValue[] = [];
  public runtimeSemantics: FeatureValue[] = [];

  public form: FormGroup;
  public refresh: boolean = false;
  public doesTheWholeFeatureListRelevant: boolean = true;

  //private relevant: NumericKeyStringArrayValueStructure[];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData,
              private formBuilder: FormBuilder,
              private inflectionTableService: InflectionTableService,
              private featuresService: FeaturesService,
              private dialog: MatDialog,
              private lexiconService: LexiconService,
              protected localStorageHelper: LocalStorageHelper,
              protected translateService: TranslateService,
              protected sessionService: SessionService,
              private knowledgeGraphService: KnowledgeGraphService) {
    super(translateService, localStorageHelper, sessionService);
    this.isEdit = this.dialogData.isEdit;
    this.lexeme = this.dialogData.lexeme;
    this.familyId = this.dialogData.familyId;
  }

  initThisSubtypeFromSession(): void {
    if (this.dialogData.line && this.isEdit) {
      this.inflectionTableService.getInflectionById({
        inflectionFeatures: this.dialogData.line.features,
        text: this.dialogData.line.text,
        inflectionId: this.dialogData.line.inflectionId,
        lexemeId: this.dialogData.lexemeId,
        familyId: this.dialogData.familyId
      })
      .then((inflection: AdvancedInflection) => {
        this.defineNotEmptyArrays(inflection);
        if (this.dialogData.line.features) {
          inflection.inflectionSemantic = this.dialogData.line.features.filter(f => f.type == 'Semantics');
        }
        this.load(inflection);
      });
    } else {
      const tempInflection = toJsonObject(emplyAdvancedInflection) as AdvancedInflection;
      this.load(tempInflection);
    }
  }

 
  ngOnInit(): void {
    this.initFromSession();
  }

  private load(inflection: AdvancedInflection): Promise<void> {
    return this.lexiconService.getLexeme(this.dialogData.lexemeId)
      .then((lexeme: AdvancedLexeme) => {
        //this.relevant = lexeme.relevant; // Vadim 18/11/18: this was overwriting the inflection's own relevant features
        inflection.lexemeGrammar = lexeme.grammar;
        inflection.lexemeStyle = lexeme.style;
        inflection.lexemeSemantic = lexeme.semantics;
        if (this.familyId) {
          return this.lexiconService.getLexemeFamilyConnection(this.dialogData.lexemeId, this.familyId)
            .then(data => {
              inflection.lexemeFamilyStyle = data.style;
              return this.knowledgeGraphService.getFamilyById(this.familyId.toString())
                .then((family: FamilyDefinition) => {
                  inflection.familyGrammar = family.grammar;
                  inflection.familySemantics = family.semanticFeatures;
                  return this.initialize(inflection);
                });
            });
        } else {
          this.initialize(inflection);
        }
      });
  }

  private defineNotEmptyArrays(inflection: AdvancedInflection): void {
    if (!inflection.inflectionGrammar) {
      inflection.inflectionGrammar = [];
    }
    if (!inflection.inflectionStyle) {
      inflection.inflectionStyle = [];
    }
    if (!inflection.inflectionSemantic) {
      inflection.inflectionSemantic = [];
    }
  }

  private initialize(inflection: AdvancedInflection): Promise<void> {
    this.inflection = toJsonObject(inflection) as AdvancedInflection;
    this.initialInflection = toJsonObject(inflection) as AdvancedInflection;
    this.inflection.lexemeId = this.dialogData.lexemeId;
    if (this.dialogData.inflectionId)
      this.inflection.inflectionId = this.dialogData.inflectionId;
    this.initialInflection.lexemeId = this.inflection.lexemeId;
    this.initialInflection.inflectionId = this.inflection.inflectionId;
    return this.getFeatures().then(() => {
      this.initForm();
      this.getRuntimeFeatures();
    });
  }

  private getFeatures(): Promise<FeatureListDefinition[]> {
    this.doesTheWholeFeatureListRelevant = !Boolean(this.inflection.relevant);
    return this.featuresService.createFilteredRelevantFeatureList(this.inflection.relevant, applicationConstants.grammarFeatureType)
      .then((grammarFeatureList: FeatureListDefinition[]) => this.grammarFeatureList = grammarFeatureList).then(() => {
        return this.featuresService.createFilteredRelevantFeatureList(this.inflection.relevant, applicationConstants.styleFeatureType)
          .then((styleFeatureList: FeatureListDefinition[]) => this.styleFeatureList = styleFeatureList).then(() => {
            return this.featuresService.createFilteredRelevantFeatureList(this.inflection.relevant, applicationConstants.semanticFeatureType)
              .then((semanticFeatures: FeatureListDefinition[]) => this.semanticFeatureList = semanticFeatures);
          });
      });    
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      inflectedForm: [this.inflection.text],
      isLemma: [this.inflection.isLemma],
      note: [this.inflection.note],
      misspelling: [this.inflection.misspelling],
      additionalForms: [this.inflection.additionalForms]
    });
  }

  public reactOnSelectedAdvancedCriteria(advancedCriteria: AdvancedCriteria) {
    if (advancedCriteria) {
      this.inflection.advancedCriteriaId = advancedCriteria.id;
      this.inflection.advancedCriteriaDescription = advancedCriteria.description;
    }
  }

  private getRuntimeFeatures(): void {
    this.inflectionTableService.runtimeInflectionFeatures({
      inflectionFeatures: this.inflection.inflectionGrammar.concat(this.inflection.inflectionStyle).concat(this.inflection.inflectionSemantic),
      lexemeId: this.dialogData.lexemeId,
      familyId: this.dialogData.familyId
    })
      .then(runtimeFeatures => {
        this.runtimeGrammar = runtimeFeatures.filter(feature => feature.type === undefined);
        this.runtimeStyle = runtimeFeatures.filter(feature => feature.type === applicationConstants.styleFeatureType);
        this.runtimeSemantics = runtimeFeatures.filter(feature => feature.type === applicationConstants.semanticFeature.type);
        this.refresh = false;
      });
  }

  public refreshRuntimeFeatures(): void {
    this.refresh = true;
    this.getRuntimeFeatures();
  }

  public save(): void {
    if (!this.isEdit)
      this.inflection.inflectionId = undefined;

    if (this.inflection.inflectionStyle)
      this.inflection.inflectionStyle.forEach(element => (element.type = applicationConstants.styleFeatureType));


    if (this.inflection.inflectionSemantic)
      this.inflection.inflectionSemantic.forEach(element => (element.type = applicationConstants.semanticFeatureType));


    if (this.inflection.inflectionFeatures == null)
      this.inflection.inflectionFeatures = undefined;
    if (this.inflection.inflectionGrammar == null)
      this.inflection.inflectionGrammar = undefined;
    if (this.inflection.inflectionStyle == null)
      this.inflection.inflectionStyle = undefined;
    if (this.inflection.inflectionSemantic == null)
      this.inflection.inflectionSemantic = undefined;
    this.inflectionTableService.updateInflection(this.inflection)
          .then((message: LampUpdateResponse) => this.handleUpdateResponse(message));
  }

  private handleUpdateResponse(successfulResponseMessage: LampUpdateResponse): void {
    if (successfulResponseMessage.success) {
      this.dialog.getDialogById(applicationConstants.dialogs.inflectionEditDialog).close(true);
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  public defineTypeOfStypeFeatures(changes: AdvancedInflection): void {
    if (changes && changes.inflectionStyle) {
      changes.inflectionStyle.forEach(inflectionStyle => inflectionStyle.type = applicationConstants.styleFeatureType);
    }
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  protected showErrorMessage(error?: string) {
    this.errorMessageSubj.next(error);
  }

  public closeDialogs(): void {
    this.dialog.getDialogById(applicationConstants.dialogs.inflectionTableDialog).close();
    this.dialog.getDialogById(applicationConstants.dialogs.inflectionEditDialog).close();
  }

  public reactOnUpdateAdvancedCriteria(): void {
    this.lexiconService.getAdvancedCriteriaById(this.inflection.advancedCriteriaId)
      .then((advancedCriteria: AdvancedCriteria) => {
        this.inflection.advancedCriteriaDescription = advancedCriteria.description;
      });
  }

}
