<h1>{{ (isLookupMode ? 'range.range-selection' : 'headers.range-list') | translate }}</h1>
<div class="ti-menu">
  <div class="ti-menu__data-wrapper">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <mat-accordion *ngIf="rangeList">
      <mat-expansion-panel
        *ngFor="let rangeListElement of rangeList; let rangeListElementIndex=index"
        appScrollToChangedPreviouslyElement
        [expanded]="(rangeListElement.Key === visitedTable)">
        <mat-expansion-panel-header
          [ngClass]="(rangeListElement.Key === visitedTable) ? changedPreviouslySelector: ''">
          <mat-panel-title>{{rangeListElement.Key}}</mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container matExpansionPanelContent>
          <ng-template ngFor let-rangeListByLanguage let-index="index" [ngForOf]="rangeListElement.Value">
            <ng-template ngFor let-rangeListByLanguageValue let-index="index" [ngForOf]="rangeListByLanguage.Value">
              <div class="ti-menu__description"
                   [ngClass]="((rangeListElement.Key === visitedTable) &&
                    (rangeListByLanguageValue.Key === visitedRangeId)) ? changedPreviouslySelector: ''">
                <p>{{rangeListByLanguageValue.Value.description}} [<span
                    style="user-select: all;">{{rangeListByLanguageValue.Key}}</span>]</p>
                <p>{{rangeListByLanguageValue.Value.example}}</p>
                <div class="ti-form__button-panel">
                  <mat-radio-button *ngIf="isLookupMode" (click)="reactOnSelectItemEvent(rangeListByLanguageValue.Key);" [value]="rangeListByLanguageValue.Key"></mat-radio-button>

                  <button mat-mini-fab color="primary" *ngIf="!isLookupMode"
                          (click)="navigateToEditPage(rangeListElement.Key, rangeListByLanguageValue.Key)">
                    <mat-icon aria-hidden="true">edit</mat-icon>
                  </button>
                  <button *ngIf="!isLookupMode && doesUserHaveAccessToAddAndDelete" mat-mini-fab color="primary"
                          appOpenConfirmDialog
                          (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, rangeListByLanguageValue.Key)">
                    <mat-icon aria-hidden="true">delete</mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
          </ng-template>
          <div class="ti-menu__description-wrapper" *ngIf="!isLookupMode && doesUserHaveAccessToAddAndDelete">
            <div class="ti-form__button-panel">
              <button mat-mini-fab color="primary"
                      (click)="navigateToCreatePage(rangeListElement.Key)">
                <mat-icon aria-hidden="true">add</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar *ngIf="!rangeList" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
