<div class="ti-phonemes-edit__edit-form-wrapper" *ngIf="phonemeEditForm">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <h1>{{ (isTheNewSign ? 'phoneme.phoneme-creation' : 'phoneme.phoneme-edit') | translate}}</h1>

  <form class="ti-phonemes-edit__edit-form" [formGroup]="phonemeEditForm">
    <mat-form-field color="accent">
      <input
        matInput
        maxlength="15"
        class="ti-phonemes-edit__phonemes-text"
        placeholder="{{'phoneme.phoneme' | translate}}"
        [(ngModel)]="phoneme"
        required
        style="line-height: 150%;"
        formControlName="phoneme"/>
    </mat-form-field>

    <mat-checkbox matInput formControlName="targetOnly" [(ngModel)]="targetOnly">
      {{'phoneme.target-generation-only' | translate}}
    </mat-checkbox>

    <mat-form-field>
      <input type="text" matInput
             placeholder="{{ 'phoneme.fall-back-to-phoneme' | translate}}"
             class="ti-phonemes-edit__phonemes-text"
             appOpenFallBackEditDialog (fallBackPhonemeAddedEvent)="saveFallBackPhoneme($event)"
             (keypress)="$event.preventDefault()"
             [(ngModel)]="fallBackTo" formControlName="fallBackTo"/>
    </mat-form-field>

    <p class="ti-phonemes-edit__label">{{'aliases.aliases' | translate}}:</p>
    <a *ngIf="aliasList && !isTheNewSign" class="ti-link-underline-text-decoration ti-phonemes-edit__phonemes-text"
       appOpenAliasEditDialog [phonemeId]="phonemeId"
       (beforeDialogClosedEvent)="updateLocalDataForTheCurrentPhoneme()" >{{aliasList}}</a>
    <button *ngIf="!aliasList"
            class="ti-button--width-auto"
            [disabled]="isTheNewSign"
            matTooltip="{{'aliases.to-create-an-alias-save-the-phoneme-first' | translate}}"
            [matTooltipDisabled]="!isTheNewSign"
            mat-button color="accent"
            appOpenAliasEditDialog [phonemeId]="phonemeId"
            (beforeDialogClosedEvent)="updateLocalDataForTheCurrentPhoneme()">
      {{'COMMON.create' | translate}}
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>

    <p class="ti-phonemes-edit__label">{{'COMMON.position' | translate}}:</p>
    <mat-radio-group [(ngModel)]="position"
                     formControlName="position">
      <mat-radio-button class="ti-phonemes-edit__radio-button" *ngFor="let option of positionOptions"
                        value="{{option.key}}">{{option.value}}
        <app-phonemes-position [position]="option.key"></app-phonemes-position>
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field>
      <textarea matInput formControlName="note" [(ngModel)]="note"
                placeholder="{{ 'COMMON.note' | translate}}"></textarea>
    </mat-form-field>


    <div class="ti-phonemes-edit--button-panel">
      <button mat-raised-button (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>

      <button mat-raised-button color="accent" [disabled]="!phoneme" (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
