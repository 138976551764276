<h1>{{ 'headers.linguist-activities' | translate }}</h1>
<div class="ti-form__one-line-fields ti-form__one-line-fields--error">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
</div>
<mat-accordion multi="true">
  <mat-expansion-panel *ngFor="let user of users" (afterExpand)="expandUser(user.user.dbUser)">
    <mat-expansion-panel-header>
      <mat-panel-title [matTooltip]="user.status">
        &nbsp;{{user.user.dbUser}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container matExpansionPanelContent>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title [matTooltip]="user.status">
            &nbsp;Activity
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container matExpansionPanelContent>
          <mat-tab-group [disableRipple]="true">
            <mat-tab *ngFor="let chart of user.activityCharts" label="{{chart.name}}">
              <div echarts [options]="chart.chartOptions" [loading]="loading()" theme="macarons"></div>
            </mat-tab>
            </mat-tab-group>
            </ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel *ngFor="let language of user.languageList" (afterExpand)="getStats(language.id, 'metrics')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  &nbsp;{{language.englishName}} - {{ 'LINGUIST-ACTIVITIES.status' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <mat-tab-group (selectedTabChange)="onParsingTabChanged(language.id, $event)">
                  <mat-tab label="{{'TEST.abuse' | translate}}">
                    <ng-template matTabContent>
                      <!-- <mat-spinner *ngIf="isLoading.metrics" [diameter]="30" style="margin: 10px auto;"></mat-spinner> -->
                      <div echarts [options]="abuseOptions[language.id.toString()]"
                        [loading]="isLoading[language.id.toString()] && isLoading[language.id.toString()].metrics"
                        (chartInit)="abuseChart[language.id.toString()] = $event" theme="macarons"></div>
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="{{'TEST.sentiment' | translate}}">
                    <div echarts [options]="sentimentOptions[language.id.toString()]"
                      [loading]="isLoading[language.id.toString()] && isLoading[language.id.toString()].metrics" theme="macarons"
                      (chartInit)="sentimentChart[language.id.toString()] = $event"></div>
                  </mat-tab>
                  <mat-tab label="{{'TEST.entities' | translate}}">
                    <div echarts [options]="entitiesOptions[language.id.toString()]"
                      [loading]="isLoading[language.id.toString()] && isLoading[language.id.toString()].metrics" theme="macarons"
                      (chartInit)="entityChart[language.id.toString()] = $event"></div>
                  </mat-tab>
                  <mat-tab label="{{'TEST.unknown-share' | translate}}" aria-label="unknownStats">
                    <div echarts [options]="unknownOptions[language.id.toString()]"
                      [loading]="isLoading[language.id.toString()] && isLoading[language.id.toString()].unknownStats"
                      (chartInit)="unknownChart[language.id.toString()] = $event" theme="macarons"></div>
                  </mat-tab>
                  <mat-tab label="{{'TEST.phrases' | translate}}" aria-label="phraseTestTotals">
                    <mat-progress-bar mode="indeterminate"
                      *ngIf="isLoading[language.id.toString()] && isLoading[language.id.toString()].phraseTestTotals"></mat-progress-bar>
                    <div style="text-align: center;">
                      <h3>{{ 'LINGUIST-ACTIVITIES.failed-phrase-tests-ratio' | translate }}</h3>
                      <div>
                        <span
                          [style.color]="failedPhraseTestCount[language.id.toString()] / totalPhraseTests[language.id.toString()] > 0.2 ? 'red': 'black'">{{failedPhraseTestCount[language.id.toString()]}}</span>
                        /
                        {{totalPhraseTests[language.id.toString()]}}
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </ng-template>
              </mat-expansion-panel>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>