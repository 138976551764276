<ul class="ti-header-menu" *ngIf="!isMobileView && enabled()">
  <li class="ti-header-menu__item">
    <span class="ti-header-menu__item-language">
      <span translate="header-menu.language-model">Language model</span>:
      <a
        routerLink="/statistics"
        class="ti-header-link ti-header-menu__link--underlined">{{languageModel}}</a>
        <mat-icon *ngIf="canEditCurrentLanguage" class="ti-header-menu__item-mode" aria-hidden="true" [matTooltip]="'header-menu.edit-mode' | translate">edit</mat-icon>
        <mat-icon *ngIf="!canEditCurrentLanguage" class="ti-header-menu__item-mode" aria-hidden="true" [matTooltip]="'header-menu.view-only-mode' | translate">visibility</mat-icon>
    </span>
  </li>
  <li class="ti-header-menu__item" aria-hidden="true">
    <mat-icon class="ti-header-menu__item-divider" aria-hidden="true">remove</mat-icon>
  </li>
  <li class="ti-header-menu__item">
    <span>
      <span translate="COMMON.user">User</span>:
      <span><a routerLink="/profile"
               class="ti-header-link ti-header-menu__link--underlined">{{username}}</a></span>
      <span *ngIf="!isGuest">&nbsp;(<a routerLink="/edits-list"
         class="ti-header-menu__link--underlined
          {{(statisticLevel ? ((statisticLevel === 2) ?  'ti-green-link' : 'ti-yellow-link' ) : 'ti-red-link')}}">{{edits}}
        <span translate="header-menu.edits-today"></span></a>
      <span>)</span>
      </span>
    </span>
  </li>
  <li class="ti-header-menu__item" aria-hidden="true">
    <mat-icon class="ti-header-menu__item-divider" aria-hidden="true">remove</mat-icon>
  </li>
  <mat-icon aria-hidden="true" style="align-self: center; cursor: pointer;" matTooltip="{{'STATUS.edit-my-note' | translate}}"
    (click)="openStatusDialog()">edit_calendar</mat-icon>
  <li class="ti-header-menu__item" aria-hidden="true">
    <mat-icon class="ti-header-menu__item-divider" aria-hidden="true">remove</mat-icon>
  </li>
  <li class="ti-header-menu__item">
    <button class="ti-header-menu__link-button" color="primary" (click)="logout()" translate="">{{'login.sign-out' |
      translate}}
    </button>
  </li>
</ul>
<mat-toolbar color="primary" *ngIf="isMobileView && enabled()">
  <mat-toolbar-row><span translate="header-menu.language-model">Language model</span>:
    <a
      (click)="toggleMobileMenu()"
      routerLink="/statistics"
      class="ti-header-link ti-header-menu__link--underlined">{{languageModel}}</a></mat-toolbar-row>
  <mat-toolbar-row>
          <span><a routerLink="/profile"
                   class="ti-header-link ti-header-menu__link--underlined">{{username}}</a></span>
    <span *ngIf="!isGuest">&nbsp;(
      <a routerLink="/edits-list"
         (click)="toggleMobileMenu()"
         class="ti-header-menu__link--underlined
          {{(statisticLevel ? ((statisticLevel === 2) ?  'ti-green-link' : 'ti-yellow-link' ) : 'ti-red-link')}}">{{edits}}<span translate="header-menu.edits-today"></span></a>
          )
      </span>&nbsp;
      <button mat-mini-fab color="primary">
        <mat-icon aria-hidden="true">add</mat-icon>
      </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <button class="ti-header-menu__link-button" color="primary" (click)="logout()" translate="">{{'login.sign-out' |
      translate}}
    </button>
  </mat-toolbar-row>
</mat-toolbar>
