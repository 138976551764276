import { Injectable } from "@angular/core";
import { EndpointUrls } from "../constants/endpoint";
import { ApiClientService } from "./api-client.service";

@Injectable()
export class AboutService {
  constructor(private apiClientService: ApiClientService) { }


  public getVersion(): Promise<string> {
    return this.apiClientService
      .get(`${EndpointUrls.about.version}`)
      .then((result) => {
        return result;
      });
  }

  public getLibraryInfo(): Promise<any> {
    return this.apiClientService
      .get(`${EndpointUrls.about.libraryInfo}`)
      .then((result) => {
        return result;
      });
  }

  public getRuntimeVersion(): Promise<string> {
    return this.apiClientService
      .get(`${EndpointUrls.about.runtimeVersion}`)
      .then((result) => {
        return result;
      });
  }

  public getRuntimeLibraryInfo(): Promise<any> {
    return this.apiClientService
      .get(`${EndpointUrls.about.runtimeLibraryInfo}`)
      .then((result) => {
        return result;
      });
  }

}