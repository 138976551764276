import {Component, Input, OnInit, Output, EventEmitter, AfterViewInit} from '@angular/core';

import {LampUpdateResponse} from '../../shared/models/common';
import { Lexeme, Root } from '../../shared/models/lexicon';
import {LexiconService} from '../../shared/services/lexicon.service';
import {SessionService} from '../../shared/services/session.service';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { Family } from '../../shared/models/knowledge-graph';

@Component({
  selector: 'app-lexicon-element',
  templateUrl: './lexicon-element.component.html',
  styleUrls: ['./lexicon-element.component.less']
})
export class LexiconElementComponent extends Translated implements OnInit, AfterViewInit {
  @Input() public lexeme: Lexeme;
  @Output() private updateLexemeEvent: EventEmitter<number> = new EventEmitter();
  @Output() private reloadFilterEvent: EventEmitter<void> = new EventEmitter();
  @Output() private deleteLexemeEvent: EventEmitter<number> = new EventEmitter();
  @Output() private deleteFamilyEvent: EventEmitter<{lexemeId: number; familyId: number}> = new EventEmitter();
  @Output() private sendSuccessfulResponseMessageEvent: EventEmitter<LampUpdateResponse> = new EventEmitter();

  public showSpinner: boolean;
  public doesUserHaveAccessToEdit: boolean;
  public isCurrentLangIsRefLang = false;

  constructor(private lexiconService: LexiconService,
    protected localStorageHelper: LocalStorageHelper,
          translateService: TranslateService,
    public sessionService: SessionService) {
    super(translateService, localStorageHelper, sessionService);
  }
  ngAfterViewInit(): void {
    if(this.lexeme && this.lexeme.families && this.lexeme.families.length > 0) {
      this.lexeme.linkedFamilyIds = this.lexeme.families.map(x => x.id).join(',');
    }
  }

  initThisSubtypeFromSession(): void {
    this.doesUserHaveAccessToEdit = this.sessionService.canEditLexicon();
    this.checkCurrentAndRefLang();
  }

  private checkCurrentAndRefLang() {
    this.sessionService.currentSessionSource.subscribe(e => {
      this.isCurrentLangIsRefLang = e && e.targetLanguage && e.referenceLanguage && e.targetLanguage.id == e.referenceLanguage.id ? true : false;
    });
  }

  ngOnInit() {
    this.initFromSession();
  }

  public deleteFamilyAfterUserConfirmation(isChecked, familyId): void {
    if (isChecked) {
      this.lexiconService.deleteLexemeFamilyConnection(this.lexeme.id, familyId)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        if (successfulResponseMessage) {
          this.sendSuccessfulResponseMessageEvent.emit(successfulResponseMessage);
          if (successfulResponseMessage.success) {
            this.deleteFamilyEvent.emit({ lexemeId: this.lexeme.id, familyId: familyId });
          }
        }
      });
    }
  }

  public sendMessageThatUpdateRequiredToParent(successfulResponseMessage: LampUpdateResponse): void {
    if(!successfulResponseMessage || successfulResponseMessage.success){
      this.reloadFilterEvent.emit();
    }else{
      this.sendSuccessfulResponseMessageEvent.emit(successfulResponseMessage);
    }
  }

  public deleteLexemeAfterUserConfirmation(confirmation: boolean, id: number): void {
    if (confirmation) {
      this.showSpinner = true;
      this.lexiconService.deleteLexeme(id)
      .then((message: LampUpdateResponse) => {
        if (message) {
          this.sendSuccessfulResponseMessageEvent.emit(message);
          if (message.success) {
            this.deleteLexemeEvent.emit(id);
          }
        }
        this.showSpinner = false;
      });
    }
  }
  
  public createRoot(lexemeId: number, familyId: number): void {
    this.showSpinner = true;
    this.lexiconService.getLexeme(lexemeId).then((advancedLexeme) => {
      const newRoot: Root = {
        familyId: familyId,
        root: advancedLexeme.stem,
      };
      this.lexiconService
        .createRoot(newRoot)
        .then((message: LampUpdateResponse) => {
          if (message.success && message.id) {
            this.lexiconService
              .updateLexeme({
                id: lexemeId,
                requestId: advancedLexeme.requestId,
                root: message.id,
              })
              .then((message: LampUpdateResponse) => {
                if (message.success) {
                  this.updateLexemeEvent.emit(lexemeId);
                } else {
                  this.showErrorMessage(message.error);
                  this.showSpinner = false;
                }
              });
          } else {
            this.showErrorMessage(message.error);
            this.showSpinner = false;
          }
        });
    });
  }

  public approveLexemeFamily(lexemeId: number, family: Family): void {
    this.lexiconService.approveLexemeFamily(lexemeId, family.id)
    .then((message: LampUpdateResponse) => {
      if (message.success) {
        family.LFUnverified = 'verified';
      } else {
        this.showErrorMessage(message.error);
      }
    });
  }

  public approveLexeme(lexeme: Lexeme): void {
    this.lexiconService.approveLexeme(lexeme.id)
      .then((message: LampUpdateResponse) => {
        if (message.success) {
          lexeme.unverified = 'verified';
        } else {
          this.showErrorMessage(message.error);
        }
      });
  }

  getUnverifiedEmoji(unverified: 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle') {
    switch(unverified) {
      case 'autogenerated':
      case 'autoGrammar':
      case 'autoStyle':
        return '💡';
      case 'failedAutoCheck':
        return '❗';
      default:
        return '❓';
    }
  }

  public validateLexemeLinks(lexemeId: number) {
    this.clearTheErrorMessage();

    this.showSpinner = true;
    this.lexiconService.validateLexemeLinks(lexemeId)
      .then((message: LampUpdateResponse) => {
        this.showSpinner = false;

        if (message.error) {
          this.showErrorMessage(message.error);
        } else {
          this.updateLexemeEvent.emit(lexemeId);
        }
      })
      .catch(error => {
        this.showErrorMessage(error.message || 'Failed to validate');
        this.showSpinner = false;
      })
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }
}
