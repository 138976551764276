import {Injectable} from '@angular/core';

import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import { Interfix } from '../models/interfixes';
import { LampUpdateResponse } from '../models/common';

@Injectable()
export class InterfixesService {

  constructor(private apiClientService: ApiClientService) {}

  public getInterfixList(): Promise<any> {
    return this.apiClientService.get(EndpointUrls.interfixes.interfixList);
  }

  public getInterfixById(interfixId: number): Promise<Interfix> {
    return this.apiClientService.get(`${EndpointUrls.interfixes.interfix}?id=${interfixId}`);
  }

  public createInterfix(interfix: Interfix): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.interfixes.interfix, interfix);
  }

  public updateInterfix(interfix: Interfix): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.interfixes.interfix, interfix);
  }

  public deleteInterfix(interfixId: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.interfixes.interfix}?id=${interfixId}`);
  }

}
