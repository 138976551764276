import {Component, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {Interfix, emptyInterfix} from '../../../shared/models/interfixes';
import {toJsonObject} from '../../../shared/helpers/object.helper';
import {InterfixesService} from '../../../shared/services/interfixes.service';
import {LampUpdateResponse} from '../../../shared/models/common';
import {RouterHelper} from '../../../shared/helpers/router.helper';
import {applicationModulesRoutePaths} from "../../../shared/constants/application-constants";

@Component({
  selector: 'app-interfix-edit',
  templateUrl: './interfix-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterfixEditComponent {
  public interfix: Interfix;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public showProgressBar: boolean;

  constructor(private interfixesService: InterfixesService,
              private routerHelper: RouterHelper,
              private changeDetectionRef: ChangeDetectorRef) {
  }


  public getInterfixById(interfixId: number): Promise<void> {
    if (interfixId) {
      return this.interfixesService.getInterfixById(interfixId)
        .then((interfix: Interfix) => {
          this.interfix = toJsonObject(interfix) as Interfix;
          this.changeDetectionRef.markForCheck();
        });
    } else {
      return Promise.resolve().then(() => {
        this.interfix = toJsonObject(emptyInterfix) as Interfix;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  public save(changes): void {
    this.showProgressBar = true;
    changes = (changes as Interfix);
    if (changes.id) {
      this.interfixesService.getInterfixById(changes.id)
        .then((interfix: Interfix) => {
          this.interfixesService.updateInterfix(changes)
            .then((message: LampUpdateResponse) => this.reactOnSuccessfullResponseMessage(message));
        });
    } else {
      if (!changes.text)
        changes.text = '';
      this.interfixesService.createInterfix(changes)
        .then((message: LampUpdateResponse) => this.reactOnSuccessfullResponseMessage(message));
    }
  }


  public reactOnSuccessfullResponseMessage(message: LampUpdateResponse): void {
    this.showProgressBar = false;
    if (message.success) {
      this.back();
    } else {
      this.errorMessageBehaviorSubject.next(message.error);
    }
  }

  private back(): void {
    const url = applicationModulesRoutePaths.morphology.morphology.path + applicationModulesRoutePaths.morphology.interfixes.path;
    this.routerHelper.navigateToPage(url);
  }

}
