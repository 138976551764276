import {Component, OnInit, OnDestroy} from '@angular/core';

import {Subscription} from 'rxjs/Subscription';

import {TranslateService} from '@ngx-translate/core';

import {SessionService} from './shared/services/session.service';
import {RouterHelper} from './shared/helpers/router.helper';

import {applicationConstants} from './shared/constants/application-constants';

import {LanguageService} from './shared/services/language.service';
import {LinguistService} from './shared/services/linguist.service';
import {TitleService} from './shared/services/title.service';

import {Language} from './shared/models/language';
import {RouterAccompaniment} from './shared/services/router-accompaniment.service';
import {FeaturesService} from './shared/services/features.service';
import {MatDialog} from '@angular/material';
import { ApiClientService } from './shared/services/api-client.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit, OnDestroy {
  private currentLanguageIsoCode: string;

  constructor(private translateService: TranslateService,
              private sessionService: SessionService,
              private routerHelper: RouterHelper,
              private languageService: LanguageService,
              private linguistService: LinguistService,
              private titleService: TitleService,
              private dialogRef: MatDialog,
              private apis: ApiClientService,
              private routerAccompaniment: RouterAccompaniment,
              private featuresService: FeaturesService) {
    this.initializeTranslateService();
  }

  private redirectToLogin() {
    if (window.location.pathname === '/login')
      return;
    this.sessionService.logout();
    this.dialogRef.closeAll();
    this.routerHelper.navigateToLoginPage();
}

  ngOnInit(): void {
    if (this.sessionService && this.apis.getToken()) {
      // we have a token. Let's try to use it
      let self = this;
      this.sessionService.refresh().then((success: boolean) => 
        {
          if (!success) {
            console.error("Invalid token. Redirecting to login...");
            self.redirectToLogin();
          }
          else {
            self.collectClientInformation();
          }
        }).catch((err) => {
          console.error("Error using token. Redirecting to login..."); 
          self.redirectToLogin(); 
        });
      return;
    }
    if (!this.sessionService || !this.sessionService.isAuthenticated()) {
      this.redirectToLogin();
    }
  }

  private collectClientInformation(): void {
    this.currentLanguageIsoCode = this.sessionService.session.preferredLanguage1.ISOCode;
    this.languageService.getLanguagesList().then((languagesList) => {
      const availableLanguages = languagesList.map((language: Language) => language.ISOCode);
      this.translateService.addLangs(availableLanguages);
      this.translateService.use(this.currentLanguageIsoCode);
      });

  }

  private initializeTranslateService(): void {
    this.translateService.addLangs(applicationConstants.languages.available_languages);
    this.translateService.setDefaultLang(applicationConstants.languages.default_language);

    this.translateService.use(this.currentLanguageIsoCode || applicationConstants.languages.default_language);
  }

  ngOnDestroy(): void {
  }
}
