<div class="ti-form-wrapper">
  <h1>{{ (isHypothesis ? ('headers.extra-hypothesis-list') : ('headers.interfixes-list')) | translate }}</h1>
  <div class="ti-progress-detectors" *ngIf="!list || showProgressBar">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <mat-table *ngIf="list" [dataSource]="list">
    <ng-container matColumnDef="id">
      <mat-header-cell class="ti-form__id" *matHeaderCellDef> {{ 'COMMON.id' | translate }}</mat-header-cell>
      <mat-cell class="ti-form__id" *matCellDef="let element"> 
        <a [href]="'#i' + element.id">{{ element.id }}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="1" *ngIf="!isHypothesis">
      <mat-header-cell class="ti-form__text" *matHeaderCellDef>
        {{ 'COMMON.text' | translate }}
      </mat-header-cell>
      <mat-cell class="ti-form__text" *matCellDef="let element"> {{ element.text }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="1" *ngIf="isHypothesis">
      <mat-header-cell *matHeaderCellDef>
        {{ 'inflection-table.assigned' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ (element && element.assignedDescriptions) ? element.assignedDescriptions:'' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="2">
      <mat-header-cell *matHeaderCellDef> {{ 'inflection-table.required' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.requiredDescriptions }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="3">
      <mat-header-cell *matHeaderCellDef> {{ 'COMMON.incompatible' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.incompatibleDescriptions }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="buttons">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="primary"
          [routerLink]="routerLinkPart + '/edit/' + element.id"
          [queryParams]="{editedId: element.id}"
        >
          <mat-icon aria-hidden="true">edit</mat-icon>
        </button>
        <button mat-mini-fab color="primary"
                appOpenConfirmDialog
                (doesUserConfirmEvent)="deleteElementAfterUserConfirmation($event, element.id)"
                *ngIf="canEdit()"
        >
          <mat-icon aria-hidden="true">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             appScrollToEvent
             appScrollToChangedPreviouslyElement
             [shouldBeEmittedScrollToEventEvent]="(visitedPunctuationIndex === row.id)"
             [ngClass]="(visitedPunctuationIndex === row.id) ? changedPreviouslySelector: ''"></mat-row>
  </mat-table>
  <div class="ti-add-button">
    <button
      mat-mini-fab
      color="primary"
      [routerLink]="routerLinkPart + '/create'"
      *ngIf="canEdit()"
    >
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </div>
</div>
