import {Component, OnInit, OnDestroy, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';

import {Subscription} from 'rxjs/Subscription';

import {PunctuationService} from '../../shared/services/punctuation.service';
import {SessionService} from '../../shared/services/session.service';

import {PunctuationSign} from '../../shared/models/punctuation-sign';
import {commonTabSettings} from '../../shared/constants/application-constants';

import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-punctuation-menu',
  templateUrl: './punctuation-menu.component.html',
  styleUrls: ['./punctuation-menu.component.less']
})

export class PunctuationMenuComponent extends Translated implements OnInit, OnDestroy {
  @Input() public isLookup: boolean;

  @Output() selectPunctuationItemEvent: EventEmitter<number> = new EventEmitter<number>();

  public punctuation: Array<PunctuationSign | undefined>;
  public displayedColumns: Array<string>;
  public languageModel: string;
  public referenceLanguageEnglishName: string = "";
  public visitedPunctuationIndex: number;
  public idIndexKey: string = commonTabSettings.idIndexKey;
  public changedPreviouslySelector: string = commonTabSettings.changedPreviouslySelector;
  public isMobileView: boolean;

  constructor(
    private punctuationService: PunctuationService,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    protected localStorageHelper: LocalStorageHelper
  ) {
    super(translateService, localStorageHelper, sessionService);
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  ngOnInit(): void {
    this.getDefaultFeatureValueIndex();
    this.displayedColumns = ['id', 'languageModel', 'changeableData', 'Reference language'];
    if (this.isLookup)
      this.displayedColumns.push('buttons');
    this.initFromSession();

  }

  initThisSubtypeFromSession(): void {
    this.languageModel = this.sessionService.session.targetLanguage.englishName;
    this.referenceLanguageEnglishName = this.sessionService.session.referenceLanguage.englishName;
    this.punctuationService.getPunctuationList()
      .then((punctuation: Array<PunctuationSign | undefined>) => {
        this.punctuation = punctuation;
      });
  }


  public getDefaultFeatureValueIndex(): void {
    this.visitedPunctuationIndex = this.getCashedIndex(this.idIndexKey);
  }

  private getCashedIndex(indexName: string): number | undefined {
    const cashedIndex = this.localStorageHelper.getTabSetting(indexName);
    const currentIndex = parseInt(cashedIndex, 10);

    return ((typeof currentIndex === 'number') && !isNaN(currentIndex)) ? currentIndex : undefined;
  }

  private deleteMark(id: number): Promise<object> {
    return this.punctuationService.deletePunctuation(id);
  }

  public deletePunctuationIfUserConfirmsDeletion(id: number, doesUserConfirm: boolean): Promise<void> | void {
    if (doesUserConfirm) {
      return this.deleteMark(id).then((punctuationList: object) => {
        this.punctuation = (punctuationList as PunctuationSign[]);
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  public selectItem(punctuationId: number): void {
    this.selectPunctuationItemEvent.emit(punctuationId);
  }

  ngOnDestroy(): void {
  }
}
