<mat-dialog-content>
  <div class="ti-form__one-line-fields ti-form__one-line-fields--error">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  </div>
  <mat-progress-bar *ngIf="loading() || isloading" color="accent" mode="indeterminate"></mat-progress-bar>
  <div class="ti-form-wrapper">
    <div class="ti-inflection-table__wrapper">
      <mat-progress-bar *ngIf="!inflectionList" color="accent" mode="indeterminate"></mat-progress-bar>
      <div *ngIf="inflectionList" class="ti-inflection-table">
        <div class="ti-inflection-table__header">
          <h1>{{ 'inflection-table.inflected-forms' | translate }}</h1>
        </div>
        <div class="ti-inflection-table__content">
          <div class="ti-form__one-line-fields">
            <div style="width: 65%; display: flex;">
              <div class="ti-inflection-table__lemma">{{lexeme}}</div>
              <div class="ti-inflection-table__select">
                <mat-form-field class="ti-inflection-table__family">
                  <mat-select (selectionChange)="filterBySelectedFamily()" placeholder="Family" [(value)]="selectedFamily">
                    <mat-option *ngFor="let family of families" [value]="family.id.toString()">
                      [{{family.id}}] {{family.definition}}
                    </mat-option>
                    <mat-option>None</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              </div>
            <div class="ti-form__one-line-fields--with-checkbox ti-inflection-table__verify">
              <span style="font-weight: bold;"
                [ngStyle]="{'color': inflectionList.auditUnavailable ? 'gray' : 'black', 'text-decoration': inflectionList.auditUnavailable ? 'line-through' : 'none'}">{{'inflection-table.verify'
                | translate}}</span>
              <div class="ti-inflection-table__filter_options__checkboxes">
                <mat-checkbox [disabled]="isloading || inflectionList.auditUnavailable"
                  matTooltip="{{'inflection-table.explain-verify' | translate}}" matInput
                  name="active" (change)="initTable()" [(ngModel)]="verify" style="margin: 2px auto;">
                </mat-checkbox>
              </div>
            </div>

            <div class="ti-form__one-line-fields--with-checkbox ti-inflection-table__filter_options">
              <span style="font-weight: bold;">{{'inflection-table.count' | translate}}</span>
              <div class="ti-inflection-table__filter_options__checkboxes">
                <mat-checkbox [disabled]="isloading" matTooltip="{{'inflection-table.explain-corpora' | translate}}" matInput
                  name="active" (change)="initTable()" [(ngModel)]="testFragmentCount">
                  <span class="ti-form__column--label-for-radio-buttons">{{'inflection-table.corpora' |
                    translate}}</span>
                </mat-checkbox>
                <mat-checkbox [disabled]="isloading" matTooltip="{{'inflection-table.explain-internet' | translate}}" matInput
                  name="active" (change)="initTable()" [(ngModel)]="externalCount">
                  <span class="ti-form__column--label-for-radio-buttons">{{'inflection-table.internet' |
                    translate}}</span>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <mat-progress-bar *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-bar>
          <div>
            <div *ngFor="let line of displayedList" class="ti-inflection-table__item" [ngStyle]="getStyles(line)"
              [class.ti-inflection-table__item_orange]="line.highlight" matTooltip="{{line.tooltip}}">
              <span *ngIf="line.lastResort">🧯&nbsp;</span>
              <span class="ti-inflection-table__item-value">
                <span style="opacity: 0; color: transparent;">|</span>
                {{line.text}}
                <span *ngIf="!line.lemma && line.highlight && line.createdBy !== myUsername" matTooltip="{{'COMMON.created-by' | translate}} {{line.createdBy}}">
                  <span style="color: red; font-size: large; font-weight: bold;">*</span>&nbsp;
                </span>
                <span style="opacity: 0; color: transparent;">|</span>
              </span>
              <span *ngIf="line.legacy && !line.lemma && line.inflectionId"
                matTooltip="Carabao Legacy ID: {{line.legacy}}">🐃&nbsp;</span>
              <span *ngIf="line.required" matTooltip="{{'COMMON.required-phrases' | translate}}">🛃&nbsp;</span>
              <div *ngIf="line.inflection" class="ti-inflection-table__item-links">
                <a *ngIf="testFragmentCount || externalCount" target="_blank" [matTooltip]="'inflection-table.corpora' | translate"
                  [routerLink]="'/corpora'" [queryParams]="{key: 'text', value: '%' + line.text}">{{testFragmentCount ?
                  (line.testFragmentCount || 0) : '-'}}</a>
                <a *ngIf="testFragmentCount || externalCount" target="_blank" [matTooltip]="'inflection-table.internet' | translate"
                  [href]="'https://duckduckgo.com/?q=%22' + line.text + '%22&amp;l=' + sessionService.session.targetLanguage.ISOCode + '&amp;p=-2'">{{externalCount
                  ? (line.externalCount || 0) : '-'}}</a>
              </div>
              <div class="ti-inflection-table__audit-result" *ngIf="line.auditResult">
                <span [matTooltip]="'inflection-table.audit-result-wrong' | translate" *ngIf="line.auditResult == 'wrong'">❌
                  {{line.externalMatch}}</span>
                <span [matTooltip]="'inflection-table.audit-result-match' | translate" *ngIf="line.auditResult == 'match'">✅</span>
                <span [matTooltip]="'inflection-table.audit-result-redundant' | translate"
                  *ngIf="line.auditResult == 'redundant'">❗</span>
                <span [matTooltip]="'inflection-table.audit-result-unlisted' | translate"
                  *ngIf="line.auditResult == 'unlisted'">❔</span>
              </div>
              <div class="ti-inflection-table__item-buttons">
                <div class="ti-inflection-table__button">
                  <button mat-mini-fab color="primary" *ngIf="isEditButtonVisible(line)" appOpenInflectionEditDialog
                    (closeDialogEvent)="afterCloseEditInflectionDialog($event)" [isEdit]="true" [line]="line"
                    [lexemeId]="lexemeId" [familyId]="selectedFamily" [lexeme]="lexeme">
                    <mat-icon aria-hidden="true">edit</mat-icon>
                  </button>
                </div>
                <div class="ti-inflection-table__button">
                  <button mat-mini-fab color="primary" *ngIf="isRevertButtonVisible(line)"
                    title="{{ 'inflection-table.reset-irregular-form' | translate }}" appOpenConfirmDialog
                    (doesUserConfirmEvent)="deleteInflection($event, line.inflectionId)">
                    <!--- "reset to default". Essentially, deletes the override in the database -->
                    <mat-icon aria-hidden="true">reply</mat-icon>
                  </button>
                </div>
                <div class="ti-inflection-table__button">
                  <button mat-mini-fab color="primary" *ngIf="isVisibilityButtonVisible(line)" appOpenInflectionMatchedDialog
                    [generatedBy]="line.generatedBy" [extraHypothesis]="line.extraHypothesis" [stemGeneratedBy]="line.stemGeneratedBy"
                    [lexeme]="line.text" [wordPart]="line.wordPart">
                    <mat-icon aria-hidden="true">visibility</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="ti-form__button-panel">
              <button mat-stroked-button color="accent" appOpenInflectionMatchedDialog [failures]="this.inflectionList.failures"
                [lexeme]="lexeme" class="mat-stroked-button mat-accent"
                [disabled]="isUnmatchedButtonDisabled()">{{'inflection-table.unmatched-inflection' | translate}}
              </button>
              <button mat-raised-button color="accent" class="ti-inflection-table__add" appOpenInflectionEditDialog
                (closeDialogEvent)="afterCloseEditInflectionDialog($event)" [isEdit]="false" [line]="null"
                [lexemeId]="lexemeId" [familyId]="selectedFamily" [lexeme]="lexeme">{{'COMMON.add-new' | translate}}
                <mat-icon aria-hidden="true">add</mat-icon>
              </button>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
</mat-dialog-content>