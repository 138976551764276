import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { TestsetService } from '../../shared/services/testset.service';
import { SessionService } from '../../shared/services/session.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { CorporaDefinition } from '../../shared/models/test.model';
import { Subscription } from 'rxjs';
import { FeatureListDefinition } from '../../shared/models/feature';
import { FeaturesService } from '../../shared/services/features.service';
import { applicationConstants } from '../../shared/constants/application-constants';
import { LampUpdateResponse } from '../../shared/models/common';

@Component({
  selector: 'app-corpora-edit',
  templateUrl: './corpora-edit.component.html',
  styleUrls: ['./corpora-edit.component.less']
})

export class CorporaEditComponent extends Translated implements OnInit {
  public corpora: CorporaDefinition = { id: 0, description: '', format: '' };
  public semanticFeatureList: FeatureListDefinition[] = [];
  public formatOptions: { value: string; description: string }[] = [];
  public isValidJson: boolean = true;
  private urlSubscr: Subscription;

  constructor(
    private route: ActivatedRoute,
    private featuresService: FeaturesService,
    private testService: TestsetService,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper
  ) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.initFromSession();
  }

  initThisSubtypeFromSession(): void {
    let thisOne = this;
    this.featuresService.createFilteredRelevantFeatureList(undefined, applicationConstants.semanticFeatureType)
      .then((semanticFeatureList: FeatureListDefinition[]) => {
        thisOne.semanticFeatureList = semanticFeatureList;
      });
    if (Translated.isCreateUrl(this.route)) {
      this.createForm();
      return;
    }

    this.urlSubscr = this.route.params.subscribe(
      params => {
        let id: number = params['id'];
        if (id) {
          thisOne.testService.getCorpora(id).then((corpDef: CorporaDefinition) => {
            thisOne.createForm();
            thisOne.corpora = corpDef;
            thisOne.urlSubscr.unsubscribe();
            thisOne.validateInputSetting();
          });
        }
      });
    this.loadFormatOptions();
  }

  private loadFormatOptions() {
    if (this.sessionService.session && this.sessionService.session.features) {
      const semanticFeatures = this.sessionService.session.features.find(e => e.Key == 'Semantics');
      const formatFeatures = semanticFeatures && semanticFeatures.Value ? semanticFeatures.Value.find(e => e.description == 'format').values : [];
      this.formatOptions = formatFeatures.map(e => {
        //convert hello world to Hello World
        const formatedDesc = e.description.split(' ').map(w => w[0].toUpperCase() + w.slice(1).toLowerCase()).join(' ');
        return {
          value: e.description,
          description: formatedDesc
        };
      });
    }
  }

  public isCreating(): boolean {
    return !(this.corpora.id != 0);
  }


  private createForm(): void {
    /*
    this.corporaEdit = this.formBuilder.group({
      description: [this.corpora.description],
      format: [this.corpora.format],
      importance: [this.corpora.importance],
      date: [this.corpora.date],
      license: [this.corpora.license],
      licenseType: [this.corpora.licenseType]
    });
    */
  }

  public save(): void {
    if (this.isCreating()) {
      this.testService.createCorpora(this.corpora).then((response: LampUpdateResponse) => {
        this.handleServerResponse(response);
      });
    } else {
      this.testService.updateCorpora(this.corpora).then((response: LampUpdateResponse) => {
        this.handleServerResponse(response);
      });
    }
  }

  validateInputSetting(): void {
    try {
      const input = !this.corpora || !this.corpora.recommendedTisaneSettings ? '{}' : this.corpora.recommendedTisaneSettings;
      JSON.parse(input);
      this.isValidJson = true;
    } catch (e) {
      this.isValidJson = false;
    }
  }
}