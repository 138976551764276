import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { LampUpdateResponse } from '../../shared/models/common';
import { SessionService } from '../../shared/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdministrationService } from '../../shared/services/administration.service';
import { RouterHelper } from '../../shared/helpers/router.helper';

@Component({
  selector: 'app-restart-lamp-service',
  templateUrl: './restart-lamp-service.component.html',
  styleUrls: ['./restart-lamp-service.component.less']
})
export class RestartLampServiceComponent implements AfterViewInit {
  @ViewChild(TemplateRef) dialogTemplate: TemplateRef<any>;

  constructor(
    private dialogRef: MatDialog,
    private administrationService: AdministrationService,
    private sessionService: SessionService,
    private routerHelper: RouterHelper
  ) { }

  ngAfterViewInit(): void {
    this.dialogRef.open(this.dialogTemplate, {
      disableClose: true
    });
  }

  public showSpinner: boolean = false;
  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageSubj.next(error);
  }

  public back(): void {
    this.dialogRef.closeAll();
    this.routerHelper.back();
  }

  public confirm(): Promise<void> {
    this.showSpinner = true;
    return this.administrationService.shutdown()
      .then((message: LampUpdateResponse) => this.reactOnTheServerResponse())
      .catch(() => this.reactOnTheServerResponse());
  }

  private reactOnTheServerResponse(): void {
    this.showSpinner = false;
    this.dialogRef.closeAll();
    this.sessionService.logout();
    this.routerHelper.navigateToLoginPage();
  }
}
