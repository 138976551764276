import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

import {Root} from '../../shared/models/lexicon';

@Component({
  selector: 'app-root-lookup-dialog',
  templateUrl: 'root-lookup-dialog.component.html',
  styleUrls: ['root-lookup-dialog.component.less']
})
export class RootLookupDialogComponent {

  public selectedRoot: Root;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  public reactOnSelectItemEvent(root: Root): void {
    this.selectedRoot = root;
  }

}
