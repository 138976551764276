<div *ngIf="!isOnlyOneFeature && isArray(featureValues) && relevantFeatureList">
  <div class="ti-features__content">
    <ng-container *ngFor="let featureValue of featureValues; let index=index">
      <div
        class="ti-features__content--two-column"
        [class.ti-features__mobile-view-class]="!disableSelect"
      >
        <ng-container *ngTemplateOutlet="featureTemplate; context: { featureValue: featureValue, index: index }">
        </ng-container>
      </div>
    </ng-container>
  </div>
  <button mat-mini-fab color="primary"
          *ngIf="featureValues && !disableSelect"
          (click)="addPairOfControls()"
          class="ti-features__add-button"
          tabindex="-1"
          >
    <mat-icon aria-hidden="true">add</mat-icon>
  </button>
</div>

<div *ngIf="isOnlyOneFeature && featureValue && relevantFeatureList">
  <ng-container *ngTemplateOutlet="featureTemplate; context: { featureValue: featureValue, index: 0 }">
  </ng-container>
</div>

<ng-template #featureTemplate let-index="index" let-featureValue="featureValue">
  <app-universal-feature
    *ngIf="featureValue"
    [featureValue]="featureValue"
    [preselectedIndex]="preselectedIndex"
    [setDefaultValues]="setDefaultValues"
    [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
    [relevantFeatureList]="relevantFeatureList"
    [isOnlyOneFeature]="isOnlyOneFeature"
    [isEmptyValueRelevant]="isEmptyValueRelevant"
    (featureChosenValueEvent)="sendTheFeatureValuesAbove($event, index)"
    [disableSelect]="disableSelect"
    [doesUserClearSelection]="doesUserClearSelection"
    (clearSelectionEvent)="clearSelection()"
    [checkboxLabel]="checkboxLabel"
    [checkboxAttributeName]="checkboxAttributeName"
  ></app-universal-feature>
</ng-template>
