import { ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { PagedFailures } from "../../shared/models/inflections";
import * as _ from "lodash";
import { combineLatest, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";

@Component({
  selector: "app-inflection-matched-dialog",
  templateUrl: "inflection-matched-dialog.component.html",
  styleUrls: ["inflection-matched-dialog.component.less"],
})
export class InflectionMatchedDialogComponent implements OnInit {
  public taggingKeys: string[] = [];
  public noData: boolean = false;
  public triggerGroups: Map<string, PagedFailures[]> = new Map<
    string,
    PagedFailures[]
  >();
  public isTagging: boolean = false;
  public affixSubfolder: string = "inflection";
  public initialDialogData: any;
  public initialTriggerGroups: Map<string, PagedFailures[]>;
  public tabIncludedSuccess = {};
  public affixText: string;
  public featureDescription: string;

  public affixTextUpdate = new Subject<string>();
  public featureDescriptionUpdate = new Subject<string>();
  public combinedRequired: string;
  public combinedAssigned: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.dialogData.tagging) {
      this.isTagging = true;
      this.dialogData.failures = this.dialogData.tagging;
      this.dialogData.tagging = undefined;
      this.affixSubfolder = "tagging";
    }

    if(dialogData) {
      this.combinedRequired = [
        dialogData.generatedBy ? dialogData.generatedBy.required : undefined,
        dialogData.stemGeneratedBy ? dialogData.stemGeneratedBy.required : undefined,
        dialogData.extraHypothesis ? dialogData.extraHypothesis.required : undefined
      ].filter(x => x).join(', ');
      this.combinedAssigned = [
        dialogData.generatedBy ? dialogData.generatedBy.assigned : undefined,
        dialogData.stemGeneratedBy ? dialogData.stemGeneratedBy.assigned : undefined,
        dialogData.extraHypothesis ? dialogData.extraHypothesis.assigned : undefined
      ].filter(x => x).join(', ');
    }

    // Debounce search.
    combineLatest([
      this.affixTextUpdate.pipe(startWith("")),
      this.featureDescriptionUpdate.pipe(startWith("")),
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.filter();
      });
  }

  resetFilter() {
    this.affixText = '';
    this.featureDescription = '';
    this.filter();
  }

  generateRangeTitle(firstPattern: string, lastPattern: string): string {
    if (firstPattern === lastPattern) return firstPattern;
    return (
      (firstPattern && firstPattern.trim().length > 0 ? firstPattern : "___") +
      "&nbsp;<b>…</b>&nbsp;" +
      lastPattern
    );
  }

  private hasSuccess(target: PagedFailures): boolean {
    for (let pf of target.Values) if (pf.reason === "success") return true;
    return false;
  }

  public isTriggerSuccessful(key: any): boolean {
    if (this.triggerGroups[key] == undefined) return false;
    for (let pfarr of this.triggerGroups[key])
      if (pfarr.hasSuccess) return true;

    return false;
  }

  private sortFailures(failures) {
    //sort first level
    failures = failures.sort((left, right) => left.Key.localeCompare(right.Key));

    //sort second level
    failures = failures.map(failure => {
      failure.Value = failure.Value.sort((left, right) => left.Key.localeCompare(right.Key));
      failure.Value = failure.Value.map(element => {
        element.Value = element.Value.sort((left, right) => {
          const leftText = (left.pattern || '') + (left.id || '') + (left.propagatedId || '');
          const righText = (right.pattern || '') + (right.id || '') + (right.propagatedId || '');
          return leftText.localeCompare(righText)
        });
        return element;
      })
      return failure;
    });
  }

  ngOnInit() {
    //backup initial data for filter function
    this.initialDialogData = this.dialogData || [];
    this.initialTriggerGroups = this.triggerGroups;

    //sort list
    this.sortFailures(this.dialogData.failures);

    if (this.dialogData.failures) {
      const MAX_LINES_PER_TRIGGER: number = 50;
      // because the number of failed patterns may grow to thousands, we have to break them into smaller groups
      for (let failuresByStem of this.dialogData.failures) {
        for (let failuresByTrigger of failuresByStem.Value) {
          let key: string = failuresByStem.Key + "_" + failuresByTrigger.Key;
          let tg: PagedFailures[] = [];
          let portionStart: number = 0;
          let pf: PagedFailures = { Description: "", Values: [] };
          for (let i = 0; i < failuresByTrigger.Value.length; i++) {
            if (
              i > 0 &&
              (i - portionStart) % MAX_LINES_PER_TRIGGER === 0 &&
              failuresByTrigger.Value[portionStart] &&
              failuresByTrigger.Value[i - 1]
            ) {
              pf.Description = this.generateRangeTitle(
                failuresByTrigger.Value[portionStart].pattern,
                failuresByTrigger.Value[i - 1].pattern
              );
              tg.push(pf);
              pf = { Description: "", Values: [] }; // new one
              portionStart = i;
            }
            pf.Values.push(failuresByTrigger.Value[i]);
            pf.hasSuccess = pf.hasSuccess || failuresByTrigger.Value[i].reason == "success";
            this.tabIncludedSuccess[failuresByStem.Key] = this.tabIncludedSuccess[failuresByStem.Key] || pf.hasSuccess;
          }
          if (failuresByTrigger.Value[portionStart])
            pf.Description = this.generateRangeTitle(
              failuresByTrigger.Value[portionStart].pattern,
              failuresByTrigger.Value[failuresByTrigger.Value.length - 1]
                .pattern
            );
          else pf.Description = "???";
          pf.hasSuccess = this.hasSuccess(pf);
          tg.push(pf);

          this.triggerGroups[key] = tg;
        }
      }
    }
    this.noData =
      this.taggingKeys.length < 1 &&
      !this.dialogData.failures &&
      !this.dialogData.extraHypothesis &&
      (!this.dialogData.generatedBy || this.dialogData.generatedBy.length < 1);
  }

  private filter() {
    let { affixText, featureDescription } = this;
    affixText = affixText ? affixText.trim().toLowerCase() : "";
    featureDescription = featureDescription
      ? featureDescription.trim().toLowerCase()
      : "";
    if (affixText.length == 0 && featureDescription.length == 0) {
      this.triggerGroups = this.initialTriggerGroups;
      return;
    }

    this.triggerGroups = new Map<string, PagedFailures[]>();
    for (const key in this.initialTriggerGroups) {
      const originalList = _.cloneDeep(this.initialTriggerGroups[key]);
      const filteredList = [];
      for (const element of originalList) {
        element.Values = element.Values || [];
        element.Values = element.Values
        .filter((failure) => {
          //filter affix text
          return !affixText || (failure.pattern || "").toLowerCase().indexOf(affixText) !== -1
          })
        .filter((failure) => {
          //filter Feature Description
          return !featureDescription ||
          (failure.assigned || "").toLowerCase().indexOf(featureDescription) !== -1 ||
          (failure.required || "").toLowerCase().indexOf(featureDescription) !== -1
        });

        if (element.Values.length > 0) {
          filteredList.push(element);
        }
      }
      if (filteredList.length > 0) {
        this.triggerGroups[key] = filteredList;
      }
    }
    this.changeDetectorRef.detectChanges();
  }
}
