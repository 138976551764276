import { Directive, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import { LexemeEditDialogComponent } from './lexeme-edit-dialog.component';

@Directive({
  selector: '[appOpenLexemeEditDialog]'
})
export class OpenLexemeEditDialogDirective {
  @Output() updateDataEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isMobileView: boolean;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public changeDetector: ChangeDetectorRef
  ) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(
      LexemeEditDialogComponent,
      {
        minWidth: this.isMobileView ? '95%' : '70%',
        id: 'lexemeEditDialog'
      }
    );
    this.changeDetector.detectChanges();

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.updateDataEvent.emit(true);
        }
      });
  }

}
