<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title *ngIf="isLexemesDialog || isAutoSuggestionLexemeDialog">{{'knowledge-graph.lexeme-selection' | translate}}</h2>
  <h2 mat-dialog-title *ngIf="isFamilyDialog">{{'knowledge-graph.family-selection' | translate}}</h2>
  <mat-dialog-actions class="ti-mat-dialog-actions---end">
    <button
      mat-raised-button
      appOpenLexemeEditDialog
      (updateDataEvent)="getNewLexemeId()"
      color="accent"
    >{{'COMMON.add-new' | translate}}
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
  <app-knowledge-graph-lexeme-selection
    *ngIf="isLexemesDialog"
    [familyId]="familyId"
    [byText]="byText"
    [chooseOnlyOneLexeme]="chooseOnlyOneItem"
    [allowOtherLanguages]="allowOtherLanguages"
    (updateEntryEvent)="updateEntry($event)"></app-knowledge-graph-lexeme-selection>
  <app-family-selection
    *ngIf="isFamilyDialog"
    [chooseOnlyOneFamily]="chooseOnlyOneItem"
    (updateEntryEvent)="updateEntry($event)"></app-family-selection>
  <app-knowledge-graph-lexeme-selection
    *ngIf="isAutoSuggestionLexemeDialog"
    [isAutoSuggestionLexemeDialog]="isAutoSuggestionLexemeDialog"
    [lexemesList]="lexemesList"
    [familyId]="familyId"
    [byText]="byText"
    [lexemesList]="lexemesList"
    (updateEntryEvent)="updateEntry($event)"></app-knowledge-graph-lexeme-selection>
</mat-dialog-content>
<mat-dialog-actions class="ti-changes-have-been-saved__actions">
  <button #btnClose mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
  <button mat-raised-button [disabled]="!idsList" color="accent" [mat-dialog-close]="idsList">{{'COMMON.select' | translate}}</button>
</mat-dialog-actions>
