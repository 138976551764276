import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {FeaturesService} from '../../shared/services/features.service';
import {FeatureLocalization} from '../../shared/models/feature';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {LampUpdateResponse} from '../../shared/models/common';
import {applicationModulesRoutePaths} from "../../shared/constants/application-constants";

@Component({
  selector: 'app-feature-localization-edit',
  templateUrl: './feature-localization-edit.component.html',
  styleUrls: ['./feature-localization-edit.component.less']
})
export class FeatureLocalizationEditComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public featureLocalizationEditForm: FormGroup;
  public featureListLocalizationId: number;
  public featureLocalizationValue: string;

  public featureLocalization: FeatureLocalization;

  public isFeatureLocalizationValueEditPage: boolean;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private featuresService: FeaturesService,
              private routerHelper: RouterHelper) {
  }

  ngOnInit(): void {
    this.subscribeOnRouteChanges();
  }

  private subscribeOnRouteChanges(): void {
    this.routeSubscription = this.route.data.subscribe(
      (data: {
        id: number,
        value: string
      }) => {
        if (data.id && !isNaN(data.id)) {
          this.featureListLocalizationId = data.id;
          this.isFeatureLocalizationValueEditPage = Boolean(data.value);
          if (this.isFeatureLocalizationValueEditPage) {
            this.featureLocalizationValue = data.value;
            this.prepareFeatureLocalizationData();
          } else {
            this.prepareFeatureListLocalizationData();
          }
        }
      });
  }

  private prepareFeatureListLocalizationData(): Promise<void> {
    return this.featuresService.getFeatureListLocalizationById(this.featureListLocalizationId)
      .then((featureLocalization: FeatureLocalization) => {
        this.featureLocalization = featureLocalization;
        this.featureLocalization.copyToPhrase = this.featureLocalization.copyToPhrase ? this.featureLocalization.copyToPhrase : '';
        this.createForm();
      });
  }

  private prepareFeatureLocalizationData(): Promise<void> {
    return this.featuresService.getFeatureListLocalizationByIdAndValue(this.featureListLocalizationId, this.featureLocalizationValue)
      .then((featureLocalization: FeatureLocalization) => {
        this.featureLocalization = featureLocalization;
        this.createForm();
      });
  }

  private createForm(): void {
    this.featureLocalizationEditForm = this.formBuilder.group({
      description: [this.featureLocalization.description],
      localized: [this.featureLocalization.localized],
      referenceUrl: [this.featureLocalization.referenceUrl],
      copyToPhrase: [this.featureLocalization.copyToPhrase],
      systemDescription: [this.featureLocalization.systemDescription],
      tag: [this.featureLocalization.tag],
      wiktionaryTag: [this.featureLocalization.wiktionaryTag]
    });
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public save(): Promise<void> {
    if (this.isFeatureLocalizationValueEditPage) {
      return this.featuresService.updateFeatureLocalization(this.featureLocalization)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.cancel();
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
    } else {
      return this.featuresService.updateFeatureListLocalization(this.featureLocalization)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.cancel();
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
    }
  }

  public cancel(): void {
    this.routerHelper.back();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
