<h1>{{ 'TEST.gold-standard-results-analysis' | translate }}</h1>
<app-universal-id-text-range-filter
  [currentRange]="'testresults'"
  [loadedRecordCount]="results ? results.length : 0"
  (chosenParameterEvent)="loadData($event)"
></app-universal-id-text-range-filter>
<div class="ti-form__one-line-fields ti-form__one-line-fields--error">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
</div>
<mat-progress-bar *ngIf="loading()" color="accent" mode="indeterminate"></mat-progress-bar>
<mat-accordion *ngIf="results" multi="true">
  <mat-expansion-panel
    *ngFor="let res of results"
    appScrollToChangedPreviouslyElement
    [expanded]="(res.id === selectedId)"
    (click)="selectedId = undefined"
    (opened)="res.uiExpanding = true"
    (closed)="res.uiExpanding = false"
    >
    <mat-expansion-panel-header>
      <mat-panel-title
        class="ti-panel-title">
        <span *ngIf="res.standard" matTooltip="{{'TEST.standard' | translate}}">🏆&nbsp;</span>
        <span *ngIf="res.gold" matTooltip="{{'TEST.gold' | translate}}">👍&nbsp;</span>
        &nbsp;[{{res.created}}]
        &nbsp;
        <span *ngIf="res.rating" matTooltip="{{interpretRating(res.rating)}}">{{res.rating}}&nbsp;</span>
        <span *ngIf="res.grade1">{{res.grade1}}&nbsp;</span>
        <span *ngIf="res.grade2">{{res.grade2}}&nbsp;</span>
        <span *ngIf="res.grade3">{{res.grade3}}&nbsp;</span>
        <span *ngIf="res.abuse && res.abuse.length &gt; 0" matTooltip="{{toSummary(res.fragment, res.abuse, undefined)}}">&nbsp;{{getAbuseEmoji(res)}}</span>
        <span *ngIf="res.sentiment && res.sentiment.length &gt; 0" matTooltip="{{toSummary(res.fragment, res.sentiment, undefined)}}">&nbsp;{{getSentimentEmoji(res)}}</span>
        <span *ngIf="res.entities && res.entities.length &gt; 0" matTooltip="{{toSummary(res.fragment, res.entities, 'subtype')}}">&nbsp;{{getEntityEmoji(res)}}</span>
        <span *ngIf="res.topics && res.topics.length &gt; 0" matTooltip="{{toSummary(res.fragment, res.topics, 'label')}}">&nbsp;📚</span>
        &nbsp;
      </mat-panel-title>
      <mat-panel-description class="ti-panel-description">
        {{panelTitle(res)}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngIf="res.uiExpanding && (!res.breakdown || res.breakdown.length &lt; 1)" matExpansionPanelContent class="mat-tab-content">
      <span>{{res.raw}}</span>
      <ng5-slider [(value)]="res.rating" [options]="ratingSliderDefinition"></ng5-slider>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!canRate() || !res.changed"
        (click)="rate(res)">{{'TEST.save-rating' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="res.uiExpanding && res.breakdown" matExpansionPanelContent class="mat-tab-content">
      <textarea id="input{{res.id}}" name="input{{res.id}}" matTooltip="{{'TEST.select-to-add-annotation' | translate}}" (select)="setSelection($event, res)" readonly rows="10" cols="90">{{res.fragment}}</textarea>
      &nbsp;&nbsp;
      <button
        mat-raised-button
        color="accent"
        [disabled]="(!canRate() || !res.changed) && res.standard"
        (click)="save(res)">{{'TEST.update-gold-standard-analysis' | translate}}
      </button>
      <mat-tab-group [disableRipple]="true">
        <mat-tab label="👺&nbsp;{{'TEST.abuse' | translate}}">
          <div class="mat-tab-content">
            <mat-chip-list *ngIf="res.abuse">
              <mat-chip *ngFor="let ab of res.abuse" [removable]="true" (removed)="removeItem(res, res.abuse, ab)"
              matTooltip="{{ab.tags}}">
                {{abuse2Emoji(ab.label)}}&nbsp;{{tag2Emoji(ab.tags)}}&nbsp;{{resultText(res.fragment, ab, '')}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <select
              matTooltip="{{ 'TEST.tags' | translate}}"
              [(ngModel)]="res.selectedAbuse">
                <option value="">--</option>
                <option *ngFor="let tag of abuseTags" value="{{tag.description}}">{{tag.description}}&nbsp;{{tag.emoji}}</option>
            </select>&nbsp;
            <button *ngFor="let av of getAbuseValues()" 
                matTooltip="{{'TEST.add-selected-text' | translate}}: {{av.description}}"
                (click)="captureFragment(res, res.abuse, 'abuse', av.description)"
                [disabled]="!hasSelection(res)">
              {{av.emoji}}&nbsp;
            </button>
          </div>
        </mat-tab>
        <mat-tab label="😐&nbsp;{{'TEST.sentiment' | translate}}">
          <div class="mat-tab-content">
            <mat-chip-list *ngIf="res.sentiment">
              <mat-chip *ngFor="let se of res.sentiment" [removable]="true"
               (removed)="removeItem(res, res.sentiment, se)"
               matTooltip="{{se.tags}}">
                {{sentiment2Emoji(se.label)}}&nbsp;{{aspect2Emoji(se.tags)}}&nbsp;{{resultText(res.fragment, se, '')}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <select
            matTooltip="{{ 'TEST.tags' | translate}}"
            role="listbox"
              [(ngModel)]="res.selectedAspect">
                <option value="">--</option>
                <option *ngFor="let aspect of sentimentAspects" value="{{aspect.description}}">{{aspect.description}}&nbsp;{{aspect.emoji}}</option>
            </select>&nbsp;
            <button *ngFor="let sv of getSentimentValues()" 
            matTooltip="{{'TEST.add-selected-text' | translate}}: {{sv.description}}"
            (click)="captureFragment(res, res.sentiment, 'sentiment_expressions', sv.description)"
                [disabled]="!hasSelection(res)">
              {{sv.emoji}}&nbsp;
            </button>
          </div>
        </mat-tab>
        <mat-tab label="🖻&nbsp;{{'TEST.entities' | translate}}">
          <div class="mat-tab-content">
            <mat-chip-list *ngIf="res.entities">
              <mat-chip *ngFor="let e of res.entities" [removable]="true"
              (removed)="removeItem(res, res.entities, e)"
              matTooltip="{{e.tags}}">
                {{entity2Emoji(e.label)}}&nbsp;{{e.subtype}}&nbsp;{{resultText(res.fragment, e, '')}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <select
              matTooltip="{{ 'TEST.tags' | translate}}"
              role="listbox"
              [(ngModel)]="res.selectedEntity">
                <option value="">--</option>
                <option *ngFor="let entity of entitySubtypes" value="{{entity.description}}">{{entity.description}}&nbsp;{{entity.emoji}}</option>
            </select>&nbsp;
            <button *ngFor="let ev of getEntityValues()" 
            matTooltip="{{'TEST.add-selected-text' | translate}}: {{ev.description}}"
            (click)="captureFragment(res, res.entities, 'entity', ev.description)"
                [disabled]="!hasSelection(res)">
              {{ev.emoji}}&nbsp;
            </button>
          </div>
        </mat-tab>
        <mat-tab label="📙&nbsp;{{'TEST.topics' | translate}}">
          <div class="mat-tab-content">
            <mat-chip-list *ngIf="res.topics">
              <mat-chip *ngFor="let t of res.topics" removable="true" (removed)="removeItem(res, res.topics, t)">
                {{resultText(res.fragment, t, 'label')}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <button appOpenFamilySelectionDialog
            (beforeDialogOpenedEvent)="disableRefresh()"
            (selectedDataEvent)="addTopic($event, res)"
            [chooseOnlyOneFamily]="true">📚</button>
          </div>
        </mat-tab>
        <mat-tab  class="mat-tab-content" label="📖&nbsp;{{'TEST.lexical-items' | translate}}">
          <div class="mat-tab-content">
            <mat-chip-list *ngIf="res.words">
              <mat-chip *ngFor="let w of res.words">
                {{resultText(res.fragment, w, '')}}&nbsp;
                <b>{{w.tags}}</b>&nbsp;
                <a *ngIf="w.subtype === 'word' && w.num &gt;= 0 && res.words.length &lt; 150" class="ti-id" title="{{'COMMON.lexeme' | translate}}" 
                appOpenKnowledgeGraphDialog
                (beforeDialogOpenedEvent)="disableRefresh()"
                (afterDialogClosedEvent)="setWordLexeme($event, w, res)"
                [isLexemesDialog]="true"
                [allowOtherLanguages]="true"
                [byText]="resultText(res.fragment, w, '') + '$'"
                [isFamilyDialog]="false"
                [chooseOnlyOneItem]="true">{{w.num}}</a>&nbsp;
                <a *ngIf="w.subtype === 'word' && res.words.length &lt; 150" class="ti-id" title="{{'COMMON.family' | translate}}" 
                appOpenFamilySelectionDialog
                [byText]="resultText(res.fragment, w, '') + '$'"
                (beforeDialogOpenedEvent)="disableRefresh()"
                (selectedDataEvent)="setWordFamily($event, w, res)"
            title="{{w.auxLabel}}"
            [chooseOnlyOneFamily]="true">{{w.entityId}}</a>
                <span *ngIf="w.subtype === 'word' && res.words.length &gt; 149" title="{{w.auxLabel}}">{{w.entityId}}</span>
              </mat-chip>
            </mat-chip-list>
            <button
            mat-raised-button
            *ngIf="res.words && res.words.length &gt; 0 && res.words.length &lt; 150 && !res.words[0].auxLabel"
            color="accent"
            (click)="loadWordDefinitions(res.words)">{{'TEST.load-definitions' | translate}}
          </button>
            </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>