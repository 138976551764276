import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {LexemeMergeDialogComponent} from './lexeme-merge-dialog.component';
import {Lexeme} from '../../shared/models/lexicon';

@Directive({selector: '[appOpenLexemeMergeDialog]'})

export class ButtonOpensLexemeMergeDialogDirective {
  @Input('duplicatesIds') duplicatesIds: string;
  @Input('currentLexeme') currentLexeme: Lexeme;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(public dialog: MatDialog) {
  }

  @HostListener('click') openDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(LexemeMergeDialogComponent, {
      minWidth: '80%',
      data: {
        duplicatesIds: this.duplicatesIds,
        currentLexeme: this.currentLexeme
      }
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });
  }
}
