<div class="ti-form-wrapper ti-feature-localization-edit-form">
  <form class="ti-form" *ngIf="featureCategoryEditForm" [formGroup]="featureCategoryEditForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <h1>{{ 'feature-category.feature-category' | translate}}</h1>
    <div class="ti-form__one-line-fields">
      <p class="ti-form__label ti-form__half-line-fields" *ngIf="!isNew">{{ 'COMMON.feature-list-id' | translate}}:
        <span class="ti--bold-text"> {{featureCategory.id}}</span></p>
    </div>
    <div class="ti-form__one-line-fields">
      <p class="ti-form__label ti-form__half-line-fields" *ngIf="!isNew">{{ 'COMMON.feature-index' | translate}}:
        <span class="ti--bold-text"> {{featureCategory.index}}</span></p>
    </div>
      <div class="ti-form__one-line-fields">
      <p class="ti-form__label ti-form__half-line-fields">{{'feature-category.feature-type' | translate}}:
        <span class="ti--bold-text"> {{featureCategory.type}}</span></p>
    </div>
    <div class="ti-form__column-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="description" name="description"
               appDisableControl
               [disableControl]="!doesUserHaveAccessToEdit"
               [(ngModel)]="featureCategory.description"
               (keypress)="clearTheErrorMessage()"
               [placeholder]="'COMMON.description' | translate">
      </mat-form-field>
    </div>

    <div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
      <mat-checkbox
        matInput formControlName="allowFallback" name="allowFallback"
        (click)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="featureCategory.allowFallback">
        <span class="ti-form__column--label-for-radio-buttons">{{'feature-category.allow-fallback' | translate}}</span>
      </mat-checkbox>
    </div>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <!-- add only digits pipe-->
        <input matInput formControlName="generationWeight" name="generationWeight"
               [(ngModel)]="featureCategory.generationWeight"
               (keypress)="clearTheErrorMessage()"
               appDisableControl
               [disableControl]="!doesUserHaveAccessToEdit"
               placeholder="{{ 'feature-category.generation-weight' | translate}}">
      </mat-form-field>
    </div>


    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
      <input matInput formControlName="referenceUrl" name="referenceUrl"
               [(ngModel)]="featureCategory.referenceUrl"
               (keypress)="clearTheErrorMessage()"
               appDisableControl
               [disableControl]="!doesUserHaveAccessToEdit"
               placeholder="{{ 'COMMON.reference-url' | translate}}">
      </mat-form-field>
    </div>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="inflectionTablePriority" name="inflectionTablePriority"
               [(ngModel)]="featureCategory.inflectionTablePriority"
               (keypress)="clearTheErrorMessage()"
               appDisableControl
               [disableControl]="!doesUserHaveAccessToEdit"
               placeholder="{{ 'feature-category.inflection-table-priority' | translate}}">
      </mat-form-field>
    </div>


    <div class="ti-form__one-line-fields ti-form__behavior">
      <p class="ti-form__label ti-form__column--label-for-radio-buttons">{{'COMMON.behavior' | translate}}</p>
      <mat-radio-group
        class=" ti-form__column"
        [(ngModel)]="featureCategory.behavior"
        formControlName="behavior"
        name="behavior"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit">
        <mat-radio-button
          class="ti-form__radio-button"
          *ngFor="let option of featureCategoryBehaviorOptions"
          [value]="option.value">
          <p class="ti-form__radio-label">{{option.description}}</p>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
      <mat-checkbox
        matInput formControlName="inflectionPattern" name="inflectionPattern"
        (click)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="featureCategory.inflectionPattern">
        <span class="ti-form__column--label-for-radio-buttons">{{'feature-category.inflection-pattern' | translate}}</span>
      </mat-checkbox>
    </div>

    <div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
      <mat-form-field color="accent">
        <input matInput formControlName="defaultValue" name="defaultValue"
               [(ngModel)]="featureCategory.defaultValue"
               appDisableControl
               [disableControl]="!doesUserHaveAccessToEdit"
               (keypress)="clearTheErrorMessage()"
               [placeholder]="'COMMON.default-value' | translate">
      </mat-form-field>
    </div>

    <div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
      <mat-checkbox class="ti-form__one-line-fields--with-checkbox"
        matInput formControlName="allowMultipleValues" name="allowMultipleValues"
        (click)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="featureCategory.allowMultipleValues">
        <span class="ti-form__column--label-for-radio-buttons">{{'feature-category.allow-multiple-values' | translate}}</span>
      </mat-checkbox>
    </div>
<div class="ti-form__one-line-fields ti-form__one-line-fields--with-checkbox">
  <mat-checkbox class="ti-form__one-line-fields--with-checkbox" matInput formControlName="copyToPhrase"
    name="copyToPhrase" (click)="clearTheErrorMessage()" appDisableControl [disableControl]="!doesUserHaveAccessToEdit"
    [(ngModel)]="featureCategory.copyToPhrase">
    <span class="ti-form__column--label-for-radio-buttons">{{'feature-category.copy-to-phrase' |
      translate}}</span>
  </mat-checkbox>
</div>
    <div class="ti-form__button-panel">

      <button mat-raised-button
              (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>
      <button
        [disabled]="!doesUserHaveAccessToEdit"
        mat-raised-button
        color="accent"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
