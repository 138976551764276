import { Component, OnInit } from '@angular/core';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../shared/services/session.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { TestsetService } from '../../shared/services/testset.service';
import { TestStats, OneLevelDateStat, ThroughputMetric, TestMetric, PhraseTestFailure, ResultDifference, TestResult, PhraseTestTotals } from '../../shared/models/test.model';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { StatisticsService } from '../../shared/services/statistics.service';
import { LinguistService } from '../../shared/services/linguist.service';
import { EditBreakdown, User } from '../../shared/models/statistics';
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../shared/models/language';
import { MatTabChangeEvent } from '@angular/material';


interface CharAreaStyle {
  normal: any[];
}

/**
 * The Y series data
 */
interface ChartYSeries {
  name: string, // e.g. 'Precision'
  type: string, // e.g. 'line'
  stack?: string, // e.g. 'counts'
  areaStyle?: CharAreaStyle, // e.g. { normal: {} },
  data: any[] // e.g. [120, 132, 101, 134, 90, 230, 210]
  markLine?: ChartMarkline;
  symbolSize?: number; // e.g. 10
  tooltip?: {
    show?: boolean;
  },
  customValue?: string;
}

/**
 * The X axis
 */
interface ChartXAxis {
  type: string;
  boundaryGap?: boolean;
  data: any[];
  customValue?: string;
}

interface ChartMarkline {
  symbol: 'none',
  label: {
    show: true,
    position: string, //'end'
    formatter?: string // the actual label
  },
  lineStyle: {
    color: '#e54035',
    width: 2
  },
  data: [{
    yAxis: number
  }]
}

interface ChartOptions {
  tooltip?;
  grid?;
  yAxis?;
  xAxis?: ChartXAxis[];
  series?: ChartYSeries[];
  markLine?: ChartMarkline;
  title?: {
    text: string
  },
}

enum FailStage {
  TRIGGERING = 'triggering',
  MATCHING = 'matching',
  REQUIREMENT_VALIDATION = 'requirement_validation',
  COMPETITION = 'competition'
}

const initialLoadingState = {
  unknownStats: null,
  metrics: null,
  coverage: null,
  fragmented: null,
  topPhrases: null,
  phraseCoverage: null,
  phraseTestTotals: null,
  throughput: null
};



@Component({
  selector: 'linguist-activities',
  templateUrl: './linguist-activities.component.html',
  styleUrls: ['./linguist-activities.component.less']
})
export class LinguistActivitiesComponent extends Translated implements OnInit {

  private _defaultMarkLine: ChartMarkline = {
    symbol: 'none',
    label: {
      show: true,
      position: 'middle'
    },
    lineStyle: {
      color: '#e54035',
      width: 2
    },
    data: [{
      yAxis: 0
    }]
  };

  /**
   * Instance of the abuse detection chart
   */
  public abuseChart: {
    [key: string]: any
  } = {};
  /**
   * Instance of the sentiment detection chart
   */
  public sentimentChart: {
    [key: string]: any
  } = {};
  /**
   * Instance of the entity detection chart
   */
  public entityChart: {
    [key: string]: any
  } = {};
  /**
   * Instance of the topic detection chart
   */
  public topicChart;
  /**
   * Instance of the % of unknown words chart
   */
  public unknownChart: {
    [key: string]: any
  } = {};
  /**
   * Instance of the top phrase chart
   */
  public topPhraseChart;
  /**
   * Instance of the sentence coverage chart
   */
  public coverageChart;
  /**
   * Instance of the share of poorly mapped / fragmented sentence chart
   */
  public fragmentedChart;
  /**
   * Instance of the WPS chart
   */
  public wpsChart;
  /**
   * Instance of the CPS chart
   */
  public cpsChart;

  public stats: TestStats;

  public lastFrom: number = 0;
  public lastTo: number = 0;

  public fromDateAbuse: number = 0;
  public toDateAbuse: number = 0;
  public fromDateSentiment: number = 0;
  public toDateSentiment: number = 0;
  public fromDateEntities: number = 0;
  public toDateEntities: number = 0;
  public fromDateTopics: number = 0;
  public toDateTopics: number = 0;
  public sampleDateAbuse: number = 0;
  public sampleDateSentiment: number = 0;
  public sampleDateEntities: number = 0;
  public sampleDateTopics: number = 0;

  private _lastRange: string;


  public failedPhraseTestCount: {
    [key: string]: any
  } = {};

  public totalPhraseTests: {
    [key: string]: any
  } = {};

  public failedPhraseExamples: PhraseTestFailure[];
  public showedFailedPhraseExamples: PhraseTestFailure[];

  public abuseDates: string[] = [];
  public sentimentDates: string[] = [];
  public entityDates: string[] = [];
  public topicDates: string[] = [];

  public _commonChartOptions: ChartOptions = {
    title: {
      text: null
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: [
      {
        type: 'value'
      }
    ],
    xAxis: [{
      type: 'category', data: []
    }],
    series: []
  };

  public isLoaded: { [key: string]: boolean } = {};
  // public isLoading = false;
  public isLoading = { ...initialLoadingState };


  public abuseOptions: {
    [key: string]: ChartOptions
  } = {};

  public sentimentOptions: {
    [key: string]: ChartOptions
  } = {};

  public entitiesOptions: {
    [key: string]: ChartOptions
  } = {};

  public topicOptions: ChartOptions = {

  };

  public unknownOptions: {
    [key: string]: ChartOptions
  } = {};

  public topPhraseOptions: ChartOptions = {

  };

  public coverageOptions: ChartOptions = {

  };

  public fragmentedOptions: ChartOptions = {

  };

  public wpsOptions: ChartOptions = {

  };

  public cpsOptions: ChartOptions = {

  };

  public languagesList: Language[] = [];

  users: {
    user: User,
    editBreakDown: EditBreakdown[],
    status: string,
    languageList: number[],
    activityCharts: {
      name: string;
      chartOptions: ChartOptions
    }[]
  }[]

  constructor(
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    protected _service: TestsetService,
    protected statisticsService: StatisticsService,
    protected linguistService: LinguistService,
    protected languageService: LanguageService,
  ) {
    super(translateService, localStorageHelper, sessionService);
    let jsonCommonOptions: string = JSON.stringify(this._commonChartOptions)
    // this.coverageOptions = JSON.parse(jsonCommonOptions);
    // this.fragmentedOptions = JSON.parse(jsonCommonOptions);
    // this.topPhraseOptions = JSON.parse(jsonCommonOptions);
    // this.unknownOptions = JSON.parse(jsonCommonOptions);

    this.abuseOptions = JSON.parse(jsonCommonOptions);
    // this.entitiesOptions = JSON.parse(jsonCommonOptions);
    // this.sentimentOptions = JSON.parse(jsonCommonOptions);
    // this.topicOptions = JSON.parse(jsonCommonOptions);

    // this.wpsOptions = JSON.parse(jsonCommonOptions);
    // this.cpsOptions = JSON.parse(jsonCommonOptions);
  }

  async ngOnInit() {
    this.initFromSession();

    this.users = (await this.getUsers() as any || []).map(user => ({
      user: user
    }));

    await this.getLanguagesList();
  }

  protected async getLanguagesList() {
    if (this.languageService.languagesList.length == 0) {
      this.languagesList = await this.languageService.getLanguagesList();
    } else {
      this.languagesList = this.languageService.languagesList;
    }
  }

  protected getUsers() {
    return this.statisticsService.getUsers();
  }

  public async getEditBreakdownByUser(userId: string) {
    return await this.statisticsService.getEditBreakdownByUser(userId);
  }

  public async getUserStatus(userId: string) {
    return await this.statisticsService.getUserStatus(userId);
  }

  async expandUser(dbUser: string) {
    this.users.map(async u => {
      if (u.user && u.user.dbUser == dbUser && !u.editBreakDown) {
        const editBreakdown = await this.getEditBreakdownByUser(dbUser);
        u.editBreakDown = editBreakdown || [];
        u.status = await this.getUserStatus(dbUser)
        u.activityCharts = [];
        u.languageList = [];
        for (let i = 0; i < editBreakdown.length; i++) {
          const item = editBreakdown[i];
          const chartOptions: ChartOptions = {
            xAxis: [{
              type: 'category',
              data: item.Value.map(v => (v.Key || ''))
            }],
            yAxis: [{ type: 'value' }],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              },
              formatter: (params) => {
                const { firstEdit, lastEdit } = params[0].data;
                const duration = (firstEdit || lastEdit) ? `between ${firstEdit ? firstEdit.substr(1, 5) : 'N/A'} and ${lastEdit ? lastEdit.substr(1, 5) : 'N/A'}` : '';
                return `<b>Edits:</b> ${params[0].data.value} ${duration} <br /> 
                ${params[0].data.status ? `<b>Status: </b> ${params[0].data.status}` : ''}`;
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            series: [{
              name: 'Status',
              type: 'line',
              data: item.Value.map(v => {
                u.languageList = u.languageList.concat(v.Value.languages);
                return {
                  value: v.Value.edits,
                  status: v.Value.status,
                  firstEdit: v.Value.firstEdit,
                  lastEdit: v.Value.lastEdit
                };
              })
            }]
          }
          u.activityCharts.push({
            name: item.Key,
            chartOptions: chartOptions
          });
        }

        //attendance
        var attendanceSummary = {};
        editBreakdown.forEach(item => {
          const { Key, Value } = item;
          for (let i = 0; i < Value.length; i++) {
            const e = Value[i];
            if (!attendanceSummary[e.Key]) {
              attendanceSummary[e.Key] = JSON.parse(JSON.stringify(e.Value));
              attendanceSummary[e.Key].edits = 0;
              attendanceSummary[e.Key].date = e.Key;
            }

            attendanceSummary[e.Key].edits += e.Value.edits;
            if (attendanceSummary[e.Key].firstEdit.localeCompare(e.Value.firstEdit) > 0) {
              attendanceSummary[e.Key].firstEdit = e.Value.firstEdit;
            }
            if (attendanceSummary[e.Key].lastEdit.localeCompare(e.Value.lastEdit) < 0) {
              attendanceSummary[e.Key].lastEdit = e.Value.lastEdit;
            }
          }
        })

        // const attendanceChartOpts = JSON.parse(JSON.stringify(this._commonChartOptions));
        const attendanceChartOpts = JSON.parse(JSON.stringify(this._commonChartOptions));
        attendanceChartOpts.color = [
          '#FFF',
          '#f35218',
        ];
        attendanceChartOpts.xAxis[0].data = Object.keys(attendanceSummary).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        attendanceChartOpts.yAxis[0].axisLabel = {
          formatter: (value) => {
            return `${value.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:00`
          }
        }

        attendanceChartOpts.series[0] = {
          name: 'First Edit',
          type: 'line',
          stack: 'x',
          areaStyle: {},
          data: Object.values(attendanceSummary).map((v: any) => {
            const firstEdit = v.firstEdit ? Number.parseFloat(v.firstEdit.substr(1, 5).replace(':', '.')) : 0;
            return {
              value: firstEdit,
              firstEdit: v.firstEdit,
              lastEdit: v.lastEdit,
              edits: v.edits,
              date: new Date(v.date),
              status: v.status,
            };
          }).sort((a, b) => a.date.getTime() - b.date.getTime())
        };
        attendanceChartOpts.series[1] = {
          name: 'Last Edit',
          type: 'line',
          stack: 'x',
          areaStyle: {},
          data: Object.values(attendanceSummary).map((v: any) => {
            const firstEdit = v.firstEdit ? Number.parseFloat(v.firstEdit.substr(1, 5).replace(':', '.')) : 0;
            const lastEdit = v.lastEdit ? Number.parseFloat(v.lastEdit.substr(1, 5).replace(':', '.')) : 0;
            return {
              value: lastEdit - firstEdit >= 0 ? lastEdit - firstEdit : firstEdit,
              firstEdit: v.firstEdit,
              lastEdit: v.lastEdit,
              edits: v.edits,
              date: new Date(v.date),
              detail: v,
              status: v.status || 'N/A'
            };
          }).sort((a, b) => a.date.getTime() - b.date.getTime())
        };

        attendanceChartOpts.tooltip.formatter = (params) => {
          const { firstEdit, lastEdit, edits } = params[0].data;

          const duration = `${firstEdit ? firstEdit.substr(1, 5) : 'N/A'} - ${lastEdit ? lastEdit.substr(1, 5) : 'N/A'}`;
          const diffInMin = firstEdit && lastEdit ? (new Date(`2020-01-01${lastEdit}`).getTime() - new Date(`2020-01-01${firstEdit}`).getTime()) / 1000 / 60 : 0;
          const status = params[0].data.status || 'N/A'
          if (diffInMin >= 0) {
            const hours = Math.floor(diffInMin / 60);
            const mins = Math.floor(diffInMin - (hours * 60));
            return `<b>Time:</b> ${duration} (${hours} hrs. ${mins} min.)<br/><b>Edits:</b> ${edits}<br/><b>Status:</b> ${status}`
          } else {
            return `<b>Time:</b> ${duration}<br /><b>Edits:</b> ${edits}<br/><b>Status:</b> ${status}`
          }
        }

        u.activityCharts.unshift({
          name: 'Attendance',
          chartOptions: attendanceChartOpts
        });

        u.languageList = _.uniq(u.languageList).map(langId => {
          const language = this.languagesList.find(x => x.id == langId);
          if (language) {
            return {
              id: langId,
              englishName: language.englishName
            }
          } else {
            return null;
          }
        }).filter(e => e)
      }
      return u;
    })
  }

  public onParsingTabChanged(langId: number, event: MatTabChangeEvent) {
    switch (event.tab.ariaLabel) {
      case 'unknownStats':
      case 'phraseTestTotals':
        return this.getStats(langId, event.tab.ariaLabel);
    }
  }

  public async getStats(langId: number, statName: 'metrics' | 'unknownStats' | 'fragmented' | 'topPhrases' | 'coverage' | 'phraseTestTotals' | 'throughput') {
    this.isLoading[langId.toString()] = this.isLoading[langId.toString()] || {};
    if (this.isLoading[langId.toString()][statName] === undefined) {
      try {
        this.isLoading[langId.toString()][statName] = true;
        this.stats = this.stats || {} as TestStats;

        let jsonCommonOptions: string = JSON.stringify(this._commonChartOptions)

        this.abuseOptions[langId.toString()] = this.abuseOptions[langId.toString()] || JSON.parse(jsonCommonOptions);
        this.sentimentOptions[langId.toString()] = this.sentimentOptions[langId.toString()] || JSON.parse(jsonCommonOptions);
        this.entitiesOptions[langId.toString()] = this.entitiesOptions[langId.toString()] || JSON.parse(jsonCommonOptions);
        this.unknownOptions[langId.toString()] = this.unknownOptions[langId.toString()] || JSON.parse(jsonCommonOptions);

        const currentLangId = this.sessionService.session.targetLanguage && this.sessionService.session.targetLanguage.id;
        if (currentLangId != langId) {
          await this.languageService.setLanguage(langId);
        }

        switch (statName) {
          case 'metrics':
            const metrics: TestMetric[] = await this._service.metrics('');
            this.setChartMetricSeries(this.abuseChart[langId.toString()], this.abuseOptions[langId.toString()],
              metrics, 'abuse', 'TEST.abuse');

            this.setChartMetricSeries(this.sentimentChart[langId.toString()], this.sentimentOptions[langId.toString()],
              metrics, 'sentiment_expressions', 'TEST.sentiment');

            this.setChartMetricSeries(this.entityChart[langId.toString()], this.entitiesOptions[langId.toString()],
              metrics, 'entity', 'TEST.entities');
            break;
          case 'unknownStats':
            const unknown: OneLevelDateStat[] = await this._service.unknownWords('');
            this.unknownOptions[langId.toString()].markLine = this.createMarkLine(7,
              this.translateService.instant('TEST.unknown-very-high-amount'));

            this.setChartSeries(this.unknownOptions[langId.toString()], unknown, 'TEST.unknown-share', this.unknownChart[langId.toString()]);
            break;

          case 'phraseTestTotals':
            const result: PhraseTestTotals = await this._service.phraseTestTotals('');
            this.failedPhraseTestCount[langId.toString()] = result.failedPhraseTests;
            this.totalPhraseTests[langId.toString()] = result.totalPhraseTests;
            break;
        }

        if (currentLangId && currentLangId != langId) {
          await this.languageService.setLanguage(currentLangId)
        }
      } catch (error) {
        console.error(error.message || error);
      } finally {
        this.isLoading[langId.toString()][statName] = false;
      }
    }
  }

  private createMarkLine(y: number, label?: string): ChartMarkline {
    let ml: ChartMarkline = JSON.parse(JSON.stringify(this._defaultMarkLine));
    ml.data[0].yAxis = y;
    if (label)
      ml.label.formatter = label;
    return ml;
  }

  public resultText(fragment: string, r: TestResult, name: string, maxLen?: number): string {
    return this._service.resultText(fragment, r, name, maxLen);
  }

  public verbEmoji(verb: string): string {
    switch (verb.toUpperCase()) {
      case 'PUT': return '📝';
      case 'POST': return '➕';
      case 'DELETE': return '➖';
      default: return verb;
    }
  }

  initThisSubtypeFromSession(): void {
    this.loadData({ key: '', value: '', description: '' });
  }

  protected setChartSeries(options: ChartOptions, series: OneLevelDateStat[], seriesName: string, chartInstance): void {
    let dates: string[] = [];
    let yValues: number[] = [];
    for (let sr of series) {
      dates.push(sr.Key);
      yValues.push(sr.Value.key / sr.Value.value * 100);
    }
    if (chartInstance && dates.length > 0 && yValues.length > 0) {
      this.setChartData(options, dates, yValues, seriesName);

      //prevent markline out of chart
      const maxPoint = _.max(yValues);
      if (options.markLine) {
        const maxMarkline = _.max(options.markLine.data, 'yAxis').yAxis;
        if (maxMarkline > maxPoint) {
          options.series.push({
            name: '',
            type: 'line',
            data: [maxMarkline * 1.2],
            symbolSize: 0,
            tooltip: {
              show: false
            }
          })
        }
      }

      chartInstance.setOption(options);
    }
  }

  protected setChartThroughputSeries(options: ChartOptions, series: ThroughputMetric[], seriesName: string, chartInstance): void {
    let dates: string[] = [];
    let yValues: number[] = [];
    for (let sr of series) {
      dates.push(sr.Key);
      yValues.push(sr.Value);
    }
    if (chartInstance && dates.length > 0 && yValues && yValues.length > 0) {
      this.setChartData(options, dates, yValues, seriesName);
      chartInstance.setOption(options);
    }
  }

  public topPhraseClick($event): void {
    alert($event);
  }


  protected setChartMetricSeries(targetChart, options: ChartOptions,
    series: TestMetric[], fn: string, chartTitle: string): void {
    let dates: string[] = [];
    let accuracy: number[] = [];
    let f1: number[] = [];
    let precision: number[] = [];
    let recall: number[] = [];
    for (let sr of series) {
      dates.push(sr.Key);
      for (let fs of sr.Value) {
        if (fs.function === fn) {
          accuracy.push(fs.accuracy * 100);
          f1.push(fs.f1 * 100);
          precision.push(fs.precision * 100);
          recall.push(fs.recall * 100);
        }
      }
    }

    switch (fn) {
      case 'abuse':
        this.abuseDates = dates;
        break;
      case 'sentiment':
      case 'sentiment_expressions':
        this.sentimentDates = dates;
        break;
      case 'entities':
      case 'entity':
        this.entityDates = dates;
        break;
    }
    //this.sliderRefresh.next();

    this.pushXAxis(options, dates);

    options.series = [];
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.precision'), precision));
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.recall'), recall));
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.f1-score'), f1));
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.accuracy'), accuracy));
    if (targetChart) {
      targetChart.setOption(options);
    }
  }

  /**
   * Chart lib will crash when it dont have value of xAxis so we added an value when init chart
   * and remove it when have the real data to load to chart
   */
  private pushXAxis(options: ChartOptions, dates: string[]) {
    if (options.xAxis.length == 1 && options.xAxis[0].data.length == 0) {
      options.xAxis = [];
    }
    options.xAxis.push(this.createCharXAxis(dates));
  }

  protected createCharXAxis(data: string[]): ChartXAxis {
    let newXAxis: ChartXAxis = { type: 'category', data: data };
    return newXAxis;
  }

  protected createChartYSeries(name: string, data: number[]): ChartYSeries {
    let newSeries: ChartYSeries = {
      type: 'line',
      //stack: 'counts',
      name: name,
      data: data
    };
    return newSeries;
  }

  protected setChartData(options: ChartOptions, dates: string[], series: number[], seriesName: string): void {
    this.pushXAxis(options, dates);
    options.xAxis.push(this.createCharXAxis(dates));
    let srs = this.createChartYSeries(this.translateService.instant(seriesName),
      series);
    if (options.markLine)
      srs.markLine = options.markLine;
    options.series.push(srs);
  }

  public linkChartInstance($event, targetChart) {
    targetChart = $event;
  }

  protected filterSettingName(): string {
    return 'teststats'; // used to save and restore the filter
  }


  public options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: [
      {
        type: '%'
      }
    ],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false
        //,data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] // dates
      }
    ],
    legend: {
      data: ['Precision', 'Recall', 'F1', 'Accuracy', 'X-5']
    },
    series: [
      {
        name: 'Precision',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [120, 132, 101, 134, 90, 230, 210]
      },
      {
        name: 'Recall',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [220, 182, 191, 234, 290, 330, 310]
      },
      {
        name: 'F1',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [150, 232, 201, 154, 190, 330, 410]
      },
      {
        name: 'Accuracy',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [320, 332, 301, 334, 390, 330, 320]
      },
      {
        name: 'X-5',
        type: 'line',
        stack: 'counts',
        label: {
          normal: {
            show: true,
            position: 'top'
          }
        },
        areaStyle: { normal: {} },
        data: [820, 932, 901, 934, 1290, 1330, 1320]
      }
    ]
  };

  escapeText(input: string | number = ''): string {
    return `"${input.toString().replace(/\"/g, '""')}"`;
  }
}