import {emptyFeatureValue, FeatureValue, ModelWithRelevantFeature} from './feature';
export interface KnowledgeGraphLink { id: number; description: string; warn?: boolean; features?: string; discouraged?: boolean; unverified?: 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle'; }

export interface KnowledgeGraphElement {
  antonyms?: KnowledgeGraphLink[];
  definition?: string;
  description?: string;
  derived?: KnowledgeGraphLink[];
  domains?: KnowledgeGraphLink[];
  domainMembers?: KnowledgeGraphLink[];
  featureList?: string;
  hypernyms?: KnowledgeGraphLink[];
  hyponyms?: KnowledgeGraphLink[];
  id: number;
  inheritedFeatureList?: string;
  lexemes?: { id: number, lemma: string, features?: string, unverified: 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle' }[];
  phrases?: string[];
  lexemeList?: string;
  properNoun?: boolean;
  representativeLemma?: string;
  translationUrl?: string;
  wikidata?: string;
  phraseTag?: string;
  discouraged?: boolean;
}

export interface Family extends ModelWithRelevantFeature {
  id: number;
  definition?: string;
  description?: string;
  warn?: boolean;
  advancedCriteriaDescription?: string;
  LFUnverified?: 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle';
}

export enum LinksTypes {
  hypernyms = 'hypernyms',
  domains = 'domains',
  hyponyms = 'hyponyms',
  domainMembers = 'domainMembers',
  derivedItems = 'relatedConcepts',
  antonyms = 'antonyms'
}

export interface FamilyDefinition extends ModelWithRelevantFeature {
  customId?: string | number; // Custom ID.	A short text field with code representing a custom external ID.
  definition?: string; // A longer note describing the family. A multiline text field.
  description?: string; // A short note (single line) describing the family (empty pretty much everywhere, it's a new column)
  earliestMention?: string; // Date of the earliest mention.	A date when the concept first emerged, or an empty string.
  fallback?: string;
  // Fallback policy	Describes how the family handles absence of data in a particular language.
  // Possible values: onlyIfEmpty / complement / never / insertEmptyWord / literalEquivalent
  frequency?: number; // Frequency grade.	A number in range 0 through 10, or an empty string.
  grammar?: FeatureValue[]; // A set of grammar features (type=Grammar) A standard feature editing array.
  grammarCopyToHyponyms?: boolean;
  // Whether the grammar features are to be inherited by the hyponym families.
  // A checkbox embedded in or close to the grammar editing control.
  iab?: string; // IAB code.	A single feature editing UI (type: Semantic, index: 25).*/
  id?: number; // Language-independent ID of the family
  iptc?: string; // IPTC code.	A single feature editing UI (type: Semantic, index: 24).
  lastYearActive?: number; // 	Last year when the concept was in active use.	A valid year, only this era (CE).
  numericDomain?: FeatureValue; // Numeric domain.	A single feature editing UI selecting a semantic feature.
  phraseTag?: string; // Phrase linguistic tag.	A short text field with code representing the type of the phrase.
  phraseType?: string;
  // Type of the phrase family.
  // A set of choices for phrase families:
  // (empty string)=not a phrase, regular=regular phrase, list=list phrase,
  // mainClause=main clause, connector=connector phrase
  properNounHyponymEntities?: boolean; // Whether features in general are to be propagated
  properNoun?: boolean; // Is proper noun? (checkbox)	A toggle determining whether the family is a proper noun
  semanticFeatures?: FeatureValue[]; // A set of semantic features (type=Semantics)	A standard feature editing array.
  semanticsCopyToHyponyms?: boolean;
  // Whether the semantic features are to be inherited by the hyponym families.
  // 	A checkbox embedded in or close to the semantic features editing control.
  value?: string | number; // Numeric value.	A valid numeral in the US English format, or an empty string. !!!!!!!!!!!
  valueRange?: string | number;
  wikidataId?: string; // Wikidata ID.	A short text field with code representing the Wikidata ID.
  wordnetId?: string; // WordNet 3.0 ID.	A short text field with code representing the WordNet 3.0 ID.
  translationUrl?: string;
  obscure?: boolean;
  lexemesInLanguages?: string;
  phrasesInLanguages?: string;
  /**
   * true if the phrases are to complement the ones in the fallback language
   */
  complement?: boolean;
  localized?: boolean;
}

export const emptyAdvancedFamily = {
  customId: '',
  definition: '',
  description: '',
  earliestMention: '',
  fallback: '',
  frequency: '',
  grammar: [],
  grammarCopyToHyponyms: false,
  iab: '',
  iptc: '',
  lastYearActive: '',
  numericDomain: emptyFeatureValue,
  phraseTag: '',
  phraseType: '',
  properNounHyponymEntities: false,
  properNoun: false,
  semanticFeatures: [],
  semanticsCopyToHyponyms: false,
  value: '',
  wikidataId: '',
  wordnetId: '',
};

export const phraseType = [
  {value: '', description: 'Not a phrase'},
  {value: 'regular', description: 'Regular phrase'},
  {value: 'list', description: 'List phrase'},
  {value: 'mainClause', description: 'Main clause'},
  {value: 'connector', description: 'Connector phrase'}
];

export const fallbackPolicy = [
  {value: 'onlyIfEmpty', description: 'Only if empty'},
  {value: 'complement', description: 'Complement'},
  {value: 'never', description: 'Never fall back'},
  {value: 'insertEmptyWord', description: 'Insert empty word'},
  {value: 'literalEquivalent', description: 'Copy literal equivalent'}
];
