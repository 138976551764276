import {Component, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {ExtraHypothesis, emptyHypothesis} from '../../../shared/models/extra-hypothesis';
import {toJsonObject} from '../../../shared/helpers/object.helper';
import {ExtraHypothesisService} from '../../../shared/services/extra-hypothesis.service';
import {LampUpdateResponse} from '../../../shared/models/common';
import {RouterHelper} from '../../../shared/helpers/router.helper';
import {applicationModulesRoutePaths} from "../../../shared/constants/application-constants";

@Component({
  selector: 'app-extra-hypothesis-edit',
  templateUrl: 'extra-hypothesis-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraHypothesisEditComponent {
  public hypothesis: ExtraHypothesis;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public showProgressBar: boolean;

  constructor(private extraHypothesisService: ExtraHypothesisService,
              private routerHelper: RouterHelper,
              private changeDetectionRef: ChangeDetectorRef) {
  }

  public getExtraHypothesisById(hypothesisId: number): Promise<void> {
    if (hypothesisId) {
      return this.extraHypothesisService.getExtraHypothesisById(hypothesisId)
        .then((hypothesis: ExtraHypothesis) => {
          this.hypothesis = toJsonObject(hypothesis) as ExtraHypothesis;
          this.changeDetectionRef.markForCheck();
        });
    } else {
      return Promise.resolve().then(() => {
        this.hypothesis = toJsonObject(emptyHypothesis) as ExtraHypothesis;
        this.changeDetectionRef.markForCheck();
      });
    }
  }

  public save(changes): void {
    this.showProgressBar = true;
    changes = (changes as ExtraHypothesis);
    if (changes.id) {
      this.extraHypothesisService.getExtraHypothesisById(changes.id)
        .then((extraHypothesis: ExtraHypothesis) => {
          this.extraHypothesisService.updateExtraHypothesis(changes)
            .then((message: LampUpdateResponse) => this.reactOnSuccessfullResponseMessage(message));
        });
    } else {
      this.extraHypothesisService.createExtraHypothesis(changes)
        .then((message: LampUpdateResponse) => this.reactOnSuccessfullResponseMessage(message));
    }
  }

  public reactOnSuccessfullResponseMessage(message: LampUpdateResponse): void {
    this.showProgressBar = false;
    if (message.success) {
      this.back();
    } else {
      this.errorMessageBehaviorSubject.next(message.error);
    }
  }

  private back(): void {
    this.routerHelper.back();
  }

}
