import {Component, OnInit, OnDestroy} from '@angular/core';
import {SessionService} from '../../shared/services/session.service';

import {Subscription} from 'rxjs/Subscription';

import {LanguageService} from '../../shared/services/language.service';
import {Language} from '../../shared/models/language';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.less']
})
export class LanguageSelectionComponent implements OnInit, OnDestroy {
  private defaultLanguageId: number;

  public selectedLanguage: number;
  public languagesList: Array<Language> = [];
  public language: Language;

  constructor(
    private sessionService: SessionService,
    private languageService: LanguageService,
    private localStorageHelper: LocalStorageHelper
  ) {
  }

  ngOnInit(): void {
    this.defaultLanguageId = this.sessionService.session.targetLanguage.id;
    this.getLanguagesList();
  }

  private getLanguagesList(): void {
    this.languageService.getLanguagesList().then((languagesList: Array<Language>) => {
      this.languagesList = languagesList as Array<Language>;
      this.selectedLanguage = this.defaultLanguageId;
    });
  }

  public onLanguageChange(): void {
    this.language = this.languagesList.find((language: Language) => (language.id === this.selectedLanguage));
    if (this.language) {
      this.localStorageHelper.setGlobalSetting('currentLanguage', JSON.stringify(this.language));
 
      let self = this;
      this.languageService.setLanguage(this.language.id)
        .then(() => {
          self.sessionService.refresh();
        });
   }
  }

  ngOnDestroy(): void {
  }
}
