import {Directive, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {FamilySelectionDialogComponent} from './family-selection-dialog.component';
import {Family} from '../../../shared/models/knowledge-graph';


@Directive({selector: '[appOpenFamilySelectionDialog]'})

export class ButtonOpensFamilySelectionDialogDirective {
  @Input() chooseOnlyOneFamily: boolean;
  @Input() familyId: number;
  @Input() byText: string;
  @Output() beforeDialogOpenedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedDataEvent: EventEmitter<{ commaDelimitedListOfFamilyIds: string, family: Family }> =
    new EventEmitter<{ commaDelimitedListOfFamilyIds: string, family: Family }>();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    this.beforeDialogOpenedEvent.emit(true);
    const dialogRef: MatDialogRef<any> = this.dialog.open(FamilySelectionDialogComponent, {
      minWidth: this.isMobileView ? '95%' : '80%',
      data: {
        chooseOnlyOneFamily: this.chooseOnlyOneFamily, familyId: this.familyId, byText: this.byText
      }
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });


    dialogRef.afterClosed()
      .subscribe((data: { commaDelimitedListOfFamilyIds: string, family: Family }) => {
        this.selectedDataEvent.emit(data);
      });
  }
}
