import {Directive, HostListener, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {MatDialog} from '@angular/material';

import {AdvancedCriteriaEditDialogComponent} from './advanced-criteria-edit-dialog.component';

@Directive({
  selector: '[appAdvancedCriteriaOpenEditDialog]'
})
export class AdvancedCriteriaOpenEditDialogDirective {

  @Output() updateDataEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() isEdit: boolean;
  @Input() currentId: number;
  @Input() forWords: boolean;
  @Input() isCopying: boolean;

  private isMobileView: boolean;

  constructor(
    public dialog: MatDialog,
    public changeDetector: ChangeDetectorRef
  ) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(
      AdvancedCriteriaEditDialogComponent,
      {
        minWidth: this.isMobileView ? '95%' : '70%',
        data: {
          isEdit: this.isEdit,
          currentId: this.currentId,
          forWords: this.forWords,
          isCopying: this.isCopying,
        },
        id: 'advancedCriteriaEditDialog'
      }
    );
    this.changeDetector.detectChanges();

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.updateDataEvent.emit(true);
        }
      });
  }

}
