import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import {Phoneme, PhonemeAlias} from '../models/phoneme-sign';
import {ApiClientService} from './api-client.service';
import {LampUpdateResponse} from '../models/common';

@Injectable()
export class PhonemesService {

  constructor(private apiClientService: ApiClientService) {
  }

  public getPhonemesList(type: string, arg: string | number): Promise<any> {
    return this.apiClientService
      .get(`${EndpointUrls.phonemes.phonemeList}?type=${type}&arg=${arg}`)
      .then((phonemeList) => {
        return (phonemeList as Array<Phoneme>);
      });
  }

  public getPhonemeById(phonemeId: number): Promise<Phoneme> {
    return this.apiClientService
      .get(`${EndpointUrls.phonemes.phoneme}?id=${phonemeId}`)
      .then((phoneme) => {
        return (phoneme as Phoneme);
      });
  }

  public updatePhoneme(phoneme: Phoneme): Promise<LampUpdateResponse> {
    return this.getPhonemeById(phoneme.id).then(() => {
      return this.apiClientService
        .put(EndpointUrls.phonemes.phoneme, phoneme)
        .then((resultMessage: LampUpdateResponse) => {
          return resultMessage;
        });
    });
  }

  public createPhoneme(phoneme: Phoneme): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.phonemes.phoneme, phoneme)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deletePhoneme(phonemeId: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.phonemes.phoneme}?id=${phonemeId}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public getPhonemeAliasList(phonemeId: number): Promise<PhonemeAlias[]> {
    return this.apiClientService
      .get(`${EndpointUrls.phonemes.phonemeAliasList}?phoneme=${phonemeId}`)
      .then((phonemeAliasList) => {
        return (phonemeAliasList as Array<PhonemeAlias>);
      });
  }

  public getPhonemeAliasById(phonemeAliasId: number): Promise<PhonemeAlias> {
    return this.apiClientService
      .get(`${EndpointUrls.phonemes.phonemeAlias}?id=${phonemeAliasId}`)
      .then((phonemeAlias) => {
        return (phonemeAlias as PhonemeAlias);
      });
  }


  public updatePhonemeAlias(phonemeAlias: PhonemeAlias): Promise<LampUpdateResponse> {
      return this.getPhonemeAliasById(phonemeAlias.id).then(() => {
        return this.apiClientService
          .put(EndpointUrls.phonemes.phonemeAlias, phonemeAlias)
          .then((resultMessage: LampUpdateResponse) => {
            this.getPhonemeAliasById(phonemeAlias.id).then();
            return resultMessage;
          });
      });
  }

  public createPhonemeAlias(phonemeAlias: PhonemeAlias): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.phonemes.phonemeAlias, phonemeAlias)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deletePhonemeAlias(phonemeAliasId: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.phonemes.phonemeAlias}?id=${phonemeAliasId}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

}
