import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {AuthenticationService} from '../../shared/services/authentication.service';
import {RouterHelper} from '../../shared/helpers/router.helper';

import {RouterAccompaniment} from '../../shared/services/router-accompaniment.service';
import {SessionService} from '../../shared/services/session.service';
import {Language} from '../../shared/models/language';
import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import {LanguageService} from '../../shared/services/language.service';
import {Translated} from '../../shared/classes/translated.class';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public credentialsForm: FormGroup;
  public isLoginError: boolean;
  public doesUserTrySubmitForm: boolean = false;
  public loginProgress: boolean = false;

  constructor(private authenticationService: AuthenticationService,
              private routerHelper: RouterHelper,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.createForm();
    this.hideErrorMessage();
  }

  private createForm(): void {
    this.credentialsForm = this.formBuilder.group({
      loginData: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public login(): Promise<void> {
    if (this.isFormValid()) {
      this.loginProgress = true;
      return this.authenticationService.login(
        this.credentialsForm.value.loginData as string,
        this.credentialsForm.value.password as string).then((isSuccessfulAuthentication) => {
        this.loginProgress = false;

        if (isSuccessfulAuthentication) {
          this.routerHelper.back();
          //this.routerHelper.navigateToWelcomePage();
        } else {
          this.showErrorMessage();
        }
      }, (error) => {
        this.loginProgress = false;
        console.error(error);

        this.showErrorMessage();
      }).catch((error) => {
        this.loginProgress = false;
        console.error(error);

        this.showErrorMessage();
      });
    }
  }

  public showErrorMessage(): void {
    this.isLoginError = true;
  }

  public hideErrorMessage(): void {
    this.isLoginError = false;
  }

  public isFormValid(): boolean {
    return (this.credentialsForm.valid) && (this.credentialsForm.touched);
  }

  public checkIsFormValid(): void {
    this.doesUserTrySubmitForm = true;
  }

  public onInputClick(): void {
    this.doesUserTrySubmitForm = false;
  }

  public signInAsGuest(): Promise<void> {

    this.loginProgress = true;
    return this.authenticationService.loginAsAGuest().then((isSuccessfulAuthentication) => {
      this.loginProgress = false;

      if (isSuccessfulAuthentication) {
        this.routerHelper.back();
      } else {
        this.showErrorMessage();
      }
    }, (error) => {
      this.loginProgress = false;
      console.error(error);

      this.showErrorMessage();
    }).catch((error) => {
      this.loginProgress = false;
      console.error(error);

      this.showErrorMessage();
    });
  }

  ngOnDestroy() {
    /*
    const languageFromLocalStorage = this.localStorageHelper.getGlobalSetting('currentLanguage');
    if (languageFromLocalStorage && (languageFromLocalStorage !== 'undefined') &&
      (languageFromLocalStorage !== 'null')) {
      const currentLanguage: Language = JSON.parse(languageFromLocalStorage);
      if (currentLanguage && currentLanguage.id) {
        this.sessionService.updateLanguage(currentLanguage);
        this.languageService.setLanguage(currentLanguage.id).then();
      }
    }
    */
  }
}
