<div class="ti-universal-feature__wrapper">
  <span class="ti-universal-feature__block feature-selector">
    <app-universal-feature-selector-ids
      #featureSelect
      *ngIf="!preselectedIndex"
      [relevantFeatureList]="relevantFeatureList"
      [featureValue]="featureValue"
      [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
      [setDefaultValues]="setDefaultValues"
      [isEmptyValueRelevant]="isOnlyOneFeature && isEmptyValueRelevant"
      (featureChosenIndexEvent)="saveCurrentFeatureId($event)"
      [disableSelect]="disableSelect"
      [emitChooseCurrentFeature]="emitChooseCurrentFeature"
      (clearFeatureOptionEvent)="clearSelectionEvent.emit($event)"
    ></app-universal-feature-selector-ids>
    <app-universal-feature-selector-options
      [ngClass]="preselectedIndex? 'ti-universal-feature-selector-options--preselected-index' : 'ti-universal-feature-selector-options'"
      [relevantFeatureList]="relevantFeatureList"
      [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
      [setDefaultValues]="setDefaultValues"
      [currentFeature]="currentFeature"
      [currentFeatureOptionId]="currentFeatureOptionId"
      (featureChosenOptionEvent)="saveCurrentFeatureOption($event)"
      [isEmptyValueRelevant]="isOnlyOneFeature && isEmptyValueRelevant"
      [disableSelect]="disableSelect"
      (clearFeatureOptionEvent)="clearSelectionEvent.emit($event)"
    ></app-universal-feature-selector-options>
  </span>

  <span class="ti-universal-feature__block">
    <mat-checkbox
        class="ti-universal-feature__custom-flag"
        *ngIf="checkboxLabel && checkboxAttributeName && !disableSelect"
        matInput
        (change)="chooseCurrentFeatureOption()"
        [(ngModel)]="customFlag">
        <span>{{checkboxLabel}}</span>
      </mat-checkbox>

    <button *ngIf="!isOnlyOneFeature && !disableSelect" mat-mini-fab color="primary"
      (click)="removePairOfControls()"
    >
      <mat-icon aria-hidden="true">delete</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="primary"
      (click)="clearSelection()"
      *ngIf="buttonVisibility()"
    >
      <mat-icon aria-hidden="true">replay</mat-icon>
    </button>
  </span>
</div>
