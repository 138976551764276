import {Component, OnDestroy, OnInit, AfterContentChecked} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {LexiconService} from '../shared/services/lexicon.service';
import {Lexeme} from '../shared/models/lexicon';
import {applicationConstants, commonTabSettings} from '../shared/constants/application-constants';
import {LocalStorageHelper} from '../shared/helpers/localhost.helper';
import { RouterHelper } from '../shared/helpers/router.helper';
import * as _ from 'lodash';

type LexemeStorage = {
  parameters: { key: string; value: string | number },
  lexemesList: Lexeme[]
};

@Component({
  selector: 'app-lexicon',
  templateUrl: './lexicon.component.html',
  styleUrls: ['./lexicon.component.less']
})
export class LexiconComponent implements OnInit, OnDestroy, AfterContentChecked  {
  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();
  public lexemesList: Lexeme[] = [];
  public filteredLexemesList: Lexeme[];
  public showingLexemesList: Lexeme[];
  public totalWordSenses = 0;
  public expandedLexemeId: number;
  public isNoFullRefresh = true;

  public doesUserHaveAccessToEdit: boolean;

  public showSpinner: boolean = false;
  public selectedLexemeId: number = undefined;
  public selectedIndexSettingName = "lexicon_" + commonTabSettings.idIndexKey;

  private scrolledAfterLoad: boolean = false;
  private $event: { key: string, value: string | number };
  private lastLexiconRequest: any;
  private routeSubscription: Subscription;
  
  //localstorage key
  private listLexemeKey = "listLexemes";
  private refreshStatusKey = "lexemeNoFullRefreshStatus";
  private createdLexemeKey = "newCreatedLexemeId";

  private lexemePath = "/lexicon/lexeme/";
  private familyPath = "/lexicon/lexeme-family-connection/";  

  public cssClassOpenAccordion: string = commonTabSettings.changedPreviouslySelector;

  //refine filter function
  public featureDescription: string;

  constructor(private lexiconService: LexiconService,
              private route: ActivatedRoute, private localStorageHelper: LocalStorageHelper,
              private router: Router,
              private routerHelper: RouterHelper) {
  }

  ngAfterContentChecked(): void {
    if (!this.scrolledAfterLoad && this.lexemesList && this.lexemesList.length > 5) {
      let el = document.getElementById('selectedLexeme');
      if (el) {
        el.scrollIntoView();
        this.scrolledAfterLoad = true;
     }
    }
  }

  ngOnInit(): void {
    if (window.event) {
      window.event.stopPropagation();
    }
    this.loadSelectedLexemeId();
    this.isNoFullRefresh = this.localStorageHelper.getTabSetting(this.refreshStatusKey) !== 'NO';
    this.routeSubscription = this.route
      .queryParams
      .subscribe(routeParameters => {
        const parameters = routeParameters as { key: string, value: string | number };
        if (parameters.key && parameters.value) {
          this.$event = parameters;
          this.requireUpdatedEvent();
        }
      });
  }

  ngOnDestroy(): void {
    this.localStorageHelper.clearTabSetting(this.selectedIndexSettingName);
    this.lastLexiconRequest = undefined;
    this.saveCurrentState();
    this.routeSubscription.unsubscribe();
  }

  public filter(searchString) {
    searchString = (searchString || '').trim().toLowerCase();
    const originalList: Lexeme[] = _.cloneDeep(this.lexemesList);
    if (searchString.length == 0) {
      this.filteredLexemesList = null;
    } else {
      this.filteredLexemesList = originalList.filter(lex => {
        if (lex.dictionaryTag && lex.dictionaryTag.toLowerCase().indexOf(searchString) > -1)
          return true;

        // if (lex.featureIdList && searchString.indexOf('=') > -1) {
        //   return lex.featureIdList.toLowerCase().indexOf(searchString) > -1;
        // }
        return false;
      })
    }
    this.loadMore(true);
  }

  private saveCurrentState(forceSave = false) {
    if(this.isNoFullRefresh){
      const nextPath = this.router.routerState.snapshot.url;          
      if (nextPath.indexOf(this.lexemePath) > -1 || nextPath.indexOf(this.familyPath) > -1 || forceSave) {
        const storageData: LexemeStorage = {
          parameters: this.$event,
          lexemesList: this.lexemesList
        };
        this.localStorageHelper.setTabSetting(
          this.listLexemeKey,
          JSON.stringify(storageData)
        );
        return;
      }
    }
    this.localStorageHelper.clearTabSetting(this.listLexemeKey);
    this.localStorageHelper.clearTabSetting(this.createdLexemeKey);
  }

  public resetMorphology(): void {
    this.lexiconService.resetMorphology();
    this.lastLexiconRequest = undefined;

    this.localStorageHelper.clearTabSetting(this.listLexemeKey);
  }

  /**
   * Retags the lexemes currently displayed.
   * WARNING: currently extremely slow, because it's mostly on the client. 
   */
  public async retag($event): Promise<void> {
    if (!this.lastLexiconRequest)
      return undefined;
    
    if (!confirm($event))
      return undefined;

    this.showSpinner = true;
    let updated: number = await this.lexiconService.retag(this.lastLexiconRequest.key, this.lastLexiconRequest.value);
    if (updated === 0)
    {
      this.showSpinner = false;
      return;
    }

    return this.lexiconService.getLexicon(this.lastLexiconRequest.key, this.lastLexiconRequest.value)
      .then((lexemesList: Lexeme[]) => {
        this.showSpinner = false;

        this.lexemesList = lexemesList;
        this.loadMore(true);
        this.totalWordSenses = lexemesList.map(lex => (lex.families || []).length).reduce((a, b) => a + b, 0);
      });
  }

  public async getLexicon($event: { key: string, value: string | number }): Promise<void> {
    //reset top search box
    this.featureDescription = '';
    this.filteredLexemesList = null;

    if (!$event || this.lastLexiconRequest 
        && this.lastLexiconRequest.key === $event.key
        && this.lastLexiconRequest.value.toString() === $event.value.toString())
      return;
    
      if(this.isNoFullRefresh && this.isValidLocalStorage()){        
        return;        
      }

    return this.queryApi($event);
}

  private queryApi($event: { key: string; value: string | number; }) {
    this.lexemesList = undefined;
    this.showingLexemesList = [];
    this.totalWordSenses = 0;
    this.showSpinner = true;
    this.$event = $event;
    this.lastLexiconRequest = $event;

    return this.lexiconService.getLexicon($event.key, $event.value)
      .then((lexemesList: Lexeme[]) => {
        this.showSpinner = false;
        this.scrolledAfterLoad = false;
        this.lexemesList = lexemesList;
        this.totalWordSenses = lexemesList.map(lex => (lex.families || []).length).reduce((a, b) => a + b, 0);
        this.loadMore(true);
        this.saveCurrentState(true);
      });
  }

private isValidLocalStorage(): boolean {  
  const prevUrl = this.routerHelper.getPreviousUrl();  
  const validNavUrl = prevUrl.indexOf(this.lexemePath) > -1 || prevUrl.indexOf(this.familyPath) > -1;
  
  let storageStr = this.localStorageHelper.getTabSetting(
    this.listLexemeKey
  );
  if (storageStr && storageStr.length > 0 && this.$event) {
    const { lexemesList, parameters } = JSON.parse(storageStr) as LexemeStorage;
    const isMatchedFilterings = (lexemesList || []).length > 0 && parameters.key == this.$event.key && parameters.value == this.$event.value;    

    if (isMatchedFilterings) {
      return true
    }
  }
  this.localStorageHelper.clearTabSetting(this.listLexemeKey);
  return false;
}

/**
 * load local storage to this.lexemesList
 * @returns true if get list lexeme successfully else return false
 */
loadLocalStorage(): boolean {
  this.lexemesList = [];
  if (this.isNoFullRefresh && this.isValidLocalStorage()) {
    let storageStr = this.localStorageHelper.getTabSetting(
      this.listLexemeKey
    );
    if (storageStr && storageStr.length > 0) {
      const { lexemesList, parameters } = JSON.parse(storageStr) as LexemeStorage;
      if (
        lexemesList &&
        lexemesList.length > 0 &&
        parameters.key == this.$event.key &&
        parameters.value == this.$event.value
      ) {
        if (lexemesList && lexemesList.length > 0) {
          this.lexemesList = lexemesList;
          this.loadMore(true);
        }
      }
    }
  }
  return this.lexemesList.length > 0;
}

  public async requireUpdatedEvent(lexemeId?: number) {
    if (lexemeId) {
      this.selectedLexemeId = lexemeId;
    }
    if(this.isNoFullRefresh && this.isValidLocalStorage()){
      //load current list and update new lexeme to list
      this.loadLocalStorage();
      lexemeId = lexemeId || this.getWorkingId();
      if (lexemeId) {
        const lexicon = await this.getLexiconById(lexemeId);
        if (lexicon) {
          const foundIndex = this.lexemesList.findIndex(x => x.id === lexemeId);
          if (foundIndex > -1) {
            this.lexemesList[foundIndex] = lexicon;
            const showingLexemeIndex = this.showingLexemesList.findIndex(x => x.id == lexemeId);
            this.showingLexemesList[showingLexemeIndex] = lexicon;
          } else {
            this.lexemesList.push(lexicon);
            this.showingLexemesList.push(lexicon);
          }
        }
      }
    } else {
      this.queryApi(this.$event);
    }    
  }

  private getWorkingId(): number {
    const prevUrl = this.routerHelper.getPreviousUrl();

    //just edited the lexeme or family connection
    const matches = prevUrl.match(/\/?editedId=\d+/g); //["editedId=4036591"]
    const updateId =
      !prevUrl.match(/\/copy/g) && matches && matches.length > 0 && matches[0].split("=").length >= 1
        ? matches[0].split("=")[1]
        : null;
    if (updateId) {
      return Number.parseInt(updateId);
    } else {
      //just created or copy lexeme
      let matches = prevUrl.match(/\/create|\/copy/g);
      if(matches && matches.length > 0) {
        return Number.parseInt(this.localStorageHelper.getTabSetting(this.createdLexemeKey));
      }
    }
    return null;
  }

  private async getLexiconById(lexemeId: number) {
    const results = await this.lexiconService.getLexicon("id", lexemeId);
    return results && results.length === 1 ? results[0] : null;
  }

  private getCashedIndex(indexName: string): string {
    return this.localStorageHelper.getTabSetting(indexName);
  }

  public inflections(current: Lexeme): string {
    const MAX_INFLECTIONS_LENGTH: number = 50;
    if (current.inflections && current.inflections.length > MAX_INFLECTIONS_LENGTH)
      return current.inflections.substr(0, MAX_INFLECTIONS_LENGTH) + '...';
    else
      return current.inflections;
  }

  public loadSelectedLexemeId(): void {
    this.selectedLexemeId = parseInt(this.getCashedIndex(this.selectedIndexSettingName), 10);
  }

  public showErrorMessage(error?: string) {
    if (!error) {
      error = '';
    }
    this.errorMessageBehaviorSubject.next(error);
  }

  public loadMore(resetScroll = false) {
    this.showingLexemesList = resetScroll || !this.showingLexemesList ? [] : this.showingLexemesList;
    const lexemesList = this.filteredLexemesList || this.lexemesList || [];
    const startAt = this.showingLexemesList.length > 0 ? this.showingLexemesList.length : 0;
    this.showingLexemesList = this.showingLexemesList.concat(lexemesList.slice(startAt, startAt + applicationConstants.loadmoreItem));
  }

  public deleteLexeme(lexemeId: number){
    if(this.isNoFullRefresh){
      this.lexemesList = this.lexemesList.filter(lexeme => lexeme.id !== lexemeId);
      this.showingLexemesList = this.showingLexemesList.filter(lexeme => lexeme.id !== lexemeId);
    } else {
      this.requireUpdatedEvent();
    }
  }

  public deleteFamily(deletedObject: {lexemeId: number; familyId: number}){
    if(this.isNoFullRefresh){
      const lexemeIndex = this.lexemesList.findIndex(x => x.id == deletedObject.lexemeId);
      const latestFamily = this.lexemesList[lexemeIndex].families.filter(family => family.id !== deletedObject.familyId);
      this.lexemesList[lexemeIndex].families = latestFamily;

      const showingLexemeIndex = this.showingLexemesList.findIndex(x => x.id == deletedObject.lexemeId);
      this.showingLexemesList[showingLexemeIndex].families = latestFamily;
    } else {
      this.requireUpdatedEvent();
    }
  }  

  public changeNoFullRefreshStatus($event){
    this.localStorageHelper.setTabSetting(this.refreshStatusKey, $event.checked ? 'YES' : 'NO');
    this.saveCurrentState(true);
  }

  public requireReloadEvent(){
    this.queryApi(this.$event);
  }
}