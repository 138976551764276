import {Component, Input, OnInit} from '@angular/core';

import {PhonemesPosition} from '../../shared/models/phoneme-sign';

@Component({
  selector: 'app-phonemes-position',
  templateUrl: './phonemes-position.component.html',
  styleUrls: ['./phonemes-position.component.less']
})
export class PhonemesPositionComponent implements OnInit {
  public phonemesPosition: PhonemesPosition;
  @Input() position: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  isStartPosition(): boolean {
    return (this.position === PhonemesPosition.start);
  }

  isEndPosition(): boolean {
    return (this.position === PhonemesPosition.end);
  }

  isMiddlePosition(): boolean {
    return (this.position === PhonemesPosition.middle);
  }

  isAllExceptEndPosition(): boolean {
    return (this.position === PhonemesPosition.allExceptEnd);
  }

  isAllExceptStartPosition(): boolean {
    return (this.position === PhonemesPosition.allExceptStart);
  }

  isAllExceptMiddlePosition(): boolean {
    return (this.position === PhonemesPosition.allExceptMiddle);
  }
}
