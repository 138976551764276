import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

import {Family} from '../../../shared/models/knowledge-graph';

@Component({
  selector: 'app-family-selection-dialog',
  templateUrl: './family-selection-dialog.component.html',
  styleUrls: ['./family-selection-dialog.component.less']
})

export class FamilySelectionDialogComponent {
  public chooseOnlyOneFamily: boolean;
  public commaDelimitedListOfFamilyIds: string = '';
  public family: Family;
  public initialFamilyId: number;
  public byText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { chooseOnlyOneFamily: boolean, familyId: number, byText: string }) {
    this.chooseOnlyOneFamily = data.chooseOnlyOneFamily;
    this.initialFamilyId = data.familyId;
    this.byText = data.byText;
  }

  public saveFamilyIdsSelection(commaDelimitedListOfFamilyIds: string): void {
    this.commaDelimitedListOfFamilyIds = commaDelimitedListOfFamilyIds;
  }

  public saveFamily(family: Family): void {
    this.family = family;
  }
}
