import {Component, Output, EventEmitter} from '@angular/core';

import {LexiconService} from '../../shared/services/lexicon.service';
import {Root} from '../../shared/models/lexicon';

@Component({
  selector: 'app-root-lookup',
  templateUrl: 'root-lookup.component.html',
  styleUrls: ['root-lookup.component.less']
})
export class RootLookupComponent {

  @Output() selectItemEvent: EventEmitter<Root> = new EventEmitter<Root>();

  public selectedItemId: number;
  public roots: Root[];
  public displayedColumns: string[] = ['id', 'root', 'family', 'selected' ];
  public showProgressBar = false;

  constructor(
    private lexiconService: LexiconService,
  ) {}

  public reactOnChoosenParameterEvent(event: {key: string; value: string}): void {
    this.filter(event.value, event.key);
  }

  private filter(arg: string, type: string): void {
    this.showProgressBar = true;
    this.lexiconService.getRootList(arg, type)
      .then((rootList: Root[]) => {
        this.roots = rootList;
        this.showProgressBar = false;
      });
  }

  public selectItem(id: number): void {
    const currentRoot = this.roots.find(root => root.id === id);
    this.selectItemEvent.emit(currentRoot);
  }

}
