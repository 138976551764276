<div class="ti-universal-filter-panel">
  <form [formGroup]="filterForm" *ngIf="filterInputParametersNames">
    <!--
      TODO: change placeholder for text to COMMON.range-text-placeholder
    -->
    <ng-template ngFor let-filterInputParameterName [ngForOf]="filterInputParametersNames">
      <mat-form-field color="accent"
      [style.width.px]="getTextFieldWidth(filterInputParameterName)"
      >
        <input matInput
        [style.width.px]="getTextFieldWidth(filterInputParameterName)"
               color="accent"
               [formControlName]="filterInputParameterName"
               [name]="filterInputParameterName"
               [placeholder]="'COMMON.'+filterInputParameterName | translate"
               (keyup)="resetOtherFields(filterInputParameterName, $event)"
               (keydown.enter)="applyFilter($event)"
        />
      </mat-form-field>
    </ng-template>
  </form>
  <form>
    <ng-template ngFor let-filterSelectParameterName let-i="index" [ngForOf]="filterSelectParametersNames">
      <mat-form-field color="accent">
        <mat-select 
          matInput
          [placeholder]="'COMMON.'+filterSelectParameterName | translate"
          name="{{filterSelectParameterName}}"
          [(ngModel)]="selectValues[filterSelectParameterName]"
          ng-keydown="$event.stopPropagation()"
          (selectionChange)="resetOtherFields(filterSelectParameterName);"
        >
          <mat-option value="">{{rangeNoneText || '-- None --'}}</mat-option>
          <mat-option *ngFor="let option of filterSelectParametersValues" [value]="option.key">
            {{option.value | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </form>
  <button *ngIf="filterInputParametersNames"
          mat-raised-button color="accent"
          (click)="applyFilter()" [disabled]="!currentFormName">{{'COMMON.show' | translate}}</button>
  &nbsp;&nbsp;
  <button *ngIf="enabledCount" class="total-count"
          mat-raised-button color="accent"
          (click)="countEntries(totalEntriesDialog)" [disabled]="!currentFormName">
          <span>{{'COMMON.total-count' | translate}}</span>
          <mat-progress-bar *ngIf="isLoadingCount" color="accent" mode="buffer"></mat-progress-bar>
        
        </button>
  &nbsp;&nbsp;
  <button *ngIf="actionButtonText"
          mat-raised-button color="accent"
          matTooltip="{{actionButtonTooltip}}" [disabled]="!canEditLanguage || loadedRecordCount &lt; 1"
          (click)="handleActionButton()">{{actionButtonText}}</button>
</div>
<div class="ti-universal-filter-panel">
  <span class="ti-universal-counter-recent-id" *ngIf="recentIds && recentIds.length > 0">
    <label>{{'COMMON.recent' | translate}}</label>&nbsp;&nbsp;&nbsp;
    <span *ngFor="let currentRecent of recentIds">
      <a (click)="filterById($event)" id="r_{{currentRecent.id}}" title="{{currentRecent.description}}">{{currentRecent.id}}</a><span *ngIf="currentRecent !== recentIds[recentIds.length - 1]">&nbsp;|&nbsp;</span>
    </span>
  </span>
  <span class="ti-universal-counter-top-level" *ngIf="loadedRecordCount > 0">
    <label>
      {{ (loadedSubRecCount ? (loadedRecordCountLabel || 'COMMON.top-level-records') : 'COMMON.load-count') | translate }}&nbsp;
    </label><strong>{{ loadedRecordCount }}</strong>
  </span>
  <span *ngIf="loadedSubRecCount > 0">
    <label>
      /  {{ loadedSubRecCountLabel || 'COMMON.total-records' | translate }}&nbsp;
    </label><strong>{{ loadedSubRecCount }}</strong>
  </span>
</div>
<br/><br/>

<ng-template #totalEntriesDialog>
  <h2 mat-dialog-title>{{totalEntriesTitle}}</h2>
  <mat-dialog-content>
    {{'COMMON.total-count-of-entries-in-the-current-range' | translate}} {{totalEntries}}
  </mat-dialog-content>
  <mat-dialog-actions style="display: flex; justify-content: space-between;" class="ti-form__button-panel">
    <button mat-raised-button color="accent" [mat-dialog-close]="true">{{'COMMON.close' | translate}}</button>
  </mat-dialog-actions>
</ng-template>
