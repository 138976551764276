<div class="ti-information-wrapper">
  <div class="ti-form__one-line-fields ti-form__block">
    <div class="ti-form__lexeme-information">
      <span class="ti-lexeme-lemma">{{lexeme.lemma}}
        <span class="ti-lexeme-dictionary-tag">{{lexeme.dictionaryTag}}</span>
      </span>
      <span class="ti-text--small"><a [href]="'/lexicon?key=id&value=' + lexeme.id" title="{{'COMMON.open-new' | translate}}" target="_blank">[{{lexeme.id}}]</a>&nbsp;</span>
      <span *ngIf="lexeme.unverified && lexeme.unverified != 'verified'" class="ti-lexeme-dictionary-tag" matTooltip="{{lexeme.unverified}}">&nbsp;{{getUnverifiedEmoji(lexeme.unverified)}}</span>
      <button
        mat-mini-fab
        [routerLink]="'/lexicon/lexeme/edit/'+lexeme.id"
        [queryParams]="{editedId: lexeme.id}"
        color="primary">
        <mat-icon  aria-hidden="true">edit</mat-icon>
      </button>
      <button
        mat-mini-fab
        appOpenConfirmDialog
        (doesUserConfirmEvent)="deleteLexemeAfterUserConfirmation($event, lexeme.id)"
        color="primary"
      >
        <mat-icon  aria-hidden="true">delete</mat-icon>
      </button>
      <button
        mat-mini-fab
        [routerLink]="'/lexicon/lexeme/copy/'+lexeme.id"
        [queryParams]="{editedId: lexeme.id}"
        color="primary">
        <mat-icon  aria-hidden="true">file_copy</mat-icon>
      </button>
      <button mat-mini-fab *ngIf="!isCurrentLangIsRefLang" (click)="validateLexemeLinks(lexeme.id)"
        [matTooltip]="'lexicon.validate-lexeme-links' | translate"
        color="primary">
        <mat-icon aria-hidden="true">lightbulb</mat-icon>
      </button>
      <button mat-mini-fab color="primary" *ngIf="lexeme.unverified && lexeme.unverified != 'verified'"
        (click)="approveLexeme(lexeme)">
        <mat-icon aria-hidden="true">check</mat-icon>
      </button>
    </div>
    <div
      class="ti-form__half-line-fields"
      *ngIf="lexeme.duplicateWarning">
      <p class="ti-red-text">{{'lexicon.duplicate-lexeme-exists' | translate}}</p>
      <button
        mat-mini-fab
        color="primary"
        appOpenLexemeMergeDialog
        [duplicatesIds]="lexeme.duplicateIds"
        [currentLexeme]="lexeme"
        (beforeDialogClosedEvent)="sendMessageThatUpdateRequiredToParent()"
      >
        <mat-icon class="ti-merge-button" aria-hidden="true">merge_type</mat-icon>
      </button>
    </div>
  </div>
  <div class="ti-form__one-line-fields">
    <p class="ti-lexeme-inflections">{{lexeme.inflections}}</p>
    <div class="ti-form__button-panel">
      <button
        mat-mini-fab
        appOpenInflectionTableDialog
        [lexeme]="lexeme.lemma"
        [lexemeId]="lexeme.id"
        color="primary">
        <mat-icon aria-hidden="true">list</mat-icon>
      </button>
    </div>
  </div>
  <div class="ti-form__one-line-fields ti-form__one-line-fields--progress-bar" *ngIf="showSpinner">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>

  <div *ngIf="lexeme.families" class="ti-lexicon__lexemes-panel">
    <ul class="ti-bulleted-list">
      <li *ngFor="let family of lexeme.families; let index=index">
        <div class="ti-form__one-line-fields">
          <p class="ti-family__description">{{family.description || family.definition}} [{{'COMMON.family' | translate}}
            <a
              [routerLink]="'/knowledge-graph'"
              [queryParams]="{key: 'id', value: family.id}"
              appShowTooltipDescription
              [tooltipDelay]="200"
              [tooltipOption]="{ type: 'Family', familyId: family.id, hideFamilyId: true }">{{family.id}}</a>]
              &nbsp;<span *ngIf="family.advancedCriteriaDescription" matTooltip="{{family.advancedCriteriaDescription}}">&nbsp;🔣</span>
              <span *ngIf="lexeme.sameAsLemma" matTooltip="{{lexeme.sameAsLemma}}">&nbsp;🔒</span>
              <span *ngIf="family.LFUnverified && family.LFUnverified != 'verified'" matTooltip="{{family.LFUnverified}}">&nbsp;{{getUnverifiedEmoji(family.LFUnverified)}}</span>
              <span *ngIf="family.discouraged" [matTooltip]="'lexicon.discouraged-tooltip' | translate">&nbsp;🔇</span>
            </p>
          <div *ngIf="!lexeme.sameAsLemma" class="ti-family__buttons">
            <button
              mat-mini-fab
              color="primary"
              [routerLink]="'/lexicon/lexeme-family-connection/edit/'+lexeme.id+'/'+family.id"
              [queryParams]="{editedId: lexeme.id, editedValue: family.id}"
            >
              <mat-icon aria-hidden="true">{{doesUserHaveAccessToEdit ? 'edit' : 'visibility'}}</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="primary"
              *ngIf="doesUserHaveAccessToEdit"
              appOpenConfirmDialog
              (doesUserConfirmEvent)="deleteFamilyAfterUserConfirmation($event, family.id)">
              <mat-icon aria-hidden="true">delete</mat-icon>
            </button>
            <button
              mat-mini-fab
              matTooltip="{{'lexicon.quick-root-creation' | translate}}"
              color="primary"
              *ngIf="doesUserHaveAccessToEdit && !lexeme.root"
              (click)="createRoot(lexeme.id, family.id)">
              <mat-icon aria-hidden="true">park</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="primary"
              *ngIf="family.LFUnverified && family.LFUnverified != 'verified'"
              (click)="approveLexemeFamily(lexeme.id, family)">
              <mat-icon aria-hidden="true">check</mat-icon>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <p>
      <a [href]="lexeme.translationUrl" target="_blank">{{'COMMON.research' | translate}}</a>
      <a
        class="ti-form__quick-link__open-families"
        [routerLink]="'/knowledge-graph'"
        [queryParams]="{key: 'id', value: lexeme.linkedFamilyIds}"
        target="_blank">{{'lexicon.families-in-knowledge-graph' | translate}}</a>
    </p>
  </div>
  <div class="ti-form__button-panel ti-form__button-panel--flex-start">
    <button
      *ngIf="doesUserHaveAccessToEdit && !lexeme.sameAsLemma"
      mat-mini-fab
      color="primary"
      [routerLink]="'/lexicon/lexeme-family-connection/create/'+lexeme.id"
      [queryParams]="{editedId: lexeme.id}">
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </div>
</div>
