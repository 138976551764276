import { Directive, ElementRef, HostListener, Input, NgZone, Renderer2 } from '@angular/core';
import { KnowledgeGraphService } from '../services/knowledge-graph.service';
import Popper from 'popper.js';
import { LexiconService } from '../services/lexicon.service';
import { LocalStorageHelper } from '../helpers/localhost.helper';
import { FeatureTypeList } from '../models/feature';
import { CommonSenseCuesService } from '../services/common-cues.service';
import { TestsetService } from '../services/testset.service';

interface TooltipOptionFamily {
  type: 'Family';
  familyId?: number;
  hideFamilyId?: boolean;
}

interface TooltipOptionFamilyInTestConsole {
  type: 'FamilyInTestConsole';
  familyId?: number;
  familyDefinition?: string;
}

interface TooltipOptionFamilyFromCommonsenseCue {
  type: 'FamilyFromCommonsenseCue';
  commonsenseCueId?: number;
}

interface TooltipOptionPhrase {
  type: 'Phrase';
  phraseId?: number;
  familyId?: number;
}

interface TooltipOptionLexeme {
  type: 'Lexeme';
  lexemeId?: number;
}

interface TooltipOptionFeature {
  type: 'Feature';
  featureTag: 'R' | 'Y' | 'S',
  featureIndex: number;
  featureValue: string;
}

interface TooltipOptionTestresultTransformationGold {
  type: 'TestresultTransformationGold';
  fragmentId?: number;
}

const featureTagMapping = {
  'R': 'Grammar',
  'Y': 'Style',
  'S': 'Semantics'
}

type TooltipOption = TooltipOptionFamily | TooltipOptionPhrase | TooltipOptionFeature | TooltipOptionLexeme | TooltipOptionFamilyInTestConsole | TooltipOptionFamilyFromCommonsenseCue | TooltipOptionTestresultTransformationGold;

@Directive({
  selector: '[appShowTooltipDescription]'
})
export class ShowTooltipDescriptionDirective {
  @Input() tooltipOption: TooltipOption;
  @Input() tooltipEnabled = true;
  @Input() tooltipDelay = 0;
  @Input() tooltipPlacement: Popper.Placement = 'auto';

  propper: Popper;

  private mouseEnterTimer;
  private isEntered;

  // @HostListener('mouseenter') onMouseEnterEvent() {
  //   if (this.tooltipEnabled) {
  //     this.mouseEnterTimer = setTimeout(() => {
  //       this.isEntered = true;
  //       this.getDescription();
  //     }, this.tooltipDelay);
  //   }
  // }

  // @HostListener('click')
  // @HostListener('document:wheel')
  // @HostListener('mouseleave')
  // onMouseLeaveEvent() {
  //   clearTimeout(this.mouseEnterTimer);
  //   if (this.tooltipEnabled && this.isEntered === true) {
  //     this.hideTooltip();

  //     if (this.propper) {
  //       this.propper.disableEventListeners();
  //       this.propper.destroy();
  //     }
  //   }
  //   this.isEntered = false;
  // }

  constructor(
    private el: ElementRef, private _renderer: Renderer2,
    private knowledgeGraphService: KnowledgeGraphService,
    private lexiconService: LexiconService,
    private commonSenseCuesService: CommonSenseCuesService,
    private testsetService: TestsetService,
    private localStorageHelper: LocalStorageHelper,
    private element: ElementRef,
    private zone: NgZone) {
      this.zone.runOutsideAngular(() => {
        this.element.nativeElement.addEventListener('mouseenter', (e) => {
          if (this.tooltipEnabled) {
            this.mouseEnterTimer = setTimeout(() => {
              this.isEntered = true;
              this.getDescription();
            }, this.tooltipDelay);
          }
        });

        //click
        this.element.nativeElement.addEventListener('click', (e) => {
          this.onMoveOut();
        });

        this.element.nativeElement.addEventListener('mouseout', (e) => {
          this.onMoveOut()
        });
        this.element.nativeElement.addEventListener('scroll', (e) => {
          this.onMoveOut()
        });
      });
  }

  onMoveOut() {
    clearTimeout(this.mouseEnterTimer);
    if (this.tooltipEnabled && this.isEntered === true) {
      this.hideTooltip();

      if (this.propper) {
        this.propper.disableEventListeners();
        this.propper.destroy();
      }
    }
    this.isEntered = false;
  }

  async getDescription() {
    this.showTooltip('⌛', false);
    let title;

    switch (this.tooltipOption.type) {
      case 'Phrase':
        if (!this.tooltipOption.familyId || !this.tooltipOption.phraseId)
          break;

        const result = await this.knowledgeGraphService.getFamilyPhraseDescription(this.tooltipOption.familyId, this.tooltipOption.phraseId);
        title = `[Family ${this.tooltipOption.familyId}: ${result.familyDescription}] [Phrase ${this.tooltipOption.phraseId}: ${result.phraseDescription}]`;
        break;
      case 'Family':
        if (!this.tooltipOption.familyId)
          break;

        const familyDesc = await this.knowledgeGraphService.getFamilyDescription(this.tooltipOption.familyId);
        title = this.tooltipOption.hideFamilyId ? familyDesc : `[Family ${this.tooltipOption.familyId}: ${familyDesc}]`;
        break;
      case 'FamilyInTestConsole':
        if (!this.tooltipOption.familyId)
          break;

        const familyDescInTestConsole = await this.knowledgeGraphService.getFamilyDescription(this.tooltipOption.familyId);
        title = `${familyDescInTestConsole} \r\n \r\n${this.tooltipOption.familyDefinition}`;
        break;
      case 'FamilyFromCommonsenseCue':
        if (!this.tooltipOption.commonsenseCueId)
          break;

        const commonSenseCue = await this.commonSenseCuesService.getById(this.tooltipOption.commonsenseCueId);
        if (commonSenseCue.family) {
          const familyDesc = await this.knowledgeGraphService.getFamilyDescription(commonSenseCue.family);
          title = `[Family ${commonSenseCue.family}: ${familyDesc}] [Comm. cue ${commonSenseCue.id}: ${commonSenseCue.description}]`;
        }

        break;
      case 'Lexeme':
        if (!this.tooltipOption.lexemeId)
          break;

        const lexemeDesc = await this.lexiconService.getLexemeDescription(this.tooltipOption.lexemeId);
        title = `[Lexeme ${this.tooltipOption.lexemeId}: ${lexemeDesc}]`;
        break;
      case 'Feature':
        const { featureIndex, featureTag, featureValue } = this.tooltipOption;
        if (!featureIndex || !featureTag || !featureValue) {
          break;
        }

        const localStorageStr = this.localStorageHelper.getGlobalSetting('features');
        if (!localStorageStr)
          return;

        const allFeatures: FeatureTypeList[] = JSON.parse(localStorageStr);
        for (const element of allFeatures) {
          if (element.Key === featureTagMapping[featureTag]) {
            const feature = element.Value.find(feature => feature.index === featureIndex);
            if (feature) {
              const value = feature.values.find(x => x.id === featureValue);
              title = value ? value.description : '';
              break;
            }
          }
        }
        break;
      case 'TestresultTransformationGold':
        if (!this.tooltipOption.fragmentId)
          break;

        const testresultTransformationGold = await this.testsetService.getTransformationGold(this.tooltipOption.fragmentId);
        title = testresultTransformationGold || '<no gold standard translation>';
        break;

    }

    if (title) {
      this.showTooltip(title, true);
    } else {
      this.hideTooltip();
    }
  }

  showTooltip(title: string, isLoading: boolean) {
    const body = this.getBodyNode();
    if (isLoading && body.querySelectorAll('span.tooltiptext').length == 0) {
      return;
    }

    this.hideTooltip();    

    let span = this._renderer.createElement('span');
    this._renderer.addClass(span, 'tooltiptext');
    span.insertAdjacentHTML('beforeend', title.replace(/\</g, '&lt;').replace(/\>/g, '&gt;').replace(/\r\n/g, '<br/>'));

    this._renderer.insertBefore(body, span, body.lastElementChild);

    span.setAttribute('data-show', '');
    this.propper = new Popper(this.el.nativeElement, span, {
      placement: this.tooltipPlacement,
      eventsEnabled: false,
    })
    this.propper.update();
  }

  hideTooltip() {
    const body = this.getBodyNode();
    body.querySelectorAll('span.tooltiptext').forEach(el => el.remove());
  }

  getBodyNode() {
    let currentNode = this.el.nativeElement;
    while (1 === 1) {
      const parentNode = this._renderer.parentNode(currentNode);
      if (parentNode.tagName.toLowerCase() == 'body')
        return parentNode;
      currentNode = parentNode;
    }
  }
}
