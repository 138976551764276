import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { Lexeme } from '../../shared/models/lexicon';

@Component({
  selector: 'app-knowledge-graph-dialog',
  templateUrl: './knowledge-graph-dialog.component.html',
  styleUrls: ['./knowledge-graph-dialog.component.less']
})
export class KnowledgeGraphDialogComponent {
  public idsList: string;
  public header: string;
  public isFamilyDialog: boolean;
  public isLexemesDialog: boolean;
  public representativeLemma: string;
  public isAutoSuggestionLexemeDialog: string;
  public familyId: number;
  public byText: string;
  public chooseOnlyOneItem: boolean;
  public allowOtherLanguages: boolean;
  public lexemesList: Lexeme[];

  @ViewChild('btnClose', { read: ElementRef }) btnClose: ElementRef<HTMLElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private localStorageHelper: LocalStorageHelper) {
    this.isLexemesDialog = data.isLexemesDialog;
    this.isFamilyDialog = data.isFamilyDialog;
    this.header = data.header;
    this.representativeLemma = data.representativeLemma;
    this.isAutoSuggestionLexemeDialog = data.isAutoSuggestionLexemeDialog;
    this.familyId = data.familyId;
    this.byText = data.byText;
    this.chooseOnlyOneItem = data.chooseOnlyOneItem;
    this.allowOtherLanguages = data.allowOtherLanguages;
    this.lexemesList = data.lexemesList;
  }

  public updateEntry($event: string): void {
    this.idsList = $event;
  }

  public getNewLexemeId() {
    this.familyId = this.localStorageHelper.getGlobalSetting('newCreatedLexemeId');
  }
}
