<h1 class="ti-center">{{ (isCreating() ? 'TEST.corpora-creation' : 'TEST.corpora-edit') |
  translate}}</h1>
<app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
<div class="ti-form-wrapper ti-form__column-fields">
  <div class="ti-form">
    <div *ngIf="corpora.id" class="ti-form__one-line-fields">
      <span class="ti-form__id-key">{{'COMMON.id' | translate}}</span>
      &nbsp;<span class="ti-form__id-value">{{corpora.id}}</span>
    </div>
    <mat-form-field color="accent">
      <input matInput required placeholder="{{ 'COMMON.description' | translate}}" [(ngModel)]="corpora.description" />
    </mat-form-field>
    <mat-form-field color="accent">
      <input matInput name="importance" (keydown)="clearTheErrorMessage()" [(ngModel)]="corpora.importance"
        [matTooltip]="'TEST.importance' | translate" [placeholder]="'TEST.importance' | translate" />
    </mat-form-field>
    
    <mat-form-field color="accent">
      <mat-select matInput placeholder="{{ 'COMMON.format' | translate}}" role="listbox" appDisableControl
        [disableControl]="!canEditNonLexicon()" [(ngModel)]="corpora.format">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let option of formatOptions" [value]="option.value">
          {{option.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="ti-form-bordered-part__content" style="display: flex">
      <app-universal-features [featureValues]="corpora.features" [doesTheWholeFeatureListRelevant]="true"
        [relevantFeatureList]="semanticFeatureList" [isOnlyOneFeature]="false" [featureType]="'Semantics'"
        [disableSelect]="!canEditNonLexicon()"></app-universal-features>
    </div>

    <mat-form-field color="accent">
      <input matInput [matDatepicker]="picker" [placeholder]="'TEST.corpora-date' | translate" name="date" appDisableControl
        [disableControl]="!canEditNonLexicon()" [(ngModel)]="corpora.date">
      <mat-datepicker-toggle color="accent" matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="year" color="accent"></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field color="accent">
      <input matInput placeholder="{{ 'TEST.license-type' | translate}}" [(ngModel)]="corpora.licenseType" />
    </mat-form-field>
    <mat-form-field color="accent">
      <textarea matInput (change)="clearTheErrorMessage()" name="license" [(ngModel)]="corpora.license"
        [placeholder]="'TEST.license' | translate"></textarea></mat-form-field>
    <mat-form-field color="accent">
      <textarea matInput (change)="clearTheErrorMessage()" (change)="validateInputSetting()" name="recommendedTisaneSettings"
        [(ngModel)]="corpora.recommendedTisaneSettings"
        [placeholder]="'TEST.recommended-tisane-settings' | translate"></textarea>
    </mat-form-field>
    <span *ngIf="!isValidJson" class="err-invalid-json">❗{{'TEST.invalid-json' |
      translate}}</span>
    <div class="ti-form__button-panel">
      <button mat-raised-button (click)="toList()">{{'COMMON.cancel' | translate}}</button>

      <button mat-raised-button color="accent" [disabled]="!canEditNonLexicon()" (click)="save()">{{'COMMON.save' |
        translate}}
      </button>
    </div>
    </div>
    
    </div>