export enum PhonemesPosition  {
  start = 'start',
  middle = 'middle',
  end = 'end',
  allExceptMiddle = 'allExceptMiddle',
  allExceptEnd = 'allExceptEnd',
  allExceptStart = 'allExceptStart'
}
export const PhonemesText = [
  {key: 'start', value: 'Start'},
  {key: 'middle', value: 'Middle'},
  {key: 'end', value: 'End'},
  {key: 'allExceptMiddle', value: 'Start and end'},
  {key: 'allExceptEnd', value: 'Start and middle'},
  {key: 'allExceptStart', value: 'Middle and End'},
  {key: '', value: 'Anywhere'}
];

export const PhonemesAliasNormalizationAttributeText = [
  {key: 'always', value: 'Always replace by main phoneme'},
  {key: 'unrecognizedOnly', value: 'Only replace if word unrecognized'},
  {key: 'designTimeStandard', value: 'Use as standard in design time'}
];


export interface Phoneme {
  requestId?: string;
  id: number;
  phoneme: string;
  position?: PhonemesPosition | string;
  fallbackId?: number;
  referencePhoneme?: string;
  aliasList?: string;
  note?: string;
  targetOnly?: boolean;
  referenceNote?: string;
}

export interface PhonemeAlias {
  requestId?: string;
  id?: number;
  phonemeId?: number;
  aliasNormalization?: string;
  text: string;
}

