import {Component, ViewChild, Input} from '@angular/core';

import {AdvancedCriteria, PhoneticCompatibility, emptyPhoneticCompatibility} from '../../../shared/models/lexicon';
import {toJsonObject} from '../../../shared/helpers/object.helper';
import { SessionService } from '../../../shared/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-phonetic-compatibilities-table',
  templateUrl: 'phonetic-compatibilities-table.component.html',
  styleUrls: ['phonetic-compatibilities-table.component.less']
})
export class PhoneticCompatibilitiesTableComponent {
  @Input() advancedCriteria: AdvancedCriteria;

  @ViewChild('phoneticCompatibilityTable') phoneticCompatibilityTable;

  private emptyPhoneticCompatibility: PhoneticCompatibility = toJsonObject(emptyPhoneticCompatibility) as PhoneticCompatibility;
  public displayedColumns: string[] = ['expressionType', 'direction', 'expression', 'buttons'];
  public isGuest: boolean;
  private _listOfWordsPrompt: string = 'List of words';


  constructor(public sessionService: SessionService, protected translateService: TranslateService) {
    this.isGuest = this.sessionService.isGuest();
    this._listOfWordsPrompt = this.translateService.instant('COMMON.comma-list-of-words');
  }

  public togglePhoneticCompatibilityEditVisibility(index: number): void {
    this.advancedCriteria.phoneticCompatibility[index].editVisibility = !this.advancedCriteria.phoneticCompatibility[index].editVisibility;
    this.phoneticCompatibilityTable.renderRows();
  }

  public removePhoneticCompatibilityItem(confirm: boolean, index: number): void {
    if (confirm) {
      this.advancedCriteria.phoneticCompatibility.splice(index, 1);
      this.phoneticCompatibilityTable.renderRows();
    }
  }

  public importList(): void {
    let listOfStrings: string = prompt(this._listOfWordsPrompt);
    if (!listOfStrings) {
      return;
    }

    this.advancedCriteria.phoneticCompatibility = [];

    let strings: string[] = listOfStrings.replace(' ', '').split(',');
    let suffixes: Map<string, Set<string>> = new Map<string, Set<string>>();
    for (let str of strings) {
      let lastLetter: string = str.substr(str.length - 1, 1); 
      let twobefore: string = str.substr(str.length - 3, 2);
      let targetMap: Set<string>;
      if (!suffixes.has(lastLetter)) {
        targetMap = new Set<string>();
        targetMap.add(twobefore);
        suffixes.set(lastLetter, targetMap);
      } else {
        targetMap = suffixes.get(lastLetter);
        if (!targetMap.has(twobefore))
          targetMap.add(twobefore);
      }
    }
    for (let itrEntry of Array.from(suffixes)) {
      let lastLetter: string = itrEntry[0];
      let twoBefore: Set<string> = itrEntry[1];
      let twoBeforeLst: string = undefined;
      for (let tb of Array.from(twoBefore)) {
        if (twoBeforeLst)
          twoBeforeLst = twoBeforeLst + '|' + tb;
        else
          twoBeforeLst = tb;
      }

      let phoneticCompatibility: PhoneticCompatibility; 
      if (twoBeforeLst.includes('|'))
        phoneticCompatibility = {type: 'regex', direction: 'backward',
          expression: '\\w*(' + twoBeforeLst + ')' + lastLetter };
      else
        phoneticCompatibility = {type: 'simple', direction: 'backward',
        expression: twoBeforeLst + lastLetter };
      this.advancedCriteria.phoneticCompatibility.push(phoneticCompatibility);
}
      


  }

  public addPhoneticCompatibility(): void {
    this.advancedCriteria.phoneticCompatibility.push((toJsonObject(this.emptyPhoneticCompatibility) as PhoneticCompatibility));
    this.phoneticCompatibilityTable.renderRows();
  }

  public doesUserSeeButton(): boolean {
    return !this.isGuest;
  }

}
