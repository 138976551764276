import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {style} from '@angular/animations';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs/Subscription';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {LexiconService} from '../../shared/services/lexicon.service';
import {AdvancedCriteria} from '../../shared/models/lexicon';
import {emptyAdvancedCriteria} from '../../shared/models/lexicon';
import {collectObjectChanges, toJsonObject} from '../../shared/helpers/object.helper';
import {SessionService} from '../../shared/services/session.service';
import {FeatureListDefinition} from '../../shared/models/feature';
import {FeaturesService} from '../../shared/services/features.service';
import {applicationConstants} from '../../shared/constants/application-constants';
import {LampUpdateResponse} from '../../shared/models/common';
import {RouterHelper} from '../../shared/helpers/router.helper';

@Component({
  selector: 'app-advanced-criteria-edit',
  templateUrl: 'advanced-criteria-edit.component.html',
  styleUrls: ['advanced-criteria-edit.component.less']
})
export class AdvancedCriteriaEditComponent implements OnInit, OnDestroy {

  @Input() isEdit: boolean;
  @Input() currentId: number;
  @Input() forWords: boolean;
  @Input() isCopying: boolean;

  public isNotDialog: boolean;
  public form: FormGroup;
  public showProgressBar = false;

  public isGuest: boolean;

  public grammarFeatureList: FeatureListDefinition[];
  public styleFeatureList: FeatureListDefinition[];
  public semanticFeatureList: FeatureListDefinition[];

  public advancedCriteria: AdvancedCriteria;
  public initialAdvancedCriteria: AdvancedCriteria;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public showAllGrammarFeatures: boolean;
  public lexemesList: string[];
  public extrahypothesisList: string[];
  public interfixList: string[];
  public cliticList: string[];
  public taggingAffixList: string[];
  public inflectionAffixList: string[];
  public lexemeFamilyIds: { lexemeId: string; familyId: string }[] = [];
  private routeSubscription: Subscription;

  constructor(public formBuilder: FormBuilder,
              public lexiconService: LexiconService,
              public sessionService: SessionService,
              public featuresService: FeaturesService,
              private route: ActivatedRoute,
              private routerHelper: RouterHelper,
              private dialogRef: MatDialog) {
    this.isGuest = this.sessionService.isGuest();
    this.showAllGrammarFeatures = true;
    this.advancedCriteria = toJsonObject(emptyAdvancedCriteria) as AdvancedCriteria;
  }

  ngOnInit() {
    this.addSubscriptions();
  }

  private loadById(): void {
    this.lexiconService.getAdvancedCriteriaById(this.currentId)
      .then((advancedCriteria: AdvancedCriteria) => {
        this.initializeEditAdvancedCriteria(advancedCriteria);
      });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  private addSubscriptions(): void {
    this.routeSubscription = this.route.params.subscribe(
      (data: {
        currentId: number,
        forWords: string
      }) => {
        if (data && data.currentId && data.forWords) {
          this.isNotDialog = true;
          this.isEdit = true;
          this.currentId = data.currentId;
          this.forWords = data.forWords === 'true';
          this.isCopying = false;
          this.loadById();
        } else {
          if (this.isEdit || this.isCopying) {
            this.loadById();
            this.showProgressBar = true;
          } else {
            this.initForm();
            this.loadFeatureValues();
          }
        }
      });
  }

  private splitCommaDelimitedListOfIdsIntoList(commaDelimitedListOfIds: string): string[] {
    return commaDelimitedListOfIds.split(',');
  }

  private initializeEditAdvancedCriteria(advancedCriteria: AdvancedCriteria): void {
    if (advancedCriteria.lexemeIds) {
      this.lexemesList = this.splitCommaDelimitedListOfIdsIntoList(advancedCriteria.lexemeIds);
    }

    if (advancedCriteria.extrahypothesisIds) {
      this.extrahypothesisList = this.splitCommaDelimitedListOfIdsIntoList(advancedCriteria.extrahypothesisIds);
    }

    if (advancedCriteria.interfixIds) {
      this.interfixList = this.splitCommaDelimitedListOfIdsIntoList(advancedCriteria.interfixIds);

    }

    if (advancedCriteria.cliticIds) {
      this.cliticList = this.splitCommaDelimitedListOfIdsIntoList(advancedCriteria.cliticIds);

    }

    if (advancedCriteria.taggingAffixIds) {
      this.taggingAffixList = this.splitCommaDelimitedListOfIdsIntoList(advancedCriteria.taggingAffixIds);
    }

    if (advancedCriteria.inflectionAffixIds) {
      this.inflectionAffixList = this.splitCommaDelimitedListOfIdsIntoList(advancedCriteria.inflectionAffixIds);
    }

    if (advancedCriteria.lexemeFamilyIds) {
      advancedCriteria.lexemeFamilyIds.forEach((item) => {
        this.lexemeFamilyIds.push({
          lexemeId: item.key,
          familyId: item.value
        });
      });
    }

    this.defineEmptyArrays(advancedCriteria);
    this.advancedCriteria = toJsonObject(advancedCriteria) as AdvancedCriteria;
    this.loadFeatureValues();
    this.initialAdvancedCriteria = toJsonObject(advancedCriteria) as AdvancedCriteria;
    this.showProgressBar = false;
    this.initForm();
  }

  public loadFeatureValues(): void {
    this.showAllGrammarFeatures = false;
    this.featuresService.createFilteredRelevantFeatureList(this.advancedCriteria.relevant, applicationConstants.grammarFeatureType)
      .then((grammarFeatureList: FeatureListDefinition[]) => this.grammarFeatureList = grammarFeatureList);
    this.featuresService.createFilteredRelevantFeatureList(this.advancedCriteria.relevant, applicationConstants.styleFeatureType)
      .then((styleFeatureList: FeatureListDefinition[]) => this.styleFeatureList = styleFeatureList);
    this.featuresService.createFilteredRelevantFeatureList(this.advancedCriteria.relevant, applicationConstants.semanticFeatureType)
      .then((semanticFeatureList: FeatureListDefinition[]) => this.semanticFeatureList = semanticFeatureList);
  }

  private defineEmptyArrays(advancedCriteria: AdvancedCriteria): void {
    if (!advancedCriteria.requiredGrammar) {
      advancedCriteria.requiredGrammar = [];
    }
    if (!advancedCriteria.requiredStyle) {
      advancedCriteria.requiredStyle = [];
    }
    if (!advancedCriteria.requiredSemantics) {
      advancedCriteria.requiredSemantics = [];
    }
    if (!advancedCriteria.assignedGrammar) {
      advancedCriteria.assignedGrammar = [];
    }
    if (!advancedCriteria.assignedStyle) {
      advancedCriteria.assignedStyle = [];
    }
    if (!advancedCriteria.assignedSemantics) {
      advancedCriteria.assignedSemantics = [];
    }
    if (!advancedCriteria.incompatibleGrammar) {
      advancedCriteria.incompatibleGrammar = [];
    }
    if (!advancedCriteria.incompatibleStyle) {
      advancedCriteria.incompatibleStyle = [];
    }
    if (!advancedCriteria.incompatibleSemantics) {
      advancedCriteria.incompatibleSemantics = [];
    }
    if (!advancedCriteria.hypernyms) {
      advancedCriteria.hypernyms = [];
    }
    if (!advancedCriteria.requiredPhrases) {
      advancedCriteria.requiredPhrases = [];
    }
    if (!advancedCriteria.phoneticCompatibility) {
      advancedCriteria.phoneticCompatibility = [];
    }
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      description: [this.advancedCriteria.description],
      phonemesMustMatch: [this.advancedCriteria.phonemesMustMatch],
      hypernymsMustMatch: [this.advancedCriteria.hypernymsMustMatch],
      requiredPhrasesChecked: [this.advancedCriteria.clearRequiredPhrases]
    });
  }

  public closeAllDialogs(): void {
    this.dialogRef.closeAll();
  }

  public showErrorMessage(error?: string): void {
    this.errorMessageBehaviorSubject.next(error);
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  public saveAdvancedCriteria(): void {
    this.removeTempProperty();
    if (this.isEdit) {
      const changes = collectObjectChanges(this.initialAdvancedCriteria, this.advancedCriteria) as AdvancedCriteria;
      if (Object.keys(changes).length !== 0) {
        changes.id = this.advancedCriteria.id;
        changes.forWords = this.forWords;
        this.replaceFromArraysToIds(changes);
        this.defineFeatureTypes(changes);
        this.lexiconService.getAdvancedCriteriaById(changes.id)
          .then((advancedCriteria: AdvancedCriteria) => {
            this.lexiconService.updateAdvancedCriteria(changes)
              .then((message: LampUpdateResponse) => this.checkResponse(message));
          });
      } else {
        this.routerHelper.navigateToPage('/welcome');
      }
    }
  }

  private checkResponse(message: LampUpdateResponse): void {
    if (message.error) {
      this.showErrorMessage(message.error);
      return;
    } else {
      this.routerHelper.navigateToPage('/welcome');
    }
  }


  private defineFeatureTypes(advancedCriteria: AdvancedCriteria): void {
    for (const key in advancedCriteria) {
      if (key.includes(applicationConstants.styleFeatureType)) {
        this.defineType(advancedCriteria, key, applicationConstants.styleFeatureType);
      } else if (key.includes(applicationConstants.semanticFeature.type)) {
        this.defineType(advancedCriteria, key, applicationConstants.semanticFeature.type);
      }
    }
  }

  private defineType(advancedCriteria: AdvancedCriteria, key: string, type: string): void {
    if (advancedCriteria) {
      advancedCriteria[key].forEach(feature => feature.type = type);
    }
  }

  private replaceFromArraysToIds(changes: AdvancedCriteria): void {
    if (changes.requiredPhrases) {
      changes.requiredPhrases = changes.requiredPhrases
        .map(requiredPhrase => {
          return {id: requiredPhrase.id};
        });
    }
    if (changes.hypernyms) {
      changes.hypernyms = changes.hypernyms
        .map(hypernym => {
          return {id: hypernym.id};
        });
    }
  }

  private removeTempProperty(): void {
    if (this.advancedCriteria &&
      this.advancedCriteria.phoneticCompatibility) {
      this.advancedCriteria.phoneticCompatibility.forEach(phoneticCompatibility => delete phoneticCompatibility.editVisibility);
    }
  }
}
