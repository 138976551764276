import {Directive, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog} from '@angular/material';

import {InflectionTableComponent} from './inflection-table.component';

@Directive({
  selector: '[appOpenInflectionTableDialog]'
})
export class OpenInflectionTableDialogDirective {
  @Output() closeDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() lexemeId: number;
  @Input() lexeme: string;

  private isMobileView: boolean;

  constructor(private dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(InflectionTableComponent, {
      minWidth: this.isMobileView ? '96%' : '90%',
      data: {
        lexemeId: this.lexemeId,
        lexeme: this.lexeme
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => this.closeDialogEvent.emit(result));
  }

}
