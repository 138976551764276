import {Directive, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog} from '@angular/material';

import {InflectionEditDialogComponent} from './inflection-edit-dialog.component';
import {Line} from '../../shared/models/inflections';

@Directive({
  selector: '[appOpenInflectionEditDialog]'
})
export class OpenInflectionEditDialogDirective {

  @Output() closeDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() isEdit: boolean;
  @Input() line: Line;
  @Input() lexemeId: number;
  @Input() familyId: number;
  @Input() lexeme: string;

  private isMobileView: boolean;

  constructor(private dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(InflectionEditDialogComponent, {
      minWidth: this.isMobileView ? '95%' : '75%',
      data: {
        isEdit: this.isEdit,
        line: this.line,
        lexemeId: this.lexemeId,
        familyId: this.familyId,
        lexeme: this.lexeme
      },
      id: 'InflectionEditDialog'
    });

    dialogRef.afterClosed()
      .subscribe(result => this.closeDialogEvent.emit(result));
  }

}
