<app-universal-id-text-range-filter
  [currentRange]="'roots'"
  [disableNavigation]="true"
  (chosenParameterEvent)="reactOnChoosenParameterEvent($event);"
></app-universal-id-text-range-filter>
<div class="ti-table__wrapper">
  <mat-progress-bar *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-bar>
  <mat-table *ngIf="roots" [dataSource]="roots">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> {{ 'COMMON.id' | translate }} </mat-header-cell>
      <mat-cell class="ti-table__column-item" *matCellDef="let root"> {{root.id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="root">
      <mat-header-cell *matHeaderCellDef> {{ 'COMMON.root' | translate }} </mat-header-cell>
      <mat-cell class="ti-table__column-item" *matCellDef="let root"> {{root.root}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="family">
      <mat-header-cell *matHeaderCellDef> {{ 'COMMON.family' | translate }} </mat-header-cell>
      <mat-cell class="ti-table__column-item" *matCellDef="let root"> {{root.familyDescription}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="selected">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="ti-table__radio-button" *matCellDef="let root">
        <mat-radio-button (click)="selectItem(root.id);" [value]="root.id"></mat-radio-button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
