import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { LampUpdateResponse } from '../../../shared/models/common';
import { AffixTest } from '../../../shared/models/affixes.model';
import { AffixesService } from '../../../shared/services/affixes.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-affix-test-list-dialog',
  templateUrl: './affix-test-list-dialog.component.html',
  styleUrls: ['affix-test-list-dialog.component.less']
})
export class AffixTestListDialogComponent implements OnInit {
  public isTaggingAffixesMenu: boolean;
  public affixGroupId: number;
  public editedAffixTest: Partial<AffixTest>;
  public showProgressbar = false;
  public addNewEntryBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addNewEntryObservable: Observable<boolean> = this.addNewEntryBehaviorSubject.asObservable();

  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();


  public affixTests: Partial<AffixTest>[] = [];

  public displayedColumns: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      isTaggingAffixesMenu: boolean,
      affixGroupId: number;
    },
    private affixesService: AffixesService,
    private snackbar: MatSnackBar) {

    if (data.affixGroupId) {
      this.isTaggingAffixesMenu = data.isTaggingAffixesMenu;
      this.affixGroupId = data.affixGroupId
      this.getList();
    }
  }


  public getList() {
    this.affixesService.getAffixTestList(this.affixGroupId.toString())
      .then((result) => {
        this.affixTests = result;
      });
  }

  ngOnInit(): void {
    this.displayedColumns = ['description', 'buttons'];
  }

  deleteAfterUserConfirmation(doesUserConfirmDelete: boolean, id: number) {
    if (doesUserConfirmDelete) {
      this.showProgressbar = true;
      return this.affixesService.deleteAffixTest(id).then((response: LampUpdateResponse) => {
        this.showProgressbar = false;
        if (response.success) {
          this.getList();
        } else {
          this.errorMessageSubj.next(response.error || '');
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  public editInflectionForm(item: AffixTest) {
    item._isEditing = true;
    setTimeout(() => {
      document.getElementById('inflectedForm_' + item.id).focus();
    }, 200);
  }

  public saveInflectionForm(item: AffixTest) {
    item._isEditing = false;
    this.showProgressbar = true;
    return this.affixesService.updateAffixTest(item).then((response: LampUpdateResponse) => {
      this.showProgressbar = false;
      if (response.success) {
        this.getList();
        this.snackbar.open('Saved successfully', 'Close', {
          duration: 2000
        });
      } else {
        this.errorMessageSubj.next(response.error || '');
      }
    });
  }
}
