export interface PunctuationSign {
  id: number;
  nonBreakCount: number;
  referenceLanguageText: string;
  text: string;
  type: string;
}

export interface PunctuationSignData {
  requestId?: string;
  id?: number;
  capitalizeNext?: boolean;
  space?: string;
  text: string;
  type?: string;
  fallbackId?: number;
  note?: string;
}

export const emptyPunctuationSignData: PunctuationSignData = {
  capitalizeNext: false,
  space: '',
  text: '',
  type: '',
  fallbackId: 0,
  note: ''
};
