import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { RouterHelper } from '../../shared/helpers/router.helper';
import { PhonemesService } from '../../shared/services/phonemes.service';
import { Phoneme, PhonemesPosition, PhonemesText } from '../../shared/models/phoneme-sign';
import { LampUpdateResponse } from '../../shared/models/common';
import { applicationModulesRoutePaths } from "../../shared/constants/application-constants";
import { LocalStorageHelper } from "../../shared/helpers/localhost.helper";
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-phonemes-edit',
  templateUrl: './phonemes-edit.component.html',
  styleUrls: ['./phonemes-edit.component.less']
})

export class PhonemesEditComponent extends Translated implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  public phonemeId: number;
  public phoneme: string;
  public targetOnly: boolean;
  public fallBackTo: string;
  public fallbackId: number;
  public note: string;
  public positionOptions = PhonemesText;
  public aliasList: string;
  public position: PhonemesPosition | string = '';
  private _requestId: string = '';

  public errorMessageBehaviorSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubj.asObservable();

  public isTheNewSign = false;

  public phonemeEditForm: FormGroup;

  constructor(private route: ActivatedRoute,
    private routerHelper: RouterHelper,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService,
    private phonemesService: PhonemesService) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.initFromSession();
  }

  initThisSubtypeFromSession(): void {
    this.routeSubscription = this.route.data.subscribe(
      (data: {
        id: number
      }) => {
        this.phonemeId = data.id;
        this.getDataForTheCurrentPhoneme();
        this.loadExtendedDetails();
        this.routeSubscription.unsubscribe();
      });
  }

  private createForm(): void {
    this.phonemeEditForm = this.formBuilder.group({
      id: [this.phonemeId],
      phoneme: [this.phoneme],
      targetOnly: [this.targetOnly],
      fallBackTo: [this.fallBackTo],
      position: [this.position],
      note: [this.note]
    });
  }

  public save(): void {
    const phoneme: Phoneme = {
      requestId: this._requestId,
      id: this.phonemeId,
      phoneme: this.phoneme,
      targetOnly: this.targetOnly,
      fallbackId: this.fallbackId,
      position: this.position,
      note: this.note
    };
    if (this.isTheNewSign) {
      this.createPhoneme(phoneme).then((isSuccess: boolean) => {
        if (isSuccess) {
          this.cancel();
        }
      });
    } else {
      this.updatePhoneme(phoneme).then((isSuccess: boolean) => {
        if (isSuccess) {
          this.cancel();
        }
      });
    }
  }

  private updatePhoneme(phoneme: Phoneme): Promise<boolean> {
    return this.phonemesService.updatePhoneme(phoneme)
      .then((resultMessage: LampUpdateResponse) => {
        if (!resultMessage.success) {
          this.showErrorMessage(resultMessage.error);
        }
        return resultMessage.success;
      });
  }

  private createPhoneme(phoneme: Phoneme): Promise<boolean> {
    return this.phonemesService.createPhoneme(phoneme)
      .then((resultMessage: LampUpdateResponse) => {
        if (!resultMessage.success) {
          this.showErrorMessage(resultMessage.error);
        }
        return resultMessage.success;
      });
  }

  private getPhonemeFromTheList(phonemeId: number | string): Promise<Phoneme> {
    return this.phonemesService.getPhonemesList('id', phonemeId).then((phonemesList) => {
      return (phonemesList[0] as Phoneme);
    });
  }

  /**
   * Loads phoneme details from outside of the entry.
   */
  private loadExtendedDetails(): void {
    this.getPhonemeFromTheList(this.phonemeId).then((currentPhoneme: Phoneme) => {
      if (currentPhoneme) {
        this.aliasList = currentPhoneme.aliasList;
        if (currentPhoneme.fallbackId) {
          this.getPhonemeFromTheList(currentPhoneme.fallbackId).then((fallbackPhoneme: Phoneme) => {
            this.fallBackTo = fallbackPhoneme.phoneme;
            this.fallbackId = fallbackPhoneme.id;
          });
        }
      }
    });
  }

  private getDataForTheCurrentPhoneme(): void {
    this.phonemesService.getPhonemeById(this.phonemeId).then((phonemeData: Phoneme) => {
      if (phonemeData) {
        this.phoneme = phonemeData.phoneme;
        this.note = phonemeData.note;
        this.targetOnly = phonemeData.targetOnly || false;
        this.position = phonemeData.position || this.position;
        this._requestId = phonemeData.requestId;
      }

      this.isTheNewSign = !Boolean(phonemeData && phonemeData.phoneme);

      this.createForm();

      this.changeDetectorRef.markForCheck();
    });
  }

  public saveFallBackPhoneme(phoneme: Phoneme): void {
    this.fallBackTo = phoneme.phoneme;
    this.fallbackId = phoneme.id;
  }

  public updateLocalDataForTheCurrentPhoneme(): void {
    this.loadExtendedDetails();
  }

  public cancel(): void {
    const url = applicationModulesRoutePaths.phonemes.path;
    const parameters = this.localStorageHelper.getTabSetting('phonemesPath');
    if (parameters && parameters !== 'undefined' && parameters !== 'null') {
      this.routerHelper.navigateToPageWithQueryParameters(url, JSON.parse(parameters));
    } else {
      this.routerHelper.navigateToPage(url);
    }
  }

  ngOnDestroy(): void {
  }
}
