<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>{{'ABOUT.title' | translate}}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>

      <!-- <p>{{'ABOUT.lamp-was-built-by' | translate}} <a href="https://tisane.ai" target="_blank">Tisane Labs Pty
          Ltd</a>.
      </p> -->
      <h3>{{'ABOUT.lamp-info' | translate}}:</h3>
      <ul style="list-style-type: none;">
        <li>{{'ABOUT.version' | translate}}: {{ lampVersion }}</li>
        <li>{{'ABOUT.libraries-used' | translate}}:</li>
        <ul style="list-style-type: none;">
          <li *ngFor="let library of lampLibraries">
            <a href="{{ library.Value }}" target="_blank">{{ library.Key }}</a>
          </li>
        </ul>
      </ul>

      <h3>{{'ABOUT.tisane-info' | translate}}:</h3>
      <ul style="list-style-type: none;">
        <li>{{'ABOUT.version' | translate}}: {{ tisaneVersion }}</li>
        <li>{{'ABOUT.libraries-used' | translate}}:</li>
        <ul style="list-style-type: none;">
          <li *ngFor="let library of tisaneLibraries">
            <a href="{{ library.Value }}" target="_blank">{{ library.Key }}</a>
          </li>
        </ul>
      </ul>
      <p class="license">{{'ABOUT.license' | translate}}</p>
    </mat-card-content>
  </mat-card>
</div>