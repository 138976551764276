import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.less']
})
export class CustomTooltipComponent implements OnInit, OnDestroy {
  @Input() link: { href: string, text: string };
  @Input() mouseEnterOnElementObservable: Observable<boolean>;
  @Input() mouseLeaveFromElementObservable: Observable<boolean>;

  private mouseEnterOnElementSubscription: Subscription;
  private mouseEnterFromElementSubscription: Subscription;

  public hidden = true;

  constructor() {

  }

  ngOnInit(): void {
    this.mouseEnterOnElementSubscription = this.mouseEnterOnElementObservable.subscribe((doesMouseEnter: boolean) => {
      if (doesMouseEnter) {
        this.show();
      }
    });

    this.mouseEnterFromElementSubscription = this.mouseLeaveFromElementObservable.subscribe((doesMouseLeave: boolean) => {
      if (doesMouseLeave) {
        this.hide();
      }
    });
  }

  goToUrl(): void {
    window.open(this.link.href, '_blank');
  }

  ngOnDestroy(): void {
    this.mouseEnterOnElementSubscription.unsubscribe();
    this.mouseEnterFromElementSubscription.unsubscribe();
  }

  show() {
    this.hidden = false;
  }

  hide() {
    setTimeout(() => {
      this.hidden = true;
    }, 5000);
  }
}
