import { SessionService } from '../../shared/services/session.service';
import { TestResult } from '../../shared/models/test.model';

/**
 * A recoursive class used to generate a visual representation of annotations.
 */
export class AnnotatedFragment {
  public prefix: string = '';
  public postfix: string = '';
  public extraStr: string = '';
  public extraRaw: string = '';
  public offset: number = 0;
  public raw: string = '';
  private _subfragments: AnnotatedFragment[] = [];

  constructor(private _service: SessionService, private _nestingLevel: number) {

  }

  private end(): number {
    return this.offset + this.raw.length;
  }

  private contains(another: AnnotatedFragment): boolean {
    return (this.offset <= another.offset
      && ((another.offset + another.raw.length) <= (this.offset + this.raw.length)));
  }

  private overlap(another: AnnotatedFragment): number | null {
    if (this.offset <= another.offset && this.offset + this.raw.length >= another.offset
      && ((another.offset + another.raw.length) >= (this.offset + this.raw.length))) {
      return this.offset + this.raw.length;
    }
  }

  private getStyle(attribute: string, label: string): string {
    switch (attribute) {
      case 'sentiment': case 'sentiment_expressions':
        let color: string = 'brown';
        switch (label) {
          case 'positive': color = 'green';
            break;
          case 'negative': color = 'red';
            break;
        }
        return 'color: ' + color;
      case 'abuse':
        return 'text-decoration: underline dotted red';
      case 'entity':
        return 'font-weight: bold';
    }
    return '';

  }

  public addSubfragment(res: TestResult, outerFragment: string, overlapOffset?: number | null, fragment?: string, parentOffset: number = 0): void {
    let af: AnnotatedFragment = new AnnotatedFragment(this._service, this._nestingLevel + 1);
    const parentFragmentOffset = this.offset + parentOffset;
    af.prefix = '<span style="font-size: large; padding: 3px 0px;' + (overlapOffset > -1 ? '' : this.getStyle(res.attribute, res.label))
      + '; background-color: #FFFF0' + this._nestingLevel + '" title="'
      + (res.tags ? res.tags : res.label) + '">';
    switch (res.attribute) {
      case 'sentiment': case 'sentiment_expressions':
        let aspectEmoji: string = this._service.getAnnotationEmoji('sentiment', 'aspect', res.auxLabel);
        af.prefix += this._service.getAnnotationEmoji('sentiment', 'polarity', res.label)
          + (aspectEmoji && aspectEmoji.length > 0 ? '<span title="' + res.auxLabel + '">' + aspectEmoji
            + '</span>' : '') + '&nbsp;';
        break;
      case 'abuse':
        af.prefix += this._service.getAnnotationEmoji('abuse', 'type', res.label) + '&nbsp;';
        break;
      case 'entity':
        af.prefix += this._service.getAnnotationEmoji('entity', 'type', res.label) + '&nbsp;';
        break;
      default:
        return;
    }
    af.postfix = '</span>';
    af.offset = res.offset - parentFragmentOffset;
    const extraRaw = overlapOffset !== null && overlapOffset > -1 ? Array.from(fragment).slice(overlapOffset - af.offset).join('') : '';
  
    if (extraRaw) {
      this.extraStr = '<span style="' + this.getStyle(res.attribute, res.label) + '">' + extraRaw + '</span></span>';
      this.extraRaw = extraRaw;
      this.prefix += af.prefix;
      return;
    } else {
      af.raw = Array.from(outerFragment).slice(af.offset, af.offset + res.length).join('');
    }
    for (let i = 0; i < this._subfragments.length; i++) {
      let existing = this._subfragments[i];
      let overlapOffset = existing.overlap(af);
      if (existing.contains(af) || overlapOffset) {
        overlapOffset = existing.contains(af) ? null : overlapOffset;
        existing.addSubfragment(res, existing.raw, overlapOffset, af.raw, parentFragmentOffset);
        return;
      }
      if (existing.offset > af.offset + af.raw.length) {
        this._subfragments.splice(i, 0, af);
        return;
      }
    }
  
    this._subfragments.push(af);
  }


  public toString(): string {
    if (!this._subfragments || this._subfragments.length < 1) {
      return this.prefix + this.raw + (this.extraStr || '') + this.postfix;
    }
    let firstOffset: number = this._subfragments[0].offset;
    let result: string = this.prefix + this.raw.substring(0, firstOffset);
    let previousFragment: AnnotatedFragment = undefined;
    for (let subfr of this._subfragments) {
      if (previousFragment && previousFragment.end() < subfr.offset)
        //result += this.raw.substring(previousFragment.end(), subfr.offset);
        result += Array.from(this.raw).slice(previousFragment.end(), subfr.offset).join('');
      result += subfr.toString();
      previousFragment = subfr;
      previousFragment.raw += subfr.extraRaw || '';
    }
    //result += this.raw.substr(previousFragment.offset + previousFragment.raw.length) + this.postfix;
    result += Array.from(this.raw).slice(previousFragment.offset + previousFragment.raw.length).join('') + this.postfix;
    return result;
  }

}
