<div class="ti-form__one-line-fields ti-graph__wrapper" *ngIf="!isKnowledgeGraphModeBasic">
  <div class="ti-graph__hypernyms">
    <app-knowledge-graph-links-list
      [linksListHeader]="'Hypernyms'"
      [isAdmin]="isAdmin"
      [linksList]="knowledgeGraphElement.hypernyms"
      [representativeLemma]="knowledgeGraphElement.representativeLemma"
      (deleteLinksEvent)="deleteHypernymsLinks($event)"
      (addLinksEvent)="updateHypernymsLinks($event)"
      (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
    ></app-knowledge-graph-links-list>
  </div>
  <div class="ti-graph__domains">
    <app-knowledge-graph-links-list
      [linksListHeader]="'Domains'"
      [isAdmin]="isAdmin"
      [isDomainList]="true"
      [linksList]="knowledgeGraphElement.domains"
      [familyId]="knowledgeGraphElement.id"
      [representativeLemma]="knowledgeGraphElement.representativeLemma"
      (deleteLinksEvent)="deleteDomainsLinks($event)"
      (addLinksEvent)="updateDomainsLinks($event)"
      (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
      (addSilentDomainsEvent)="addSilentDomains($event)"
      (addGenerationDomainsEvent)="addGenerationDomains($event)"
    ></app-knowledge-graph-links-list>
  </div>
  <div class="ti-graph__derived-items">
    <app-knowledge-graph-links-list
      [linksListHeader]="'Derived items'"
      [isAdmin]="isAdmin"
      [linksList]="knowledgeGraphElement.derived"
      [representativeLemma]="knowledgeGraphElement.representativeLemma"
      (deleteLinksEvent)="deleteDerivedLinks($event)"
      (addLinksEvent)="updateDerivedLinks($event)"
      (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
    ></app-knowledge-graph-links-list>
  </div>
</div>
<div class="ti-form__one-line-fields ti-graph-main__base-info">
  <div class="ti-graph__base-info">
    <div class="ti-form__one-line-fields">
      <a [href]="'/knowledge-graph?key=id&value=' + knowledgeGraphElement.id" title="{{'COMMON.open-new' | translate}}" target="_blank">[{{knowledgeGraphElement.id}}]</a>
      &nbsp;&nbsp;&nbsp;<strong>{{knowledgeGraphElement.representativeLemma}}</strong>
      <button mat-mini-fab color="primary"
              [routerLink]="'/knowledge-graph/advanced-edit/edit/'+knowledgeGraphElement.id"
              [queryParams]="{editedId: knowledgeGraphElement.id}">
        <mat-icon aria-hidden="true">edit</mat-icon>
      </button>
    </div>
    <p *ngIf="knowledgeGraphElement.definition">({{knowledgeGraphElement.definition}})</p>
    <p *ngIf="knowledgeGraphElement.featureList">({{knowledgeGraphElement.featureList}})</p>
    <ul *ngIf="knowledgeGraphElement.phrases">
      <li *ngFor="let phrase of knowledgeGraphElement.phrases">🔣&nbsp;{{phrase}}</li>
    </ul>
    <ng-template [ngIf]="knowledgeGraphElement.lexemes">
      <app-changeable-list
        [list]="lexemesListAsLinkList"
        [isAbleToReplaceLinks]="!isGuest"
        [isAdmin]="!isGuest"
        [isFamilyDialog]="false"
        [isLexemesDialog]="true"
        [familyId]="knowledgeGraphElement.id"
        [representativeLemma]="knowledgeGraphElement.representativeLemma"
        (deleteLinksEvent)="deleteLexemes($event)"
        (addLinksEvent)="updateLexemes($event)"
        (moveLinksEvent)="moveLexemes($event)"
        (refreshFamilyEvent)="updateCurrentKnowledgeGraphElement()"
      ></app-changeable-list>
    </ng-template>
    <p>
      <b *ngIf="knowledgeGraphElement.phraseTag">{{knowledgeGraphElement.phraseTag}}&nbsp;</b>
      <a *ngIf="knowledgeGraphElement.translationUrl && !knowledgeGraphElement.phraseTag" [href]="knowledgeGraphElement.translationUrl" target="_blank">{{'COMMON.research' | translate}}</a>
      &nbsp;&nbsp;
      <a *ngIf="knowledgeGraphElement.translationUrl && knowledgeGraphElement.phraseTag" [href]="knowledgeGraphElement.translationUrl" target="_blank">{{'headers.list-of-phrases' | translate}}</a>
    <a *ngIf="knowledgeGraphElement.wikidata" [href]="'https://www.wikidata.org/wiki/' + knowledgeGraphElement.wikidata" target="_blank">Wikidata</a></p>
  </div>
</div>
<div class="ti-form__one-line-fields ti-graph__wrapper" *ngIf="!isKnowledgeGraphModeBasic">
  <div class="ti-graph__hyponyms">
    <app-knowledge-graph-links-list
      [linksListHeader]="'Hyponyms'"
      [isRangeSelectorShown]="true"
      [isAdmin]="isAdmin"
      [range]="'hypernyms'"
      (chosenParameterEvent)="getHyponymsForTheCurrentRange($event)"
      [linksList]="knowledgeGraphElement.hyponyms"
      [representativeLemma]="knowledgeGraphElement.representativeLemma"
      (deleteLinksEvent)="deleteHyponymsLinks($event)"
      (addLinksEvent)="updateHyponymsLinks($event)"
      (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
    ></app-knowledge-graph-links-list>
  </div>
  <div class="ti-graph__domain-members">
    <app-knowledge-graph-links-list
      [linksListHeader]="'Domain members'"
      [isRangeSelectorShown]="true"
      [isAdmin]="isAdmin"
      [range]="'domains'"
      [linksList]="knowledgeGraphElement.domainMembers"
      [familyId]="knowledgeGraphElement.id"
      (chosenParameterEvent)="getDomainMembersForTheCurrentRange($event)"
      [representativeLemma]="knowledgeGraphElement.representativeLemma"
      (deleteLinksEvent)="deleteDomainMembersLinks($event)"
      (addLinksEvent)="updateDomainMembersLinks($event)"
      (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
    ></app-knowledge-graph-links-list>
  </div>
  <div class="ti-graph__antonyms">
    <app-knowledge-graph-links-list
      [linksListHeader]="'Antonyms'"
      [isAdmin]="isAdmin"
      [linksList]="knowledgeGraphElement.antonyms"
      (chosenParameterEvent)="refreshAntonyms($event)"
      [representativeLemma]="knowledgeGraphElement.representativeLemma"
      (deleteLinksEvent)="deleteAntonymsLinks($event)"
      (addLinksEvent)="updateAntonymsLinks($event)"
      (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
    ></app-knowledge-graph-links-list>
  </div>
</div>
