<div class="ti-form-wrapper">
  <mat-progress-bar *ngIf="!familyForm || !family || isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
  <form class="ti-form" *ngIf="family && familyForm" [formGroup]="familyForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <h1 class="ti-form__one-line-fields">
      {{ (isNewFamily ? 'advanced-family-edit.creating-new-family' : 'advanced-family-edit.editing-family') |
      translate}}
      {{ isNewFamily ? '' : familyId}}
    </h1>
    <div class="ti-knowledge-graph-advanced-edit__open-dialog-button-wrapper">
      <button
        class="ti-knowledge-graph-advanced-edit__open-dialog-button"
        *ngIf="!isNewFamily && sessionService.canEditNonLexicon()"
        mat-raised-button
        color="accent"
        appOpenKnowledgeGraphLanguageSpecificEditDialog
        [familyId]="familyId"
        [systemDescription]="initialFamilyData.description"
        [relevantGrammarFeatureList]="relevantGrammarFeatureList"
        [relevantSemanticFeatureList]="relevantSemanticFeatureList"
        [localizedFamily]="localizedFamily"
        (beforeDialogClosedEvent)="getCurrentLocalizationFamilyData()"
        (afterDialogClosedEvent)="updateFamilyLocalization($event)"
      >{{'advanced-family-edit.edit-language-specific' | translate}}
      </button>
      <button
        *ngIf="sessionService.canEditNonLexicon()"
        [disabled]="!localizedFamily || !localizedFamily.localized"
        mat-raised-button
        class="ti-button__reset-lang-specific"
        appOpenConfirmDialog
        (doesUserConfirmEvent)="resetLangSpecificAfterUserConfirmation($event)"
      >{{ 'advanced-family-edit.reset-language-specific' | translate }}
      </button>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field class="ti-form__column--space-between" color="accent">
        <input
          matInput
          name="familyId"
          [required]="true"
          (keydown)="clearTheErrorMessage()"
          appDisableControl
          [disableControl]="!isAdmin()"
          [(ngModel)]="familyId"
          formControlName="familyId"
          [placeholder]="'COMMON.family-id' | translate"/>
      </mat-form-field>
      <mat-checkbox
        class="ti-form__column--space-between"
        matInput
        name="properNoun"
        [(ngModel)]="family.properNoun"
        formControlName="properNoun">
        <span class="ti-form__label">{{'advanced-family-edit.is-proper-noun' | translate}}</span>
      </mat-checkbox>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input
          matInput
          name="description"
          (keydown)="clearTheErrorMessage()"
          appDisableControl
          [disableControl]="!isAdmin()"
          [(ngModel)]="family.description"
          formControlName="description"
          [placeholder]="'COMMON.description' | translate"/>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
      <textarea
        matInput
        name="definition"
        (keydown)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!isAdmin()"
        [(ngModel)]="family.definition"
        formControlName="definition"
        [placeholder]="'COMMON.definition' | translate"></textarea>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields ti-family__phrase-type" *ngIf="family.lexemesInLanguages">
      <span class="ti-form__label">{{'knowledge-graph.lexemes-in-languages' | translate}}</span>&nbsp;<span>{{family.lexemesInLanguages}}</span>
    </div>
    <div class="ti-form__one-line-fields ti-family__phrase-type">
      <p class="ti-form__label ti-form__column--label-for-radio-buttons">{{'advanced-family-edit.phrase-type' |
        translate}}</p>
      <mat-radio-group
        class=" ti-form__column"
        [(ngModel)]="family.phraseType"
        formControlName="phraseType"
        appDisableControl
        [disableControl]="!isAdmin()">
        <mat-radio-button
          class="ti-form__radio-button"
          *ngFor="let option of phraseTypeOptions"
          [value]="option.value">
          <p class="ti-form__radio-label">{{option.description}}</p>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input
          matInput
          name="phraseTag"
          (keydown)="clearTheErrorMessage()"
          appDisableControl
          [disableControl]="!(isAdmin() && (family.phraseType !== ''))"
          [(ngModel)]="family.phraseTag"
          formControlName="phraseTag"
          [placeholder]="'advanced-family-edit.phrase-tag' | translate"/>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields ti-family__phrase-type" *ngIf="family.phrasesInLanguages">
      <span class="ti-form__label">{{'knowledge-graph.phrases-in-languages' | translate}}</span>&nbsp;<span>{{family.phrasesInLanguages}}</span>
    </div>
    <div class="ti-form__one-line-fields ti-family__phrase-type">
      <p class="ti-form__label ti-form__column--label-for-radio-buttons">{{'advanced-family-edit.fallback-policy' |
        translate}}</p>
      <mat-radio-group
        class=" ti-form__column"
        [(ngModel)]="family.fallback"
        formControlName="fallback"
        (keydown)="clearTheErrorMessage()"
        appDisableControl
        [disableControl]="!(isAdmin() && (family.phraseType === ''))">
        <mat-radio-button
          class="ti-form__radio-button"
          *ngFor="let option of fallbackPolicyOptions"
          [value]="option.value">
          <p class="ti-form__radio-label">{{option.description}}</p>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-checkbox
        matInput
        [(ngModel)]="family.properNounHyponymEntities"
        formControlName="properNounHyponymEntities">
        <span class="ti-form__label">{{'advanced-family-edit.propagate-features' | translate}}</span>
      </mat-checkbox>
      <mat-checkbox
      class="ti-form__column--space-between"
      matInput
      name="obscure"
      [(ngModel)]="family.obscure"
      formControlName="obscure">
      <span class="ti-form__label">{{'COMMON.obscure' | translate}}</span>
    </mat-checkbox>
 </div>
    <div class="ti-form__one-line-fields">
      <p class="ti-form__label ti-form__column--label-for-radio-buttons">{{'advanced-family-edit.iab' | translate}}</p>
      <ng-container *ngIf="family">
        <app-universal-features
          [featureValue]="{index: IABFeatureIndex, value: family.iab}"
          [doesTheWholeFeatureListRelevant]="true"
          [preselectedIndex]="IABFeatureIndex"
          [setDefaultValues]="false"
          [isOnlyOneFeature]="true"
          [isEmptyValueRelevant]="true"
          [relevantFeatureList]="relevantSemanticFeatureList"
          (featuresUpdatedValueEvent)="clearTheErrorMessage();updateCurrentIABValue($event)"
          (clearSelectionEvent)="clearIab()"
        ></app-universal-features>
      </ng-container>
    </div>
    <div class="ti-form__one-line-fields">
      <p class="ti-form__label ti-form__column--label-for-radio-buttons">{{'advanced-family-edit.iptc' | translate}}</p>
      <ng-container *ngIf="family">
        <app-universal-features
          [featureValue]="{index: IPTCFeatureIndex, value: family.iptc}"
          [doesTheWholeFeatureListRelevant]="true"
          [preselectedIndex]="IPTCFeatureIndex"
          [setDefaultValues]="false"
          [isOnlyOneFeature]="true"
          [isEmptyValueRelevant]="true"
          [relevantFeatureList]="relevantSemanticFeatureList"
          (featuresUpdatedValueEvent)="clearTheErrorMessage();updateCurrentIPTCValue($event)"
          (clearSelectionEvent)="clearIptc()"
        ></app-universal-features>
      </ng-container>
    </div>
    <div class="ti-knowledge-graph-advanced-edit-form__bordered-part ti-form__bordered-part">
      <span class="ti-language-settings__label ti-form-bordered-part__label">{{'COMMON.features' | translate}}</span>

      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-language-settings__label ti-form-bordered-part__label">{{'COMMON.grammar' | translate}}</span>
          <!-- <div class="ti-form-bordered-part__label">
            <mat-checkbox
              matInput
              name="grammarCopyToHyponyms"
              [(ngModel)]="family.grammarCopyToHyponyms"
              formControlName="grammarCopyToHyponyms">
              <span class="ti-form__label">{{'advanced-family-edit.copy-to-hyponyms' | translate}}</span>
            </mat-checkbox>
          </div> -->
        </div>
        <div class="ti-form-bordered-part__content" style="display: flex">
          <app-universal-features
            *ngIf="relevantGrammarFeatureList"
            [featureValues]="family.grammar"
            [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
            [relevantFeatureList]="relevantGrammarFeatureList"
            [isOnlyOneFeature]="false"
            [checkboxLabel]="'advanced-family-edit.dont-copy-to-hyponyms' | translate"
            checkboxAttributeName="blockPropagate"
            (featuresUpdatedValueEvent)="clearTheErrorMessage()"
          ></app-universal-features>
        </div>
      </div>
      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-language-settings__label ti-form-bordered-part__label">{{'COMMON.semantic' | translate}}</span>
          <!-- <div class="ti-form-bordered-part__label">
            <mat-checkbox
              matInput
              name="semanticsCopyToHyponyms"
              [(ngModel)]="family.semanticsCopyToHyponyms"
              formControlName="semanticsCopyToHyponyms">
              <span class="ti-form__label">{{'advanced-family-edit.copy-to-hyponyms' | translate}}</span>
            </mat-checkbox>
          </div> -->
        </div>
        <div class="ti-form-bordered-part__content" style="display: flex">
          <app-universal-features
            [featureValues]="family.semanticFeatures"
            [doesTheWholeFeatureListRelevant]="true"
            [relevantFeatureList]="relevantSemanticFeatureList"
            [isOnlyOneFeature]="false"
            (featuresUpdatedValueEvent)="clearTheErrorMessage()"
            [checkboxLabel]="'advanced-family-edit.dont-copy-to-hyponyms' | translate"
            checkboxAttributeName="blockPropagate"
          >
          </app-universal-features>
        </div>
      </div>
    </div>

    <div class="ti-knowledge-graph-advanced-edit-form__bordered-part ti-form__bordered-part">
      <span class="ti-language-settings__label ti-form-bordered-part__label">{{'advanced-family-edit.numeric-value' | translate}}</span>
      <div class="ti-form-bordered-part__content" style="display: flex">
       <div class="ti-form__one-line-fields ti-family__value-wrapper">
          <mat-form-field color="accent" class="ti-form__column--space-between">
            <input
              matInput
              name="value"
              (keydown)="clearTheErrorMessage()"
              appDisableControl
              [disableControl]="!isAdmin()"
              [(ngModel)]="family.value"
              type="number"
              formControlName="value"
              [required]="false"
              [placeholder]="'COMMON.value' | translate"/>
          </mat-form-field>
          <div class="ti-form__column--space-between">
            <app-universal-features
              [featureValue]="family.numericDomain"
              [doesTheWholeFeatureListRelevant]="true"
              [isEmptyValueRelevant]="true"
              [setDefaultValues]="false"
              [isOnlyOneFeature]="true"
              [relevantFeatureList]="relevantSemanticFeatureList"
              (featuresUpdatedValueEvent)="clearTheErrorMessage(); saveUpdatedNumericDomain($event)"
            >
            </app-universal-features>
          </div>
        </div>
        <div class="ti-form__one-line-fields">
          <mat-form-field color="accent" class="ti-form__column--space-between">
            <input
              matInput
              name="valueRange"
              (keydown)="clearTheErrorMessage()"
              appDisableControl
              [disableControl]="!isAdmin()"
              [(ngModel)]="family.valueRange"
              type="number"
              formControlName="valueRange"
              [placeholder]="'advanced-family-edit.range-length' | translate"/>
          </mat-form-field>
          <div class="ti-form__column--space-between">
          </div>
        </div>
      </div>
    </div>
    <div class="ti-form__one-line-fields ti-family__data">
      <div class="ti-form__column--space-between">
        <mat-form-field color="accent">
          <input
            matInput
            name="frequency"
            (keydown)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!isAdmin()"
            [(ngModel)]="family.frequency"
            formControlName="frequency"
            [matTooltip]="'COMMON.frequency-grade-tooltip' | translate"
            [placeholder]="'COMMON.frequency-grade' | translate"/>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            matInput
            [matDatepicker]="picker"
            [placeholder]="'advanced-family-edit.earliest-mention' | translate"
            name="earliestMention"
            appDisableControl
            [max]="maxDate"
            formControlName="earliestMention"
            [disableControl]="!isAdmin()"
            [(ngModel)]="earliestMention"
          >
          <mat-datepicker-toggle color="accent" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="year" color="accent"></mat-datepicker>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            matInput
            name="lastYearActive"
            (keydown)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!isAdmin()"
            [matTooltip]="'advanced-family-edit.last-year-active-tooltip' | translate"
            [(ngModel)]="family.lastYearActive"
            formControlName="lastYearActive"
            [placeholder]="'advanced-family-edit.last-year-active' | translate"/>
        </mat-form-field>
      </div>
      <div class="ti-form__column--space-between">
        <mat-form-field color="accent">
          <input
            matInput
            name="wordnetId"
            (keydown)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!isAdmin()"
            [(ngModel)]="family.wordnetId"
            formControlName="wordnetId"
            [placeholder]="'advanced-family-edit.word-net-id' | translate"/>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            matInput
            name="wikidataId"
            (keydown)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!isAdmin()"
            [(ngModel)]="family.wikidataId"
            formControlName="wikidataId"
            [placeholder]="'advanced-family-edit.wikidata-id' | translate"/>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            matInput
            name="lastYearActive"
            (keydown)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!isAdmin()"
            [(ngModel)]="family.customId"
            formControlName="customId"
            [placeholder]="'advanced-family-edit.custom-external-id' | translate"/>
        </mat-form-field>
      </div>
    </div>

    <div class="ti-form__button-panel">
      <button mat-raised-button (click)="cancel()">{{'COMMON.cancel' | translate}}</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!isAdmin()"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
  <app-audit-trail    
    *ngIf="family"
    (assignEvent)="showHistory($event)"
    [tableName]="'Families'"
    [entityId]="family.id">
  </app-audit-trail>
</div>
