import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisableControlDirective } from './disable-control.directive';
import { GenerateScrollToEventDirective } from './generate-scroll-to-event.directive';
import { ScrollToChangedPreviouslyElementDirective } from './scroll-to-changed-previously-element.directive';
import { ButtonOpensKnowledgeGraphDialogDirective } from '../../knowledge-graph/knowledge-graph-dialog/open-knowledge-graph-dialog-button.directive';
import { OpenPunctuationLookupDirective } from '../../punctuation/punctuation-lookup/open-punctuation-lookup.directive';
import { ButtonOpensFamilySelectionDialogDirective } from '../../universal-components/family/family-selection-dialog/open-family-selection-dialog-button.directive';
import { AdvancedCriteriaButtonDirective } from '../../lexicon/advanced-criteria-dialog/open-advanced-criteria-dialog.directive';
import { AdvancedCriteriaOpenEditDialogDirective } from '../../lexicon/advanced-criteria-edit-dialog/open-advanced-criteria-edit-dialog.directive';
import { ButtonOpensNonBreaksEditDialogDirective } from '../../punctuation/non-breaks/non-breaks-for-the-mark-dialog/open-non-breaks-dialog-button.directive';
import { ButtonOpensFallBackEditDialogDirective } from '../../phonemes/fall-back-to-phoneme-dialog/open-fall-back-edit-dialog-button.directive';
import { ButtonOpensAliasEditDialogDirective } from '../../phonemes/aliases/alias-list-edit-dialog/open-alias-edit-dialog-button.directive';
import { ButtonOpensKnowledgeGraphLanguageSpecificEditDirective } from '../../knowledge-graph/knowledge-graph-element-language-specific-edit-dialog/open-knowledge-graph-language-specific-edit-button.directive';
import { ButtonOpensRangeLocalizationDirective } from '../../administration/ranges/range-localization-dialog/open-range-localization-dialog-button.directive';
import { ButtonOpensLexemeMergeDialogDirective } from '../../lexicon/lexeme-merge-dialog/open-lexeme-merge-dialog-button.directive';
import { RootLookupButtonDirective } from '../../lexicon/root-lookup-dialog/root-lookup-button.directive';
import { OpenDoYouSaveDialogDirective } from '../../universal-components/do-you-save-dialog/open-do-you-save-dialog.directive';
import { OpenInflectionMatchedDialogDirective } from '../../inflection-table/inflection-matched-dialog/open-inflection-matched-dialog.directive';
import { OpenInflectionEditDialogDirective } from '../../inflection-table/inflection-edit-dialog/open-inflection-edit-dialog.directive';
import { OpenInflectionTableDialogDirective } from '../../inflection-table/open-inflection-table-dialog.directive';
import { ShowTooltipDescriptionDirective } from './show-tooltip-description.directive';
import { OpenLexemeEditDialogDirective } from '../../lexicon/lexeme-edit-dialog/open-lexeme-edit-dialog.directive';
import { ButtonOpensAffixTestListDialogDirective } from '../../morphology/tagging-and-inflection/affix-test-list-dialog/open-affix-test-list-dialog-button.directive';
import { ButtonOpensAffixTestAddDialogDirective } from '../../morphology/tagging-and-inflection/affix-test-add-dialog/open-affix-test-add-dialog-button.directive';
import { OpenUnknownWordsDialogDirective } from '../../corpora/unknown-words-dialog/open-unknown-words-dialog.directive';
import { ButtonOpensDomainGenerationDialogDirective } from '../../knowledge-graph/domain-generation-dialog/open-domain-generation-dialog-button.directive';
import { RangeLookupButtonDirective } from '../../administration/ranges/range-lookup-dialog/range-lookup-button.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DisableControlDirective,
    GenerateScrollToEventDirective,
    ScrollToChangedPreviouslyElementDirective,
    ButtonOpensKnowledgeGraphDialogDirective,
    ButtonOpensDomainGenerationDialogDirective,
    OpenPunctuationLookupDirective,
    AdvancedCriteriaButtonDirective,
    AdvancedCriteriaOpenEditDialogDirective,
    OpenLexemeEditDialogDirective,
    ButtonOpensNonBreaksEditDialogDirective,
    ButtonOpensFallBackEditDialogDirective,
    ButtonOpensAliasEditDialogDirective,
    ButtonOpensKnowledgeGraphLanguageSpecificEditDirective,
    ButtonOpensLexemeMergeDialogDirective,
    ButtonOpensRangeLocalizationDirective,
    RootLookupButtonDirective,
    OpenDoYouSaveDialogDirective,
    OpenInflectionMatchedDialogDirective,
    OpenInflectionEditDialogDirective,
    OpenUnknownWordsDialogDirective,
    OpenInflectionTableDialogDirective,
    ButtonOpensFamilySelectionDialogDirective,
    ShowTooltipDescriptionDirective,
    ButtonOpensAffixTestListDialogDirective,
    ButtonOpensAffixTestAddDialogDirective,
    RangeLookupButtonDirective
  ],
  exports: [
    DisableControlDirective,
    GenerateScrollToEventDirective,
    ScrollToChangedPreviouslyElementDirective,
    ButtonOpensKnowledgeGraphDialogDirective,
    ButtonOpensDomainGenerationDialogDirective,
    OpenPunctuationLookupDirective,
    AdvancedCriteriaButtonDirective,
    AdvancedCriteriaOpenEditDialogDirective,
    OpenLexemeEditDialogDirective,
    ButtonOpensNonBreaksEditDialogDirective,
    ButtonOpensFallBackEditDialogDirective,
    ButtonOpensAliasEditDialogDirective,
    ButtonOpensKnowledgeGraphLanguageSpecificEditDirective,
    ButtonOpensLexemeMergeDialogDirective,
    ButtonOpensRangeLocalizationDirective,
    RootLookupButtonDirective,
    OpenDoYouSaveDialogDirective,
    OpenInflectionMatchedDialogDirective,
    OpenInflectionEditDialogDirective,
    OpenUnknownWordsDialogDirective,
    OpenInflectionTableDialogDirective,
    ButtonOpensFamilySelectionDialogDirective,
    ShowTooltipDescriptionDirective,
    ButtonOpensAffixTestListDialogDirective,
    ButtonOpensAffixTestAddDialogDirective,
    RangeLookupButtonDirective
  ]
})
export class DirectivesModule {
}
