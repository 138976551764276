import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {SessionData, Ranges, IdAndDescription} from '../models/session';
import {applicationConstants} from '../constants/application-constants';
import {LocalStorageHelper} from '../helpers/localhost.helper';
import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import {FeatureValueDefinition} from '../models/feature';

/**
 * Handles operations related to the LaMP session object.
 * Mostly a local copy of the server object to modify the UI.
 */
@Injectable()
export class SessionService implements OnInit, OnDestroy {
  public session: SessionData = null;
  private emptySession = <SessionData>{};
  /**
   * Name of the local storage setting for the authentication token.
   */
  private static readonly TOKEN_SETTING_NAME: string = "auth";
  /**
   * Name of the local storage setting for the username.
   */
  private static readonly USERNAME_SETTING_NAME: string = "u";

  private sessionSource: BehaviorSubject<SessionData> = new BehaviorSubject<SessionData>(this.session);
  public currentSessionSource: Observable<SessionData> = this.sessionSource.asObservable();
  private _sessionBroadcasts: BroadcastChannel = new BroadcastChannel("tisane_session");
  private _sessionsFromOtherTabs: BroadcastChannel = new BroadcastChannel("tisane_session");
  /**
   * Was the signal sent by this tab?
   */
  private _itWasMe: boolean = false;

  /**
   * Constructs a new session service instance.
   * @param localStorageHelper manages settings in the local storage
   * @param apiClientService sends requests to LaMP
   */
  constructor(
    private localStorageHelper: LocalStorageHelper,
    private apiClientService: ApiClientService
  ) {

  }

  ngOnInit(): void {
    this.session = this.emptySession;
    //this._sessionsFromOtherTabs.onmessage = this.listenToOtherTabs;
    this._sessionsFromOtherTabs.addEventListener("message", (e) => 
      {
        if (this._itWasMe) {
          this._itWasMe = false;
          return;
        }
    
        console.info("Refresh signal received");
        this.refresh();
    
      });
  }

  ngOnDestroy(): void {
    this._sessionBroadcasts.close();
    this._sessionsFromOtherTabs.close();
  }

  public languageISOCode(): string {
    if (this.session && this.session.targetLanguage)
      return this.session.targetLanguage.ISOCode;
    else
      return '';
  }

  public languageDir(): string {
    if (this.session && this.session.targetLanguage && this.session.targetLanguage.rightToLeft)
      return 'rtl';
    else
      return 'ltr';
  }

  public textAlignStyle(): string {
    if (this.languageDir() === 'rtl')
      return 'right';
    else
      return 'left';
  }

  private listenToOtherTabs(ev: MessageEvent) {
    if (this._itWasMe) {
      this._itWasMe = false;
      return;
    }

    console.info("Refresh signal received");
    this.refresh();

  }

  private alertSubscribers() {
    this._itWasMe = true;
    this.sessionSource.next(this.session);
    this._sessionBroadcasts.postMessage(window.name); // for other tabs
  }

  public fastRefresh(): Promise<boolean> {
    return this.apiClientService.get(EndpointUrls.refreshSessionNoReload).then(
      (sessionData: SessionData) => {
        if (!sessionData) {
          this.session.isAuthenticated = undefined;
          this.localStorageHelper.setGlobalSetting(SessionService.USERNAME_SETTING_NAME, "<logged out>");
          return false;
        }
        else {
          this.setSession(sessionData);
          return true;
        }
      }
    )
    .catch((err) => { return false; });
  }

  public refresh(): Promise<boolean> {
    return this.apiClientService.get(EndpointUrls.refreshSessionNoReload).then(
      (sessionData: SessionData) => {
        if (!sessionData) {
          this.localStorageHelper.clearGlobalSetting(applicationConstants.cookieKeys.features);
          this.localStorageHelper.clearGlobalSetting(applicationConstants.cookieKeys.ranges);
          if (this.session) {
            this.session.isAuthenticated = undefined;
            this.localStorageHelper.setGlobalSetting(SessionService.USERNAME_SETTING_NAME, "<logged out>");
          }
          return false;
        }
        else {
          this.setSession(sessionData);
          return true;
        }
      }
    )
    .catch((err) => { return false; });
  }

  public logout() {
    if (!this.session)
      this.session = this.emptySession;
    this.session.isAuthenticated = undefined;
    this.localStorageHelper.clearGlobalSetting(SessionService.TOKEN_SETTING_NAME);
    this.localStorageHelper.clearGlobalSetting(SessionService.USERNAME_SETTING_NAME);
    this.apiClientService.deleteRequest(EndpointUrls.logout);
    this.alertSubscribers();
  }

  public isAuthenticated(): boolean {
    if (!this.session)
      return false;
    let token = this.localStorageHelper.getGlobalSetting(SessionService.TOKEN_SETTING_NAME);
    return token ? true : false;
  }

  public username(): string {
    return this.localStorageHelper.getGlobalSetting(SessionService.USERNAME_SETTING_NAME);
  }

  public bookmark(table: string, id: number): Promise<string> {
    return this.apiClientService.get(`${EndpointUrls.bookmark}?table=${table}&id=${id}`);
  }

  public bookmarkText(text: string): Promise<any> {
    return this.apiClientService.get(`${EndpointUrls.bookmarkText}?text=${text}`).then((result) => {
      if(result.success === false)
        throw new Error('Bookmark failed!');
    })
  }

  private setSession(sessionData: SessionData) {
    if (!sessionData)
      return;
    this.session = sessionData;
    this.session.isAuthenticated = true;
    this.session.targetLanguage = this.session.defaultLanguageOnStartup;
    this.localStorageHelper.setGlobalSetting(applicationConstants.cookieKeys.features,
      JSON.stringify(sessionData.features));
    this.localStorageHelper.setGlobalSetting(applicationConstants.cookieKeys.ranges,
      JSON.stringify(sessionData.ranges));
    this.alertSubscribers();
  }

  public processAuthenticationResponse(login: string, data: object): void {
    let sessionPlusUser: SessionData = data as SessionData;
    this.localStorageHelper.setGlobalSetting(SessionService.TOKEN_SETTING_NAME, sessionPlusUser.token);
    this.localStorageHelper.setGlobalSetting(SessionService.USERNAME_SETTING_NAME, login);
    this.setSession(sessionPlusUser);
  }
  
  public getRecentIds(table: string): IdAndDescription[] {
    if (!this.session)
      return [] as IdAndDescription[];
    const elementIndex = this.session.recent.findIndex(tableAndIds => (tableAndIds.Key === table));
    if (elementIndex > -1 && this.session.recent[elementIndex].Value)
      return this.session.recent[elementIndex].Value as IdAndDescription[];
    else
      return [] as IdAndDescription[];
  }

  public refreshSessionData(session: SessionData) {
    session = this.session;
  }

  public canEditLanguage(): boolean {
    return this.isAdmin() || this.session.canEditCurrentLanguage;
  }

  public permissionLevel(): string {
    if (!this.session || !this.isAuthenticated()) return 'unauthorized';
    if (this.isGuest()) return 'guest';
    if (this.isAdmin()) return 'admin';
    if (this.isLinguistWhoIsAbleEditOnlyLexicon()) return 'lexicon';
    if (this.session && this.session.corpusContributorOnly) return 'corpus';
    if (this.canEditNonLexicon())  return 'linguist';
    return 'unauthorized';
  }

  public isAdmin(): boolean {
    return (this.session && this.session.admin);
  }
  public isGuest(): boolean {
    return !this.isAuthenticated() || this.session.guest;
  }

  public canEditNonLexicon(): boolean {
    return this.session && (this.session.admin || this.canEditLanguage() && this.isLinguistWithFullAccess());
  }

  public getAnnotationValues(annotationType: string, attribute: string): FeatureValueDefinition[] {
    let description: string = annotationType + '/' + attribute; 
    for (let ftype of this.session.features)
      if (ftype.Key === 'Semantics') {
        for (let category of ftype.Value)
          if (category.description === description)
            return category.values;
      }
      
    return undefined;
  }

  public getAnnotationTypeEmoji(annotationType: string): string {
    switch (annotationType) {
      case 'sentiment': case 'sentiment_expressions': 
        return '😐';
      case 'abuse': 
        return '👺';
      case 'topic':
        return '📚';
      case 'entity':
        return '🧑';
    }
    return '';
  }

  public getAnnotationEmoji(annotationType: string, attribute: string, featureValue: string): string {
    let values: FeatureValueDefinition[] = this.getAnnotationValues(annotationType, attribute);
    if (!values || values.length < 1)
      return ''; //undefined; 
    for (let v of values)
      if (v.description === featureValue)
        return v.emoji ? v.emoji : '';

    return ''; // undefined;
  }

  public canEditLexicon(): boolean {
    return this.session && (this.session.admin || this.canEditLanguage() 
      && (this.isLinguistWhoIsAbleEditOnlyLexicon() || this.isLinguistWithFullAccess()));
  }
  
  public isLinguistWithFullAccess(): boolean {
    return this.session && !(this.session.admin || this.session.guest || this.session.editLexiconOnly);
  }
  
  public isLinguistWhoIsAbleEditOnlyLexicon(): boolean {
    return this.session && (this.session.editLexiconOnly);
  }

}
