<mat-dialog-content>
  <app-punctuation-menu
    [isLookup]="isLookup"
    (selectPunctuationItemEvent)="reactOnSelectPunctuationItem($event)"
  ></app-punctuation-menu>
</mat-dialog-content>
<mat-dialog-actions>
  <div style="display: flex; justify-content: space-between; width: 100%;">
    <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="selectedItemId">{{'COMMON.select' | translate}}</button>
  </div>
</mat-dialog-actions>
