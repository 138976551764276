import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {AppMaterialDependenciesModule} from '../../app-material-dependencies.module';

import {UniversalFilterComponent} from './universal-filter/universal-filter.component';
import {UniversalRangeFilterComponent} from './universal-range-filter/universal-range-filter.component';
import {UniversalIdTextRangeFilterComponent} from './universal-id-text-range-filter/universal-id-text-range-filter.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/filter/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialDependenciesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      isolate: true
    }),
  ],
  declarations: [UniversalFilterComponent, UniversalIdTextRangeFilterComponent, UniversalRangeFilterComponent],
  exports: [UniversalFilterComponent, UniversalIdTextRangeFilterComponent, UniversalRangeFilterComponent]
})

export class FilterModule {
}
