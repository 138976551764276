import {Subscription} from 'rxjs/Subscription';
import {TranslateService} from '@ngx-translate/core';

import {SessionService} from '../services/session.service';
import {applicationConstants} from '../constants/application-constants';
import {Language} from '../models/language';
import { RouterHelper } from '../helpers/router.helper';
import { LampUpdateResponse, FilterSetting } from '../models/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageHelper } from '../helpers/localhost.helper';

/**
 * A base (abstract) class managing LaMP session, user permissions, and localization (string translations).
 */
export abstract class Translated {
  /**
   * Manages the local copy of the LaMP session.
   */
  protected sessionServiceSubscription: Subscription;
  protected currentLanguageIsoCode: string = "en";
  /**
   * State: is data currently being loaded?
   */
  private _loadingData: boolean = false;
  /**
   * State: is refresh currently forbidden?
   */
  private _noRefresh: boolean = false;
  public errorMessageBehaviorSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubj.asObservable();  

  constructor(protected translateService: TranslateService,
            protected localStorageHelper: LocalStorageHelper,
            protected sessionService: SessionService) {
    this.translateService.addLangs(applicationConstants.languages.available_languages);
    this.translateService.setDefaultLang(applicationConstants.languages.default_language);
    if (this.sessionService && this.sessionService.session 
          && this.sessionService.session.preferredLanguage1)
          this.configureTranslationService(this.sessionService.session.preferredLanguage1,
            this.sessionService.session.preferredLanguage2,
            this.sessionService.session.referenceLanguage);

    this.translateService.use(this.currentLanguageIsoCode || applicationConstants.languages.default_language);
  }

  /**
   * Initializes the page localization according to the specified preferred languages.
   * @param lFirstChoice 
   * @param lSecondChoice 
   * @param lReference 
   */
  private configureTranslationService(lFirstChoice: Language, lSecondChoice: Language, lReference: Language) {
    let firstChoice = lFirstChoice ? lFirstChoice.ISOCode : "";
    let secondChoice = lSecondChoice ? lSecondChoice.ISOCode : "";
    let referenceLanguage = lReference ? lReference.ISOCode : "";
    if (this.translateService.langs.indexOf(firstChoice) < 0 && this.translateService.langs.indexOf(secondChoice) > 0) {
      this.currentLanguageIsoCode = secondChoice;
    } else
      this.currentLanguageIsoCode = firstChoice;
    this.translateService.use(this.currentLanguageIsoCode || applicationConstants.languages.default_language);
    this.translateService.setDefaultLang(referenceLanguage);
  }

  /**
   * For initialization in the children classes.
   */
  abstract initThisSubtypeFromSession(): void;

  /**
   * Refreshes the session and reconfigures the related parts.
   */
  protected initFromSessionRefresh(): void {
    this.sessionService.refresh().then(() => {
      this.configureTranslationService(this.sessionService.session.preferredLanguage1,
        this.sessionService.session.preferredLanguage2,
        this.sessionService.session.referenceLanguage);
      this.initThisSubtypeFromSession();
    });
}

/**
 * Blocks any attempts to reload data. 
 * (Used as a workaround for a strange bug when an open dialog causes the underlying window 
 * to reload the data.)
 */
public disableRefresh(): void {
  this._noRefresh = true;
}

/**
 * Reenables loading data if blocked by disableRefresh(). 
 * (Used as a workaround for a strange bug when an open dialog causes the underlying window 
 * to reload the data.)
 */
public enableRefresh(): void {
  this._loadingData = false;
  this._noRefresh = false;
}

/**
 * A helper method returning the last part of a URL in edit pages.
 * @param isNew is the instance being created?
 * @returns 
 */
protected static lastPartOfUrl(isNew: boolean): string {
  return isNew ? '/create' : '/edit';
}

/**
 * Gets whether the data is currently being loaded.
 */
public loading(): boolean {
  return this._loadingData;
}

/**
 * Gets an emoji for a specified abuse snippet, sentiment snippet, entity, etc.
 * @param type a type to get the emoji for
 * @param label a label to get the emoji for
 */
public getEmoji(type: string, label: string): string {
  switch (type) {
    case 'sentiment': case 'sentiment_expressions': 
      return this.sentiment2Emoji(label);
    case 'abuse':
      return this.abuse2Emoji(label);
    case 'entity':
      return this.entity2Emoji(label);
  }
  return '';
}

/**
 * Returns an emoji for the specified sentiment.
 * @param sentiment 
 * @returns 
 */
public sentiment2Emoji(sentiment: string): string {
  return this.sessionService.getAnnotationEmoji('sentiment', 'polarity', sentiment);
}

/**
 * Returns an emoji for the specified entity type.
 * @param entityType 
 * @returns 
 */
public entity2Emoji(entityType: string): string {
  return this.sessionService.getAnnotationEmoji('entity', 'type', entityType);
}

/**
 * Returns an emoji for the specified entity subtype.
 * @param subtype 
 * @returns 
 */
public entitySubtype2Emoji(subtype: string): string {
  if (!subtype)
    return '';
  return this.sessionService.getAnnotationEmoji('entity', 'subtype', subtype);
}

/**
 * Returns an emoji for the specified abuse type.
 * @param abuseType 
 * @returns 
 */
public abuse2Emoji(abuseType: string): string {
  return this.sessionService.getAnnotationEmoji('abuse', 'type', abuseType);
}


/**
 * Intelligently transfers the user to a relevant table, presumably filtered. 
 * If the history is long, meaning, we came from the table. 
 * If not, it was just this page, in which case, we'll just show the table filtered by id.
 * @param id the ID of the current record.
 * @param isNew whether we're creating (true) or editing (false)
 * @param routerHelper the router helper instance
 */
protected navigateToFilteredTable(id: number | string, isNew: boolean, routerHelper: RouterHelper): void {
  if (window.history.length < 2) {
    try {
      // for the cases it was not called from a table
      let currentUrl = window.location.pathname;
      let urlParts = currentUrl.split(Translated.lastPartOfUrl(isNew));
      if (id)
        routerHelper.navigateByUrl(urlParts[0] + '?key=id&value=' + id);
      else
        routerHelper.navigateByUrl(urlParts[0]);
    } catch (error) {
      console.error("Can't go back " + error.error);
      // when everything else fails
      window.close();
    }
  }
  else
    routerHelper.back();

}

/**
 * Is a new object being created?
 * @returns 
 */
public isCreating(): boolean {
  return false;
}

/**
 * Gets the ISO code of the active language; can be used for the "lang" attributes in text fields.
 */
public languageISOCode(): string {
  return this.sessionService.languageISOCode();
}

/**
 * Gets the ISO code of the reference language.
 */
public referenceLanguageISOCode(): string {
  if (!this.sessionService || !this.sessionService.session)
    return '';
  return this.sessionService.session.referenceLanguage.ISOCode;
}

/**
 * Is the user an administrator?
 * @returns 
 */
public isAdmin(): boolean {
  if (!this.sessionService || !this.sessionService.session)
    return false;
  return this.sessionService.session.admin;
}

/**
 * Is the user allowed to give ratings?
 * @returns 
 */
public canRate(): boolean {
  if (!this.sessionService || !this.sessionService.session)
    return false;
  return !this.sessionService.session.guest;
}

/**
 * Gets the native name of the current language.
 */
public languageNativeName(): string {
  return this.sessionService.session.targetLanguage.name;
}

/**
 * Gets the English name of the reference language.
 */
public referenceLanguageName(): string {
  return this.sessionService.session.referenceLanguage.englishName;
}

/**
 * Gets the direction (rtl / ltr) of the active language; can be used for the "dir" attributes in text fields.
 */
public languageDir(): string {
  return this.sessionService.languageDir();
}

/**
 * Gets the alignment (right / left) of the active language; can be used for the "style" attributes in text fields.
 */
public textAlignStyle(): string {
  return this.sessionService.textAlignStyle();
}

protected getFilteredData(searchArgument: string, searchArgumentType: string): Promise<void> {
  // must be overridden
  return Promise.resolve();
}

/**
 * Loads the data based on the current filter settings.
 * @param filterToUse the filter setting to use
 * @returns 
 */
public loadData(filterToUse: FilterSetting): void {
  if (this._loadingData || this._noRefresh)
    return;

  this._loadingData = true;
  this.disableRefresh();
  this.showErrorMessage('');
  let filterSettingAttr: string = this.filterSettingName();
  if (!filterSettingAttr)
    throw new Error("You need to override filterSettingName().");
  this.localStorageHelper.setTabSetting(filterSettingAttr, JSON.stringify(filterToUse));
  this.getFilteredData(filterToUse.value.toString(), filterToUse.key).then(() => {
    this.enableRefresh();
  });
}

/**
 * Loads data according to the filter settings in the local storage (tab-level, not global).
 */
protected loadFilterSettingsFromTabStorage(): void {
  let filterSettingAttr: string = this.filterSettingName();
  if (filterSettingAttr) {
    let filterSettings: string = this.localStorageHelper.getTabSetting(this.filterSettingName());
    if (filterSettings) {
      let filterParams: FilterSetting = JSON.parse(filterSettings);
      this.loadData(filterParams);
    }
  }
}

  protected initFromSession(): void {
    this.currentLanguageIsoCode = "en";
    if (this.sessionService.session) {
      this.configureTranslationService(this.sessionService.session.preferredLanguage1,
        this.sessionService.session.preferredLanguage2,
        this.sessionService.session.referenceLanguage);
      this.initThisSubtypeFromSession();
    }
    else {
      // refresh the session and try again
      this.initFromSessionRefresh();
    }
    //this.loadFilterSettingsFromTabStorage();
  }

  /**
   * Initializes language-related attributes.
   */
  protected initializeTranslateService(): void {
    this.translateService.addLangs(applicationConstants.languages.available_languages);
    this.translateService.setDefaultLang(applicationConstants.languages.default_language);

    this.translateService.use(this.currentLanguageIsoCode || applicationConstants.languages.default_language);
  }

  protected subscribeToSession(): void {
    this.sessionServiceSubscription = this.sessionService.currentSessionSource.subscribe(() =>
    {
      this.initThisSubtypeFromSession();
    });
  }

  /**
   * Can the user edit non-lexicon data?
   * @returns 
   */
  public canEditNonLexicon(): boolean {
    return this.sessionService.canEditNonLexicon();
  }

  /**
   * Handles LaMP response after a detected update. Either goes back or shows an error. 
   * @param successfulResponseMessage 
   */
  protected handleServerResponse(successfulResponseMessage: LampUpdateResponse): void {
    if (successfulResponseMessage.success) {
      this.toList();
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  /**
   * Redirects to the list it was presumably called from. 
   * 
   * WARNING: needs to be revised for situations when there is no history. 
   */
  public toList(): void {
    if (window.history.length > 1)
    window.history.back(); 
  else
    window.close(); // there's no list to go back to
  }

  /**
   * Resets the error message.
   */
  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  /**
   * Name of the filter setting for the current page. 
   * To use, inherit and provide a name according to your page. E.g. return 'page_i_just_created';
   * @returns 
   */
  protected filterSettingName(): string {
    return undefined; // used to save and restore the filter
  }

  /**
   * Displays the last error.
   * @param msg error message to display
   */
  protected showErrorMessage(msg: string) {
    this.errorMessageBehaviorSubj.next(msg); 
  }

  /**
   * Checks whether the URL is to create a new entry.
   * @param route 
   * @returns 
   */
  protected static isCreateUrl(route: ActivatedRoute): boolean {
    for (let i = 0; i < route.snapshot.url.length; i++) {
      let urlSection: string = route.snapshot.url[i].path;
      if (urlSection === 'create' || urlSection === '0' || urlSection === 'id=0')
        return true;
    }
    return false;
  }

  /**
   * Deletes a specified row by ID. MUST be inherited to work. 
   * (Currently inherited only in a few places)
   * @param id ID of the record to delete
   * @param $event event that triggered the call
   */
  public deleteRowById(id: number, $event: Event): void {
    // do nothing by default
  }

  /**
   * Cleans up removing the session, when it's active.
   */
  protected unsubscribeFromSessionIfSubscribed(): void {
    if (this.sessionServiceSubscription) {
      this.sessionServiceSubscription.unsubscribe();
    }
  }
}
