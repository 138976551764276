import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {scrollDialogToEnd} from '../../../shared/helpers/scroll-content';

@Component({
  selector: 'app-non-breaks-for-the-mark-dialog',
  templateUrl: './non-breaks-for-the-mark-dialog.component.html',
  styleUrls: ['./non-breaks-for-the-mark-dialog.component.less']
})
export class NonBreaksForTheMarkDialogComponent {
  public punctuationSignId: number;
  public doesUserHaveAccessToEdit: boolean;
  public addNewEntryBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addNewEntryObservable: Observable<boolean> = this.addNewEntryBehaviorSubject.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.punctuationSignId = data.punctuationSignId;
    this.doesUserHaveAccessToEdit = data.doesUserHaveAccessToEdit;
  }

  public addNewEntry(): void {
    this.addNewEntryBehaviorSubject.next(true);
  }

  public scrollToEnd(): void {
    scrollDialogToEnd();
  }
}
