<div class="ti-menu-wrapper">
  <div class="ti-form__one-line-fields">
    <app-universal-id-text-range-filter
      [currentRange]="'families'"
      [disableNavigation]="true"
      (chosenParameterEvent)="findFamilies($event)"
    ></app-universal-id-text-range-filter>
    <mat-progress-bar *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="ti-menu-wrapper">
    <mat-table *ngIf="familiesList" #nonBreaksTable [dataSource]="familiesList">
      <ng-container [matColumnDef]="displayedColumns[0]">
        <mat-header-cell class="ti-family__id" *matHeaderCellDef>Id</mat-header-cell>
        <mat-cell class="ti-family__id" *matCellDef="let element">{{element.id}}</mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[1]">
        <mat-header-cell class="ti-family__description" *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell class="ti-family__description" *matCellDef="let element">{{element.description}}</mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[2]">
        <mat-header-cell class="ti-family__definition" *matHeaderCellDef>Definition</mat-header-cell>
        <mat-cell class="ti-family__definition" *matCellDef="let element">{{element.definition}}</mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="displayedColumns[3]">
        <mat-header-cell class="ti-family__button" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="mat-cell---with-checkbox ti-family__button" *matCellDef="let element">
          <mat-radio-button *ngIf="chooseOnlyOneFamily" (change)="saveCurrentFamily(element.id)"></mat-radio-button>
          <mat-checkbox *ngIf="!chooseOnlyOneFamily" (change)="updateEntry(element.id, $event)"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
