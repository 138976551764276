import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LinguistService } from '../../shared/services/linguist.service';
import { LampUpdateResponse } from '../../shared/models/common';
import { SessionService } from '../../shared/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-status-note',
  templateUrl: './status-note.component.html',
  styleUrls: ['./status-note.component.less']
})
export class StatusNoteComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private dialogRef: MatDialog,
    private linguistService: LinguistService
  ) { }

  public status: string;
  public originalStatus: string;
  public statusNoteForm: FormGroup;
  public showSpinner: boolean = false;
  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();

  ngOnInit(): void {
    this.getUserStatus()
      .then(() => {
        this.createForm();
      });
  }

  private createForm(): void {
    this.statusNoteForm = this.formBuilder.group({
      status: [this.status]
    });
  }

  private getUserStatus(): Promise<void> {
    return this.linguistService.getStatus()
      .then((status) => {
        this.status = status;
        this.originalStatus = status;
      });
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageSubj.next(error);
  }

  public back(): void {
    this.dialogRef.closeAll();
  }

  public save(): Promise<void> {
    if (this.status) {
      if(this.originalStatus) {
        return this.linguistService.updateStatus(this.status)
        .then((message: LampUpdateResponse) => this.reactOnTheServerResponse(message));
      } else {
        return this.linguistService.createStatus(this.status)
        .then((message: LampUpdateResponse) => this.reactOnTheServerResponse(message));
      }
      
    }
  }

  private reactOnTheServerResponse(successfulResponseMessage: LampUpdateResponse): void {
    if (!successfulResponseMessage.success) {
      const error: string | HttpErrorResponse | any = successfulResponseMessage.error;
      this.showErrorMessage(error instanceof HttpErrorResponse ? error.message : error);
    } else {
      this.back();
    }
  }
}
