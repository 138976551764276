<div class="ti-links-list">
  <div class="ti-links-list__header"><p>{{linksListHeader}}</p>
    <div class="ti-links__filter-wrapper">
      <app-universal-range-filter
        *ngIf="isRangeSelectorShown"
        [currentRange]="range"
        (chosenParameterEvent)="pushTheDataFurther($event)"
      ></app-universal-range-filter>
    </div>
  </div>
  <app-changeable-list
    [list]="linksList"
    [isAbleToReplaceLinks]="false"
    [isDomainList]="isDomainList"
    [isAdmin]="isAdmin"
    [isFamilyDialog]="true"
    [familyId]="familyId"
    [header]="linksListHeader"
    [representativeLemma]="representativeLemma"
    (deleteLinksEvent)="deleteLinks($event)"
    (addLinksEvent)="updateLinksList($event)"
    (addSilentDomainsEvent)="addSilentDomains($event)"
    (addGenerationDomainsEvent)="addGenerationDomains($event)"
    (checkedFamiliesEvent)="sendCheckedFamiliesEvent($event)"
  ></app-changeable-list>
</div>
