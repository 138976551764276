<mat-dialog-content>
  <div class="ti-menu-wrapper ti-form__column-fields" *ngIf="statusNoteForm" [formGroup]="statusNoteForm">
    <div class="ti-form__column-fields ti-form__one-line-fields--center">
      <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-progress-bar color="accent" mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
    </div>
    <div class="ti-form__one-line-fields">
      <span class="ti-form__value">
        <mat-form-field class="ti-form-half-line-fields__field" color="accent" style="width: 700px;">
          <textarea matInput [required]="true" formControlName="status" [(ngModel)]="status"
            (keypress)="clearTheErrorMessage()" placeholder="{{ 'STATUS.leave-your-note-here' | translate}}"
            style="height: 200px;"></textarea>
        </mat-form-field>
      </span>
    </div>
    <div class="ti-form__column-fields ti-form__one-line-fields--center">
      <h4 class="ti-form__one-line-fields">
        {{'STATUS.what-are-you-working-on-today' | translate}}
      </h4>
    </div>
    <div class="ti-form__button-panel">
      <button [ngStyle]="{'visibility': originalStatus ? 'visible' : 'hidden'}" mat-raised-button (click)="back()">{{'COMMON.cancel' | translate}}
      </button>
      <button mat-raised-button color="accent" (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </div>
</mat-dialog-content>