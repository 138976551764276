<div class="ti-dialog__wrapper">
  <h1>{{ 'AFFIXES.add-affix-test' | translate}}</h1>

  <form [formGroup]="affixTestAddDialogForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <mat-progress-bar *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-bar>

    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input matInput formControlName="inflectedForm" name="inflectedForm" [(ngModel)]="affixTest.inflectedForm"
          placeholder="{{( isTaggingAffixesMenu ? 'lexicon.stem' : 'AFFIXES.inflection-form') | translate}}">
      </mat-form-field>
    </div>

    <div class="ti-form-bordered-part__content">
      <p>
        <mat-label *ngIf="affixTest.lexemeId">
          {{selectedLemma}} [Lexeme <a target="blank" [routerLink]="'/lexicon'" [queryParams]="{key: 'id', value: affixTest.lexemeId}">{{affixTest.lexemeId}}</a>]
        </mat-label>
        <mat-label *ngIf="!affixTest.lexemeId">
          Lexeme
        </mat-label>
        <button
          mat-mini-fab
          class="ti-button__select-lexeme"
          color="primary"
          appOpenKnowledgeGraphDialog
          (afterDialogClosedEvent)="selectLexeme($event)"
          [isLexemesDialog]="true"
          [isFamilyDialog]="false"
          [chooseOnlyOneItem]="true"
          matTooltip="{{'CONTRACTION.find-lexeme' | translate}}"
        >
          <mat-icon aria-hidden="true">search</mat-icon>
        </button>
        <button
          mat-mini-fab
          class="ti-button__reset-selection"
          color="primary"
          *ngIf="affixTest.lexemeId"
          (click)="resetSelection()"
          matTooltip="{{'CONTRACTION.reset-all' | translate}}"
          >
          <mat-icon aria-hidden="true">remove</mat-icon>
        </button>
      </p>
      <mat-form-field color="accent" class="ti-form__family-dropdown">
        <mat-select
          matInput
          [required]="true"
          [(ngModel)]="affixTest.familyId"
          formControlName="familyId"
          placeholder="{{ 'CONTRACTION.family' | translate }}"
        >
          <mat-option role="option" value=""></mat-option>
          <mat-option
            role="option"
            *ngFor="let opt of familyOpts"
            [value]="opt.id"
            >[{{ opt.id }}] {{ opt.description }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>


    <div class="ti-affix-test-add-dialog--button-panel">
      <button #btnCloseDialog mat-raised-button mat-dialog-close>{{'COMMON.cancel' | translate}}
      </button>

      <button mat-raised-button color="accent" (click)="save()">{{'COMMON.save' |
        translate}}
      </button>
    </div>
  </form>
</div>