import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {NonBreaksForTheMarkDialogComponent} from './non-breaks-for-the-mark-dialog.component';

@Directive({selector: '[appOpenNonBreaksEditDialog]'})

export class ButtonOpensNonBreaksEditDialogDirective {
  @Input('punctuationSignId') private punctuationSignId: number;
  @Input('doesUserHaveAccessToEdit') private doesUserHaveAccessToEdit: boolean;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openNonBreaksEditDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(NonBreaksForTheMarkDialogComponent, {
      maxWidth: this.isMobileView ? '96%' : '60%',
      minWidth: this.isMobileView ? '96%' : '70%',
      data: {punctuationSignId: this.punctuationSignId, doesUserHaveAccessToEdit: this.doesUserHaveAccessToEdit}
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });
  }
}
