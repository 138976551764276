<mat-table #phoneticCompatibilityTable [dataSource]="advancedCriteria.phoneticCompatibility">
  <ng-container matColumnDef="expressionType">
    <mat-header-cell *matHeaderCellDef>{{ 'advanced-criteria.expression-type' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let phoneticCompatibilityItem; let i = index">
      <div class="ti-ac__table-value" *ngIf="!phoneticCompatibilityItem.editVisibility">{{ phoneticCompatibilityItem.type }}</div>
      <div *ngIf="phoneticCompatibilityItem.editVisibility">
        <mat-form-field color="accent">
          <mat-select
            role="listbox"
            placeholder="{{ 'advanced-criteria.expression-type' | translate}}"
            [(ngModel)]="advancedCriteria.phoneticCompatibility[i].type"
            [ngModelOptions]="{standalone: true}"
          >
            <mat-option role="option" [value]="'simple'">{{'basic_simple' | translate }}</mat-option>
            <mat-option role="option" [value]="'regex'">{{'basic_regex' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="direction">
    <mat-header-cell *matHeaderCellDef>{{ 'advanced-criteria.direction' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let phoneticCompatibilityItem; let i = index">
      <div class="ti-ac__table-value" *ngIf="!phoneticCompatibilityItem.editVisibility">{{ phoneticCompatibilityItem.direction }}</div>
      <div *ngIf="phoneticCompatibilityItem.editVisibility">
        <mat-form-field color="accent">
          <mat-select
            role="listbox"
            placeholder="{{ 'advanced-criteria.direction' | translate}}"
            [(ngModel)]="advancedCriteria.phoneticCompatibility[i].direction"
          >
            <mat-option role="option" [value]="'forward'">{{'basic_forward' | translate }}</mat-option>
            <mat-option role="option" [value]="'backward'">{{'basic_backward' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="expression">
    <mat-header-cell *matHeaderCellDef>{{ 'COMMON.expression' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let phoneticCompatibilityItem; let i = index">
      <div class="ti-ac__table-value" *ngIf="!phoneticCompatibilityItem.editVisibility">{{ phoneticCompatibilityItem.expression }}</div>
      <div *ngIf="phoneticCompatibilityItem.editVisibility">
        <mat-form-field color="accent">
          <input
            matInput
            name="expression"
            placeholder="{{ 'COMMON.expression' | translate}}"
            [(ngModel)]="advancedCriteria.phoneticCompatibility[i].expression"
            [ngModelOptions]="{standalone: true}"
          >
        </mat-form-field>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="buttons">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell class="ti-form__button-panel" *matCellDef="let phoneticCompabilityItem;let i = index">
      <button
        *ngIf="phoneticCompabilityItem.editVisibility"
        mat-mini-fab
        color="primary"
        (click)="togglePhoneticCompatibilityEditVisibility(i)"
      >
        <mat-icon aria-hidden="true">done</mat-icon>
      </button>
      <button
        *ngIf="phoneticCompabilityItem.editVisibility"
        mat-mini-fab
        color="primary"
        (click)="removePhoneticCompatibilityItem($event, i)"
      >
        <mat-icon aria-hidden="true">clear</mat-icon>
      </button>
    <button
      *ngIf="!phoneticCompabilityItem.editVisibility && doesUserSeeButton()"
      mat-mini-fab
      color="primary"
      (click)="togglePhoneticCompatibilityEditVisibility(i)"
    >
      <mat-icon aria-hidden="true">edit</mat-icon>
    </button>
      <button
        *ngIf="!phoneticCompabilityItem.editVisibility && doesUserSeeButton()"
        mat-mini-fab
        color="primary"
        appOpenConfirmDialog
        (doesUserConfirmEvent)="removePhoneticCompatibilityItem($event, i)"
      >
        <mat-icon aria-hidden="true">delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<button
  class="ti-ac__add-button"
  mat-mini-fab color="primary"
  (click)="addPhoneticCompatibility()"
  *ngIf="doesUserSeeButton()"
>
  <mat-icon aria-hidden="true">add</mat-icon>
</button>&nbsp;
<button
class="ti-ac__add-button"
mat-mini-fab color="primary"
(click)="importList()"
matTooltip="{{'advanced-criteria.build-from-words' | translate}}"
*ngIf="doesUserSeeButton()"
>
<mat-icon aria-hidden="true">import_export</mat-icon>
</button>

