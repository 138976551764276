import {Component, Output, EventEmitter, OnInit, OnDestroy, Input, ViewChild, Inject} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {MAT_DIALOG_DATA} from '@angular/material';

import {LexiconService} from '../../shared/services/lexicon.service';
import {AdvancedCriteria} from '../../shared/models/lexicon';
import {LampUpdateResponse} from '../../shared/models/common';
import {SessionService} from '../../shared/services/session.service';

@Component({
  selector: 'app-advanced-criteria',
  templateUrl: './advanced-criteria.component.html',
  styleUrls: ['./advanced-criteria.component.less']
})

export class AdvancedCriteriaComponent implements OnInit, OnDestroy {

  @Output() selectAdvancedCriteriaItemEvent: EventEmitter<AdvancedCriteria> = new EventEmitter<AdvancedCriteria>();
  @Output() sessionInterrupted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() forWords: boolean;

  @ViewChild('advancedCriteriaTable') advancedCriteriaTable;

  private currentArg: string;
  private currentType: string;
  private sessionSubscription: Subscription;

  public displayedColumns: string[] = ['id', 'description', 'buttons', 'selected'];
  public advancedCriteriaList: AdvancedCriteria[];
  public showProgressBar: boolean = false;
  public currentAdvancedCriteria: AdvancedCriteria;
  public isMobileView: boolean;
  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();
  public currentLanguageId: number;

  constructor(
    public lexiconService: LexiconService,
    public sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { forWords: boolean }
  ) {}

  ngOnInit(): void {
    this.addSubscriptions();
    this.forWords = this.dialogData.forWords;
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  private addSubscriptions(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource
      .subscribe(session => {
        this.currentLanguageId = session.targetLanguage.id;
        if (!session.isAuthenticated) {
          this.sessionInterrupted.emit(true);
        }
      });
  }

  public reactOnChosenParameterEvent(event: { key: string; value: string }): Promise<void> {
    this.currentArg = event.value;
    this.currentType = event.key;
    return this.filter();
  }

  public filter(): Promise<void> {
    this.showProgressBar = true;
    return this.lexiconService.getAdvancedCriteriaList(this.currentType, this.currentArg, this.forWords)
      .then((advancedCriteriaList: AdvancedCriteria[]) => {
        this.advancedCriteriaList = advancedCriteriaList;
        this.showProgressBar = false;
      });
  }

  public selectItem(advancedCriteriaId: number): void {
    this.currentAdvancedCriteria = this.advancedCriteriaList.find(advancedCriteria => advancedCriteria.id === advancedCriteriaId);
    this.selectAdvancedCriteriaItemEvent.emit(this.currentAdvancedCriteria);
  }

  public deleteAdvancedCriteriaAfterUserConfirmation(doesUserConfirm: boolean, advancedCriteriaId: number): Promise<void> {
    if (doesUserConfirm) {
      return this.lexiconService.deleteAdvancedCriteria(advancedCriteriaId)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.filter();
          } else {
            this.errorMessageBehaviorSubject.next(successfulResponseMessage.error);
          }
        });
    }
  }

  public reactOnUpdateDataEvent(id: number): Promise<void> {
    const index = this.advancedCriteriaList.findIndex(advancedCriteria => advancedCriteria.id === id);
    this.showProgressBar = true;

    return this.lexiconService.getAdvancedCriteriaById(id)
      .then((advancedCriteria: AdvancedCriteria) => {
        this.advancedCriteriaList[index] = advancedCriteria;
        this.advancedCriteriaTable.renderRows();
        this.showProgressBar = false;
      });
  }

  ngOnDestroy(): void {
    this.sessionSubscription.unsubscribe();
  }
}
