export interface NonBreak {
  requestId?: string;
  id?: number;
  description: string;
  regex: string;
  heuristicId?: number | string;
  language?: number;
  punctuationId?: number;
}

export const emptyNonBreak: NonBreak = {
  description: '',
  regex: '',
  heuristicId: '',
  language: 0
};

export interface NonDictionary {
  description: string;
  entityType: string;
  features: Array<{ index: number, value: string }>;
  hypernym: number;
  id: number;
  regex: string;
}

export interface NonBreakGlobal extends NonBreak{
  trailingRegex?: string;
  backward?: boolean;
  maxIterations?: number;
}