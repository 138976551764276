import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {scrollDialogToEnd} from '../../../shared/helpers/scroll-content';

@Component({
  selector: 'app-alias-list-edit-dialog',
  templateUrl: './alias-list-edit-dialog.component.html',
  styleUrls: ['alias-list-edit-dialog.component.less']
})
export class AliasListEditDialogComponent {
  public phonemeId: number;
  public addNewEntryBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addNewEntryObservable: Observable<boolean> = this.addNewEntryBehaviorSubject.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: { phonemeId: number }) {
    this.phonemeId = data.phonemeId;
  }

  public addNewEntry(): void {
    this.addNewEntryBehaviorSubject.next(true);
  }

  public handleNewElementLine(): void {
    scrollDialogToEnd();
  }
}
