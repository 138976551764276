import {Component, OnInit} from '@angular/core';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {Interfix} from '../../../shared/models/interfixes';
import {InterfixesService} from '../../../shared/services/interfixes.service';
import {LampUpdateResponse} from '../../../shared/models/common';

@Component({
  selector: 'app-interfix-list',
  templateUrl: './interfix-list.component.html'
})
export class InterfixListComponent implements OnInit {
  public interfixList: Interfix[];

  public showProgressBar: boolean;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  constructor(public interfixesService: InterfixesService) {
  }

  ngOnInit(): void {
    this.getInterfixList();
  }

  public getInterfixList(): Promise<void> {
    this.showProgressBar = true;
    return this.interfixesService.getInterfixList()
      .then((interfixList: Interfix[]) => {
        this.showProgressBar = false;
        this.interfixList = interfixList;
      });
  }

  public deleteInterfix(id: number): Promise<void> {
    this.showProgressBar = true;
    return this.interfixesService.deleteInterfix(id)
      .then((message: LampUpdateResponse) => {
        this.showProgressBar = false;
        if (message.success) {
          this.getInterfixList();
        } else {
          this.showErrorMessage(message.error);
        }
      });
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }
}
