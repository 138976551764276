<div class="ti-ac__table-wrapper-content">
  <mat-table #requiredPhrasesTable [dataSource]="advancedCriteria.requiredPhrases">
    <ng-container matColumnDef="checked">
      <mat-header-cell
        *matHeaderCellDef
        class="ti-mat-cell---with-checkbox"
        class="ti-ac__checkbox-cell"
      >
        <div class="ti-wrapper">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [disabled]="isGuest"
          >
          </mat-checkbox>
        </div>
      </mat-header-cell>
      <mat-cell
        class="ti-mat-cell---with-checkbox ti-ac__checkbox-cell"
        *matCellDef="let row"
      >
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="isGuest"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="id">
      <mat-header-cell class="ti-ac__id-cell" *matHeaderCellDef>{{ 'COMMON.id' | translate }}</mat-header-cell>
      <mat-cell class="ti-ac__id-cell" *matCellDef="let requiredPhrase">{{requiredPhrase.id}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="addChildren">
      <mat-header-cell class="ti-ac__add-children-cell" *matHeaderCellDef>{{ 'advanced-criteria.add-children' | translate }}</mat-header-cell>
      <mat-cell class="ti-ac__add-children-cell" *matCellDef="let requiredPhrase">{{(requiredPhrase.children ? 'COMMON.yes' : 'COMMON.no') | translate}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="phraseDetails">
      <mat-header-cell class="ti-ac__phrase-details-cell" *matHeaderCellDef>{{ 'advanced-criteria.phrase-details' | translate}}</mat-header-cell>
      <mat-cell class="ti-ac__phrase-details-cell" *matCellDef="let requiredPhrase">{{requiredPhrase.definition}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="requiredPhrasesColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: requiredPhrasesColumns;"
             (click)="selection.toggle(row)">
    </mat-row>
  </mat-table>
  <div class="ti-ac__add-button-wrapper">
    <button mat-mini-fab color="primary"
      appOpenConfirmDialog
      (doesUserConfirmEvent)="removeRequiredPhrases($event)"
      [disabled]="doesUserClickButton()"
      *ngIf="doesUserSeeButton()"
    >
      <mat-icon aria-hidden="true">delete</mat-icon>
    </button>
  </div>
</div>
<button
  class="ti-ac__add-button"
  mat-mini-fab
  appOpenFamilySelectionDialog
  [chooseOnlyOneFamily]="true"
  (selectedDataEvent)="addRequiredPhrase($event)"
  color="primary"
  *ngIf="doesUserSeeButton()"
>
  <mat-icon aria-hidden="true">add</mat-icon>
</button>
