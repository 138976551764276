<div *ngIf="list" class="ti-graph__lexemes-panel"
[ngClass]="{'ti-graph__lexemes-panel--with-buttons': (list[0] && commaDelimitedIdsList)}">
<mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>

  <ul>
    <li *ngFor="let link of list; let index=index">
      <div class="ti-form__one-line-fields">
        <mat-checkbox
        *ngIf="link.id"
        appShowTooltipDescription class="tooltip"
        [tooltipEnabled]="isFamilyDialog"
        [tooltipOption]="{ type: 'Family', familyId: link.id }"
        
          matInput
          (change)="handleLink(link.id, $event.checked, link.description)">
            <span
              class="ti-language-settings__label"
            >
              {{link.description}}&nbsp;{{link.features}}
              <span class="checked-link" *ngIf="isLexemesDialog">
                [<a [routerLink]="'/lexicon'" [queryParams]="{key: 'id', value: link.id}">{{'COMMON.lexeme' | translate}} {{link.id}}</a>]
              </span>
              <span *ngIf="!header && link.unverified && link.unverified != 'verified'" matTooltip="{{link.unverified}}">&nbsp;{{getUnverifiedEmoji(link.unverified)}}</span>
              
            </span>
        </mat-checkbox>
        <span *ngIf="!header && link.discouraged && isLexemesDialog"
          [matTooltip]="'lexicon.discouraged-tooltip' | translate">&nbsp;🔇</span>
        <span *ngIf="link.discouraged && isFamilyDialog"
          [matTooltip]="'COMMON.silent-tooltip' | translate">&nbsp;🤫</span>
          <mat-icon 
            [matTooltip]="'knowledge-graph.toggle-silent' | translate"
            *ngIf="isAdmin && isFamilyDialog && (header == 'Domains' || header == 'Domain members')"
            class="btn-switch-silent"
            (click)="switchSilent(link)"
            aria-hidden="true">swap_horiz</mat-icon>
        <span *ngIf="!link.id">...</span>
        <button
            mat-mini-fab
            color="primary"
            class="btn-approve"
            *ngIf="!header && link.unverified && link.unverified != 'verified'"
            (click)="approveLexemeFamily(link, familyId)">
            <mat-icon aria-hidden="true">check</mat-icon>
          </button>
      </div>
    </li>
  </ul>
  <div class="ti-form__column-fields ti-form__column-fields--with-buttons" *ngIf="list[0]">
    <button
      mat-mini-fab
      color="primary"
      appOpenKnowledgeGraphDialog
      (afterDialogClosedEvent)="replaceLinks($event)"
      [isFamilyDialog]="true"
      [isLexemesDialog]="false"
      *ngIf="isAbleToReplaceLinks && commaDelimitedIdsList">
      <mat-icon aria-hidden="true">arrow_right_alt</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="primary"
      *ngIf="commaDelimitedIdsList && (isLexemesDialog || isAdmin)"
      appOpenConfirmDialog
      (doesUserConfirmEvent)="deleteLinksAfterUserConfirmation($event)">
      <mat-icon aria-hidden="true">delete</mat-icon>
    </button>
  </div>
</div>
<div class="ti-form__one-line-fields ti-form__button-panel">
  <button
    *ngIf="isAdmin"
    mat-mini-fab
    color="primary"
    appOpenKnowledgeGraphDialog
    (afterDialogClosedEvent)="updateLinksList($event)"
    [isLexemesDialog]="isLexemesDialog"
    [isFamilyDialog]="isFamilyDialog">
    <mat-icon aria-hidden="true">add</mat-icon>
  </button>
  <button *ngIf="isDomainList" mat-mini-fab color="primary" appOpenKnowledgeGraphDialog
    [isLexemesDialog]="isLexemesDialog" [isFamilyDialog]="isFamilyDialog"
    (afterDialogClosedEvent)="addSilentDomains($event)" [matTooltip]="'COMMON.add-silent-domains' | translate">
    <mat-icon aria-hidden="true">add_link</mat-icon>
  </button>

  <button
    *ngIf="isDomainList && isAdmin"
    mat-mini-fab color="primary"
    [matTooltip]="'knowledge-graph.add-domains-by-ai' | translate"
    appOpenDomainGenerationDialog
    (afterDialogClosedEvent)="addGeneratedDomains($event)"
    [familyId]="familyId">
    <mat-icon aria-hidden="true">lightbulb</mat-icon>
  </button>

  <button
    *ngIf="isLexemesDialog && isAbleToReplaceLinks"
    mat-mini-fab
    color="primary"
    appOpenKnowledgeGraphDialog
    (afterDialogClosedEvent)="updateLinksList($event)"
    [familyId]="familyId"
    [isAutoSuggestionLexemeDialog]="true"
    (isLoadingEvent)="isLoading = $event">
    <mat-icon aria-hidden="true">lightbulb</mat-icon>
  </button>
</div>
