export const applicationConstants = {
  languages: {
    available_languages: ['ru', 'en'],
    default_language: 'en'
  },
  session_cookie_key: 'data',
  cookieKeys: {
    ranges: 'ranges',
    session: 'session',
    features: 'features'
  },
  clear_session_data: '=;expires=Thu, 01 Jan 1970 00:00:00 GMT',
  guestLogin: 'guest',
  title: {
    endPart: ' - Tisane LaMP',
    default: 'Tisane LaMP'
  },
  grammarFeatureType: 'Grammar',
  defaultFeatureType: 'Grammar',
  styleFeatureType: 'Style',
  semanticFeatureType: 'Semantics',
  semanticFeature: {
    type: 'Semantics',
    index: 7
  },
  preselectedIndexes: {
    IAB: 25,
    IPTC: 24
  },
  dialogs: {
    inflectionTableDialog: 'inflectionTableDialog',
    inflectionEditDialog: 'InflectionEditDialog',
    advancedCriteriaDialog: 'advancedCriteriaDialog',
    advancedCriteriaEditDialog: 'advancedCriteriaEditDialog',
    lexemeEditDialog: 'lexemeEditDialog',
    domainGenerationDialog: 'domainGenerationDialog'
  },
  loadmoreItem: 50,
  noneText: {
    testresults: 'Fragments Tested Daily'
  }
};

export const applicationModulesRoutePaths = {
  login: {path: '/login', title: 'Sign in'},
  lexicon: {path: '/lexicon', title: 'Lexicon'},
  punctuation: {path: '/punctuation', title: 'Punctuation'},
  statistics: {path: '/statistics', title: 'Statistics'},
  phonemes: {path: '/phonemes', title: 'Phonemes'},
  administration: {
    administration: {path: '/administration'},
    'language-settings': {path: '/language-settings', title: 'Language settings'},
    localization: {path: '/features-localization', title: 'Feature localization'},
    definition: {path: '/features-definition', title: 'Features Definition'},
    range: { path: '/data-filters', title: 'Data filters' },
    'health-check-definitions': { path: '/health-check-definitions', title: 'Health Checks' },
    'linguist-activities': { path: '/linguist-activities', title: 'Linguist Activity' },
    'restart-lamp-service': { path: '/restart-lamp-service', title: 'Restart Service' }
  },
  advanced: {
    advanced: {path: '/advanced'},
    'coreference-resolution': {path: '/coreference-resolution', title: 'Coreference Resolution'},
    'non-dictionary-heuristics': {path: '/non-dictionary-heuristics', title: 'Non-dictionary Heuristics'},
    'non-breaks': {path: '/non-breaks', title: 'Non-break Rules'},
    'pro-drop': {path: '/pro-drop', title: 'Pro-drop'},
    'etymology': {path: '/etymology', title: 'Etymology'},
    'contractions': {path: '/contractions', title: 'Contractions'}
  },
  syntaxAndContext: {
    syntaxAndContext: {path: '/syntax-and-context'},
    'grammatical-agreement': {path: '/grammatical-agreement', title: 'Grammatical agreement'},
    'phrasal-patterns': {path: '/phrasal-patterns', title: 'Phrasal patterns'},
    'commonsense-cues': {path: '/commonsense-cues', title: 'Commonsense Cues'},
  },
  morphology: {
    morphology: {path: '/morphology'},
    'default-feature-values': {path: '/default-feature-values', title: 'Default feature values'},
    'extra-hypothesis generation': {path: '/extra-hypothesis-generation', title: 'Extra-Hypothesis Generation'},
    interfixes: {path: '/interfixes', title: 'Interfixes'},
    clitics: {path: '/clitics', title: 'Clitics'},
    inflection: {path: '/affixes/inflection', title: 'Inflection'},
    tagging: {path: '/affixes/tagging', title: 'Tagging'}
  },
  'knowledge-graph': {path: '/knowledge-graph', title: 'Knowledge Graph'},
  tracer: {path: '/visual-tracer', title: 'Visual Debugger'},
  results: {path: '/results', title: 'Test Results'},
  testconsole: {path: '/test-console', title: 'Test Console'},
  testoverview: {path: '/test-overview', title: 'Test Results Overview'},
  verifyresultsanalysis: { path: '/verify-results-analysis', title: 'Gold Standard (Analysis)' },
  verifyresultstransformation: { path: '/verify-results-transformation', title: 'Gold Standard (Transformation)' },
  corpora: {path: '/corpora', title: 'Corpora'},
  testproviders: { path: '/testproviders', title: 'Test Applications' },
  about: { path: '/about', title: 'About' },
};

export interface MenuItemsInterface {
  item: string;
  path?: string;
  subMenu?: MenuItemsInterface[];
  onlyAdmin?: boolean;
}

export const menuItems: MenuItemsInterface[] = [
  {item: 'lexicon', path: applicationModulesRoutePaths.lexicon.path},
  {
    item: 'morphology',
    subMenu: [
      {
        item: 'inflection',
        path: applicationModulesRoutePaths.morphology.morphology.path +
        applicationModulesRoutePaths.morphology.inflection.path
      },
      {
        item: 'tagging',
        path: applicationModulesRoutePaths.morphology.morphology.path +
        applicationModulesRoutePaths.morphology.tagging.path
      },
      {
        item: 'default feature values',
        path: applicationModulesRoutePaths.morphology.morphology.path +
        applicationModulesRoutePaths.morphology['default-feature-values'].path
      },
      {
        item: 'clitics',
        path: applicationModulesRoutePaths.morphology.morphology.path +
        applicationModulesRoutePaths.morphology.clitics.path
      }, {
        item: 'interfixes',
        path: applicationModulesRoutePaths.morphology.morphology.path +
        applicationModulesRoutePaths.morphology.interfixes.path
      }, {
        item: 'extra-hypothesis generation',
        path: applicationModulesRoutePaths.morphology.morphology.path +
        applicationModulesRoutePaths.morphology['extra-hypothesis generation'].path
      }]
  },
  {item: 'knowledge graph', path: applicationModulesRoutePaths['knowledge-graph'].path},
  {
    item: 'disambiguation',
    subMenu: [
      {
        item: 'phrasal patterns',
        path: applicationModulesRoutePaths.syntaxAndContext.syntaxAndContext.path +
        applicationModulesRoutePaths.syntaxAndContext['phrasal-patterns'].path
      },
      {
        item: 'grammatical agreement',
        path: applicationModulesRoutePaths.syntaxAndContext.syntaxAndContext.path +
        applicationModulesRoutePaths.syntaxAndContext['grammatical-agreement'].path
      },
      {
        item: 'commonsense cues',
        path: applicationModulesRoutePaths.syntaxAndContext.syntaxAndContext.path +
        applicationModulesRoutePaths.syntaxAndContext['commonsense-cues'].path
      }
    ]
  },
  {item: 'punctuation', path: applicationModulesRoutePaths.punctuation.path},
  {item: 'phonemes', path: applicationModulesRoutePaths.phonemes.path},
  {
    item: 'advanced',
    subMenu: [
      {
        item: 'non-breaks', 
        path: applicationModulesRoutePaths.advanced["non-breaks"].path
      },
      {
        item: 'coreference resolution',
        path: applicationModulesRoutePaths.advanced.advanced.path +
        applicationModulesRoutePaths.advanced['coreference-resolution'].path
      },
      {
        item: 'non-dictionary heuristics',
        path: applicationModulesRoutePaths.advanced.advanced.path +
        applicationModulesRoutePaths.advanced['non-dictionary-heuristics'].path
      },
      {
          item: 'pro-drop', 
          path: applicationModulesRoutePaths.advanced.advanced.path + 
          applicationModulesRoutePaths.advanced["pro-drop"].path
      },
      {
          item: 'etymology', 
          path: applicationModulesRoutePaths.advanced.advanced.path + 
          applicationModulesRoutePaths.advanced["etymology"].path
      },
      {
        item: 'contractions', 
        path: applicationModulesRoutePaths.advanced.advanced.path + 
        applicationModulesRoutePaths.advanced["contractions"].path
    }
    ]
  },
  {
    item: 'administration',
    subMenu: [
      {
        item: 'language settings',
        path: applicationModulesRoutePaths.administration.administration.path +
        applicationModulesRoutePaths.administration['language-settings'].path
      },
      {
        item: 'features',
        subMenu: [{
          item: 'Definition',
          path: applicationModulesRoutePaths.administration.administration.path +
          applicationModulesRoutePaths.administration.definition.path
        },
          {
            item: 'Localization',
            path: applicationModulesRoutePaths.administration.administration.path +
            applicationModulesRoutePaths.administration.localization.path
          }
        ]
      },
      {
        item: 'data filters',
        path: applicationModulesRoutePaths.administration.administration.path +
        applicationModulesRoutePaths.administration.range.path
      },
      {
        item: 'Health Checks',
        path: applicationModulesRoutePaths.administration.administration.path +
        applicationModulesRoutePaths.administration['health-check-definitions'].path
      },
      {
        onlyAdmin: true,
        item: 'Linguist Activity',
        path: applicationModulesRoutePaths.administration.administration.path +
          applicationModulesRoutePaths.administration['linguist-activities'].path
      },
      {
        onlyAdmin: true,
        item: 'Restart Service',
        path: applicationModulesRoutePaths.administration.administration.path +
          applicationModulesRoutePaths.administration['restart-lamp-service'].path
      }]
  },
  {
    item: 'testing',
    subMenu: [
      {
        item: 'run analysis',
        path: applicationModulesRoutePaths.testconsole.path
      },
      {
        item: 'debug',
        path: applicationModulesRoutePaths.tracer.path
      },
      {
        item: 'results',
        subMenu: [{
          item: 'gold standard (analysis)',
          path: applicationModulesRoutePaths.verifyresultsanalysis.path
        }, {
            item: 'gold standard (transformation)',
            path: applicationModulesRoutePaths.verifyresultstransformation.path
          },
            {
              item: 'overview',
              path: applicationModulesRoutePaths.testoverview.path
            }
          ]
      },
      {
        item: 'corpora',
        path: applicationModulesRoutePaths.corpora.path
      },
      {
        item: 'test applications',
        path: applicationModulesRoutePaths.testproviders.path
      }
    ]
  },
  { item: 'about', path: applicationModulesRoutePaths.about.path },
];

export const punctuationRoles = [
  'genericComma',
  'sentenceTerminator',
  'bracketStart',
  'bracketEnd',
  'scopeDelimiter',
  'hyphen',
  'quoteStart',
  'quoteEnd',
  'listComma'];

export const punctuationSpaces = ['none', 'after', 'before', 'beforeAndAfter', 'original'];

export const commonTabSettings = {
  changedPreviouslySelector: 'ti-changed-previously',
  type: 'type',
  keyIndexKey: 'keyIndex',
  idIndexKey: 'idIndex',
  valueIndexKey: 'valueIndex',
  previousPageKey: 'previousPage',
  beforePreviousPageKey: 'beforePreviousPage',
  editUrlPart: 'edit',
  createUrlPart: 'create'
};

export const errorMessages = {
  actionIsNotAllowed: 'Action not allowed',
  pageDoesNotExist: 'Page doesn`t exist or you do not have the permission to access it. ' +
  'Please contact our support to request access.',
  incorrectDataInserted: 'Invalid data.'
};

export const timeZones: string[] = [
  'Afghanistan Standard Time',
  'Alaskan Standard Time',
  'Aleutian Standard Time',
  'Altai Standard Time',
  'Arab Standard Time',
  'Arabian Standard Time',
  'Arabic Standard Time',
  'Argentina Standard Time',
  'Astrakhan Standard Time',
  'Atlantic Standard Time',
  'AUS Central Standard Time',
  'Aus Central W. Standard Time',
  'AUS Eastern Standard Time',
  'Azerbaijan Standard Time',
  'Azores Standard Time',
  'Bahia Standard Time',
  'Bangladesh Standard Time',
  'Belarus Standard Time',
  'Bougainville Standard Time',
  'Canada Central Standard Time',
  'Cape Verde Standard Time',
  'Caucasus Standard Time',
  'Cen. Australia Standard Time',
  'Central America Standard Time',
  'Central Asia Standard Time',
  'Central Brazilian Standard Time',
  'Central Europe Standard Time',
  'Central European Standard Time',
  'Central Pacific Standard Time',
  'Central Standard Time',
  'Central Standard Time (Mexico)',
  'Chatham Islands Standard Time',
  'China Standard Time',
  'Cuba Standard Time',
  'Dateline Standard Time',
  'E. Africa Standard Time',
  'E. Australia Standard Time',
  'E. Europe Standard Time',
  'E. South America Standard Time',
  'Easter Island Standard Time',
  'Eastern Standard Time',
  'Eastern Standard Time (Mexico)',
  'Egypt Standard Time',
  'Ekaterinburg Standard Time',
  'Fiji Standard Time',
  'FLE Standard Time',
  'Georgian Standard Time',
  'GMT Standard Time',
  'Greenland Standard Time',
  'Greenwich Standard Time',
  'GTB Standard Time',
  'Haiti Standard Time',
  'Hawaiian Standard Time',
  'India Standard Time',
  'Iran Standard Time',
  'Israel Standard Time',
  'Jordan Standard Time',
  'Kaliningrad Standard Time',
  'Kamchatka Standard Time',
  'Korea Standard Time',
  'Libya Standard Time',
  'Line Islands Standard Time',
  'Lord Howe Standard Time',
  'Magadan Standard Time',
  'Magallanes Standard Time',
  'Marquesas Standard Time',
  'Mauritius Standard Time',
  'Mid-Atlantic Standard Time',
  'Middle East Standard Time',
  'Montevideo Standard Time',
  'Morocco Standard Time',
  'Mountain Standard Time',
  'Mountain Standard Time (Mexico)',
  'Myanmar Standard Time',
  'N. Central Asia Standard Time',
  'Namibia Standard Time',
  'Nepal Standard Time',
  'New Zealand Standard Time',
  'Newfoundland Standard Time',
  'Norfolk Standard Time',
  'North Asia East Standard Time',
  'North Asia Standard Time',
  'North Korea Standard Time',
  'Omsk Standard Time',
  'Pacific SA Standard Time',
  'Pacific Standard Time',
  'Pacific Standard Time (Mexico)',
  'Pakistan Standard Time',
  'Paraguay Standard Time',
  'Romance Standard Time',
  'Russia Time Zone 10',
  'Russia Time Zone 11',
  'Russia Time Zone 3',
  'Russian Standard Time',
  'SA Eastern Standard Time',
  'SA Pacific Standard Time',
  'SA Western Standard Time',
  'Saint Pierre Standard Time',
  'Sakhalin Standard Time',
  'Samoa Standard Time',
  'Sao Tome Standard Time',
  'Saratov Standard Time',
  'SE Asia Standard Time',
  'Singapore Standard Time',
  'South Africa Standard Time',
  'Sri Lanka Standard Time',
  'Sudan Standard Time',
  'Syria Standard Time',
  'Taipei Standard Time',
  'Tasmania Standard Time',
  'Tocantins Standard Time',
  'Tokyo Standard Time',
  'Tomsk Standard Time',
  'Tonga Standard Time',
  'Transbaikal Standard Time',
  'Turkey Standard Time',
  'Turks And Caicos Standard Time',
  'Ulaanbaatar Standard Time',
  'US Eastern Standard Time',
  'US Mountain Standard Time',
  'UTC',
  'UTC+12',
  'UTC+13',
  'UTC-02',
  'UTC-08',
  'UTC-09',
  'UTC-11',
  'Venezuela Standard Time',
  'Vladivostok Standard Time',
  'W. Australia Standard Time',
  'W. Central Africa Standard Time',
  'W. Europe Standard Time',
  'W. Mongolia Standard Time',
  'West Asia Standard Time',
  'West Bank Standard Time',
  'West Pacific Standard Time',
  'Yakutsk Standard Time'
];
