import {Directive, HostListener, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {RootLookupDialogComponent} from './root-lookup-dialog.component';
import {Root} from '../../shared/models/lexicon';

@Directive({
  selector: '[appRootLookupButtonDirective]'
})
export class RootLookupButtonDirective {

  @Output() selectedDataEvent: EventEmitter<Root> = new EventEmitter<Root>();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(
      RootLookupDialogComponent, {
        minWidth: this.isMobileView ? '96%' : '80%'
      }
    );

    dialogRef.afterClosed()
      .subscribe((selectedData: Root) => {
        this.selectedDataEvent.emit(selectedData);
      });
  }

}
