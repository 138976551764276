import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';

import {KnowledgeGraphElement, KnowledgeGraphLink, LinksTypes} from '../../shared/models/knowledge-graph';
import {SessionService} from '../../shared/services/session.service';
import {KnowledgeGraphService} from '../../shared/services/knowledge-graph.service';
import {LampUpdateResponse} from '../../shared/models/common';

@Component({
  selector: 'app-knowledge-graph-table-element',
  templateUrl: './knowledge-graph-table-element.component.html',
  styleUrls: ['./knowledge-graph-table-element.component.less']
})
export class MainListModeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() knowledgeGraphElement: KnowledgeGraphElement;
  @Input() isKnowledgeGraphModeBasic: boolean;

  private sessionSubscription: Subscription;
  public isAdmin: boolean;

  public lexemesListAsLinkList: KnowledgeGraphLink[];

  @Output() errorMessageEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() familiesIdsCommaDelimitedListToUpdateEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() currentFamilyUpdatedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() familiesIdsCommaDelimitedListForGoToEvent: EventEmitter<{ linkId: number, isLinkChecked: boolean, description: string }> =
    new EventEmitter<{ linkId: number, isLinkChecked: boolean, description: string }>();
  @Output() lexemeUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private sessionService: SessionService,
              private knowledgeGraphService: KnowledgeGraphService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscribeOnSessionChanges();
    if (this.knowledgeGraphElement && this.knowledgeGraphElement.lexemes) {
      this.lexemesListToKnowledgeGraphLinkList(this.knowledgeGraphElement.lexemes);
    }
  }

  ngOnChanges(): void {
    if (this.knowledgeGraphElement && this.knowledgeGraphElement.lexemes) {
      this.lexemesListToKnowledgeGraphLinkList(this.knowledgeGraphElement.lexemes);
    }
  }

  private subscribeOnSessionChanges(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.isAdmin = this.sessionService.isAdmin();
    });
  }

  public lexemesListToKnowledgeGraphLinkList(lexemes: { id: number, lemma: string, features?: string, discouraged?: boolean, unverified?: 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle' }[]): void {
    const lexemesListAsLinkList: KnowledgeGraphLink[] = [];

    for (let i = 0; i < lexemes.length; i++) {
      lexemesListAsLinkList[i] = {
        id: lexemes[i].id,
        description: lexemes[i].lemma,
        features: lexemes[i].features,
        unverified: lexemes[i].unverified,
        discouraged: lexemes[i].discouraged,
      };
    }

    this.lexemesListAsLinkList = lexemesListAsLinkList;
    this.changeDetectorRef.markForCheck();
  }

  public deleteLexemes(commaDelimitedIdsList: string): Promise<void> {
    return this.knowledgeGraphService
      .deleteFamilyLexemeLinksForTheSpecifiedFamily(this.knowledgeGraphElement.id.toString(), commaDelimitedIdsList)
      .then((resultMessage: LampUpdateResponse) => {
        this.handleSuccessfulServerResponse(resultMessage);
      });
  }

  public updateLexemes(commaDelimitedIdsList: string, familyId?: string): Promise<void> {
    const updatedFamilyId = familyId ? familyId : this.knowledgeGraphElement.id.toString();
    return this.knowledgeGraphService
      .updateFamilyLexemeLinksForTheSpecifiedFamily(updatedFamilyId, commaDelimitedIdsList)
      .then((resultMessage: LampUpdateResponse) => {
        
        this.handleSuccessfulServerResponse(resultMessage);
      });
  }

  public moveLexemes($event: { commaDelimitedIdsList: string, commaDelimitedListOfTargetFamilies: string }): Promise<void> {
    return this.knowledgeGraphService
      .moveFamilyLexemeLinksToTheOtherFamilies(this.knowledgeGraphElement.id.toString(),
        $event.commaDelimitedListOfTargetFamilies, $event.commaDelimitedIdsList)
      .then((resultMessage: LampUpdateResponse) => {
        if (resultMessage.success) {
          this.familiesIdsCommaDelimitedListToUpdateEvent.next(this.knowledgeGraphElement.id.toString() +
            ',' + $event.commaDelimitedListOfTargetFamilies);
        }
        this.handleSuccessfulServerResponse(resultMessage, true);
      });
  }

  private updateKnowlegdgeGraphElementParts(target: KnowledgeGraphElement, src: KnowledgeGraphElement) {
    // no, we can't just assign, because that would disconnect the reference, 
    // and then the outer subscribers will not be updated
    target.domainMembers = src.domainMembers;
    target.domains = src.domains;
    target.featureList = src.featureList;
    target.hypernyms = src.hypernyms;
    target.hyponyms = src.hyponyms;
    target.antonyms = src.antonyms;
    target.inheritedFeatureList = src.inheritedFeatureList;
    target.definition = src.definition;
    target.derived = src.derived;
    target.domainMembers = src.domainMembers;
    target.lexemeList = src.lexemeList;
    target.lexemes = src.lexemes;
    target.properNoun = src.properNoun;
    target.representativeLemma = src.representativeLemma;
    target.translationUrl = src.translationUrl;
  }

  public updateCurrentKnowledgeGraphElement(): Promise<void> {
    let thisElement = this;
    return this.knowledgeGraphService
      .getKnowledgeGraph(this.knowledgeGraphElement.id.toString(), 'id')
      .then((knowledgeGraphElementInArray: KnowledgeGraphElement[]) => {
        try {
          thisElement.updateKnowlegdgeGraphElementParts(thisElement.knowledgeGraphElement, knowledgeGraphElementInArray[0]);
          thisElement.lexemesListToKnowledgeGraphLinkList(thisElement.knowledgeGraphElement.lexemes);
          thisElement.changeDetectorRef.markForCheck();
          thisElement.lexemeUpdate.next(true);
        } catch (error) {

        }
      });
  }

  public getHyponymsForTheCurrentRange($event: { key: string, value: string }): Promise<void> {
    return this.knowledgeGraphService
      .getHyponymFamiliesListForTheSpecifiedFamily(this.knowledgeGraphElement.id.toString(),
        $event.value)
      .then((hyponymFamiliesList) => {
        if (hyponymFamiliesList) {
          this.knowledgeGraphElement.hyponyms = [...hyponymFamiliesList];
        }
      });
  }

  public getDomainMembersForTheCurrentRange($event: { key: string, value: string }): Promise<void> {
    return this.knowledgeGraphService
      .getDomainMemberFamiliesListForTheSpecifiedFamily(this.knowledgeGraphElement.id.toString(),
        $event.value.toString())
      .then((domainMemberFamiliesList) => {
        if (domainMemberFamiliesList) {
          this.knowledgeGraphElement.domainMembers = [...domainMemberFamiliesList];
        }
      });
  }

  public refreshAntonyms($event: { key: string, value: string }): Promise<void> {
    return this.knowledgeGraphService.getKnowledgeGraph(this.knowledgeGraphElement.id.toString(),
      "id", "false").then((kge: KnowledgeGraphElement[]) => {
          if (kge && kge[0]) {
            this.knowledgeGraphElement.antonyms = kge[0].antonyms;
          }
      }
      /*
      .getAntonymFamiliesListForTheSpecifiedFamily(this.knowledgeGraphElement.id.toString())
      .then((hyponymFamiliesList) => {
        if (hyponymFamiliesList) {
          this.knowledgeGraphElement.hyponyms = [...hyponymFamiliesList];
        }
      }
      */
      );
  }

  private handleSuccessfulServerResponse(resultMessage: LampUpdateResponse, stopUpdate?: boolean): Promise<void> {
    if (!resultMessage.success) {
      this.errorMessageEventEmitter.next(resultMessage.error);
    }
    if (stopUpdate) {
      return Promise.resolve();
    }
    return this.updateCurrentKnowledgeGraphElement();
  }

  public deleteFamilyLinks(typeOfTheLinksToInsert: LinksTypes, commaDelimitedIdsList: string): Promise<void> {
    this.errorMessageEventEmitter.next('');
    const familyId = this.knowledgeGraphElement.id.toString();
    this.currentFamilyUpdatedEvent.next(true);

    return this.knowledgeGraphService
      .deleteFamilyLexemeLinksForTheFamilySelectionPage(familyId, typeOfTheLinksToInsert, commaDelimitedIdsList)
      .then((resultMessage: LampUpdateResponse) => {
        this.handleSuccessfulServerResponse(resultMessage);
      });
  }

  public updateFamilyLinks(typeOfTheLinksToInsert: LinksTypes, commaDelimitedIdsList: string): Promise<void> {
    this.errorMessageEventEmitter.next('');
    const familyId = this.knowledgeGraphElement.id.toString();
    this.currentFamilyUpdatedEvent.next(true);

    return this.knowledgeGraphService
      .updateFamilyLexemeLinksForTheFamilySelectionPage(familyId, typeOfTheLinksToInsert, commaDelimitedIdsList)
      .then((resultMessage: LampUpdateResponse) => {
        this.handleSuccessfulServerResponse(resultMessage);
      });
  }

  public deleteHypernymsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.deleteFamilyLinks(LinksTypes.hypernyms, commaDelimitedIdsList);
  }

  public deleteDomainsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.deleteFamilyLinks(LinksTypes.domains, commaDelimitedIdsList);
  }

  public deleteDerivedLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.deleteFamilyLinks(LinksTypes.derivedItems, commaDelimitedIdsList);
  }

  public deleteHyponymsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.deleteFamilyLinks(LinksTypes.hyponyms, commaDelimitedIdsList);
  }

  public deleteDomainMembersLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.deleteFamilyLinks(LinksTypes.domainMembers, commaDelimitedIdsList);
  }

  public deleteAntonymsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.deleteFamilyLinks(LinksTypes.antonyms, commaDelimitedIdsList);
  }

  public updateHypernymsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.updateFamilyLinks(LinksTypes.hypernyms, commaDelimitedIdsList);
  }

  public updateDomainsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.updateFamilyLinks(LinksTypes.domains, commaDelimitedIdsList);
  }

  public updateDerivedLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.updateFamilyLinks(LinksTypes.derivedItems, commaDelimitedIdsList);
  }

  public updateHyponymsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.updateFamilyLinks(LinksTypes.hyponyms, commaDelimitedIdsList);
  }

  public updateDomainMembersLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.updateFamilyLinks(LinksTypes.domainMembers, commaDelimitedIdsList);
  }

  public updateAntonymsLinks(commaDelimitedIdsList: string): Promise<void> {
    return this.updateFamilyLinks(LinksTypes.antonyms, commaDelimitedIdsList);
  }

  public sendCheckedFamiliesEvent($event: { linkId: number, isLinkChecked: boolean, description: string }): void {
    this.familiesIdsCommaDelimitedListForGoToEvent.next($event);
  }

  public addSilentDomains(selectedFamilies: string) {
    // this.isLoading = true;
    if (!selectedFamilies || !selectedFamilies.length) {
      this.errorMessageEventEmitter.emit(`Current node doesn't have any domains`);
      return;
    }

    this.knowledgeGraphService.addSilentDomains(this.knowledgeGraphElement.id, selectedFamilies)
      .then((message: LampUpdateResponse) => {
        // this.isLoading = false;
        if (message.success) {
          this.errorMessageEventEmitter.emit();
          this.updateCurrentKnowledgeGraphElement();
        } else {
          this.errorMessageEventEmitter.emit(message.error);
        }
      });
  }

  public addGenerationDomains(isSuccess: boolean) {
    if(isSuccess) {
      this.handleSuccessfulServerResponse({ success: isSuccess});
    }    
  }

  ngOnDestroy(): void {
    this.sessionSubscription.unsubscribe();
  }
}
