import {Language, emptyLanguage} from './language';
import {UserRoles} from './user-roles.enum';
import {FeatureListDefinition, FeatureTypeList} from './feature';

/**
 * Set of database ranges for the filtering.
 */
export interface Ranges {
  Key: string;
  Value: { Key: string, Value: { description: string, where: string, language?: number } }[];
}

/**
 * Pair of id + description.
 */
export interface IdAndDescription {
  /**
   * Numeric ID.
   */
  id: number;
  /**
   * Human-friendly description.
   */
  description?: string;
}

/**
 * Recently edited or selected entries in a table.
 */
export interface RecentEntriesForTable {
  /**
   * Table name.
   */
  Key: string;
  /**
   * Set of the recent entries. 
   */
  Value: IdAndDescription [];
}

/**
 * Local copy of the server Session object.  
 * Contains permissions, active and preferred languages, etc.
 */
export interface SessionData {
  /**
   * Token used to authorize. Obtained when logging in.
   */
  token: string;
  /**
   * Is the user an administrator?
   */
  admin: boolean;
  /**
   * Language being viewed.
   */
  targetLanguage?: Language;
  /**
   * Default active language.
   */
  defaultLanguage: Language;
  defaultLanguageOnStartup: Language;
  /**
   * Time of the last logon.
   */
  loggedOnSince: string;
  /**
   * When the session is supposed to end (session start + maximum amount of time allowed).
   */
  sessionEnd: string;
  /**
   * Language preferred by the user as a UI language.
   */
  preferredLanguage1: Language;
  /**
   * Language preferred by the user as a UI language.
   */
  preferredLanguage2?: Language;
  /**
   * Is the user only allowed to edit the lexicon?
   */
  editLexiconOnly: boolean;
  /**
   * Is the user only allowed to edit the Corpora module?
   */
  corpusContributorOnly?: boolean;
  /**
   * Can the user edit the currently selected language?
   */
  canEditCurrentLanguage?: boolean;
  guest: boolean;
  /**
   * External IP address of a machine that started the session as viewed by the server. 
   */
  ip: string;
  /**
   * Definition of all the features. 
   * Since it's the source, to change it
   */
  features?: FeatureTypeList[];
  ranges?: Ranges [];
  /**
   * Main language in the system (usually English).
   */
  referenceLanguage: Language;
  /**
   * Recently touched or edited entries.
   */
  recent?: RecentEntriesForTable [];
  /**
   * Is the user authenticated?
   */
  isAuthenticated?: boolean;
  /**
   * Must the user compile the current language model? 
   * If changes were made after the last compilation, then yes.
   */
  mustCompile?: boolean;
  /**
   * When last compilation ran.
   */
  lastCompilation?: string;
  /**
   * How long since the last compilation ran.
   */
  lastCompilationRelative?: string;
};
