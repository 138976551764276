<div class="ti-statistics">
  <mat-progress-spinner *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-spinner>
  <button
    *ngIf="!showProgressBar && !statistics"
    mat-raised-button
    color="accent"
    (click)="calculateStatistics()">
    <mat-icon aria-hidden="true">bar_chart</mat-icon>
    {{'headers.calculate-statistics' | translate}}
  </button>
  <div class="ti-statistics__list" *ngIf="statistics">
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--larger-font">{{'headers.lexeme-families' | translate}}</span>
      <span class="ti-statistics__quantity
      {{lexemeFamiliesResult}} ti-text-bold">
      {{statistics.LanguageLexemeFamilyCount}} /
      {{statistics.TotalLexemeFamilyCount}}</span>
    </p>
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--smaller-font">{{'headers.proper-noun-families' | translate}}</span>
      <span class="ti-statistics__quantity
      {{properFamiliesResult}}">
      {{statistics.LanguageProperNounLexemeFamilyCount}} /
      {{statistics.TotalProperNounLexemeFamilyCount}} </span>
    </p>
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--smaller-font">{{'headers.nonproper-noun-families' | translate}}</span>
      <span class="ti-statistics__quantity
      {{nonProperNameFamiliesResult}}">
      {{statistics.LanguageNonProperNounLexemeFamilyCount}} /
      {{statistics.TotalNonProperNounLexemeFamilyCount}}</span>

    </p>
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--smaller-font">{{'headers.common-concepts' | translate}}</span>
      <span class="ti-statistics__quantity
      {{commonConceptsFamiliesResult}}">
      {{statistics.LanguageFrequentLexemeFamilyCount}} /
      {{statistics.TotalFrequentLexemeFamilyCount}}</span>
    </p>
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--larger-font">{{'COMMON.lexemes' | translate}}</span>
      <span class="ti-statistics__quantity ti-text-bold">
      {{statistics.LanguageLexemeCount}}</span>
    </p>
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--larger-font">{{'headers.phrase-families' | translate}}</span>
      <span class="ti-statistics__quantity
      {{phraseFamiliesResult}} ti-text-bold">
      {{statistics.LanguagePhraseFamilies}} /
      {{statistics.TotalPhraseFamilies}}</span>
    </p>
    <p class="ti-statistics__item">
      <span class="ti-statistics__description ti-statistics__description--larger-font">{{'headers.inflection-patterns' | translate}}</span>
      <span class="ti-statistics__quantity ti-text-bold">
      {{statistics.InflectedFormRuleCount}}</span>
    </p>
  </div>
</div>
