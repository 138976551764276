 <ul class="ti-sidebar-menu__menu" role="navigation">
  <ng-template ngFor let-item let-index="index" [ngForOf]="menuItems">
    <li class="ti-sidebar-menu__menu-item" *ngIf="item.subMenu">
      <a class="ti-sidebar-menu__menu" (click)="toggleMenuItems(index)">{{item.item | titlecase}}
        <mat-icon *ngIf="isCollapsedMenuItems(index)" aria-hidden="true">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="!isCollapsedMenuItems(index)" aria-hidden="true">keyboard_arrow_down</mat-icon>
      </a>
      <ul class="ti-sidebar-menu__submenu" id="{{item.item}}"
          [@slideInOut]="isCollapsedMenuItems(index)">
        <ng-template ngFor let-subitem let-subitemIndex="index" [ngForOf]="item.subMenu">
          <li class="ti-sidebar-menu__submenu-item" *ngIf="!subitem.item">
            <a routerLinkActive="active-link" routerLink="/404">{{subitem | titlecase}}</a>
          </li>
          <li class="ti-sidebar-menu__submenu-item"
            *ngIf="subitem.item && !subitem.subMenu && (!subitem.onlyAdmin || (subitem.onlyAdmin && permissionLevel == 'admin'))">
            <a routerLinkActive="active-link" routerLink="/{{subitem.path}}">{{subitem.item | titlecase}}</a>
          </li>
          <li class="ti-sidebar-menu__submenu-item" *ngIf="subitem.item && subitem.subMenu">
            <a routerLinkActive="active-link" (click)="toggleMenuItems(index, subitemIndex)">{{subitem.item | titlecase}}
              <mat-icon *ngIf="isCollapsedMenuItems(index, subitemIndex)" aria-hidden="true">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="!isCollapsedMenuItems(index, subitemIndex)" aria-hidden="true">keyboard_arrow_down</mat-icon>
            </a>
            <ul class="ti-sidebar-menu__submenu" id="{{subitem.item}}"
                [@slideInOut]="isCollapsedMenuItems(index, subitemIndex)">
              <ng-template ngFor let-subsubitem [ngForOf]="subitem.subMenu">
                <li class="ti-sidebar-menu__submenu-item" *ngIf="!subsubitem.item">
                  <a routerLinkActive="active-link" routerLink="/404">{{subsubitem | titlecase}}</a>
                </li>
                <li class="ti-sidebar-menu__submenu-item"
                  *ngIf="subsubitem.item && (!subsubitem.onlyAdmin || (subsubitem.onlyAdmin && permissionLevel == 'admin'))">
                  <a routerLinkActive="active-link" routerLink="/{{subsubitem.path}}">{{subsubitem.item | titlecase}}</a>
                </li>
              </ng-template>
            </ul>
          </li>
        </ng-template>
      </ul>
    </li>
    <li class="ti-sidebar-menu__menu-item"
      *ngIf="!item.subMenu && (!item.onlyAdmin || (item.onlyAdmin && permissionLevel == 'admin'))">
      <a routerLinkActive="active-link" routerLink="{{item.path ? item.path : '/404'}}">
        {{item.item | titlecase}}
      </a>
    </li>
  </ng-template>
</ul>
