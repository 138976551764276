import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {Translated} from '../../shared/classes/translated.class';
import {SessionService} from '../../shared/services/session.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { MAT_DIALOG_DATA } from '@angular/material';

/**
 * Asks the user, "are you sure"?
 */
@Component({
  selector: 'app-are-you-sure-dialog',
  templateUrl: './are-you-sure-dialog.component.html'
})
export class AreYouSureDialogComponent extends Translated implements OnInit, OnDestroy {
  constructor(
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translateService, localStorageHelper, sessionService);
  }

  initThisSubtypeFromSession(): void {
  }

  ngOnInit(): void {
    this.initFromSession();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromSessionIfSubscribed();
  }
}
