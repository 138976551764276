<h1>{{ 'TEST.providers' | translate }}</h1>
<div class="ti-menu">
<div class="ti-menu__data-wrapper">
  <mat-table *ngIf="providers" #table [dataSource]="providers">
    <!--- every column is in an ng container element, linked to a definition, with a header, and the value as element.something -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> {{ 'COMMON.id' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"
      > {{element.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> {{ 'COMMON.description' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"
        > {{element.description}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="typename">
        <mat-header-cell *matHeaderCellDef> {{ 'TEST.type-version' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"
        > {{element.typename}}&nbsp;{{element.version}}
        </mat-cell>
      </ng-container>
  
    <!--- it may have dynamic headers and edit buttons -->
    <ng-container matColumnDef="updateButtons">
      <mat-header-cell
       *matHeaderCellDef
      >&nbsp;
        <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration"
      [routerLink]="'/testproviders/edit/0'"
      [queryParams]="{editedId: 0}"
    >
            <mat-icon aria-hidden="true">add</mat-icon>
        </button>
    </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <!--- this is where the content goes -->
        <div *ngIf="canEditNonLexicon()">
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration"
                  [routerLink]="'/testproviders/edit/'+element.id"
                  [queryParams]="{editedId: element.id}">
            <mat-icon aria-hidden="true">edit</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="ti-link-underline-text-decoration"
                  appOpenConfirmDialog
                  (doesUserConfirmEvent)="deleteRowById(element.id, $event)">
            <mat-icon aria-hidden="true">delete</mat-icon>
          </button>
        </div>

      </mat-cell>
    </ng-container>
  
  
    <!-- BEGIN column linking -->
    <mat-header-row *matHeaderRowDef="listColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: listColumns;"
             appScrollToEvent
             appScrollToChangedPreviouslyElement
             [shouldBeEmittedScrollToEventEvent]="(lastId === row.id)"></mat-row>
    <!-- END column linking -->
  </mat-table>

</div>
</div>
