import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

import {ErrorMessageComponent} from './error-message.component';
import {DirectivesModule} from '../../shared/directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    DirectivesModule,
  ],
  declarations: [ErrorMessageComponent],
  exports: [ErrorMessageComponent]
})
export class ErrorMessageModule {
}
