import {FeatureValue, ModelWithRelevantFeature} from './feature';

/**
 * Language definitions.
 */
export interface Language {
  /**
   * Edit request ID (if being edited)
   */
  requestId?: string;
  /**
   * Standard ISO code.
   */
  ISOCode: string;
  /**
   * English name.
   */
  englishName: string;
  /**
   * Internal numeric ID.
   */
  id: number;
  /**
   * Legacy string code, if available.
   */
  legacyCode: string;
  /**
   * Native name.
   */
  name: string;
  /**
   * Preferred encoding.
   */
  nativeEncoding: string;
  /**
   * Is the script right to left? (Arabic, Hebrew)
   */
  rightToLeft?: boolean;
  /**
   * Fallback language IDs.
   */
  fallbackIds?: number[];
  /**
   * Lexeme fallback language ID.
   */
  lexemeFallback?: string;
  /**
   * Phrases fallback language ID.
   */
  phraseFallback?: string;
  fallback?: string;
}

export const emptyLanguage: Language = {
  ISOCode: '',
  englishName: '',
  id: 0,
  legacyCode: '',
  name: '',
  nativeEncoding: '',
  rightToLeft: false,
  fallbackIds: [0, 0, 0, 0]
};

/**
 * How the words are segmented.
 */
export enum LanguageSegmentation {
  /**
   * White spaces (e.g. English, French, Spanish, Russian)
   */
  whitespace = 'whitespace',
  /**
   * White spaces but also with compound words (German, Dutch, Norwegian, Finnish, Hungarian, etc.)
   */
  decompounding = 'decompounding',
  /**
   * Logographic languages not using white space (Chinese scripts)
   */
  logographic = 'logographic',
  /**
   * Non-logographic languages not using white spaces (Japanese, Thai)
   */
  longNoWhiteSpace = 'longNoWhiteSpace',
  /**
   * Special type of decompounding segmentation, suitable for Korean.
   */
  korean = 'korean'
}

export const languageSegmentationOptions = [
  {key: 'whitespace', value: 'White space (e.g. English, Spanish, Russian, Arabic)'},
  {key: 'decompounding', value: 'White space + compounds (e.g. German, Dutch)'},
  {key: 'logographic', value: 'No white spaces, logographic (e.g. Chinese) '},
  {key: 'longNoWhiteSpace', value: 'No white spaces, non-logographic (e.g. Thai)'},
  {key: 'korean', value: 'White space + compounds with clitics (Korean)'}
];

export interface LanguageDescription extends ModelWithRelevantFeature {
  id?: number;
  ietf?: string;
  name?: string;
  englishName?: string;
  systemLanguage?: string;
  segmentation?: LanguageSegmentation;
  compoundHead?: FeatureValue;
  commalessPhraseAutogen?: FeatureValue;
  agentlessPhraseAutogenRequired?: FeatureValue;
  agentlessPhraseAutogen?: FeatureValue;
  isHyphenationMWEMarker?: string;
  encoding?: string;
  charset?: string;
  legacyCode?: string;
  inflectionSanityCheckRegex?: string;
  font?: string;
  wordFallback1?: number;
  wordFallback2?: number;
  properNounFallback?: number;
  phraseFallback?: number;
  emojiFallback?: number;
  maximumPunctuationNonbreakPrefix?: string;
  doProperNounsInflect?: string;
  features?: { type?: string, index: number, value: string }[];
  translationReferenceURL?: string;
  lexemeReferenceURL?: string;

  complementPhraseFallback?: boolean;
  nonproperFallbackNativeInflection?: boolean;
  properFallbackNativeInflection?: boolean;
  alwaysAutogenMisspellings?: boolean;
  corpusUrl?: string;
  customCollation?: string;
  allowGenerativeAI?: boolean;
}
