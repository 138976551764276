<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{'knowledge-graph.add-domains-by-ai' | translate}}</h2>
</div>
<mat-dialog-content>

  <!-- Dialog Content -->
  <div>
    <div class="ti-form__one-line-fields ti-form__one-line-fields--error">
      <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    </div>
    <mat-progress-bar *ngIf="isLoading" color="accent" mode="determinate" [value]="processed / totalFamilies * 100"></mat-progress-bar>

    <p class="progress" *ngIf="processed > 0">Processing {{processed}}/{{totalFamilies}}</p>

    <div class="ti-form-wrapper ti-form__one-line-fields">
      <form class="ti-form" *ngIf="form" [formGroup]="form">
        
        <mat-form-field color="accent" class="ti-form__prompt mat-form-field-flex">
          <textarea 
            matInput
            name="prompt"
            (keyup)="validatePrompt($event)" 
            [(ngModel)]="prompt"
            formControlName="prompt"
            [disabled]="isLoading"
            [placeholder]="'COMMON.prompt' | translate"></textarea>
        </mat-form-field>
        <mat-checkbox class="ti-form__column ti-form__one-line-fields--with-checkbox" matInput name="silent" [disabled]="isLoading"
          [(ngModel)]="silent" (change)="clearTheErrorMessage()" formControlName="silent">
          <span class="ti-form__label">{{'COMMON.silent' | translate}}</span>
        </mat-checkbox>
        <mat-checkbox class="ti-form__column ti-form__one-line-fields--with-checkbox" matInput name="includeHyponyms" [disabled]="isLoading"
          [(ngModel)]="includeHyponyms" (change)="validatePrompt()" formControlName="includeHyponyms">
          <span class="ti-form__label">{{'knowledge-graph.include-hyponyms' | translate}}</span>
        </mat-checkbox>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="ti-changes-have-been-saved__actions">
  <button #btnClose mat-button mat-dialog-close [disabled]="isLoading">{{'COMMON.close' | translate}}</button>
  <button mat-raised-button color="accent" (click)="generate()" [disabled]="invalidPrompt || isLoading">{{'COMMON.generate' | translate}}</button>
</mat-dialog-actions>