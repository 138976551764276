<div class="ti-table-wrapper">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <mat-progress-bar *ngIf="showProgressbar" color="accent" mode="indeterminate"></mat-progress-bar>
  <div *ngIf="isEmptyRangeLocalizationList"><p>{{'nothing-to-show' | translate}}</p></div>
  <div *ngIf="!isEmptyRangeLocalizationList">
    <mat-table #nonBreaksTable [dataSource]="rangeLocalizationList">
      <ng-container matColumnDef="language-id">
        <mat-header-cell *matHeaderCellDef>{{'COMMON.language-id' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element;">
          <div class="ti-form-element__wrapper">
            <div
              class="ti-form-element__language-id"
              *ngIf="!(editId === element.index)">{{element.languageId}}</div>
            <div class="ti-form-element__language-selector">
              <app-universal-language-select
                [description]="' '"
                [initialLanguageId]="element.languageId"
                [hasToBeDisabled]="(editId !== element.index)"
                (selectedLanguageChanged)="updateLanguage($event)"
              ></app-universal-language-select>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>{{'COMMON.description' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element;">
          <div class="ti-form-element__wrapper">
            <div *ngIf="!(editId === element.index)"
                  class="ti-form-element__description">{{element.description}}</div>
            <textarea
              *ngIf="(editId === element.index)"
              class="ti-form-element__description"
              matInput
              [(ngModel)]="element.description"
              placeholder="{{'COMMON.description' | translate}}"
            >{{element.description}}</textarea>

            <div class="ti-form__button-panel" class="ti-form-element__buttons">
              <button mat-mini-fab color="primary"
                      [disabled]="!doesUserHaveAccessDelete"
                      *ngIf="!(editId === element.index)"
                      (click)="edit(element.index)"
              >
                <mat-icon aria-hidden="true">edit</mat-icon>
              </button>
              <button mat-mini-fab color="primary"
                      [disabled]="!doesUserHaveAccessDelete"
                      *ngIf="!(editId === element.index)"
                      appOpenConfirmDialog
                      (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, element.rangeId, element.languageId)"
              >
                <mat-icon aria-hidden="true">delete</mat-icon>
              </button>
              <button mat-mini-fab color="primary"
                      [disabled]="!doesUserHaveAccessDelete"
                      matTooltip="The localization exists for the current language"
                      [matTooltipDisabled]="!isDuplicates"
                      *ngIf="(editId === element.index)"
                      (click)="update(element)">
                <mat-icon aria-hidden="true">done</mat-icon>
              </button>
              <button mat-mini-fab color="primary"
                      [disabled]="!doesUserHaveAccessDelete"
                      *ngIf="(editId === element.index)"
                      (click)="revert(element.languageId)">
                <mat-icon aria-hidden="true">clear</mat-icon>
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
