import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {SessionService} from '../../shared/services/session.service';
import {LexiconService} from '../../shared/services/lexicon.service';
import {FeaturesService} from '../../shared/services/features.service';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {commonTabSettings} from '../../shared/constants/application-constants';

import {
  AdvancedCriteria, emptyLexemeFamilyConnection, Lexeme,
  LexemeFamilyConnection
} from '../../shared/models/lexicon';
import {Family} from '../../shared/models/knowledge-graph';
import {FeatureListDefinition, FeatureValue} from '../../shared/models/feature';
import {LampUpdateResponse} from '../../shared/models/common';

import {collectObjectChanges, toJsonObject} from '../../shared/helpers/object.helper';
import {frequencyValidator} from '../../shared/models/form.validator';
import {applicationConstants, applicationModulesRoutePaths} from '../../shared/constants/application-constants';
import {LocalStorageHelper} from "../../shared/helpers/localhost.helper";
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lexeme-family-connection-edit',
  templateUrl: './lexeme-family-connection-edit.component.html',
  styleUrls: ['./lexeme-family-connection-edit.component.less']
})

export class LexemeFamilyConnectionEditComponent extends Translated implements OnInit, OnDestroy {
  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();
  public showSpinner: boolean;
  public isNew: boolean;
  public doesUserHaveAccessToEdit: boolean;

  private subscriptions: Subscription[] = [];

  public lexemeFamilyConnection: LexemeFamilyConnection;
  public initialLexemeFamilyConnection: LexemeFamilyConnection;
  public form: FormGroup;

  public relevantStylisticFeatureList: FeatureListDefinition[];
  public styleFeatureType: string = applicationConstants.styleFeatureType;
  public selectedIndexSettingName = "lexicon_" + commonTabSettings.idIndexKey;

  public relevantGrammarFeatureList: FeatureListDefinition[];
  public relevantStyleFeatureList: FeatureListDefinition[];
  public semanticFeatureList: FeatureListDefinition[];

  constructor(public sessionService: SessionService,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private lexiconService: LexiconService,
              public localStorageHelper: LocalStorageHelper,
              public translateService: TranslateService,
              private routerHelper: RouterHelper, private featuresService: FeaturesService) {
                super(translateService, localStorageHelper, sessionService);
  }

  initThisSubtypeFromSession(): void {
  }

  ngOnInit(): void {
    this.getFeaturesList();
    this.addSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subsciprion => subsciprion.unsubscribe());
    if (this.lexemeFamilyConnection && this.lexemeFamilyConnection.lexemeId)
      this.localStorageHelper.setTabSetting(this.selectedIndexSettingName, this.lexemeFamilyConnection.lexemeId.toString());
  }

  private getCurrentLexeme(id: number): Promise<Lexeme> {
    return this.lexiconService.getLexeme(id);
  }

  private addSubscriptions(): void {
    const routeSubscription = this.route.data.subscribe((data: {
      id: number,
      value: number | string
    }) => {
      this.showSpinner = true;
      this.isNew = (data.value === undefined);

      if (this.isNew) {
        this.getCurrentLexeme(data.id).then((lexeme: Lexeme) => {

          this.lexemeFamilyConnection = toJsonObject(emptyLexemeFamilyConnection) as LexemeFamilyConnection;
          this.initialLexemeFamilyConnection = toJsonObject(emptyLexemeFamilyConnection) as LexemeFamilyConnection;

          this.lexemeFamilyConnection.lexemeId = data.id;
          this.lexemeFamilyConnection.lemma = lexeme.lemma;

          this.createForm();
        });
      } else {
        this.getLexemeFamilyConnection(data.id, parseInt(data.value.toString(), 10));
      }
    });

    const sessionSubscription = this.sessionService.currentSessionSource.subscribe((session) => {
      this.doesUserHaveAccessToEdit = this.sessionService.canEditLexicon();
    });
    this.subscriptions = [routeSubscription, sessionSubscription];
  }

  private getLexemeFamilyConnection(lexemeId: number, familyId: number): Promise<void> {
    return this.lexiconService.getLexemeFamilyConnection(lexemeId, familyId)
      .then((lexemeFamilyConnection: LexemeFamilyConnection) => {
        this.lexemeFamilyConnection = toJsonObject(lexemeFamilyConnection) as LexemeFamilyConnection;
        this.initialLexemeFamilyConnection = toJsonObject(lexemeFamilyConnection) as LexemeFamilyConnection;
        this.createForm();
      });
  }

  public createForm(): void {
    if (this.lexemeFamilyConnection) {
      this.form = this.formBuilder.group({
        discouraged: [this.lexemeFamilyConnection.discouraged],
        frequency: [this.lexemeFamilyConnection.frequency, frequencyValidator()],
      });
    }
    this.showSpinner = false;
  }

  public saveUpdatedFamily($event: { commaDelimitedListOfFamilyIds: string, family: Family }): void {
    if ($event && $event.family) {
      this.lexemeFamilyConnection.familyId = $event.family.id;
      this.lexemeFamilyConnection.familyDescription = $event.family.description || $event.family.definition;
    }
  }

  public reactOnSelectedAdvancedCriteria(advancedCriteria: AdvancedCriteria): void {
    if (advancedCriteria) {
      this.lexemeFamilyConnection.advancedCriteriaDescription = advancedCriteria.description;
      this.lexemeFamilyConnection.advancedCriteriaId = advancedCriteria.id;
    }
  }

  public clearAdvancedCriteria(): void {
    this.lexemeFamilyConnection.advancedCriteriaDescription = '';
    this.lexemeFamilyConnection.advancedCriteriaId = 0;
  }

  public getFeatureList(): Promise<void> {
    return this.featuresService.createFilteredRelevantFeatureList(this.lexemeFamilyConnection ? this.lexemeFamilyConnection.relevant : undefined, this.styleFeatureType).then((relevantStylisticFeatureList) => {
      this.relevantStylisticFeatureList = relevantStylisticFeatureList;
    });
  }

  private getFeaturesList(): Promise<void> {
    const relevant = this.lexemeFamilyConnection ? this.lexemeFamilyConnection.relevant : undefined;
    return this.featuresService.createFilteredRelevantFeatureList(relevant, applicationConstants.defaultFeatureType).then((filteredGrammarFeatures) => {
      this.relevantGrammarFeatureList = filteredGrammarFeatures;
      return this.featuresService.createFilteredRelevantFeatureList(relevant, applicationConstants.styleFeatureType).then((filteredStyleFeatures) => {
        this.relevantStyleFeatureList = filteredStyleFeatures;
        return this.featuresService.getFeatureList(applicationConstants.semanticFeatureType
        ).then((semanticFeatures: FeatureListDefinition[]) => { this.semanticFeatureList = semanticFeatures; });
      });
    });
  }

  public handleStylisticFeatureChange($event: FeatureValue[]): void {
    this.lexemeFamilyConnection.style = $event;
  }

  private back(): void {
    this.routerHelper.back();
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  public showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public cancel(): void {
    this.back();
  }

  public save(): Promise<void> {
    const changes = collectObjectChanges(this.initialLexemeFamilyConnection, this.lexemeFamilyConnection) as LexemeFamilyConnection;
    changes.lexemeId = this.lexemeFamilyConnection.lexemeId;
    changes.discouraged = this.lexemeFamilyConnection.discouraged;
    if (!changes.familyId) {
      changes.familyId = this.initialLexemeFamilyConnection.familyId;
    }
    if (changes.style && changes.style[0]) {
      changes.style.forEach(element => (element.type = this.styleFeatureType));
    }

    if (this.isNew) {
      return this.lexiconService.createLexemeFamilyConnection(changes)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
        });
    } else {
      return this.lexiconService.updateLexemeFamilyConnection(changes)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
        });
    }
  }

  private reactOnSuccessfulResponseMessage(successfulResponseMessage: LampUpdateResponse): void {
    if (successfulResponseMessage.success) {
      this.back();
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  public reactOnUpdateAdvancedCriteria(): void {
    this.lexiconService.getAdvancedCriteriaById(this.lexemeFamilyConnection.advancedCriteriaId)
      .then((advancedCriteria: AdvancedCriteria) => {
        this.lexemeFamilyConnection.advancedCriteriaDescription = advancedCriteria.description;
      });
  }
}
