import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UnknownWordsDialogComponent } from './unknown-words-dialog.component';

@Directive({
  selector: '[appOpenUnknownWordsDialog]'
})
export class OpenUnknownWordsDialogDirective {
  @Output() closeDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() corporaId: number;

  private isMobileView: boolean;

  constructor(private dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(UnknownWordsDialogComponent, {
      minWidth: this.isMobileView ? '95%' : '75%',
      data: {
        corporaId: this.corporaId
      },
      id: 'UnknownWordsDialog'
    });

    dialogRef.afterClosed()
      .subscribe(result => this.closeDialogEvent.emit(result));
  }

}
