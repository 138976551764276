import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {SharedModule} from '../../shared/_module/shared.module';

import {UniversalLanguageSelectComponent} from './universal-language-select.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/app/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  declarations: [UniversalLanguageSelectComponent],
  exports: [UniversalLanguageSelectComponent]
})
export class UniversalLanguageSelectModule { }
