<mat-progress-bar color="accent" mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>

<mat-table *ngIf="lexemesForMerge && !showSpinner" #lexemesTable [dataSource]="lexemesForMerge">
  <ng-container matColumnDef="lexeme-id">
    <mat-header-cell *matHeaderCellDef>{{'COMMON.id' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span>{{element.id}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>{{'COMMON.details' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span>{{element.lemma}}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

