<h1>{{ (isNew() ? 'TEST.testprovider-creation' : 'TEST.testprovider-editing') | translate}}</h1>
<div class="ti-progress-detectors">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  </div>
<div class="ti-form-wrapper ti-form__column-fields">
  <form class="ti-form" *ngIf="testproviderForm" [formGroup]="testproviderForm">
      <div *ngIf="_providerData.id" class="ti-form__one-line-fields">
        <span class="ti-form__id-key">{{'COMMON.id' | translate}}</span>
        &nbsp;<span class="ti-form__id-value">{{_providerData.id}}</span>
      </div>
        <mat-form-field color="accent">
            <input
              matInput
              name="description"
              [required]="true"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.description"
              formControlName="description"
              [placeholder]="'COMMON.description' | translate"/>
        </mat-form-field>
  
        <mat-form-field color="accent">
            <input
              matInput
              name="infoUrl"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.infoUrl"
              formControlName="infoUrl"
              [placeholder]="'TEST.info-url' | translate"/>
        </mat-form-field>

        <mat-form-field color="accent">
                <input
                  matInput
                  name="typename"
                  [required]="true"
                  (keydown)="clearTheErrorMessage()"
                  [(ngModel)]="_providerData.typename"
                  formControlName="typename"
                  [placeholder]="'TEST.typename' | translate"/>
        </mat-form-field>
      
        <mat-form-field color="accent">
                <input
                  matInput
                  name="version"
                  [required]="false"
                  (keydown)="clearTheErrorMessage()"
                  [(ngModel)]="_providerData.version"
                  formControlName="version"
                  [placeholder]="'TEST.version' | translate"/>
        </mat-form-field>
        <div class="ti-form__bordered-part">
          <span class="ti-form-bordered-part__label">Parameters</span>
          <mat-form-field color="accent">
            <input
              matInput
              name="param1"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.param1"
              formControlName="param1"
              [placeholder]="'TEST.param1' | translate"/>
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field color="accent">
            <input
              matInput
              name="param1value"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.param1value"
              formControlName="param1value"
              [placeholder]="'TEST.param1-value' | translate"/>
          </mat-form-field>
          <br/>
          <mat-form-field color="accent">
            <input
              matInput
              name="param2"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.param2"
              formControlName="param2"
              [placeholder]="'TEST.param2' | translate"/>
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field color="accent">
            <input
              matInput
              name="param2value"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.param2value"
              formControlName="param2value"
              [placeholder]="'TEST.param2-value' | translate"/>
          </mat-form-field>
          <br/>
          <mat-form-field color="accent">
            <input
              matInput
              name="param3"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.param3"
              formControlName="param3"
              [placeholder]="'TEST.param3' | translate"/>
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field color="accent">
            <input
              matInput
              name="param3value"
              [required]="false"
              (keydown)="clearTheErrorMessage()"
              [(ngModel)]="_providerData.param3value"
              formControlName="param3value"
              [placeholder]="'TEST.param3-value' | translate"/>
          </mat-form-field>

        </div>
        <div class="ti-form__one-line-fields ti-form-bordered-part"></div>
      <div class="ti-form__button-panel">
        <button mat-raised-button (click)="toList()">{{'COMMON.cancel' | translate}}</button>
  
        <button
          mat-raised-button
          color="accent"
          [disabled]="!canEditNonLexicon()"
          (click)="save()">{{'COMMON.save' | translate}}
        </button>
      </div>   
    </form>
    
  </div>