<div class="ti-form-wrapper" *ngIf="localizedFamily">
  <form class="ti-form" *ngIf="familyForm" [formGroup]="familyForm">
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input
          matInput
          name="familyId"
          appDisableControl
          [disableControl]="true"
          [(ngModel)]="familyId"
          formControlName="familyId"
          [placeholder]="'COMMON.family-id' | translate"/>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input
          matInput
          name="description"
          appDisableControl
          [disableControl]="true"
          [(ngModel)]="systemDescription"
          formControlName="systemDescription"
          [placeholder]="'COMMON.system-description' | translate"/>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
        <input
          matInput
          name="description"
          (change)="handleChange()"
          appDisableControl
          [disableControl]="!allowedToEdit"
          [(ngModel)]="localizedFamily.description"
          formControlName="description"
          [placeholder]="'COMMON.description' | translate"/>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-form-field color="accent">
      <textarea
        matInput
        name="definition"
        (change)="handleChange()"
        appDisableControl
        [disableControl]="!allowedToEdit"
        [(ngModel)]="localizedFamily.definition"
        formControlName="definition"
        [placeholder]="'COMMON.definition' | translate"></textarea>
      </mat-form-field>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-checkbox
        name="complementFallback"
        (change)="handleChange()"
        matInput
        [(ngModel)]="localizedFamily.complement"
        formControlName="complementFallback">
        <span class="ti-form__label">{{'advanced-family-edit.complement' | translate}}</span>
      </mat-checkbox>
    </div>
    <div class="ti-knowledge-graph-advanced-edit-form__bordered-part ti-form__bordered-part">
      <span class="ti-language-settings__label ti-form-bordered-part__label">{{'COMMON.features' | translate}}</span>

      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-language-settings__label ti-form-bordered-part__label">{{'COMMON.grammar' | translate}}</span>
        </div>
        <div class="ti-form-bordered-part__content" style="display: flex">
          <app-universal-features
            *ngIf="relevantGrammarFeatureList"
            [featureValues]="localizedFamily.grammar"
            [doesTheWholeFeatureListRelevant]="true"
            [relevantFeatureList]="relevantGrammarFeatureList"
            [isOnlyOneFeature]="false"
            (featuresUpdatedValueEvent)="handleChange()"
          ></app-universal-features>
        </div>
      </div>
      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-language-settings__label ti-form-bordered-part__label">{{'COMMON.semantic' | translate}}</span>
        </div>
        <div class="ti-form-bordered-part__content" style="display: flex">
          <app-universal-features
            *ngIf="relevantSemanticFeatureList"
            [featureValues]="localizedFamily.semanticFeatures"
            [doesTheWholeFeatureListRelevant]="true"
            [relevantFeatureList]="relevantSemanticFeatureList"
            [isOnlyOneFeature]="false"
            (featuresUpdatedValueEvent)="handleChange()"
          >
          </app-universal-features>
        </div>
      </div>
    </div>
  </form>
</div>
