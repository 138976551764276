<div class="ti-form-wrapper">
  {{showProgressBar}}
  <h1 *ngIf="!isHypothesis">{{(isEdit ? 'interfix.interfix-edit': 'interfix.interfix-creation') |
    translate}}</h1>
  <h1 *ngIf="isHypothesis">{{(isEdit ? 'extra-hypothesis.extra-hypothesis-edit':
    'extra-hypothesis.extra-hypothesis-creation') |
    translate}}</h1>
  <div class="ti-extra__wrapper ti-progress-detectors" *ngIf="showProgressBar || !updatedElement">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="updatedElement" class="ti-extra__wrapper">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>

    <div *ngIf="updatedElement.id" class="ti-extra__read-only ti-extra__outside">
      <div class="ti-extra__read-only-key ti-extra__label">{{ 'COMMON.id' | translate }}</div>
      <div class="ti-extra__read-only-value">{{ updatedElement.id }}</div>
    </div>
    <div *ngIf="!isHypothesis" class="ti-extra__read-only ti-extra__outside">
      <div class="ti-extra__read-only-key ti-extra__label">
        {{ 'COMMON.text' | translate}}
      </div>
      <div class="ti-extra__read-only-value">
        <mat-form-field class="ti-extra__notes">
          <input
            matInput
            [(ngModel)]="updatedElement.text"
            appDisableControl
            [disableControl]="!doesUserHaveAnAccessToEdit"/>
        </mat-form-field>
      </div>
    </div>

    <div class="ti-extra__advanced-criteria">
      <div class="ti-extra__read-only ti-extra__advanced-criteria-wrapper">
        <div class="ti-extra__read-only-key ti-extra__label">
          {{ (isHypothesis? 'COMMON.advanced-criteria': 'interfix.preciding-word') | translate }}
        </div>
        <div class="ti-extra__advanced-criteria-content">
          <div>{{ updatedElement.advancedCriteriaDescription }}</div>
          <div class="ti-extra__description-wrapper" class="ti-form__button-wrapper">
            <button
              mat-mini-fab
              color="primary"
              appAdvancedCriteriaButton
              [forWords]="false"
              [dialogTitle]="(isHypothesis? 'extra-hypothesis.extra-hypothesis': 'interfix.preciding-word') | translate"
              (selectedDataEvent)="reactOnUpdateAdvancedCriteria($event)"
              [disabled]="!doesUserHaveAnAccessToEdit"
            >
              <mat-icon aria-hidden="true">search</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="primary"
              style="margin-left: 10px"
              [disabled]="!updatedElement.advancedCriteriaId || !doesUserHaveAnAccessToEdit"
              appAdvancedCriteriaOpenEditDialog
              [forWords]="false"
              (updateDataEvent)="reactOnUpdateAdvancedCriteria($event)"
              [isEdit]="true"
              [currentId]="updatedElement.advancedCriteriaId"
            >
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="ti-extra__features-item">
        <h3>{{ 'COMMON.required-features' | translate }}</h3>
        <div class="ti-extra__features-wrapper">
          <h4>{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="grammarFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.requiredGrammar"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="grammarFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4>{{'lexicon.style' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="styleFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.requiredStyle"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4>{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="semanticFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.requiredSemantics"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
        </div>
      </div>

      <div class="ti-extra__features-item" *ngIf="isHypothesis">
        <h3>{{ 'COMMON.assigned-features' | translate }}</h3>
        <div class="ti-extra__features-wrapper">
          <h4>{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="grammarFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.assignedGrammar"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="grammarFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4>{{'lexicon.style' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="styleFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.assignedStyle"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4>{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="semanticFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.assignedSemantics"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
        </div>
      </div>

      <div class="ti-extra__features-item">
        <h3>{{ 'COMMON.incompatible-features' | translate }}</h3>
        <div class="ti-extra__features-wrapper">
          <h4>{{'COMMON.grammar' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="grammarFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.incompatibleGrammar"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="grammarFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4>{{'lexicon.style' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="styleFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.incompatibleStyle"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="styleFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
          <h4>{{'COMMON.semantic' | translate}}</h4>
          <div class="ti-extra__select-wrapper" *ngIf="semanticFeatureList">
            <app-universal-features
              [featureValues]="updatedElement.incompatibleSemantics"
              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
              [relevantFeatureList]="semanticFeatureList"
              [isOnlyOneFeature]="false"
              [disableSelect]="true"
            ></app-universal-features>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isHypothesis">
      <div class="ti-extra__read-only ti-extra__advanced-criteria-wrapper">
        <div class="ti-extra__read-only-key ti-extra__label">
          {{ 'interfix.following-word' | translate }}
        </div>
        <div class="ti-extra__advanced-criteria-content">
          <div class="ti-extra__description-wrapper">{{ updatedElement.followingWordAdvancedCriteriaDescription }}</div>
          <div class="ti-form__button-wrapper">
            <button
              mat-mini-fab
              color="primary"
              appAdvancedCriteriaButton
              [forWords]="false"
              [dialogTitle]="(isHypothesis? 'extra-hypothesis.extra-hypothesis': 'interfix.preciding-word') | translate"
              (selectedDataEvent)="reactOnUpdateAdvancedCriteria($event, true)"
              [disabled]="!doesUserHaveAnAccessToEdit"
            >
              <mat-icon aria-hidden="true">search</mat-icon>
            </button>
            <button
              mat-mini-fab
              color="primary"
              style="margin-left: 10px"
              [disabled]="!updatedElement.followingWordAdvancedCriteriaId || !doesUserHaveAnAccessToEdit"
              appAdvancedCriteriaOpenEditDialog
              [forWords]="false"
              (updateDataEvent)="reactOnUpdateAdvancedCriteria($event)"
              [isEdit]="true"
              [currentId]="updatedElement.followingWordAdvancedCriteriaId"
            >
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="form">
      <div class="ti-form__one-line-fields" *ngIf="!isHypothesis">
        <mat-form-field color="accent" class="ti-form__number-input">
          <input
            matInput
            name="number"
            (keydown)="clearTheErrorMessage()"
            appDisableControl
            [disableControl]="!canEditNonLexicon()"
            [(ngModel)]="updatedElement.charactersToDelete"
            formControlName="charactersToDelete"
            min="0"
            [placeholder]="'AFFIXES.number-of-characters-to-delete' | translate"/>
          <span matSuffix>
            <mat-icon
              matSuffix
              (click)="updatedElement.charactersToDelete = (updatedElement.charactersToDelete) ? (updatedElement.charactersToDelete + 1) : 1">arrow_drop_up</mat-icon>
            <mat-icon
              matSuffix
              [ngClass]="updatedElement.charactersToDelete ? '' : 'ti-text-grey'"
              (click)="updatedElement.charactersToDelete = (updatedElement.charactersToDelete) ? (updatedElement.charactersToDelete - 1) : 0">arrow_drop_down</mat-icon>
          </span>
        </mat-form-field>
      </div>
        <div class="ti-extra__read-only ti-extra__outside">
        <div class="ti-extra__read-only-key ti-extra__label">
          {{ 'COMMON.note' | translate}}
        </div>
        <div class="ti-extra__read-only-value">
          <mat-form-field class="ti-extra__notes">
            <textarea
              matInput
              formControlName="notes"
              [(ngModel)]="updatedElement.note"
              appDisableControl
              [disableControl]="!doesUserHaveAnAccessToEdit"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div *ngIf="updatedElement.legacyId" class="ti-extra__read-only ti-extra__outside">
      <div class="ti-extra__read-only-key ti-extra__label">
        {{ 'COMMON.legacy-id' | translate }}
      </div>
      <div class="ti-extra__read-only-value">
        {{ updatedElement.legacyId }}
      </div>
    </div>
    <div class="ti-form__button-panel">
      <button mat-raised-button (click)="back()">{{'COMMON.cancel' | translate}}</button>
      <button
        mat-button
        mat-raised-button
        color="accent"
        (click)="save()"
        [disabled]="!doesUserHaveAnAccessToEdit"
      >{{'COMMON.save' | translate}}
      </button>
    </div>
  </div>
</div>
