import {Component, OnInit, Input, ChangeDetectorRef, OnDestroy, EventEmitter, Output} from '@angular/core';

import {Phoneme, PhonemesText} from '../../shared/models/phoneme-sign';
import {PhonemesService} from '../../shared/services/phonemes.service';
import {SessionService} from '../../shared/services/session.service';

import {commonTabSettings} from '../../shared/constants/application-constants';

import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import {RouterHelper} from '../../shared/helpers/router.helper';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-phonemes-table',
  templateUrl: './phonemes-table.component.html',
  styleUrls: ['./phonemes-table.component.less']
})
export class PhonemesTableComponent extends Translated implements OnInit, OnDestroy {
  @Input() phonemes: Array<Phoneme>;
  @Input() isDialog: boolean;
  @Output() chosenPhonemeEvent: EventEmitter<Phoneme> = new EventEmitter<Phoneme>();
  @Output() isDeletedPhonemeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public displayedColumns: string[];
  public editId = 0;
  public doesUserHaveAccessToEdit: boolean;
  public chosenElementId = 0;
  private positionTextArray = PhonemesText;

  public elementIndex: number;
  public idIndexKey: string = commonTabSettings.idIndexKey;
  public changedPreviouslySelector: string = commonTabSettings.changedPreviouslySelector;
  public isMobileView: boolean;

  constructor(
    protected translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
              protected sessionService: SessionService,
              private phonemesService: PhonemesService,
              protected localStorageHelper: LocalStorageHelper,
              private routerHelper: RouterHelper
  ) {
    super(translateService, localStorageHelper, sessionService);
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  ngOnInit(): void {
    this.getDefaultFeatureValueIndex();
    this.initFromSession();
  }

  initThisSubtypeFromSession(): void {
    const currentLanguage = this.sessionService.session.targetLanguage.englishName;
    const referenceLanguage = this.sessionService.session.referenceLanguage.englishName + '  ';

    let displayedColumns = ['id', currentLanguage, 'alias', 'position', referenceLanguage];

    if (this.isDialog) {
      displayedColumns = ['select', 'id', currentLanguage, 'Reference language'];
    }
    this.displayedColumns = displayedColumns;

    this.doesUserHaveAccessToEdit = this.sessionService.canEditNonLexicon();
}

  public toggle(element: Phoneme | null): void {
    if (this.chosenElementId !== element.id) {
      this.chosenElementId = element.id;
      this.chosenPhonemeEvent.emit(element);
    } else {
      this.chosenElementId = 0;
      this.chosenPhonemeEvent.emit(null);
    }
  }

  public edit(phonemesId: number): void {
    this.editId = phonemesId;
  }

  private deletePhoneme(phonemesId: number): Promise<any> {
    return this.phonemesService.deletePhoneme(phonemesId).then();
  }

  public deletePhonemeIfUserConfirmsDeletion(id: number, doesUserConfirm: boolean): Promise<void> | void {
    if (doesUserConfirm) {
      return this.deletePhoneme(id).then(() => {
        this.isDeletedPhonemeEvent.emit(true);
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  public getDefaultFeatureValueIndex(): void {
    this.elementIndex = this.getCashedIndex(this.idIndexKey);
  }

  private getCashedIndex(indexName: string): number | undefined {
    const cashedIndex = this.localStorageHelper.getTabSetting(indexName);
    const currentIndex = parseInt(cashedIndex, 10);

    return ((typeof currentIndex === 'number') && !isNaN(currentIndex)) ? currentIndex : undefined;
  }

  public getCurrentPositionText(positionKey: string): string {
    const currentPositionTextElement = this.positionTextArray.find(position => (position.key === positionKey));

    if (currentPositionTextElement && currentPositionTextElement.value) {
      return currentPositionTextElement.value;
    }
    return '';
  }

  ngOnDestroy(): void {
  }

}
