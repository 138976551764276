import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { applicationConstants } from '../../shared/constants/application-constants';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../shared/services/session.service';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { TestsetService } from '../../shared/services/testset.service';
import { UnknownWord, UnknownWordStat } from '../../shared/models/test.model';

@Component({
  selector: 'app-unknown-words-dialog',
  templateUrl: 'unknown-words-dialog.component.html',
  styleUrls: ['unknown-words-dialog.component.less']
})
export class UnknownWordsDialogComponent extends Translated implements OnInit {
  public unknownWords: UnknownWord[];
  public displayedColumns: string[] = ['unknownWord', 'unknownWordOccurrences'];
  public showProgressBar = false;

  corporaId: number;


  constructor(@Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    public translateService: TranslateService,
    public localStorageHelper: LocalStorageHelper,
    public sessionService: SessionService,
    private testsetService: TestsetService) {
    super(translateService, localStorageHelper, sessionService);
    this.corporaId = this.dialogData.corporaId;
  }

  ngOnInit(): void {
    this.initFromSession();
  }

  initThisSubtypeFromSession(): void {
    if (this.dialogData.corporaId) {
      this.testsetService.getCorporaUnknownWords(this.dialogData.corporaId)
        .then((results) => {
          this.unknownWords = (results || []).map(e => {
            return {
              unknownWord: e.Key,
              unknownWordOccurrences: e.Value
            }
          });
        });
    }
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  public closeDialogs(): void {
    this.dialog.getDialogById(applicationConstants.dialogs.inflectionTableDialog).close();
    this.dialog.getDialogById(applicationConstants.dialogs.inflectionEditDialog).close();
  }
}
