import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs/Subscription';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

import {SessionService} from '../../shared/services/session.service';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {FamilyDefinition, emptyAdvancedFamily, fallbackPolicy, phraseType} from '../../shared/models/knowledge-graph';
import {KnowledgeGraphService} from '../../shared/services/knowledge-graph.service';
import {emptyFeatureValue, FeatureListDefinition, FeatureValue, ModelWithRelevantFeature} from '../../shared/models/feature';
import {FeaturesService} from '../../shared/services/features.service';
import {applicationConstants, applicationModulesRoutePaths} from '../../shared/constants/application-constants';
import {frequencyValidator, thisCEEraValidator} from '../../shared/models/form.validator';
import {areTwoObjectsIdentical, collectObjectChanges, toJsonObject} from '../../shared/helpers/object.helper';
import {LampUpdateResponse} from '../../shared/models/common';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-knowledge-graph-element-advanced-edit',
  templateUrl: './knowledge-graph-element-advanced-edit.component.html',
  styleUrls: ['knowledge-graph-element-advanced-edit.component.less']
})
export class KnowledgeGraphElementAdvancedEditComponent extends Translated implements OnInit, OnDestroy {
  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();
  public isNewFamily: boolean;
  public familyId: number;
  public family: FamilyDefinition;
  public familyForm: FormGroup;

  public fallbackPolicyOptions = fallbackPolicy;
  public phraseTypeOptions = phraseType;
  private routeSubscription: Subscription;

  public relevantGrammarFeatureList: FeatureListDefinition[];
  public relevantSemanticFeatureList: FeatureListDefinition[];
  public initialFamilyData: FamilyDefinition;
  public dataChanged: boolean = false;
  public IABFeatureIndex = applicationConstants.preselectedIndexes.IAB;
  public IPTCFeatureIndex = applicationConstants.preselectedIndexes.IPTC;

  public localizedFamily: FamilyDefinition;
  public earliestMention: string;
  public doesTheWholeFeatureListRelevant: boolean;
  public lexemesInLanguages: string;
  public phrasesInLanguages: string;
  public isLoading = false;

  public maxDate = new Date((new Date()).getTime() + (24 * 60 * 60 * 1000));

  constructor(private knowledgeGraphService: KnowledgeGraphService,
              private route: ActivatedRoute,
              sessionService: SessionService,
              translateService: TranslateService,
              protected localStorageHelper: LocalStorageHelper,
              private formBuilder: FormBuilder,
              private routerHelper: RouterHelper,
              private featuresService: FeaturesService) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.initFromSession();
    this.routeSubscription = this.route.data.subscribe(
      (data: { id: number }) => {
        if (data.id) {
          this.familyId = data.id;
        } else {
          this.isNewFamily = true;
        }
        this.getCurrentFamilyData().then(() => {
          if (this.familyId) {
            this.getCurrentLocalizationFamilyData().then(() => {
              return this.getFeatures(this.family).then(() => {
                this.createForm();
              });
            });
          } else {
            return this.getFeatures(this.family).then(() => {
              this.createForm();
            });
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  initThisSubtypeFromSession(): void {

  }

  private getFeatures(family: ModelWithRelevantFeature): Promise<void> {
    let thisFamily = family;
    this.doesTheWholeFeatureListRelevant = !Boolean(thisFamily.relevant);
    return this.featuresService.createFilteredRelevantFeatureList(thisFamily.relevant, applicationConstants.grammarFeatureType).then((relevantGrammarFeatureList) => {
      this.relevantGrammarFeatureList = relevantGrammarFeatureList;
      this.featuresService.createFilteredRelevantFeatureList(thisFamily.relevant, applicationConstants.semanticFeatureType).then((filteredSemantics) => {
        this.relevantSemanticFeatureList = filteredSemantics;
      });
    });
  }

  private createForm(): void {
    this.familyForm = this.formBuilder.group({
      definition: [this.family.definition],
      description: [this.family.description],
      familyId: [this.familyId],
      fallback: [this.family.fallback],
      phraseType: [this.family.phraseType],
      properNoun: [this.family.properNoun],
      properNounHyponymEntities: [this.family.properNounHyponymEntities],
      frequency: [this.family.frequency, frequencyValidator()],
      earliestMention: [this.earliestMention],
      lastYearActive: [this.family.lastYearActive, thisCEEraValidator()],
      wordnetId: [this.family.wordnetId],
      wikidataId: [this.family.wikidataId],
      customId: [this.family.customId],
      grammarCopyToHyponyms: [this.family.grammarCopyToHyponyms],
      semanticsCopyToHyponyms: [this.family.semanticsCopyToHyponyms],
      value: [this.family.value],
      valueRange: [this.family.valueRange],
      phraseTag: [this.family.phraseTag],
      translationUrl: [this.family.translationUrl],
      obscure: [this.family.obscure]
    });
  }

  private getCurrentFamilyData(): Promise<void> {
    if (this.familyId) {
      return this.knowledgeGraphService.getFamilyById(this.familyId.toString()).then((family: FamilyDefinition) => {
        this.initialFamilyData = toJsonObject(family) as FamilyDefinition;
        family.numericDomain = (family.numericDomain) ? family.numericDomain : emptyFeatureValue;
        this.family = family;
        if (family.earliestMention) {
          this.earliestMention = family.earliestMention;
        }
      });
    } else {
      this.family = toJsonObject(emptyAdvancedFamily) as FamilyDefinition;
      this.initialFamilyData = toJsonObject(emptyAdvancedFamily) as FamilyDefinition;
      return Promise.resolve();
    }
  }

  private earliestMentionToAppropriateEarliestMentionAttributeForm() {
    if (this.earliestMention) {
      this.family.earliestMention = (new Date(this.earliestMention)).toISOString();
    }
  }

  public clearTheErrorMessage(): void {
    this.dataChanged = true;
    this.showErrorMessage('');
  }

  public showErrorMessage(errorMessage: string): void {
    this.errorMessageSubj.next(errorMessage);
  }

  public cancel(): void {
    this.back();
  }

  public updateCurrentIABValue($event: FeatureValue): void {
    this.family.iab = $event.value;
  }

  public updateCurrentIPTCValue($event: FeatureValue): void {
    this.family.iptc = $event.value;
  }

  public saveUpdatedNumericDomain($event: FeatureValue): void {
    this.family.numericDomain = $event;
  }

  public save(): Promise<void> {
    this.earliestMentionToAppropriateEarliestMentionAttributeForm();
    this.defineTypeOfSemanticFeatures();
    if (this.family.numericDomain && this.family.numericDomain.index && this.family.numericDomain.value)
      this.family.numericDomain.type = applicationConstants.semanticFeature.type;

    const changes = collectObjectChanges(this.initialFamilyData, this.family) as FamilyDefinition;
    changes.id = this.familyId;

    if (changes.numericDomain && this.initialFamilyData.numericDomain
        && changes.numericDomain === this.initialFamilyData.numericDomain) {
      delete changes.numericDomain;
    }

    // need to send null to server to reset it
    // if (!changes.iab) {
    //   delete changes.iab;
    // }

    // if (!changes.iptc) {
    //   delete changes.iptc;
    // }

    if (this.isNewFamily && !changes.phraseType)
      changes.phraseType = 'none';

    if (Object.keys(changes).length) {
      if (this.isNewFamily) {
        return this.knowledgeGraphService.createFamily(changes).then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.localStorageHelper.setTabSetting('newCreatedKnowledgeGraphId', successfulResponseMessage.id.toString());
            this.back();
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
      } else {
        return this.knowledgeGraphService.updateFamily(changes).then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.back();
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
      }
    }
    return Promise.resolve().then(() => {
      this.back();
    });
  }

  public defineTypeOfSemanticFeatures(): void {
    if (this.family && this.family.semanticFeatures) {
      this.family.semanticFeatures.forEach(feature => feature.type = applicationConstants.semanticFeature.type);
    }
  }

  public updateFamilyLocalization(changes: FamilyDefinition): Promise<void> {
    if (Object.keys(changes).length) {
      return this.getCurrentLocalizationFamilyData()
    }
    return Promise.resolve().then(() => {
      this.back();
    });
  }

  public getCurrentLocalizationFamilyData(): Promise<void> {
    this.isLoading = true;
    return this.knowledgeGraphService.getFamilyLocalizationById(this.familyId.toString()).then((family: FamilyDefinition) => {
      this.isLoading = false;
      if (Object.keys(family).length) {
        if (!family.grammar) {
          family.grammar = [];
        }
        if (!family.semanticFeatures) {
          family.semanticFeatures = [];
        }
        this.localizedFamily = family;
      }
    });
  }

  public resetLangSpecificAfterUserConfirmation(doesUserConfirmDeletion: boolean): Promise<void> {
    if (doesUserConfirmDeletion) {
      this.isLoading = true;
      return this.getCurrentLocalizationFamilyData().then(() => {
        let emptyDefinition = toJsonObject(emptyAdvancedFamily) as FamilyDefinition;
        emptyDefinition = {
          ...emptyDefinition,
          id: this.localizedFamily.id,
          requestId: this.localizedFamily.requestId,
          localized: this.localizedFamily.localized
        }
        this.isLoading = true;
        return this.knowledgeGraphService.updateFamilyLocalization(emptyDefinition).then((successfulResponseMessage: LampUpdateResponse) => {
          this.isLoading = false;
          if (successfulResponseMessage.success) {
            this.getCurrentLocalizationFamilyData();
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
      });
    }
  }

  public clearIab(): void {
    this.family.iab = null;
  }

  public clearIptc(): void {
    this.family.iptc = null;
  }

  public back(): void {
    this.routerHelper.back();
  }

  public showHistory(log: FamilyDefinition) {
    log.requestId = this.family.requestId;
    this.family = log;
  }
}
