<div class="ti-menu-wrapper">
  <br/>
    <mat-radio-group *ngIf="allowOtherLanguages" [(ngModel)]="targetLanguageMode" layout="row">
        <mat-radio-button class="ti-language-settings__radio-button" value="">
          {{currentLanguageName}}
        </mat-radio-button>&nbsp;&nbsp;
        <mat-radio-button *ngIf="hasFallback" class="ti-language-settings__radio-button" value="fallback">
            {{'COMMON.include-fallback' | translate}}
          </mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button class="ti-language-settings__radio-button" value="all">
              {{'COMMON.all-languages' | translate}}
            </mat-radio-button>
          </mat-radio-group>
    <!--
  <div class="ti-form__one-line-fields">
    -->
    <app-universal-id-text-range-filter
      *ngIf="!isAutoSuggestionLexemeDialog"
      [currentRange]="'lexemes'"
      [disableNavigation]="true"
      (chosenParameterEvent)="findLexemes($event)"
    ></app-universal-id-text-range-filter>
    <mat-progress-bar *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-bar>
  <!--
  </div>
  -->

  <div class="ti-menu-wrapper">
    <mat-table *ngIf="lexemesList" #nonBreaksTable [dataSource]="lexemesList">
      <ng-container [matColumnDef]="displayedColumns[0]">
        <mat-header-cell class="ti-menu__id" *matHeaderCellDef>Id</mat-header-cell>
        <mat-cell class="ti-menu__id" *matCellDef="let element">{{element.id}}</mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[1]">
        <mat-header-cell class="ti-menu__lemma" *matHeaderCellDef>Lemma</mat-header-cell>
        <mat-cell class="ti-menu__lemma" *matCellDef="let element">{{element.lemma}}
          <span *ngIf="element.sameFamiliesAs">&nbsp;&nbsp;🔒</span>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[2]">
        <mat-header-cell class="ti-menu__form" *matHeaderCellDef>Inflected forms</mat-header-cell>
        <mat-cell class="ti-menu__form" *matCellDef="let element">{{element.inflections}}</mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="displayedColumns[3]">
        <mat-header-cell class="ti-menu__features" *matHeaderCellDef>Features</mat-header-cell>
        <mat-cell class="ti-menu__features" *matCellDef="let element">{{element.features}}</mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="displayedColumns[4]">
        <mat-header-cell class="ti-menu__checkbox" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="mat-cell---with-checkbox ti-menu__checkbox" *matCellDef="let element">
          <mat-checkbox [disabled]="element.sameFamiliesAs > 0" *ngIf="!chooseOnlyOneLexeme"
            (change)="updateEntry(element.id, $event)">
          </mat-checkbox>
          <mat-radio-button
            *ngIf="chooseOnlyOneLexeme"
            (click)="selectItem(element.id);"
            [value]="false"
            [name]="'selected-item'"
            [disabled]="element.sameFamiliesAs > 0"
          ></mat-radio-button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
