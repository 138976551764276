import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../shared/services/session.service';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-domain-generation-dialog',
  templateUrl: './domain-generation-dialog.component.html',
  styleUrls: ['./domain-generation-dialog.component.less']
})
export class DomainGenerationDialogComponent extends Translated {

  initThisSubtypeFromSession(): void {
  }
  public familyId: number;
  public prompt: string = '';
  public silent: boolean = true;
  public includeHyponyms: boolean = false;
  public form: FormGroup;

  public isLoading = false;
  public processed: number = 0;
  public totalFamilies: number = 0;
  public invalidPrompt = false;

  @ViewChild('btnClose', { read: ElementRef }) btnClose: ElementRef<HTMLElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, protected translateService: TranslateService,
    private dialogRef: MatDialogRef<DomainGenerationDialogComponent>,
    public sessionService: SessionService,
    protected localStorageHelper: LocalStorageHelper,
    private knowledgeGraphService: KnowledgeGraphService,
    private formBuilder: FormBuilder) {
    super(translateService, localStorageHelper, sessionService,);
    this.familyId = data.familyId;

    this.form = this.formBuilder.group({
      prompt: [],
      silent: [],
      includeHyponyms: []
    });
  }


  validatePrompt() {
    const promptValue = this.prompt;
    this.clearTheErrorMessage();
    this.invalidPrompt = false;

    if (promptValue) {
      const containsPlaceholder = /\{0\}/.test(promptValue);
      const invalidPlaceholder = /\{[2-9]\}|\{[0-9]{2,}\}/.test(promptValue);

      // Display warnings and disable Generate button if necessary
      if (invalidPlaceholder) {
        this.showErrorMessage("Only {0} (lemma representing family) and {1} (family definition) are supported.");
        this.invalidPrompt = true;
        return; // Exit the function as the prompt is invalid
      } else if (!containsPlaceholder) {
        this.showErrorMessage("Use {0} for the current family, and optionally, {1} for the family definition.");
        if (this.includeHyponyms) {
          this.invalidPrompt = true;
        }
        return; // Exit the function as the prompt is invalid
      }
    }
  }

  public async generate() {
    this.isLoading = true;
    let familyIds: number[] = [this.familyId];
    if (this.includeHyponyms) {
      const hyponymIds = await this.knowledgeGraphService.getHyponymIds(this.familyId);
      familyIds = familyIds.concat(hyponymIds);
    }
    this.isLoading = true;
    this.totalFamilies = familyIds.length;
    for (const familyId of familyIds) {
      try {
        const result = await this.knowledgeGraphService.generateDomains(familyId, this.prompt, this.silent)
        this.processed++;
        if (result.error) {
          this.showErrorMessage(result.error);
        }
      } catch (error) {
        this.showErrorMessage(error.message);
      }
    }
    this.isLoading = false;
    this.dialogRef.close(true);
  }

}
