import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';

import {Subscription} from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {
  applicationModulesRoutePaths, punctuationRoles,
  punctuationSpaces
} from '../../shared/constants/application-constants';

import {emptyPunctuationSignData, PunctuationSign, PunctuationSignData} from '../../shared/models/punctuation-sign';
import {PunctuationService} from '../../shared/services/punctuation.service';

import {RouterHelper} from '../../shared/helpers/router.helper';
import {SessionService} from '../../shared/services/session.service';
import {collectObjectChanges, toJsonObject} from '../../shared/helpers/object.helper';
import {LampUpdateResponse} from '../../shared/models/common';
import { Translated } from '../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-punctuation-edit',
  templateUrl: './punctuation-edit.component.html',
  styleUrls: ['./punctuation-edit.component.less']
})

export class PunctuationEditComponent extends Translated implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public punctuationSignData: PunctuationSignData;
  public initialPunctuationSignData: PunctuationSignData;

  public punctuationEditForm: FormGroup;

  public punctuationSignList: PunctuationSign[];
  public punctuationRoles: string[] = punctuationRoles;
  public punctuationSpaces: string[] = punctuationSpaces;

  public punctuationSignId: number;

  public text: string;
  public nonBreakCount: number;

  public isTheNewSign: boolean;
  public doesUserHaveAccessToEdit: boolean;
  public showSpinner: boolean;

  public errorMessageSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageSubj.asObservable();

  constructor(protected translateService: TranslateService,
              private route: ActivatedRoute,
              private routerHelper: RouterHelper,
              private punctuationService: PunctuationService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              protected localStorageHelper: LocalStorageHelper,
              protected sessionService: SessionService) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.initFromSession();
    this.addSubscriptions();
  }

  initThisSubtypeFromSession(): void {
    this.doesUserHaveAccessToEdit = this.sessionService.canEditNonLexicon();
  }

  private createForm(): void {
    this.punctuationEditForm = this.formBuilder.group({
      id: [this.punctuationSignId],
      capitalizeNext: [this.punctuationSignData.capitalizeNext],
      space: [this.punctuationSignData.space],
      text: [this.punctuationSignData.text],
      type: [this.punctuationSignData.type],
      fallbackId: [this.punctuationSignData.fallbackId],
      note: [this.punctuationSignData.note]
    });
  }

  private addSubscriptions(): void {
    const routeSubscription: Subscription = this.route.data.subscribe(
      (data: {
        punctuationSignData?: PunctuationSignData,
        punctuationSignList: PunctuationSign[],
        id: number
      }) => {
        this.extractData(data);
        this.createForm();
      });

    this.subscriptions.push(routeSubscription);
  }

  private getDataFromPunctuationSignList(punctuationSignList): void {
    const filteredRule = (punctuationSignElement: PunctuationSign) => {
      return punctuationSignElement.id === this.punctuationSignId;
    };
    const punctuationSign: PunctuationSign = punctuationSignList.filter(filteredRule)[0];

    this.nonBreakCount = punctuationSign.nonBreakCount || 0;
  }

  private extractData(data): void {
    this.punctuationSignId = data.id;
    this.punctuationSignList = data.punctuationSignList;

    this.isTheNewSign = !Boolean(data.punctuationSignData);

    if (this.isTheNewSign) {
      this.punctuationSignData = toJsonObject(emptyPunctuationSignData)  as PunctuationSignData;
      this.initialPunctuationSignData = toJsonObject(emptyPunctuationSignData)  as PunctuationSignData;
    } else {
      this.punctuationSignData = toJsonObject(data.punctuationSignData) as PunctuationSignData;
      this.initialPunctuationSignData = toJsonObject(data.punctuationSignData) as PunctuationSignData;
    }

    this.getDataFromPunctuationSignList(data.punctuationSignList);
  }

  public save(): Promise<void> {
    this.showSpinner = true;
    const punctuationSignDataChanges = collectObjectChanges(this.initialPunctuationSignData,
      this.punctuationSignData) as PunctuationSignData;

    if (Object.keys(punctuationSignDataChanges).length) {
      punctuationSignDataChanges.id = this.punctuationSignId;
      punctuationSignDataChanges.text = this.punctuationSignData.text;

      if (this.isTheNewSign) {
        return this.punctuationService.createPunctuationSignData(punctuationSignDataChanges)
          .then((successfulResponseMessage: LampUpdateResponse) => {
            this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
          });
      } else {
        return this.punctuationService.updatePunctuationSignData(punctuationSignDataChanges)
          .then((successfulResponseMessage: LampUpdateResponse) => {
            this.reactOnSuccessfulResponseMessage(successfulResponseMessage);
          });
      }
    } else {
      this.back();
      return Promise.resolve();
    }
  }

  private reactOnSuccessfulResponseMessage(successfulResponseMessage: LampUpdateResponse): void {
    this.showSpinner = false;
    if (successfulResponseMessage.success) {
      this.back();
    } else {
      this.showErrorMessage(successfulResponseMessage.error);
    }
  }

  private back(): void {
    this.routerHelper.navigateToPage(applicationModulesRoutePaths.punctuation.path);
  }

  public updatePunctuationList(): Promise<void> {
    return this.punctuationService.getPunctuationList().then((punctuationSignList) => {
      this.getDataFromPunctuationSignList(punctuationSignList);
      this.changeDetectorRef.markForCheck();
    });
  }

  public cancel(): void {
    this.back();
  }


  protected showErrorMessage(error?: string) {
    this.errorMessageSubj.next(error);
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
