import {Directive, HostListener, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {FallBackToPhonemeDialogComponent} from './fall-back-to-phoneme-dialog.component';
import {Phoneme} from '../../shared/models/phoneme-sign';


@Directive({selector: '[appOpenFallBackEditDialog]'})

export class ButtonOpensFallBackEditDialogDirective {
  @Output() fallBackPhonemeAddedEvent: EventEmitter<Phoneme> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openFallBackEditDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(FallBackToPhonemeDialogComponent, {
      maxWidth: this.isMobileView ? '100%' : '60%'
    });

    dialogRef.afterClosed().subscribe((phoneme: Phoneme) => {
      if (phoneme) {
        this.fallBackPhonemeAddedEvent.emit(phoneme);
      }
    });
  }
}
