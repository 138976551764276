import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {SessionService} from './session.service';
import {RouterHelper} from '../helpers/router.helper';

import {UserRoles} from '../models/user-roles.enum';

@Injectable()
export class PassAuthenticatedUserGuard implements CanActivate, CanActivateChild {

  constructor(private sessionService: SessionService, private routerHelper: RouterHelper) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /*
    return Observable.create((observer) => {
      return this.sessionService.currentSessionSource.subscribe((session) => {
        if (session) {
          observer.next(session && session.isAuthenticated);
          observer.complete();
        }
      });
    });
    */
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}

@Injectable()
export class PassNonAuthenticatedUserGuard implements CanActivate, CanActivateChild {
  constructor(private sessionService: SessionService, private routerHelper: RouterHelper) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /*
    return Observable.create((observer) => {
      return this.sessionService.currentSessionSource.subscribe((session) => {
        if (session && session.isAuthenticated) {
          this.routerHelper.navigateToLoginPage();
        }
        observer.next(!(session && session.isAuthenticated));
        observer.complete();
      });
    });
    */
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}

@Injectable()
export class PassEverybodyExceptGuestGuard implements CanActivate, CanActivateChild {
  constructor(private sessionService: SessionService, private routerHelper: RouterHelper) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
