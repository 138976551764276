<div class="ti-menu-wrapper ti-form__column-fields" style="padding-left: 10px; padding-right: 10px;">
  <input id="btnPickLog" type="file" (change)="handleFileSelect($event)" class="inputfile" />
  <label for="btnPickLog" id="btnPicklogLabel">Click here to select the log</label>
  <br />
  <mat-chip-list *ngIf="!finished()">
    <mat-chip *ngFor="let titleSegment of _currentTitleParts" color="accent">{{titleSegment}}</mat-chip>
    <mat-icon id="outcome" aria-hidden="true" matTooltip="{{_outcomeReason}}">{{_operationOutcome}}</mat-icon>
  </mat-chip-list>
  <div id="attention" *ngIf="!finished()" style="display:inline-block; min-height: 20px;">
    <h2>{{_contentBeforeHighlight}}<span class="textFocus">{{_contentInsideHighlight}}</span>{{_contentAfterHighlight}}
    </h2>
  </div>
  <br /><br /><br />
  <span *ngIf="!_currentParse && !finished()">
    <mat-grid-list id="constituents" role="list" cols="{{_totalSentenceSpan + 2}}" rowHeight="55px" gutterSize="10">
      <mat-grid-tile role="listitem" *ngFor="let constituent of _constituents" [rowspan]="calculateRowSpan(constituent)"
        [colspan]="constituent.colspan" [style.border-right]="'1px dashed #ddd'">
        <span *ngIf="constituent.segm && constituent.segm.length > 1">
          <span *ngFor="let segmentation of constituent.segm">
            <mat-chip-list>
              <mat-chip *ngFor="let lexicalItem of segmentation.items" matBadge="{{getBadge(lexicalItem)}}"
                matBadgePosition="below after"
                [style.background-color]="_selectedNode === lexicalItem.runtimeId ? _selectionColor : ''">{{lexicalItem.text}}
              </mat-chip>
              <mat-icon matTooltip="{{getAnnotation(lexicalItem)}}">{{getIcon(lexicalItem)}}</mat-icon>
            </mat-chip-list>
            <mat-divider></mat-divider>
          </span>
        </span>
        <mat-chip-list *ngIf="constituent.segm && constituent.segm.length === 1">
          <span *ngFor="let lexicalItem of constituent.segm[0].items">
            <mat-chip matBadge="{{getBadge(lexicalItem)}}" matBadgePosition="below after" style="overflow:visible"
              [style.background-color]="_selectedNode === lexicalItem.runtimeId ? _selectionColor : ''">{{lexicalItem.text}}
            </mat-chip>
            <mat-icon matTooltip="{{getAnnotation(lexicalItem)}}">{{getIcon(lexicalItem)}}</mat-icon>
          </span>
        </mat-chip-list>
        <mat-chip-list *ngIf="!constituent.segm">
          <mat-chip matBadge="{{getBadge(constituent)}}" matBadgePosition="below after"
            [style.background-color]="_selectedNode === constituent.runtimeId ? _selectionColor : ''">{{constituent.text}}
          </mat-chip>
          <mat-icon matTooltip="{{getAnnotation(constituent)}}">{{getIcon(constituent)}}</mat-icon>
        </mat-chip-list>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list role="list" *ngFor="let phraseLevel of _phrases" [cols]="getTotalColSpan(phraseLevel)"
      rowHeight="55px" gutterSize="10">
      <mat-grid-tile role="listitem" *ngFor="let phrase of phraseLevel" [rowspan]="1" [colspan]="phrase.colspan"
        [style.border-right]="'1px dashed #ddd'">
        <mat-chip-list *ngIf="phrase.runtimeId > 0">
          <mat-chip matTooltip="{{phraseTooltip(phrase)}}" matBadge="{{getBadge(phrase)}}"
            matBadgePosition="below after"
            [style.background-color]="_selectedNode === phrase.runtimeId ? _selectionColor : ''"
            [innerHTML]="phrase.text">
          </mat-chip>
          <mat-icon matTooltip="{{getAnnotation(phrase)}}">{{getIcon(phrase)}}</mat-icon>
        </mat-chip-list>
      </mat-grid-tile>
    </mat-grid-list>
  </span>

  <div *ngIf="_currentParse && !(finished() && _sentences && _sentences.length > 1)" class="clt"
    [innerHTML]="outputParse()">
  </div>

  <span *ngIf="finished()">
    <mat-chip-list *ngIf="_topics && _topics.length > 0">
      <h4>Topics</h4>&nbsp;&nbsp;
      <mat-chip *ngFor="let item of _topics" matTooltip="{{item.definition}}">{{objectDescription(item)}}</mat-chip>
    </mat-chip-list>

    <mat-chip-list *ngIf="_abuse && _abuse.length > 0">
      <h4>Abuse</h4>&nbsp;&nbsp;
      <mat-chip *ngFor="let item of _abuse">{{objectDescription(item)}}</mat-chip>
    </mat-chip-list>

    <mat-chip-list *ngIf="_sentiment && _sentiment.length > 0">
      <h4>Sentiment</h4>&nbsp;&nbsp;
      <mat-chip *ngFor="let item of _sentiment">{{objectDescription(item)}}</mat-chip>
    </mat-chip-list>
  </span>
  <br /><br />
  <mat-progress-bar *ngIf="_lines && _lines.length > 1" mode="determinate"
    value="{{_currentLineIndex / _lines.length * 100}}"></mat-progress-bar>
  <br /><br id="beforeSearchMatches" />
  <mat-expansion-panel *ngIf="_highlighted && _searchMatches && _searchMatches.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>Search results</mat-panel-title>
      <mat-panel-description>Matches
        found:&nbsp;{{_searchMatches.length}}&nbsp;{{_searchOverflow}}</mat-panel-description>
    </mat-expansion-panel-header>
    <!-- this is where the search matches are displayed-->
    <ul>
      <li [innerHTML]="objectPanelHeaderSafeHTML(_highlighted)"></li>
      <li><strong>Type:</strong>&nbsp;{{_highlighted.type}}</li>
      <li><strong>Runtime ID:</strong>&nbsp;{{_highlighted.runtimeId}}</li>
    </ul>
    <ng-template matExpansionPanelContent>
      <!--
      <mat-chip-list class="mat-chip-list-stacked" aria-orientation="vertical">
      -->
      <mat-chip-list>
        <mat-chip *ngFor="let match of _searchMatches"
          matTooltip="Click to go to the match ({{match.description}}, section {{match.section}})"
          matBadgePosition="below after" [style.cursor]="pointer" [style.font-size]="small"
          (click)="skipToLine(match.start)">{{match.briefDescription}}</mat-chip>
      </mat-chip-list>
    </ng-template>
  </mat-expansion-panel>
  <p *ngIf="_sentence && _sentence.length > 0">Current sentence: <strong>{{_sentence}}</strong></p>
  <!--
  <p>Input: <strong>{{_input}}</strong></p>
  -->
  <p *ngIf="_currentSection">
    Section: <strong>{{_currentSection}}</strong>
  </p>
  <!-- VCR controls -->
  <fieldset ng-disabled="_controlOperationInProgress" class="player text-center" id="playControls"
    *ngIf="_lines && _lines.length > 1">

    <button type="button" id="button_fbw" class="btn" (click)="buttonPreviousSection()"
      matTooltip="Rewind to the previous section">
      <i class="fa fa-fast-backward" id="ibutton_fbw"></i>
      <mat-icon aria-hidden="true">skip_previous</mat-icon>
    </button>

    <button type="button" id="button_bw" class="btn" (click)="buttonBackQuickly()" matTooltip="Rewind 500 steps">
      <i class="fa fa-backward" id="ibutton_bw"></i>
      <mat-icon aria-hidden="true">fast_rewind</mat-icon>
    </button>

    <button type="button" id="button_play" class="btn" (click)="buttonPlayPress($event)"
      matTooltip="{{_isPlaying ? 'Pause' : 'Play'}}">
      <i class="fa fa-play" id="ibutton_play"></i>
      <mat-icon *ngIf="!_isPlaying" aria-hidden="true">play_arrow</mat-icon>
      <mat-icon *ngIf="_isPlaying" aria-hidden="true">pause</mat-icon>
    </button>

    <button type="button" id="button_stop" class="btn" (click)="buttonGoToEnd()"
      matTooltip="Load all entities and stop">
      <i class="fa fa-stop" id="ibutton_stop"></i>
      <mat-icon aria-hidden="true">subtitles</mat-icon>
    </button>

    <button type="button" id="button_fw" class="btn" (click)="buttonForwardQuickly()" matTooltip="Skip 500 steps">
      <i class="fa fa-forward" id="ibutton_fw"></i>
      <mat-icon aria-hidden="true">fast_forward</mat-icon>
    </button>

    <button type="button" id="button_ffw" class="btn" (click)="buttonNextSection()"
      matTooltip="Skip to the next section">
      <i class="fa fa-fast-forward" id="ibutton_ffw"></i>
      <mat-icon aria-hidden="true">skip_next</mat-icon>
    </button>

  </fieldset>
  <div *ngIf="_lines && _lines.length > 1">
    <label>Faster&nbsp;&nbsp;</label>
    <mat-slider [max]="3001" [min]="1" [step]="50" [tickInterval]="50" [thumbLabel]="true" (input)="pauseProcessing()"
      [(ngModel)]="_stepDelay" [vertical]="false">
    </mat-slider>
    <label>&nbsp;&nbsp;Slower</label>
  </div>
  <br />
  <mat-accordion multi="false" class="mat-table">
    <mat-expansion-panel *ngFor="let entityType of _entityGroupTypes" id="{{'entityType_' + entityType}}">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span *ngIf="warning[entityType]" [matTooltip]="warning[entityType]">⚠️&nbsp;</span>
          {{_entityTypeDescriptions.get(entityType)[0]}}
        </mat-panel-title>
        <mat-panel-description>{{_entityTypeDescriptions.get(entityType)[1]}}</mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template *ngIf="entityType === 'phrase'" matExpansionPanelContent>
        <div class="ti-form__one-line-fields--with-checkbox">
          <mat-checkbox matInput [(ngModel)]="showMatchedOnly">
            <span class="ti-text-italic">{{'TEST.matched-only' | translate}}</span>
          </mat-checkbox>
        </div>

        <ng-container *ngFor="let familyGroup of _phrasesByFamily | keyvalue">
          <mat-expansion-panel
            *ngIf="!showMatchedOnly || (showMatchedOnly && countSuccessfulMatches(familyGroup.value) > 0)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                👪&nbsp;{{familyGroup.key}}&nbsp;&nbsp;&nbsp;&nbsp;
                <span *ngIf="countAliveMatches(familyGroup.value)&gt;0">
                  {{'COMMON.alive' | translate}}&nbsp;<b
                    style="color: green;">{{countAliveMatches(familyGroup.value)}}</b>
                </span>
              </mat-panel-title>
              <mat-panel-description>
                &nbsp;&nbsp;
                <mat-checkbox *ngIf="countTranslated(familyGroup.value)&gt;0"
                  id="phrase-show-{{familyGroup.key}}-translated" name="phrase-show-{{familyGroup.key}}-translated"
                  [checked]="_showTranslated.get(familyGroup.key)"
                  (change)="setToggle(_showTranslated, familyGroup.key, $event)">{{'TEST.translated' |
                  translate}}&nbsp;{{countTranslated(familyGroup.value)}}&nbsp;&nbsp;</mat-checkbox>

                <mat-checkbox *ngIf="countSuccessfulMatches(familyGroup.value)&gt;0"
                  id="phrase-show-{{familyGroup.key}}-successful" name="phrase-show-{{familyGroup.key}}-successful"
                  [checked]="_showFamilyMatched.get(familyGroup.key)"
                  (change)="setToggle(_showFamilyMatched, familyGroup.key, $event)">{{'COMMON.matched' |
                  translate}}&nbsp;{{countSuccessfulMatches(familyGroup.value)}}&nbsp;&nbsp;</mat-checkbox>

                <mat-checkbox *ngIf="!showMatchedOnly && countFailedMatches(familyGroup.value)&gt;0"
                  id="phrase-show-{{familyGroup.key}}-failed" name="phrase-show-{{familyGroup.key}}-failed"
                  [checked]="showToggle(_showFamilyMismatched, familyGroup.key)"
                  (change)="setToggle(_showFamilyMismatched, familyGroup.key, $event)">{{'COMMON.partial-matches' |
                  translate}}&nbsp;{{countFailedMatches(familyGroup.value)}}</mat-checkbox>
                &nbsp;
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <mat-expansion-panel
                *ngFor="let obj of familyGroup.value | tisanePhraseFilter : { translated: _showTranslated.get(familyGroup.key), matched: _showFamilyMatched.get(familyGroup.key), mismatched: _showFamilyMismatched.get(familyGroup.key)}">
                <mat-expansion-panel-header class="mat-button-wrapper">
                  <mat-panel-title class="tooltip" appShowTooltipDescription [tooltipDelay]="400"
                    [tooltipOption]="{ type: 'Phrase', familyId: obj.family, phraseId: obj.id }">
                    <ng-container *ngTemplateOutlet="safeTitle; context { entity: obj }"></ng-container>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <mat-tab-group [disableRipple]="true">
                    <mat-tab label="{{'COMMON.details' | translate}}">
                      <p><button type="button" class="orangebtn" (click)="findObject(obj.runtimeId)"
                          title="Search">🔎</button></p>
                      <span [innerHTML]="objectToSafeHTML(obj)"></span>
                    </mat-tab>
                    <mat-tab label="{{'COMMON.elements' | translate}}">
                      <mat-accordion>
                        <mat-expansion-panel *ngFor="let el of obj.elements">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="tooltip panel-title-hypothesis" appShowTooltipDescription
                              [tooltipDelay]="400"
                              [tooltipOption]="{ type: 'Hypothesis', lexemeId: geLexemeId(el.target_node) }"
                              [tooltipEnabled]="_entitiesByRef.has(el.target_node) && _entitiesByRef.get(el.target_node).type == 'lexical_item'">
                              <span *ngIf="el.incomplete">😞&nbsp;</span>
                              <ng-container
                                *ngTemplateOutlet="safeTitle; context { entity: el.target_node, byHandle: true, family: obj.family, identity: _entitiesByRef.has(el.element_pattern_id) && _entitiesByRef.get(el.element_pattern_id).identity }">
                              </ng-container>
                            </mat-panel-title>
                            <mat-panel-description>
                              <mat-chip-list *ngIf="el.conditions">
                                <mat-chip *ngFor="let cnd of el.conditions" appShowTooltipDescription
                                  [tooltipDelay]="200" [tooltipEnabled]="['C', 'T', 'J', 'V'].indexOf(cnd.tag) >= 0"
                                  [tooltipOption]="{ type: 'Family', familyId: cnd.id }">
                                  <div
                                    [matTooltip]="cnd.feature ? (cnd.mode ? cnd.mode + ' ' : '') + (_featureDescriptionStore[cnd.tag + cnd.feature.index + cnd.feature.value] || getFeatureDescription(cnd.tag, cnd.feature.index, cnd.feature.value)) : ''">
                                    <b>{{cnd.tag}}</b>
                                    <span [style.text-decoration]="getConditionTextDecoration(cnd)"
                                      [style.text-decoration-style]="getConditionTextDecorationStyle(cnd)"
                                      *ngIf="cnd.feature">{{cnd.feature.index}}={{cnd.feature.value}}</span>
                                    <span [style.text-decoration]="getConditionTextDecoration(cnd)"
                                      [style.text-decoration-style]="getConditionTextDecorationStyle(cnd)"
                                      *ngIf="cnd.id || cnd.tagValue">={{cnd.id}}{{cnd.tagValue}}</span>
                                  </div>
                                </mat-chip>
                              </mat-chip-list>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <p>{{getElementTitle(el)}}</p>
                            <div class="element-detail" *ngIf="el.mismatches && el.mismatches.length &gt; 0">
                              <p>{{'COMMON.mismatches' | translate}}</p>
                              <!-- the ones that did not match -->
                              <mat-chip-list class="multi-line" *ngFor="let mismatch of el.mismatches">
                                <mat-chip class="tooltip" appShowTooltipDescription [tooltipDelay]="400"
                                  [tooltipOption]="{ type: 'Lexeme', lexemeId: _entitiesByRef.has(mismatch.linkedEntity) && _entitiesByRef.get(mismatch.linkedEntity).lexeme }">
                                  <span [innerHTML]="getHypothesisSenseDescription(mismatch.linkedEntity)"></span>
                                </mat-chip>
                                <mat-chip matTooltip="{{mismatch.error}}" class="error-tooltip"
                                  [style.background-color]="'red'">
                                  {{truncate(mismatch.error)}}</mat-chip>
                              </mat-chip-list>
                            </div>
                            <ng-container *ngIf="el.matches">
                              <div *ngFor="let match of (el.matches || [])">
                                <!-- Senses -->
                                <span *ngIf="_entitiesByRef.has(match.ref)">
                                </span>
                                <div *ngIf="match.type == 'sense'">
                                  <p>
                                    <mat-chip [style.background-color]="'#75b6de'" [matTooltip]="(_entitiesByRef.get(match.hypothesis_runtimeId) && _entitiesByRef.get(match.hypothesis_runtimeId).deleted) || match.deactivation">
                                      <span *ngIf="match.active === false || match.deleted" [matTooltip]="match.deleted">🗑</span>&nbsp;{{'TEST.sense' | translate}}
                                    </mat-chip>
                                  </p>
                                  <div class="element-detail">
                                    <mat-chip matTooltip="#{{match.id}} {{match.definition}}">
                                      {{getObjectEmoji(match)}}&nbsp;
                                      <a href="/knowledge-graph?key=id&value={{match.family}}" target="_blank"
                                        [innerHTML]="chipFaceDescription(match.definition)"></a>
                                    </mat-chip>
                                    <div *ngFor="let featureType of ['grammar', 'style', 'semantics']"
                                      style="margin-top: 10px;">
                                      <div *ngIf="match[featureType]">
                                        <mat-chip-list>
                                          <mat-chip [style.background-color]="'gray'">{{('COMMON.' + featureType) |
                                            translate}}</mat-chip>
                                          <mat-chip *ngFor="let g of sortedByIndex(match[featureType])"
                                            [matTooltip]="_featureDescriptionStore[featureType + g.index + g.value] || getFeatureDescription(featureType, g.index, g.value)">
                                            <div>
                                              {{g.index}}={{g.value}}
                                            </div>
                                          </mat-chip>
                                        </mat-chip-list>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div *ngIf="match.ref && subphrases.has(match.ref)">
                                  <p>
                                    <mat-chip [style.background-color]="'#75b6de'" [matTooltip]="(_entitiesByRef.get(match.hypothesis_runtimeId) && _entitiesByRef.get(match.hypothesis_runtimeId).deleted) || match.deactivation">
                                      <span
                                        *ngIf="subphrases.get(match.ref).active === false || subphrases.get(match.ref).deleted" [matTooltip]="subphrases.get(match.ref).deleted">🗑</span>&nbsp;{{'TEST.subphrases'
                                      |
                                      translate}}
                                    </mat-chip>
                                  </p>
                                  <div class="element-detail">
                                    <p><b>{{'TEST.runtime-id' | translate}}:</b> {{subphrases.get(match.ref).runtimeId}}
                                    </p>
                                    <div *ngFor="let featureType of ['grammar', 'style', 'semantics']">
                                      <div *ngIf="subphrases.get(match.ref)[featureType]">
                                        <mat-chip-list>
                                          <mat-chip [style.background-color]="'gray'">{{('COMMON.' + featureType) |
                                            translate}}</mat-chip>
                                          <mat-chip
                                            *ngFor="let g of sortedByIndex(subphrases.get(match.ref)[featureType])"
                                            [matTooltip]="_featureDescriptionStore[featureType + g.index + g.value] || getFeatureDescription(featureType, g.index, g.value)">
                                            <div>
                                              {{g.index}}={{g.value}}
                                            </div>
                                          </mat-chip>
                                        </mat-chip-list>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  *ngIf="match.ref && _entitiesByRef.has(match.ref) && _entitiesByRef.get(match.ref).type == 'hypothesis'">
                                  <p>
                                    <mat-chip [style.background-color]="'#75b6de'" [matTooltip]="(_entitiesByRef.get(match.hypothesis_runtimeId) && _entitiesByRef.get(match.hypothesis_runtimeId).deleted) || match.deactivation">
                                      <span [matTooltip]="_entitiesByRef.get(match.ref).deleted"
                                        *ngIf="_entitiesByRef.get(match.ref).active === false || _entitiesByRef.get(match.ref).deleted">🗑</span>&nbsp;{{'TEST.hypothesis'
                                      |
                                      translate}}
                                    </mat-chip>
                                  </p>
                                  <div class="element-detail">
                                    <a href="/lexicon?key=id&value={{_entitiesByRef.get(match.ref).lexeme}}"
                                      target="_blank" style="display: block; margin-bottom: 10px;"
                                      title="View lexeme">[Lexeme#{{_entitiesByRef.get(match.ref).lexeme}}]</a>
                                    <div *ngFor="let featureType of ['grammar', 'style', 'semantics']">
                                      <div *ngIf="_entitiesByRef.get(match.ref)[featureType]">
                                        <mat-chip-list>
                                          <mat-chip [style.background-color]="'gray'">{{('COMMON.' + featureType) |
                                            translate}}</mat-chip>
                                          <mat-chip
                                            *ngFor="let g of sortedByIndex(_entitiesByRef.get(match.ref)[featureType])"
                                            [matTooltip]="_featureDescriptionStore[featureType + g.index + g.value] || getFeatureDescription(featureType, g.index, g.value)">
                                            <div>
                                              {{g.index}}={{g.value}}
                                            </div>
                                          </mat-chip>
                                        </mat-chip-list>
                                      </div>
                                    </div>

                                    <div
                                      *ngIf="_entitiesByRef.get(match.ref).senses && _entitiesByRef.get(match.ref).senses">
                                      <p><mat-chip [style.background-color]="'#9ab9cd'"
                                          class="has-senses">{{'commonsense-cues.families' |
                                          translate}}</mat-chip></p>
                                      <mat-chip-list>
                                        <mat-chip
                                          *ngFor="let sense of removeDuplicatedByProperty(_entitiesByRef.get(match.ref).senses, 'family')">
                                          <div>
                                            <a appShowTooltipDescription [tooltipDelay]="200"
                                              [tooltipOption]="{ type: 'Family', familyId: sense.family }"
                                              href="/knowledge-graph?key=id&value={{sense.family}}" target="_blank"
                                              title="View family">{{sense.family}}</a>
                                          </div>
                                        </mat-chip>
                                      </mat-chip-list>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </ng-container>
                          </ng-template>

                        </mat-expansion-panel>
                      </mat-accordion>
                    </mat-tab>
                    <mat-tab *ngIf="obj.linked && !obj.incomplete" label="{{'TEST.parse-forests' | translate}}">
                      <div style="padding: 25px;">
                        <mat-chip-list orientation="vertical">
                          <mat-chip class="ti-wrap-description" *ngFor="let lnkId of getParseType(obj.linked)"
                            [matTooltip]="getValueByProperty(lnkId, 'deleted')">
                            <a (click)="openParseForest(lnkId); $event.preventDefault();"><span
                                [innerHTML]="objectPanelHeaderSafeHTMLByHandle(lnkId)"></span></a>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </mat-tab>
                    <mat-tab *ngIf="obj.failures" label="⚠&nbsp;{{'TEST.failures' | translate}}">
                      <mat-accordion>
                        <mat-expansion-panel *ngFor="let failureLinkedEntity of obj.failures | keyvalue">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <span [innerHTML]="objectPanelHeaderSafeHTMLByHandle(failureLinkedEntity.key)"></span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <mat-chip-list *ngFor="let failure of failureLinkedEntity.value">
                              <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                              <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                [style.background-color]="'red'">{{truncate(failure.error)}}</mat-chip>
                            </mat-chip-list>
                          </ng-template>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </mat-tab>
                    <mat-tab *ngIf="obj.subphrases" label="{{'TEST.subphrases' | translate}}">
                      <mat-accordion>
                        <mat-expansion-panel *ngFor="let subphrase of obj.subphrases">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <span *ngIf="subphrase.active === false || subphrase.deleted" [matTooltip]="subphrase.deleted">🗑 &nbsp;</span>
                              <span>[{{subphrase.runtimeId}}]&nbsp;</span>
                            </mat-panel-title>
                            <mat-panel-description>
                              <span *ngIf="subphrase.grammar">
                                <span
                                  *ngFor="let el of subphrase.grammar; let i = index">{{el.index}}={{el.value}},&nbsp;</span>
                              </span>
                              <span *ngIf="subphrase.semantics">
                                <span *ngFor="let el of subphrase.semantics; let i = index">
                                  {{el.index}}={{el.value}}
                                  <span *ngIf="i != subphrase.semantics.length - 1">,&nbsp;</span>
                                </span>
                              </span>
                              <span *ngIf="subphrase.disambiguated">🏆 </span>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <span class="delete-reason"
                              matTooltip="{{'TEST.delete-reason' | translate}}">{{getDeleteReason(subphrase)}}</span>
                            <p><strong>{{'TEST.score' | translate}}: </strong></p>
                            <mat-chip-list>
                              <ng-container *ngIf="subphrase.score">
                                <mat-chip
                                  *ngFor="let key of objectKeys(subphrase.score)"><strong>{{key}}</strong>:{{subphrase.score[key]}}
                                </mat-chip>&nbsp;
                              </ng-container>
                            </mat-chip-list>
                            <mat-accordion class="subphrases">
                              <p><strong style="color: #000;">{{'TEST.elements' | translate}}</strong></p>
                              <mat-expansion-panel style="margin-top: 0;"
                                *ngFor="let el of sortedByIndex(subphrase.elements)">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    {{'COMMON.identity' | translate}}&nbsp;{{el.identity ? el.identity : 'COMMON.none' |
                                    translate}}
                                  </mat-panel-title>

                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                  <p><strong>Matches: </strong></p>
                                  <div *ngFor="let match of removeDuplicatedByProperty(el.matches, 'family')"
                                    class="match">
                                    <span *ngIf="match.abuse" class="match-abuse">👺</span>
                                    <span *ngIf="match.sentiment" class="match-sentiment">😐</span>
                                    <div *ngIf="match.family">
                                      👪 <a href="/knowledge-graph?key=id&value={{match.family}}" target="_blank"
                                        title="View family">[Family#{{match.family}}]</a>
                                    </div>
                                    <p *ngIf="match.lexeme">
                                      <strong>{{'COMMON.lexeme' | translate}}: </strong>
                                      <a href="/lexicon?key=id&value={{match.lexeme}}" target="_blank"
                                        title="View lexeme">[Lexeme#{{match.lexeme}}]</a>
                                    </p>
                                    <div *ngIf="match.grammar">
                                      <mat-chip-list *ngIf="match.grammar">
                                        <mat-chip [style.background-color]="'gray'">{{'COMMON.grammar' |
                                          translate}}</mat-chip>
                                        <mat-chip *ngFor="let g of sortedByIndex(match.grammar)">
                                          <div>
                                            {{g.index}}={{g.value}}
                                          </div>
                                        </mat-chip>
                                      </mat-chip-list>
                                    </div>
                                    <div *ngIf="match.senses">
                                      <p><strong>{{'COMMON.interpretations' | translate}}: </strong></p>
                                      <mat-chip-list>
                                        <mat-chip
                                          *ngFor="let sense of removeDuplicatedByProperty(match.senses, 'family')">
                                          <div>
                                            <a appShowTooltipDescription [tooltipDelay]="200"
                                              [tooltipOption]="{ type: 'Family', familyId: sense.family }"
                                              href="/knowledge-graph?key=id&value={{sense.family}}" target="_blank"
                                              title="View family">{{sense.family}}</a>
                                          </div>
                                        </mat-chip>
                                      </mat-chip-list>
                                    </div>
                                  </div>
                                  <div *ngIf="el.grammarFilter">
                                    <p><strong>{{'TEST.grammar-clustered-by' | translate}}: </strong></p>
                                    <p style="margin-left: 10px;">
                                      {{sortNumber(el.grammarFilter).join(', ')}}
                                    </p>
                                  </div>
                                </ng-template>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </ng-template>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </mat-tab>
                    <mat-tab *ngFor="let gen of obj.generation"
                      [label]="('TEST.translation-to' | translate) + ' ' + getLanguageName(gen.lampLangId)">
                      <ng-container *ngIf="gen.targets">
                        <mat-expansion-panel *ngIf="gen.targets['gateway']">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <div style="background-color: magenta;color: white;">[{{'TEST.gateway' | translate}}]
                              </div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <ng-container
                              *ngTemplateOutlet="generation; context { obj: obj, item: gen.targets.gateway, lateGrammar: gen.targets['lateGrammar'], isShowHeader: true }"></ng-container>
                          </ng-template>
                        </mat-expansion-panel>
                        <ng-container *ngFor="let choice of gen.targets['choices']; index as i">
                          <mat-expansion-panel style="overflow: visible;">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <ng-container
                                  *ngTemplateOutlet="generationHeader; context { obj: obj, item: choice, selectedPhraseId: gen.targets['selected'] && gen.targets['selected'].phrase_id}"></ng-container>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                              <ng-container
                                *ngTemplateOutlet="generation; context { obj: obj, item: choice, isShowHeader: false}"></ng-container>
                            </ng-template>
                          </mat-expansion-panel>
                        </ng-container>
                      </ng-container>
                    </mat-tab>
                  </mat-tab-group>
                </ng-template>
              </mat-expansion-panel>
            </ng-template>
          </mat-expansion-panel>
        </ng-container>

      </ng-template>
      <ng-template *ngIf="entityType === 'phrasal_pattern' || entityType === 'commonsense_cue'"
        matExpansionPanelContent>
        <div class="ti-form__filter-phrasal-pattern-commonsense-cue">
          <span class="ti-icon__filter"><mat-icon>filter_list</mat-icon></span>
          <mat-form-field appearance="fill">
            <mat-label *ngIf="entityType === 'phrasal_pattern'">{{'TEST.filter-by-family-id-phrase-id' |
              translate}}</mat-label>
            <mat-label *ngIf="entityType === 'commonsense_cue'">{{'TEST.filter-commonsense-cue-id-description' |
              translate}}</mat-label>
            <input matInput #localFilterBox (keyup)="localFilter(localFilterBox.value, entityType)" />

            <button [disabled]="!localFilterBox.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="localFilterBox.value = ''; localFilter(localFilterBox.value, entityType)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <mat-expansion-panel *ngFor="let familyGroup of _localFilterState[entityType] | keyvalue">
          <mat-expansion-panel-header class="mat-button-wrapper">
            <mat-panel-title>
              {{entityType === 'commonsense_cue' ? '🔢' : '👪'}}&nbsp;{{familyGroup.key}}...{{familyGroup.key +
              (entityType === 'commonsense_cue'? 99 : 49)}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <mat-expansion-panel *ngFor="let obj of familyGroup.value">
              <mat-expansion-panel-header class="mat-button-wrapper">
                <mat-panel-title class="tooltip" appShowTooltipDescription [tooltipDelay]="400"
                  [tooltipOption]="{ type: 'Phrase', familyId: obj.family, phraseId: obj.id }"
                  [tooltipEnabled]="entityType === 'phrasal_pattern'">
                  <ng-container *ngTemplateOutlet="safeTitle; context { entity: obj }"></ng-container>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <mat-tab-group [disableRipple]="true">
                  <mat-tab label="{{'COMMON.details' | translate}}">
                    <p><button type="button" class="orangebtn" (click)="findObject(obj.runtimeId)"
                        title="Search">🔎</button></p>
                    <span [innerHTML]="objectToSafeHTML(obj)"></span>
                  </mat-tab>
                  <mat-tab *ngIf="obj.failures" label="⚠&nbsp;{{'TEST.failures' | translate}}">
                    <mat-accordion>
                      <!-- FAILURES -->
                      <ng-container *ngFor="let failureLinkedEntity of obj.failures | keyvalue">
                        <mat-expansion-panel *ngIf="!_isHypothesis[failureLinkedEntity.key]">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <span [innerHTML]="objectPanelHeaderSafeHTMLByHandle(failureLinkedEntity.key)"></span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <!-- Lexical item level -->
                            <ng-container
                              *ngIf="_nestedHypotheses[obj.runtimeId + '_' + failureLinkedEntity.key] || _nestedSenses[obj.runtimeId + '_' + failureLinkedEntity.key]">
                              <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <span [innerHTML]="'TEST.lexical-item-level' | translate"></span>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                  <mat-chip-list *ngFor="let failure of failureLinkedEntity.value">
                                    <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                                    <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                      [style.background-color]="'red'">
                                      {{truncate(failure.error)}}</mat-chip>
                                  </mat-chip-list>
                                </ng-template>
                              </mat-expansion-panel>
                            </ng-container>
                            <!-- Nested hypotheses -->
                            <ng-container *ngIf="_nestedHypotheses[obj.runtimeId + '_' + failureLinkedEntity.key]">
                              <ng-container
                                *ngFor="let hypothesis of _nestedHypotheses[obj.runtimeId + '_' + failureLinkedEntity.key]">
                                <mat-expansion-panel>
                                  <mat-expansion-panel-header>
                                    <mat-panel-title class="tooltip" appShowTooltipDescription [tooltipDelay]="400"
                                      [tooltipOption]="{ type: 'Lexeme', lexemeId: _entitiesByRef.get(hypothesis.runtimeId).lexeme }"
                                      [tooltipEnabled]="_entitiesByRef.get(hypothesis.runtimeId).lexeme > 0">
                                      <span [innerHTML]="getHypothesisSenseDescription(hypothesis.runtimeId)"></span>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <ng-template matExpansionPanelContent>
                                    <mat-chip-list *ngFor="let failure of hypothesis.failures">
                                      <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                                      <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                        [style.background-color]="'red'">
                                        {{truncate(failure.error)}}</mat-chip>
                                    </mat-chip-list>
                                  </ng-template>
                                </mat-expansion-panel>
                              </ng-container>
                            </ng-container>

                            <!-- Nested senses -->
                            <ng-container *ngIf="_nestedSenses[obj.runtimeId + '_' + failureLinkedEntity.key]">
                              <ng-container
                                *ngFor="let sense of _nestedSenses[obj.runtimeId + '_' + failureLinkedEntity.key]">
                                <mat-expansion-panel>
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>
                                      <span [innerHTML]="getHypothesisSenseDescription(sense.runtimeId)"></span>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <ng-template matExpansionPanelContent>
                                    <mat-chip-list *ngFor="let failure of sense.failures">
                                      <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                                      <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                        [style.background-color]="'red'">
                                        {{truncate(failure.error)}}</mat-chip>
                                    </mat-chip-list>
                                  </ng-template>
                                </mat-expansion-panel>
                              </ng-container>
                            </ng-container>

                            <ng-container
                              *ngIf="!_nestedHypotheses[obj.runtimeId + '_' + failureLinkedEntity.key] && !_nestedSenses[obj.runtimeId + '_' + failureLinkedEntity.key]">
                              <mat-chip-list *ngFor="let failure of failureLinkedEntity.value">
                                <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                                <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                  [style.background-color]="'red'">
                                  {{truncate(failure.error)}}</mat-chip>
                              </mat-chip-list>
                            </ng-container>
                          </ng-template>
                        </mat-expansion-panel>
                      </ng-container>

                      <!-- HYPOTHESES -->
                      <ng-container *ngFor="let linkedLexical of obj.lexicalItems">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              📖&nbsp;<span>{{linkedLexical.text}}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <mat-expansion-panel *ngFor="let hypothesis of linkedLexical.hypotheses">
                              <mat-expansion-panel-header>
                                <mat-panel-title appShowTooltipDescription [tooltipDelay]="400"
                                  [tooltipOption]="{ type: 'Lexeme', lexemeId: hypothesis.lexemeId}"
                                  [tooltipEnabled]="_entitiesByRef.has(hypothesis.runtimeId) && _entitiesByRef.get(hypothesis.runtimeId).type == 'hypothesis'">
                                  <span [innerHTML]="getHypothesisSenseDescription(hypothesis.runtimeId)"></span>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <ng-template matExpansionPanelContent>
                                <mat-chip-list *ngFor="let failure of hypothesis.failures">
                                  <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                                  <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                    [style.background-color]="'red'">
                                    {{truncate(failure.error)}}</mat-chip>
                                </mat-chip-list>
                              </ng-template>
                            </mat-expansion-panel>
                            <mat-expansion-panel *ngFor="let sense of linkedLexical.senses">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <span [innerHTML]="getHypothesisSenseDescription(sense.runtimeId)"></span>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <ng-template matExpansionPanelContent>
                                <mat-chip-list *ngFor="let failure of sense.failures">
                                  <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                                  <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                                    [style.background-color]="'red'">
                                    {{truncate(failure.error)}}</mat-chip>
                                </mat-chip-list>
                              </ng-template>
                            </mat-expansion-panel>
                          </ng-template>
                        </mat-expansion-panel>
                      </ng-container>
                    </mat-accordion>
                  </mat-tab>
                  <mat-tab *ngIf="obj.element_conditions || obj.elements" label="{{'COMMON.elements' | translate}}">
                    <mat-accordion>
                      <mat-expansion-panel *ngFor="let el of elementsOrConditions(obj)">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span *ngIf="el.trigger"
                              matTooltip="{{'inflection-table.trigger' | translate}}">🎬&nbsp;</span>
                            {{'COMMON.identity' | translate}}&nbsp;{{el.identity ? el.identity : 'COMMON.none' |
                            translate}}
                            <span *ngIf="el.parent && el.parent.family && el.identity">&nbsp; :
                              {{getPhraseIdentityDescription(el.parent.family, el.identity)}}</span>
                            &nbsp;
                            <span *ngIf="el.head"
                              matTooltip="{{'element-clause.is-head-member' | translate}}">👑&nbsp;</span>
                            <span *ngIf="!el.allowPhrases" matTooltip="{{'element-clause.lexemes-only' | translate}}"
                              [style.text-decoration]="'line-through'">🔣&nbsp;</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                          <div style="padding: 25px;">
                            <span *ngIf="el.absent">❎</span>
                            <mat-chip-list>
                              <mat-chip *ngFor="let cnd of el.conditions" appShowTooltipDescription [tooltipDelay]="200"
                                [tooltipEnabled]="['C', 'T', 'J', 'V'].indexOf(cnd.tag) >= 0"
                                [tooltipOption]="{ type: 'Family', familyId: cnd.id }">
                                <div
                                  [matTooltip]="cnd.feature && ['C', 'T', 'J', 'V'].indexOf(cnd.tag) == -1 ? (cnd.mode ? cnd.mode + ' ' : '') + (_featureDescriptionStore[cnd.tag + cnd.feature.index + cnd.feature.value] || getFeatureDescription(cnd.tag, cnd.feature.index, cnd.feature.value)) : ''">
                                  <b>{{cnd.tag}}</b>
                                  <span [style.text-decoration]="getConditionTextDecoration(cnd)"
                                    [style.text-decoration-style]="getConditionTextDecorationStyle(cnd)"
                                    *ngIf="cnd.feature">{{cnd.feature.index}}={{cnd.feature.value}}</span>
                                  <span [style.text-decoration]="getConditionTextDecoration(cnd)"
                                    [style.text-decoration-style]="getConditionTextDecorationStyle(cnd)"
                                    *ngIf="cnd.id || cnd.tagValue">={{cnd.id}}{{cnd.tagValue}}</span>
                                </div>
                              </mat-chip>
                            </mat-chip-list>
                          </div>
                        </ng-template>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-tab>
                </mat-tab-group>
              </ng-template>
            </mat-expansion-panel>
          </ng-template>
        </mat-expansion-panel>
      </ng-template>
      <ng-template *ngIf="entityType == 'memory'" matExpansionPanelContent>
        <ul>
          <li *ngFor="let obj of _entityGroups.get(entityType)" [id]="obj.runtimeId">
            <ng-container *ngIf="obj.contents && obj.contents.flags">
              <mat-expansion-panel>
                <mat-expansion-panel-header class="mat-button-wrapper">
                  <mat-panel-title>{{'TEST.flags' | translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <mat-tab-group dynamicHeight [disableRipple]="true">
                    <mat-tab label="{{'COMMON.details' | translate}}">
                      <p><button type="button" class="orangebtn" (click)="findObject(obj.runtimeId)"
                          title="Search">🔎</button></p>
                      <span [innerHTML]="objectToSafeHTML(obj)"></span>
                    </mat-tab>
                    <mat-tab label="{{'TEST.content' | translate}}">
                      <div style="padding: 25px;">
                        <mat-chip-list>
                          <mat-chip *ngFor="let flag of obj.contents.flags">
                            {{getFeatureDescription('S', flag.index, flag.value )}}
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </ng-template>
              </mat-expansion-panel>
            </ng-container>
          </li>
        </ul>
      </ng-template>
      <ng-template
        *ngIf="!(entityType === 'phrasal_pattern' || entityType === 'phrase' || entityType === 'commonsense_cue' || entityType === 'phrasal_element')"
        matExpansionPanelContent>
        <ng-container *ngIf="entityType == 'commonsense_cue_match'">
          <mat-tab-group [disableRipple]="true">
            <mat-tab *ngFor="let group of _entityGroups.get(entityType)" label="{{group.key}}">
              <ul>
                <li *ngFor="let obj of group.value" [id]="obj.runtimeId">
                  <ng-container *ngTemplateOutlet="commonEntityStructure; context { obj: obj }"></ng-container>
                </li>
              </ul>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-container *ngIf="entityType != 'commonsense_cue_match'">
          <ul>
            <li *ngFor="let obj of _entityGroups.get(entityType)" [id]="obj.runtimeId">
              <ng-container *ngTemplateOutlet="commonEntityStructure; context { obj: obj }"></ng-container>
            </li>
          </ul>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="objectKeys(assumption).length > 0">
      <mat-expansion-panel-header>
        <mat-panel-title>⏳ {{ 'TEST.assumptions' | translate}}</mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-expansion-panel *ngFor="let key of objectKeys(assumption)">
          <mat-expansion-panel-header>
            <mat-panel-title>{{key}}</mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ul class="list-assumption" style="list-style-type: disclosure-closed;">
              <li *ngFor="let text of assumption[key]" style="white-space: pre-line; margin-bottom: 15px;">
                <div #content (click)="content.classList.toggle('full')">
                  {{ text.length > 200 && !content.classList.contains('full') ? text.substring(0, 200) : text }}
                  <span *ngIf="text.length > 200 && !content.classList.contains('full')"
                    style="font-weight: 500; cursor: pointer;font-size: 1.2em;"> (...)</span>
                </div>
              </li>
            </ul>
          </ng-template>
        </mat-expansion-panel>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #safeTitle let-entity="entity" let-byHandle="byHandle" let-family="family" let-identity="identity">
  <span *ngIf="entity && _panelHeaderStore[entity.runtimeId]" [innerHTML]="_panelHeaderStore[entity.runtimeId]"></span>
  <span *ngIf="entity && !_panelHeaderStore[entity.runtimeId] && !byHandle"
    [innerHTML]="objectPanelHeaderSafeHTML(entity)"></span>
  <span *ngIf="entity && !_panelHeaderStore[entity.runtimeId] && byHandle"
    [innerHTML]="objectPanelHeaderSafeHTMLByHandle(entity)"></span>
  <span *ngIf="family && identity">&nbsp; (identity {{identity}}: {{getPhraseIdentityDescription(family,
    identity)}})</span>
</ng-template>
<ng-template #commonEntityStructure let-obj="obj">
  <mat-expansion-panel>
    <mat-expansion-panel-header class="mat-button-wrapper">
      <mat-panel-title [innerHTML]="objectPanelHeaderSafeHTML(obj)">
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-tab-group [disableRipple]="true">
        <mat-tab label="{{'COMMON.details' | translate}}">
          <p><button type="button" class="orangebtn" (click)="findObject(obj.runtimeId)" title="Search">🔎</button></p>
          <span [innerHTML]="objectToSafeHTML(obj)"></span>
        </mat-tab>
        <mat-tab *ngIf="obj.failures && obj.failures.size === 1" label="⚠&nbsp;{{'TEST.failures' | translate}}">
          <div style="padding: 25px;">
            <mat-chip-list *ngFor="let failure of obj.failures.get(0)">
              <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
              <mat-chip matTooltip="{{failure.error}}" class="error-tooltip" [style.background-color]="'red'"
                matBadge="{{failure.times &gt; 1 ? failure.times : ''}}" matBadgePosition="below after">
                {{truncate(failure.error)}}</mat-chip>
            </mat-chip-list>
          </div>
        </mat-tab>
        <mat-tab *ngIf="obj.failures && obj.failures.size &gt; 1" label="⚠&nbsp;{{'TEST.failures' | translate}}">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let failureLinkedEntity of obj.failures | keyvalue">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span [innerHTML]="objectPanelHeaderSafeHTMLByHandle(failureLinkedEntity.key)"></span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <mat-chip-list *ngFor="let failure of failureLinkedEntity.value">
                  <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                  <mat-chip matTooltip="{{failure.error}}" class="error-tooltip" [style.background-color]="'red'">
                    {{truncate(failure.error)}}</mat-chip>
                </mat-chip-list>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>
        <mat-tab *ngIf="obj.hypotheses" label="{{'COMMON.interpretations' | translate}}">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let hyp of allHypotheses(obj)">
              <mat-expansion-panel-header *ngIf="hyp">
                <mat-panel-title>
                  <span *ngIf="hyp.deleted || hyp.active === false" matTooltip="{{hyp.deleted || hyp.deactivation}}">🗑️&nbsp;</span>
                  <b>{{getFeature1(hyp.grammar)}}</b>&nbsp;
                  <a class="tooltip" appShowTooltipDescription [tooltipDelay]="200"
                    [tooltipOption]="{ type: 'Lexeme', lexemeId: hyp.lexeme }"
                    [href]="'/lexicon?key=id&value='+hyp.lexeme" target="_blank">{{'COMMON.lexeme' |
                    translate}}&nbsp;{{hyp.lexeme}}</a>
                  <span *ngIf="hyp.stopword" [matTooltip]="'TEST.stopword' | translate">&nbsp;🛑</span>
                  <!-- detectedSense -->
                  <span *ngIf="obj.detectedSense && hyp.family == obj.detectedSense.family">&nbsp;🏆</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template *ngIf="hyp" matExpansionPanelContent>
                <mat-tab-group [disableRipple]="true">
                  <mat-tab label="{{'COMMON.details' | translate}}">
                    <div style="padding: 25px;">
                      <mat-chip-list *ngIf="hyp.grammar">
                        <mat-chip [style.background-color]="'gray'">{{'COMMON.grammar' | translate}}</mat-chip>
                        <mat-chip *ngFor="let cnd of sortedByIndex(hyp.grammar)">
                          <div
                            [matTooltip]="_featureDescriptionStore['R' + cnd.index + cnd.value] || getFeatureDescription('R', cnd.index, cnd.value)">
                            {{cnd.index}}={{cnd.value}}
                          </div>
                        </mat-chip>
                      </mat-chip-list>
                      <mat-chip-list *ngIf="hyp.style">
                        <mat-chip [style.background-color]="'gray'">{{'COMMON.style' | translate}}</mat-chip>
                        <mat-chip *ngFor="let cnd of sortedByIndex(hyp.style)">
                          <div
                            [matTooltip]="_featureDescriptionStore['Y' + cnd.index + cnd.value] || getFeatureDescription('Y', cnd.index, cnd.value)">
                            {{cnd.index}}={{cnd.value}}
                          </div>
                        </mat-chip>
                      </mat-chip-list>
                      <mat-chip-list *ngIf="hyp.semantics">
                        <mat-chip [style.background-color]="'gray'">{{'COMMON.semantic' | translate}}</mat-chip>
                        <mat-chip *ngFor="let cnd of sortedByIndex(hyp.semantics)">
                          <div
                            [matTooltip]="_featureDescriptionStore['S' + cnd.index + cnd.value] || getFeatureDescription('S', cnd.index, cnd.value)">
                            {{cnd.index}}={{cnd.value}}
                          </div>
                        </mat-chip>
                      </mat-chip-list>
                      <br />
                      <span *ngIf="hyp.legacyRecord">🐃&nbsp;{{hyp.legacyRecord}}</span>
                      <br />
                      <b *ngIf="hyp.senses">{{'COMMON.interpretations' | translate}}</b><br />
                      <mat-chip-list orientation="vertical">
                        <mat-chip *ngFor="let sense of (hyp.senses || []).concat(hyp.inactive || [])" matTooltip="{{sense.definition}}">
                          
                          <span [matTooltip]="sense.deactivation || sense.deleted" *ngIf="sense.deactivation">🗑 &nbsp;</span>
                          <a href="/knowledge-graph?key=id&value={{sense.family}}" target="_blank" appShowTooltipDescription [tooltipDelay]="200"
                            [tooltipOption]="{ type: 'Family', familyId: sense.family }">Family#{{sense.family}}</a>&nbsp;
                          <a [href]="sense.url" target="_blank">{{sense.ref_lemma}}</a>
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </mat-tab>
                  <mat-tab *ngIf="hyp.score" label="{{hyp.score.match_score ? 'Score: ' + hyp.score.match_score : 'Score'}}">
                    <ng-container *ngTemplateOutlet="scoreTemplate; context { score: hyp.score, senses: hyp.senses, inactive: hyp.inactive }"></ng-container>
                  </mat-tab>
                  <mat-tab *ngIf="collectHypothesisLinked(hyp)" label="{{'PHRASES.member-in' | translate}}">
                    <div style="padding: 25px;">
                      <mat-chip-list orientation="vertical">
                        <mat-chip *ngFor="let lnkId of collectHypothesisLinked(hyp)">
                          <span [innerHTML]="objectPanelHeaderSafeHTMLByHandle(lnkId)"></span>
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </mat-tab>
                  <mat-tab label="⚠&nbsp;{{'TEST.failures' | translate}}" *ngIf="findFailureMap(hyp.runtimeId)">
                    <mat-accordion>
                      <mat-expansion-panel *ngFor="let failureMap of findFailureMap(hyp.runtimeId).failures | keyvalue">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span [innerHTML]="objectPanelHeaderSafeHTMLByHandle(failureMap.key)"></span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                          <mat-chip-list *ngFor="let failure of failureMap.value">
                            <mat-chip matTooltip="{{failure.breadcrumbs}}">{{failure.section}}</mat-chip>
                            <mat-chip matTooltip="{{failure.error}}" class="error-tooltip"
                              [style.background-color]="'red'">
                              {{truncate(failure.error)}}</mat-chip>
                          </mat-chip-list>
                        </ng-template>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-tab>
                </mat-tab-group>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>
        <mat-tab *ngIf="obj.linked" label="{{'TEST.related-entities' | translate}}">
          <div style="padding: 25px;">
            <mat-chip-list orientation="vertical">
              <mat-chip *ngFor="let lnkId of obj.linked">
                <span [innerHTML]="objectPanelHeaderSafeHTMLByHandle(lnkId)"></span>
              </mat-chip>
            </mat-chip-list>
          </div>
        </mat-tab>
        <ng-container *ngIf="obj.generation && (obj.type == 'generated' || obj.type == 'lexical_item')">
          <mat-tab *ngFor="let gen of (obj.type == 'generated' ? obj.generation : obj.generation.generation)"
            [label]="('TEST.translation-to' | translate) + ' ' + getLanguageName(gen.target_language_id)">

            <p *ngIf="gen.targets.featuresToCompare"><b>Features matched: </b></p>
            <mat-chip *ngFor="let f of gen.targets.featuresToCompare"
              [matTooltip]="_featureDescriptionStore['Grammar' + f.index + f.value] || getFeatureDescription('Grammar', f.index, f.value)">
              {{f.index + ' = ' + f.value}}
            </mat-chip>

            <ng-container *ngFor="let choice of sortByProp(gen.targets['choices'], 'match_score', 'DESC'); index as i">
              <mat-expansion-panel style="overflow: visible;">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span *ngIf="gen.targets.selected && choice.id == gen.targets.selected.id"
                      matTooltip="{{'TEST.selected' | translate}}">✅&nbsp;</span>
                    <span><a href="/lexicon?key=id&value={{choice.id}}" target="_blank">[Lexeme {{choice.id}}]
                        {{choice.lemma}}</a>: {{choice.match_score}}</span>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <ul class="generation-wrapper">
                    <li *ngIf="choice.features">
                      <b>Features: </b>
                      <ng-container *ngIf="choice.features">
                        <mat-chip *ngFor="let f of choice.features" style="overflow: visible;"
                          [matTooltip]="_featureDescriptionStore[f.type + f.index + f.value] || getFeatureDescription(f.type || 'Grammar', f.index, f.value)"
                          [matBadge]="isExistInMismatches(f, choice.mismatches) ? 'x' : null"
                          matBadgePosition="below after" matBadgeColor="warn">
                          {{f.index + ' = ' + f.value}}</mat-chip>
                      </ng-container>
                    </li>
                    <li *ngIf="choice.late">
                      <b>Late phrase selection features: </b>
                      <ng-container *ngIf="choice.late">
                        <mat-chip *ngFor="let f of choice.late" style="overflow: visible;"
                          [matTooltip]="_featureDescriptionStore[f.type + f.index + f.value] || getFeatureDescription(f.type || 'Grammar', f.index, f.value)">
                          {{f.index + ' = ' + f.value}}</mat-chip>
                      </ng-container>
                    </li>
                    <li *ngIf="choice.root1 || choice.root2">
                      <b>Roots: </b> {{ choice.root1 }} {{choice.root1 && choice.root12 ? ', ' : ''}} {{choice.root2}}
                    </li>
                    <li *ngIf="choice.frequency">
                      <b>Frequency: </b> {{ choice.frequency }}
                    </li>
                    <li *ngIf="choice.forms">
                      <b>Forms: </b>
                      <ul>
                        <li *ngFor="let form of sortByProp(choice.forms, 'match_score', 'DESC')">
                          <span class="form-text"><b matTooltip="text">{{form.text}}</b> <span
                              matTooltip="Score">{{form.match_score}}</span></span>
                          <ng-container *ngIf="form.features">
                            <mat-chip *ngFor="let f of form.features" style="overflow: visible;"
                              [matTooltip]="_featureDescriptionStore[f.type + f.index + f.value] || getFeatureDescription(f.type || 'Grammar', f.index, f.value)"
                              [matBadge]="isExistInMismatches(f, form.mismatches) ? 'x' : null"
                              matBadgePosition="below after" matBadgeColor="warn">
                              {{f.index + ' = ' + f.value}}</mat-chip>
                          </ng-container>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ng-template>
              </mat-expansion-panel>
            </ng-container>

          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-template>
  </mat-expansion-panel>
</ng-template>
<ng-template #generation let-obj="obj" let-item="item" let-item="isShowHeader" let-lateGrammar="lateGrammar">
  <div class="generation">
    <ng-container *ngIf="isShowHeader">
      <ng-container *ngTemplateOutlet="generationHeader; context { obj: obj, item: choice }"></ng-container>
    </ng-container>
    <ng-container
      *ngIf="fetchPhraseDetail(item.phrase_id, obj.runtimeId) && _generalStore['phrase_' + item.phrase_id]  && _generalStore['phrase_' + item.phrase_id].elements">
      <p><b>{{ 'COMMON.elements' | translate }}</b></p>
      <ng-container *ngFor="let e of _generalStore['phrase_' + item.phrase_id].elements">
        <mat-chip *ngIf="isExist(item.elements, e.identity, 'identity')" [matBadge]="e.identity"
          matBadgePosition="below after" style="overflow:visible">
          {{e.source}} <span style="padding: 0 10px;">➡️</span> {{e.description}}
        </mat-chip>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="lateGrammar && lateGrammar.length">
      <p><b>{{ 'TEST.late-grammar' | translate }}</b></p>
      <mat-chip *ngFor="let f of lateGrammar"
        [matTooltip]="_featureDescriptionStore['Grammar' + f.index + f.value] || getFeatureDescription('Grammar', f.index, f.value)">
        {{f.index + ' = ' + f.value}}
      </mat-chip>
    </ng-container>
  </div>
</ng-template>
<ng-template #generationHeader let-obj="obj" let-item="item" let-selectedPhraseId="selectedPhraseId">
  <div class="generation-header">
    <div>
      <span *ngIf="item.phrase_id == selectedPhraseId" matTooltip="{{'TEST.selected' | translate}}">✅&nbsp;</span>
      <b>ID: </b>
      <a target="_blank" [routerLink]="'/syntax-and-context/phrasal-patterns/edit/' + obj.family + '/' + item.phrase_id"
        [queryParams]="{editedId:  obj.family, editedValue: item.phrase_id }">{{item.phrase_id}}</a>
    </div>
    <div>
      <b>Score: </b> {{item.match_score || 0}}
    </div>
    <div *ngIf="item.features || item.mismatches">
      <b>Features: </b>
      <ng-container *ngIf="item.features">
        <mat-chip *ngFor="let f of item.features" style="overflow: visible;"
          [matTooltip]="_featureDescriptionStore[f.type + f.index + f.value] || getFeatureDescription(f.type || 'Grammar', f.index, f.value)"
          [matBadge]="isExistInMismatches(f, item.mismatches) ? 'x' : null" matBadgePosition="below after"
          matBadgeColor="warn">
          {{f.index + ' = ' + f.value}}</mat-chip>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #scoreTemplate let-score="score" let-senses="senses" let-inactive="inactive">
  <div *ngIf="score.commonsense" style="margin-bottom: 20px;">
    <mat-accordion *ngIf="score.commonsense">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Commonsense cue matches: {{score.commonsense}}</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <ul style="list-style-type: disc;">
            <li *ngFor="let cue of score.commonsenseCues" style="padding: 10px 0px;">
              <a [routerLink]="'/syntax-and-context/commonsense-cues'" [queryParams]="{key: 'id', value: cue.id}"
                target="_blank">
                {{cue.description || getCommonCueDescription(cue)}} [{{cue.id}}]
              </a> = {{cue.points}} points
            </li>
          </ul>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- Topic -->
  <div *ngIf="score.relevance && score.domains" class="score-relevance">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Topics: {{score.relevance}}</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div style="padding: 25px;">
            <mat-chip-list>
              <mat-chip *ngFor="let domain of score.domains" style="display: ruby;">
                  <a href="/knowledge-graph?key=id&value={{domain.id}}" target="_blank"><span *ngIf="_families.has(domain.id)">{{_families.get(domain.id).description}}</span> [{{domain.id}}]</a> {{domain.relevance}} match(es)
              </mat-chip>
            </mat-chip-list>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngIf="score.relevance && !score.domains">
    <h4>Relevance: {{score.relevance}}</h4>
  </div>
  <div *ngIf="score.frequency !== 0">
    <h4>Frequency: {{score.frequency}}</h4>
  </div>
  <div *ngIf="score.bonus !== 0">
    <h4>Bonus: {{score.bonus}}</h4>
  </div>
  <div *ngIf="senses || inactive" class="score-senses">
    <h4>Senses</h4>
    <ul *ngFor="let sense of (senses || []).concat(inactive || [])">
      <li>
        <span *ngIf="_families.has(sense.family)" class="family-desc">
          <a [href]="'/knowledge-graph?key=id&value=' + sense.family" target="_blank">{{_families.get(sense.family).description}} [{{sense.family}}]</a> {{_families.get(sense.family).definition}}
        </span>
        <ng-container *ngIf="!_families.has(sense.family)">
          <a [href]="'/knowledge-graph?key=id&value=' + sense.family" target="_blank">Family#{{sense.family}}</a>
        </ng-container>
        <div style="padding-left: 20px;">
          <ng-container *ngTemplateOutlet="scoreTemplate; context: { score: sense.score, senses: null }"></ng-container>
        </div>
      </li>
    </ul>
  </div>
</ng-template>