import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {RangeLocalizationDialogComponent} from './range-localization-dialog.component';
import {LinguisticRangeLocalization} from '../../../shared/models/range';

@Directive({selector: '[appOpenRangeLocalizationDialog]'})

export class ButtonOpensRangeLocalizationDirective {
  @Input('rangeLocalizationList') private rangeLocalizationList: LinguisticRangeLocalization[];
  @Input('rangeId') private rangeId: number;
  @Input('doesUserHaveAccessDelete') private doesUserHaveAccessDelete: boolean;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(RangeLocalizationDialogComponent, {
      minWidth: this.isMobileView ? '96%' : '80%',
      data: {
        rangeLocalizationList: this.rangeLocalizationList,
        rangeId: this.rangeId,
        doesUserHaveAccessDelete: this.doesUserHaveAccessDelete
      }
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });
  }
}
