<h1>{{ 'TEST.stats' | translate }}</h1>
<app-universal-range-filter [currentRange]="'testresults'" (chosenParameterEvent)="loadData($event)"
  [rangeNoneText]="'TEST.fagments-tested-daily' | translate"></app-universal-range-filter>
<div class="ti-form__one-line-fields ti-form__one-line-fields--error">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
</div>
<mat-accordion multi="true" *ngIf="showExpansion">
  <mat-expansion-panel [hideToggle]="" (afterExpand)="getStats('metrics')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        🎯&nbsp;{{'TEST.accuracy-stats' | translate}}
      </mat-panel-title>
      <mat-panel-description>
        {{'TEST.accuracy-stats-description' | translate}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container matExpansionPanelContent>
      <mat-tab-group [disableRipple]="true" (selectedTabChange)="lastFrom = null; lastTo = null; onAccuracyTabChanged($event)">
        <mat-tab label="{{'TEST.abuse' | translate}}">
          <div echarts [options]="abuseOptions" [loading]="isLoading.metrics" (chartInit)="abuseChart = $event"
            theme="macarons">
          </div>
          <ng5-slider [(value)]="fromDateAbuse" [(highValue)]="toDateAbuse"
            [options]="abuseDiffSliderOptions"></ng5-slider>
          <ng-container *ngTemplateOutlet="showdiff; context: {difflist: diffAbuse, 
              fromDate: fromDateAbuse, toDate: toDateAbuse, availableDates: abuseDates,
              options: abuseDiffSliderOptions, emojitype: 'abuse', textlabel: ''}"></ng-container>
          <span>
            <ng5-slider [(value)]="sampleDateAbuse" [options]="abuseDiffSliderOptions"></ng5-slider>
            <label class="ti-form__label ti-form__form-attribute-value-field">{{ 'TEST.sample-errors' |
              translate}}</label>
          </span>
          <ng-container *ngTemplateOutlet="sampleErrors; context {type: 'abuse', 
            date: sampleDateAbuse, textlabel: ''}"></ng-container>
        </mat-tab>
        <mat-tab label="{{'TEST.sentiment' | translate}}">
          <div echarts [options]="sentimentOptions" [loading]="loading()" theme="macarons"
            (chartInit)="sentimentChart = $event"></div>
          <ng5-slider [(value)]="fromDateSentiment" [(highValue)]="toDateSentiment"
            [options]="sentimentDiffSliderOptions"></ng5-slider>
          <ng-container *ngTemplateOutlet="showdiff; context: {difflist: diffSentiment, 
            fromDate: fromDateSentiment, toDate: toDateSentiment, availableDates: sentimentDates,
            options: sentimentDiffSliderOptions, emojitype: 'sentiment_expressions', textlabel: ''}"></ng-container>
          <div class="ti-form__one-line-fields">
            <ng5-slider [(value)]="sampleDateSentiment" [options]="sentimentDiffSliderOptions"></ng5-slider>
            <label class="ti-form__label ti-form__form-attribute-value-field">{{ 'TEST.sample-errors' |
              translate}}</label>
          </div>
          <ng-container *ngTemplateOutlet="sampleErrors; context {type: 'sentiment_expressions', 
            date: sampleDateSentiment, textlabel: ''}"></ng-container>
        </mat-tab>
        <mat-tab label="{{'TEST.entities' | translate}}">
          <div echarts [options]="entitiesOptions" [loading]="loading()" theme="macarons"
            (chartInit)="entityChart = $event"></div>
          <ng5-slider [(value)]="fromDateEntities" [(highValue)]="toDateEntities"
            [options]="entityDiffSliderOptions"></ng5-slider>
          <ng-container *ngTemplateOutlet="showdiff; context: {difflist: diffEntity, 
            fromDate: fromDateEntities, toDate: toDateEntities, availableDates: entityDates,
            options: entityDiffSliderOptions, emojitype: 'entity', textlabel: ''}"></ng-container>
          <div class="ti-form__one-line-fields">
            <ng5-slider [(value)]="sampleDateEntities" [options]="entityDiffSliderOptions"></ng5-slider>
            <label class="ti-form__label ti-form__form-attribute-value-field">{{ 'TEST.sample-errors' |
              translate}}</label>
          </div>
          <ng-container *ngTemplateOutlet="sampleErrors; context {type: 'entity', 
            date: sampleDateEntities, textlabel: ''}"></ng-container>
        </mat-tab>
        <ng-container *ngIf="testProviders == undefined">
          <mat-tab label="Loading providers..."></mat-tab>
        </ng-container>
        <ng-container *ngIf="testProviders !== undefined && ratingOptions">
          <mat-tab *ngFor="let provider of testProviders" label="{{provider.description}}" aria-label="{{provider.id}}">
            <div echarts [options]="ratingOptions[provider.id]" [loading]="isLoading.ratingStats[provider.id]" (chartInit)="ratingCharts[provider.id] = $event" *ngIf="isLoading.ratingStats"
              theme="macarons">
            </div>

            <!-- Diff -->
            <div class="ti-form__one-line-fields" *ngIf="transDates.length > 0">
              <ng5-slider [(value)]="fromDateTrans" [(highValue)]="toDateTrans" [options]="transDiffSliderOptions"></ng5-slider>
                <button mat-raised-button color="accent" [disabled]="!fromDateTrans || !toDateTrans || fromDateTrans == lastFrom && toDateTrans == lastTo"
                  (click)="generateRatedDiff(fromDateTrans, toDateTrans, provider.id)">{{ 'TEST.compare-dates' | translate}}
                </button>
                <mat-progress-bar *ngIf="isLoading['ratedDiff']" color="accent" mode="indeterminate" style="margin: 10px 0;"></mat-progress-bar>
                <ul class="translate-diff">
                  <li *ngFor="let rs of ratedDiffResult" [class]="rs.isNegative ? 'negative' : 'positive'">
                    <span class="fragment">{{rs.fragment}}</span><br/>
                    {{rs.result1.raw}} [{{(rs.result1.grade1 * 100).toFixed(5)}}] ➡️  {{rs.result2.raw}} [{{(rs.result2.grade1 * 100).toFixed(5)}}] [<a href="corpora?key=id&value={{rs.testFragmentId}}" target="_blank">{{rs.testFragmentId}}</a>]
                  </li>
                </ul>
            </div>
          </mat-tab>
        </ng-container>

        <!--
        <mat-tab label="{{'TEST.topics' | translate}}">
          <div echarts [options]="topicOptions" [loading]="loading()"  theme="macarons" (chartInit)="topicChart = $event" ></div>
          <ng5-slider [(value)]="fromDateTopics" [(highValue)]="toDateTopics" [options]="topicDiffSliderOptions"></ng5-slider>
          <ng-container *ngTemplateOutlet="showdiff; context: {difflist: diffTopic, 
            fromDate: fromDateTopics, toDate: toDateTopics, availableDates: topicDates,
            options: topicDiffSliderOptions, emojitype: 'topic', textlabel: 'label'}"></ng-container>
            <div class="ti-form__one-line-fields">
              <ng5-slider [(value)]="sampleDateTopics" [options]="topicDiffSliderOptions"></ng5-slider>
              <label class="ti-form__label ti-form__form-attribute-value-field">{{ 'TEST.sample-errors' | translate}}</label>
          </div>
          <ng-container *ngTemplateOutlet="sampleErrors; context {type: 'topic', 
            date: sampleDateTopics, textlabel: 'label'}"></ng-container>
         </mat-tab>
        -->
      </mat-tab-group>
    </ng-container>
  </mat-expansion-panel>

  <mat-expansion-panel (afterExpand)="getStats('unknownStats')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        🔡&nbsp;{{'TEST.parsing' | translate}}
      </mat-panel-title>
      <mat-panel-description>
        {{'TEST.parsing-description' | translate}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container matExpansionPanelContent>
      <mat-tab-group [disableRipple]="true" (selectedTabChange)="onParsingTabChanged($event)">
        <mat-tab label="{{'TEST.unknown-share' | translate}}" aria-label="unknownStats">
          <div echarts [options]="unknownOptions" [loading]="isLoading.unknownStats" (chartInit)="unknownChart = $event"
            theme="macarons">
          </div>
        </mat-tab>
        <mat-tab label="{{'TEST.top-phrases' | translate}}" aria-label="topPhrases">
          <div echarts [options]="topPhraseOptions" [loading]="isLoading.topPhrases"
            (chartInit)="topPhraseChart = $event" (chartDblClick)="topPhraseClick($event)" theme="macarons"></div>
        </mat-tab>
        <mat-tab label="{{'TEST.coverage' | translate}}" aria-label="coverage">
          <div echarts [options]="coverageOptions" [loading]="isLoading.coverage" (chartInit)="coverageChart = $event"
            theme="macarons"></div>
        </mat-tab>
        <mat-tab label="{{'TEST.fragmented' | translate}}" aria-label="fragmented">
          <div echarts [options]="fragmentedOptions" [loading]="isLoading.fragmented"
            (chartInit)="fragmentedChart = $event" theme="macarons"></div>
        </mat-tab>
        <mat-tab label="{{'TEST.failed-phrase-tests' | translate}}" aria-label="phraseTestTotals">
          <mat-progress-bar mode="indeterminate" *ngIf="isLoading.phraseTestTotals"></mat-progress-bar>
          <h4 style="text-align: center;"><span
              [style.color]="failedPhraseTestCount / totalPhraseTests > 0.2 ? 'red': 'black'">{{failedPhraseTestCount}}</span>
            / {{totalPhraseTests}}</h4>
          <div *ngIf="showedFailedPhraseExamples" class="ti-form__one-line-fields ti-form__filter-box">
            <mat-card>
              <div class="ti-form__one-line-fields">
                <label class="ti-form__filter-label" id="radio-group-label">{{'TEST.failed-stage' | translate}} </label>
                <ng-container *ngFor="let item of filterPhraseList">
                  <mat-checkbox [(ngModel)]="item.currentValue" (change)="localFilter()">
                    <span class="ti-form__label">
                      {{item.label | translate}}
                    </span>
                  </mat-checkbox>
                </ng-container>
              </div>
              <mat-checkbox class="ti-form__filter-label" [(ngModel)]="newFailuresOnly">
                <span class="ti-form__label">
                  {{"TEST.show-new-failures-only" | translate}}
                </span>
              </mat-checkbox>
            </mat-card>
          </div>
          <button *ngIf="failedPhraseExamples && failedPhraseExamples.length" class="ti-button__download-csv"
            mat-flat-button color="accent" (click)="downloadFailPhraseInCSV()">
            <mat-icon aria-hidden="true">file_download</mat-icon>{{"TEST.download-csv" | translate }}
          </button>
          <div class="clear-fix"></div>
          <ul *ngIf="showedFailedPhraseExamples">
            <li *ngFor="let ex of showedFailedPhraseExamples"
              [style.display]="!newFailuresOnly || ex.lastSucceeded ? '':'none'">
              <span class="ti-label__select-all">{{ex.snippet}}</span>&nbsp;
              <span *ngIf="ex.failStageDisplay && ex.positive" class="ti-label__fail-stage"
                matTooltip="{{ex.failReason}}">
                ({{ex.failStageDisplay | translate}})</span>
              <span *ngIf="!ex.positive" matTooltip="{{'TEST.negative-example-fail' | translate}}">&nbsp;➖</span>&nbsp;
              &nbsp;<a [href]="'/syntax-and-context/phrasal-patterns?key=id&value=' + ex.family" target="_target">[<span
                  matTooltip="{{ex.familyDescription}}">{{ex.family}}</span> / <span
                  matTooltip="{{ex.description}}">{{ex.id}}</span>]</a>
              <span *ngIf="ex.lastSucceeded" style="font-size: smaller;">&nbsp;({{"TEST.last-worked" |
                translate}}&nbsp;{{ex.lastSucceeded}})
              </span>
            </li>
          </ul>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </mat-expansion-panel>


  <mat-expansion-panel (afterExpand)="getStats('throughput')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        🏃‍♂️&nbsp;{{'TEST.throughput' | translate}}
      </mat-panel-title>
      <mat-panel-description>
        {{'TEST.throughput-description' | translate}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container matExpansionPanelContent>
      <!-- <div echarts [options]="wpsOptions" [loading]="loading()" (chartInit)="wpsChart = $event" theme="macarons"></div> -->
      <div echarts [options]="cpsOptions" [loading]="isLoading.throughput" (chartInit)="cpsChart = $event"
        theme="macarons">
      </div>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #sampleErrors let-type="type" let-date="date" let-textlabel="textlabel">
  <ng-container *ngIf="date && type && date &gt; 0 && getMetric(date, type)">
    <button class="ti-button__download-csv" mat-flat-button color="accent" (click)="downloadAccuracyInCSV(date, type)">
      <mat-icon aria-hidden="true">file_download</mat-icon>{{"TEST.download-csv" | translate }}
    </button>
    <div class="clear-fix"></div>
    <div class="ti-form__one-line-fields">
      &nbsp;
      <ul>
        <li *ngFor="let missing of getMetric(date, type).missingSample" title="{{'TEST.missing' | translate}}">
          ➖&nbsp;<a [style.color]="'red'" [style.text-decoration]="'line-through'"
            [href]="'/corpora?key=id&value=' + missing.testFragmentId" target="_target">{{resultText(missing.fragment,
            missing, textlabel, 200)}}</a>
          <span *ngIf="missing.label">&nbsp;({{missing.label}})</span>
          <span *ngIf="missing.fragment">&nbsp;<strong>{{"TEST.from" | translate }}</strong> {{missing.fragment}}</span>
          <span *ngIf="missing.translation">&nbsp;<strong>({{sessionService.session.referenceLanguage ?
              sessionService.session.referenceLanguage.ISOCode : ''}})</strong>
            {{missing.translation}}</span>
        </li>
      </ul>
      <ul>
        <li *ngFor="let incorrect of getMetric(date, type).incorrectSample">
          &nbsp;<a matTooltip="{{'TEST.result' | translate}}"
            [style.text-decoration]="incorrect.mustBe ? '' : 'line-through'" [style.color]="'red'"
            [href]="'/corpora?key=id&value=' + incorrect.testFragmentId"
            target="_target">{{resultText(incorrect.fragment, incorrect, textlabel, 200)}}
            <span *ngIf="incorrect.label">&nbsp;({{incorrect.label}})</span>
          </a>
          <span *ngIf="incorrect.mustBe" matTooltip="{{'TEST.standard' | translate}}" [style.color]="'green'">&nbsp;↔️
            {{resultText(incorrect.fragment, incorrect.mustBe, textlabel, 200)}}
            <span *ngIf="incorrect.mustBe.label">&nbsp;({{incorrect.mustBe.label}})</span>
          </span>
          <span *ngIf="incorrect.fragment">&nbsp;<strong>{{"TEST.from" | translate }}</strong>
            {{incorrect.fragment}}</span>
          <span *ngIf="incorrect.translation">&nbsp;<strong>({{sessionService.session.referenceLanguage ?
              sessionService.session.referenceLanguage.ISOCode : ''}})</strong>
            {{incorrect.translation}}</span>
          <span *ngIf="!incorrect.mustBe" matTooltip="{{'TEST.must-not-be-extracted' | translate}}">&nbsp;✖</span>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-template>

<ng-template #showdiff let-difflist="difflist" let-emojitype="emojitype" let-options="options" let-textlabel="textlabel"
  let-from="fromDate" let-to="toDate" let-availabledates="availableDates">
  <div class="ti-form__one-line-fields" *ngIf="availabledates">
    &nbsp;
    <button mat-raised-button color="accent" [disabled]="!from || !to || from == lastFrom && to == lastTo"
      (click)="generateDiff(from, to, emojitype)">{{ 'TEST.compare-dates' | translate}}
    </button>
    <mat-accordion *ngIf="difflist" multi="true">
      <mat-expansion-panel *ngFor="let b of difflist.better">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ...<a [style.color]="'green'" [href]="'/corpora?key=id&value=' + b.testFragmentId"
              title="{{'TEST.better' | translate}}" target="_target">{{resultText(b.fragment, b, textlabel)}}</a>...
            &nbsp;{{getEmoji(emojitype, b.label)}}
          </mat-panel-title>
          <mat-panel-description matTooltip="{{b.fragment}}">
            {{b.fragment}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container matExpansionPanelContent>
          <h4>{{'TEST.relevant-edits' | translate}}</h4>
          <ul *ngIf="b.changes">
            <li *ngFor="let ch of b.changes">
              <b
                matTooltip="{{ch.verb}}">{{verbEmoji(ch.verb)}}</b>&nbsp;<b>{{ch.id}}</b>&nbsp;{{ch.description}}&nbsp;({{ch.table}})&nbsp;[{{ch.user}}]
              <a *ngIf="ch.url" [href]="ch.url" target="_blank">{{'COMMON.go-to-source' | translate}}</a>
            </li>
          </ul>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel *ngFor="let w of difflist.worse">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ...<a title="{{(w.standard ? 'TEST.no-longer-extracted' : 'TEST.must-not-be-extracted') | translate}}"
              [style.text-decoration]="w.standard ? '' : 'line-through'" [style.color]="'red'"
              [href]="'/corpora?key=id&value=' + w.testFragmentId" target="_target">{{resultText(w.fragment, w,
              textlabel)}}</a>...
            &nbsp;{{getEmoji(emojitype, w.label)}}
          </mat-panel-title>
          <mat-panel-description matTooltip="{{w.fragment}}">
            {{w.fragment}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container matExpansionPanelContent>
          <h4>{{'TEST.relevant-edits' | translate}}</h4>
          <ul *ngIf="w.changes">
            <li *ngFor="let ch of w.changes">
              <b
                matTooltip="{{ch.verb}}">{{verbEmoji(ch.verb)}}</b>&nbsp;<b>{{ch.id}}</b>&nbsp;{{ch.description}}&nbsp;({{ch.table}})&nbsp;[{{ch.user}}]
              <a *ngIf="ch.url" [href]="ch.url" target="_blank">{{'COMMON.go-to-source' | translate}}</a>
            </li>
          </ul>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-template>