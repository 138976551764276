import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';

import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';

import {FeatureListDefinition, FeatureValue} from '../../../shared/models/feature';

@Component({
  selector: 'app-universal-feature-selector-ids',
  templateUrl: './universal-feature-selector-ids.component.html',
  styleUrls: ['./universal-feature-selector-ids.component.less']
})

export class UniversalFeatureSelectorIdsComponent implements OnInit, OnDestroy {
  @Input() relevantFeatureList: FeatureListDefinition[];
  @Input() featureValue: FeatureValue;
  @Input() doesTheWholeFeatureListRelevant: boolean;
  @Input() setDefaultValues: boolean;
  @Input() isEmptyValueRelevant: boolean;
  @Input() disableSelect: boolean;
  @Input() emitChooseCurrentFeature: Subject<boolean>;

  public currentFeatureIndex: number;
  public previousFeatureIndex: number;
  public isFullFeatureListShowed: boolean;

  public textFeatureValue: string;

  private chooseCurrentFeatureSubscription: Subscription;

  @Output() featureChosenIndexEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
    this.initialize();
  }

  private initialize(): void {
    this.currentFeatureIndex = this.featureValue.index;
    this.previousFeatureIndex = this.featureValue.index;

    if (this.emitChooseCurrentFeature) {
      this.chooseCurrentFeatureSubscription = this.emitChooseCurrentFeature
      .subscribe(result => {
        this.previousFeatureIndex = undefined;
        this.currentFeatureIndex = undefined;
        this.chooseCurrentFeature();
      });
    }

    this.chooseCurrentFeature();

    if (this.disableSelect) {
      this.getTextFeatureValue();
    }
  }

  public toggleFeatureList(): void {
    this.isFullFeatureListShowed = !this.isFullFeatureListShowed;
  }

  public chooseCurrentFeature($event?): void {
    if ($event) {
      this.previousFeatureIndex = $event;
    } else {
      if (this.previousFeatureIndex) {
        this.currentFeatureIndex = this.previousFeatureIndex;
      }
    }
    if (this.setDefaultValues && !this.currentFeatureIndex) {
      let currentFeature: FeatureListDefinition;

      if (this.doesTheWholeFeatureListRelevant || this.isFullFeatureListShowed) {
        currentFeature = this.relevantFeatureList[0];
      } else {
        currentFeature = this.relevantFeatureList.find(element => (element.relevant || element.relevantPartially));
      }
      this.currentFeatureIndex = currentFeature.id;
    }
    if (this.currentFeatureIndex) {
      this.featureChosenIndexEvent.emit(this.currentFeatureIndex);
    }
  }

  public getTextFeatureValue(): void {
    this.textFeatureValue = this.relevantFeatureList.find(feature => feature.index === this.featureValue.index).description;
  }

  ngOnDestroy() {
    if (this.emitChooseCurrentFeature) {
      this.chooseCurrentFeatureSubscription.unsubscribe();
    }
  }
}
