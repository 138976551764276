<div class="ti-language-settings__wrapper">
  <form class="ti-language-settings__form" *ngIf="languageDescriptionForm" [formGroup]="languageDescriptionForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <p class="ti-language-settings_title">ID: <span
      class="ti-language-settings--bold-text">{{languageDescription.id}}</span></p>
    <div class="ti-language-settings__one-line-fields ti-language-settings__with-checkbox">
      <div class="ti-language-settings__two-column-fields">
        <app-custom-tooltip
          [link]="{text:'IETF language tags', href: 'https://datahub.io/core/language-codes '}"
          [mouseEnterOnElementObservable]="mouseEnterOnElementObservable"
          [mouseLeaveFromElementObservable]="mouseLeaveFromElementObservable"
        ></app-custom-tooltip>
        <mat-form-field color="accent"><input
          (mouseenter)="mouseEnterOnElement() " (mouseleave)="mouseLeaveFromElement()"
          matInput
          (change)="clearTheErrorMessage()"
          placeholder="IETF Language Tag"
          [(ngModel)]="languageDescription.ietf"
          formControlName="ietf"
        /></mat-form-field>
      </div>
      <div class="ti-language-settings__checkbox-wrapper ti-language-settings__two-column-fields">
        <mat-checkbox
          matInput
          (change)="clearTheErrorMessage()"
          name="systemLanguage"
          disabled="true"
          formControlName="systemLanguage"
          [(ngModel)]="languageDescription.systemLanguage">
          <span class="ti-language-settings__label">System reference language</span>
        </mat-checkbox>
      </div>
    </div>

    <mat-form-field color="accent"><input
      matInput (change)="clearTheErrorMessage()"
      placeholder="Native name"
      formControlName="name"
      style="line-height: 150%;"
      [(ngModel)]="languageDescription.name"/>
    </mat-form-field>
    <mat-form-field color="accent">
      <input
        matInput
        (change)="clearTheErrorMessage()"
        placeholder="English name"
        formControlName="englishName"
        [(ngModel)]="languageDescription.englishName"/></mat-form-field>
    <p class="ti-language-settings__label">Word segmentation:</p>
    <mat-radio-group [(ngModel)]="languageDescription.segmentation"
                     formControlName="segmentation">
      <mat-radio-button class="ti-language-settings__radio-button" *ngFor="let option of languageSegmentationOptions"
                        value="{{option.key}}">
        <p class="ti-language-settings__radio-label">{{option.value}}</p>
      </mat-radio-button>
    </mat-radio-group>

    <mat-checkbox class="ti-language-settings__checkbox-wrapper" matInput (change)="clearTheErrorMessage()" name="allowGenerativeAI" formControlName="allowGenerativeAI"
      [(ngModel)]="languageDescription.allowGenerativeAI">
      <span class="ti-language-settings__label">Allow generative AI input</span>
    </mat-checkbox>

    <div class="ti-language-settings__one-line-fields" *ngIf="doesSegmentationEqualToTheDecompoundingValue()">
      <span class="ti-language-settings__label ti-language-settings__compounds">Head word in compounds:</span>
      <app-universal-features *ngIf="featureValue && relevantFeatureList"
                              [featureValue]="featureValue"
                              [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
                              [isOnlyOneFeature]="isOnlyOneFeature"
                              [isEmptyValueRelevant]="true"
                              [relevantFeatureList]="relevantFeatureList"
                              (featuresUpdatedValueEvent)="saveUpdatedFeatures($event)"
      ></app-universal-features>
    </div>

    <div class="ti-language-settings__checkbox-wrapper">
      <mat-checkbox
        matInput
        (change)="clearTheErrorMessage()"
        name="isHyphenationMWEMarker"
        formControlName="isHyphenationMWEMarker"
        [(ngModel)]="languageDescription.isHyphenationMWEMarker">
        <span class="ti-language-settings__label">Hyphens mark multiword expressions</span>
      </mat-checkbox>
    </div>
    <div class="ti-language-settings__checkbox-wrapper">
      <mat-checkbox matInput (change)="clearTheErrorMessage()" name="complementPhraseFallback"
        formControlName="complementPhraseFallback" [(ngModel)]="languageDescription.complementPhraseFallback">
        <span class="ti-language-settings__label">Add all phrases from fallback language</span>
      </mat-checkbox>
    </div>
    <div class="ti-language-settings__checkbox-wrapper">
      <mat-checkbox matInput (change)="clearTheErrorMessage()" name="nonproperFallbackNativeInflection"
        formControlName="nonproperFallbackNativeInflection"
        [(ngModel)]="languageDescription.nonproperFallbackNativeInflection">
        <span class="ti-language-settings__label">Add all non-proper noun lexemes from fallback language with native
          inflection (Creole languages)</span>
      </mat-checkbox>
    </div>
    <div class="ti-language-settings__checkbox-wrapper">
      <mat-checkbox matInput (change)="clearTheErrorMessage()" name="properFallbackNativeInflection"
        formControlName="properFallbackNativeInflection" [(ngModel)]="languageDescription.properFallbackNativeInflection">
        <span class="ti-language-settings__label">Add all proper nouns from fallback language with native inflection</span>
      </mat-checkbox>
    </div>
    <div class="ti-language-settings__checkbox-wrapper">
      <mat-checkbox matInput (change)="clearTheErrorMessage()" name="alwaysAutogenMisspellings"
        formControlName="alwaysAutogenMisspellings" [(ngModel)]="languageDescription.alwaysAutogenMisspellings">
        <span class="ti-language-settings__label">Always generate misspellings, even when existing forms found</span>
      </mat-checkbox>
    </div>
    <div class="ti-language-settings__one-line-fields ti-language-settings__thin-inputs">
      <div class="ti-language-settings__two-column-fields">
        <mat-form-field color="accent"><input
          matInput
          (change)="clearTheErrorMessage()"
          placeholder="Native encoding"
          formControlName="encoding"
          [(ngModel)]="languageDescription.encoding"/></mat-form-field>
      </div>
      <div class="ti-language-settings__two-column-fields">
        <mat-form-field color="accent"><input
          matInput
          (change)="clearTheErrorMessage()"
          placeholder="Windows charset"
          formControlName="charset"
          [(ngModel)]="languageDescription.charset"/></mat-form-field>
      </div>
    </div>
    <mat-form-field color="accent"><input matInput (change)="clearTheErrorMessage()" placeholder="Corpus Url"
        formControlName="corpusUrl" [(ngModel)]="languageDescription.corpusUrl" />
    </mat-form-field>
    <mat-form-field color="accent"><input
      matInput
      (change)="clearTheErrorMessage()"
      placeholder="Inflected forms validation regex"
      formControlName="inflectionSanityCheckRegex"
      [(ngModel)]="languageDescription.inflectionSanityCheckRegex"/>
    </mat-form-field>
    <mat-form-field color="accent"><input
      matInput
      (change)="clearTheErrorMessage()"
      placeholder="Preferred font"
      formControlName="font" [(ngModel)]="languageDescription.font"/>
    </mat-form-field>
    <mat-form-field color="accent">
      <input
        matInput
        (change)="clearTheErrorMessage()"
        placeholder="Knowledge graph translation reference URL template, e.g. https://en.wikipedia.org/wiki/{0}"
        formControlName="translationReferenceURL"
        [(ngModel)]="languageDescription.translationReferenceURL"/></mat-form-field>
      <mat-form-field color="accent">
      <input
        matInput
        (change)="clearTheErrorMessage()"
        placeholder="Lexicon translation reference URL template, e.g. https://en.wiktionary.org/wiki/{0}"
        formControlName="lexemeReferenceURL"
        [(ngModel)]="languageDescription.lexemeReferenceURL"/></mat-form-field>
    <div class="ti-language-settings__when-not-found ti-language-settings__features ti-form__bordered-part">
      <span class="ti-language-settings__label ti-form-bordered-part__label">When not found, search in other language models</span>

      <div class="ti-language-settings__one-line-fields ti-language-settings__when-not-found-part">
        <div class="ti-language-settings__column-fields ti-language-settings__closer-column-fields">
          <app-universal-language-select
            description="Words (primary)"
            [initialLanguageId]="languageDescription.wordFallback1"
            (selectedLanguageChanged)="updateWordsField($event)"
          ></app-universal-language-select>
          <app-universal-language-select
            description="Proper nouns"
            [initialLanguageId]="languageDescription.properNounFallback"
            (selectedLanguageChanged)="updateProperNounsField($event)"
          ></app-universal-language-select>
          <app-universal-language-select
            description="Phrasal patterns"
            [initialLanguageId]="languageDescription.phraseFallback"
            (selectedLanguageChanged)="updatePhrasalPatternsField($event)"
          ></app-universal-language-select>
        </div>
        <div class="ti-language-settings__column-fields">
          <app-universal-language-select
            description="Emoji"
            [initialLanguageId]="languageDescription.emojiFallback"
            (selectedLanguageChanged)="updateEmojiFallback($event)"
          ></app-universal-language-select>
          <app-universal-language-select
            description="Words (secondary)"
            [initialLanguageId]="languageDescription.wordFallback2"
            (selectedLanguageChanged)="updateThenField($event)"></app-universal-language-select>
        </div>
      </div>
    </div>
    <p><span class="ti-language-settings__label">Maximum number of characters from the start of a non-break to the punctuation mark:</span>
      <mat-form-field color="accent"><input
        matInput
        (change)="clearTheErrorMessage()"
        formControlName="maximumPunctuationNonbreakPrefix"
        [(ngModel)]="languageDescription.maximumPunctuationNonbreakPrefix"/>
      </mat-form-field>
    </p>
    <div class="ti-language-settings__checkbox-wrapper">
      <mat-checkbox
        matInput
        (change)="clearTheErrorMessage()"
        name="doProperNounsInflect"
        formControlName="doProperNounsInflect"
        [(ngModel)]="languageDescription.doProperNounsInflect">
        <span class="ti-language-settings__label">Proper nouns inflect</span>
      </mat-checkbox>
    </div>
    <!-- <div class="ti-language-settings__one-line-fields ti-language-settings__label_and_feature">
      <span class="ti-language-settings__label ti-language-settings__compounds">Compound heads require this feature</span>
      <app-universal-features *ngIf="grammarFeaturesList" [featureValue]="languageDescription.compoundHead"
        [relevantFeatureList]="grammarFeaturesList" [doesTheWholeFeatureListRelevant]="true" [setDefaultValues]="true"
        [isOnlyOneFeature]="true"
        (featuresUpdatedValueEvent)="languageDescription.compoundHead = $event"></app-universal-features>
    </div> -->
    <div class="ti-language-settings__one-line-fields ti-language-settings__label_and_feature">
      <span class="ti-language-settings__label ti-language-settings__compounds">Automatically generate phrases without
        commas</span>
      <app-universal-features *ngIf="languageDescription.commalessPhraseAutogen && styleFeaturesList"
        [featureValue]="languageDescription.commalessPhraseAutogen"
        [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant" [isOnlyOneFeature]="isOnlyOneFeature"
        [isEmptyValueRelevant]="true" [relevantFeatureList]="styleFeaturesList"
        (featuresUpdatedValueEvent)="languageDescription.commalessPhraseAutogen = setFeatureTypeForList('Style', $event)"
        (clearSelectionEvent)="languageDescription.commalessPhraseAutogen = {}">
      </app-universal-features>
    </div>
    
    <div class="ti-form__bordered-part ti-language-settings__features">
      <span class="ti-language-settings__label ti-form-bordered-part__label">Automatically generate pro-drop main
        clauses</span>
      <div class="ti-form-bordered-part__content ti-language-settings__features-wrapper" style="display: block">
        <!-- Require -->
        <div class="ti-language-settings__one-line-fields">
          <span class="ti-language-settings__label ti-language-settings__compounds">Require</span>
          <app-universal-features *ngIf="languageDescription.agentlessPhraseAutogenRequired && semanticFeaturesList"
            [featureValue]="languageDescription.agentlessPhraseAutogenRequired"
            [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant" [isOnlyOneFeature]="isOnlyOneFeature"
            [isEmptyValueRelevant]="true" [relevantFeatureList]="semanticFeaturesList"
            (clearSelectionEvent)="languageDescription.agentlessPhraseAutogenRequired = {}"
            (featuresUpdatedValueEvent)="languageDescription.agentlessPhraseAutogenRequired = setFeatureTypeForList('Semantics', $event)"></app-universal-features>
        </div>
    
        <!-- Assign -->
        <div class="ti-language-settings__one-line-fields">
          <span class="ti-language-settings__label ti-language-settings__compounds">Assign</span>
          <app-universal-features *ngIf="languageDescription.agentlessPhraseAutogen && grammarFeaturesList"
            [featureValue]="languageDescription.agentlessPhraseAutogen"
            [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant" [isOnlyOneFeature]="isOnlyOneFeature"
            [isEmptyValueRelevant]="true" [relevantFeatureList]="grammarFeaturesList"
            (clearSelectionEvent)="languageDescription.agentlessPhraseAutogen = {}"
            (featuresUpdatedValueEvent)="languageDescription.agentlessPhraseAutogen = $event"></app-universal-features>
        </div>
      </div>
    </div>
    
    <mat-form-field color="accent" class="ti-language-settings__custom-collation">
      <input matInput (change)="clearTheErrorMessage()" placeholder="Custom collation" formControlName="customCollation"
        [(ngModel)]="languageDescription.customCollation" />
    </mat-form-field>

    <div class="ti-form__bordered-part ti-language-settings__features">
      <span class="ti-language-settings__label ti-form-bordered-part__label">Language features</span>
      <div class="ti-form-bordered-part__content ti-language-settings__features-wrapper"  style="display: flex">
        <ng-container *ngIf="semanticFeaturesValuesList">
          <app-universal-features
            [featureValues]="semanticFeaturesValuesList"
            [relevantFeatureList]="semanticFeaturesList"
            [doesTheWholeFeatureListRelevant]="true"
            [preselectedIndex]="semanticFeatureIndex"
            [setDefaultValues]="true"
            (featuresUpdatedValueEvent)="updateCurrentSemanticFeatureValue($event)"
            ></app-universal-features>
          </ng-container>
      </div>
    </div>

    <div class="ti-language-settings--button-panel">
      <button mat-raised-button
              (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>

      <button
        mat-raised-button
        color="accent"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
