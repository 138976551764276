import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OverlayModule} from '@angular/cdk/overlay';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatBadgeModule} from '@angular/material';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTreeModule, MatTreeNode} from '@angular/material/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    MatToolbarModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatExpansionModule,
    OverlayModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTreeModule,
    MatBadgeModule,
    ScrollingModule
  ],
  exports: [
    CommonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatExpansionModule,
    OverlayModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTreeModule,
    MatBadgeModule,
    ScrollingModule
  ]
})
export class AppMaterialDependenciesModule {
}
