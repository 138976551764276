import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../../shared/_module/shared.module';

import {UniversalFeaturesComponent} from './universal-features/universal-features.component';
import {UniversalFeatureSelectorOptionsComponent} from './universal-feature-selector-options/universal-feature-selector-options.component';
import {UniversalFeatureSelectorIdsComponent} from './universal-feature-selector-ids/universal-feature-selector-ids.component';
import {UniversalFeatureComponent} from './universal-feature/universal-feature.component';
import {FeatureIdToFeatureIdRelationComponent} from './feature-id-to-feature-id-relation/feature-id-to-feature-id-relation.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [UniversalFeaturesComponent,
    UniversalFeatureSelectorOptionsComponent,
    UniversalFeatureSelectorIdsComponent,
    UniversalFeatureComponent,
    FeatureIdToFeatureIdRelationComponent],
  exports: [UniversalFeaturesComponent,
    UniversalFeatureSelectorOptionsComponent,
    UniversalFeatureSelectorIdsComponent,
    UniversalFeatureComponent,
    FeatureIdToFeatureIdRelationComponent]
})
export class FeaturesModule {
}
