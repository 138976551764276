import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import {PunctuationSign, PunctuationSignData} from '../models/punctuation-sign';
import {ApiClientService} from './api-client.service';
import {SessionService} from './session.service';
import {LampUpdateResponse} from '../models/common';

@Injectable()
export class PunctuationService {
  public punctuationList: PunctuationSign[];

  constructor(private apiClientService: ApiClientService,
              private sessionService: SessionService) {
  }

  public getPunctuationList(): Promise<Array<PunctuationSign | undefined>> {
    return this.apiClientService
      .get(`${EndpointUrls.punctuation.punctuationList}`)
      .then((punctuationList) => {
        this.punctuationList = (punctuationList as Array<PunctuationSign>);
        return this.punctuationList;
      });
  }

  public getPunctuationSignData(punctuationSignId: number): Promise<PunctuationSignData | undefined> {
    return this.apiClientService
      .get(`${EndpointUrls.punctuation.punctuation}?id=${punctuationSignId}`)
      .then((punctuationSignData) => {
        return (punctuationSignData as PunctuationSignData);
      });
  }

  public updatePunctuationSignData(punctuationSignData: PunctuationSignData ): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.punctuation.punctuation, punctuationSignData)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public createPunctuationSignData(punctuationSignData: PunctuationSignData): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.punctuation.punctuation, punctuationSignData)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public deletePunctuation(id: number): Promise<any> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.punctuation.punctuation}?id=${id}`)
      .then(() => {
      // TODO: fix this method
        return this.getPunctuationList().then(data => {
          return data;
        });
      });
  }
}
