export function getPathWithoutQueryAndHashParameters(path: string): string {
  return path.split('#')[0].split('?')[0];
}

export function getHashFromPath(path: string): string {
  return (path.split('#')[1]);
}

export function getQueryParameters(path: string): object {
  const queryParams = {};
  const queryParamsInString = path.split('?')[1];

  if (queryParamsInString) {
    queryParamsInString.replace(
      new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
        queryParams[$1] = $3;
        return '';
      });
  }
  return queryParams;
}


export function isFirstUrlChildOfTheSecond(firstUrl: string, secondUrl: string): boolean {
  let isFirstUrlChildOfTheSecond: boolean = false;
  if (firstUrl && secondUrl) {
    firstUrl = getPathWithoutQueryAndHashParameters(firstUrl);
    secondUrl = getPathWithoutQueryAndHashParameters(secondUrl);

    if (secondUrl.length < firstUrl.length) {
      const regExp = new RegExp(getPathWithoutQueryAndHashParameters(secondUrl));
      isFirstUrlChildOfTheSecond = regExp.test(getPathWithoutQueryAndHashParameters(firstUrl));
    }

  }
  return isFirstUrlChildOfTheSecond;
}
