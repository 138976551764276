<div class="ti-punctuation-edit--edit-form-wrapper" *ngIf="punctuationSignId">
  <h1>{{ (isTheNewSign ? 'punctuation.punctuation-creation' : 'punctuation.punctuation-edit') | translate}}</h1>

  <form class="ti-punctuation-edit--edit-form" [formGroup]="punctuationEditForm">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
    <mat-progress-bar *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-bar>
    <mat-form-field color="accent">
      <input
        matInput
        required
        class="ti-punctuation-edit--punctuation-text"
        placeholder="{{ 'punctuation.punctuation-mark' | translate}}"
        [(ngModel)]="punctuationSignData.text"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        formControlName="text"/>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select
        matInput
        placeholder="{{ 'COMMON.type' | translate}}"
        role="listbox"
        formControlName="type"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="punctuationSignData.type">
        <mat-option value="">-- {{'COMMON.none' | translate }} --</mat-option>
        <mat-option role="option" *ngFor="let role of punctuationRoles" [value]="role">
          <!--https://github.com/ngx-translate/core/issues/580-->
          <span translate>{{'punctuation_roles_'+role | translate}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select
        matInput
        placeholder="{{ 'punctuation.spacing-policy' | translate}}"
        role="listbox"
        formControlName="space"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        [(ngModel)]="punctuationSignData.space">
        <mat-option role="option" *ngFor="let spaceType of punctuationSpaces" [value]="spaceType">
          <!--https://github.com/ngx-translate/core/issues/580-->
          <span translate>{{'punctuation_spaces_'+spaceType | translate}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select
        role="listbox"
        formControlName="fallbackId"
        placeholder="{{ 'punctuation.fall-back-to' | translate}}"
        [(ngModel)]="punctuationSignData.fallbackId"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
      >
        <ng-template ngFor let-punctuationSign [ngForOf]="punctuationSignList">
          <mat-option role="option" *ngIf="punctuationSign.text" [value]="punctuationSign.id">
            <span>{{punctuationSign.text}}</span>
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      matInput
      formControlName="capitalizeNext"
      [(ngModel)]="punctuationSignData.capitalizeNext"
      appDisableControl
      [disableControl]="!doesUserHaveAccessToEdit"
    >
      {{ 'punctuation.capitalize-next-word' | translate}}
    </mat-checkbox>

    <mat-form-field>
      <textarea
        matInput
        formControlName="note"
        [(ngModel)]="punctuationSignData.note"
        appDisableControl
        [disableControl]="!doesUserHaveAccessToEdit"
        placeholder="{{ 'COMMON.note' | translate}}"></textarea>
    </mat-form-field>

    <p class="ti-punctuation-edit--link">
      <button
        class="ti-link-underline-text-decoration ti-link-button ti-blue-link"
        [disabled]="isTheNewSign"
        matTooltip="{{'punctuation.to-create-a-non-break-rule-the-punctuation-first' | translate}}"
        [matTooltipDisabled]="!isTheNewSign"
        appOpenNonBreaksEditDialog
        [punctuationSignId]="punctuationSignId"
        [doesUserHaveAccessToEdit]="doesUserHaveAccessToEdit"
        (beforeDialogClosedEvent)="updatePunctuationList()"
      >{{nonBreakCount}}
        {{ 'non-break.non-break-rules' | translate}}
      </button>
    </p>
    <div class="ti-punctuation-edit--button-panel">
      <button mat-raised-button (click)="cancel()">{{'COMMON.cancel' | translate}}
      </button>

      <button
        mat-raised-button
        color="accent"
        [disabled]="!doesUserHaveAccessToEdit"
        (click)="save()">{{'COMMON.save' | translate}}
      </button>
    </div>
  </form>
</div>
