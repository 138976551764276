import {Component, OnDestroy, OnInit, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';

import {Subscription} from 'rxjs/Subscription';

import {RouterHelper} from '../../shared/helpers/router.helper';
import {SessionService} from '../../shared/services/session.service';
import {LinguistService} from '../../shared/services/linguist.service';

import {StatisticLevel} from '../../shared/constants/statistic-level.enum';
import { MatDialog } from '@angular/material';
import { StatusNoteComponent } from '../../administration/status-note/status-note.component';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.less']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {

  @Output() toggleMobileMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public languageModel: string;
  public username: string;
  public edits: number;
  public statisticLevel: StatisticLevel;
  public canEditCurrentLanguage: boolean;
  public isGuest: boolean;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener;
  public isMobileView: boolean;

  private sessionServiceSubscription: Subscription;
  private linguistServiceSubscription: Subscription;

  constructor(private sessionService: SessionService,
              private routerHelper: RouterHelper,
              private linguistService: LinguistService,
              private changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private dialog: MatDialog) {

    this.mobileQuery = media.matchMedia('(max-width: 100vh)');
    this.mobileQueryListener = (data) => {
      changeDetectorRef.detectChanges();
      this.detectIsMobileView();
    };
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  public refreshLanguageModel() {
      this.sessionService.refresh();
  }

  ngOnInit() {
    this.detectIsMobileView();
    let self = this;
    this.sessionServiceSubscription = this.sessionService.currentSessionSource.subscribe((session) => {
      self.canEditCurrentLanguage = session.canEditCurrentLanguage;
      self.languageModel = session.targetLanguage.name;
      self.username = self.sessionService.username();
      self.isGuest = session.guest;
    });

    this.linguistServiceSubscription = this.linguistService.currentEditsQuantitySource.subscribe((currentEditsQuantity) => {
      this.edits = currentEditsQuantity;
      this.statisticLevel = (this.edits > 105) ? StatisticLevel.good : ((this.edits > 75) ? StatisticLevel.average : StatisticLevel.bad);
    });

    this.linguistService.getStatus().then((status) => {
      if (!status) {
        this.openStatusDialog();
      }
    });

    window.addEventListener('storage', (e) => {
      if (e.key == 'currentLanguage' && e.newValue)
        return this.refreshLanguageModel();
      })
  }

  openStatusDialog() {
    this.dialog.open(StatusNoteComponent, {
      minWidth: '70%',
      position: {
        top: '50px'
      },
      closeOnNavigation: false,
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    this.sessionServiceSubscription.unsubscribe();
    this.linguistServiceSubscription.unsubscribe();
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  public enabled(): boolean {
    return this.sessionService && this.sessionService.isAuthenticated();
  }

  private detectIsMobileView(): void {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  public logout() {
    this.sessionService.logout();
    this.routerHelper.navigateToLoginPage();
  }

  public toggleMobileMenu(): void {
    this.toggleMobileMenuEvent.emit(true);
  }

}
