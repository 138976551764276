<app-header *ngIf="isAuthenticated()" (openMenuEvent)="onOpenMenuEvent($event)"></app-header>
<mat-sidenav-container
  [style.height]="mobileQuery.matches ? 'calc(100% - 280px)' : ''"
  [style.margin-top.px]="isMobileView ? 56 : 0">
  <mat-sidenav
    *ngIf="isAuthenticated()"
    [(opened)]="openedMenu"
    role="navigation"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches" [fixedTopGap]="mobileQuery.matches ? 56 : 0">
    <app-sidebar-menu></app-sidebar-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
