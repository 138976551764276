import { Component } from '@angular/core';

@Component({
  selector: 'app-changes-have-been-saved-dialog',
  templateUrl: './changes-have-been-saved-dialog.component.html',
  styleUrls: ['./changes-have-been-saved-dialog.component.less']
})
export class ChangesHaveBeenSavedDialogComponent {
  constructor() { }
}
