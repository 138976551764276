import {Component, OnInit, OnDestroy, Input, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {NonBreaksService} from '../../../shared/services/nonbreaks.service';
import {NonBreak, emptyNonBreak, NonDictionary} from '../../../shared/models/non-break';
import {SessionService} from '../../../shared/services/session.service';

import {createFlatArrayCopy, toJsonObject} from '../../../shared/helpers/object.helper';
import {LampUpdateResponse} from '../../../shared/models/common';
import { Translated } from '../../../shared/classes/translated.class';
import { LocalStorageHelper } from '../../../shared/helpers/localhost.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-non-breaks-table-for-the-mark',
  templateUrl: './non-breaks-table-for-the-mark.component.html',
  styleUrls: ['./non-breaks-table-for-the-mark.component.less']
})
export class NonBreaksTableForTheMarkComponent extends Translated implements OnInit, OnDestroy {
  @Input() public punctuationSignId: number;
  @Input() private addNewEntryObservable: Observable<boolean>;

  @Output() addNewElementEvent: EventEmitter<any> = new EventEmitter();

  public nonBreaks: NonBreak[];
  public initialNonBreaks: NonBreak[];
  public displayedColumns: string[];
  public nonDictionaryList: NonDictionary[];
  public editedNonBreak: NonBreak = undefined;
  public forAllLanguages: boolean = false;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  private subscription: Subscription;

  constructor(private nonBreaksService: NonBreaksService,
      protected translateService: TranslateService,
              protected sessionService: SessionService,
              protected localStorageHelper: LocalStorageHelper,
              private changeDetectorRef: ChangeDetectorRef) {
    super(translateService, localStorageHelper, sessionService);
    this.displayedColumns = ['regular-expression', 'description', 'non-dictionary-pattern', 'for-all-languages'];
  }

  ngOnInit(): void {
    this.getNonBreakRules();
    this.subscription = this.addNewEntryObservable.subscribe((shouldAddEntry) => {
      if (shouldAddEntry) {
        this.addNewRowInTheTable();
      }
    });
  }

  initThisSubtypeFromSession(): void {

  }

  private getNonBreakRules(): Promise<boolean> {
    // const getNonBreaksListForCurrentLanguagePromise = this.nonBreaksService.getNonBreaksListForCurrentLanguage()
    //   .then((nonBreaksList: NonBreak[]) => {
    //     if (!this.nonBreaks) {
    //       this.nonBreaks = [];
    //     }
    //     this.nonBreaks = this.nonBreaks.concat(nonBreaksList);
    //     this.initialNonBreaks = createFlatArrayCopy(this.nonBreaks);
    //   });

    const getNonBreaksPromise = this.nonBreaksService.getNonBreaks(this.punctuationSignId)
      .then((nonBreaksList: NonBreak[]) => {
        if (!this.nonBreaks) {
          this.nonBreaks = [];
        }
        this.nonBreaks = this.nonBreaks.concat(nonBreaksList);
        this.initialNonBreaks = createFlatArrayCopy(this.nonBreaks);
      });

    const getNonDictionaryListPromise = this.nonBreaksService.getNonDictionaryList()
      .then((nonDictionary: NonDictionary[]) => {
        this.nonDictionaryList = nonDictionary;
      });

    return Promise.all([
      // getNonBreaksListForCurrentLanguagePromise,
      getNonBreaksPromise,
      getNonDictionaryListPromise]).then(() => {
      return true;
    }, () => {
      return false;
    }).catch(() => {
      return false;
    });
  }

  private addNewRowInTheTable(): void {
    this.revertChanges();

    if (!this.nonBreaks) {
      this.nonBreaks = [];
    }
    this.editedNonBreak = {description: '???', regex: '???', language: this.sessionService.session.targetLanguage.id};

    this.nonBreaks = this.nonBreaks.concat([this.editedNonBreak]);

    this.addNewElementEvent.emit(true);
  }

  public updateNonBreak(element) {
    if (element.id) {
      this.editedNonBreak.language = this.forAllLanguages ? 0 : this.sessionService.session.targetLanguage.id;
      this.nonBreaksService.updateNonBreak(this.editedNonBreak).then((result: LampUpdateResponse) => {
        this.editedNonBreak = undefined;
        if (result.success) {
          this.updateNonBreaksList();
        } else {
          this.showErrorMessage(result.error);
          this.changeDetectorRef.markForCheck();
        }
      });
    } else {
      this.editedNonBreak.punctuationId = this.punctuationSignId;
      this.editedNonBreak.language = this.forAllLanguages ? undefined : this.sessionService.session.targetLanguage.id;
      this.nonBreaksService.createNonBreak(this.editedNonBreak).then((result: LampUpdateResponse) => {
        this.editedNonBreak = undefined;
        if (result.success) {
          this.updateNonBreaksList();
        } else {
          this.showErrorMessage(result.error);
          this.changeDetectorRef.markForCheck();
        }
      });
    }
    this.editedNonBreak = undefined;
  }

  public revertChanges(): void {
    this.editedNonBreak = undefined;

    this.clearTheErrorMessage();

    this.nonBreaks = createFlatArrayCopy(this.initialNonBreaks);

    this.changeDetectorRef.markForCheck();
  }

  private updateNonBreaksList(): void {
    this.nonBreaks = [];
    this.initialNonBreaks = [];
    this.getNonBreakRules().then(() => {
      this.clearTheErrorMessage();
      this.changeDetectorRef.markForCheck();
    });
  }

  public deleteNonBreak(id: number, doesUserConfirm: boolean): Promise<void> {
    if (doesUserConfirm) {
      return this.nonBreaksService.deleteNonBreak(id).then(() => {
        this.updateNonBreaksList();
      });
    }
  }

  public isSelectedForEditing(nb: NonBreak): boolean {
    return !nb.id || this.editedNonBreak && this.editedNonBreak.id === nb.id;
  }

  public isEditingThis(nb: NonBreak): boolean {
    return (nb.language || this.isAdmin()) && this.isSelectedForEditing(nb);
  }

  public edit(id: number) {
    this.revertChanges();
    this.nonBreaksService.getNonBreakData(id).then((nonBreak: NonBreak) => {
      this.forAllLanguages = nonBreak.language ? false : true;
      this.editedNonBreak = nonBreak;
    });
  }

  public getNonDictionaryPattern(heuristicId: number) {
    if (heuristicId && this.nonDictionaryList) {
      const nonDictionaryPattern: NonDictionary = this.nonDictionaryList.find(element => element.id === heuristicId);
      if (!nonDictionaryPattern)
        return '';
      return (nonDictionaryPattern.description + ' ' + nonDictionaryPattern.regex);
    }
    return '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
