import {Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

import {InflectionTableService} from '../shared/services/inflection-table.service';
import {InflectionList, Line} from '../shared/models/inflections';
import {LexiconService} from '../shared/services/lexicon.service';
import {Family, KnowledgeGraphElement} from '../shared/models/knowledge-graph';
import {Lexeme} from '../shared/models/lexicon';
import {LampUpdateResponse} from '../shared/models/common';
import { KnowledgeGraphService } from '../shared/services/knowledge-graph.service';
import { Translated } from '../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageHelper } from '../shared/helpers/localhost.helper';
import { SessionService } from '../shared/services/session.service';

@Component({
  selector: 'app-inflection-table',
  templateUrl: 'inflection-table.component.html',
  styleUrls: ['inflection-table.component.less']
})
export class InflectionTableComponent extends Translated implements OnInit {

  initThisSubtypeFromSession(): void {
    //throw new Error('Method not implemented.');
  }

  public isloading: boolean;
  public lexemeId: number;
  public lexeme: string;
  public inflectionList: InflectionList;
  public displayedList: Line[];
  public failureLemmas: any[] = [];
  public families: Family[];
  public selectedFamily: string;
  public showProgressBar: boolean;
  public testFragmentCount: boolean;
  public externalCount: boolean;
  public verify: boolean;
  public myUsername: string;


  constructor(
    private inflectionTableService: InflectionTableService,
    private knowledgeBaseService: KnowledgeGraphService,
    @Inject(MAT_DIALOG_DATA) private dialogData: {lexeme: string, lexemeId: number},
    private lexiconService: LexiconService,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    private dialogRef: MatDialog
  ) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit() {
    this.lexeme = this.dialogData.lexeme;
    this.lexemeId = this.dialogData.lexemeId;

    this.initTable();

    let self = this;
    this.sessionServiceSubscription = this.sessionService.currentSessionSource.subscribe((session) => {
      self.myUsername = self.sessionService.username();
    });
   }

  public initTable(): void {
      this.families = [];
      this.isloading = true;
    this.inflectionTableService.getInflectionList(this.lexemeId, this.testFragmentCount, this.externalCount, this.verify)
      .then((inflectionList: InflectionList) => {
        this.inflectionList = inflectionList;
        if (inflectionList.auditUnavailable) {
          this.verify = false;
        }
        if (this.inflectionList && this.inflectionList.lines) {
          let familyList: string = '';
          for (let ln of this.inflectionList.lines) {
            if (ln.families)
              for (let fid of ln.families) {
                let results: Family[] = this.families.filter((family: Family) => { return family.id === fid });
                if (!results || results.length === 0) {
                  this.families.push({id: fid});
                  if (familyList)
                    familyList = familyList + ', ' + fid.toString();
                  else
                    familyList = fid.toString();
                }
              }
          }
          if (familyList) {
            this.knowledgeBaseService.getKnowledgeGraph(familyList, 'id', 'true').then(
              (knowledgeGraph: KnowledgeGraphElement[]) => {
                for (let fm of this.families) {
                  for (let kge of knowledgeGraph)
                    if (kge.id === fm.id) {
                      fm.definition = kge.definition;
                      break;
                    }
                }
                this.isloading = false;
              });
              this.selectedFamily = this.families[0].id.toString();
          } else {
            this.isloading = false;
          }
          this.displayedList = inflectionList.lines.slice();
        } else {
          this.displayedList = [];
          this.isloading = false;
        }
        this.filterBySelectedFamily();
      });
  }

  public getStyles(line: Line): {} {
    return {
      'padding-left': '' + (line.indent * 13) + 'px',
      'font-weight': !line.highlight && !line.inflectionId && !line.features || line.lemma ? 'bold' : '',
      'color': this.getColor(line),
      'background-color': this.getBackgroundColor(line),
      'text-decoration': line.misspelled ? 'underline wavy red' : ''
    };
  }

  private getColor(line: Line): string {
    return line.indent < 4 && !line.inflection ? 'rgb(255, 255, 255)' : '';
  }

  private getBackgroundColor(line: Line): string {
    let color: string;

    switch (line.indent) {
      case 1:
        color =  'rgb(127, 127, 127)';
        break;
      case 2:
        color = 'rgb(166, 166, 166)';
        break;
      case 3:
        color = 'rgb(191, 191, 191)';
        break;
      case 4:
        color = 'rgb(217, 217, 217)';
        break;
      default:
        color = 'rgb(255, 255, 255)';
    }

    return line.inflection ? 'rgb(242, 242, 242)' : color;
  }

  public isEditButtonVisible(line: Line): boolean {
    return line.inflection;
  }

  public isRevertButtonVisible(line: Line): boolean {
    return !line.lemma && line.highlight;
  }

  public isVisibilityButtonVisible(line: Line): boolean {
    return line.inflection && !line.highlight;
  }

  public filterBySelectedFamily(): void {
    if (this.inflectionList && this.inflectionList.lines) {
      if (this.selectedFamily) {
        this.displayedList = this.inflectionList.lines.filter((item, index) => {
          let nSelectedFamily: number = Number.parseInt(this.selectedFamily);
          if (item.families) {
            return item.families.includes(nSelectedFamily);
          } else {
            let nextForm: Line;
            let currentIndent: number = item.indent;
            for (let fi = index + 1; fi < this.inflectionList.lines.length; fi++) {
              if (this.inflectionList.lines[fi].families) {
                nextForm = this.inflectionList.lines[fi++];
                let formIndent: number = nextForm.indent;
                while (fi < this.inflectionList.lines.length && nextForm && (nextForm.indent > currentIndent // === formIndent 
                    && !nextForm.families && currentIndent < formIndent
                    || nextForm.families && !nextForm.families.includes(nSelectedFamily))) {
                  nextForm = this.inflectionList.lines[fi++];
                }
                break;
              }
              if (currentIndent >= this.inflectionList.lines[fi].indent)
                break;
            }
            return nextForm && nextForm.families && nextForm.families.includes(nSelectedFamily);
          }
        });
        
        /**
         * @DEPRECATED For detail, please refer: https://mantis.tisane.ai/view.php?id=1841
         */
        
        // //TODO: pass a feature list ID and compare against it, rather than indent (unreliable)
        // for (let li = this.displayedList.length - 2; li > 0; li--) {
        //   let currentLine: Line = this.displayedList[li];
        //   if (!currentLine || currentLine.inflection) break;
          
        //   let hasAnotherValue: boolean = false;
        //   for (let ali = this.displayedList.length - 2; !hasAnotherValue && ali > -1; ali--)
        //     if (!this.displayedList[ali].inflection 
        //         && this.displayedList[ali].indent === currentLine.indent 
        //         && this.displayedList[ali].text !== currentLine.text)
        //         hasAnotherValue = true;
          
        //   if (!hasAnotherValue)
        //       for (let di = this.displayedList.length - 1; di > -1; di--)
        //         if (!this.displayedList[di].inflection
        //           && this.displayedList[di].text === currentLine.text)
        //         this.displayedList.splice(di, 1);

        // }

      } else {
        this.displayedList = this.inflectionList.lines.map(line => {
          return line;
        });
      }
    }
  }

  public isUnmatchedButtonDisabled(): boolean {
    if (this.inflectionList && this.inflectionList.failures) {
      return this.inflectionList.failures.length === 0;
    } else {
      return true;
    }
  }

  public deleteInflection(confirm: boolean, inflectionId: number): void {
    if (confirm) {
      this.showProgressBar = true;
      this.inflectionTableService.deleteInflection(inflectionId)
        .then((message: LampUpdateResponse) => {
          this.showProgressBar = false;
          if (message.success) {
            const findedIndex = this.inflectionList.lines.findIndex(inflection => inflection.inflectionId === inflectionId);
            this.inflectionList.lines.splice(findedIndex, 1);
            this.filterBySelectedFamily();
          }
        });
    }
  }

  public afterCloseEditInflectionDialog(saved: boolean): void {
    if (saved) {
      this.initTable();
    }
  }

  ngOnDestroy(): void {
    this.sessionServiceSubscription.unsubscribe();
  }

}
