import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { LinguisticRangeList, RangeForLookupTable } from '../../../shared/models/range';
import { RangeService } from '../../../shared/services/range.service';

@Component({
  selector: 'app-range-lookup-dialog',
  templateUrl: 'range-lookup-dialog.component.html',
  styleUrls: ['range-lookup-dialog.component.less']
})
export class RangeLookupDialogComponent {

  public selectedRangeId: number;
  public rangeList: RangeForLookupTable[];

  public displayedColumns: string[] = ["id", "range", "table", "selected"];
  public showProgressBar = false;

  constructor(
    private rangeService: RangeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  public reactOnSelectItemEvent(rangeId: number): void {
    this.selectedRangeId = rangeId;
  }
}
