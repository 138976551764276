import {Directive, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog} from '@angular/material';

import {DoYouSaveDialogComponent} from './do-you-save-dialog.component';

@Directive({ selector: '[appDoYouSaveDialog]'})
export class OpenDoYouSaveDialogDirective {

  @Output() dialogWasClosedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() isObjectChanged;

  constructor(
    private dialog: MatDialog
  ) {}

  @HostListener('click') openDialog() {
    if (this.isObjectChanged) {
      const dialogRef = this.dialog.open(DoYouSaveDialogComponent);
      dialogRef.afterClosed()
      .subscribe(response => this.dialogWasClosedEvent.emit(response));
    } else {
      this.dialogWasClosedEvent.emit(true);
    }
  }

}
