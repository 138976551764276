import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input,
  Output
} from '@angular/core';

import {emptyFeatureValue, FeatureListDefinition, FeatureValue} from '../../../shared/models/feature';
import {areTwoObjectsIdentical, toJsonObject, isArray} from '../../../shared/helpers/object.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-universal-features',
  templateUrl: './universal-features.component.html',
  styleUrls: ['./universal-features.component.less']
})
export class UniversalFeaturesComponent {
  @Input() relevantFeatureList: FeatureListDefinition[];
  @Input() featureValue: FeatureValue;
  @Input() featureValues: FeatureValue[];
  @Input() doesTheWholeFeatureListRelevant: boolean;
  @Input() featureType: string;

  @Input() isOnlyOneFeature: boolean;
  @Input() isEmptyValueRelevant: boolean;
  @Input() setDefaultValues: boolean;
  @Input() preselectedIndex: number;
  @Input() disableSelect: boolean;
  @Input() doesUserClearSelection: boolean;
  @Input() checkboxLabel: string;
  @Input() checkboxAttributeName: string;

  @Output() featuresUpdatedValueEvent: EventEmitter<FeatureValue | FeatureValue[]> = new EventEmitter<FeatureValue | FeatureValue[]>();
  @Output() clearSelectionEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isArray = isArray;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  public addPairOfControls() {
    const newFeatureValue = toJsonObject(emptyFeatureValue) as FeatureValue;
    if (this.preselectedIndex) {
      newFeatureValue.index = this.preselectedIndex;
    }

    this.featureValues.push(newFeatureValue);

    this.changeDetectorRef.markForCheck();
  }

  public isFeatureValueDefined(featureValue): boolean {
    return (featureValue && featureValue.index);
  }

  public sendTheFeatureValuesAbove($event?: FeatureValue, index?: number): void {
    if (this.featureValues) {
      this.featureValues.forEach(element => delete element.type);
    }
    let featureValue: FeatureValue;

    const isFeatureValueDefined = this.isFeatureValueDefined($event);
    let featureValuesChanged = this.featureValues && (typeof index === 'number') &&
      !areTwoObjectsIdentical(this.featureValues[index], $event);
    const featureValueChanged = this.featureValue && !areTwoObjectsIdentical(this.featureValue, $event);

    if ($event && index && !this.featureValues) {
      this.featureValues = [];
    }
    if ($event) {
      if (featureValuesChanged && isFeatureValueDefined) {
        this.featureValues[index].value = $event.value;
        this.featureValues[index].index = $event.index;
        if (this.checkboxAttributeName) {
          this.featureValues[index][this.checkboxAttributeName] = $event[this.checkboxAttributeName] || false;
        }
        if (this.featureType)
          this.featureValues[index].type = this.featureType;
      } else if (featureValueChanged && !index) {
        featureValue = $event;
      } else {
        return;
      }
    } else {
      featureValuesChanged = true;
      this.featureValues.splice(index, 1);
    }
    if (this.featureValue) {
      this.featuresUpdatedValueEvent.emit(featureValue);
    }
    if (this.featureValues && featureValuesChanged) {
      if (this.featureType) {
        let thisOne = this;
        this.featureValues.forEach(element => element.type = thisOne.featureType);
      }
      this.featuresUpdatedValueEvent.emit(this.featureValues);
    }
  }

  public clearSelection(): void {
    this.clearSelectionEvent.emit(true);
  }

}
