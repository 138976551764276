export interface Statistics {
  InflectedFormRuleCount: number;
  LanguageFrequentLexemeFamilyCount: number;
  LanguageLexemeCount: number;
  LanguageLexemeFamilyCount: number;
  LanguageNonProperNounLexemeFamilyCount: number;
  LanguagePhraseFamilies: number;
  LanguageProperNounLexemeFamilyCount: number;
  TotalFrequentLexemeFamilyCount: number;
  TotalLexemeFamilyCount: number;
  TotalNonProperNounLexemeFamilyCount: number;
  TotalPhraseFamilies: number;
  TotalProperNounLexemeFamilyCount: number;
}

export const emptyStatistics: Statistics = {
  InflectedFormRuleCount: 0,
  LanguageFrequentLexemeFamilyCount: 0,
  LanguageLexemeCount: 0,
  LanguageLexemeFamilyCount: 0,
  LanguageNonProperNounLexemeFamilyCount: 0,
  LanguagePhraseFamilies: 0,
  LanguageProperNounLexemeFamilyCount: 0,
  TotalFrequentLexemeFamilyCount: 0,
  TotalLexemeFamilyCount: 0,
  TotalNonProperNounLexemeFamilyCount: 0,
  TotalPhraseFamilies: 0,
  TotalProperNounLexemeFamilyCount: 0,
};

export interface User {
  administrator: boolean;
  currentLanguage: number;
  dbUser: string;
  editableLanguages: string;
  isGuest: boolean;
  isLexiconEditorOnly: boolean;
  permissionLevel: string;
  preferredLanguage1: number;
  preferredLanguage2: number;
  requestId: string;
  timezone: string;
}

export interface EditBreakdown {
  Key: string;
  Value: {
    Key: string;
    Value: {
      edits: number;
      status: string;
      languages: number[];
      firstEdit: string,
      lastEdit: string
    }
  }[]
}