import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Subscription} from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {PhonemesService} from '../../../shared/services/phonemes.service';
import {PhonemeAlias, PhonemesAliasNormalizationAttributeText} from '../../../shared/models/phoneme-sign';
import {toJsonObject} from '../../../shared/helpers/object.helper';
import {LampUpdateResponse} from '../../../shared/models/common';

@Component({
  selector: 'app-alias-list-edit',
  templateUrl: './alias-list-edit.component.html',
  styleUrls: ['./alias-list-edit.component.less']
})

export class AliasListEditComponent implements OnInit {
  @Input() phonemeId: number;
  @Input() addNewEntryObservable: Observable<boolean>;
  @Output() addNewElementEvent: EventEmitter<any> = new EventEmitter();

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public aliasList: PhonemeAlias[];
  public phonemesAliasNormalizationAttributeTextChoices = PhonemesAliasNormalizationAttributeText;
  public currentlyEdited: PhonemeAlias;
  public displayedColumns: string[];
  private initialAliasList: PhonemeAlias[];

  private subscription: Subscription;

  constructor(private phonemesService: PhonemesService,
              private changeDetectorRef: ChangeDetectorRef) {
    this.displayedColumns = ['alias-text', 'alias-normalization', 'actions'];
  }

  ngOnInit(): void {
    this.getPhonemeAliases().then();
    this.subscription = this.addNewEntryObservable.subscribe((shouldAddEntry) => {
      if (shouldAddEntry) {
        this.addNewRowInTheTable();
      }
    });
  }

  public saveAlias(phonemeAlias: PhonemeAlias): Promise<void> {
    if (phonemeAlias.id) {
      return this.updatePhonemeAlias(phonemeAlias);
    } else {
      phonemeAlias.phonemeId = this.phonemeId;
      return this.createPhonemeAlias(phonemeAlias);
    }
  }

  private updatePhonemeAlias(phonemeAlias: PhonemeAlias): Promise<void> {
    return this.phonemesService.updatePhonemeAlias(phonemeAlias)
      .then((resultMessage: LampUpdateResponse) => {
        this.currentlyEdited = undefined;
        if (resultMessage.success) {
          return this.getPhonemeAliases();
        } else {
          this.showErrorMessage(resultMessage.error);
        }
        return Promise.resolve();
      });
  }

  private createPhonemeAlias(phonemeAlias: PhonemeAlias): Promise<void> {
    return this.phonemesService.createPhonemeAlias(phonemeAlias)
      .then((resultMessage: LampUpdateResponse) => {
        if (resultMessage.success) {
          this.currentlyEdited = {text: '', phonemeId: this.phonemeId, id: 0, aliasNormalization: 'unrecognizedOnly' };
          return this.getPhonemeAliases();
        } else {
          this.showErrorMessage(resultMessage.error);
          return Promise.resolve();
        }
      });
  }

  private getPhonemeAliases(): Promise<void> {
    return this.phonemesService.getPhonemeAliasList(this.phonemeId).then((phonemeAliasList: PhonemeAlias[]) => {
      this.aliasList = toJsonObject(phonemeAliasList);

      this.initialAliasList = toJsonObject(phonemeAliasList);

      this.changeDetectorRef.markForCheck();
      return Promise.resolve();
    });
  }

  public getAliasNormalizationText(key: string): string {
    const aliasNormalizationTextElement = this.phonemesAliasNormalizationAttributeTextChoices
      .filter(element => (element.key === key));

    const firstElement = this.phonemesAliasNormalizationAttributeTextChoices[0];

    if (aliasNormalizationTextElement && aliasNormalizationTextElement[0] && aliasNormalizationTextElement[0].value) {

      return aliasNormalizationTextElement[0].value;
    }

    if (firstElement && firstElement.value) {
      return firstElement.value;
    }
    return '';
  }

  public edit(aliasId: number) {
    this.initialAliasList = toJsonObject(this.aliasList) as PhonemeAlias[];
    this.phonemesService.getPhonemeAliasById(aliasId).then((alias: PhonemeAlias) => {
      this.currentlyEdited = alias;
    });
  }

  public deleteAlias(aliasId: number): Promise<boolean> {
    return this.phonemesService.deletePhonemeAlias(aliasId).then((resultMessage: LampUpdateResponse) => {
      if (!resultMessage.success) {
        this.showErrorMessage(resultMessage.error);
      }
      return resultMessage.success;
    });
  }

  public revertChanges(): void {
    if (this.initialAliasList) {
      this.aliasList = toJsonObject(this.initialAliasList) as PhonemeAlias[];
    }

    this.currentlyEdited = undefined;

    this.clearTheErrorMessage();

    this.changeDetectorRef.markForCheck();
  }

  private addNewRowInTheTable(): void {
    this.revertChanges();

    if (!this.aliasList) {
      this.aliasList = [];
    }

    const newAlias: PhonemeAlias = {text: '', aliasNormalization: 'always', phonemeId: this.phonemeId};
    this.initialAliasList = toJsonObject(this.aliasList) as PhonemeAlias[];
    this.currentlyEdited = newAlias;

    this.aliasList = this.aliasList.concat([newAlias]);

    this.addNewElementEvent.emit(true);
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  public deleteAliasIfUserConfirmsDeletion(aliasId: number, doesUserConfirm: boolean): Promise<void> | void {
    if (doesUserConfirm) {
      return this.deleteAlias(aliasId).then(() => {
        return this.getPhonemeAliases();
      });
    }
  }

}
