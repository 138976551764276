<div class="ti-menu-wrapper ti-form__column-fields">
  <h1>{{ 'TEST.corpora' | translate }}</h1>
  <app-universal-id-text-range-filter [currentRange]="'testfragments'"
    [loadedRecordCount]="corpora ? corpora.length : 0" [loadedRecordCountLabel]="'TEST.total-corpora'"
    [loadedSubRecCount]="totalTestFragments" [loadedSubRecCountLabel]="'TEST.test-fragments'"
    (chosenParameterEvent)="loadData($event)"></app-universal-id-text-range-filter>

  <mat-checkbox class="chk-no-full-refresh" matInput [(ngModel)]="isNoFullRefresh" (change)="changedNoRefresh()">
    <span class="ti-form__label">{{"lexicon.no-full-refresh" | translate}}</span>
  </mat-checkbox>
  <div class="ti-form__buttons">
    <button mat-mini-fab color="primary" [routerLink]="'/corpora/create'">
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="{{'TEST.reset-cache' | translate}}">
      <mat-icon aria-hidden="true">refresh</mat-icon>
    </button>
  </div>

  <div class="ti-form__one-line-fields ti-form__one-line-fields--error">
    <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  </div>

  <mat-progress-bar *ngIf="loading()" color="accent" mode="indeterminate"></mat-progress-bar>

  <div class="ti-form__filter" *ngIf="!loading()">
    <span class="ti-icon__filter"><mat-icon>filter_list</mat-icon></span>
    <mat-form-field appearance="fill" *ngFor="let field of filterFields">
      <mat-label>{{field.label | translate}}</mat-label>
      <input matInput [(ngModel)]="field.value" (keyup)="refineFilter()" />

      <button [disabled]="!field.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="field.value = ''; refineFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <mat-accordion *ngIf="corporaFiltering" multi="true">
    <mat-expansion-panel *ngFor="let crp of corporaFiltering" appScrollToChangedPreviouslyElement
      [expanded]="(selectedCorporaId === crp.id) || (showingElementId === crp.id) || corporaFiltering.length === 1"
      (click)="saveTheCurrentId(crp.id)"
      >
      <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title>
          <span>{{crp.description}}&nbsp;[{{crp.id}}]</span>
          &nbsp;<span *ngIf="crp.format" class="label">{{crp.format}}</span>
          <span *ngIf="canEditNonLexicon()">&nbsp;
            <button mat-mini-fab color="primary" (click)="storeResultBeforeLeaving()" [routerLink]="'/corpora/edit/' + crp.id"
              [queryParams]="{editedId: crp.id}">
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>&nbsp;
            <button mat-mini-fab color="primary" appOpenUnknownWordsDialog [corporaId]="crp.id"
              [matTooltip]="'TEST.shows-unknown-words' | translate">
              <mat-icon aria-hidden="true">contact_support</mat-icon>
            </button>&nbsp;
            <!--
                <button
                  mat-mini-fab
                  color="primary"
                  appOpenConfirmDialog
                  (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, crp.id)"
                >
                  <mat-icon aria-hidden="true">delete</mat-icon>
                </button>
                -->
          </span>
        </mat-panel-title>
        <mat-panel-description>
          <span>{{crp.labels}}</span>
        </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
        <button mat-mini-fab color="primary" (click)="storeResultBeforeLeaving()"
          [routerLink]="'/testfragments/create/' + crp.id" [queryParams]="{editedId: crp.id}">
          <mat-icon aria-hidden="true">add</mat-icon>
        </button>
        <div *ngFor="let fr of crp.fragments" class="panel">
          <div class="panel-content">
            <span><span style="user-select: all">{{inbrief(fr.fragment)}}</span>
              &nbsp;
              [<span style="user-select: all"><a [href]="'/testfragments/edit/' + fr.id + '?editedId=' + fr.id"
                  target="_blank">{{fr.id}}</a></span>]
              [<a [href]="'/verify-results-analysis?key=id&value=' + fr.id" target="_blank" [matTooltip]="'TEST.edit-gold-standard-2' | translate">{{'TEST.analysis-gold-standard' | translate}}</a>]&nbsp;
              <ng-container *ngIf="providerTransToLangId">
                [<a routerLink="/verify-results-transformation" [queryParams]="{testProviderId: providerTransToLangId, key: 'id', value: fr.id}" target="_blank" [matTooltip]="'TEST.edit-gold-standard-2' | translate">{{providerTransDescription}}</a>]&nbsp;
              </ng-container>
              <span *ngIf="!fr.bookmarked" matTooltip="{{'COMMON.click-to-bookmark' | translate}}"
                (click)="bookmark(fr)">&nbsp;🔖</span>
              <span *ngIf="fr.discussion" [matTooltip]="fr.discussion"> 📓 </span>
              <span *ngIf="fr.errors" [matTooltip]="fr.errors">&nbsp;🤦‍</span>
              <span *ngIf="fr.unknown" [matTooltip]="fr.unknown">&nbsp;👽</span></span>&nbsp;
              <span class="ti-form__labels" *ngIf="fr.labels">{{fr.labels}}</span>
              <br />
              <span class="translation tooltip" appShowTooltipDescription
              [tooltipOption]="{ type: 'TestresultTransformationGold', fragmentId: fr.id }">
                <i *ngIf="fr.fragmentTranslation">(en)</i>
              {{fr.fragmentTranslation.length > 300 ? fr.fragmentTranslation.substring(0, 300) + '...' :
              fr.fragmentTranslation}}
              </span>
              </div>
              <span class="label-description">
                <span *ngIf="fr.labelDescriptions">🏷️ {{fr.labelDescriptions}}</span>
              </span>
              <div *ngIf="canEditNonLexicon()" class="ti-form__button-panel">
            <button mat-mini-fab color="primary" (click)="storeResultBeforeLeaving()" [routerLink]="'/testfragments/edit/' + fr.id"
              [queryParams]="{editedId: fr.id}">
              <mat-icon aria-hidden="true">edit</mat-icon>
            </button>&nbsp;
            <button mat-mini-fab color="primary" appOpenConfirmDialog
              (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, fr.id)">
              <mat-icon aria-hidden="true">delete</mat-icon>
            </button>
            </div>
            </div>
            </ng-template>
            </mat-expansion-panel>
            </mat-accordion>
</div>