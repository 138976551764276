import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';

import {KnowledgeGraphService} from '../../../shared/services/knowledge-graph.service';
import {Family} from '../../../shared/models/knowledge-graph';

@Component({
  selector: 'app-family-selection',
  templateUrl: './family-selection.component.html',
  styleUrls: ['./family-selection.component.less']
})

export class FamilySelectionComponent implements OnInit {
  @Input() chooseOnlyOneFamily: boolean;
  @Input() initialFamilyId: number;
  @Input() byText: string;
  public showSpinner: boolean = false;
  public familiesList: Family[];
  public displayedColumns: string[];
  public addedFamiliesIdsStringList: string = '';

  private $event: { key: string, value: string | number };

  public currentCheckedFamily: number = 0;
  public currentChosenFamily: Family;

  @Output() updateEntryEvent: EventEmitter<string> = new EventEmitter();
  @Output() chosenFamilyEvent: EventEmitter<Family> = new EventEmitter();

  constructor(private knowledgeGraphService: KnowledgeGraphService) {
  }

  ngOnInit() {
    this.displayedColumns = ['id', 'description', 'definition', 'actions'];
    if (this.byText)
      this.findFamilies({key: 'text', value: this.byText});
    else {
      if (this.initialFamilyId > 0)
        this.findFamilies({key: 'id', value: this.initialFamilyId});
    }
  }

  public findFamilies($event: { key: string, value: string | number }): Promise<void> {
    this.familiesList = undefined;
    this.showSpinner = true;
    this.$event = $event;

    return this.knowledgeGraphService.getFamiliesListForTheCurrentRange($event.value.toString(), $event.key)
      .then((familiesList: Family[]) => {
        this.showSpinner = false;
        if (familiesList[0]) {
          this.familiesList = familiesList;
        }
      });
  }

  public proceedCurrentFamilyId(id: number, isLexemeSelected: boolean): void {
    const addedFamiliesIdsList = this.addedFamiliesIdsStringList ? this.addedFamiliesIdsStringList.split(',') : [];
    if (isLexemeSelected) {
      this.currentCheckedFamily = id;
      addedFamiliesIdsList.push(id.toString());
    } else {
      this.currentCheckedFamily = 0;
      const deletedId = addedFamiliesIdsList.findIndex(element => (element.toString() === id.toString()));
      addedFamiliesIdsList.splice(deletedId, 1);
    }

    this.addedFamiliesIdsStringList = addedFamiliesIdsList.length ? addedFamiliesIdsList.join(',') : '';
  }

  public saveCurrentFamily(id: number): void {
    this.currentCheckedFamily = id;
    this.addedFamiliesIdsStringList = id.toString();

    const family: Family = this.familiesList.find(element => element.id === id);
    this.currentChosenFamily = family;

    this.chosenFamilyEvent.emit(this.currentChosenFamily);
  }

  public updateEntry(id: number, $event: MatCheckboxChange): void {
    this.proceedCurrentFamilyId(id, $event.checked);

    this.updateEntryEvent.emit(this.addedFamiliesIdsStringList);
    this.chosenFamilyEvent.emit(this.currentChosenFamily);
  }
}

