import {Component, OnDestroy, OnInit, EventEmitter, Output} from '@angular/core';

import {Subscription} from 'rxjs/Subscription';

import {SessionService} from '../../shared/services/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private openedMenu: boolean = false;
  @Output() openMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>(this.openedMenu);
  private sessionServiceSubscription: Subscription;
  public isMobileView: boolean;
  public mobileMenuToggle: boolean = false;

  constructor(private sessionService: SessionService) {
  }
  
  public isAuthenticated(): boolean {
    return this.sessionService && this.sessionService.isAuthenticated();
  }


  ngOnInit(): void {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
    if (!this.isAuthenticated())
      this.mobileMenuToggle = false;
    
    this.sessionServiceSubscription = this.sessionService.currentSessionSource.subscribe((session) => {
       if (!session.isAuthenticated) {
         this.mobileMenuToggle = false;
       }
    });
  }


  public toggleMenu() {
    this.openedMenu = !this.openedMenu;
    this.openMenuEvent.emit(this.openedMenu);
  }

  public toggleMobileMenu(): void {
    this.mobileMenuToggle = !this.mobileMenuToggle;
  }

  ngOnDestroy(): void {
    this.sessionServiceSubscription.unsubscribe();
  }
}
