import {FeatureValue, ModelWithRelevantFeature} from './feature';

export interface Interfix extends ModelWithRelevantFeature {
  advancedCriteriaDescription?: string;
  advancedCriteriaId?: number;
  assignedGrammar?: FeatureValue[]; // never be used to display
  assignedSemantics?: FeatureValue[]; // never be used to display
  assignedStyle?: FeatureValue[]; // never be used to display
  followingWordAdvancedCriteriaDescription?: string;
  followingWordAdvancedCriteriaId?: number;
  id?: number;
  charactersToDelete?: number;
  incompatibleDescriptions?: string;
  incompatibleGrammar?: FeatureValue[];
  incompatibleStyle?: FeatureValue[];
  incompatibleSemantics?: FeatureValue[];
  legacyId?: number;
  note?: string;
  requiredGrammar?: FeatureValue[];
  requiredStyle?: FeatureValue[];
  requiredSemantics?: FeatureValue[];
  requiredDescriptions?: string;
  text?: string;
}

export const emptyInterfix: Interfix = {
  advancedCriteriaDescription: '',
  advancedCriteriaId: null,
  followingWordAdvancedCriteriaDescription: '',
  followingWordAdvancedCriteriaId: null,
  incompatibleDescriptions: '',
  incompatibleGrammar: null,
  incompatibleStyle: null,
  incompatibleSemantics: null,
  legacyId: null,
  note: '',
  requiredDescriptions: '',
  requiredGrammar: null,
  requiredStyle: null,
  requiredSemantics: null,
  text: ''
};
