import {Directive, HostListener, Input} from '@angular/core';
import {MatDialog} from '@angular/material';

import {InflectionMatchedDialogComponent} from './inflection-matched-dialog.componen';
import {GeneratedBy} from '../../shared/models/inflections';

@Directive({
  selector: '[appOpenInflectionMatchedDialog]'
})
export class OpenInflectionMatchedDialogDirective {

  @Input() generatedBy: GeneratedBy;
  @Input() stemGeneratedBy: GeneratedBy;
  @Input() extraHypothesis: GeneratedBy;
  @Input() wordPart: boolean;
  @Input() failures: any;
  @Input() lexeme: string;
  @Input() tagging: any;

  constructor(
    private dialog: MatDialog
  ) {}

  @HostListener('click') openDialog() {
    this.dialog.open(InflectionMatchedDialogComponent, {
      data: {
        generatedBy: this.generatedBy,
        stemGeneratedBy: this.stemGeneratedBy,
        extraHypothesis: this.extraHypothesis,
        failures: this.failures,
        lexeme: this.lexeme,
        tagging: this.tagging,
        wordPart: this.wordPart
      },
      minWidth: '70%'
    });

  }

}
