<mat-dialog-content>
  <h1 *ngIf="noData">{{ 'COMMON.there-is-no-data' | translate }}</h1>
  <div *ngIf="dialogData.generatedBy || dialogData.extraHypothesis">
    <h4>{{ 'inflection-table.inflection-matсhed-header' | translate }}</h4>
    <h1>{{dialogData.lexeme}}</h1>
    <p *ngIf="dialogData.generatedBy"> {{ dialogData.generatedBy.trigger }}</p>
\      <mat-chip-list>
          <mat-chip *ngIf="dialogData.stemGeneratedBy && dialogData.stemGeneratedBy.pattern"  matTooltip="{{ 'AFFIXES.stem-change' | translate }}" color="primary">
            {{ dialogData.stemGeneratedBy.pattern }}
          </mat-chip>
          <mat-chip *ngIf="dialogData.stemGeneratedBy && dialogData.stemGeneratedBy.id" matTooltip="{{ 'AFFIXES.stem-change' | translate }}">
            <a class="ti-matched-dialog__link"
              [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+dialogData.stemGeneratedBy.id"
              target="_blank"
            > ✂&nbsp;{{ dialogData.stemGeneratedBy.id }}</a>
          </mat-chip>
          <mat-chip *ngIf="dialogData.stemGeneratedBy && dialogData.stemGeneratedBy.propagatedId" matTooltip="{{ 'AFFIXES.stem-change' | translate }}">
            <a class="ti-matched-dialog__link"
              [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+dialogData.stemGeneratedBy.propagatedId"
              target="_blank"
            > ✂&nbsp;{{ dialogData.stemGeneratedBy.propagatedId }}</a>
          </mat-chip>
          <mat-chip *ngIf="dialogData.generatedBy" color="primary">{{ dialogData.generatedBy.pattern }}</mat-chip>
          <mat-chip *ngIf="dialogData.generatedBy && !dialogData.wordPart" matTooltip="{{ 'inflection-table.inflected-form' | translate }}">
            <a class="ti-matched-dialog__link"
              [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+dialogData.generatedBy.id"
              target="_blank"
            > 🏗️&nbsp;{{ dialogData.generatedBy.id }}</a>
          </mat-chip>
          <mat-chip *ngIf="dialogData.generatedBy && dialogData.wordPart" matTooltip="{{ 'inflection-table.inflected-form' | translate }}">
              <a class="ti-matched-dialog__link"
                [routerLink]="'/morphology/interfixes/edit/'+dialogData.generatedBy.id+'?editedId='+dialogData.generatedBy.id"
                [queryParams]="{editedId: dialogData.generatedBy.id}"
                target="_blank"
              > 🧩&nbsp;{{ dialogData.generatedBy.id }}</a>
            </mat-chip>
            <mat-chip *ngIf="dialogData.generatedBy && dialogData.generatedBy.propagatedId">
              <a class="ti-matched-dialog__link"
                [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+dialogData.generatedBy.propagatedId"
                target="_blank"
              > 🔗&nbsp;{{ dialogData.generatedBy.propagatedId }}</a>
          </mat-chip>
          <mat-chip *ngIf="combinedRequired" 
            matTooltip="{{ 'inflection-table.required' | translate }}">
            🛂&nbsp;{{combinedRequired}}
          </mat-chip>
          <mat-chip *ngIf="combinedAssigned" 
            matTooltip="{{ 'inflection-table.assigned' | translate }}">
            🎁&nbsp;{{combinedAssigned}}
          </mat-chip>
          <mat-chip *ngIf="dialogData.extraHypothesis && dialogData.extraHypothesis.id" 
            matTooltip="{{ 'extra-hypothesis.extra-hypothesis' | translate }}">
            <a class="ti-matched-dialog__link"
            [href]="'/morphology/extra-hypothesis-generation'"
            target="_blank"
          > 🧬&nbsp;{{ dialogData.extraHypothesis.id }}&nbsp;</a>&nbsp;{{dialogData.extraHypothesis.required}}
          </mat-chip>
    </mat-chip-list>
  </div>
  <div *ngIf="dialogData.failures">
    <h4 *ngIf="!isTagging">{{ 'inflection-table.inflection-unmatched-header' | translate }}</h4>
    <h4 *ngIf="isTagging">{{ 'COMMON.tagging-affixes' | translate }}</h4>
    <div class="ti-form__one-line-fields ti-form__filter">
      <mat-form-field color="accent">
        <input
          matInput
          [(ngModel)]="affixText"
          (ngModelChange)="affixTextUpdate.next($event)"
          [placeholder]="'inflection-table.search-affix-text' | translate"/>
      </mat-form-field>
      <mat-form-field color="accent">
        <input
          matInput
          [(ngModel)]="featureDescription"
          (ngModelChange)="featureDescriptionUpdate.next($event)"
          [placeholder]="'inflection-table.search-feature-description' | translate"/>
      </mat-form-field>
      <button
          mat-mini-fab
          class="ti-button__reset-selection"
          color="primary"
          [disabled]="!affixText && !featureDescription"
          (click)="resetFilter()"
          [matTooltip]="'inflection-table.reset' | translate"
          >
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
    </div>
    <mat-tab-group>
      <mat-tab *ngFor="let failuresByStem of dialogData.failures">
        <ng-template mat-tab-label>
          <span [ngClass]="{'ti-matched-dialog__green': tabIncludedSuccess[failuresByStem.Key]}">{{(failuresByStem.Key.indexOf('(') > 0 ? '' : '✂&nbsp;') + failuresByStem.Key}}</span>
        </ng-template>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let failuresByTrigger of failuresByStem.Value">
              <mat-expansion-panel-header *ngIf="triggerGroups[failuresByStem.Key + '_' + failuresByTrigger.Key]">
                  <mat-panel-title
                  [ngClass]="{'ti-matched-dialog__green': isTriggerSuccessful(failuresByStem.Key + '_' + failuresByTrigger.Key)}">
                    {{ failuresByTrigger.Key }}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                  <mat-accordion *ngIf="triggerGroups[failuresByStem.Key + '_' + failuresByTrigger.Key]">
                  <mat-expansion-panel *ngFor="let tg of triggerGroups[failuresByStem.Key + '_' + failuresByTrigger.Key]">
                      <mat-expansion-panel-header>  
                        <mat-panel-title>
                          <span [innerHTML]="tg.Description"
                          [ngClass]="{'ti-matched-dialog__green': tg.hasSuccess}"></span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>
                        <mat-chip-list *ngFor="let failure of tg.Values">
                            <mat-chip [ngClass]="{'ti-matched-dialog__red': failure.reason === 'pattern' || failure.reason === 'regex', 'ti-matched-dialog__green': failure.reason === 'success'}">
                              {{ failure.pattern }}
                            </mat-chip>
                            <mat-chip *ngIf="!failure.wordPart"
                               matTooltip="{{ 'inflection-table.inflected-form' | translate }}">
                                <a class="ti-matched-dialog__link"
                                  [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+failure.id"
                                  target="_blank"
                                > 🏗️&nbsp;{{ failure.id }}</a>
                              </mat-chip>
                              <mat-chip *ngIf="failure.wordPart"
                              matTooltip="{{ 'interfix.interfix' | translate }}">
                              <a class="ti-matched-dialog__link"
                              [routerLink]="'/morphology/interfixes/edit/'+failure.id+'?editedId='+failure.id"
                              [queryParams]="{editedId: failure.id}"
                              target="_blank"
                            > 🧩&nbsp;{{ failure.id }}</a>
                             </mat-chip>
                             <mat-chip *ngIf="failure.spawnedFrom">
                                  <a class="ti-matched-dialog__link"
                                    [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+failure.spawnedFrom"
                                    target="_blank"
                                  > 🔗&nbsp;{{ failure.spawnedFrom }}</a>
                              </mat-chip>
                              <mat-chip *ngIf="failure.propagatedId">
                                <a class="ti-matched-dialog__link"
                                  [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+failure.propagatedId"
                                  target="_blank"
                                > 🔗&nbsp;{{ failure.propagatedId }}</a>
                            </mat-chip>
                            <mat-chip *ngIf="failure.required || failure.reason === 'required'" 
                              [ngClass]="{'ti-matched-dialog__red': failure.reason === 'required', 'ti-matched-dialog__green': failure.reason === 'success'}"
                              matTooltip="{{ 'inflection-table.required' | translate }}">
                              🛂&nbsp;{{failure.required}}
                              <span *ngIf="failure.features" class="ti-matched-dialog__red">&nbsp;({{failure.features}})</span>
                            </mat-chip>
                            <mat-chip *ngIf="failure.assigned || failure.reason === 'assigned'" 
                            [ngClass]="{'ti-matched-dialog__red': failure.reason === 'assigned', 'ti-matched-dialog__green': failure.reason === 'success'}"
                              matTooltip="{{ 'inflection-table.assigned' | translate }}">
                              🎁&nbsp;{{failure.assigned}}
                              <span *ngIf="failure.features" class="ti-matched-dialog__red">&nbsp;({{failure.features}})</span>
                            </mat-chip>
                            <mat-chip *ngIf="failure.reason === 'incompatible'" 
                            [ngClass]="'ti-matched-dialog__red'"
                              matTooltip="{{ 'COMMON.incompatible' | translate }}">
                              🛂&nbsp;{{ 'COMMON.incompatible' | translate }}
                              <span *ngIf="failure.features" class="ti-matched-dialog__red">&nbsp;({{failure.features}})</span>
                            </mat-chip>
                            <mat-chip *ngIf="failure.reason === 'hypernyms'" 
                            [ngClass]="'ti-matched-dialog__red'"
                              matTooltip="{{ 'COMMON.hypernym' | translate }}">
                              🛂&nbsp;{{ 'COMMON.hypernym' | translate }}
                            </mat-chip>
                            <mat-chip *ngIf="failure.phoneticFailure" 
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.phonetic-compatibility' | translate }}">
                            🧵&nbsp;{{failure.phoneticFailure}}
                          </mat-chip>
                          <mat-chip *ngIf="failure.reason === 'phonetic' && !failure.phoneticFailure" 
                          [ngClass]="'ti-matched-dialog__red'"
                          matTooltip="{{ 'AFFIXES.phonetic-compatibility' | translate }}">
                          🧵&nbsp;{{ 'AFFIXES.phonetic-compatibility' | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="failure.reason === 'deletions'"
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.too-many-characters-to-delete' | translate }}">
                            ❌&nbsp;{{ 'AFFIXES.too-many-characters-to-delete' | translate }}
                          </mat-chip>
                          <mat-chip *ngIf="failure.reason === 'infix'"
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.infix-mismatch' | translate }}">
                            🔌&nbsp;{{ 'AFFIXES.infix-mismatch' | translate }}
                          </mat-chip>
                          <mat-chip *ngIf="failure.reason === 'others'"
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.mutually-incompatible' | translate }}">
                            🧩&nbsp;{{ 'AFFIXES.mutually-incompatible' | translate }}
                          </mat-chip>
                          <mat-chip *ngIf="failure.reason === 'reduplication'"
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.invalid-reduplication' | translate }}">
                            ➿&nbsp;{{ 'AFFIXES.invalid-reduplication' | translate }}
                          </mat-chip>
                          <mat-chip *ngIf="failure.reason === 'overwriting'"
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.not-allowed-to-overwrite' | translate }}">
                            🚫&nbsp;{{ 'AFFIXES.not-allowed-to-overwrite' | translate }}
                          </mat-chip>
                          <mat-chip *ngIf="failure.reason === 'agreeing'"
                            [ngClass]="'ti-matched-dialog__red'"
                            matTooltip="{{ 'AFFIXES.agreeing-forms-issue' | translate }}">
                            👎&nbsp;{{ 'AFFIXES.agreeing-forms-issue' | translate }}
                          </mat-chip>
                          <mat-chip *ngIf="failure.madeRedundantByPattern"
                            matTooltip="{{ 'inflection-table.made-redundant-by-inflection' | translate }}">
                                🥊&nbsp;
                                <a class="ti-matched-dialog__link"
                                  [href]="'/morphology/affixes/'+affixSubfolder+'/?key=id&value='+failure.madeRedundantByPattern"
                                  target="_blank"
                                >{{ failure.madeRedundantByPattern }}</a>
                            </mat-chip>
                            <mat-chip *ngIf="failure.madeRedundantByForm"
                            matTooltip="{{ 'inflection-table.made-redundant-by-form' | translate }}">
                                🥊&nbsp;{{ failure.madeRedundantByForm }}
                            </mat-chip>
                        </mat-chip-list>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div *ngIf="dialogData.tagging && !noData">
    <h1>{{ 'inflection-table.tagging-trace' | translate }} {{dialogData.lexeme}}</h1>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let trigger of taggingKeys">
        <mat-expansion-panel-header>
          <mat-panel-title>
            [{{ 'inflection-table.trigger' | translate }}] {{ trigger }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p style="margin: 23px 0;" *ngFor="let tag of dialogData.tagging[trigger]">
          <span
            *ngIf="tag"
            [ngClass]="{'ti-matched-dialog__red': tag.reason === 'pattern', 'ti-matched-dialog__green': tag.reason === 'success'}">{{ tag.pattern }}</span>
          <a class="ti-matched-dialog__link"
             [routerLink]="'/morphology/affixes/'+affixSubfolder+'/edit/'+tag.id"
             [queryParams]="{editedId: tag.id}"
             target="_blank"> {{ 'inflection-table.inflected-form' | translate }} {{ tag.id }}</a> :
          <span
            [ngClass]="{'ti-matched-dialog__red': tag.reason === 'required', 'ti-matched-dialog__green': tag.reason === 'success'}">
              <span class="ti-matched-dialog__static-word">{{ 'inflection-table.required' | translate }}</span> :
              {{ tag.required }},</span>
          <span
            [ngClass]="{'ti-matched-dialog__red': tag.reason === 'assigned', 'ti-matched-dialog__green': tag.reason === 'success'}">
              <span class="ti-matched-dialog__static-word">{{ 'inflection-table.assigned' | translate }}</span> :
              {{ tag.assigned }}</span>
          <span
            [ngClass]="{'ti-matched-dialog__red': tag.reason === 'hypernyms', 'ti-matched-dialog__green': tag.reason === 'success'}">{{ tag.hypernyms ? ', hypernyms' : '' }}</span>
          <span
            [ngClass]="{'ti-matched-dialog__red': tag.reason === 'incompatible', 'ti-matched-dialog__green': tag.reason === 'success'}">{{ tag.incompatible ? ', incompatible' : '' }}</span>
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-dialog-content>
