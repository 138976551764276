import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs/Subject';

import {FeatureListDefinition, FeatureValueDefinition, FeatureValue} from '../../../shared/models/feature';

@Component({
  selector: 'app-universal-feature',
  templateUrl: './universal-feature.component.html',
  styleUrls: ['./universal-feature.component.less']
})

export class UniversalFeatureComponent implements OnInit {
  @Input() relevantFeatureList: FeatureListDefinition[];
  @Input() isOnlyOneFeature: boolean;
  @Input() featureValue: FeatureValue;
  @Input() doesTheWholeFeatureListRelevant: boolean;
  @Input() setDefaultValues: boolean;
  @Input() preselectedIndex: number;
  @Input() isEmptyValueRelevant: boolean;
  @Input() disableSelect: boolean;
  @Input() doesUserClearSelection: boolean;
  @Input() checkboxLabel: string;
  @Input() checkboxAttributeName: string;

  public currentFeatureIndex: number;
  public previousFeatureIndex: number;
  public currentFeature: FeatureListDefinition;
  public currentFeatureOptionId: string;
  public previousFeatureOptionId: string;
  public emitChooseCurrentFeature: Subject<boolean> = new Subject<boolean>();
  public customFlag:boolean;

  @Output() featureChosenValueEvent: EventEmitter<FeatureValue | undefined> = new EventEmitter<FeatureValue | undefined>();
  @Output() clearSelectionEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initialize();
  }

  private initialize(): void {
    this.currentFeatureIndex = this.featureValue.index;
    this.previousFeatureIndex = this.featureValue.index;
    this.currentFeatureOptionId = this.featureValue.value;
    this.previousFeatureOptionId = this.featureValue.value;
    if(this.checkboxAttributeName) {
      this.customFlag = this.featureValue[this.checkboxAttributeName];
    }

    if (this.setDefaultValues && !this.currentFeatureIndex && !this.currentFeatureOptionId) {
      this.setDefault();
    }

    this.chooseCurrentFeature();
    this.chooseCurrentFeatureOption();
  }

  private setDefault() {
    let currentFeature: FeatureListDefinition;

    if (this.doesTheWholeFeatureListRelevant) {
      currentFeature = this.relevantFeatureList[0];
    } else {
      currentFeature = this.relevantFeatureList.find(element => (element.relevant || element.relevantPartially))[0];
    }
    this.currentFeature = currentFeature;
    this.currentFeatureIndex = currentFeature.id;

    this.setFeatureOptionIfIsNotEmptyValueRelevant(currentFeature);
  }

  private setFeatureOptionIfIsNotEmptyValueRelevant(currentFeature: FeatureListDefinition) {
    let currentFeatureOption: FeatureValueDefinition;

    if (this.currentFeature) {
      if (this.doesTheWholeFeatureListRelevant) {
        currentFeatureOption = currentFeature.values[0];
      } else {
        currentFeatureOption = currentFeature.values.find(element => element.relevant);
        currentFeatureOption = currentFeatureOption ? currentFeatureOption : currentFeature.values[0];
      }
      this.featureValue = {index: currentFeature.id, value: currentFeatureOption.id} as FeatureValue;
      if (this.checkboxLabel && this.checkboxAttributeName) {
        this.featureValue[this.checkboxAttributeName] = this.customFlag || false;
      }
      this.currentFeatureOptionId = (currentFeatureOption.id) ? currentFeatureOption.id.toString() : '';
    }
  }


  public chooseCurrentFeatureOption(): void {
    if (this.currentFeatureIndex) {
      const featureValue = { index: this.currentFeatureIndex, value: this.currentFeatureOptionId } as FeatureValue;
      if (this.checkboxLabel && this.checkboxAttributeName) {
        featureValue[this.checkboxAttributeName] = this.customFlag || false;
      }

      this.featureChosenValueEvent.emit(featureValue);
    }
  }

  public chooseCurrentFeature(): void {
    if (this.currentFeatureIndex) {
      const currentFeature = this.relevantFeatureList.find(element => element.index === this.currentFeatureIndex);

      if (currentFeature) {
        this.currentFeature = currentFeature;
      } else {
        this.currentFeatureIndex = (this.currentFeature) ? (this.currentFeature.index) : 0;
      }

      if (this.previousFeatureIndex !== this.currentFeatureIndex) {
        this.currentFeatureOptionId = undefined;
      }

      if (!this.isEmptyValueRelevant && !this.currentFeatureOptionId) {
        this.setFeatureOptionIfIsNotEmptyValueRelevant(this.currentFeature);
      }
      this.changeDetectorRef.markForCheck();

      this.previousFeatureOptionId = this.currentFeatureOptionId;
      this.previousFeatureIndex = this.currentFeatureIndex;
    }
  }

  public removePairOfControls(): void {
    this.featureChosenValueEvent.emit(undefined);
  }

  public saveCurrentFeatureId($event: number): void {
    this.currentFeatureIndex = $event;

    this.chooseCurrentFeature();
  }

  public reactOnClearFeatureOptionEvent(): void {
    if (this.isOnlyOneFeature) {
      this.clearSelectionEvent.emit();
    }
  }

  public saveCurrentFeatureOption($event: string): void {
    this.currentFeatureOptionId = $event;

    this.chooseCurrentFeatureOption();
  }

  public clearSelection(): void {
    this.currentFeature = undefined;
    this.currentFeatureIndex = undefined;
    this.emitChooseCurrentFeature.next(true);
    this.clearSelectionEvent.emit(true);
  }

  public buttonVisibility(): boolean {
    return !this.disableSelect && this.currentFeature && this.doesUserClearSelection;
  }

}
