<div class="ti-form-wrapper">
  <h1>{{ 'TEST.fragment-view' | translate}}</h1>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
  <div class="ti-form">
    <div *ngIf="fragment.id" class="ti-form__one-line-fields">
      <span class="ti-form__id-key">{{'COMMON.id' | translate}}</span>
      &nbsp;<span class="ti-form__id-value">{{fragment.id}}</span>
    </div>
    <mat-form-field color="accent" *ngIf="isCreating()">
      <textarea matInput required (change)="clearTheErrorMessage()" name="fragment" [(ngModel)]="fragment.fragment"
        [placeholder]="'TEST.input' | translate"></textarea>
    </mat-form-field>
    <span class="ti-fragment" id="fragment" *ngIf="!isCreating()" [innerHtml]="taggedResult"></span>
    <span class="ti-fragment-trans" *ngIf="fragment.fragmentTranslation">
      <i>(en)</i> {{fragment.fragmentTranslation}}
    </span>
    <div class="ti-form__one-line-fields" *ngIf="fragment.errors">
      ⚠&nbsp;{{fragment.errors}}
    </div>
    <div class="ti-form__one-line-fields" *ngIf="fragment.unknown" matTooltip="{{'TEST.unknown' | translate}}">
      👽&nbsp;
      <span *ngFor="let unkn of unknownWords">
        <a title="{{'TEST.create-lexeme' | translate}}" [href]="'/lexicon/lexeme/create?' + unkn.trim()"
          target="_blank">📖&nbsp;{{unkn}}</a>
      </span>
    </div>
    <div *ngFor="let cls of classifications">{{cls.emoji}}&nbsp;<b>&nbsp;{{cls.subtype}}</b>&nbsp;{{cls.label}}
    </div>

    <mat-tab-group (selectedTabChange)="getFragmentTransformationResults($event)">
      <mat-tab label="{{ 'TEST.analysis' | translate }}">
        <ul role="navigation">
          <li *ngFor="let rs of resultsets" (click)="selectResultset(rs)"
            [style.font-weight]="selectedResultSet === rs ? 'bold' : ''"
            [style.back-color]="selectedResultSet === rs ? 'orange' : ''">
            <!--
                    <span *ngIf="selectedResultSet === rs">👉&nbsp;</span>
                    -->
            <span *ngIf="rs.standard" matTooltip="{{'TEST.standard' | translate}}">🥇&nbsp;</span>
            <span *ngIf="rs.gold" matTooltip="{{'TEST.gold' | translate}}">👍&nbsp;</span>
            {{rs.created.replace('T', ' ').replace('Z', '')}}&nbsp;({{rs.providerName}})
            &nbsp;<span *ngIf="rs.time"
              matTooltip="{{'TEST.time-taken' | translate}}: {{generateTimeInfo(rs)}}">⏱&nbsp;{{rs.time.toFixed(3)}}</span>
            <span matTooltip="{{'TEST.copy-clipboard' | translate}}" style="cursor: pointer;"
              (click)="copyToClipboard(rs.raw)">&nbsp;👩‍💻</span>
            <span *ngIf="!rs.standard" matTooltip="{{'TEST.mark-standard' | translate}}"
              (click)="markStandard(rs)">✔🥇&nbsp;</span>
            <span *ngIf="!rs.gold" matTooltip="{{'TEST.mark-gold' | translate}}" (click)="markGold(rs)">✔👍&nbsp;</span>
            <a *ngIf="rs === resultsets[0]" matTooltip="{{'TEST.edit-gold-standard' | translate}}"
              [href]="'/verify-results-analysis?key=id&value=' + rs.testFragmentId" target="_target">&nbsp;📝</a>
          </li>
        </ul>
      </mat-tab>
      <mat-tab *ngFor="let p of testProviders" [label]="p.description">
        <ng-template matTabContent>
          <div *ngIf="(fragmentTransformationResults[p.id] || []).length == 0" class="no-result">
            No results
          </div>
          <ng-container *ngIf="(fragmentTransformationResults[p.id] || []).length > 0">
            <ul class="transformation">
              <li *ngFor="let res of fragmentTransformationResults[p.id]">
                <span>{{res.created.replace('T', ' ').replace('Z', '')}}&nbsp;</span>
                <span>{{res.result}}</span>
                <span *ngIf="res.rating || res.grade1 || res.grade2 || res.grade3">
                  [COMET MT:
                  <ng-container *ngFor="let prop of ['rating','grade1','grade2','grade3']">
                    <span *ngIf="res[prop]" matTooltip="{{('TEST.' + prop) | translate}}">&nbsp;{{res[prop].toFixed(3)}}</span>
                  </ng-container>]
                </span>
                <span *ngIf="res.timeTaken" class="time-taken">&nbsp;⏱&nbsp;{{res.timeTaken.toFixed(3)}}</span>
              </li>
            </ul>
          </ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="ti-form__one-line-fields">
      <mat-checkbox [(ngModel)]="fragment.test" matInput>
        <span class="ti-form__label">
          {{"TEST.for-daily-tests" | translate}}
        </span>
      </mat-checkbox>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-checkbox [(ngModel)]="fragment.stopTestingAfterNext" matInput>
        <span class="ti-form__label">
          {{"TEST.stop-testing-after-next" | translate}}
        </span>
      </mat-checkbox>
    </div>
    <div class="ti-form__one-line-fields">
      <mat-checkbox [(ngModel)]="fragment.critical" matInput>
        <span class="ti-form__label">
          {{"TEST.critical" | translate}}
        </span>
      </mat-checkbox>
    </div>

    <div class="ti-form__discussion">
      <mat-form-field color="accent">
        <textarea matInput [(ngModel)]="fragment.discussion" [placeholder]="'TEST.discussion' | translate"
          [rows]="5"></textarea>
      </mat-form-field>
    </div>

    <div class="ti-form__bordered-part features">
      <span class="ti-form-bordered-part__label">{{'TEST.labels' | translate}}</span>

      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-form-bordered-part__label">{{'COMMON.grammar' | translate}}</span>
        </div>
        <div class="ti-form-bordered-part" style="display: flex;">
          <app-universal-features *ngIf="relevantGrammarFeatureList" [featureValues]="grammarFeatures"
            [doesTheWholeFeatureListRelevant]="dontFilterGrammar" [relevantFeatureList]="relevantGrammarFeatureList"
            [isOnlyOneFeature]="false" (featuresUpdatedValueEvent)="grammarFeatures = $event"></app-universal-features>
        </div>
      </div>
      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-form-bordered-part__label">{{'lexicon.style' | translate}}</span>
        </div>
        <div class="ti-form-bordered-part__content" style="display: flex">
          <app-universal-features *ngIf="relevantStyleFeatureList" [featureValues]="styleFeatures"
            [doesTheWholeFeatureListRelevant]="true" [relevantFeatureList]="relevantStyleFeatureList"
            [isOnlyOneFeature]="false" (featuresUpdatedValueEvent)="styleFeatures = $event">
          </app-universal-features>
        </div>
      </div>
      <div class="ti-form__bordered-part">
        <div class="ti-form__one-line-fields ti-form-bordered-part__label">
          <span class="ti-form-bordered-part__label">{{'COMMON.semantic' | translate}}</span>
        </div>
        <div class="ti-form-bordered-part__content" style="display: flex">
          <app-universal-features *ngIf="relevantSemanticFeatureList" [featureValues]="semanticFeatures"
            [doesTheWholeFeatureListRelevant]="true" [relevantFeatureList]="relevantSemanticFeatureList"
            [isOnlyOneFeature]="false" (featuresUpdatedValueEvent)="semanticFeatures = $event">
          </app-universal-features>
        </div>
      </div>
    </div>
    <div class="ti-form__one-line-fields created" *ngIf="fragment.createdBy || fragment.created">
      <span class="ti-form__id-key">{{'COMMON.created' | translate}}</span>
      <span class="ti-form__created-info">{{fragment.createdBy}} &nbsp;({{fragment.created}})</span>
    </div>

    <div class="action">
      <button mat-raised-button (click)="toList()">{{'COMMON.cancel' | translate}}</button>
      <button mat-raised-button color="accent" [disabled]="!canEditNonLexicon()" (click)="save()">{{'COMMON.save' |
        translate}}
      </button>
    </div>
  </div>

</div>