<div class="ti-language-selection">

  <mat-form-field class="ti-language-selection__select" color="accent">
    <mat-select
      role="listbox"
      placeholder="{{ 'language-selection.selected-language' | translate}}"
      [(value)]="selectedLanguage"
      (selectionChange)="onLanguageChange()"
    >
      <mat-option role="option" *ngFor="let lng of languagesList" [value]="lng.id">{{lng.englishName}}
       ({{lng.name}})</mat-option>
    </mat-select>
  </mat-form-field>
</div>
