import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';

import {PunctuationSign} from '../models/punctuation-sign';
import {PunctuationService} from '../services/punctuation.service';


@Injectable()
export class PunctuationSignListResolver implements Resolve<Promise<PunctuationSign[]>> {

  constructor(private punctuationService: PunctuationService) {
  }

  public resolve(route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot): Promise<PunctuationSign[]> {
    return Observable.create((observer) => {
      return this.punctuationService.getPunctuationList()
        .then((punctuationList: PunctuationSign[]) => {

        observer.next(punctuationList);
        observer.complete();
      });
    });
  }
}
