<mat-toolbar class="ti-header" color="primary">
  <mat-toolbar-row
    [style.borderBottom]="mobileMenuToggle ? '1px solid gray' : 'none'"
    class="ti-header__logo-content"
  >
    <div class="ti-header__logo-container">
      <button mat-icon-button (click)="toggleMenu();mobileMenuToggle = false" *ngIf="isAuthenticated()">
        <mat-icon aria-hidden="true">menu</mat-icon>
        <span class="cdk-visually-hidden">Menu button</span>
      </button>
      <a class="ti-header__logo" target="_blank" href="https://tisane.ai"></a>
    </div>
    <div
      class="ti-header__toggle"
      style="color: white;"
      (click)="toggleMobileMenu()"
      *ngIf="isAuthenticated() && isMobileView"
    >
      <button mat-icon-button>
        <mat-icon aria-hidden="true">more_vert</mat-icon>
      </button>
    </div>
    <app-header-menu *ngIf="isAuthenticated() && !isMobileView"></app-header-menu>
  </mat-toolbar-row>
  <mat-toolbar-row class="ti-header_dropdown" *ngIf="mobileMenuToggle && isAuthenticated()">
    <div class="ti-header__dropdown-content">
      <app-header-menu
        class="ti-header-additional ti-header"
        *ngIf="isAuthenticated() && isMobileView"
        (toggleMobileMenuEvent)="toggleMobileMenu()"
      ></app-header-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>


