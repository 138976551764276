import {Component, OnDestroy, OnInit} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {FeaturesService} from '../../shared/services/features.service';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {emptyFeatureDefinition, FeatureDefinition} from '../../shared/models/feature';
import {LampUpdateResponse} from '../../shared/models/common';
import {collectObjectChanges, toJsonObject} from '../../shared/helpers/object.helper';
import {SessionService} from '../../shared/services/session.service';
import {SessionData} from '../../shared/models/session';
import {applicationModulesRoutePaths} from "../../shared/constants/application-constants";

@Component({
  selector: 'app-feature-edit',
  templateUrl: './feature-edit.component.html',
  styleUrls: ['./feature-edit.component.less']
})
export class FeatureEditComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  private sessionSubscription: Subscription;

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  public doesUserHaveAccessToEdit: boolean;
  public featureDefinitionEditForm: FormGroup;

  public isNew: boolean;
  public featureIndex: number;
  public featureId: string | number;
  public featureListId: number;
  public featureListType: string;

  public featureDefinition: FeatureDefinition;
  public initialFeatureDefinition: FeatureDefinition;

  public currentLanguage: string;

  constructor(
    private formBuilder: FormBuilder,
    private featuresService: FeaturesService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private routerHelper: RouterHelper
  ) {}

  ngOnInit() {
    this.subscribeOnRouteChanges();
    this.subscribeOnSessionChanges();
  }

  private subscribeOnSessionChanges(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.doesUserHaveAccessToEdit = !this.sessionService.isAdmin();
      this.currentLanguage = this.sessionService.session.targetLanguage.englishName;
    });
  }

  private subscribeOnRouteChanges(): void {
    this.routeSubscription = this.route.data.subscribe(
      (data: {
        id: number,
        value: number | string,
        type: string
      }) => {
        this.featureListId = data.id;
        this.featureListType = data.type;
        this.isNew = false;

        if(this.featureListId) {
          this.featuresService.getFeatureDefinitionListDetailsById(this.featureListId).then((category) => {
            this.featureIndex = category.index;
          })
        }

        if (data.value) {
          this.featureId = data.value === 'null' ? '' : data.value;
          this.getFeatureDefinitionById(this.featureListId, this.featureId)
            .then(() => {
              this.createForm();
            });
        } else {
          this.isNew = true;
          this.featureDefinition = toJsonObject(emptyFeatureDefinition) as FeatureDefinition;
          this.createForm();
        }
      });
  }

  private getFeatureDefinitionById(featureListId: number, id: number | string): Promise<void> {
    if (id !== undefined) {
      return this.featuresService.getFeatureDefinitionByIdAndFeatureDefinitionListId(featureListId, id)
        .then((featureDefinition: FeatureDefinition) => {
          this.featureDefinition = toJsonObject(featureDefinition) as FeatureDefinition;
          this.initialFeatureDefinition = toJsonObject(featureDefinition) as FeatureDefinition;
        });
    }

    return Promise.resolve();
  }


  private createForm(): void {
    if (this.featureDefinition) {
      this.featureDefinitionEditForm = this.formBuilder.group({
        id: [this.featureDefinition.id],
        description: [this.featureDefinition.description],
        glossingTag: [this.featureDefinition.glossingTag],
        pennTag: [this.featureDefinition.pennTag],
        referenceUrl: [this.featureDefinition.referenceUrl],
        ud: [this.featureDefinition.ud],
        udTag: [this.featureDefinition.udTag],
        usedInLanguages: [this.featureDefinition.usedInLanguages],
        stopword: [this.featureDefinition.stopword],
        emojiTag: [this.featureDefinition.emojiTag],
        disableHeadExposal: [this.featureDefinition.disableHeadExposal],
        reference: [this.featureDefinition.reference],
        requireFlag: [this.featureDefinition.requireFlag],
        inflectionTablePriority: [this.featureDefinition.inflectionTablePriority,
          [
            Validators.maxLength(3),
            Validators.pattern(/^\d+$/ )
          ]
        ],
        triggerScoreClauseBonus: [this.featureDefinition.triggerScoreClauseBonus,
          [
            Validators.pattern(/^\d+$/)
          ]
        ],
      });
    }
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  private showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }

  public cancel(): void {
    this.routerHelper.back();
  }

  public save(): Promise<void> {
    this.featureDefinition.id = this.featureDefinition.id || '';
    if (this.isNew) {
      const changes = toJsonObject(this.featureDefinition) as FeatureDefinition;
      changes.type = this.featureListType;
      changes.index = this.featureListId;
      changes.featureList = this.featureListId;

      if (!changes.inflectionTablePriority) {
        delete changes.inflectionTablePriority;
      }

      changes.triggerScoreClauseBonus = changes.triggerScoreClauseBonus || 0;

      if (changes.generationFallbackValue === '')
        delete changes.generationFallbackValue;

      if (changes.antonymValue === '')
        delete changes.antonymValue;

      return this.featuresService.createFeatureDefinition(changes)
        .then((message: LampUpdateResponse) => this.reactOnResponseMessage(message));
    } else {
      const changes = collectObjectChanges(this.initialFeatureDefinition, this.featureDefinition) as FeatureDefinition;
      changes.id = this.featureDefinition.id;

      if (!changes.inflectionTablePriority) {
        delete changes.inflectionTablePriority;
      }

      changes.triggerScoreClauseBonus = changes.triggerScoreClauseBonus || 0;

      return this.featuresService.updateFeatureDefinition(changes)
        .then((message: LampUpdateResponse) => this.reactOnResponseMessage(message));
    }
  }

  private reactOnResponseMessage(message: LampUpdateResponse): void {
    if (message.success) {
      this.featuresService.refreshSession()
        .then((sessionData: SessionData) => {
          this.sessionService.refreshSessionData(sessionData);
          this.cancel();
        });
    } else {
      this.showErrorMessage(message.error);
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.sessionSubscription.unsubscribe();
  }

}
