import {Directive, HostListener} from '@angular/core';

import {commonTabSettings} from '../constants/application-constants';
import {ScrollContentService} from '../services/scroll-content.service';

@Directive({
    selector: '[appScrollToChangedPreviouslyElement]'
  }
)
export class ScrollToChangedPreviouslyElementDirective {
  private changedPreviouslySelector = commonTabSettings.changedPreviouslySelector;

  constructor(
    private scrollContentService: ScrollContentService) {
  }

  private scrollTo(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.' + this.changedPreviouslySelector);
      const element = elements[elements.length - 1];

      if (element) {
        const top = (element).getBoundingClientRect().top;

        this.scrollContentService.offsetTopSource.next(top);
      }
    }, 0);
  }

  private scrollToTop(): void {
    setTimeout(() => {
        this.scrollContentService.scrollToTopSource.next(true);
    }, 0);
  }

  @HostListener('opened') onOpened() {
    this.scrollTo();
  }
  @HostListener('scrollToEvent') onScrollToEvent() {
    this.scrollTo();
  }
  @HostListener('scrollToTopEvent') onScrollToTopEvent() {
    this.scrollToTop();
  }
}
