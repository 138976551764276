import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';
import {Language, emptyLanguage, LanguageDescription} from '../models/language';

import {ApiClientService} from './api-client.service';
import {FeaturesService} from './features.service';
import {LampUpdateResponse} from '../models/common';
import { emptyFeatureValue } from '../models/feature';

/**
 * Manages selection of language, either at the system level, or in a particular field. 
 */
@Injectable()
export class LanguageService {
  public languagesList: Array<Language> = [];
  public language: Language;

  constructor(private apiClientService: ApiClientService,
              private featuresService: FeaturesService) {
  }

  public getLanguagesList(): Promise<Array<Language>> {
    if (this.languagesList && this.languagesList[0]) {
      return Promise.resolve(this.languagesList);
    }

    return this.apiClientService
      .get(EndpointUrls.languageSettings.languagesList)
      .then((languagesList) => {
        this.languagesList = (languagesList as Array<Language>);
        return this.languagesList;
      });
  }

  public setLanguage(languageId: number): Promise<Language> {
    return this.apiClientService
      .post(`${EndpointUrls.languageSettings.setLanguage}?language=${languageId}`)
      .then((language) => {
        this.language = (language as Language) || emptyLanguage;
        return this.featuresService.loadFeatureValues().then(() => {
          this.clearSessionData();
          return this.language;
        });
      });

  }

  private clearSessionData() {
    // clear data for "No full refresh" after changed language
    sessionStorage.removeItem('corpora');
    sessionStorage.removeItem('knowledgeGraphs');
    sessionStorage.removeItem('lexemeNoFullRefreshStatus');
  }

  public getLanguageDescription(): Promise<LanguageDescription> {
    return this.apiClientService
      .get(EndpointUrls.languageSettings.language)
      .then((languageDescription: LanguageDescription) => {
        languageDescription.compoundHead = languageDescription.compoundHead || emptyFeatureValue;
        languageDescription.commalessPhraseAutogen = languageDescription.commalessPhraseAutogen || emptyFeatureValue;
        languageDescription.agentlessPhraseAutogen = languageDescription.agentlessPhraseAutogen || emptyFeatureValue;
        languageDescription.agentlessPhraseAutogenRequired = languageDescription.agentlessPhraseAutogenRequired || emptyFeatureValue;
        return languageDescription;
      });
  }


  public updateLanguageDescription(languageDescription: LanguageDescription): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.languageSettings.language, languageDescription)
      .then((resultMessage: LampUpdateResponse) => {
          return resultMessage;
      });
  }

}
