<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{'aliases.aliases' | translate}}:</h2>
  <mat-dialog-actions class="ti-mat-dialog-actions---end">
    <button mat-raised-button color="accent" (click)="addNewEntry()">{{'COMMON.add-new' | translate}}
      <mat-icon aria-hidden="true">add</mat-icon></button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
 <app-alias-list-edit
   [phonemeId]="phonemeId"
   [addNewEntryObservable]="addNewEntryObservable"
   (addNewElementEvent)="handleNewElementLine()"></app-alias-list-edit>
  <p style="visibility: hidden; height: 100px">{{'aliases.aliases' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions class="ti-mat-dialog-actions---end">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
</mat-dialog-actions>
