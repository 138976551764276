<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{'knowledge-graph.family-selection' | translate}}</h2>
</div>
<mat-dialog-content>
  <app-family-selection [chooseOnlyOneFamily]="chooseOnlyOneFamily"
  [initialFamilyId]="initialFamilyId"
  [byText]="byText"
                        (chosenFamilyEvent)="saveFamily($event)"
                        (updateEntryEvent)="saveFamilyIdsSelection($event)"></app-family-selection>
</mat-dialog-content>
<mat-dialog-actions class="ti-mat-dialog-actions--space-between">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
  <button
    mat-raised-button
    color="accent"
    [mat-dialog-close]="{commaDelimitedListOfFamilyIds: commaDelimitedListOfFamilyIds, family: family}">{{'COMMON.select' | translate}}
  </button>
</mat-dialog-actions>
