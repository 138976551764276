<mat-dialog-content>
  <div class="ti-table__wrapper">
    <h1>{{ 'TEST.unknown-words' | translate }}</h1>
    <mat-progress-bar *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-bar>
    <mat-table *ngIf="unknownWords" [dataSource]="unknownWords">
      <ng-container matColumnDef="unknownWord">
        <mat-header-cell *matHeaderCellDef> {{ 'TEST.unknown-word' | translate }} </mat-header-cell>
        <mat-cell class="ti-table__column-item" *matCellDef="let item">
          <a target="_blank" routerLink="/corpora" [queryParams]="{ key: 'text', value: '%' + item.unknownWord }">
            {{item.unknownWord}}
          </a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="unknownWordOccurrences">
        <mat-header-cell *matHeaderCellDef> {{ 'TEST.unknown-word-occurrences' | translate }} </mat-header-cell>
        <mat-cell class="ti-table__column-item" *matCellDef="let item"> {{item.unknownWordOccurrences}}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div class="ti-form__button-panel">
    <button mat-button [mat-dialog-close]="false">{{'COMMON.close' | translate}}</button>
  </div>
</mat-dialog-actions>