<ng-template>
  <h1 mat-dialog-title>Hold up!</h1>
  <mat-dialog-content>
    <div class="ti-menu-wrapper ti-form__column-fields">
      <div class="ti-form__column-fields ti-form__one-line-fields--center">
        <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
      </div>
      <div class="ti-form__one-line-fields">
        <mat-progress-bar color="accent" mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
      </div>
      <div class="ti-form__column-fields ti-form__one-line-fields--center">
        <p class="ti-form__one-line-fields">
          {{'RESTART-LAMP-SERVICE.confirmation' | translate}}
        </p>
      </div>
      <div class="ti-form__button-panel">
        <button mat-raised-button mat-dialog-close color="accent" (click)="back()">{{'COMMON.no' | translate}}</button>
        <button mat-raised-button (click)="confirm()">{{'COMMON.yes' | translate}}</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>