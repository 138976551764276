import {Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appScrollToEvent]'
})
export class GenerateScrollToEventDirective implements OnInit, OnChanges {
  @Input('shouldBeEmittedScrollToEventEvent') shouldBeEmittedScrollToEventEvent: boolean;
  @Input('shouldBeEmittedScrollToTopEventEvent') shouldBeEmittedScrollToTopEventEvent: boolean;
  @Output() scrollToEvent: EventEmitter<any> = new EventEmitter();
  @Output() scrollToTopEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    if (this.shouldBeEmittedScrollToEventEvent) {
      this.scrollToEvent.emit(1);
    }
    if (this.shouldBeEmittedScrollToTopEventEvent) {
      this.scrollToTopEvent.emit(1);
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.shouldBeEmittedScrollToEventEvent && simpleChanges.shouldBeEmittedScrollToEventEvent.currentValue) {
      this.scrollToEvent.emit(1);

    }
    if (simpleChanges.shouldBeEmittedScrollToTopEventEvent && simpleChanges.shouldBeEmittedScrollToTopEventEvent.currentValue) {
      this.scrollToTopEvent.emit(1);
    }
  }
}
