<div class="ti-phonemes-menu-wrapper">
  <h1>{{ 'headers.list-of-phonemes' | translate }}</h1>
  <app-universal-id-text-range-filter
    [currentRange]="'phonemes'"
    [disableNavigation]="isDialog"
    [loadedRecordCount]="phonemes ? phonemes.length : 0"
    (chosenParameterEvent)="reactOnChosenParameterEvent($event)"
  ></app-universal-id-text-range-filter>
  <div class="ti-phonemes-menu__mat-progress-spinner-wrapper">
    <mat-progress-spinner *ngIf="showSpinner" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>
  <app-phonemes-table
    *ngIf="!showSpinner && phonemes"
    [phonemes]="phonemes"
    [isDialog]="isDialog"
    (isDeletedPhonemeEvent)="repeatFilter()"
    (chosenPhonemeEvent)="sendChosenPhonemeToParent($event)"></app-phonemes-table>
</div>
