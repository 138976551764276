<div class="ti-breaks-table-wrapper">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <mat-table *ngIf="nonBreaks" #nonBreaksTable [dataSource]="nonBreaks">
    <ng-container matColumnDef="regular-expression">
      <mat-header-cell *matHeaderCellDef>{{'COMMON.regular-expression' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{element.regex}}</span>
        <textarea [(ngModel)]="editedNonBreak.regex" *ngIf="editedNonBreak && isSelectedForEditing(element)" matInput
                  placeholder="{{'COMMON.regular-expression' | translate}}">{{element.regex}}</textarea>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>{{'COMMON.description' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{element.description}}</span>
        <textarea [(ngModel)]="editedNonBreak.description" *ngIf="editedNonBreak && isSelectedForEditing(element)" matInput
                  placeholder="{{'COMMON.description' | translate}}">{{element.description}}</textarea>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="non-dictionary-pattern">
      <mat-header-cell *matHeaderCellDef>{{'non-break.non-dictionary-pattern' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!isSelectedForEditing(element)">{{getNonDictionaryPattern(element.heuristicId)}}</span>
        <mat-select *ngIf="editedNonBreak && isSelectedForEditing(element)"
                    role="listbox"
                    placeholder="{{ 'non-break.non-dictionary-pattern' | translate}}"
                    [(ngModel)]="editedNonBreak.heuristicId"

        >
          <mat-option role="option" *ngFor="let nonDictionary of nonDictionaryList" [value]="nonDictionary.id">
            {{nonDictionary.description}}
            ({{nonDictionary.regex}})
          </mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="for-all-languages">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
        class="ti-form__column ti-form__one-line-fields--with-checkbox"
        matInput
        matTooltip="{{'non-break.for-all-languages' | translate}}"
        name="forAllLanguages"
        *ngIf="isEditingThis(element) && isAdmin()"
        [(ngModel)]="forAllLanguages">
        <span class="ti-form__label">&nbsp;🌐</span>
      </mat-checkbox>
        <button mat-mini-fab
           *ngIf="(element.language || isAdmin()) && element.id && !editedNonBreak && sessionService.canEditNonLexicon()"
           (click)="edit(element.id)"
        >
          <mat-icon aria-hidden="true">edit</mat-icon>
        </button>
        <button mat-mini-fab
           *ngIf="(element.language || isAdmin()) && element.id && !editedNonBreak && sessionService.canEditNonLexicon()"
           appOpenConfirmDialog
           (doesUserConfirmEvent)="deleteNonBreak(element.id, $event)"
        >
          <mat-icon aria-hidden="true">delete</mat-icon>
        </button>
        <button mat-mini-fab
           *ngIf="isEditingThis(element)"
           (click)="updateNonBreak(editedNonBreak)">
          <mat-icon aria-hidden="true">done</mat-icon>
        </button>
        <button mat-mini-fab
           *ngIf="isEditingThis(element)"
           (click)="revertChanges()">
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
        <span *ngIf="!element.language && !editedNonBreak"         
          matTooltip="{{'non-break.for-all-languages' | translate}}">&nbsp;🌐</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
