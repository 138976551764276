import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, AfterViewInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {SessionService} from '../../../shared/services/session.service';
import {Translated} from '../../../shared/classes/translated.class';
import { LocalStorageHelper } from '../../../shared/helpers/localhost.helper';
import { FilterSetting } from '../../../shared/models/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-universal-range-filter',
  templateUrl: './universal-range-filter.component.html',
  styleUrls: ['./universal-range-filter.component.less']
})

/***
 * This component is only used when there is no ID or text lookup. It appears to be otherwise similar to
 * the more commonly used UniversalIdTextRangeFilterComponent. (Yes, I know, horrible design)
 */
export class UniversalRangeFilterComponent extends Translated implements OnInit, OnDestroy  {
  @Output() chosenParameterEvent: EventEmitter<FilterSetting> =
    new EventEmitter();

  private _currentRange: string;

  @Input() set currentRange (value: string) {
    this._currentRange = value;
    //this.initFromSession();
  }
  get currentRange(): string {
    return this._currentRange;
  }

  @Input() rangeNoneText: string;

  public range: { Key: string, Value: Array<{ Key: string, Value: { description: string, where: string, language?: number } }> }[];

  // public predeterminedValue: string | number;
  // public predeterminedKey: string;
  public filterSelectParametersNames: string[];
  public filterSelectParametersValues: { key?: string | number, value?: string }[] = [];

  constructor(protected translateService: TranslateService,
              protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService) {
    super(translateService, localStorageHelper, sessionService);
  }

  public ngOnInit(): void {
    this.initFromSession();
    //this.subscribeToSession();
  }

  ngOnDestroy(): void {
    super.unsubscribeFromSessionIfSubscribed();
  }

  initThisSubtypeFromSession(): void {
    if (!this.sessionService.session)
      return;
    const languageId = this.sessionService.session.targetLanguage.id;
    const rangeFromSession = this.sessionService.session.ranges;

    const filtrationRule = (range: {
      Key: string, Value: { Key: string, Value: { description: string, where: string, language?: number } }[]
    }) => {
      if (range.Key !== this.currentRange) {
        return false;
      }
      const subFiltrationRule = (subRange: { Key: string, Value: { description: string, where: string, language?: number } }) => {
        return (subRange.Value.language === languageId) || (subRange.Value.language === undefined);
      };
      const filteredRange = range.Value.filter(subFiltrationRule);

      return filteredRange.length;
    };

    if (rangeFromSession) {
      this.range = rangeFromSession.filter(filtrationRule);
      this.formFilterData();
    }
  }

  private formFilterData(): void {
    this.filterSelectParametersNames = ['range'];

    if (this.range && this.range.length) {
      for (let i = 0; i < this.range.length; i++) {
        const groupValues = this.range[i].Value;

        this.filterSelectParametersValues = this.filterSelectParametersValues ?
          this.filterSelectParametersValues : [];

        for (let j = 0; j < groupValues.length; j++) {
          const value: { key?: string | number, value?: string } = {};
          value.value = groupValues[j].Value.description;
          value.key = groupValues[j].Key;

          this.filterSelectParametersValues = this.filterSelectParametersValues.concat([value]);
        }
      }
    }
  }

  public reactOnChosenParameterEvent($event: { key: string, value: string | number }): void {
    if (!$event.key) {
      $event.key = 'range';
    }
    if (!$event.value) {
      $event.value = '';
    }
    this.pushTheDataFurther($event);
  }

  public pushTheDataFurther($event: { key: string, value: string | number }): void {
    const $updatedEvent: FilterSetting = {key: $event.key.toLowerCase(), value: $event.value};
    this.chosenParameterEvent.emit($updatedEvent);

  }
}
