import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs/Observable';

@Injectable()
export class GetValueFromRouterParametersResolver implements Resolve<Promise<string>> {

  constructor() {
  }

  public resolve(route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot): Promise<string> {
    return Observable.create((observer) => {
      observer.next(route.params.value);
      observer.complete();
    });
  }
}
