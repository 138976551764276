import { IComparisonEnrichedNode } from 'json-diff-ts';
import {Language} from './language';

/**
 * Returned by LaMP web service when an update request is invoked. 
 */
export interface LampUpdateResponse {
  /**
   * was the update successful?
   */
  success: boolean;
  /**
   * an error message, if an error happened
   */
  error?: string;
  /**
   * an internal code
   */
  messageStringId?: string;
  /**
   * an ID of the last created record, if exists
   */
  id?: number;
}

/**
 * The user profile: permissions and preferred languages.
 * 
 * This is a copy of the server's profile. 
 * The server has the final say, of course, so if there is a discrepancy, the user gets kicked out.
 */
export interface Profile {
  /**
   * Is the user an administrator?
   */
  administrator: boolean;
  /**
   * Currently active language LaMP ID
   */
  currentLanguage: number;
  /**
   * DB username.
   */
  dbUser: string;
  /**
   * Is the user a guest (view-only access)?
   */
  isGuest: boolean;
  /**
   * Is the user a lexicon editor only?
   */
  isLexiconEditorOnly: boolean;
  permissionLevel: string;
  /**
   * Preferred UI language 1.
   */
  preferredLanguage1: number;
  /**
   * Preferred UI language 2.
   */
  preferredLanguage2: number;
  /**
   * User's time zone (SQL Server standard).
   */
  timezone: string;
  /**
   * Editable languages.
   */
  editableLanguages?: string;
}

/**
 * Holds info about the edits made by the linguists.
 */
export interface Edits {
  /**
   * When the edit was made.
   */
  datetime?: string;
  /**
   * Transaction ID.
   */
  transaction?: number;
  /**
   * Language, when available.
   */
  language?: Language;
  /**
   * Database table involved.
   */
  table?: string;
  description?: string;
  /**
   * Entity ID in its table.
   */
  entityId?: number;
  /**
   * URL to the record.
   */
  url?: string;
  /**
   * Type of the edit (POST, PUT, DELETE)
   */
  verb?: string;
  action?: string;
  /**
   * Request body submitted to LaMP.
   */
  requestBody?: string;
  /**
   * The original entry, when available.
   */
  original?: string;
  parsedRequestBody?: any;
  parsedOriginal?: any;
  /**
   * Error message, when an exception happens during parsing.
   */
  parseError?: string;

  /**
   * @deprecated old menthod. No longer used.
   * Difference compare between before and after changed
   */
  diffs: IComparisonEnrichedNode

  diff?: string | object;
}

export const emptyProfile: Profile = {
  administrator: false,
  currentLanguage: 0,
  dbUser: '',
  isGuest: false,
  isLexiconEditorOnly: false,
  permissionLevel: '',
  preferredLanguage1: 0,
  preferredLanguage2: 0,
  timezone: ''
};

/**
 * Filter in use (by ID / text / range ID), its type, and its description.
 */
export interface FilterSetting {
  /**
   * Type of the filter: id / text / range.
   */
  key: string, 
  /**
   * Value or range of values to search (substring if key is text, ID if key is ID, or range ID if the key is range)
   */
  value: string | number,
  /**
   * Description of the range ID displayed in the page title.
   */
  description?: string
}