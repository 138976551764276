import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { AffixTestAddDialogComponent } from './affix-test-add-dialog.component';


@Directive({ selector: '[appOpenAffixTestAddDialog]' })

export class ButtonOpensAffixTestAddDialogDirective {
  @Input('isTaggingAffixesMenu') isTaggingAffixesMenu: string;
  @Input('affixGroupId') affixGroupId: string;
  upId: number;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openAffixTestListDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(AffixTestAddDialogComponent, {
      data: {
        isTaggingAffixesMenu: this.isTaggingAffixesMenu,
        affixGroupId: this.affixGroupId
      },
      maxWidth: this.isMobileView ? '96%' : '60%',
      minWidth: '50%'
    });

    dialogRef.beforeClosed().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });
  }
}
