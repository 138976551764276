import {NumericKeyStringArrayValueStructure} from './key-value-structure';

/**
 * Base interface for models containing a request ID and relevant features.
 */
export interface ModelWithRelevantFeature {
  /**
   * Unique identifier used by LaMP to identify edit sessions.
   * This allows, for example, editing the same entities in multiple threads. 
   * 
   * Returned by edit... methods and used by update... methods. 
   */
  requestId?: string;
  /**
   * Relevant features. 
   * Used to display more relevant lists of values in SELECT controls.
   */
  relevant?: NumericKeyStringArrayValueStructure[];
}

/**
 * Definition of a feature value.
 */
export interface FeatureValueDefinition {
  /**
   * Feature value.
   */
  id: string | number;
  /**
   * Human-readable description.
   */
  description: string;
  /**
   * Emoji to be displayed in LaMP.
   */
  emoji?: string;
  relevant?: boolean;
}

/**
 * Complete definition of a feature list.
 */
export interface FeatureListDefinition {
  /**
   * Unique feature list ID (across types).
   */
  id: number;
  /**
   * Description of the feature list.
   */
  description: string;
  /**
   * Internal (within the type) index.
   */
  index: number;
  /**
   * List of possible values
   */
  values: FeatureValueDefinition[];
  relevant?: boolean;
  relevantPartially?: boolean;
  hasEmpty?: boolean; // whether the current feature is allowed to have empty string values
}

/**
 * List of feature definitions per feature type
 */
export interface FeatureTypeList {
  /**
   * Feature type (grammar / style / semantics)
   */
  Key: string;
  Value: FeatureListDefinition[];
}

/**
 * Actual feature value (not a feature value definition).
 */
export interface FeatureValue {
  /**
   * Feature index within its type (different from list ID)
   */
  index: number;
  /**
   * Value
   */
  value: string;
  /**
   * Feature type (grammar / style / semantics)
   */
  type?: string;
}

export const emptyFeatureValue: FeatureValue = {index: undefined, value: undefined};

/**
 * Default feature assignment value.
 */
export interface DefaultFeatureValue extends ModelWithRelevantFeature {
  id?: string;
  beforeAffixes?: boolean;
  trigger?: FeatureValue;
  generation?: boolean;
  allowOverwrite?: boolean;
  triggerDescription?: string;
  localizedEntityFeatureList?: string;
  featureIdList?: string;
  assignments?: FeatureValue[];
  maxIterations?: number;
  note?: string;
}

export const emptyDefaultFeatureValue = {trigger: emptyFeatureValue, assignments: [emptyFeatureValue]};

export interface DefaultFeatureValuesListElement {
  Key: string;
  Value: DefaultFeatureValue[];
}

export interface FeaturesListElement {
  Key: string;
  Value: FeatureListDefinition[];
}

export interface FeatureLocalization {
  requestId?: string;
  description?: string;
  id: number;
  index: number;
  localized?: boolean;
  referenceUrl?: string;
  systemDescription?: string;
  tag?: string;
  copyToPhrase?: string;
  wiktionaryTag?: string;
}

export interface FeatureCategory {
  requestId?: string;
  allowFallback?: boolean; //?
  allowMultipleValues?: boolean;
  copyToPhrase?: boolean;
  behavior: string;
  defaultValue: any;
  description: string;
  generationWeight: number;
  id?: number;
  index: number;
  inflectionPattern: boolean;
  referenceUrl: string;
  type: string;
  ud: any;
  inflectionTablePriority?: number;
}


export const featureCategoryBehaviorOptions = [
  {value: 'generic', description: 'generic'},
  {value: 'immutable', description: 'immutable class'},
  {value: 'inflection', description: 'inflection feature'},
  {value: 'immutableIfInLemma', description: 'immutable if Lemma'},
  {value: 'ignoreOnGeneration', description: 'ignore on generation'}
];


export const emptyFeatureCategory = {
  allowFallback: false,
  allowMultipleValues: false,
  copyToPhrase: false,
  behavior: 'generic', // featureCategoryBehaviorOptions
  defaultValue: '',
  description: '',
  generationWeight: 0,
  inflectionPattern: false,
  referenceUrl: '',
  type: '',
  ud: ''
};

export interface FeatureDefinition {
  antonymValue?: string;
  description?: string;
  generationFallbackValue?: string;
  glossingTag?: string;
  featureList?: number | string;
  id?: string | number;
  index?: string | number;
  pennTag?: string;
  referenceUrl?: string;
  type?: string;
  ud?: string;
  udTag?: string;
  usedInLanguages?: string;
  emojiTag?: string;
  stopword?: boolean;
  disableHeadExposal?: boolean;
  reference: string,
  requireFlag?: boolean,
  inflectionTablePriority?: number;
  triggerScoreClauseBonus?: number;
  globalUseCountLexemes?: string; // Read-only.
  globalUseCountPhrases?: string; // Read-only.
  globalUseCountFamilies?: string; // Read-only.
  globalUseCountAffixes?: string; // Read-only.
  globalUseCountClitics?: string; // Read-only.
  globalUseCountDefaultValues?: string; // Read-only.
  globalUseCountAgreement?: string; // Read-only.
  globalUseCountPragmatics?: string; // Read-only.
  languageUseCountLexemes?: string; // Read-only.
  languageUseCountPhrases?: string; // Read-only.
  languageUseCountFamilies?: string; // Read-only.
  languageUseCountAffixes?: string; // Read-only.
  languageUseCountClitics?: string; // Read-only.
  languageUseCountDefaultValues?: string; // Read-only.
  languageUseCountAgreement?: string; // Read-only.
}

export const emptyFeatureDefinition = {
  antonymValue: '',
  description: '',
  generationFallbackValue: '',
  glossingTag: '',
  pennTag: '',
  referenceUrl: '',
  udTag: '',
  inflectionTablePriority: ''
};
