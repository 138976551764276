<div class="ti-alias-list-table-wrapper">
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <mat-table *ngIf="aliasList" #nonBreaksTable [dataSource]="aliasList">
    <ng-container matColumnDef="alias-text">
      <mat-header-cell *matHeaderCellDef>{{'aliases.alias' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="(!currentlyEdited || currentlyEdited.id !== element.id) && element.id"
              class="ti-sign-in-the-table--editable ti-alias-list__alias-text">{{element.text}}</span>
        <mat-form-field
          *ngIf="(currentlyEdited && currentlyEdited.id === element.id) || !element.id"
          color="accent">
          <input maxlength="15" class="ti-alias-list__alias-text"
                 required
                 matInput
                 style="line-height: 150%;"
                 [(ngModel)]="currentlyEdited.text"/></mat-form-field>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="alias-normalization">
      <mat-header-cell *matHeaderCellDef>Alias normalization</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="(!currentlyEdited || currentlyEdited.id !== element.id) && element.id">{{getAliasNormalizationText(element.aliasNormalization)}}</span>
        <mat-radio-group *ngIf="(currentlyEdited && currentlyEdited.id === element.id) || !element.id" [(ngModel)]="currentlyEdited.aliasNormalization">
          <mat-radio-button
            class="ti-phonemes-edit__radio-button"
            *ngFor="let option of phonemesAliasNormalizationAttributeTextChoices"
            value="{{option.key}}"><div>{{option.value}}</div>
          </mat-radio-button>
        </mat-radio-group>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-mini-fab
           *ngIf="(!currentlyEdited || currentlyEdited.id !== element.id) && element.id"
           (click)="edit(element.id)"
        ><mat-icon aria-hidden="true">edit</mat-icon>
        </button>
        <button mat-mini-fab
           class="ti-header-menu__link--underlined"
           *ngIf="(!currentlyEdited || currentlyEdited.id !== element.id) && element.id"
           appOpenConfirmDialog
           (doesUserConfirmEvent)="deleteAliasIfUserConfirmsDeletion(element.id, $event)"
           >
          <mat-icon aria-hidden="true">delete</mat-icon>
        </button>
        <button mat-mini-fab
           [disabled]="!element.text"
           class="ti-header-menu__link--underlined"
           *ngIf="(currentlyEdited && currentlyEdited.id === element.id) || !element.id"
           (click)="saveAlias(currentlyEdited)">
          <mat-icon aria-hidden="true">done</mat-icon>
        </button>
        <button mat-mini-fab class="ti-header-menu__link--underlined"
           *ngIf="(currentlyEdited && currentlyEdited.id === element.id) || !element.id"
           (click)="revertChanges(element.id)">
          <mat-icon aria-hidden="true">clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
