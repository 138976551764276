import {Component, OnDestroy, OnInit, AfterViewInit} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';

import {FeaturesListElement} from '../../shared/models/feature';
import {applicationModulesRoutePaths} from '../../shared/constants/application-constants';

import {SessionService} from '../../shared/services/session.service';
import {FeaturesService} from '../../shared/services/features.service';
import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {LampUpdateResponse} from '../../shared/models/common';
import {SessionData} from '../../shared/models/session';


@Component({
  selector: 'app-definition-menu-item',
  templateUrl: './definition-menu-item.component.html',
  styleUrls: ['./definition-menu-item.component.less']
})
export class DefinitionMenuItemComponent implements OnInit, OnDestroy, AfterViewInit {

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();

  private sessionSubscription: Subscription;
  public featureDefinitionList: FeaturesListElement[];

  public doesUserHaveAccessToAddAndDelete: boolean;

  public pathToEditPages: string = applicationModulesRoutePaths.administration.administration.path +
    applicationModulesRoutePaths.administration.definition.path;


  constructor(
    public featuresService: FeaturesService,
    private sessionService: SessionService,
    private routerHelper: RouterHelper
  ) {}

  ngOnInit(): void {
    this.subscribeOnSessionChanges();
    this.getFeatureDefinitionList();
  }

  ngAfterViewInit(): void {
    this.featuresService.scrollToLast();
  }


  ngOnDestroy(): void {
    this.sessionSubscription.unsubscribe();
  }

  private subscribeOnSessionChanges(): void {
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(() => {
      this.doesUserHaveAccessToAddAndDelete = this.sessionService.isAdmin();
    });
  }

  public getFeatureDefinitionList() {
    this.featuresService.getFeatureDefinitionList().then((featureDefinitionList: FeaturesListElement[]) => {
      this.featureDefinitionList = featureDefinitionList;
    });
  }

  public navigateToCreatePage(featureListLocalizationListKey: string,
                              featureListLocalizationId?: number): void {
    let page = this.pathToEditPages;
    const queryParameters = {
      editedKey: featureListLocalizationListKey,
      editedId: ''
    };

    if (featureListLocalizationId) {
      page = page + '/definition/create/' + featureListLocalizationListKey + '/' + featureListLocalizationId.toString();
      queryParameters.editedId = featureListLocalizationId.toString();
    } else {
      page = page + '/category/create/' + featureListLocalizationListKey;
    }

    this.routerHelper.navigateToPageWithQueryParameters(page, queryParameters);
  }

  public navigateToEditPage(featureListLocalizationListKey: string,
                            featureListLocalizationId: number, featureLocalizationValue?: string) {

    let page = this.pathToEditPages;
    const queryParameters = {
      editedKey: featureListLocalizationListKey,
      editedId: featureListLocalizationId.toString(),
      editedValue: ''
    };

    if (featureLocalizationValue !== undefined) {
      page = page + '/definition/edit/' + featureListLocalizationId.toString() + '/' + (featureLocalizationValue.toString() || null);
      queryParameters.editedValue = featureLocalizationValue.toString();
    } else {
      page = page + '/category/edit/' + featureListLocalizationId.toString();
    }

    this.routerHelper.navigateToPageWithQueryParameters(page, queryParameters);
  }

  public deleteAfterUserConfirmation(doesUserConfirmDeletion: boolean, featureListId: number, id?: number): Promise<void> {
    if (doesUserConfirmDeletion) {
      if (id === undefined) {
        return this.featuresService.deleteFeatureDefinitionListById(featureListId)
          .then((successfulResponseMessage: LampUpdateResponse) => {
            if (successfulResponseMessage.success) {
              this.featuresService.refreshSession()
                .then((sessionData: SessionData) => {
                  this.getFeatureDefinitionList();
                });
            } else {
              this.showErrorMessage(successfulResponseMessage.error);
            }
          });
      }

      return this.featuresService.deleteFeatureDefinitionByIdAndFeatureDefinitionListId(featureListId, id)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.featuresService.refreshSession()
              .then((sessionData: SessionData) => {
                this.getFeatureDefinitionList();
              });
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
    }

    return Promise.resolve();
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  protected showErrorMessage(error?: string) {
    this.errorMessageBehaviorSubject.next(error);
  }
}
