import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';

import {PunctuationSignData} from '../models/punctuation-sign';
import {PunctuationService} from '../services/punctuation.service';

@Injectable()
export class PunctuationSignDataResolver implements Resolve<Promise<PunctuationSignData | undefined>> {

  constructor(private punctuationService: PunctuationService) {
  }

  public resolve(route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot): Promise<PunctuationSignData | undefined> {
    return Observable.create((observer) => {
      const punctuationSignId = route.params.id;
      return this.punctuationService.getPunctuationSignData(punctuationSignId).then((punctuationSignData) => {
        observer.next(punctuationSignData as PunctuationSignData);
        observer.complete();
      });
    });
  }
}
