import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

import {ExtraHypothesis} from '../../../shared/models/extra-hypothesis';
import {Interfix} from '../../../shared/models/interfixes';
import {SessionService} from '../../../shared/services/session.service';
import {LocalStorageHelper} from '../../../shared/helpers/localhost.helper';
import {commonTabSettings} from '../../../shared/constants/application-constants';
import { Translated } from '../../../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-interfix-and-hypothesis-list',
  templateUrl: './interfix-and-hypothesis-list.component.html',
  styleUrls: ['./interfix-and-hypothesis-list.component.less']
})
export class InterfixAndHypothesisListComponent extends Translated implements OnInit, OnDestroy {
  @Input() public isHypothesis: boolean;
  @Input() public list: ExtraHypothesis[] | Interfix[];
  @Input() public routerLinkPart: string;
  @Input() public errorMessageSource: Observable<string>;
  @Input() public showProgressBar: boolean;

  @Output() deleteElementEvent: EventEmitter<number> = new EventEmitter();

  public visitedPunctuationIndex: number;
  public idIndexKey: string = commonTabSettings.idIndexKey;
  public changedPreviouslySelector: string = commonTabSettings.changedPreviouslySelector;

  public displayedColumns: string[] = ['id', this.isHypothesis ? '2' : '1', this.isHypothesis ? '1' : '2', '3', 'buttons'];

  constructor(protected translateService: TranslateService,
              protected sessionService: SessionService,
              protected localStorageHelper: LocalStorageHelper) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.getDefaultFeatureValueIndex();
  }

  ngOnDestroy(): void {
  }

  public canEdit(): boolean {
    return this.sessionService.canEditNonLexicon();
  }

  initThisSubtypeFromSession(): void {
  }

  public deleteElementAfterUserConfirmation(doesUserConfirm: boolean, elementId: number): void {
    if (doesUserConfirm) {
      this.deleteElementEvent.next(elementId);
    }
  }

  private getDefaultFeatureValueIndex(): void {
    this.visitedPunctuationIndex = this.getCashedIndex(this.idIndexKey);
  }

  private getCashedIndex(indexName: string): number | undefined {
    const cashedIndex = this.localStorageHelper.getTabSetting(indexName);
    const currentIndex = parseInt(cashedIndex, 10);

    return ((typeof currentIndex === 'number') && !isNaN(currentIndex)) ? currentIndex : undefined;
  }
}
