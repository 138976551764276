<div class="ti-mat-dialog-header-with-button">
  <h2 mat-dialog-title>{{'AFFIXES.affix-test' | translate}}</h2>
  <mat-dialog-actions class="ti-mat-dialog-actions---end">
    <button mat-raised-button color="accent"
      appOpenAffixTestAddDialog
      [isTaggingAffixesMenu]="isTaggingAffixesMenu"
      [affixGroupId]="affixGroupId"
      (beforeDialogClosedEvent)="getList()">{{'COMMON.add-new'
      | translate}}
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
<mat-dialog-content>
  <mat-progress-bar *ngIf="showProgressbar" color="accent" mode="indeterminate"></mat-progress-bar>
  <app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
  <div class="ti-breaks-table-wrapper">
    <mat-table *ngIf="affixTests" #affixTestsTable [dataSource]="affixTests">
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div [matTooltip]="element.failReason" class="description-content">
            <span *ngIf="element.lastTestFailedString != null">{{element.lastTestFailedString === 'no' ? '✅' : '❌' }}&nbsp;</span>
            <span *ngIf="isTaggingAffixesMenu && element.mainLemma">{{element.mainLemma}}&nbsp; <span class="ti-affix-test__arrow">→&nbsp;</span></span>
            <span *ngIf="!isTaggingAffixesMenu && element.stem">{{element.stem}}&nbsp; <span class="ti-affix-test__arrow">→&nbsp;</span></span>
            <span *ngIf="element.inflectedForm && !element._isEditing">{{element.inflectedForm}}&nbsp;</span>
            <mat-form-field color="accent" *ngIf="element.inflectedForm && element._isEditing" class="">
              <input matInput [(ngModel)]="element.inflectedForm" placeholder="Inflection form"
                [id]="'inflectedForm_' + element.id" />
            </mat-form-field>
            <span *ngIf="element.lexemeId">[Lexeme <a target="blank" [routerLink]="'/lexicon'" [queryParams]="{key: 'id', value: element.lexemeId}">{{element.lexemeId}}</a>]&nbsp;</span>
            <span *ngIf="element.familyDescription">({{element.familyDescription}})&nbsp;</span>
            <span *ngIf="element.lastSucceeded && element.lastSucceeded.length">(last success: {{element.lastSucceeded.substr(0, 10)}})</span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- Edit -->
          <button mat-mini-fab color="primary" *ngIf="!element._isEditing" (click)="editInflectionForm(element)">
            <mat-icon aria-hidden="true">edit</mat-icon>
          </button>
          
          <!-- Save -->
          <button mat-mini-fab color="primary" *ngIf="element._isEditing == true" (click)="saveInflectionForm(element)">
            <mat-icon aria-hidden="true">done</mat-icon>
          </button>
          
          <!-- Delete -->
          <button mat-mini-fab color="primary" appOpenConfirmDialog
            (doesUserConfirmEvent)="deleteAfterUserConfirmation($event, element.id)">
            <mat-icon aria-hidden="true">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="ti-mat-dialog-actions---end">
  <button mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
</mat-dialog-actions>