import {Injectable} from '@angular/core';

import {ApiClientService} from './api-client.service';
import {EndpointUrls} from '../constants/endpoint';
import {LampUpdateResponse} from '../models/common';
import {InflectionList, AdvancedInflection} from '../models/inflections';
import {AdvancedLexeme} from '../models/lexicon';
import {FeatureValue} from '../models/feature';

@Injectable()
export class InflectionTableService {

  constructor(private apiClientService: ApiClientService) {}

  public getInflectionList(lexemeId: number, testFragmentCount = false, externalCount = false, audit = false): Promise<InflectionList> {
    return this.apiClientService.get(`${EndpointUrls.inflection.inflectionList}?lexeme=${lexemeId}&testFragmentCount=${testFragmentCount}&externalCount=${externalCount}&audit=${audit}`);
  }

  public getInflectionById(line: {inflectionFeatures?: FeatureValue[]; text?: string; inflectionId: number; lexemeId: number; familyId: number}): Promise<AdvancedInflection> {
    return this.apiClientService.post(EndpointUrls.inflection.inflection, line);
  }

  public updateInflection(inflection: AdvancedInflection): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.inflection.inflection, inflection);
  }

  public deleteInflection(inflectionId: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.inflection.inflection}?id=${inflectionId}`);
  }

  public createInflection(inflection: AdvancedInflection): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.inflection.inflection, inflection);
  }

  public tagLemma(lexeme: AdvancedLexeme): Promise<AdvancedLexeme> {
    return this.apiClientService.post(EndpointUrls.inflection.tagLemma, lexeme);
  }

  public runtimeInflectionFeatures(body: {familyId: number | string; lexemeId: number | string; inflectionFeatures: FeatureValue[]}): Promise<FeatureValue[]> {
    return this.apiClientService.post(EndpointUrls.inflection.runtimeInflectionFeatures, body);
  }

}
