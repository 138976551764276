import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppMaterialDependenciesModule } from '../../app-material-dependencies.module';
import { ErrorMessageModule } from '../../universal-components/error-message/error-message.module';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialDependenciesModule,
    ErrorMessageModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [],
  exports: [AppMaterialDependenciesModule, FormsModule, ReactiveFormsModule, ErrorMessageModule, DirectivesModule]
})
export class SharedModule {
}
