<app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>
<app-universal-id-text-range-filter
      [currentRange]="'compatibilitysets'"
      (chosenParameterEvent)="reactOnChosenParameterEvent($event)"
      [disableNavigation]="true"
>
</app-universal-id-text-range-filter>
<mat-progress-bar *ngIf="showProgressBar" color="accent" mode="indeterminate"></mat-progress-bar>
<div *ngIf="advancedCriteriaList" class="ti-table__wrapper">
  <mat-table #advancedCriteriaTable [dataSource]="advancedCriteriaList">
    <ng-container matColumnDef="id">
      <mat-header-cell class="ti-table__id-header" *matHeaderCellDef> {{ 'COMMON.id' | translate }} </mat-header-cell>
      <mat-cell class="ti-table__column-item ti-table__id" *matCellDef="let advancedCriteria"> {{ advancedCriteria.id }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef> {{ 'COMMON.description' | translate }} </mat-header-cell>
      <mat-cell class="ti-table__column-item ti-table__description" *matCellDef="let advancedCriteria"> {{ advancedCriteria.description }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="buttons">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="ti-table__buttons" *matCellDef="let advancedCriteria">
          <button
            mat-mini-fab
            color="primary"
            [disabled]="advancedCriteria.languageId != currentLanguageId"
            title="{{advancedCriteria.languageId != currentLanguageId ? ('lexicon.can-not-edit-in-current-language' | translate) : ''}}"
            appAdvancedCriteriaOpenEditDialog
            (updateDataEvent)="reactOnUpdateDataEvent(advancedCriteria.id)"
            [isEdit]="true"
            [forWords]="forWords"
            [currentId]="advancedCriteria.id"
          >
            <mat-icon aria-hidden="true">edit</mat-icon>
          </button>
          <button mat-mini-fab color="primary" [disabled]="advancedCriteria.languageId != currentLanguageId"
            title="{{advancedCriteria.languageId != currentLanguageId ? ('lexicon.can-not-edit-in-current-language' | translate) : ''}}"
            appOpenConfirmDialog
            (doesUserConfirmEvent)="deleteAdvancedCriteriaAfterUserConfirmation($event, advancedCriteria.id)"
          >
            <mat-icon aria-hidden="true">delete</mat-icon>
          </button>
          <button mat-mini-fab color="primary"
            [isCopying]="true"
            appAdvancedCriteriaOpenEditDialog
            [forWords]="forWords"
            [currentId]="advancedCriteria.id"
          >
            <mat-icon aria-hidden="true">file_copy</mat-icon>
          </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="selected">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="ti-table__radio-button" *matCellDef="let advancedCriteria">
        <mat-radio-button
          (click)="selectItem(advancedCriteria.id);"
          [value]="false"
          [name]="'selected-item'"
        ></mat-radio-button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
