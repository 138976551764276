import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import {ApiClientService} from './api-client.service';
import {LampUpdateResponse} from '../models/common';
import {AdvancedCriteria, Lexeme, LexemeFamilyConnection, Root, AdvancedLexeme, emptyAdvancedLexeme} from '../models/lexicon';
import { LocalStorageHelper } from '../helpers/localhost.helper';

@Injectable()
export class LexiconService {

  constructor(private apiClientService: ApiClientService, private localStorageHelper: LocalStorageHelper) {
  }

  public getLexicon(type: string, arg: string | number): Promise<Lexeme[]> {
    if (!arg || !type)
      return undefined;
    return this.apiClientService
      .get(`${EndpointUrls.lexicon.lexicon}?type=${type}&arg=${arg}`)
      .then((lexemes) => {
        return (lexemes as Lexeme[]);
      });
  }

  public resetMorphology() {
    this.apiClientService.get(`${EndpointUrls.lexicon.unloadMorphology}`);
  }

  public mergeLexemes(target: number, ids: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .get(`${EndpointUrls.lexicon.mergeLexemes}?target=${target}&ids=${ids}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public retag(type: string, arg: string): Promise<number> {
    return this.apiClientService.put(`${EndpointUrls.lexicon.retag}?type=${type}&arg=${arg}`).then((updated: number) => 
    {
      return updated;
    });
  }
  
  public async retagLexeme(lexeme: Lexeme): Promise<void> {
    return this.getLexeme(lexeme.id).then(async (expandedLexeme: AdvancedLexeme) => {
      // we need to obtain the features
      await this.apiClientService.post(EndpointUrls.inflection.tagLemma, expandedLexeme).then(
        async (taggedLexeme: AdvancedLexeme) => {
        let editedLexeme: AdvancedLexeme = taggedLexeme; //emptyAdvancedLexeme;
        delete editedLexeme.tagging;
        delete editedLexeme.lastYearActive;
        delete editedLexeme.relevant;
        delete editedLexeme.unverified;
        delete editedLexeme.frequency;
        delete editedLexeme.earliestMention;
        await this.updateLexeme(editedLexeme);
      });
    });
  }

  public getLexemeFamilyConnection(lexemeId: number, familyId: number): Promise<LexemeFamilyConnection> {
    return this.apiClientService
      .get(`${EndpointUrls.lexicon.lexemeFamily}?lexeme=${lexemeId}&family=${familyId}`)
      .then((lexemeFamilyConnection) => {
        return lexemeFamilyConnection as LexemeFamilyConnection;
      });
  }

  public async getLexemeDescription(lexemeId: number): Promise<string> {
    let description = this.localStorageHelper.getTabSetting(lexemeId.toString());
    if (!description) {
      description = await this.apiClientService.get(`${EndpointUrls.lexicon.lexemeDescription}?lexeme=${lexemeId}`);
      this.localStorageHelper.setTabSetting(lexemeId.toString(), description);
    }
    return description;
  }

  public createLexemeFamilyConnection(lexemeFamilyConnection: LexemeFamilyConnection): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.lexicon.lexemeFamily, lexemeFamilyConnection)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public updateLexemeFamilyConnection(lexemeFamilyConnection: LexemeFamilyConnection): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.lexicon.lexemeFamily, lexemeFamilyConnection)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public deleteLexemeFamilyConnection(lexemeId: number, familyId: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.lexicon.lexemeFamily}?lexeme=${lexemeId}&family=${familyId}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public getLexemeByLegacyId(legacyId: number): Promise<AdvancedLexeme> {
    return this.apiClientService
      .get(`${EndpointUrls.lexicon.legacy}?id=${legacyId}`)
      .then((lexeme) => {
        return lexeme as AdvancedLexeme;
      });
  }

  public getLexeme(lexemeId: number): Promise<AdvancedLexeme> {
    return this.apiClientService
      .get(`${EndpointUrls.lexicon.lexeme}?id=${lexemeId}`)
      .then((lexeme) => {
        return lexeme as AdvancedLexeme;
      });
  }

  public createLexeme(lexeme: Lexeme): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.lexicon.lexeme, lexeme)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public updateLexeme(lexeme: AdvancedLexeme): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.lexicon.lexeme, lexeme)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public deleteLexeme(lexemeId: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.lexicon.lexeme}?id=${lexemeId}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public approveLexeme(lexemeId: number): Promise<LampUpdateResponse> {
    return this.apiClientService.post(`${EndpointUrls.lexicon.approveLexeme}?lexeme=${lexemeId}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public validateLexemeLinks(lexemeId: number): Promise<LampUpdateResponse> {
    return this.apiClientService.post(`${EndpointUrls.lexicon.validateLexemeLinks}?lexeme=${lexemeId}&existing=true&fallback=EXISTING`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public getAdvancedCriteriaList(type: string, arg: string | number, forWords: boolean): Promise<AdvancedCriteria[]> {
    return this.apiClientService
      .get(`${EndpointUrls.lexicon.advancedCriteriaList}?type=${type}&arg=${arg}&forWords=${forWords}`)
      .then((advancedCriteria) => {
        return (advancedCriteria as AdvancedCriteria[]);
      });
  }

  public getAdvancedCriteriaById(advancedCriteriaId: number): Promise<AdvancedCriteria> {
    return this.apiClientService.get(`${EndpointUrls.lexicon.advancedCriteria}?id=${advancedCriteriaId}`).then(
      (advancedCriteria: AdvancedCriteria) => { 
        return advancedCriteria; 
      }
    );
  }

  public createAdvancedCriteria(advancedCriteria: AdvancedCriteria): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.lexicon.advancedCriteria, advancedCriteria)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public updateAdvancedCriteria(advancedCriteria: AdvancedCriteria): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.lexicon.advancedCriteria, advancedCriteria)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public deleteAdvancedCriteria(advancedCriteriaId: number): Promise<LampUpdateResponse> {
    return this.apiClientService
        .deleteRequest(`${EndpointUrls.lexicon.advancedCriteria}?id=${advancedCriteriaId}`);
  }

  public getRootList(arg: string, type: string): Promise<Root[]> {
    return this.apiClientService.get(`${EndpointUrls.lexicon.rootList}?arg=${arg}&type=${type}`);
  }

  public createRoot(root: Root): Promise<LampUpdateResponse> {    
    return this.apiClientService.post(EndpointUrls.roots.root, root);    
  }

  public approveLexemeFamily(lexemeId: number, familyId: number): Promise<LampUpdateResponse> {
    return this.apiClientService.post(`${EndpointUrls.lexicon.approveLexemeFamily}?lexeme=${lexemeId}&family=${familyId}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public toggleSilent(member: number, domain: number): Promise<LampUpdateResponse> {
    return this.apiClientService.put(`${EndpointUrls.lexicon.toggleSilent}?member=${member}&domain=${domain}`)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }
}
