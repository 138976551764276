import {
  Component, EventEmitter, Input, OnChanges, Output,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { MatCheckboxChange, MatSnackBar } from '@angular/material';

import {KnowledgeGraphService} from '../../shared/services/knowledge-graph.service';
import {Lexeme} from '../../shared/models/lexicon';
import {SessionService} from '../../shared/services/session.service';
import { MatDialogRef } from '@angular/material/dialog';
import { KnowledgeGraphDialogComponent } from '../knowledge-graph-dialog/knowledge-graph-dialog.component';

@Component({
  selector: 'app-knowledge-graph-lexeme-selection',
  templateUrl: './knowledge-graph-lexeme-selection.component.html',
  styleUrls: ['./knowledge-graph-lexeme-selection.component.less']
})
export class KnowledgeGraphLexemeSelectionComponent implements OnInit {
  @Input() isAutoSuggestionLexemeDialog: boolean;
  @Input() familyId: number;
  @Input() byText: string;
  @Input() chooseOnlyOneLexeme: boolean;
  @Input() allowOtherLanguages: boolean = false;
  @Input() lexemesList: Lexeme[] = [];
  public showSpinner: boolean = false;
  // public lexemesList: Lexeme[];
  public displayedColumns: string[];
  public addedLexemesList: any[] = [];
  public targetLanguageMode: string = "";
  public currentLanguageName: string;
  public hasFallback: boolean = false;

  private $event: { key: string, value: string | number };

  @Output() updateEntryEvent: EventEmitter<string> = new EventEmitter();

  constructor(private knowledgeGraphService: KnowledgeGraphService,
    sessionService: SessionService, public dialogRef: MatDialogRef<KnowledgeGraphDialogComponent>, private snackBar: MatSnackBar) {

    this.displayedColumns = ['id', 'lemma', 'inflectedForms',
      'featureDescriptionList', 'actions'];
    if (sessionService.session && sessionService.session.targetLanguage) {
      this.currentLanguageName = sessionService.session.targetLanguage.name;
      this.hasFallback = Boolean(sessionService.session.targetLanguage.fallback);
    }
  }

  ngOnInit(): void {
    if (window.event) {
      window.event.stopPropagation();
    } 
    if (this.byText) {
      this.findLexemes({key: "text", value: this.byText});
    } else {
      if (this.familyId > 0) {
        this.findLexemes({key: "id", value: this.familyId});
      }
    }
  }

  // ngOnChanges(simpleChanges: SimpleChanges): void {
  //   if (this.isAutoSuggestionLexemeDialog && this.familyId && simpleChanges.isAutoSuggestionLexemeDialog.firstChange) {
  //     this.showSpinner = true;
  //     this.knowledgeGraphService.getSuggestedLexemesList(this.familyId)
  //       .then((lexemesList: Lexeme[]) => {
  //         this.lexemesList = [];
  //         this.showSpinner = false;
  //         if (lexemesList && lexemesList[0]) {
  //           this.lexemesList = lexemesList;
  //           if (lexemesList.length && lexemesList[0].id == -1) {
  //             this.dialogRef.close(lexemesList[0].id);
  //             this.snackBar.open('Detected missing lexemes by Generative AI', "Close", {
  //               duration: 3000
  //             });
  //           }
  //         }
  //       });
  //   }
  // }

  public findLexemes($event: { key: string, value: string | number }): Promise<void> {
    this.addedLexemesList = [];
    this.$event = $event;
    this.showSpinner = true;

    if (this.isAutoSuggestionLexemeDialog) {
      this.showSpinner = false;
      return Promise.resolve();
    } else {
      return this.knowledgeGraphService.getLexemesListForTheCurrentRange($event.value.toString(), $event.key, this.targetLanguageMode)
        .then((lexemesList: Lexeme[]) => {
          this.lexemesList = [];
          this.showSpinner = false;
          if (lexemesList && lexemesList[0]) {
            this.lexemesList = lexemesList;
          }
        });
    }
  }

  public addToListOrRemove(lexemeId: number, isLexemeSelected: boolean): void {
    if (isLexemeSelected) {
      this.addedLexemesList.push(lexemeId);
    } else {
      const deletedLexemeId = this.addedLexemesList.findIndex(element => (element === lexemeId));
      this.addedLexemesList.splice(deletedLexemeId, 1);
    }
  }

  public updateEntry(lexemeId: number, $event: MatCheckboxChange): void {
      this.addToListOrRemove(lexemeId, $event.checked);
      this.updateEntryEvent.emit(this.formTheLexemesIdsListIntoString());
  }

  public selectItem(lexemeId: number): void {
    this.updateEntryEvent.emit(String(lexemeId));
  }

  public formTheLexemesIdsListIntoString(): string {
    return this.addedLexemesList.join(',');
  }
}
