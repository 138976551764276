import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {KnowledgeGraphElementLanguageSpecificEditDialogComponent} from './knowledge-graph-element-language-specific-edit-dialog.component';
import {FamilyDefinition} from '../../shared/models/knowledge-graph';
import {FeatureListDefinition} from '../../shared/models/feature';



@Directive({selector: '[appOpenKnowledgeGraphLanguageSpecificEditDialog]'})

export class ButtonOpensKnowledgeGraphLanguageSpecificEditDirective {
  @Input('familyId') familyId: number;
  @Input('systemDescription') systemDescription: string;
  @Input('relevantGrammarFeatureList') relevantGrammarFeatureList: FeatureListDefinition[];
  @Input('relevantSemanticFeatureList') relevantSemanticFeatureList: FeatureListDefinition[];
  @Input('localizedFamily') localizedFamily: FamilyDefinition;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() afterDialogClosedEvent: EventEmitter<FamilyDefinition> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(KnowledgeGraphElementLanguageSpecificEditDialogComponent, {
      minWidth: this.isMobileView ? '96%' : '80%',
      data: {
        familyId: this.familyId,
        systemDescription: this.systemDescription,
        relevantGrammarFeatureList: this.relevantGrammarFeatureList,
        relevantSemanticFeatureList: this.relevantSemanticFeatureList,
        localizedFamily: this.localizedFamily
      }
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });

    dialogRef.afterClosed().subscribe((familyChanges: FamilyDefinition) => {
      if (familyChanges && Object.keys(familyChanges).length) {
        this.afterDialogClosedEvent.emit(familyChanges);
      }
    });
  }
}
